// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._qro7rG{background-color:#fff;border-radius:5px;max-width:450px;width:450px}._qro7rG._x28Y2A{-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column}@media screen and (max-width:767px){._qro7rG{width:95vw}}", ""]);
// Exports
exports.locals = {
	"boost-modal": "_qro7rG",
	"boost-modal--checkout": "_x28Y2A"
};
module.exports = exports;
