import classNames from 'classnames';
import _ from 'lodash';
import memoize from 'memoize-one';
import React, { Component } from 'react';
import isEqual from 'react-fast-compare';

import withStyles from '~tools/react/hocs/withStyles';
import withUniqueId, { WithUniqueIdProps } from '~tools/react/hocs/withUniqueId';

import Text from '~tools/react/components/Text';

import CheckSvg from '~web-core/lib/common/svgs/icons/interface/check.svg';

import {
  BedWindow,
  CreditCard,
  Eye,
  EyeHidden,
  House,
  HouseHandMoney,
  MoneyBag,
  Photo,
} from '~web-core/lib/common/svgs/icons/physical-objects';

import * as enums from './enums';

import styles from './IconRadioButtons.scss';

const iconToSvg = {
  [enums.Icons.BedWindow]: BedWindow,
  [enums.Icons.CreditCard]: CreditCard,
  [enums.Icons.Eye]: Eye,
  [enums.Icons.EyeHidden]: EyeHidden,
  [enums.Icons.House]: House,
  [enums.Icons.HouseMoney]: HouseHandMoney,
  [enums.Icons.MoneyBag]: MoneyBag,
  [enums.Icons.Photo]: Photo,
};

interface Option {
  value: string;
  icon: enums.Icons;
  subtitle: string;
  title: string;
}

interface InputProps {
  onChange: (value: string) => void;
  options: Option[];
  value?: string;
}

type Props =
  InputProps &
  WithUniqueIdProps;

class IconRadioButtons extends Component<Props> {
  static enums = enums;

  getMemoizedOptions = memoize((options: Option[]) => _.map(options, option => ({
    onClick: this.handleClick.bind(null, option.value),
    ...option,
  })));

  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(nextProps, this.props);
  }

  handleClick = (value: string) => this.props.onChange(value);

  render() {
    return (
      <div styleName="icon-radio-buttons">
        {_.map(this.getMemoizedOptions(this.props.options), (option) => {
          const Icon = iconToSvg[option.icon];
          return (
            <button
              key={`${option.value}-${this.props.uniqueId}`}
              type="button"
              onClick={option.onClick}
              className={classNames({
                [styles['icon-radio-button']]: true,
                [styles['icon-radio-button--active']]: option.value === this.props.value,
              })}>
              <input
                name={`icon-radio-button-${this.props.uniqueId}`}
                styleName="icon-radio-button__input"
                type="radio"
                value={option.value}
              />
              <Icon styleName="icon-radio-button__svg" />
              <div styleName="icon-radio-button__text">
                <Text
                  content={option.title}
                  isEmphasized={true}
                />
                <Text
                  color={Text.enums.Colors.Secondary}
                  content={option.subtitle}
                  size={Text.enums.Sizes.Small}
                />
              </div>
              {option.value === this.props.value ? (
                <CheckSvg styleName="icon-radio-button__check" />
              ) : null}
            </button>
          );
        })}
      </div>
    );
  }
}

IconRadioButtons.enums = enums;

export default withUniqueId(withStyles(styles)(IconRadioButtons));
