import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import * as enums from './enums';

import styles from './StatusLight.scss';

interface Props {
  status: enums.Statuses;
}

function StatusLight(props: Props) {
  return (
    <div
      className={classNames({
        [styles['status-light']]: true,
        [styles[`status-light--status-${_.kebabCase(props.status)}`]]: props.status,
      })}
    />
  );
}

export default withStyles(styles)(StatusLight);
