import React, { Component } from 'react';
import _ from 'lodash';
import queryString, { ParsedQuery } from 'query-string';

import Modal from '~tools/react/components/Modal';
import Button from '~tools/react/components/Button';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import Spinner from '~tools/react/components/Spinner';

import PhotoEditorModalFilters from './components/PhotoEditorModalFilters';
import PhotoEditorModalView from './components/PhotoEditorModalView';
import PhotoEditorModalImage from './components/PhotoEditorModalImage';
import PhotoEditorModalConfirmation from './components/PhotoEditorModalConfirmation';

import * as enums from './enums';

import { Photo, Tag } from './types';

type Adjustment = ParsedQuery<string>;

interface Props {
  deletePhoto: () => void;
  isOpen: boolean;
  mediaTags: Tag[];
  onClose: (shouldRefresh?: boolean) => void;
  selectedPhoto: Photo | null;
  updatePhoto: (string, args: { tagUuids?: string[], adjustment?: Adjustment }) => Promise<any>;
}

interface State {
  adjustment: Adjustment;
  cacheTimestamp: number;
  isCloseConfirmationVisible: boolean;
  isDeleteConfirmationVisible: boolean;
  isLoading: boolean;
  selectedFilter: enums.Filters | null;
  selectedPhotoTag?: Tag | null;
}

class PhotoEditorModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      adjustment: {},
      cacheTimestamp: new Date().getTime(),
      isCloseConfirmationVisible: false,
      isDeleteConfirmationVisible: false,
      isLoading: false,
      selectedFilter: null,
      selectedPhotoTag: props.selectedPhoto ? _.head(props.selectedPhoto.tags) : null,
    };
  }

  handleCloseWithConfirmation = () => {
    if (_.isEmpty(this.state.adjustment)) {
      this.closeModal(false);
    } else {
      this.toggleCloseConfirmation();
    }
  };

  handleGoBack = () => this.setState({ selectedFilter: null });

  handleClickFilter = (filter: enums.Filters | null) => this.setState({ selectedFilter: filter });

  handleUpdateFilter = (key: string, val: string | number) =>
    this.setState({ adjustment: { ...this.state.adjustment, [key]: typeof val === 'number' ? _.toString(val) : val } });

  handleSetTag = async (tag: Tag) => {
    if (this.props.selectedPhoto) {
      await this.props.updatePhoto(_.get(this.props.selectedPhoto, 'uuid'), { tagUuids: [tag.uuid] });
      this.setState({ selectedPhotoTag: tag });
    }
  };

  handleSaveChanges = async () => {
    if (!_.isEmpty(this.state.adjustment)) {
      this.setState({ isLoading: true });
      await this.props.updatePhoto(_.get(this.props.selectedPhoto, 'uuid'), { adjustment: this.state.adjustment });
      this.setState({ cacheTimestamp: new Date().getTime() });
    }
    this.closeModal(!_.isEmpty(this.state.adjustment));
  }

  handleUpdateQuery = (query: string, cb: () => void | null) =>
    this.setState({ adjustment: queryString.parse(query) }, () => {
      if (cb) cb();
    });

  render() {
    return (
      <Modal onClose={this.props.onClose} isOpen={this.props.isOpen}>
        <Modal isOpen={this.state.isLoading}>
          <Spinner />
        </Modal>
        {this.state.isCloseConfirmationVisible ?
          <PhotoEditorModalConfirmation
            primaryAction={this.closeModal}
            secondaryAction={this.toggleCloseConfirmation}
            title="Are you sure you want to quit? You will lose your unsaved changes."
          /> : null}
        {this.state.isDeleteConfirmationVisible ?
          <PhotoEditorModalConfirmation
            primaryAction={this.props.deletePhoto}
            secondaryAction={this.toggleDeleteConfirmation}
            title="Are you sure you want to delete this photo?"
          /> : null}
        <PhotoEditorModalView isFaded={this.state.isLoading ||
          this.state.isCloseConfirmationVisible ||
          this.state.isDeleteConfirmationVisible}>
          <PhotoEditorModalFilters isActive={!!this.state.selectedFilter}>
            <PhotoEditorModalFilters.PhotoEditorModalFiltersSection title="Adjust">
              <PhotoEditorModalFilters.PhotoEditorModalFilterSlider
                defaultValue={0}
                filter={enums.Filters.Brightness}
                isActive={enums.Filters.Brightness === this.state.selectedFilter}
                label="%"
                maxValue={100}
                minValue={-100}
                onChange={this.handleUpdateFilter}
                onClickTab={this.handleClickFilter}
                primaryAction={this.handleGoBack}
                step={1}
                value={this.state.adjustment.bri as string}
              />
              <PhotoEditorModalFilters.PhotoEditorModalFilterSlider
                defaultValue={0}
                filter={enums.Filters.Contrast}
                isActive={enums.Filters.Contrast === this.state.selectedFilter}
                label="%"
                maxValue={100}
                minValue={-100}
                onChange={this.handleUpdateFilter}
                onClickTab={this.handleClickFilter}
                primaryAction={this.handleGoBack}
                step={1}
                value={this.state.adjustment.con as string}
              />
              <PhotoEditorModalFilters.PhotoEditorModalFilterSlider
                defaultValue={0}
                filter={enums.Filters.Saturation}
                isActive={enums.Filters.Saturation === this.state.selectedFilter}
                label="%"
                maxValue={100}
                minValue={-100}
                onChange={this.handleUpdateFilter}
                onClickTab={this.handleClickFilter}
                primaryAction={this.handleGoBack}
                step={1}
                value={this.state.adjustment.sat as string}
              />
              <PhotoEditorModalFilters.PhotoEditorModalFilterSlider
                defaultValue={0}
                filter={enums.Filters.Highlight}
                isActive={enums.Filters.Highlight === this.state.selectedFilter}
                label="%"
                maxValue={0}
                minValue={-100}
                onChange={this.handleUpdateFilter}
                onClickTab={this.handleClickFilter}
                primaryAction={this.handleGoBack}
                step={1}
                value={this.state.adjustment.high as string}
              />
              <PhotoEditorModalFilters.PhotoEditorModalFilterSlider
                defaultValue={0}
                filter={enums.Filters.Shadow}
                isActive={enums.Filters.Shadow === this.state.selectedFilter}
                label="%"
                maxValue={100}
                minValue={0}
                onChange={this.handleUpdateFilter}
                onClickTab={this.handleClickFilter}
                primaryAction={this.handleGoBack}
                step={1}
                value={this.state.adjustment.shad as string}
              />
            </PhotoEditorModalFilters.PhotoEditorModalFiltersSection>
            <PhotoEditorModalFilters.PhotoEditorModalFiltersSection title="Align">
              <PhotoEditorModalFilters.PhotoEditorModalFilterFlip
                isActive={enums.Filters.Flip === this.state.selectedFilter}
                onChange={this.handleUpdateFilter}
                onClickTab={this.handleClickFilter}
                primaryAction={this.handleGoBack}
                value={`${this.state.adjustment.flip}`}
              />
              <PhotoEditorModalFilters.PhotoEditorModalFilterSlider
                defaultValue={0}
                filter={enums.Filters.Rotate}
                isActive={enums.Filters.Rotate === this.state.selectedFilter}
                label="deg"
                maxValue={360}
                minValue={0}
                onChange={this.handleUpdateFilter}
                onClickTab={this.handleClickFilter}
                primaryAction={this.handleGoBack}
                step={1}
                value={this.state.adjustment.rot as string}
              />
            </PhotoEditorModalFilters.PhotoEditorModalFiltersSection>
            <PhotoEditorModalFilters.PhotoEditorModalFiltersSection title="Tag">
              <PhotoEditorModalFilters.PhotoEditorModalFilterTags
                isActive={enums.Filters.Tag === this.state.selectedFilter}
                mediaTags={this.props.mediaTags}
                onChange={this.handleSetTag}
                onClickTab={this.handleClickFilter}
                primaryAction={this.handleGoBack}
                value={this.state.selectedPhotoTag}
              />
            </PhotoEditorModalFilters.PhotoEditorModalFiltersSection>
            <Button onClick={this.handleSaveChanges} label="Save changes" width={Button.enums.Widths.Full} />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
            <Button
              color={Button.enums.Colors.White}
              label="Close"
              onClick={this.handleCloseWithConfirmation}
              style={Button.enums.Styles.Outline}
              width={Button.enums.Widths.Full}
            />
          </PhotoEditorModalFilters>
          <PhotoEditorModalImage
            onClickDelete={this.toggleDeleteConfirmation}
            onClose={this.handleCloseWithConfirmation}
            previewImageUrl={this.getImageUrl()}
            query={queryString.stringify(this.state.adjustment)}
            selectedPhoto={this.props.selectedPhoto}
            selectedPhotoTag={this.state.selectedPhotoTag}
            updateQuery={this.handleUpdateQuery}
          />
        </PhotoEditorModalView>
      </Modal>
    );
  }

  toggleDeleteConfirmation = () =>
    this.setState({ isDeleteConfirmationVisible: !this.state.isDeleteConfirmationVisible });

  toggleCloseConfirmation = () => this.setState({ isCloseConfirmationVisible: !this.state.isCloseConfirmationVisible });

  getImageUrl = () => `${_.get(this.props.selectedPhoto, 'url')}?${this.state.cacheTimestamp}&${queryString.stringify(this.state.adjustment)}`;

  closeModal = (refreshCache?: boolean) => {
    this.setState({
      adjustment: {},
      isLoading: false,
      isCloseConfirmationVisible: false,
      isDeleteConfirmationVisible: false,
      selectedFilter: null,
    }, () => {
      this.props.onClose(refreshCache);
    });
  }
}

export default PhotoEditorModal;
