import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import Alert from '~tools/react/components/Alert';
import Card from '~tools/react/components/Card';
import withStyles from '~tools/react/hocs/withStyles';

import { Listing, Verification } from '~web-manage/lib/common/scenes/Unit/scenes/UnitVerification/types';

import styles from './DashboardListingVerifyVerifiedState.scss';

interface Props {
  listing: Listing;
  verifications: Verification[];
}

function DashboardListingVerifyVerifiedState(props: Props) {
  const verification = _.find(props.verifications, v => !!v.verifiedAt);
  if (!verification || !verification.verifiedAt) return null;

  return (
    <Card>
      <Card.CardSection>
        <div styleName="dashboard-listing-verify-verified">
          <Alert
            color={Alert.enums.Colors.Blue}
            link={{
              label: 'View listing',
              path: `/listing/${props.listing.slug}`,
            }}
            title={`Congratulations, your listing was verified on ${moment(verification.verifiedAt).format('MM/DD/YYYY')}.`}
            description="Verified listings help ensure high quality applicants and increase search visibility."
          />
        </div>
      </Card.CardSection>
    </Card>
  );
}

export default withStyles(styles)(DashboardListingVerifyVerifiedState);
