import { CalendarTimeSlotEntityTypes } from '~tools/types/graphqlAdminSchema';
import withMutation from '~tools/react/graphql/withMutation';

import AttachTimeSlotsToCalendarMutation from './attachTimeSlotsToCalendar.gql';

interface Calendar {
  uuid: string;
  availableTimeSlots: {
    dayOfWeek: number;
    endsAt: number;
    startsAt: number;
    uuid: number;
  }[];
}

interface AttachTimeSlotsToCalendarInput {
  daysOfWeek: number[];
  eligibleEntityTypes: CalendarTimeSlotEntityTypes[];
  endingHour: number;
  startingHour: number;
}

interface Data {
  attachTimeSlotsToCalendar: Calendar;
}

interface Variables {
  uuid: string;
  input: AttachTimeSlotsToCalendarInput;
}

export { CalendarTimeSlotEntityTypes };

export interface AttachTimeSlotsToCalendarProps {
  attachTimeSlotsToCalendar: (uuid: string, input: AttachTimeSlotsToCalendarInput) => Promise<Calendar>;
}

export default withMutation<{}, Data, Variables, AttachTimeSlotsToCalendarProps>(AttachTimeSlotsToCalendarMutation, {
  props: props => ({
    attachTimeSlotsToCalendar: async (uuid, input) => {
      const res = await props.mutate({
        variables: {
          uuid,
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error attaching time slots to calendar.');

      return res.data.attachTimeSlotsToCalendar;
    },
  }),
});
