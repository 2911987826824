// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1qAndK{width:400px}@media screen and (max-width:1127px){._1qAndK{width:100%}}", ""]);
// Exports
exports.locals = {
	"checkout-view": "_1qAndK"
};
module.exports = exports;
