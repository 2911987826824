// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._APRKr8{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;max-width:450px;padding:16px 24px 24px;width:450px}._APRKr8>div{width:100%}", ""]);
// Exports
exports.locals = {
	"boost-payment-form": "_APRKr8"
};
module.exports = exports;
