import { CreateLeaseInviteInput } from '~tools/types/graphqlSchema';

import withMutation from '~tools/react/graphql/withMutation';

import CreateLeaseInviteMutation from './createLeaseInvite.gql';

interface LeaseInvite {
  createdAt: string;
  email: string;
  expiresAt: string | null;
  inviterAccount: {
    uuid: string;
  };
  lease: {
    uuid: string;
  };
  updatedAt: string;
  uuid: string;
}

interface Data {
  createLeaseInvite: LeaseInvite;
}

interface Variables {
  input: CreateLeaseInviteInput;
}

export interface CreateLeaseInviteProps {
  createLeaseInvite: (input: CreateLeaseInviteInput) => Promise<LeaseInvite>;
}

export default withMutation<{}, Data, Variables, CreateLeaseInviteProps>(CreateLeaseInviteMutation, {
  props: props => ({
    createLeaseInvite: async (input) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating invite.');

      return res.data.createLeaseInvite;
    },
  }),
});
