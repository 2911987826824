import React from 'react';

import Button from '~tools/react/components/Button';
import Text from '~tools/react/components/Text';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './VerificationRow.scss';

interface Props {
  title: string;
  isDisabled?: boolean;
  action?: {
    label: string;
    link: {
      path: string;
      shouldOpenNewTab?: boolean;
    };
  };
}

class VerificationRow extends React.PureComponent<Props> {
  render() {
    return (
      <div styleName="verification-row">
        <div styleName="verification-row__left">
          <Text
            content={this.props.title}
            size={Text.enums.Sizes.Small}
            color={this.props.isDisabled ? Text.enums.Colors.Secondary : undefined}
          />
        </div>
        {this.props.action ? (
          <div styleName="verification-row__buttons">
            <Button
              color={Button.enums.Colors.Gray}
              label={this.props.action.label}
              link={this.props.action.link}
              size={Button.enums.Sizes.XSmall}
              style={Button.enums.Styles.Outline}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(VerificationRow);
