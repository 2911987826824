import withMutation from '~tools/react/graphql/withMutation';
import { ServiceTypes } from '~tools/types/graphqlSchema';

import CreateServiceProviderMutation from './createServiceProvider.gql';

interface ServiceProvider {
  uuid: string;
  serviceType: ServiceTypes;
}

interface Response {
  createServiceProvider: ServiceProvider;
}

interface CreateServiceProviderInput {
  organizationUuid: string;
  serviceType: ServiceTypes;
  serviceAreaBoundingBox?: {
    points: {
      lat: number;
      long: number;
    }[];
  }
}

interface Variables {
  input: CreateServiceProviderInput;
}

export interface CreateServiceProviderProps {
  createServiceProvider: (input: CreateServiceProviderInput) => Promise<ServiceProvider>;
}

export default withMutation<{}, Response, Variables, CreateServiceProviderProps>(CreateServiceProviderMutation, {
  props: props => ({
    createServiceProvider: async (input: CreateServiceProviderInput) => {
      const res = await props.mutate({
        variables: { input },
      });

      if (!res || !res.data) throw new Error('Error creating service provider');
      if (!res.data.createServiceProvider) throw new Error('No createServiceProvider returned');

      return res.data.createServiceProvider;
    },
  }),
});
