// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._QDoo2m{border-right:1px solid #e6e8eb;padding:24px}._QDoo2m:last-of-type{border-right:none}", ""]);
// Exports
exports.locals = {
	"setting-card": "_QDoo2m"
};
module.exports = exports;
