export { Sizes } from '~tools/react/components/Text/enums';

export enum Colors {
  Blue = 'BLUE',
  BrandAdvancedPlan = 'BRAND_ADVANCED_PLAN',
  BrandBasicPlan = 'BRAND_BASIC_PLAN',
  BrandEnterprisePlan = 'BRAND_ENTERPRISE_PLAN',
  BrandLeaseholderPlan = 'BRAND_LEASEHOLDER_PLAN',
  BrandLitePlan = 'BRAND_LITE_PLAN',
  Gray = 'GRAY',
  Orange = 'ORANGE',
}

export enum Styles {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
}
