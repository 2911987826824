import Big from 'big.js';

export const convertBigToInt = (big: Big): number => {
  return Math.round(parseFloat(big.toFixed(2)));
};

export const getOrdinal = (number: number): string => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const val = number % 100;
  return number + (suffixes[(val - 20) % 10] || suffixes[val] || suffixes[0]);
};
