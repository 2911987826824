import classNames from 'classnames';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import withStyles from '~tools/react/hocs/withStyles';
import { ChevronRight } from '~web-core/lib/common/svgs/icons/directional';

import * as enums from './enums';
import styles from './Breadcrumbs.scss';

interface Props {
  items: {
    path?: string,
    label: string,
  }[],
  style?: enums.Styles,
}

function Breadcrumbs(props: Props) {
  return (
    <nav styleName={classNames({
      breadcrumbs: true,
      'breadcrumbs--compact': props.style === enums.Styles.Compact,
    })}>
      {_.map(props.items, (item, index) => {
        if (index < (props.items.length - 1)) {
          return (
            <Fragment key={`${item.label}-${index}`}>
              {item.path ? (
                <Link to={item.path}>{item.label}</Link>
              ) : item.label}
              <ChevronRight />
            </Fragment>
          );
        }

        if (item.path) {
          return <Link key={`${item.label}-${index}`} to={item.path}>{item.label}</Link>;
        }

        return <span key={`${item.label}-${index}`}>{item.label}</span>;
      })}
    </nav>
  );
}

export default withStyles(styles)(Breadcrumbs);
