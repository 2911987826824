// @flow
import 'moment-range';
import _ from 'lodash';
import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { canUseDOM } from 'exenv';

import withStyles from '~tools/react/hocs/withStyles';

import { ChevronLeft, ChevronRight } from '~web-core/lib/common/svgs/icons/directional';

import Cell from './components/Cell';

import * as enums from './enums';

import styles from './Calendar.scss';

type Props = {|
  initialView: $Values<typeof enums.Views>,
  maxValue?: moment,
  minValue?: moment,
  timezone?: String;
  onSelect: (value: moment, isDay?: boolean) => *,
  value: moment,
|};

type State = {|
  value: moment,
  view: $Values<typeof enums.Views>,
|};

class Calendar extends Component<Props, State> {
  static enums = enums;

  static defaultProps = {
    initialView: enums.Views.Days,
    timezone: canUseDOM ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'UTC',
  };

  state = {
    value: this.props.value || moment.tz(this.props.timezone),
    view: this.props.initialView,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleChangeValue = (value: moment) => {
    this.setState({ value });
  };

  handleClickToday = () => {
    this.handleSelectValue(moment.tz(this.props.timezone).startOf('day'));
  };

  handleKeyDown = (e: KeyboardEvent) => {
    const newValue = this.state.value.clone();
    const unitsPerRow = this.state.view === Calendar.enums.Views.Days ? 7 : 3;

    switch (e.keyCode) {
      case enums.KeyConstants.Left: {
        this.handleChangeValue(newValue.subtract(1, _.lowercase(this.state.view)));
        break;
      }
      case enums.KeyConstants.Right: {
        this.handleChangeValue(newValue.add(1, _.lowercase(this.state.view)));
        break;
      }
      case enums.KeyConstants.Up: {
        this.handleChangeValue(newValue.subtract(unitsPerRow, _.lowercase(this.state.view)));
        break;
      }
      case enums.KeyConstants.Down: {
        this.handleChangeValue(newValue.add(unitsPerRow, _.lowercase(this.state.view)));
        break;
      }
      case enums.KeyConstants.Enter: {
        if (this.state.view !== Calendar.enums.Views.Days) {
          this.setState({ view: this.state.view - 1 });
          break;
        }

        this.props.onSelect(this.state.value);
        break;
      }
      default:
        break;
    }
  };

  handleNavigateLeft = () => {
    switch (this.state.view) {
      case enums.Views.Days: {
        this.setState({
          value: this.state.value.subtract(1, 'months'),
        });
        break;
      }
      case enums.Views.Months: {
        this.setState({
          value: this.state.value.subtract(1, 'years'),
        });
        break;
      }
      case enums.Views.Years:
      default: {
        this.setState({
          value: this.state.value.subtract(10, 'years'),
        });
      }
    }
  };

  handleNavigateRight = () => {
    switch (this.state.view) {
      case enums.Views.Days: {
        this.setState({
          value: this.state.value.add(1, 'months'),
        });
        break;
      }
      case enums.Views.Months: {
        this.setState({
          value: this.state.value.add(1, 'years'),
        });
        break;
      }
      case enums.Views.Years:
      default: {
        this.setState({
          value: this.state.value.add(10, 'years'),
        });
      }
    }
  };

  handleSelectValue = (selectedValue: moment) => {
    if (this.props.minValue && selectedValue.isBefore(this.props.minValue)) return;
    if (this.props.maxValue && selectedValue.isAfter(this.props.maxValue)) return;

    this.handleChangeValue(selectedValue);
    if (this.state.view === enums.Views.Days) {
      this.props.onSelect(selectedValue, true);
      return;
    }
    this.props.onSelect(selectedValue);
    this.goToPreviousView();
  };

  render() {
    let cellFormat;
    let cells = [];
    let headerCopy = '';
    switch (this.state.view) {
      case enums.Views.Days: {
        cellFormat = Cell.enums.Formats.Day;
        cells = this.getDays();
        headerCopy = this.state.value.format('MMMM (YYYY)');
        break;
      }
      case enums.Views.Months: {
        cellFormat = Cell.enums.Formats.Month;
        cells = this.getMonths();
        headerCopy = this.state.value.year();
        break;
      }
      case enums.Views.Years:
      default: {
        cellFormat = Cell.enums.Formats.Year;
        cells = this.getYears();
        headerCopy = [cells[0].label, cells[cells.length - 1].label].join('-');
      }
    }

    return (
      <div styleName="calendar">
        <header styleName="calendar__header">
          <button type="button" onClick={this.handleNavigateLeft}>
            <ChevronLeft />
          </button>
          <button
            type="button"
            disabled={this.state.view === enums.Views.Years}
            onClick={this.goToNextView}>
            {headerCopy}
          </button>
          <button type="button"onClick={this.handleNavigateRight}>
            <ChevronRight />
          </button>
        </header>
        <div
          className={classNames({
            [styles.calendar__grid]: true,
            [styles['calendar__grid--days']]: this.state.view === enums.Views.Days,
          })}>
          {this.state.view === enums.Views.Days ? _.map(
            _.values(enums.DayTitles), title => (
              <span
                key={title}
                styleName="title-cell">
                {title}
              </span>
            ),
          ) : null}
          {_.map(cells, (item) => {
            let cellTheme = '';
            if (item.curr) cellTheme = Cell.enums.Styles.Active;
            else if (item.next || item.prev) cellTheme = Cell.enums.Styles.Disabled;

            return (
              <Cell
                format={cellFormat}
                isDisabled={this.props.minValue ? item.value.isBefore(this.props.minValue) : false}
                isHighlighted={item.today}
                key={item.value.format('MM-DD-YYYY')}
                label={item.label}
                onClick={this.handleSelectValue}
                style={cellTheme} // eslint-disable-line
                value={item.value}
              />
            );
          })}
        </div>
        <button
          type="button"
          onClick={this.handleClickToday}
          styleName="calendar__footer-button">
          Today
        </button>
      </div>
    );
  }

  getDays = () => {
    const now = this.props.value || moment.tz(this.props.timezone);
    const currDay = now.date();
    const days = [];
    const end = now.clone().endOf('month').weekday(6);
    const maxDate = this.props.maxValue;
    const minDate = this.props.minValue;
    const month = now.month();
    const start = now.clone().startOf('month').weekday(0);
    const today = moment.tz(this.props.timezone);
    const year = now.year();

    moment
      .tz(this.props.timezone)
      .range(start, end)
      .by('days', (day) => {
        days.push({
          curr: day.date() === currDay && day.month() === month,
          disabled: day.isBefore(minDate) || day.isAfter(maxDate),
          label: day.format('D'),
          next: day.month() > month || day.year() > year,
          prev: (day.month() < month && !(day.year() > year)) || day.year() < year,
          today: day.date() === today.date() && day.month() === today.month(),
          value: day,
        });
      });

    return days;
  };

  getMonths = () => {
    const now = this.props.value;
    const month = now.month();

    return _.map(moment.monthsShort(), (item, i) => ({
      curr: i === month,
      label: item,
      value: now.clone().set({ month: i }),
    }));
  };

  getYears = () => {
    const now = this.props.value;
    const start = now.clone().subtract(5, 'year');
    const end = now.clone().add(6, 'year');
    const currYear = now.year();
    const items = [];

    moment
      .tz(this.props.timezone)
      .range(start, end)
      .by('years', (year) => {
        items.push({
          curr: currYear === year.year(),
          label: year.format('YYYY'),
          value: year,
        });
      });

    return items;
  };

  goToNextView = () => this.setState({ view: this.state.view + 1 });
  goToPreviousView = () => this.setState({ view: this.state.view - 1 });
}

export default withStyles(styles)(Calendar);
