// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1qAfoJ{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;-webkit-box-pack:center;justify-content:center}._1qAfoJ>div{width:100%}._1qAfoJ ._ernmYD{height:200px;width:200px}._1qAfoJ h1{color:#182029;font-size:20px;font-weight:700;margin:0 0 32px}", ""]);
// Exports
exports.locals = {
	"dashboard-listing-verify-verified": "_1qAfoJ",
	"verified-listing": "_ernmYD"
};
module.exports = exports;
