import React, { Fragment, PureComponent } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import withStyles from '~tools/react/hocs/withStyles';

import Link from '~tools/react/components/utility/Link';
import Portal from '~tools/react/components/utility/Portal';

import { ChevronRight } from '~web-core/lib/common/svgs/icons/directional';

import BookmarkSvg from './svgs/bookmark.svg';
import ImageSvg from './svgs/image.svg';
import MessageSvg from './svgs/message.svg';
import QuestionSvg from './svgs/question.svg';
import SavedSearchSvg from './svgs/savedsearch.svg';

import * as enums from './enums';

import styles from './Toast.scss';

const enumToSvg = {
  [enums.Icons.Bookmark]: BookmarkSvg,
  [enums.Icons.Image]: ImageSvg,
  [enums.Icons.Message]: MessageSvg,
  [enums.Icons.Question]: QuestionSvg,
  [enums.Icons.SavedSearch]: SavedSearchSvg,
};

interface BaseProps {
  onClose: () => void;
  isOpen: boolean;
  title: string;
  subtitle?: string;
  icon: enums.Icons;
}

interface ButtonProps extends BaseProps {
  onClick?: (e: React.MouseEvent) => void;
}

interface LinkProps extends BaseProps {
  link?: {
    path: string;
  };
}

interface State {
  isOpen: boolean;
}

type Props = ButtonProps & LinkProps;

class Toast extends PureComponent<Props, State> {
  static enums = enums;

  state = {
    isOpen: false,
  };

  timeout: NodeJS.Timeout | null = null;

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isOpen && !this.props.isOpen) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this.setState({ isOpen: false }), 500);
    } else if (!prevProps.isOpen && this.props.isOpen) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.props.onClose, 3000);
      this.setState({ isOpen: true });
    }
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  render() {
    if (!this.props.isOpen && !this.state.isOpen) return null;

    const Icon = enumToSvg[this.props.icon];
    const children = (
      <Fragment>
        <div styleName="toast__icon">
          <Icon />
        </div>
        <div styleName="toast__text">
          <h5>{this.props.title}</h5>
          {this.props.subtitle ? <p>{this.props.subtitle}</p> : null}
        </div>
        {this.props.onClick || this.props.link ? (
          <span styleName="toast__right">
            <ChevronRight />
          </span>
        ) : null}
      </Fragment>
    );

    return (
      <Portal>
        <TransitionGroup styleName="animation-wrapper">
          {this.state.isOpen && this.props.isOpen ? (
            <CSSTransition
              key="toast"
              classNames={{
                enter: styles['fade-enter'],
                enterActive: styles['fade-enter-active'],
                exit: styles['fade-exit'],
                exitActive: styles['fade-exit-active'],
              }}
              timeout={500}>
              {'link' in this.props && this.props.link ? (
                <Link
                  key="toast"
                  shouldOpenNewTab
                  styleName="toast"
                  to={this.props.link.path}>
                  {children}
                </Link>
              ) : (
                <button
                  key="toast"
                  onClick={this.props.onClick}
                  styleName="toast">
                  {children}
                </button>
              )}
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </Portal>
    );
  }
}

export default withStyles(styles)(Toast);
