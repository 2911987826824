// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._DBDMbZ{border-radius:5px;border:1px solid #e6e8eb;padding:10px 15px}", ""]);
// Exports
exports.locals = {
	"bordered-view": "_DBDMbZ"
};
module.exports = exports;
