import React, { Component } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import User from './svgs/user.svg';
import Cross from './svgs/x.svg';

import styles from './BoostModalHeader.scss';

interface Props {
  headline: string;
  onClose: () => void;
  subheadline: string;
}

interface State {
  viewCount: number;
}

class BoostModalHeader extends Component<Props, State> {
  interval?: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);

    this.state = {
      viewCount: 80 + Math.floor(50 * Math.random()),
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.handleIncrementView, 1000);
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  handleIncrementView = () => {
    const randomNumber = Math.round(Math.random() * 5);
    const viewCount = this.state.viewCount + randomNumber;

    this.setState({ viewCount });
  }

  render() {
    return (
      <div styleName="boost-modal-header">
        <button styleName="boost-modal-header__close" onClick={this.props.onClose}>
          <Cross />
        </button>
        <div styleName="boost-modal-header__users">
          <div styleName="boost-modal-header__user"><User /></div>
          <div styleName="boost-modal-header__user"><User /></div>
          <div styleName="boost-modal-header__user"><User /></div>
          <div styleName="boost-modal-header__user"><User /></div>
          <div styleName="boost-modal-header__user"><User /></div>
          <div styleName="boost-modal-header__user"><User /></div>
          <div styleName="boost-modal-header__user"><User /></div>
          <div styleName="boost-modal-header__user"><User /></div>
          <div styleName="boost-modal-header__user"><User /></div>
        </div>
        <div styleName="boost-modal-header__user-overlay" />
        <div styleName="boost-modal-header__headline">
          <h2>{this.props.headline}</h2>
          <div styleName="boost-modal-header__views">
            <h1>{this.state.viewCount}</h1>
            <h2>Views</h2>
          </div>
        </div>
        <h3>{this.props.subheadline}</h3>
      </div>
    );
  }
}

export default withStyles(styles)(BoostModalHeader);
