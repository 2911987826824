// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._P9W8OX{background-color:#ff5167;border-radius:50%;box-shadow:0 0 4px #ff5167;display:inline-block;height:8px;margin-right:10px;margin-top:2px;width:8px}._P9W8OX._krP3vv{background-color:#23ce99;box-shadow:0 0 4px #23ce99}", ""]);
// Exports
exports.locals = {
	"status-circle": "_P9W8OX",
	"active": "_krP3vv"
};
module.exports = exports;
