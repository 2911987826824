import classNames from 'classnames';
import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './BoostModalView.scss';

interface Props {
  children: ReactNode;
  isCheckout: boolean;
}

function BoostModalView(props: Props) {
  return (
    <div
      className={classNames({
        [styles['boost-modal']]: true,
        [styles['boost-modal--checkout']]: props.isCheckout,
      })}>
      {props.children}
    </div>
  );
}

export default withStyles(styles)(BoostModalView);
