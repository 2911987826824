import withMutation from '~tools/react/graphql/withMutation';

import DeleteServicePreferenceMutation from './deleteServicePreference.gql';

interface Data {
  deleteServicePreference: boolean;
}

interface Variables {
  uuid: string;
}

export interface DeleteServicePreferenceProps {
  deleteServicePreference: (uuid: string) => Promise<boolean>;
}

export default withMutation<{}, Data, Variables, DeleteServicePreferenceProps>(DeleteServicePreferenceMutation, {
  props: props => ({
    deleteServicePreference: async (uuid: string) => {
      const res = await props.mutate({
        variables: { uuid },
      });
      if (!res || !res.data) throw new Error('Error deleting service preference.');

      return res.data.deleteServicePreference;
    },
  }),
});
