import withMutation from '~tools/react/graphql/withMutation';

import CreatePersonMutation from './createPerson.gql';

interface Person {
  bio?: string;
  email?: string;
  firstName: string;
  lastName?: string;
  uuid: string;
  website?: string;
}

interface CreatePersonInput {
  bio?: string;
  email?: string;
  firstName: string;
  lastName?: string;
  website?: string;
}

interface Data {
  createPerson: Person;
}

interface Variables {
  input: CreatePersonInput;
}

export interface CreatePersonProps {
  createPerson: (input: CreatePersonInput) => Promise<Person>;
}

export default withMutation<{}, Data, Variables, CreatePersonProps>(CreatePersonMutation, {
  props: props => ({
    createPerson: async (input) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating person.');

      return res.data.createPerson;
    }
  }),
});
