import React from 'react';

import { AddressUnitRoomTypes, ConditionReportTypes } from '~tools/types/graphqlSchema';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Tag from '~tools/react/components/Tag';
import Heading from '~tools/react/components/Heading';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import Badge from '~tools/react/components/Badge';

import * as tagEnums from '~tools/react/components/Tag/enums';

import { ConditionReport } from '../../../../types';
import ReportRow from '../ReportRow';

interface Props {
  room: {
    uuid: string;
    name: string;
    roomType: AddressUnitRoomTypes;
  };
  reports: ConditionReport[];
  onImageLoad: () => void;
}

function InspectionRoom(props: Props) {
  return (
    <div>
      <Heading
        isMarkdown={true}
        content={`*${props.room.name}*`}
        font={Heading.enums.Fonts.Secondary}
        size={Heading.enums.Sizes.XSmall}
      />
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
      {!props.reports.length ? (
        <>
          <HorizontalRule />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
            <Badge icon={Badge.enums.Icons.Check} />
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
            <Text content="No issues reported for this room." />
          </Row>
        </>
      ) : null}

      {props.reports.map(report => {
        let displayReport;
        let tag: {
          color: tagEnums.Colors;
          icon?: tagEnums.Icons;
          label: string;
        };

        if (report.fixedConditionReport) {
          displayReport = report.fixedConditionReport;
          tag = {
            color: Tag.enums.Colors.Green,
            icon: Tag.enums.Icons.Check,
            label: 'Resolved',
          };
        } else if (report.originConditionReport) {
          displayReport = report.originConditionReport;
          tag = {
            color: Tag.enums.Colors.Red,
            icon: Tag.enums.Icons.Cross,
            label: 'Open',
          };
        } else {
          displayReport = report;
          if (report.reportType === ConditionReportTypes.DAMAGE) {
            tag = {
              color: Tag.enums.Colors.Red,
              icon: Tag.enums.Icons.Cross,
              label: 'Open',
            };
          } else {
            tag = {
              color: Tag.enums.Colors.Gray,
              label: 'Info',
            };
          }
        }

        return (
          <div key={report.uuid}>
            <HorizontalRule />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
            <ReportRow
              displayReport={displayReport}
              requiredServiceType={report.requiredServiceType}
              tag={tag}
              onImageLoad={props.onImageLoad}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          </div>
        );
      })}
    </div>
  );
}

export default InspectionRoom;
