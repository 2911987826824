import withMutation from '~tools/react/graphql/withMutation';
import { CreateStripeFinancialConnectionSessionInput } from '~tools/types/graphqlSchema';

import CreateStripeFinancialConnectionSessionMutation from './createStripeFinancialConnectionSession.gql';

interface Data {
  createStripeFinancialConnectionSession: string;
}

interface Variables {
  input: CreateStripeFinancialConnectionSessionInput;
}

export interface CreateStripeFinancialConnectionSessionProps {
  createStripeFinancialConnectionSession: (
    input: CreateStripeFinancialConnectionSessionInput,
  ) => Promise<string>;
}

export default withMutation<{}, Data, Variables, CreateStripeFinancialConnectionSessionProps>(
  CreateStripeFinancialConnectionSessionMutation,
  {
    props: (props) => ({
      createStripeFinancialConnectionSession: async (
        input: CreateStripeFinancialConnectionSessionInput,
      ) => {
        const res = await props.mutate({
          variables: {
            input,
          },
        });
        if (!res || !res.data)
          throw new Error('Error creating Stripe financial connection session.');

        return res.data.createStripeFinancialConnectionSession;
      },
    }),
  },
);
