import withMutation from '~tools/react/graphql/withMutation';
import { PhoneNumberTypes } from '~tools/types/graphqlSchema';

import AttachPhoneNumberToPerson from './attachPhoneNumberToPerson.gql';

interface PhoneNumber {
  phoneNumber: string;
  type: PhoneNumberTypes;
  uuid: string;
}

interface AttachPhoneNumberToPerson {
  personUuid: string;
}

interface Data {
  attachPhoneNumberToPerson: PhoneNumber;
}

interface Variables {
  input: AttachPhoneNumberToPerson;
  uuid: string;
}

export interface AttachPhoneNumberToPersonProps {
  attachPhoneNumberToPerson: (uuid: string, input: AttachPhoneNumberToPerson) => Promise<PhoneNumber>;
}

export default withMutation<{}, Data, Variables, AttachPhoneNumberToPersonProps>(AttachPhoneNumberToPerson, {
  props: props => ({
    attachPhoneNumberToPerson: async (uuid, input) => {
      const res = await props.mutate({
        variables: {
          input,
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error attaching phone number.');

      return res.data.attachPhoneNumberToPerson;
    }
  }),
});
