// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._O0G6EW{position:relative}._O0G6EW ._6OAEeX{-webkit-box-align:center;align-items:center;background:-webkit-gradient(linear,right top, left top,from(#fff),to(hsla(0,0%,100%,0)));background:linear-gradient(270deg,#fff,hsla(0,0%,100%,0));border:none;bottom:0;display:-webkit-box;display:flex;height:36px;-webkit-box-pack:center;justify-content:center;opacity:0;padding-left:24px;position:absolute;right:8px;-webkit-transform:scale(.36);transform:scale(.36);-webkit-transform-origin:right;transform-origin:right;-webkit-transition:all .2s cubic-bezier(.215,.61,.355,1);transition:all .2s cubic-bezier(.215,.61,.355,1)}._O0G6EW ._6OAEeX svg circle,._O0G6EW ._6OAEeX svg path{stroke:#273444}._O0G6EW ._6OAEeX._x28AbZ{opacity:1}._O0G6EW ._nrBvpE{background:#fff;border-radius:5px;bottom:-1px;box-shadow:0 9px 25px -6px rgba(0,0,0,.1);display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;overflow:hidden;position:absolute;-webkit-transform:translateY(100%);transform:translateY(100%);-webkit-transition:all .2s cubic-bezier(.215,.61,.355,1);transition:all .2s cubic-bezier(.215,.61,.355,1);width:100%;z-index:1}._O0G6EW._BX0rPX input{margin:12px}._O0G6EW._BX0rPX ._nrBvpE{border-top:1px solid #e6e8eb;bottom:auto;max-height:300px;overflow:auto;position:relative;-webkit-transform:none;transform:none}", ""]);
// Exports
exports.locals = {
	"form-typeahead": "_O0G6EW",
	"form-typeahead__spinner": "_6OAEeX",
	"form-typeahead__spinner--visible": "_x28AbZ",
	"form-typeahead__view": "_nrBvpE",
	"form-typeahead--style-dropdown": "_BX0rPX"
};
module.exports = exports;
