import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './HelloSignModalView.scss';

function HelloSignModalView(props) {
  return (
    <div styleName="hello-sign-modal" id="hello-sign-container">
      {props.children}
    </div>
  );
}

export default withStyles(styles)(HelloSignModalView);
