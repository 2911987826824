// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-webkit-keyframes _5aAmO2{0%{-webkit-transform:scale(0);transform:scale(0)}to{-webkit-transform:scale(1);transform:scale(1)}}@keyframes _5aAmO2{0%{-webkit-transform:scale(0);transform:scale(0)}to{-webkit-transform:scale(1);transform:scale(1)}}._drDMJW{-webkit-box-align:center;align-items:center;background:#eff2f7;border-radius:4px;display:-webkit-box;display:flex;margin:8px 4px 0;max-width:100%;min-height:30px;padding:2px 6px}._drDMJW ._x28zmn{height:18px;margin-right:5px;min-width:18px;stroke:#53d391;width:18px}._drDMJW ._7RAawR{color:#182029;font-size:14px;font-weight:300;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}@media screen and (max-width:1127px){._drDMJW ._7RAawR{font-size:12px}}._drDMJW ._br0wvZ{color:#8492a6;font-size:14px;font-weight:100;margin-right:auto;padding-left:5px;white-space:nowrap}@media screen and (max-width:1127px){._drDMJW ._br0wvZ{font-size:12px}}._drDMJW ._32APPX{color:#8492a6;font-size:14px;font-weight:300}._drDMJW ._GN6O5r,._drDMJW ._32APPX{border:none;border-left:1px solid #d3dce6;display:-webkit-box;display:flex;margin:0 0 0 8px;padding:0 0 0 8px}._drDMJW ._GN6O5r ._5aAj1K{height:14px;stroke:#8492a6;width:14px}._drDMJW ._GN6O5r ._5aAj1K path{stroke-width:3px}", ""]);
// Exports
exports.locals = {
	"attachment": "_drDMJW",
	"attachment__check": "_x28zmn",
	"attachment__filename": "_7RAawR",
	"attachment__filesize": "_br0wvZ",
	"attachment__progress": "_32APPX",
	"attachment__close": "_GN6O5r",
	"attachment__cross": "_5aAj1K",
	"animateIn": "_5aAmO2"
};
module.exports = exports;
