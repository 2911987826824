import loadable from '@loadable/component';
import _ from 'lodash';

import * as enums from './enums';

const LoadableTimelineBadge = loadable(() => import(/* webpackChunkName: "timeline-badge" */'./TimelineBadge'));
const TimelineBadge = _.assign(LoadableTimelineBadge, { enums });

export default TimelineBadge;
export * from './TimelineBadge';
