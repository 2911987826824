import React, { ReactNode } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import withStyles from '~tools/react/hocs/withStyles';

import * as enums from './enums';
import styles from './Grid.scss';

type Props = {
  children: ReactNode;
  columns?: enums.Columns;
  spacing?: enums.Spacing;
};

function Grid(props: Props) {
  const spacing = props.spacing || enums.Spacing.Small;
  return (
    <section
      styleName={classNames({
        grid: true,
        [`grid--columns-${_.kebabCase(props.columns)}`]: props.columns,
        [`grid--spacing-${_.kebabCase(spacing)}`]: spacing,
      })}
    >
      {props.children}
    </section>
  );
}

export default withStyles(styles)(Grid);
