// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._7RA1z2{display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row}@media screen and (max-width:1127px){._7RA1z2{-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column}}", ""]);
// Exports
exports.locals = {
	"data-row": "_7RA1z2"
};
module.exports = exports;
