import withMutation from '~tools/react/graphql/withMutation';
import { CreateStripeSetupIntentInput } from '~tools/types/graphqlSchema';

import CreateStripeSetupIntentMutation from './createStripeSetupIntent.gql';

interface Data {
  createStripeSetupIntent: string;
}

interface Variables {
  input: CreateStripeSetupIntentInput;
}

export interface CreateStripeSetupIntentProps {
  createStripeSetupIntent: (input: CreateStripeSetupIntentInput) => Promise<string>;
}

export default withMutation<{}, Data, Variables, CreateStripeSetupIntentProps>(
  CreateStripeSetupIntentMutation,
  {
    props: (props) => ({
      createStripeSetupIntent: async (input: CreateStripeSetupIntentInput) => {
        const res = await props.mutate({
          variables: {
            input,
          },
        });
        if (!res || !res.data) throw new Error('Error creating Stripe setup intent.');

        return res.data.createStripeSetupIntent;
      },
    }),
  },
);
