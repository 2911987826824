import React, { Fragment, PureComponent } from 'react';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withAuth, { AuthProps } from '~tools/react/hocs/withAuth';
import withPaymentSubscription, { PaymentSubscriptionProps } from '~tools/react/hocs/withPaymentSubscription';

import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import DashboardAccordion from '~tools/react/components/DashboardAccordion';
import GenericLoadingVisual from '~tools/react/components/GenericLoadingVisual';
import Heading from '~tools/react/components/Heading';
// import HorizontalRule from '~tools/react/components/HorizontalRule';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
// import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
// import ArrowLink from '~tools/react/components/ArrowLink';

import BrandedBackground from './components/BrandedBackground';
// import GetStartedSection from './components/GetStartedSection';

import query from './OnboardingCard.gql';

interface Viewer {
  firstName: string;
  payoutAccounts: { id: string; }[];
  stripeConnectAccount: {
    id: string;
    payoutsEnabled: boolean;
    requirements: {
      currentlyDue: string[];
      disabledReason: string;
      pastDue: string[];
    };
  } | null;
  uuid: string;
}

interface QueryProps {
  isLoading: boolean;
  viewer: Viewer | null;
}

type Props =
  QueryProps &
  AuthProps &
  PaymentSubscriptionProps;

class OnboardingCard extends PureComponent<Props> {
  render() {
    const viewer = this.props.viewer;
    if (!viewer || this.props.isLoading) {
      return (
        <Card>
          <Card.CardSection>
            <GenericLoadingVisual />
          </Card.CardSection>
        </Card>
      );
    }

    const isConnectAccountActivated =
      viewer.stripeConnectAccount &&
      (
        !viewer?.stripeConnectAccount?.requirements.disabledReason ||
        (
          viewer?.stripeConnectAccount?.requirements.disabledReason === 'requirements.past_due' &&
          viewer?.stripeConnectAccount?.requirements.pastDue.length === 1 &&
          viewer?.stripeConnectAccount?.requirements.pastDue[0] === 'external_account'
        )
      );
    const hasPayoutAccount = !!viewer.payoutAccounts.length;

    return (
      <Fragment>
        <div style={{ position: 'relative' }}>
          <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
            <div style={{ width: '85%', zIndex: 1 }}>
              <Heading
                content="*Almost there, just add your payout details*"
                font={Heading.enums.Fonts.Secondary}
                isMarkdown
                size={Heading.enums.Sizes.Medium}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
              <Text
                content="Connect a payout account and tell us a bit about your business to activate your account"
                size={Text.enums.Sizes.XLarge}
                isEmphasized
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              <Card
                overflow={Card.enums.OverflowValues.Hidden}
                shadow={Card.enums.Shadows.Small}
                hasMargin={false}>
                <Card.CardSection
                  spacing={Card.CardSection.enums.Spacing.None}>
                  <DashboardAccordion initiallyOpenItemIndex={isConnectAccountActivated ? 1 : 0}>
                    <DashboardAccordion.DashboardAccordionItem
                      status={
                        isConnectAccountActivated ?
                          DashboardAccordion.DashboardAccordionItem.enums.Statuses.Completed :
                          undefined
                      }
                      tag={{
                        color: isConnectAccountActivated ?
                          DashboardAccordion.DashboardAccordionItem.enums.TagColors.Green :
                          DashboardAccordion.DashboardAccordionItem.enums.TagColors.Gray,
                        label: isConnectAccountActivated ? 'Complete' : 'Required',
                      }}
                      title="Add your business details">
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                      <Text
                        content="To process rent payouts we need some details about your business."
                        size={Text.enums.Sizes.Small}
                      />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
                      <Button
                        color={Button.enums.Colors.Blue}
                        icon={Button.enums.Icons.ExternalLink}
                        label="Add business details"
                        link={{
                          path: `/outbound/stripe/onboarding`,
                          state: {
                            from: '/',
                          },
                        }}
                        size={Button.enums.Sizes.XSmall}
                      />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                    </DashboardAccordion.DashboardAccordionItem>
                    <DashboardAccordion.DashboardAccordionItem
                      isLocked={!isConnectAccountActivated}
                      status={
                        hasPayoutAccount ?
                          DashboardAccordion.DashboardAccordionItem.enums.Statuses.Completed : undefined
                      }
                      tag={{
                        color: hasPayoutAccount ?
                          DashboardAccordion.DashboardAccordionItem.enums.TagColors.Green :
                          DashboardAccordion.DashboardAccordionItem.enums.TagColors.Gray,
                        label: hasPayoutAccount ? 'Complete' : 'Required',
                      }}
                      title="Add an account for payouts">
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                      <Text
                        content="We'll pay out your received your rent payments to your chosen account. This can be changed later."
                        size={Text.enums.Sizes.Small}
                      />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                      <Button
                        color={Button.enums.Colors.Blue}
                        icon={Button.enums.Icons.ChevronRight}
                        label="Add a payout account"
                        link={{
                          path: '/settings/payouts',
                        }}
                        size={Button.enums.Sizes.XSmall}
                      />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                    </DashboardAccordion.DashboardAccordionItem>
                  </DashboardAccordion>
                </Card.CardSection>
              </Card>
            </div>
            <div style={{ zIndex: 0, overflow: 'hidden' }}>
              <BrandedBackground top="0px" right="14px" />
            </div>
          </Row>
        </div>
        {/* <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXLarge} />
        <GetStartedSection>
          <div style={{ width: '85%', zIndex: 1 }}>
            <Heading
              content="Optimize your property for Caretaker"
              font={Heading.enums.Fonts.Secondary}
              size={Heading.enums.Sizes.Small}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
            <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
              <div style={{ width: '100%' }}>
                <Heading
                  content="Set up your systems"
                  font={Heading.enums.Fonts.Secondary}
                  size={Heading.enums.Sizes.XSmall}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <Card
                  backgroundColor={Card.enums.BackgroundColors.Transparent}
                  shadow={Card.enums.Shadows.None}>
                  <Card.CardSection>
                    <Heading
                      content="Planning a Smart Lock Installation"
                      font={Heading.enums.Fonts.Secondary}
                      size={Heading.enums.Sizes.XXSmall}
                    />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                    <Text
                      content="Choose the best access control for your property"
                      size={Text.enums.Sizes.Small}
                      isEmphasized
                    />
                  </Card.CardSection>
                </Card>
                <Card
                  backgroundColor={Card.enums.BackgroundColors.Transparent}
                  shadow={Card.enums.Shadows.None}>
                  <Card.CardSection>
                    <Heading
                      content="Installing Smart Locks"
                      font={Heading.enums.Fonts.Secondary}
                      size={Heading.enums.Sizes.XXSmall}
                    />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                    <Text
                      content="Steps for ordering and installing smart locks"
                      size={Text.enums.Sizes.Small}
                      isEmphasized
                    />
                  </Card.CardSection>
                </Card>
              </div>
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Medium} />
              <div style={{ width: '100%' }}>
                <Heading
                  content="Automate home maintenance"
                  font={Heading.enums.Fonts.Secondary}
                  size={Heading.enums.Sizes.XSmall}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <Card
                  backgroundColor={Card.enums.BackgroundColors.Transparent}
                  shadow={Card.enums.Shadows.None}>
                  <Card.CardSection>
                    <Heading
                      content="Planning a Smart Lock Installation"
                      font={Heading.enums.Fonts.Secondary}
                      size={Heading.enums.Sizes.XXSmall}
                    />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                    <Text
                      content="Choose the best access control for your property"
                      size={Text.enums.Sizes.Small}
                      isEmphasized
                    />
                  </Card.CardSection>
                </Card>
                <Card
                  backgroundColor={Card.enums.BackgroundColors.Transparent}
                  shadow={Card.enums.Shadows.None}>
                  <Card.CardSection>
                    <Heading
                      content="Installing Smart Locks"
                      font={Heading.enums.Fonts.Secondary}
                      size={Heading.enums.Sizes.XXSmall}
                    />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                    <Text
                      content="Steps for ordering and installing smart locks"
                      size={Text.enums.Sizes.Small}
                      isEmphasized
                    />
                  </Card.CardSection>
                </Card>
              </div>
            </Row>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
            <Row
              flexBehavior={Row.enums.FlexBehaviors.Default}>
              <div style={{ marginLeft: 'auto' }} />
              <ArrowLink
                link={{
                  path: '/manual',
                }}
                label="Learn about the Caretaker platform"
                color={ArrowLink.enums.Colors.Blue}
                size={ArrowLink.enums.Sizes.Medium}
              />
            </Row>
          </div>
        </GetStartedSection> */}
        <VerticalSpacing
          quantity={2}
          size={VerticalSpacing.enums.Sizes.Large}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
      </Fragment>
    );
  }
}

interface Response {
  viewer: Viewer;
}

export default compose(
  withAuth,
  withPaymentSubscription,
  withQuery<AuthProps, Response, {}, QueryProps>(query, {
    options: props => ({
      skip: !props.isLoggedIn,
    }),
    props: props => ({
      isLoading: props.loading,
      viewer: props.data?.viewer || null,
    }),
  }),
)(OnboardingCard);
