import dayjs, { Dayjs } from 'dayjs';
import moment, { Moment } from 'moment-timezone';

export const convertTimeSlotToNearestDate = (timeOfDay: string, dayOfWeek: number, timezone: string): Moment => {
  const date = moment.utc(`${moment.utc().startOf('day').set('day', dayOfWeek).format('YYYY-MM-DD')}T${timeOfDay}`).tz(timezone);
  if (date.isBefore(moment.tz(timezone).startOf('hour'))) return date.add(1, 'week');
  return date;
};

export const formatHoursAsTime = (hours: number) => {
  const time = moment().startOf('day').add(hours, 'hours');
  return time.format('HH:mm:ss');
};

export const formatTimestamp = (rawTimestamp: string | Dayjs, formatString: string) => {
  const timestamp = typeof rawTimestamp === 'string' ? dayjs(rawTimestamp) : rawTimestamp;
  const thisYear = dayjs().year();
  const dateYear = timestamp.year();

  if (thisYear === dateYear) {
    const updatedFormatString = formatString.replace(', YYYY', '').replace(' YYYY', '');
    return timestamp.format(updatedFormatString);
  }

  return timestamp.format(formatString);
};

export const getDifferenceInMonths = (startDate: Moment, endDate: Moment): number => {
  const duration = moment.duration(endDate.diff(startDate));
  const months = duration.asMonths();
  return parseInt((Math.round(months * 2) / 2).toFixed(1).replace('.0', ''), 10);
};
