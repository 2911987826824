import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import Button from '~tools/react/components/Button';

interface Props {
  label: string;
  url: string;
}

class ThreadItemMetadataAttachment extends PureComponent<Props> {
  render() {
    return (
      <Link to={this.props.url}>
        <Button
          align={Button.enums.Alignments.Left}
          label={this.props.label}
          size={Button.enums.Sizes.XSmall}
          style={Button.enums.Styles.Secondary}
          type={Button.enums.Types.Button}
          width={Button.enums.Widths.Auto}
        />
      </Link>
    );
  }
}

export default ThreadItemMetadataAttachment;
