export enum Icons {
  PlusCircle = 'PLUS_CIRCLE',
  ChevronDown = 'CHEVRON_DOWN',
  ChevronUp = 'CHEVRON_UP',
}

export enum Styles {
  Add = 'ADD',
  Select = 'SELECT',
}
