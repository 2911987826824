import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './PhotoListView.scss';

interface Props {
  bindRef?: (e: HTMLDivElement) => void;
  children: ReactNode;
  isReordering: boolean;
}

function PhotoListView(props: Props) {
  if (!props.children) return null;

  return (
    <div
      ref={props.bindRef}
      className={classNames({
        [styles['photo-list-view']]: true,
        [styles['photo-list-view--reordering']]: props.isReordering,
      })}>
      {props.children}
    </div>
  );
}

export default withStyles(styles)(SortableContainer(PhotoListView));
