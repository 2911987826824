import React, { Fragment, PureComponent } from 'react';

import { HighlightColors } from '~tools/react/components/Card/components/CardSection/enums';
import { Colors as ArrowLinkColors } from '~tools/react/components/ArrowLink/enums';
import { Colors as ButtonColors } from '~tools/react/components/Button/enums';
import { Colors as TextColors } from '~tools/react/components/Text/enums';

import ArrowLink from '~tools/react/components/ArrowLink';
import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import StatusLight from './components/StatusLight';

import * as enums from './enums';

interface Props {
  description: string;
  highlight?: string;
  isLoading?: boolean;
  link?: {
    path: string;
    label: string;
  };
  status: enums.Statuses;
  title: string;
}

class StatusCardSection extends PureComponent<Props> {
  render() {
    let buttonColor: ButtonColors | undefined;
    let highlightColor: HighlightColors | undefined;
    let textColor: TextColors;
    let arrowLinkColor: ArrowLinkColors | undefined;
    switch (this.props.status) {
      case enums.Statuses.Unknown: {
        textColor = TextColors.Secondary;
        break;
      }
      case enums.Statuses.NoData: {
        arrowLinkColor = ArrowLinkColors.Gray;
        buttonColor = ButtonColors.Gray;
        highlightColor = HighlightColors.Gray;
        textColor = TextColors.Secondary;
        break;
      }
      case enums.Statuses.Degraded: {
        arrowLinkColor = ArrowLinkColors.Red;
        highlightColor = HighlightColors.Red;
        textColor = TextColors.Error;
        break;
      }
      case enums.Statuses.Warning: {
        arrowLinkColor = ArrowLinkColors.Yellow;
        highlightColor = HighlightColors.Yellow;
        textColor = TextColors.Warning;
        break;
      }
      case enums.Statuses.Ok:
      default: {
        arrowLinkColor = ArrowLinkColors.Green;
        textColor = TextColors.Success;
        break;
      }
    }

    return (
      <Card.CardSection
        isLoading={this.props.isLoading}
        highlightColor={this.props.isLoading ? undefined : highlightColor}>
        <Row
          flexBehavior={Row.enums.FlexBehaviors.Default}
          verticalAlignment={Row.enums.VerticalAlignments.Center}>
          <Heading
            content={this.props.title}
            font={Heading.enums.Fonts.Secondary}
            size={Heading.enums.Sizes.XXSmall}
          />
          <HorizontalSpacing
            size={HorizontalSpacing.enums.Sizes.XXSmall}
          />
          <StatusLight
            status={this.props.status}
          />
        </Row>
        <VerticalSpacing
          size={VerticalSpacing.enums.Sizes.XXSmall}
        />
        <Row
          flexBehavior={Row.enums.FlexBehaviors.Default}
          verticalAlignment={Row.enums.VerticalAlignments.Center}>
          {this.props.highlight ? (
            <Fragment>
              <Text
                color={textColor}
                content={this.props.highlight}
                isMarkdown
                size={Text.enums.Sizes.Large}
              />
              <Text
                content="&nbsp;"
                size={Text.enums.Sizes.Large}
              />
            </Fragment>
          ) : null}
          <Text
            content={this.props.description}
            isMarkdown
            size={Text.enums.Sizes.Large}
          />
        </Row>
        {this.props.link && this.props.status !== enums.Statuses.NoData ? (
          <Fragment>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
            <ArrowLink
              color={arrowLinkColor}
              label={this.props.link.label}
              link={{
                path: this.props.link.path,
              }}
              size={ArrowLink.enums.Sizes.Small}
            />
          </Fragment>
        ) : null}
        {this.props.link && this.props.status === enums.Statuses.NoData ? (
          <Fragment>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
            <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
              <Button
                align={Button.enums.Alignments.Left}
                color={buttonColor}
                icon={Button.enums.Icons.Plus}
                label={this.props.link.label}
                link={{
                  path: this.props.link.path,
                }}
                size={Button.enums.Sizes.XSmall}
                style={Button.enums.Styles.Secondary}
              />
            </Row>
          </Fragment>
        ) : null}
      </Card.CardSection>
    );
  }
}

export default StatusCardSection;
