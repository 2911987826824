import { ApolloQueryResult } from 'apollo-client';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ShipmentStatuses } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withDeleteViewing, { DeleteViewingProps } from '~tools/react/graphql/mutations/viewings/withDeleteViewing';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';
import LoadingVisual from '~web-manage/lib/common/components/__deprecated/LoadingVisual';

import { getAbbreviatedStreetAddress } from '~tools/utils/geography/addresses';

import Alert from '~tools/react/components/Alert';
import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import DashboardDataGrid from '~tools/react/components/DashboardDataGrid';
import Heading from '~tools/react/components/Heading';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import InteractiveMap from '~tools/react/components/InteractiveMap';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import VerticalRule from '~tools/react/components/VerticalRule';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import { formatAsUSD } from '~tools/utils/string';

import { formatPhoneNumber } from '~web-manage/lib/common/utils/phoneNumber';

import ScheduleViewingPhotos from './components/ScheduleViewingPhotos';

import AdditionalInstructionsSVG from './svgs/additional-instructions.svg';
import DoormanNotesSVG from './svgs/doorman-notes.svg';
import KeyPickupSVG from './svgs/key-pickup.svg';
import ListingLocationSVG from './svgs/listing-location.svg';
import ViewingDateSVG from './svgs/viewing-date.svg';

import { Viewing as ViewingType } from './types';

import query from './Viewing.gql';

const ZOOM_BUFFER = 0.015;

enum NormalizedGuestAccessPolicyTypeTypes {
  KeyAcquisitionSmartLock = 'GUEST_ACCESS.KEY_ACQUISITION.SMART_LOCK',
  KeyAcquisitionHuman = 'GUEST_ACCESS.KEY_ACQUISITION.HUMAN',
  GuestListEntryRequired = 'GUEST_ACCESS.GUEST_LIST_ENTRY.REQUIRED',
  BlanketFlipVisitorsAllowed = 'GUEST_ACCESS.BLANKET_FLIP_VISITORS.ALLOWED',
}

interface State {
  isDeleting: boolean;
}

type InputProps = RouteComponentProps<{ viewingUuid: string }>;

type Props =
  QueryProps &
  InputProps &
  DeleteViewingProps;

class Viewing extends PureComponent<Props, State> {
  state: State = {
    isDeleting: false,
  };

  handleDelete = async () => {
    const viewing = this.props.viewing;
    if (!viewing) return;

    this.setState({ isDeleting: true });
    try {
      await this.props.deleteViewing(viewing.uuid);
      this.setState({ isDeleting: false });
      this.props.history.push('/viewings');
    } catch (err) {
      this.setState({ isDeleting: false });
    }
  }

  render() {
    const viewing = this.props.viewing;
    if (this.props.isLoading || !viewing) {
      return (
        <ManageStage>
          <LoadingVisual />
        </ManageStage>
      );
    }
    const account = viewing.account;
    const addressUnit = viewing.addressUnit;
    const accessInstruction = addressUnit.accessInstruction;
    const abbreviatedStreetAddress = getAbbreviatedStreetAddress(addressUnit.address.streetAddress1);

    const policyTypesToDisplay = [
      NormalizedGuestAccessPolicyTypeTypes.KeyAcquisitionHuman,
      NormalizedGuestAccessPolicyTypeTypes.GuestListEntryRequired,
      NormalizedGuestAccessPolicyTypeTypes.BlanketFlipVisitorsAllowed,
    ];
    const policiesToDisplay =
      _.filter(addressUnit.address.building.policies, policy =>
        _.includes(policyTypesToDisplay, policy.policyType.normalizedPolicyType.type)
      );

    const keyHandoffPolicy = _.find(policiesToDisplay,
      gP => gP.policyType.normalizedPolicyType.type === NormalizedGuestAccessPolicyTypeTypes.KeyAcquisitionHuman
    );
    const guestListPolicy = _.find(policiesToDisplay,
      gP => gP.policyType.normalizedPolicyType.type === NormalizedGuestAccessPolicyTypeTypes.GuestListEntryRequired
    );
    const blanketFlipVisitorsPolicy = _.find(policiesToDisplay,
      gP => gP.policyType.normalizedPolicyType.type === NormalizedGuestAccessPolicyTypeTypes.BlanketFlipVisitorsAllowed
    );

    let evictionHistoryStr: string;
    let evictionHistoryColor;
    switch (account.activeCreditReport?.hasEvictionRecords) {
      case true:
        evictionHistoryStr = 'Has eviction on record';
        evictionHistoryColor = DashboardDataGrid.enums.Colors.Red;
        break;
      case false:
        evictionHistoryStr = 'No evictions on record';
        evictionHistoryColor = DashboardDataGrid.enums.Colors.Green;
        break;
      default:
        evictionHistoryStr = 'N/A';
        break;
    }

    let criminalHistoryStr: string;
    let criminalHistoryColor;
    switch (account.activeCreditReport?.hasCriminalRecords) {
      case true:
        criminalHistoryStr = 'Has criminal record';
        criminalHistoryColor = DashboardDataGrid.enums.Colors.Red;
        break;
      case false:
        criminalHistoryStr = 'No criminal record';
        criminalHistoryColor = DashboardDataGrid.enums.Colors.Green;
        break;
      default:
        criminalHistoryStr = 'N/A';
        break;
    }

    const activeIncomeSource = _.orderBy(
      account.incomeSources ?? [],
      ['verifiedAt', 'annualIncome'],
      ['asc', 'desc'],
    )[0];

    let verificationStatusStr: string;
    let verificationStatusColor;
    const verification = account.verifications[0];
    if (verification?.verifiedAt) {
      verificationStatusStr = 'Identity verified';
      verificationStatusColor = DashboardDataGrid.enums.Colors.Green;
    } else {
      verificationStatusStr = 'Pending';
    }

    let displayPhoneNumber: string;
    if (viewing.listing.hasSmartLockAccess) {
      displayPhoneNumber = formatPhoneNumber('7048003547');
    } else if (viewing.thread?.viewerProxyPhoneNumber) {
      displayPhoneNumber = formatPhoneNumber(viewing.thread?.viewerProxyPhoneNumber);
    } else {
      displayPhoneNumber = '●●● - ●●● - ●●●●';
    }

    return (
      <ManageStage>
        <Breadcrumbs
          items={[
            { path: '/viewings', label: 'All viewings' },
            { label: `${account.fullName} at ${abbreviatedStreetAddress}${addressUnit.name ? `, ${addressUnit.name}` : `.`}` },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
        <Card
          header={{
            title: `Viewing at ${abbreviatedStreetAddress}${addressUnit.name ? `, ${addressUnit.name}` : `.`}`,
          }}>
          {viewing.createdAt === ShipmentStatuses.DELIVERED && !addressUnit ? (
            <Card.CardAlert
              action={{
                label: 'Set up your smart lock',
                onClick: () => {},
              }}
              icon={Card.CardAlert.enums.Icons.Alert}
              message="This smart lock needs to be set up"
            />
          ) : null}
          <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
            <div style={{ minWidth: '55%' }}>
              <Card.CardSection title="Details">
                <DashboardDataGrid
                  items={[
                    {
                      label: 'Date',
                      description: moment(viewing.calendarEvent.startsAt).tz(addressUnit.address.timezone).format('dddd, MMMM Do, YYYY'),
                    },
                    {
                      label: 'Starts at',
                      description: moment(viewing.calendarEvent.startsAt).tz(addressUnit.address.timezone).format('h:mm A'),
                    },
                    {
                      label: 'Ends at',
                      description: moment(viewing.calendarEvent.endsAt).tz(addressUnit.address.timezone).add('1', 'millisecond').format('h:mm A'),
                    },
                  ]}
                />
              </Card.CardSection>
              <Card.CardSection title="Viewer">
                <DashboardDataGrid
                  items={[
                    {
                      label: 'Name',
                      description: account.fullName,
                    },
                    {
                      label: 'Verification',
                      description: verificationStatusStr,
                      color: verificationStatusColor,
                    },
                    {
                      label: 'Credit score',
                      description: `${account.activeCreditReport?.creditScore || 'N/A'}`,
                    },
                    {
                      label: 'Eviction history',
                      color: evictionHistoryColor,
                      description: evictionHistoryStr,
                    },
                    {
                      label: 'Criminal history',
                      color: criminalHistoryColor,
                      description: criminalHistoryStr,
                    },
                    {
                      label: 'Annual income',
                      description: activeIncomeSource ? `$${formatAsUSD(activeIncomeSource.annualIncome)}` : `N/A`,
                    },
                  ]}
                />
              </Card.CardSection>
              <Card.CardSection title="Contact">
                {viewing.listing.hasSmartLockAccess ? (
                  <Text content="Since this unit has a set up smart lock, Caretaker support will be on-call in case anything goes wrong during this viewing. Here's the number we'll give your viewer in case they need immediate assistance:" />
                ) : (
                  <Text
                    content={viewing.thread?.viewerProxyPhoneNumber ?
                      `Need to get in contact for any reason? Reach ${account.firstName} via texting the number below. Be sure to send the text from the number you registered on your account.` :
                      `You'll be able to reach ${account.firstName} via texting a number displayed below when the viewing is less than 2 hours away.`
                    }
                  />
                )}
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
                <Card>
                  <Card.CardSection>
                    <Heading
                      align={Heading.enums.Align.Center}
                      font={Heading.enums.Fonts.Secondary}
                      content={displayPhoneNumber}
                    />
                  </Card.CardSection>
                </Card>
              </Card.CardSection>
              <Card.CardSection title="Cancel">
                <Text
                  content={`If plans changed and your place can no longer be made available for ${account.firstName} to view at ${moment(viewing.calendarEvent.startsAt).tz(addressUnit.address.timezone).format('hA')}, you have the ability to cancel their viewing.`}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <Button
                  align={Button.enums.Alignments.Right}
                  color={Button.enums.Colors.Red}
                  isLoading={this.state.isDeleting}
                  label="Cancel viewing"
                  onClick={this.handleDelete}
                />
              </Card.CardSection>
            </div>
            <VerticalRule />
            <Card.CardSection>
              <Alert
                icon={Alert.enums.Icons.Info}
                title="Viewing instructions preview"
                description={`This is what ${account.firstName} will see when they arrive at your place. Make sure it's clear, and make some updates if you need to.`}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              <Heading
                content="Overview"
                size={Heading.enums.Sizes.XSmall}
                font={Heading.enums.Fonts.Secondary}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                <ViewingDateSVG />
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                <div>
                  <Heading
                    content="Viewing date"
                    size={Heading.enums.Sizes.XXSmall}
                    font={Heading.enums.Fonts.Secondary}
                  />
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                  <Text
                    content={
                      `${moment(viewing.calendarEvent.startsAt).tz(addressUnit.address.timezone).format('dddd, MMM D')} from ${moment(viewing.calendarEvent.startsAt).tz(addressUnit.address.timezone).format('h:mm A')} - ${moment(viewing.calendarEvent.endsAt).tz(addressUnit.address.timezone).add('1', 'millisecond').format('h:mm A')}`
                    }
                  />
                </div>
              </Row>
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                <ListingLocationSVG />
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                <div>
                  <Heading
                    content="Listing location"
                    size={Heading.enums.Sizes.XXSmall}
                    font={Heading.enums.Fonts.Secondary}
                  />
                  <Text content={`${addressUnit.address.streetAddress1}${addressUnit.name ? ` ${addressUnit.name},` : ','} ${addressUnit.address.city} ${addressUnit.address.state} ${addressUnit.address.zipCode}`} />
                </div>
              </Row>
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              <HorizontalRule />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              <Heading
                content="Access instructions"
                size={Heading.enums.Sizes.XSmall}
                font={Heading.enums.Fonts.Secondary}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              {keyHandoffPolicy ? (
                <Fragment>
                  <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                    <KeyPickupSVG />
                    <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                    <div>
                      <Heading
                        content="Key pickup"
                        size={Heading.enums.Sizes.XXSmall}
                        font={Heading.enums.Fonts.Secondary}
                      />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                      <Text
                        content={keyHandoffPolicy.policyType.normalizedPolicyType.description}
                      />
                      {keyHandoffPolicy.externalNotes ? (
                        <Fragment>
                          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                          <Text
                            color={Text.enums.Colors.Secondary}
                            content={keyHandoffPolicy.externalNotes}
                            size={Text.enums.Sizes.Medium}
                          />
                        </Fragment>
                      ) : null}
                    </div>
                  </Row>
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                </Fragment>
              ) : null}
              {guestListPolicy ? (
                <Fragment>
                  <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                    <DoormanNotesSVG />
                    <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                    <div>
                      <Heading
                        content="Doorman notes"
                        size={Heading.enums.Sizes.XXSmall}
                        font={Heading.enums.Fonts.Secondary}
                      />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                      <Text
                        content={guestListPolicy.policyType.normalizedPolicyType.description}
                      />
                      {guestListPolicy.externalNotes ? (
                        <Fragment>
                          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                          <Text
                            color={Text.enums.Colors.Secondary}
                            content={guestListPolicy.externalNotes}
                            size={Text.enums.Sizes.Medium}
                          />
                        </Fragment>
                      ) : null}
                    </div>
                  </Row>
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                </Fragment>
              ) : null}
              {blanketFlipVisitorsPolicy ? (
                <Fragment>
                  <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                    <DoormanNotesSVG />
                    <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                    <div>
                      <Heading
                        content="Doorman notes"
                        size={Heading.enums.Sizes.XXSmall}
                        font={Heading.enums.Fonts.Secondary}
                      />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                      <Text
                        content={blanketFlipVisitorsPolicy.policyType.normalizedPolicyType.description}
                      />
                      {blanketFlipVisitorsPolicy.externalNotes ? (
                        <Fragment>
                          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                          <Text
                            color={Text.enums.Colors.Secondary}
                            content={blanketFlipVisitorsPolicy.externalNotes}
                            size={Text.enums.Sizes.Medium}
                          />
                        </Fragment>
                      ) : null}
                    </div>
                  </Row>
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                </Fragment>
              ) : null}
              {accessInstruction ? (
                <Fragment>
                  <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                    <AdditionalInstructionsSVG />
                    <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                    <div>
                      <Heading
                        content="Additional instructions"
                        size={Heading.enums.Sizes.XXSmall}
                        font={Heading.enums.Fonts.Secondary}
                      />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                      <Text
                        content={accessInstruction.notes}
                      />
                    </div>
                  </Row>
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                </Fragment>
              ) : null}
              {accessInstruction && accessInstruction.photos.length > 0 ? (
                <ScheduleViewingPhotos photos={accessInstruction.photos} />
              ) : (
                <div style={{ height: '240px', width: '100%' }}>
                  <InteractiveMap
                    bounds={{
                      minLng: addressUnit.address.longitude - ZOOM_BUFFER,
                      minLat: addressUnit.address.latitude - ZOOM_BUFFER,
                      maxLng: addressUnit.address.longitude + ZOOM_BUFFER,
                      maxLat: addressUnit.address.latitude + ZOOM_BUFFER,
                    }}>
                    <InteractiveMap.InteractiveMapMarker
                      label="Listing"
                      isActive
                      latitude={addressUnit.address.latitude}
                      longitude={addressUnit.address.longitude}
                      type={InteractiveMap.InteractiveMapMarker.enums.Types.Label}
                    />
                  </InteractiveMap>
                </div>
              )}
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
              <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                <Button
                  style={Button.enums.Styles.Outline}
                  align={Button.enums.Alignments.Right}
                  label="Edit access instructions"
                  size={Button.enums.Sizes.Small}
                  link={{
                    path: `/units/${viewing.listing.propertyManagerContract.uuid}/access`,
                    shouldOpenNewTab: true,
                  }}
                  icon={Button.enums.Icons.ExternalLink}
                />
              </Row>
              {/* <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
              <div styleName="viewing-details__separator" />
              <div styleName="viewing-details__support">
                <h4>Having issues with your viewing?</h4>
                <p>
                  Text our Viewings Emergency Line: <a href="sms:+17048003547">(704) 800-3547</a>
                </p>
                <Text
                  color={Text.enums.Colors.Secondary}
                  content="This number can only receive texts, not calls"
                  size={Text.enums.Sizes.Small}
                />
              </div> */}
            </Card.CardSection>
          </Row>
        </Card>
      </ManageStage>
    );
  }
}

interface Response {
  viewer: {
    viewing: ViewingType | null;
    uuid: string;
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  refetch: () => Promise<ApolloQueryResult<Response>>;
  viewing: ViewingType | null;
}

interface Variables {
  uuid: string;
}

export default compose(
  withDeleteViewing,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: props => ({
      ssr: false,
      skip: !props.match.params.viewingUuid,
      variables: {
        viewingUuid: props.match.params.viewingUuid,
      },
    }),
    props: props => ({
      isLoading: props.loading,
      refetch: props.refetch,
      viewing: props.data?.viewer?.viewing || null,
    }),
  }),
)(Viewing);
