import _ from 'lodash';
import React, { Children, cloneElement, Component, isValidElement, ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import DashboardAccordionItem from './components/DashboardAccordionItem';

import styles from './DashboardAccordion.scss';

interface Props {
  children?: ReactNode;
  listItems?: {
    title: string;
    content: string;
  }[];
  initiallyOpenItemIndex?: number;
}

interface State {
  openItemId: number | null;
}

class DashboardAccordion extends Component<Props, State> {
  static DashboardAccordionItem = DashboardAccordionItem;

  constructor(props: Props) {
    super(props);

    this.state = {
      openItemId: this.props.initiallyOpenItemIndex ?? 0,
    };
  }

  handleItemToggle = (id: number) => {
    this.setState({ openItemId: this.state.openItemId !== id ? id : null });
  }

  render() {
    const listItems = this.props.listItems;
    const children = Children.toArray(this.props.children);
    return (
      <ul styleName="accordion">
        {listItems ? (
          _.map(listItems, (item, index) => (
            <DashboardAccordionItem
              content={item.content}
              id={index}
              isOpen={index === this.state.openItemId}
              key={item.title}
              onClick={this.handleItemToggle}
              title={item.title}
            />
          ))
        ) : _.map(children, (child, index) => {
          if (isValidElement(child)) {
            return cloneElement(child, {
              id: index,
              isOpen: index === this.state.openItemId,
              ...child.props,

              // Guarantee onClick is set, even if `undefined` is passed explicitly
              onClick: child.props.onClick || this.handleItemToggle,
            });
          }

          return child;
        })}
      </ul>
    );
  }
}

export default withStyles(styles)(DashboardAccordion);
