// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._0N5in2{align-content:flex-start;-webkit-box-align:start;align-items:flex-start;display:-webkit-box;display:flex;-webkit-box-flex:1;flex:1;flex-wrap:wrap;-webkit-box-pack:start;justify-content:flex-start;overflow-y:auto;padding-bottom:24px;padding-top:60px;width:100%}@media screen and (max-width:500px){._0N5in2{padding-top:0}}._0N5in2._RnEJxx{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-pack:center;justify-content:center;padding-top:40px;width:100%}@media screen and (max-width:1127px){._0N5in2._RnEJxx{padding-bottom:8px}}@media screen and (max-width:500px){._0N5in2._RnEJxx{padding-top:0}}", ""]);
// Exports
exports.locals = {
	"photo-view": "_0N5in2",
	"photo-view--active": "_RnEJxx"
};
module.exports = exports;
