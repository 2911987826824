// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-webkit-keyframes _vqQk6j{0%{fill:#f6f8fa;stroke:#eff2f7}to{fill:#e5e9f2;stroke:#e0e6ed}}@keyframes _vqQk6j{0%{fill:#f6f8fa;stroke:#eff2f7}to{fill:#e5e9f2;stroke:#e0e6ed}}._P9WO2Y{-webkit-animation:_vqQk6j .65s linear infinite alternate;animation:_vqQk6j .65s linear infinite alternate;border-radius:3px}", ""]);
// Exports
exports.locals = {
	"bar": "_P9WO2Y",
	"shimmer": "_vqQk6j"
};
module.exports = exports;
