import withMutation from '~tools/react/graphql/withMutation';
import { UpdateLeasesSettingInput } from '~tools/types/graphqlSchema';

import UpdateLeasesSettingMutation from './updateLeasesSetting.gql';

interface LeasesSetting {
  uuid: string;
}

interface Response {
  updateLeasesSetting: LeasesSetting;
}

interface Variables {
  input: UpdateLeasesSettingInput;
  uuid: string;
}

export interface UpdateLeasesSettingProps {
  updateLeasesSetting: (uuid: string, input: UpdateLeasesSettingInput) => Promise<LeasesSetting>;
}

export default withMutation<{}, Response, Variables, UpdateLeasesSettingProps>(UpdateLeasesSettingMutation, {
  props: props => ({
    updateLeasesSetting: async (uuid: string, input: UpdateLeasesSettingInput) => {
      const res = await props.mutate({
        variables: {
          input,
          uuid,
        },
      });

      if (!res || !res.data) throw new Error('Error updating leases setting');
      if (!res.data.updateLeasesSetting) throw new Error('No updateLeasesSetting returned');

      return res.data.updateLeasesSetting;
    },
  }),
});
