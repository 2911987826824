export enum BodyBackgroundColors {
  Gray = 'GRAY',
  White = 'WHITE',
}

export enum Widths {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE',
  ExtraLarge = 'EXTRA_LARGE',
}
