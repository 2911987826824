import withMutation from '~tools/react/graphql/withMutation';
import {
  CreateVerificationForListingInput,
  VerificationVerifiedEntityTypes,
} from '~tools/types/graphqlSchema';

import CreateVerificationForListingMutation from './createVerificationForListing.gql';

interface Verification {
  createdAt: string;
  failedAt: string | null;
  updatedAt: string;
  uuid: string;
  verificationMethod: {
    name: string;
    slug: string;
    uuid: string;
  };
  verifiedAt: string | null;
  verifiedEntityType: VerificationVerifiedEntityTypes | null;
}

interface Response {
  createVerificationForListing: Verification;
}

export interface CreateVerificationForListingProps {
  createVerificationForListing: (input: CreateVerificationForListingInput) => Promise<Verification>;
}

export default withMutation<{}, Response, { input: CreateVerificationForListingInput }, CreateVerificationForListingProps>(CreateVerificationForListingMutation, {
  props: props => ({
    createVerificationForListing: async (input: CreateVerificationForListingInput) => {
      const res = await props.mutate({
        variables: { input },
      });

      if (!res || !res.data || !res.data.createVerificationForListing) throw new Error('Error creating verification for this listing.');

      return res.data.createVerificationForListing;
    },
  }),
});
