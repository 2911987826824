import classNames from 'classnames';
import _ from 'lodash';
import React, { PureComponent } from 'react';

import Markdown from '~tools/react/components/Markdown';

import withStyles from '~tools/react/hocs/withStyles';

import * as enums from './enums';

import styles from './Heading.scss';

interface Props {
  align?: enums.Align;
  color?: enums.Colors;
  content: string;
  dangerouslyOverrideStyles?: {
    [attr: string]: string;
  };
  font?: enums.Fonts;
  hasHoverStyles?: boolean;
  isMarkdown?: boolean;
  onClick?: () => void;
  priority?: enums.Priorities;
  shouldPreserveNewlines?: boolean;
  size?: enums.Sizes;
  style?: enums.Styles;
}

class Heading extends PureComponent<Props> {
  static defaultProps = {
    color: enums.Colors.Primary,
    font: enums.Fonts.Primary,
    isMarkdown: false,
    size: enums.Sizes.Medium,
  };
  static enums = enums;

  render() {
    let HTMLTag = '';
    switch (this.props.size) {
      case enums.Sizes.XLarge: {
        HTMLTag = 'h1';
        break;
      }
      case enums.Sizes.Large: {
        HTMLTag = 'h2';
        break;
      }
      case enums.Sizes.Medium: {
        HTMLTag = 'h3';
        break;
      }
      case enums.Sizes.Small: {
        HTMLTag = 'h4';
        break;
      }
      default: {
        HTMLTag = 'h5';
        break;
      }
    }

    if (this.props.priority) {
      HTMLTag = `h${this.props.priority}`;
    }

    return (
      // @ts-ignore
      <HTMLTag
        className={classNames({
          [styles.heading]: true,
          [styles['heading--hover']]: this.props.hasHoverStyles,
          [styles['heading--markdown']]: this.props.isMarkdown,
          [styles['heading--on-click']]: !!this.props.onClick,
          [styles['heading--preserve-newlines']]: this.props.shouldPreserveNewlines,
          [styles[`heading--align-${_.kebabCase(this.props.align)}`]]: this.props.align,
          [styles[`heading--color-${_.kebabCase(this.props.color)}`]]: this.props.color,
          [styles[`heading--font-${_.kebabCase(this.props.font)}`]]: this.props.font,
          [styles[`heading--size-${_.kebabCase(this.props.size)}`]]: this.props.size,
          [styles[`heading--style-${_.kebabCase(this.props.style)}`]]: this.props.style,
        })}
        onClick={this.props.onClick}
        style={this.props.dangerouslyOverrideStyles}>
        {this.props.isMarkdown ? (
          <Markdown style={Markdown.enums.Styles.None} content={this.props.content} />
        ) : this.props.content}
      </HTMLTag>
    );
  }
}

export default withStyles(styles)(Heading);
