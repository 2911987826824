import React, { Fragment } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Boost from '~web-core/lib/_common/components/utils/svg/boost.svg';
import ConfettiComponent from '~web-core/lib/_common/components/presentationals/ui/Confetti/Component';

import Button from '~tools/react/components/Button';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import styles from './BoostSuccessView.scss';

interface Props {
  insightsPath?: string;
  onClose: () => void;
}

function BoostSuccessView(props: Props) {
  return (
    <div styleName="boost-modal">
      <ConfettiComponent length={50} styleName="boost-modal__confetti" />
      <div styleName="boost-modal__body-success">
        <div styleName="boost-modal__body-success-icon">
          <Boost
            height="75"
            width="75"
          />
        </div>
        <h1>
          Boost successfully purchased!
        </h1>
        <h2>
          You can track how your ad is performing on your unit marketing overview.
        </h2>
        {props.insightsPath ? (
          <Fragment>
            <Button
              label="View marketing overview"
              link={{
                path: props.insightsPath,
              }}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          </Fragment>
        ) : null}
        <Button
          label="Close"
          onClick={props.onClose}
          style={Button.enums.Styles.Inverse}
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(BoostSuccessView);
