import React, { Component, ComponentType, Fragment } from 'react';
import hoistStatics from 'hoist-non-react-statics';

import {
  AutocompletePrediction,
  AutocompleteService,
  PlaceResult,
  PlacesService,
} from '~web-core/lib/common/types/googlemaps';

import Globals from '~web-core/lib/common/globals';

import Script from '~tools/react/components/utility/Script';

export interface WithPlacesAutocompleteProps {
  getPlaceDetails: (placeId: string) => Promise<PlaceResult | null>;
  getPlacePredictions: (value: string, options?: any) => Promise<AutocompletePrediction[] | null>;
}

interface State {
  isScriptInitialized: boolean;
}

function withPlacesAutocomplete<T>(ComposedComponent: ComponentType<T & WithPlacesAutocompleteProps>) {
  const displayName = ComposedComponent.displayName || ComposedComponent.name || 'Component';

  class WithPlacesAutocomplete extends Component<T, State> {
    autocompleteService: AutocompleteService | undefined;
    autocompleteSessionToken: string | undefined;
    placesService: PlacesService | undefined;

    static ComposedComponent = ComposedComponent;
    static displayName = `withPlacesAutocomplete(${displayName})`;

    state: State = {
      isScriptInitialized: false,
    };

    handleScriptLoad = () => {
      if (!(window as any).autocompleteService) {
        (window as any).autocompleteService = new (window as any).google.maps.places.AutocompleteService();
      }
      if (!(window as any).placesService) {
        (window as any).placesService = new (window as any).google.maps.places.PlacesService(document.getElementById('attr'));
      }

      this.autocompleteSessionToken = new (window as any).google.maps.places.AutocompleteSessionToken();
    };

    render() {
      return (
        <Fragment>
          <div id="attr" style={{ display: 'none' }} />
          {this.state.isScriptInitialized ? (
            <Script
              inlineOnLoad={this.getInitializeGoogleString()}
              onLoad={this.handleScriptLoad}
              url={`https://maps.googleapis.com/maps/api/js?key=${Globals.GOOGLE_PLACES_KEY}&libraries=places`}
            />
          ) : null}
          <ComposedComponent
            {...this.props}
            getPlaceDetails={this.getPlaceDetails}
            getPlacePredictions={this.getPlacePredictions}
          />
        </Fragment>
      );
    }

    getPlacePredictions = async (value: string, options?: any): Promise<AutocompletePrediction[] | null> => {
      const autocompleteService = (window as any).autocompleteService;
      if (autocompleteService) {
        return new Promise((resolve) => {
          autocompleteService.getPlacePredictions({
            componentRestrictions: { country: 'us' },
            input: value,
            // Google will charge one autocomplete "discovery" as a single lookup if we use a session token
            sessionToken: this.autocompleteSessionToken,
            ...options,
          }, resolve);
        });
      }

      this.setState({ isScriptInitialized: true });
      return [];
    };

    getPlaceDetails = async (placeId: string): Promise<PlaceResult | null> => {
      const placesService = (window as any).placesService;

      if (placesService) {
        const result = await new Promise<PlaceResult>((resolve) => {
          placesService.getDetails({
            placeId,
            // These fields affect billing, so don't add anything without checking the implicatons
            fields: [
              'address_components',
              'formatted_address',
              'geometry',
              'id',
              'name',
              'place_id',
              'scope',
              'type',
              'url',
            ],
            // Google will charge one autocomplete "discovery" as a single lookup if we use a session token
            sessionToken: this.autocompleteSessionToken,
          }, resolve);
        });
        this.autocompleteSessionToken = new (window as any).google.maps.places.AutocompleteSessionToken();
        return result;
      }

      this.setState({ isScriptInitialized: true });
      return Promise.reject(new Error('Places library not loaded.'));
    };

    getInitializeGoogleString = () =>
      `function () {
        window.autocompleteService = new window.google.maps.places.AutocompleteService();
        window.placesService = new window.google.maps.places.PlacesService(document.getElementById('attr'));
      }`;
  }

  return hoistStatics(WithPlacesAutocomplete, ComposedComponent);
}

export default withPlacesAutocomplete;
