import withMutation from '~tools/react/graphql/withMutation';

import VoidLeaseMutation from './voidLease.gql';

export interface Lease {
  uuid: string;
  voidedAt: string | null;
}

interface Data {
  voidLease: Lease;
}

interface Variables {
  uuid: string;
}

export interface VoidLeaseProps {
  voidLease: (uuid: string) => Promise<Lease>;
}

export default withMutation<{}, Data, Variables, VoidLeaseProps>(VoidLeaseMutation, {
  props: props => ({
    voidLease: async (uuid) => {
      const res = await props.mutate({
        variables: {
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error voiding lease agreement.');

      return res.data.voidLease;
    },
  }),
});
