import withMutation from '~tools/react/graphql/withMutation';
import { ApplicationStatuses } from '~tools/types/graphqlSchema';

import RejectApplication from './rejectApplication.gql';

export interface Application {
  uuid: string;
  rejectedAt: string;
  status: ApplicationStatuses;
}

interface Data {
  rejectApplication: Application;
}

interface Variables {
  uuid: string;
}

export interface RejectApplicationProps {
  rejectApplication: (uuid: string) => Promise<Application>;
}

export default withMutation<{}, Data, Variables, RejectApplicationProps>(RejectApplication, {
  props: props => ({
    rejectApplication: async (uuid: string) => {
      const res = await props.mutate({
        variables: {
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error rejecting application.');

      return res.data.rejectApplication;
    },
  }),
});
