// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._krPxG5{border-radius:500px;height:10px;width:10px}._krPxG5._jrVDbq{background-color:#ca0021}._krPxG5._EGnskV{border:2px solid #aab7c9}._krPxG5._nrBkN2{background-color:#15a964}._krPxG5._aeRCE2{border:2px solid #aab7c9}._krPxG5._RnEf2p{background-color:#f2a615}", ""]);
// Exports
exports.locals = {
	"status-light": "_krPxG5",
	"status-light--status-degraded": "_jrVDbq",
	"status-light--status-no-data": "_EGnskV",
	"status-light--status-ok": "_nrBkN2",
	"status-light--status-unknown": "_aeRCE2",
	"status-light--status-warning": "_RnEf2p"
};
module.exports = exports;
