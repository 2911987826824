import { CalendarEntityTypes } from '~tools/types/graphqlAdminSchema';
import withMutation from '~tools/react/graphql/withMutation';

import CreateCalendarMutation from './createCalendar.gql';

interface Calendar {
  uuid: string;
}

interface CreateCalendarInput {
  entity: {
    uuid: string;
    type: CalendarEntityTypes;
  };
}

interface Data {
  createCalendar: Calendar;
}

interface Variables {
  input: CreateCalendarInput;
}

export { CalendarEntityTypes };

export interface CreateCalendarProps {
  createCalendar: (input: CreateCalendarInput) => Promise<Calendar>;
}

export default withMutation<{}, Data, Variables, CreateCalendarProps>(CreateCalendarMutation, {
  props: props => ({
    createCalendar: async (input) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating calendar.');

      return res.data.createCalendar;
    },
  }),
});
