import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './PhotoEditorModalFiltersSection.scss';

interface Props {
  children: ReactNode;
  title: string;
}

function PhotoEditorModalFiltersSection(props: Props) {
  return (
    <div styleName="modal-filters-section">
      <h5>{props.title}</h5>
      {props.children}
    </div>
  );
}

export default withStyles(styles)(PhotoEditorModalFiltersSection);
