// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._WVjPyB{padding:24px 32px}", ""]);
// Exports
exports.locals = {
	"listing-verify-content": "_WVjPyB"
};
module.exports = exports;
