import { ApolloQueryResult } from 'apollo-client';
import _ from 'lodash';
import React, { Component } from 'react';

import withQuery from '~tools/react/graphql/withQuery';
import { formatShortAddress } from '~web-manage/lib/common/utils/addressUnit';
import { Typeahead } from '~tools/react/components/Form';
import { PrivatePropertyManagerContractsFilterInput } from '~tools/types/graphqlSchema';

import query from './PropertyManagerContractsTypeahead.gql';

export interface PropertyManagerContractSearchResult {
  uuid: string;
  addressUnit: {
    uuid: string;
    name: string;
    address: {
      uuid: string;
      streetAddress1: string;
    };
  };
}

interface Variables {
  filter: PrivatePropertyManagerContractsFilterInput,
  first: number;
}

interface Response {
  viewer: {
    propertyManagerContracts: PropertyManagerContractSearchResult[];
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  refetch: (args: Variables) => Promise<ApolloQueryResult<Response>>;
  propertyManagerContracts: PropertyManagerContractSearchResult[];
}

interface InputProps {
  isRequired?: boolean;
  label?: string;
  name: string;
  onChange?: (propertyManagerContract: PropertyManagerContractSearchResult | null) => void;
  placeholder?: string;
  value?: string;
}

type Props = InputProps & QueryProps;

interface State {
  value: string;
}

const MIN_QUERY_LENGTH = 1;
const RESULTS_COUNT = 5;

class PropertyManagerContractsTypeahead extends Component<Props, State> {
  static defaultProps = {
    isRequired: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
    };
  }

  handleChange = (value) => {
    this.setState({ value }, () => {
      if (!this.props.isLoading && (value.length >= MIN_QUERY_LENGTH || value.length === 0)) {
        this.refetch();
      }
    });
  }

  handleClick = (data: { label: string, value: string }) => {
    this.handleChange(data.label);

    const propertyManagerContract = this.props.propertyManagerContracts.find(pmc => pmc.uuid === data.value) ?? null;
    if (this.props.onChange) this.props.onChange(propertyManagerContract);
  }

  render() {
    const typeaheadItems = _.map(this.props.propertyManagerContracts, propertyManagerContract => ({
      label: formatShortAddress(propertyManagerContract.addressUnit),
      value: propertyManagerContract.uuid,
    }));

    return (
      <Typeahead
        isLoading={this.props.isLoading}
        isRequired={this.props.isRequired}
        items={typeaheadItems}
        label={this.props.label}
        name={this.props.name}
        onChange={this.handleChange}
        onClick={this.handleClick}
        placeholder={this.props.placeholder}
        value={this.state.value}
      />
    );
  }

  refetch = async () => {
    const queriedTerm = this.state.value;
    await this.props.refetch({
      filter: {
        isActive: true,
        addressUnit: {
          smart: {
            icontains: queriedTerm,
          },
        },
      },
      first: RESULTS_COUNT,
    });

    if (this.state.value !== queriedTerm) {
      await this.refetch();
    }
  }
}

export default withQuery<InputProps, Response, Variables, QueryProps>(query, {
  options: props => ({
    variables: {
      first: RESULTS_COUNT,
      filter: {
        isActive: true,
        uuid: props.value || undefined,
      },
    },
    fetchPolicy: 'no-cache',
  }),
  props: props => ({
    isLoading: props.loading,
    propertyManagerContracts: props.data?.viewer?.propertyManagerContracts ?? [],
    refetch: props.refetch,
  }),
})(PropertyManagerContractsTypeahead);
