import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './NotificationsView.scss';

interface Props {
  children: ReactNode;
}

function NotificationsView(props: Props) {
  return (
    <div styleName="notifications">
      {props.children}
    </div>
  );
}

export default withStyles(styles)(NotificationsView);
