import { ApolloQueryResult } from 'apollo-client';
import _ from 'lodash';
import React, { PureComponent } from 'react';

import { StripeAccountHolderTypes } from '~tools/types/graphqlSchema';
import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withStripe, { StripeProps } from '~tools/react/hocs/withStripe';

import AlertSVG from '~tools/svgs/icons/interface/alert-triangle.svg';
import ClockSVG from '~tools/svgs/icons/interface/clock.svg';

import withRemovePayoutAccount, {
  RemovePayoutAccountProps,
} from '~tools/react/graphql/mutations/payoutAccounts/withRemovePayoutAccount';
import withSetPrimaryPayoutAccount, {
  SetPrimaryPayoutAccountProps,
} from '~tools/react/graphql/mutations/payoutAccounts/withSetPrimaryPayoutAccount';
import withCreateStripeFinancialConnectionSession, {
  CreateStripeFinancialConnectionSessionProps,
} from '~tools/react/graphql/mutations/accounts/withCreateStripeFinancialConnectionSession';
import withAddPayoutAccount, {
  AddPayoutAccountProps,
} from '~tools/react/graphql/mutations/payoutAccounts/withAddPayoutAccount';

import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import Center from '~tools/react/components/Center';
import ConfirmationModal from '~tools/react/components/ConfirmationModal';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Spinner from '~tools/react/components/Spinner';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import query from './PayoutsSettings.gql';

import PaymentSourceRow from './components/PaymentSourceRow';

import { PayoutAccount, StripeExternalAccountTypes } from './types';

type Props = QueryProps &
  SetPrimaryPayoutAccountProps &
  RemovePayoutAccountProps &
  CreateStripeFinancialConnectionSessionProps &
  StripeProps &
  AddPayoutAccountProps;

interface State {
  isOpeningFinancialConnectionModal: boolean;
  isConfirmationLoading: boolean;
  isConfirmationModalOpen: boolean;
  selectedPayoutAccountId?: string;
}

class PayoutsSettings extends PureComponent<Props, State> {
  state: State = {
    isConfirmationModalOpen: false,
    isConfirmationLoading: false,
    isOpeningFinancialConnectionModal: false,
  };

  handleSetPrimaryPayoutAccount = async (id: string) => {
    await this.props.setPrimaryPayoutAccount(id);
    await this.props.refetch();
  };
  handleDeletePayoutAccount = (id: string) =>
    this.setState({
      isConfirmationModalOpen: true,
      selectedPayoutAccountId: id,
    });

  handleConfirmDeletion = async () => {
    if (!this.state.selectedPayoutAccountId) return;

    this.setState({ isConfirmationLoading: true });
    await this.props.removePayoutAccount(this.state.selectedPayoutAccountId);

    await this.props.refetch();
    this.handleCloseConfirmationModal();
  };

  handleCloseConfirmationModal = () =>
    this.setState({
      isConfirmationLoading: false,
      isConfirmationModalOpen: false,
      selectedPayoutAccountId: undefined,
    });

  render() {
    const viewer = this.props.viewer;
    if (this.props.isLoading || !viewer) {
      return (
        <ManageStage>
          <Card
            header={{
              actions: [
                {
                  icon: Card.enums.ActionIcons.Plus,
                  isDisabled: true,
                  isLoading: this.state.isOpeningFinancialConnectionModal,
                  label: 'Add payout account',
                  onClick: this.openFinancialConnectionModal,
                },
              ],
              title: 'Payout accounts',
            }}
          >
            <Card.CardSection>
              <Center direction={Center.enums.Directions.Column}>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
                <Spinner />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
              </Center>
            </Card.CardSection>
          </Card>
        </ManageStage>
      );
    }

    const payoutAccounts = viewer.payoutAccounts ?? [];
    const hasConnectAccount = !!viewer.stripeConnectAccount;
    const isConnectAccountActivated =
      viewer.stripeConnectAccount &&
      (!viewer?.stripeConnectAccount?.requirements.disabledReason ||
        (viewer?.stripeConnectAccount?.requirements.disabledReason === 'requirements.past_due' &&
          viewer?.stripeConnectAccount?.requirements.pastDue.length === 1 &&
          viewer?.stripeConnectAccount?.requirements.pastDue[0] === 'external_account'));
    const isConnectAccountMissingRequirements =
      !isConnectAccountActivated &&
      viewer?.stripeConnectAccount?.requirements.disabledReason === 'requirements.past_due';
    const isConnectAccountPendingVerification =
      !isConnectAccountActivated &&
      (viewer?.stripeConnectAccount?.requirements.disabledReason ===
        'requirements.pending_verification' ||
        viewer?.stripeConnectAccount?.requirements.disabledReason === 'under_review');
    const isConnectAccountRejected =
      viewer?.stripeConnectAccount?.requirements.disabledReason &&
      viewer?.stripeConnectAccount?.requirements.disabledReason.indexOf('rejected') !== -1;
    return (
      <ManageStage>
        <ConfirmationModal
          cancelAction={{
            label: 'Cancel',
            onClick: this.handleCloseConfirmationModal,
          }}
          confirmAction={{
            label: 'Delete',
            onClick: this.handleConfirmDeletion,
          }}
          description="Are you sure you want to remove this payout account?"
          isLoading={this.state.isConfirmationLoading}
          isOpen={this.state.isConfirmationModalOpen}
          onClose={this.handleCloseConfirmationModal}
          title="Delete payout account"
        />
        <Breadcrumbs
          items={[
            { path: `/settings`, label: 'Settings' },
            { path: `/settings/payouts`, label: 'Payouts settings' },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
        <Card
          header={{
            actions: [
              {
                label: 'Add payout account',
                isLoading: this.state.isOpeningFinancialConnectionModal,
                icon: Card.enums.ActionIcons.Plus,
                isDisabled: !isConnectAccountActivated,
                onClick: this.openFinancialConnectionModal,
              },
            ],
            title: 'Payout accounts',
          }}
        >
          {!hasConnectAccount ? (
            <Card.CardSection>
              <Center direction={Center.enums.Directions.Column}>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <Row
                  flexBehavior={Row.enums.FlexBehaviors.Default}
                  verticalAlignment={Row.enums.VerticalAlignments.Center}
                >
                  <AlertSVG
                    style={{ color: '#F2A615' }}
                    width="20"
                    height="20"
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                  <Heading
                    content="Your account needs to be activated"
                    font={Heading.enums.Fonts.Secondary}
                    size={Heading.enums.Sizes.XSmall}
                  />
                </Row>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                <Text content="Please activate your account before managing payout settings" />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <Button
                  color={Button.enums.Colors.Blue}
                  style={Button.enums.Styles.Secondary}
                  icon={Button.enums.Icons.ChevronRight}
                  label="Activate account"
                  link={{
                    path: '/',
                  }}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              </Center>
            </Card.CardSection>
          ) : null}
          {isConnectAccountMissingRequirements ? (
            <Card.CardSection>
              <Center direction={Center.enums.Directions.Column}>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <Row
                  flexBehavior={Row.enums.FlexBehaviors.Default}
                  verticalAlignment={Row.enums.VerticalAlignments.Center}
                >
                  <AlertSVG
                    style={{ color: '#F2A615' }}
                    width="20"
                    height="20"
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                  <Heading
                    content="Your account needs some additional information"
                    font={Heading.enums.Fonts.Secondary}
                    size={Heading.enums.Sizes.XSmall}
                  />
                </Row>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                <Text content="We need to verify a few details with you before continuing" />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <Button
                  color={Button.enums.Colors.Blue}
                  style={Button.enums.Styles.Secondary}
                  icon={Button.enums.Icons.ChevronRight}
                  label="Update account"
                  link={{
                    path: '/settings/account',
                  }}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              </Center>
            </Card.CardSection>
          ) : null}
          {isConnectAccountPendingVerification ? (
            <Card.CardSection>
              <Center direction={Center.enums.Directions.Column}>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <Row
                  flexBehavior={Row.enums.FlexBehaviors.Default}
                  verticalAlignment={Row.enums.VerticalAlignments.Center}
                >
                  <ClockSVG
                    style={{ color: '#182029' }}
                    width="20"
                    height="20"
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                  <Heading
                    content="Your business details are currently pending verification"
                    font={Heading.enums.Fonts.Secondary}
                    size={Heading.enums.Sizes.XSmall}
                  />
                </Row>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                <Text content="This process usually only takes a few minutes." />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              </Center>
            </Card.CardSection>
          ) : null}
          {isConnectAccountRejected ? (
            <Card.CardSection>
              <Center direction={Center.enums.Directions.Column}>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <Row
                  flexBehavior={Row.enums.FlexBehaviors.Default}
                  verticalAlignment={Row.enums.VerticalAlignments.Center}
                >
                  <AlertSVG
                    style={{ color: '#CA0021' }}
                    width="20"
                    height="20"
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                  <Heading
                    content="Your provided verification info was rejected by Stripe."
                    font={Heading.enums.Fonts.Secondary}
                    size={Heading.enums.Sizes.XSmall}
                  />
                </Row>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                <Text content="Check your provided details and ensure they're accurate. If all looks correct, reach out to support@caretaker.com." />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <Button
                  color={Button.enums.Colors.Blue}
                  style={Button.enums.Styles.Secondary}
                  icon={Button.enums.Icons.ExternalLink}
                  label="Update account"
                  link={{
                    path: '/outbound/stripe/update',
                  }}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              </Center>
            </Card.CardSection>
          ) : null}
          {isConnectAccountActivated ? (
            <Card.CardSection
              spacing={payoutAccounts.length > 0 ? Card.CardSection.enums.Spacing.None : undefined}
            >
              {payoutAccounts.length > 0 ? (
                _.map(
                  _.sortBy(payoutAccounts, (account) => (account.defaultForCurrency ? 0 : 1)),
                  (payoutAccount) => {
                    if (payoutAccount.type === StripeExternalAccountTypes.BANK_ACCOUNT) {
                      return (
                        <PaymentSourceRow
                          accountName={payoutAccount.bankAccount.accountName}
                          bankName={payoutAccount.bankAccount.bankName}
                          id={payoutAccount.id}
                          isDefault={payoutAccount.defaultForCurrency}
                          isDefaultForTopups={
                            payoutAccount.id === viewer.balance?.defaultTopupSourceId
                          }
                          key={payoutAccount.id}
                          last4={payoutAccount.bankAccount.last4}
                          onDelete={this.handleDeletePayoutAccount}
                          onSetDefault={this.handleSetPrimaryPayoutAccount}
                        />
                      );
                    }
                    return (
                      <PaymentSourceRow
                        brand={payoutAccount.card.brand}
                        expMonth={payoutAccount.card.expMonth}
                        expYear={payoutAccount.card.expYear}
                        id={payoutAccount.id}
                        isDefault={payoutAccount.defaultForCurrency}
                        key={payoutAccount.id}
                        last4={payoutAccount.card.last4}
                        onDelete={this.handleDeletePayoutAccount}
                        onSetDefault={this.handleSetPrimaryPayoutAccount}
                      />
                    );
                  },
                )
              ) : (
                <Text content="Add a payout account to start accepting payments." />
              )}
            </Card.CardSection>
          ) : null}
        </Card>
      </ManageStage>
    );
  }

  openFinancialConnectionModal = async () => {
    const stripe = this.props.stripe;
    if (!stripe) return;

    this.setState({ isOpeningFinancialConnectionModal: true });
    const clientSecret = await this.props.createStripeFinancialConnectionSession({
      accountHolderType: StripeAccountHolderTypes.ACCOUNT,
    });
    this.setState({ isOpeningFinancialConnectionModal: false });
    const result = await stripe.collectBankAccountToken({
      clientSecret,
    });

    if (result.error) {
      console.log(result.error.message);
    } else if (result.token) {
      await this.props.addPayoutAccount(result.token.id);
      await this.props.refetch();
    }
  };
}

interface Viewer {
  balance: {
    defaultTopupSourceId: string | null;
    uuid: string;
  } | null;
  fullName: string;
  payoutAccounts: PayoutAccount[];
  stripeConnectAccount: {
    id: string;
    payoutsEnabled: boolean;
    requirements: {
      currentlyDue: string[];
      disabledReason: string | null;
      pastDue: string[];
    };
  } | null;
  uuid: string;
}

interface Response {
  viewer: Viewer | null;
}

export interface QueryProps {
  isLoading: boolean;
  refetch: () => Promise<ApolloQueryResult<Response>>;
  viewer: Viewer | null;
}

export default compose(
  withQuery<{}, Response, {}, QueryProps>(query, {
    props: (props) => ({
      isLoading: props.loading,
      refetch: props.refetch,
      viewer: props.data?.viewer ?? null,
    }),
  }),
  withCreateStripeFinancialConnectionSession,
  withRemovePayoutAccount,
  withSetPrimaryPayoutAccount,
  withAddPayoutAccount,
  withStripe,
)(PayoutsSettings);
