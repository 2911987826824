// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._NRBQod{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column}._NRBQod>p:first-child{color:#273444;margin-bottom:16px}._NRBQod>label+label{margin-top:16px}", ""]);
// Exports
exports.locals = {
	"manage-listing-amenities-view": "_NRBQod"
};
module.exports = exports;
