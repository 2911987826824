import _ from 'lodash';
import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Globals from '~web-manage/lib/common/globals';

import { StripeAccountLinkTypes } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';
import withAuth, { AuthProps } from '~tools/react/hocs/withAuth';
import withCreateStripeAccountLink, { CreateStripeAccountLinkProps } from '~tools/react/graphql/mutations/accounts/withCreateStripeAccountLink';

import Redirect from '~tools/react/components/utility/Redirect';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';
import LoadingVisual from '~web-manage/lib/common/components/__deprecated/LoadingVisual';

type InputProps = RouteComponentProps<{ accountLinkType: string }>;

type Props =
  InputProps &
  AuthProps &
  CreateStripeAccountLinkProps;

interface State {
  accountLinkUrl?: string;
}

class StripeConnectRedirect extends PureComponent<Props, State> {
  state: State = {};

  async componentDidMount() {
    if (!this.props.isLoggedIn) return;

    const accountLinkUrl = await this.props.createStripeAccountLink({
      returnUrl: this.props.location.state && this.props.location.state.from ? `${Globals.WEB_MANAGE_DOMAIN}${this.props.location.state.from}` : `${Globals.WEB_MANAGE_DOMAIN}/settings/account`,
      refreshUrl: `${Globals.WEB_MANAGE_DOMAIN}/outbound/stripe/${this.props.match.params.accountLinkType}`,
      type: StripeAccountLinkTypes[`${_.toUpper(this.props.match.params.accountLinkType)}`],
    });

    this.setState({ accountLinkUrl });
  }

  render() {
    if (!this.props.isLoggedIn || !this.state.accountLinkUrl) {
      return (
        <ManageStage>
          <LoadingVisual />
        </ManageStage>
      );
    }

    return (
      <Redirect to={this.state.accountLinkUrl} />
    );
  }
}

export default compose(
  withAuth,
  withCreateStripeAccountLink,
)(StripeConnectRedirect);
