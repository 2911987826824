import React, { Component } from 'react';
import _ from 'lodash';
import { canUseDOM } from 'exenv';

import LightboxViewModal from '~tools/react/components/LightboxViewModal';
import Carousel from '~tools/react/components/Carousel';
import Image from '~tools/react/components/utility/Image';
import BackgroundImage from '~tools/react/components/BackgroundImage';

interface Props {
  isMobile: boolean;
  isOpen: boolean;
  photos: {
    position: number;
    tags?: string[];
    url: string;
  }[];
  headline: string;
  subheadline?: string;
  onClose?: () => void;
}

interface State {
  selectedSlideIndex: number;
}

class ImageModal extends Component<Props, State> {
  state: State = {
    selectedSlideIndex: 0,
  };

  handleSelectNextSlide = () => {
    this.setState({ selectedSlideIndex: this.state.selectedSlideIndex + 1 });
  };

  handleSelectPreviousSlide = () => {
    this.setState({ selectedSlideIndex: this.state.selectedSlideIndex - 1 });
  };

  handleSelectSlide = (index: number) => {
    this.setState({ selectedSlideIndex: index });
  }

  handleClose = () => {
    this.setState({ selectedSlideIndex: 0 });
    if (this.props.onClose) this.props.onClose();
  }

  render() {
    return (
      <LightboxViewModal
        headline={this.props.headline}
        isMobile={this.props.isMobile}
        isOpen={this.props.isOpen}
        onClose={this.handleClose}
        onNext={this.state.selectedSlideIndex < this.props.photos.length - 1 ? this.handleSelectNextSlide : undefined}
        onPrevious={this.state.selectedSlideIndex > 0 ? this.handleSelectPreviousSlide : undefined}
        onSelectSlide={this.handleSelectSlide}
        photos={this.props.photos}
        selectedSlideIndex={this.state.selectedSlideIndex}
        subheadline={this.props.subheadline || ''}>
        <Carousel
          activeChildIndex={this.state.selectedSlideIndex}
          hasKeyEvents={!this.props.isMobile}
          onSelectNext={this.handleSelectNextSlide}
          onSelectPrevious={this.handleSelectPreviousSlide}
          shouldFadeSiblings={!this.props.isMobile}
          shouldLoop={this.props.isMobile}
          shouldMountSiblings={!this.props.isMobile}
          slideGapSize={!this.props.isMobile ? Carousel.enums.SlideGapSizes.Medium : undefined}>
          {_.map(_.sortBy(this.props.photos, p => p.position), photo => (
            <Carousel.CarouselSlide key={photo.url}>
              {this.props.isMobile ? (
                <Image
                  isLazy={canUseDOM}
                  key={photo.url}
                  size={{ w: 400 }}
                  src={photo.url}
                />
              ) : (
                <BackgroundImage
                  backgroundSize="auto 100%"
                  isLazy={canUseDOM}
                  key={photo.url}
                  size={{ h: this.props.isMobile ? 400 : 1000, w: this.props.isMobile ? 450 : undefined }}
                  src={photo.url}
                />
              )}
            </Carousel.CarouselSlide>
          ))}
        </Carousel>
      </LightboxViewModal>
    );
  }
}

export default ImageModal;
