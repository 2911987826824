import CreateUploadMutation from '~tools/react/graphql/mutations/uploads/createUpload.gql';
import withMutation from '~tools/react/graphql/withMutation';

import { UploadTags } from '~tools/types/graphqlSchema';

export interface Upload {
  createdAt: string;
  filename: string;
  fileType: string;
  tag: UploadTags;
  updatedAt: string;
  url: string;
  uuid: string;
}

interface CommonInputFields {
  filename: string;
  url: string;
  fileType: string;
}

interface InputWithTag extends CommonInputFields {
  tag: UploadTags;
}

interface InputWithDocumentTypeUuid extends CommonInputFields {
  documentTypeUuid: string;
}

export type CreateUploadInput = InputWithTag | InputWithDocumentTypeUuid;

interface Data {
  createUpload: Upload;
}

interface Variables {
  input: CreateUploadInput;
}

export interface CreateUploadProps {
  createUpload: (input: CreateUploadInput) => Promise<Upload>;
}

export { UploadTags };

export default withMutation<{}, Data, Variables, CreateUploadProps>(CreateUploadMutation, {
  props: props => ({
    createUpload: async (input) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating upload.');

      return res.data.createUpload;
    },
  }),
});
