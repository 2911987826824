export enum Colors {
  Blue = 'BLUE',
  Gray = 'GRAY',
  Orange = 'ORANGE',
}

export enum Styles {
  Checkbox = 'CHECKBOX',
  Toggle = 'TOGGLE',
}
