// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._yprVeR{bottom:0;left:0;overflow:hidden;position:absolute;right:0;top:0}", ""]);
// Exports
exports.locals = {
	"confetti": "_yprVeR"
};
module.exports = exports;
