import _ from 'lodash';
import slugify from 'slugify';

slugify.extend({ '/': '-' });

export const formatAsNumber = (value: number | string) => {
  let stringNum = '';
  if (typeof value === 'string') {
    stringNum = parseInt(value, 10).toString();
  } else if (typeof value === 'number') {
    stringNum = value.toFixed(0).toString();
  }

  return stringNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatAsUSD = (amount: number | string, isFloat?: boolean): string => {
  if (!amount && amount !== 0) return '';

  let stringNum = '';
  if (typeof amount === 'string') {
    stringNum = parseInt(amount, 10).toString();
    if (isFloat) stringNum = parseFloat(amount).toFixed(2);
  } else if (typeof amount === 'number') {
    stringNum = amount.toFixed(0).toString();
    if (isFloat) stringNum = parseFloat(amount.toString()).toFixed(2);
  }

  return stringNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatAsSlug = (value: string) => {
  return slugify(_.lowerCase(value)).replace(/[^a-zA-Z0-9-]/g, '');
};
