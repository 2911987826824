import dayjs from 'dayjs';
import _ from 'lodash';
import queryString from 'query-string';
import React, { Fragment, PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { RentPaymentRoles, TransactionStatuses, TransactionTypes } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';
import { pageToCursor } from '~tools/react/graphql/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withAuth, { AuthProps } from '~tools/react/hocs/withAuth';
import { formatAsUSD } from '~tools/utils/string';
import { formatTimestamp } from '~tools/utils/time';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';
import IconBlock from '~web-manage/lib/common/components/IconBlock';

import Avatar from '~tools/react/components/Avatar';
import ArrowLink from '~tools/react/components/ArrowLink';
import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Table from '~tools/react/components/Table';
import Tag from '~tools/react/components/Tag';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import * as tagEnums from '~tools/react/components/Tag/enums';

import Icon from '~web-manage/lib/common/components/Icon';

import PayoutModal from './containers/PayoutModal';

import query from './Revenue.gql';

const NUMBER_OF_RENT_PAYMENTS_TO_FETCH = 20;

interface RouteParams {
  propertyManagerContractUuid: string;
  tab: string;
}

interface CreditTransaction {
  createdAt: string;
  payout: {
    createdAt: string;
    status: TransactionStatuses;
    succeededAt: string | null;
    uuid: string;
  } | null;
  reversedAt: string | null;
  status: TransactionStatuses;
  succeededAt: string | null;
  uuid: string;
}

interface RentPayment {
  amountInCents: number;
  createdAt: string;
  creditTransaction: CreditTransaction | null;
  debitTransaction: {
    availableAt: string | null;
    createdAt: string;
    refundedAt: string | null;
    status: TransactionStatuses;
    succeededAt: string | null;
    uuid: string;
  } | null;
  payer: {
    fullName: string;
    photoUrl: string;
    uuid: string;
  };
  lease: {
    addressUnit: {
      address: {
        streetAddress1: string;
        uuid: string;
      };
      name: string | null;
      uuid: string;
    };
    uuid: string;
  };
  uuid: string;
}

interface Transaction {
  amountInCents: number;
  uuid: string;
}

interface Viewer {
  createdAt: string;
  fullName: string;
  rentPayments: RentPayment[];
  rentPaymentsTotal: number;
  rentPaymentsPageInfo: {
    endCursor: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string | null;
  } | null;
  stripeConnectAccount: {
    externalAccounts: {
      accountHolderName: string;
      bankName: string;
      currency: string;
      defaultForCurrency: boolean;
      id: string;
      last4: string;
    }[];
    id: string;
    payoutsEnabled: boolean;
    requirements: {
      currentlyDue: string[];
      disabledReason: string;
      pastDue: string[];
    };
  } | null;
  stripeConnectAccountBalance: {
    available: {
      amount: number;
      currency: string;
    }[];
  } | null;
  transactions: Transaction[];
  uuid: string;
}

interface QueryProps {
  isLoading: boolean;
  balanceAmountInCents: number;
  inTransitAmountInCents: number;
  rentPayments: RentPayment[];
  rentPaymentsTotal: number;
  rentPaymentsPageInfo: {
    endCursor: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string | null;
  } | null;
  viewer: Viewer | null;
}

type InputProps =
  RouteComponentProps<RouteParams> &
  AuthProps;

type Props =
  InputProps &
  QueryProps;

interface State {
  selectedRentPaymentUuid?: string;
  isPayoutModalOpen: boolean;
}

class Revenue extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isPayoutModalOpen: false,
    };
  }

  handleClosePayoutModal = () => this.setState({
    isPayoutModalOpen: false,
    selectedRentPaymentUuid: undefined,
  });
  handleOpenPayoutModal = (rentPaymentUuid?: string) => {
    if (!rentPaymentUuid) return;

    this.setState({
      isPayoutModalOpen: true,
      selectedRentPaymentUuid: rentPaymentUuid,
    });
  };

  render() {
    const viewer = this.props.viewer;
    const rentPayments = viewer?.rentPayments || [];

    const payoutAccount = _.find(
      viewer?.stripeConnectAccount?.externalAccounts,
      (externalAccount) => externalAccount.currency === 'usd' && externalAccount.defaultForCurrency,
    );

    return (
      <ManageStage>
        <PayoutModal
          isOpen={this.state.isPayoutModalOpen}
          onClose={this.handleClosePayoutModal}
          rentPaymentUuid={this.state.selectedRentPaymentUuid ?? null}
        />
        <Breadcrumbs
          items={[
            { path: `/finances`, label: 'Finances' },
            { path: `/revenue`, label: 'Revenue' },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
        <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
          <div>
            <Heading
              content="Revenue"
              font={Heading.enums.Fonts.Secondary}
              isMarkdown
              size={Heading.enums.Sizes.Small}
            />
          </div>
          <Button
            align={Button.enums.Alignments.Right}
            color={Button.enums.Colors.Gray}
            icon={Button.enums.Icons.Edit}
            label="Update payout settings"
            link={{
              path: `/settings/payouts`,
            }}
            size={Button.enums.Sizes.Small}
            style={Button.enums.Styles.Outline}
          />
        </Row>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
        <Row
          flexBehavior={Row.enums.FlexBehaviors.Grow}>
          <Card
            isLoading={this.props.isLoading}
            shadow={Card.enums.Shadows.Small}>
            <Card.CardSection spacing={Card.CardSection.enums.Spacing.Small}>
              <Heading
                content="Balance"
                color={Heading.enums.Colors.Secondary}
                font={Heading.enums.Fonts.Secondary}
                size={Heading.enums.Sizes.XXSmall}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
              <Heading
                content={`$${formatAsUSD(this.props.balanceAmountInCents / 100, true)} *USD*`}
                font={Heading.enums.Fonts.Secondary}
                isMarkdown
                size={Heading.enums.Sizes.XSmall}
              />
            </Card.CardSection>
            <Card.CardSection spacing={Card.CardSection.enums.Spacing.Small}>
              <Row
                flexBehavior={Row.enums.FlexBehaviors.Default}
                verticalAlignment={Row.enums.VerticalAlignments.Center}>
                <Heading
                  content="*In transit to bank*"
                  font={Heading.enums.Fonts.Secondary}
                  isMarkdown
                  size={Heading.enums.Sizes.XXXSmall}
                  color={Heading.enums.Colors.Secondary}
                />
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXSmall} />
                <Icon
                  svg={Icon.enums.SVGs.ExternalSend}
                  color={Icon.enums.Colors.Gray}
                  size={{
                    height: '15px',
                    width: '15px',
                  }}
                />
              </Row>
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
              <Heading
                content={`$${formatAsUSD(this.props.inTransitAmountInCents / 100, true)} *USD*`}
                font={Heading.enums.Fonts.Secondary}
                isMarkdown
                size={Heading.enums.Sizes.XXSmall}
              />
            </Card.CardSection>
          </Card>
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Large} />
          <Card
            isLoading={this.props.isLoading}
            shadow={Card.enums.Shadows.Small}>
            <Card.CardSection
              spacing={Card.CardSection.enums.Spacing.Small}>
              <Heading
                content="Payout account"
                color={Heading.enums.Colors.Secondary}
                font={Heading.enums.Fonts.Secondary}
                size={Heading.enums.Sizes.XXSmall}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
              {viewer && payoutAccount ? (
                <Fragment>
                  <Row
                    flexBehavior={Row.enums.FlexBehaviors.Default}
                    verticalAlignment={Row.enums.VerticalAlignments.Center}>
                    <IconBlock
                      icon={IconBlock.enums.Icons.Bank}
                      color={IconBlock.enums.Colors.Gray}
                    />
                    <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                    <div>
                      <Text
                        content={payoutAccount.bankName}
                        size={Text.enums.Sizes.Small}
                        isEmphasized
                      />
                      <Row
                        flexBehavior={Row.enums.FlexBehaviors.Default}
                        verticalAlignment={Row.enums.VerticalAlignments.Center}>
                        <Text
                          content={`••••• ${payoutAccount.last4}`}
                          font={Text.enums.Fonts.Monospace}
                          size={Text.enums.Sizes.Medium}
                        />
                        <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                        <Tag
                          label={_.toUpper(payoutAccount.currency)}
                          color={Tag.enums.Colors.Gray}
                          size={Tag.enums.Sizes.Small}
                        />
                      </Row>
                    </div>
                  </Row>
                  <VerticalSpacing
                    size={VerticalSpacing.enums.Sizes.Medium}
                  />
                  <ArrowLink
                    color={ArrowLink.enums.Colors.Blue}
                    label="Change payout account"
                    link={{
                      path: '/settings/payouts',
                    }}
                    size={ArrowLink.enums.Sizes.Small}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <Row
                    flexBehavior={Row.enums.FlexBehaviors.Default}
                    verticalAlignment={Row.enums.VerticalAlignments.Center}>
                    <IconBlock
                      icon={IconBlock.enums.Icons.DollarSign}
                      color={IconBlock.enums.Colors.Gray}
                    />
                    <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                    <Text
                      content="You haven't added a payout account yet."
                      size={Text.enums.Sizes.Medium}
                      color={Text.enums.Colors.Secondary}
                    />
                  </Row>
                  <VerticalSpacing
                    size={VerticalSpacing.enums.Sizes.Medium}
                  />
                  <ArrowLink
                    color={ArrowLink.enums.Colors.Blue}
                    label="Complete your account setup to receive payouts"
                    link={{
                      path: '/settings/payouts',
                    }}
                    size={ArrowLink.enums.Sizes.Small}
                  />
                </Fragment>
              )}
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
            </Card.CardSection>
          </Card>
        </Row>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
        <Card
          header={{
            title: 'Transactions',
          }}>
          <Card.CardSection
            spacing={Card.CardSection.enums.Spacing.None}>
            <Table>
              <Table.TableHead>
                <Table.TableRow>
                  <Table.TableHeader>
                    Date paid
                  </Table.TableHeader>
                  <Table.TableHeader
                    alignment={Table.TableHeader.enums.Alignments.RIGHT}>
                    Amount
                  </Table.TableHeader>
                  <Table.TableHeader />
                  <Table.TableHeader>
                    Payment
                  </Table.TableHeader>
                  <Table.TableHeader>
                    Unit
                  </Table.TableHeader>
                  <Table.TableHeader>
                    Tenant
                  </Table.TableHeader>
                  <Table.TableHeader>
                    Payout
                  </Table.TableHeader>
                  <Table.TableHeader />
                </Table.TableRow>
              </Table.TableHead>
              <Table.TableBody>
                {rentPayments.length > 0 ? (
                  <Fragment>
                    {_.map(rentPayments, (rentPayment) => {
                      const availableAt = rentPayment.debitTransaction?.availableAt;
                      let paymentStatus: TransactionStatuses | 'REFUNDED' | 'CONFIRMING' = rentPayment.debitTransaction?.refundedAt ? 'REFUNDED' : (rentPayment.debitTransaction?.status || TransactionStatuses.FAILED);
                      if (paymentStatus === TransactionStatuses.SUCCEEDED && availableAt && dayjs(availableAt).isAfter(dayjs())) {
                        paymentStatus = 'CONFIRMING';
                      }
                      return (
                        <Table.TableRow
                          id={rentPayment.uuid}
                          onClick={this.handleOpenPayoutModal}
                          key={rentPayment.uuid}>
                          <Table.TableData>
                            <Text
                              content={formatTimestamp(rentPayment.createdAt, 'MMM D, YYYY, h:mm A')}
                              size={Text.enums.Sizes.Small}
                            />
                          </Table.TableData>
                          <Table.TableData
                            alignment={Table.TableData.enums.Alignments.RIGHT}
                            width={Table.TableData.enums.Widths.MINIMIZED}>
                            <Text
                              content={`*$${formatAsUSD(rentPayment.amountInCents / 100, true)}*`}
                              isMarkdown
                            />
                          </Table.TableData>
                          <Table.TableData width={Table.TableHeader.enums.Widths.MINIMIZED}>
                            <Text
                              content="USD"
                              size={Text.enums.Sizes.Small}
                              color={Text.enums.Colors.Secondary}
                              isMarkdown
                            />
                          </Table.TableData>
                          <Table.TableData>
                            <Tag
                              label={paymentStatus}
                              color={
                                rentPayment.debitTransaction?.refundedAt ?
                                  Tag.enums.Colors.Gray :
                                  this.getTagColorForStatus(paymentStatus)
                              }
                              icon={
                                rentPayment.debitTransaction ?
                                  this.getPaymentIcon(paymentStatus) :
                                  undefined
                              }
                              size={Tag.enums.Sizes.Small}
                            />
                          </Table.TableData>
                          <Table.TableData width={Table.TableData.enums.Widths.AUTO}>
                            <Text
                              content={`${rentPayment.lease.addressUnit.address.streetAddress1}${rentPayment.lease.addressUnit.name ? `, *${rentPayment.lease.addressUnit.name}*` : ''}`}
                              isMarkdown
                              size={Text.enums.Sizes.Small}
                            />
                          </Table.TableData>
                          <Table.TableData width={Table.TableData.enums.Widths.AUTO}>
                            <Avatar
                              size={Avatar.enums.Sizes.XSmall}
                              imageUrl={rentPayment.payer.photoUrl}
                            />
                            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                            <Text
                              content={rentPayment.payer.fullName}
                              shouldWrap={false}
                              size={Text.enums.Sizes.Small}
                            />
                          </Table.TableData>
                          <Table.TableData>
                            {rentPayment.creditTransaction ? (
                              <Tag
                                label={this.getPayoutStatus(rentPayment.creditTransaction)}
                                color={
                                  rentPayment.creditTransaction?.payout ?
                                    this.getTagColorForStatus(rentPayment.creditTransaction.payout.status) :
                                    undefined
                                }
                                icon={
                                  rentPayment.creditTransaction?.payout ?
                                    this.getPayoutIcon(rentPayment.creditTransaction.payout.status) :
                                    undefined
                                }
                                size={Tag.enums.Sizes.Small}
                              />
                            ) : (
                              <Text
                                content="-"
                              />
                            )}
                          </Table.TableData>
                        </Table.TableRow>
                      );
                    })}
                    {this.props.rentPaymentsPageInfo ? (
                      <Table.TablePaginator
                        startCursor={this.props.rentPaymentsPageInfo.startCursor || ''}
                        endCursor={this.props.rentPaymentsPageInfo.endCursor || ''}
                        resultsPerPage={NUMBER_OF_RENT_PAYMENTS_TO_FETCH}
                        totalResults={this.props.rentPaymentsTotal}
                      />
                    ) : null}
                  </Fragment>
                ) : (
                  <Table.TableEmptyState
                    label="Your payments and payouts will show up here as they come in."
                  />
                )}
              </Table.TableBody>
            </Table>
          </Card.CardSection>
        </Card>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
      </ManageStage>
    );
  }

  getTagColorForStatus = (status: TransactionStatuses | 'REFUNDED' | 'CONFIRMING'): tagEnums.Colors | undefined => {
    switch (status) {
      case TransactionStatuses.SUCCEEDED:
      case TransactionStatuses.PAID:
        return tagEnums.Colors.Green;
      case TransactionStatuses.CANCELED:
      case TransactionStatuses.FAILED:
        return tagEnums.Colors.Red;
      case 'CONFIRMING':
        return tagEnums.Colors.Blue;
      case TransactionStatuses.IN_TRANSIT:
      case TransactionStatuses.PENDING:
      default:
        return tagEnums.Colors.Gray;
    }
  }

  getPaymentIcon = (status: TransactionStatuses | 'REFUNDED' | 'CONFIRMING') => {
    switch (status) {
      case 'CONFIRMING':
      case TransactionStatuses.PENDING: {
        return Tag.enums.Icons.Clock;
      }
      case TransactionStatuses.FAILED: {
        return Tag.enums.Icons.Alert;
      }
      default: {
        return undefined;
      }
    }
  }

  getPayoutStatus = (creditTransaction: CreditTransaction | null) => {
    if (creditTransaction?.payout?.status === TransactionStatuses.PENDING) {
      return _.startCase(TransactionStatuses.IN_TRANSIT);
    }

    if (creditTransaction?.payout) return _.startCase(creditTransaction.payout.status);
    if (creditTransaction) return creditTransaction.status;

    return TransactionStatuses.PENDING;
  }

  getPayoutIcon = (status: TransactionStatuses) => {
    switch (status) {
      case TransactionStatuses.IN_TRANSIT:
      case TransactionStatuses.PENDING: {
        return Tag.enums.Icons.Clock;
      }
      case TransactionStatuses.PAID: {
        return Tag.enums.Icons.ExternalSend;
      }
      case TransactionStatuses.FAILED: {
        return Tag.enums.Icons.Alert;
      }
      case TransactionStatuses.CANCELED: {
        return Tag.enums.Icons.Cross;
      }
      default: {
        return undefined;
      }
    }
  }
}

interface Response {
  viewer: Viewer | null;
}

export default compose(
  withAuth,
  withQuery<InputProps, Response, {}, QueryProps>(query, {
    options: (props) => {
      const pageString = queryString.parse(props.location.search).page;
      const page = _.parseInt(typeof pageString === 'string' ? pageString : '1') ?? undefined;
      const after = page > 1 ? pageToCursor(page, NUMBER_OF_RENT_PAYMENTS_TO_FETCH) : undefined;
      return {
        skip: !props.isLoggedIn,
        variables: {
          after,
          first: NUMBER_OF_RENT_PAYMENTS_TO_FETCH,
          rentPaymentsFilter: {
            role: {
              eq: RentPaymentRoles.PAYEE,
            },
          },
          transactionsFilter: {
            status: {
              eq: TransactionStatuses.IN_TRANSIT,
            },
            type: {
              eq: TransactionTypes.PAYOUT,
            },
          },
        },
      };
    },
    props: props => ({
      balanceAmountInCents: _.find(props.data?.viewer?.stripeConnectAccountBalance?.available, account => account.currency === 'usd')?.amount ?? 0,
      inTransitAmountInCents: _.sumBy(props.data?.viewer?.transactions, 'amountInCents'),
      isLoading: props.loading,
      rentPayments: props.data?.viewer?.rentPayments ?? [],
      rentPaymentsPageInfo: props.data?.viewer?.rentPaymentsPageInfo ?? null,
      rentPaymentsTotal: props.data?.viewer?.rentPaymentsTotal ?? 0,
      viewer: props.data?.viewer ?? null,
    }),
  }),
)(Revenue);
