import _ from 'lodash';
import React, { MouseEvent, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './Link.scss';

interface Props {
  ['data-text']?: string;
  altText?: string;
  children: ReactNode;
  className?: string;
  isDraggable?: boolean;
  isTabIndexed?: boolean;
  onClick?: (e: MouseEvent) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  shouldOpenNewTab?: boolean;
  state?: {
    [key: string]: any;
  };
  styleName?: string;
  to: string;
}

function Link(props: Props) {
  const shouldUseAnchorElement = props.to.includes('http')
    || props.to.startsWith('mailto:')
    || props.to.startsWith('#');

  if (shouldUseAnchorElement) {
    return (
      <a
        className={props.className}
        data-text={props['data-text']}
        draggable={props.isDraggable}
        href={props.to}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        rel={props.shouldOpenNewTab ? 'noopener noreferrer' : undefined}
        tabIndex={!props.isTabIndexed ? -1 : undefined}
        target={props.shouldOpenNewTab ? '_blank' : undefined}
        title={props.altText}>
        {props.children}
      </a>
    );
  }

  return (
    <RouterLink
      className={props.className}
      data-text={props['data-text']}
      draggable={props.isDraggable}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      rel={props.shouldOpenNewTab ? 'noopener noreferrer' : undefined}
      tabIndex={!props.isTabIndexed ? -1 : undefined}
      target={props.shouldOpenNewTab ? '_blank' : undefined}
      to={props.state ? {
        pathname: props.to,
        state: props.state,
      } : props.to}
      title={props.altText}>
      {props.children}
    </RouterLink>
  );
}

Link.defaultProps = {
  isTabIndexed: true,
};

export default withStyles(styles)(Link);
