import queryString from 'query-string';
import React, { PureComponent, ReactNode } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';

import withQuery from '~tools/react/graphql/withQuery';

import { compose } from '~tools/react/hocs/utils';
import withAuth, { AuthProps } from '~tools/react/hocs/withAuth';

import Stage from '~web-core/lib/common/stages/Stage';

import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import AuthView from './components/AuthView';
import FlipLogo from './components/FlipLogo';

import query from './AuthStage.gql';

interface InputProps extends RouteComponentProps {
  children: ReactNode;
  title: string;
}

interface State {
  redirectPath?: string;
}

type Props = InputProps & AuthProps & QueryProps;

class AuthStage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    let path: string | undefined;
    if (props.location.search) {
      const queryParams = queryString.parse(props.location.search);
      if (typeof queryParams.redirect === 'string') path = decodeURIComponent(queryParams.redirect);
    } else if (props.location.state && props.location.state.from) {
      path = props.location.state.from;
    }

    this.state = {
      redirectPath: path,
    };
  }

  render() {
    if (this.props.viewer?.uuid) {
      return (
        <Redirect to={this.state.redirectPath || '/'} />
      );
    }

    return (
      <Stage
        alert={null}
        hasAppBanner={false}
        hasFooter={false}
        hasNavigation={false}>
        <AuthView>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
          <FlipLogo />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXLarge} />
          <Text
            content={this.props.title}
            size={Text.enums.Sizes.XLarge}
          />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
          {this.props.children}
        </AuthView>
      </Stage>
    );
  }
}

interface Response {
  viewer: {
    uuid: string;
  } | null;
}

interface QueryProps {
  viewer: {
    uuid: string;
  } | null;
}

export default compose(
  withAuth,
  withRouter,
  withQuery<InputProps & AuthProps, Response, {}, QueryProps>(query, {
    options: props => ({
      skip: !props.isLoggedIn,
    }),
    props: props => ({
      viewer: props.data?.viewer || null,
    }),
  }),
)(AuthStage);
