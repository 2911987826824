// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._80AjMd{display:-webkit-box;display:flex;padding:16px 0;width:100%}._80AjMd ._mmJeQJ{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;height:24px}._80AjMd ._EGn9RB{-webkit-box-flex:1;flex:1;margin:0 0 0 16px}._80AjMd ._EGn9RB>p:first-child{line-height:26px}", ""]);
// Exports
exports.locals = {
	"checkable-list-item": "_80AjMd",
	"checkable-list-item__checkbox": "_mmJeQJ",
	"checkable-list-item__text": "_EGn9RB"
};
module.exports = exports;
