import _ from 'lodash';
import React, { Component } from 'react';

import { ListingBoostStatusTypes } from '~tools/types/graphqlSchema';

import graphql, { compose } from '~tools/react/graphql';
import withPauseListingBoost, { PauseListingBoostProps } from '~tools/react/graphql/mutations/listingBoosts/withPauseListingBoost';

import analytics from '~web-core/lib/common/utils/analytics';
import {
  PAUSED_BOOST_CAMPAIGN,
  VIEWED_PAUSE_BOOST_CONFIRMATION_MODAL,
} from '~web-core/lib/_common/constants/analytics';

import ModalStage from '~web-manage/lib/common/stages/ModalStage';

import Button from '~tools/react/components/Button';
import ConfirmationModal from '~tools/react/components/ConfirmationModal';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import PurchaseBoostModal from '~tools/react/containers/PurchaseBoostModal';

import BoostInsightsTableView from './components/BoostInsightsTableView';
import BoostInsight from './components/BoostInsight';

import query from './UnitMarketing.gql';

import {
  Listing,
} from './types';

interface InputProps {
  isLoading: boolean;
  propertyManagerContract: {
    listing: Listing;
    uuid: string;
  } | null;
  refetch: () => Promise<void>;
}

interface State {
  isPauseBoostModalOpen: boolean;
  isPurchaseBoostModalOpen: boolean;
  selectedPauseBoostUuid?: string;
}

type Props =
  InputProps &
  PauseListingBoostProps;

const VALID_BOOST_STATUSES = [
  ListingBoostStatusTypes.SUCCESS,
  ListingBoostStatusTypes.REFUNDED,
  ListingBoostStatusTypes.PARTIAL_REFUND,
  ListingBoostStatusTypes.PENDING,
];

class UnitMarketing extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isPauseBoostModalOpen: false,
      isPurchaseBoostModalOpen: false,
    };
  }

  handleClosePauseBoostModal = () => {
    analytics.track(VIEWED_PAUSE_BOOST_CONFIRMATION_MODAL, {
      boostType: 'DAILY',
    });

    this.setState({
      isPauseBoostModalOpen: false,
      selectedPauseBoostUuid: undefined,
    });
  }
  handleOpenPauseBoostModal = (selectedPauseBoostUuid?: string) => {
    this.setState({
      isPauseBoostModalOpen: true,
      selectedPauseBoostUuid: selectedPauseBoostUuid || this.state.selectedPauseBoostUuid,
    });
  }

  handleClosePurchaseBoostModal = () => {
    this.setState({ isPurchaseBoostModalOpen: false });
    this.props.refetch();
  }
  handleOpenPurchaseBoostModal = () => this.setState({ isPurchaseBoostModalOpen: true });

  handlePauseBoostConfirmation = async () => {
    if (!this.state.selectedPauseBoostUuid) return;

    await this.props.pauseListingBoost(this.state.selectedPauseBoostUuid);
    analytics.track(PAUSED_BOOST_CAMPAIGN, {
      boostType: 'DAILY',
    });
    await this.props.refetch();
    this.handleClosePauseBoostModal();
  }

  render() {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return null;

    const listing = propertyManagerContract.listing;
    const filteredBoosts = _.filter(listing.boosts, boost => _.includes(VALID_BOOST_STATUSES, boost.status));
    return (
      <ModalStage title="Unit marketing">
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXLarge} />
        {filteredBoosts.length > 0 ? (
          <BoostInsightsTableView>
            {_.map(filteredBoosts, boost => (
              <BoostInsight
                boost={boost}
                key={boost.uuid}
                onOpenPauseBoostModal={this.handleOpenPauseBoostModal}
              />
            ))}
            <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
              <Button
                align={Button.enums.Alignments.Right}
                icon={Button.enums.Icons.Plus}
                label="Purchase Boost"
                onClick={this.handleOpenPurchaseBoostModal}
              />
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Medium} />
            </Row>
          </BoostInsightsTableView>
        ) : null}
        <PurchaseBoostModal
          isOpen={this.state.isPurchaseBoostModalOpen}
          listingUuid={listing.uuid}
          onClose={this.handleClosePurchaseBoostModal}
        />
        <ConfirmationModal
          cancelAction={{
            label: 'Cancel',
            onClick: this.handleClosePauseBoostModal,
          }}
          confirmAction={{
            label: 'End campaign',
            onClick: this.handlePauseBoostConfirmation,
          }}
          isOpen={this.state.isPauseBoostModalOpen}
          onClose={this.handleClosePauseBoostModal}
          title="Are you sure you want to end your campaign?"
          description="This will permanently end your campaign and any associated recurring charges."
        />
      </ModalStage>
    );
  }
}

export default compose(
  graphql(query, {
    options: ({ match }) => ({
      variables: {
        propertyManagerContractUuid: match.params.propertyManagerContractUuid,
      },
      ssr: false,
    }),
    props: props => ({
      isLoading: props.data.loading,
      propertyManagerContract: props.data.viewer?.propertyManagerContract ?? null,
      refetch: props.data.refetch,
    }),
  }),
  withPauseListingBoost,
)(UnitMarketing);
