import loadable from '@loadable/component';
import _ from 'lodash';

import * as enums from '~tools/react/components/utility/Mapbox/enums';

import InteractiveMapControls from './components/InteractiveMapControls';
import InteractiveMapMarker from './components/InteractiveMapMarker';
import InteractiveMapRoute from './components/InteractiveMapRoute';

const LoadableInteractiveMap = loadable(() => import(/* webpackChunkName: "interactive-map" */'./InteractiveMap'));

export default _.assign(LoadableInteractiveMap, {
  enums,
  InteractiveMapControls,
  InteractiveMapMarker,
  InteractiveMapRoute,

  // Legacy support
  MapViewControls: InteractiveMapControls,
  MapViewMarker: InteractiveMapMarker,
  MapViewRoute: InteractiveMapRoute,
});
