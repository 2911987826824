// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._P9WRDA{display:-webkit-box;display:flex;-webkit-box-pack:justify;justify-content:space-between}._P9WRDA ._drDVpK{display:-webkit-box;display:flex;margin-right:16px}._P9WRDA ._drDVpK ._O0G3PQ{border:none;padding:0}._P9WRDA ._drDVpK ._O0G3PQ img{border:1px solid #e6e8eb;border-radius:5px;height:80px;-o-object-fit:cover;object-fit:cover;width:80px}._P9WRDA ._drDVpK ._RnE7dW{display:-webkit-box;display:flex;font-size:14px;font-weight:300;line-height:22.4px}._P9WRDA ._drDVpK ._RnE7dW ._99vOdr{border-left:1px solid #e6e8eb;padding-left:12px}._P9WRDA ._drDVpK ._RnE7dW ._99vOdr a{color:#8492a6}", ""]);
// Exports
exports.locals = {
	"report-row": "_P9WRDA",
	"report-row__left": "_drDVpK",
	"report-row__left__image": "_O0G3PQ",
	"report-row__left__links": "_RnE7dW",
	"report-row__left__links__view-photos": "_99vOdr"
};
module.exports = exports;
