import _ from 'lodash';

import { Events } from './enums';

import BugsnagAnalytics from './bugsnag';
import GoogleAnalytics from './ga';

import {
  AnalyticsIdentifyInput,
  AnalyticsTrackInput,
} from './types';

const platforms = {
  BugsnagAnalytics: new BugsnagAnalytics(),
  GoogleAnalytics: new GoogleAnalytics(),
};

export * from './enums';

export default {
  identify(uuid: string, properties?: AnalyticsIdentifyInput): void {
    _.map(platforms, (platform) => {
      platform.identify(uuid);
      if (properties) platform.set(properties);
    });
  },

  page(route: string): void {
    _.map(platforms, platform => platform.page(route));
  },

  track(event: Events, rawProperties?: AnalyticsTrackInput): void {
    const properties = rawProperties || {};
    _.map(platforms, platform => platform.track(event, properties));
  },
};
