import _ from 'lodash';
import classNames from 'classnames';
import React, { Component } from 'react';
import { matchPath, RouteComponentProps, withRouter } from 'react-router';
import isEqual from 'react-fast-compare';

import { compose } from '~tools/react/hocs/utils';
import withStyles from '~tools/react/hocs/withStyles';

import Link from '~tools/react/components/utility/Link';
import Button from '~tools/react/components/Button';

import { Check } from '~web-core/lib/common/svgs/icons/interface';

import styles from './BreadcrumbStepper.scss';

import * as enums from './enums';

interface BaseBreadcrumbStepperStep {
  isComplete: boolean;
  isDisabled?: boolean;
  label: string;
}

interface DisplayBreadcrumbStepperStep extends BaseBreadcrumbStepperStep {
  isActive: boolean;
}

interface ActionBreadcrumbStepperStep extends BaseBreadcrumbStepperStep {
  isActive: boolean;
  onClick: () => void;
}

interface LinkBreadcrumbStepperStep extends BaseBreadcrumbStepperStep {
  link: {
    path: string;
  };
}

export type BreadcrumbStepperStep =
  ActionBreadcrumbStepperStep |
  LinkBreadcrumbStepperStep |
  DisplayBreadcrumbStepperStep;

interface InputProps {
  alignment?: enums.Alignments;
  color?: enums.Colors;
  hasUnderline?: boolean;
  primaryLink?: {
    path: string;
    label: string;
  };
  size?: enums.Sizes;
  steps: BreadcrumbStepperStep[];
}

type Props =
  InputProps &
  RouteComponentProps;

class BreadcrumbStepper extends Component<Props> {
  static defaultProps = {
    hasUnderline: true,
  };
  static enums = enums;

  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(nextProps, this.props);
  }

  render() {
    return (
      <div
        className={classNames({
          [styles.stepper]: true,
          [styles[`stepper--size-${_.kebabCase(this.props.size)}`]]: this.props.size,
        })}>
        <div
          className={classNames({
            [styles.stepper__steps]: true,
            [styles[`stepper__steps--color-${_.kebabCase(this.props.color)}`]]: this.props.color,
            [styles[`stepper__steps--alignment-${_.kebabCase(this.props.alignment)}`]]: this.props.alignment,
          })}>
          {_.map(this.props.steps, (step, position) => (
            <div
              className={classNames({
                [styles.step]: true,
                [styles['step--active']]: 'isActive' in step ? step.isActive : matchPath(step.link.path, { exact: true, path: this.props.match.url }),
                [styles['step--complete']]: step.isComplete,
                [styles['step--disabled']]: step.isDisabled,
                [styles['step--underline']]: this.props.hasUnderline,
              })}
              key={step.label}>
              {'link' in step ? (
                <Link
                  styleName="step__link"
                  to={step.link.path}>
                  <i styleName="step__icon">{step.isComplete ? <Check /> : position + 1}</i>
                  {step.label}
                </Link>
              ) : null}
              {'onClick' in step ? (
                <button
                  onClick={step.onClick}
                  styleName="step__link">
                  <i styleName="step__icon">{step.isComplete ? <Check /> : position + 1}</i>
                  {step.label}
                </button>
              ) : null}
              {!('link' in step || 'onClick' in step) ? (
                <div styleName="step__link">
                  <i styleName="step__icon">{step.isComplete ? <Check /> : position + 1}</i>
                  {step.label}
                </div>
              ) : null}
            </div>
          ))}
        </div>
        {this.props.primaryLink ? (
          <Button
            align={Button.enums.Alignments.Right}
            color={this.props.color}
            icon={Button.enums.Icons.Download}
            link={{
              path: this.props.primaryLink.path,
            }}
            label={this.props.primaryLink.label}
          />
        ) : null}
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles),
)(BreadcrumbStepper);
