import React from 'react';

import Card from '~tools/react/components/Card';
import Spinner from '~tools/react/components/Spinner';
import withStyles from '~tools/react/hocs/withStyles';

import styles from './LoadingVisual.scss';

function LoadingVisual() {
  return (
    <Card>
      <Card.CardSection>
        <div styleName="loader">
          <Spinner />
        </div>
      </Card.CardSection>
    </Card>
  );
}

export default withStyles(styles)(LoadingVisual);
