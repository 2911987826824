import _ from 'lodash';
import isEqual from 'react-fast-compare';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { Component, MouseEvent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import { formatAsUSD } from '~tools/utils/string';
import { getSizeUrl } from '~web-core/lib/common/utils/photo';

import Button from '~tools/react/components/Button';
import ImageCarousel from '~tools/react/components/ImageCarousel';

import DoorSvg from './svgs/door-bw.svg';
import PlaceSvg from './svgs/place-bw.svg';
import BathSvg from './svgs/bath-bw.svg';
import BedSvg from './svgs/bed-bw.svg';

import styles from './ListingCard.scss';

interface Props {
  propertyManagerContractUuid?: string;
  listing: {
    description: string;
    earliestMoveInDate: string;
    images: {
      position: number;
      url: string;
      id: string;
    }[];
    locationString: string;
    numBathrooms: number;
    numBedrooms: number;
    rentInCents: number;
    title: string;
    type: string;
  };
}

class ListingCard extends Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return isEqual(this.props, nextProps);
  }

  handleClick = (e: MouseEvent<EventTarget>) => e.stopPropagation();

  render() {
    const images = _.map(_.take(this.props.listing.images, 5), (image) => {
      const newImage = _.clone(image);
      newImage.url = getSizeUrl(image.url, { fit: 'crop', width: 375, height: 300 });
      return newImage;
    });

    return (
      <div styleName="sidebar">
        <div styleName="sidebar__carousel">
          <ImageCarousel images={images} />
        </div>
        <div styleName="sidebar__left-border">
          <div styleName="sidebar__headline">
            <h5>{_.truncate(this.props.listing.title, { length: 50 })}</h5>
            <p>{this.props.listing.locationString}</p>
          </div>
          <div styleName="amenities">
            <div styleName="amenities__item">
              <BedSvg />
              {this.props.listing.numBedrooms ? `${this.props.listing.numBedrooms} ${pluralize('bedroom', this.props.listing.numBedrooms)}` : 'Studio'}
            </div>
            <div styleName="amenities__item">
              <BathSvg />
              {this.props.listing.numBathrooms} {pluralize('bathroom', this.props.listing.numBathrooms)}
            </div>
            <div styleName="amenities__item">
              {this.props.listing.type === 'Entire Place' ? <PlaceSvg /> : <DoorSvg />}
              {this.props.listing.type}
            </div>
          </div>
          <div styleName="sidebar__listing">
            <h5>Overview</h5>
            <p>{_.truncate(this.props.listing.description, { length: 100 })}</p>
          </div>
          <div styleName="sidebar__cta">
            <span>
              <p>
                <span>${formatAsUSD(this.props.listing.rentInCents)}</span> per month
              </p>
              <p>Move in {moment(this.props.listing.earliestMoveInDate).format('MMM D')} </p>
            </span>
            {this.props.propertyManagerContractUuid ? (
              <Button
                style={Button.enums.Styles.Outline}
                label="View unit details"
                link={{
                  path: `/units/${this.props.propertyManagerContractUuid}`,
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ListingCard);
