import _ from 'lodash';
import React, { Component, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import queryString from 'query-string';

import { PaymentSubscriptionPlans } from '~tools/types/graphqlSchema';

import Stage from '~web-core/lib/common/stages/Stage';

import { AddressUnit } from '~tools/react/graphql/mutations/addressUnits/withFetchOrCreateAddressUnit';

import BreadcrumbStepper from '~tools/react/components/BreadcrumbStepper';
import Row from '~tools/react/components/Row';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import WidthConstraintView from '~tools/react/components/WidthConstraintView';

import ExistingUnitsRedirect from './containers/ExistingUnitsRedirect';
import AddUnits from './containers/AddUnits';
import PurchaseDetails from './containers/PurchaseDetails';

import Logo from './components/FlipLogo';

interface InputProps {
  children: ReactNode;
}

type Props =
  InputProps &
  RouteComponentProps<{ plan?: string }>;

interface State {
  addressUnits: { [uuid: string]: AddressUnit; };
  step: number;
}

class LandlordStart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      addressUnits: {},
      step: 0,
    };
  }

  handleAddUnits = (addressUnits: {
    [uuid: string]: AddressUnit;
  }) => {
    this.setState({
      addressUnits,
      step: 1,
    });
  }

  handleSubscriptionCreated = () => {
    this.props.history.push('/');
  }

  render() {
    const parsedUrlQuery = queryString.parse(this.props.location.search);
    const rawPlan = Array.isArray(parsedUrlQuery.plan) ? parsedUrlQuery.plan.join('') : parsedUrlQuery.plan;
    const plan = _.toUpper(rawPlan || '') as PaymentSubscriptionPlans;
    return (
      <Stage
        alert={null}
        hasAppBanner={false}
        hasFooter={false}
        hasNavigation={false}
        shouldLoadSubscriptions={false}>
        <ExistingUnitsRedirect />
        <WidthConstraintView size={WidthConstraintView.enums.Sizes.Large}>
          <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
            <Logo />
            <BreadcrumbStepper
              alignment={BreadcrumbStepper.enums.Alignments.Right}
              hasUnderline={false}
              steps={[
                {
                  isActive: this.state.step === 0,
                  isComplete: this.state.step > 0,
                  isDisabled: true,
                  label: 'Add units',
                },
                {
                  isActive: this.state.step === 1,
                  isComplete: this.state.step > 1,
                  isDisabled: true,
                  label: 'Payment details',
                },
              ]}
            />
          </Row>
        </WidthConstraintView>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
        {this.state.step === 0 ? (
          <WidthConstraintView size={WidthConstraintView.enums.Sizes.Large}>
            <AddUnits
              paymentSubscriptionPlan={plan}
              onContinue={this.handleAddUnits}
            />
          </WidthConstraintView>
        ) : null}
        {this.state.step === 1 && plan ? (
          <WidthConstraintView size={WidthConstraintView.enums.Sizes.Large}>
            <PurchaseDetails
              paymentSubscriptionPlan={plan}
              addressUnits={this.state.addressUnits}
              onSubscriptionCreated={this.handleSubscriptionCreated}
            />
          </WidthConstraintView>
        ) : null}
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXLarge} />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXLarge} />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXLarge} />
      </Stage>
    );
  }
}

export default withRouter(LandlordStart);
