import React, { ComponentType } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import ArrowLink from '~tools/react/components/ArrowLink';

import IconBlock from '~web-manage/lib/common/components/IconBlock';

import ApplicationsSvg from '~web-core/lib/common/svgs/brand/applications.svg';
import LeasesSvg from '~web-core/lib/common/svgs/brand/leases.svg';
import MaintenanceSvg from '~web-core/lib/common/svgs/brand/maintenance.svg';

import * as enums from './enums';
import styles from './SettingCard.scss';

const enumToProductSVG = {
  [enums.Products.Applications]: ApplicationsSvg,
  [enums.Products.Leases]: LeasesSvg,
  [enums.Products.Maintenance]: MaintenanceSvg,
};

interface SharedProps {
  link: {
    path: string;
  };
  subtitle: string;
  title: string;
}

interface ProductProps extends SharedProps {
  product: enums.Products;
}

interface BusinessProps extends SharedProps {
  icon: enums.Icons;
}

type Props = ProductProps | BusinessProps;

function SettingCard(props: Props) {
  const ProductBlock = 'product' in props ? enumToProductSVG[props.product] : undefined;
  let arrowLinkColor = ArrowLink.enums.Colors.Blue;
  if ('product' in props) {
    switch (props.product) {
      case enums.Products.Applications:
        arrowLinkColor = ArrowLink.enums.Colors.Blue;
        break;
      case enums.Products.Leases:
        arrowLinkColor = ArrowLink.enums.Colors.Purple;
        break;
      case enums.Products.Maintenance:
        arrowLinkColor = ArrowLink.enums.Colors.Green;
        break;
    }
  }
  return (
    <div styleName="setting-card">
      {'icon' in props ? (
        <IconBlock icon={props.icon} />
      ) : null}
      {'product' in props ? (
        <ProductBlock styleName="setting-card__icon" />
      ) : null}
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
      <ArrowLink
        color={arrowLinkColor}
        label={props.title}
        link={props.link}
        size={ArrowLink.enums.Sizes.Medium}
      />
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
      <Text
        content={props.subtitle}
      />
    </div>
  );
}

export default withStyles(styles)(SettingCard) as ComponentType<Props>;
