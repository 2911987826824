import withMutation from '~tools/react/graphql/withMutation';
import { PhoneNumberTypes } from '~tools/types/graphqlSchema';

import DetachPhoneNumberFromPerson from './detachPhoneNumberFromPerson.gql';

interface PhoneNumber {
  phoneNumber: string;
  type: PhoneNumberTypes;
  uuid: string;
}

interface DetachPhoneNumberFromPerson {
  personUuid: string;
}

interface Data {
  detachPhoneNumberFromPerson: PhoneNumber;
}

interface Variables {
  input: DetachPhoneNumberFromPerson;
  uuid: string;
}

export interface DetachPhoneNumberFromPersonProps {
  detachPhoneNumberFromPerson: (uuid: string, input: DetachPhoneNumberFromPerson) => Promise<PhoneNumber>;
}

export default withMutation<{}, Data, Variables, DetachPhoneNumberFromPersonProps>(DetachPhoneNumberFromPerson, {
  props: props => ({
    detachPhoneNumberFromPerson: async (uuid, input) => {
      const res = await props.mutate({
        variables: {
          input,
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error detaching phone number.');

      return res.data.detachPhoneNumberFromPerson;
    }
  }),
});
