// @flow

import _ from 'lodash';

// import GoogleAnalytics from './ga';

import type {
  AnalyticsIdentifyType,
  AnalyticsTrackType,
} from '../../types';

const platforms = {
  // GoogleAnalytics: new GoogleAnalytics(),
};

const analytics = {
  identify(rawId: string | AnalyticsIdentifyType, rawProperties?: AnalyticsIdentifyType): void {
    let properties;
    let uuid;

    if (_.isString(rawId)) uuid = rawId;
    else if (_.isObject(rawId)) properties = _.clone(rawId);

    if (uuid && rawProperties) properties = _.clone(rawProperties);

    if (uuid) _.map(platforms, platform => platform.identify(uuid));
    if (properties) {
      properties = _.omit(properties, ['uuid']);
      _.map(platforms, platform => platform.set(properties));
    }
  },

  page(route: string): void {
    _.map(platforms, platform => platform.page(route));
  },

  track(event: string, rawProps?: AnalyticsTrackType): void {
    const props = rawProps || {};
    _.map(platforms, platform => platform.track(event, props));
  },
};

export default analytics;
