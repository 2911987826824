import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';

import { MessageTypes } from '~web-core/lib/common/types';

import Avatar from '~tools/react/components/Avatar';
import withStyles from '~tools/react/hocs/withStyles';
import BrandIcon from '~tools/svgs/brand/icon.svg';

import styles from './ThreadItem.scss';

import ThreadItemActionAttachment from './components/ThreadItemActionAttachment';
import ThreadItemMetadataAttachment from './components/ThreadItemMetadataAttachment';
import ThreadItemContentAnswerAttachment from './components/ThreadItemContentAnswerAttachment';
import ThreadItemPhotoAttachment from './components/ThreadItemPhotoAttachment';

interface Props {
  avatarUrl?: string;
  content: string;
  iconUrl?: string;
  title: string;
  pillLabel: string | null;
  timestamp: string;
  type: MessageTypes;
}

class ThreadItem extends PureComponent<Props> {
  static ThreadItemActionAttachment = ThreadItemActionAttachment;
  static ThreadItemMetadataAttachment = ThreadItemMetadataAttachment;
  static ThreadItemContentAnswerAttachment = ThreadItemContentAnswerAttachment;
  static ThreadItemPhotoAttachment = ThreadItemPhotoAttachment;

  render() {
    return (
      <div styleName="thread-item">
        <div styleName="thread-item__message">
          {this.props.avatarUrl && (
            <Avatar size={Avatar.enums.Sizes.Large} imageUrl={this.props.avatarUrl} />
          )}
          {this.props.iconUrl && (
            <div styleName="thread-item__message__icon">
              <img
                alt=""
                src={this.props.iconUrl}
              />
            </div>
          )}
          <div styleName="thread-item__message__text">
            <div styleName="thread-item__message__text__title">
              <p>{this.props.title}</p>
              {this.props.pillLabel && (
                <span
                  styleName={classNames({
                    'thread-item__message__text__title__pill-label': true,
                    'thread-item__message__text__title__pill-label__admin':
                      this.props.pillLabel && this.props.pillLabel.toLowerCase() === 'caretaker',
                  })}>
                  {this.props.pillLabel.toLowerCase() === 'caretaker' && <BrandIcon />}
                  {this.props.pillLabel}
                </span>
              )}
              <span styleName="thread-item__message__text__title__date">
                {moment(this.props.timestamp).format('h:mm A')}
              </span>
            </div>
            <div styleName={classNames({
              'thread-item__message__text__content': true,
              'thread-item__message__text__content--secondary': this.props.type === MessageTypes.ACTIVITY,
            })}>
              {this.props.content}
            </div>
          </div>
        </div>
        {this.props.children && (
          <div styleName="thread-item__attachments">
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

const StyledThreadItem = withStyles(styles)(ThreadItem);

export default _.assign(StyledThreadItem, {
  ThreadItemActionAttachment,
  ThreadItemContentAnswerAttachment,
  ThreadItemMetadataAttachment,
  ThreadItemPhotoAttachment,
});
