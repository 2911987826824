import withMutation from '~tools/react/graphql/withMutation';

import { CreateClaimInputType as CreateClaimInput } from '~tools/types/graphqlSchema';

import CreateClaim from './createClaim.gql';

interface Claim {
  amountInCents: number;
  createdAt: string;
  description: string;
  uuid: string;
}

interface Data {
  createClaim: Claim;
}

interface Variables {
  input: CreateClaimInput;
}

export interface CreateClaimProps {
  createClaim: (input: CreateClaimInput) => Promise<Claim>;
}

export default withMutation<{}, Data, Variables, CreateClaimProps>(CreateClaim, {
  props: props => ({
    createClaim: async (input: CreateClaimInput) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating claim.');

      return res.data.createClaim;
    },
  }),
});
