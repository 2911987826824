import React, { PureComponent } from 'react';

interface Props {
  minRows: number;
  maxRows: number;
  lineHeight: number;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  value: string;
}

interface State {
  rows: number;
}

class ResizableTextarea extends PureComponent<Props, State> {
  static defaultProps = {
    maxRows: 10,
    minRows: 1,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      rows: props.minRows,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== '' && this.props.value === '') {
      this.setState({ rows: 1 });
    }
  }

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { minRows, maxRows } = this.props;

    const previousRows = event.target.rows;
    event.target.rows = minRows;

    const currentRows = ~~(event.target.scrollHeight / this.props.lineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }

    this.setState({
      rows: currentRows < maxRows ? currentRows : maxRows,
    });

    this.props.onChange(event);
  }

  render() {
    return (
      <textarea
        onChange={this.handleChange}
        placeholder={this.props.placeholder}
        onKeyPress={this.props.onKeyPress}
        rows={this.state.rows}
        value={this.props.value}
      />
    );
  }
}

export default ResizableTextarea;
