import { ListingStatuses, ListListingStatusEnum } from '~tools/types/graphqlSchema';

import ListListingMutation from '~tools/react/graphql/mutations/listings/listListing.gql';
import withMutation from '~tools/react/graphql/withMutation';

interface Listing {
  uuid: string;
  slug: string;
  status: ListingStatuses;
}

interface Data {
  listListing: Listing;
}

interface ListListingInput {
  status: ListListingStatusEnum;
}

interface Variables {
  uuid: string;
  input: ListListingInput;
}

export interface ListListingProps {
  listListing: (uuid: string, input: ListListingInput) => Promise<Listing>;
}

export default withMutation<{}, Data, Variables, ListListingProps>(ListListingMutation, {
  props: props => ({
    listListing: async (uuid, input) => {
      const res = await props.mutate({
        variables: {
          input,
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error listing listing.');

      return res.data.listListing;
    },
  }),
});

export { ListListingStatusEnum };
