import _ from 'lodash';
import React, { PureComponent } from 'react';
import moment, { Moment } from 'moment-timezone';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withUpdateListing, { UpdateListingProps } from '~tools/react/graphql/mutations/listings/withUpdateListing';

import Button from '~tools/react/components/Button';
import Form, { Input } from '~tools/react/components/Form';
import ThemedModal from '~tools/react/components/ThemedModal';
import DatePicker from '~tools/react/components/DatePicker';

import query from './UpdateAvailabilityModal.gql';

interface InputProps {
  isOpen: boolean;
  listingUuid: string;
  onClose: () => void;
}

type Props =
  InputProps &
  QueryProps &
  UpdateListingProps;

interface State {
  earliestMoveInDate?: Moment;
  leaseEndDate?: Moment;
}

class UpdateAvailabilityModal extends PureComponent<Props, State> {
  state: State = {};

  handleSubmit = async () => {
    const listing = this.props.listing;
    if (!listing) return;

    const timezone = listing.addressUnit.address.timezone;
    const earliestMoveInDate = this.state.earliestMoveInDate || moment.tz(listing.earliestMoveInDate, timezone);
    const leaseEndDate = this.state.leaseEndDate || moment.tz(listing.leaseEndDate, timezone);
    const durationInMonths = leaseEndDate.diff(earliestMoveInDate, 'months');
    await this.props.updateListing(this.props.listingUuid, {
      leaseEndDate: leaseEndDate.toDate().toISOString(),
      earliestMoveInDate: earliestMoveInDate.toDate().toISOString(),
      maxLeaseTerm: durationInMonths,
      minLeaseTerm: durationInMonths,
    });
    this.props.onClose();
  };

  handleChangeEarliestMoveInDate = (value: Moment) => this.setState({ earliestMoveInDate: value });
  handleChangeLeaseEndDate = (value: Moment) => this.setState({ leaseEndDate: value });

  render() {
    const listing = this.props.listing;

    return (
      <ThemedModal
        width={ThemedModal.enums.Widths.Small}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title="Availability">
        <ThemedModal.ThemedModalSection>
          <Form onSubmit={this.handleSubmit}>
            <DatePicker
              label="Earliest available move in date"
              labelFormat={Input.enums.LabelFormats.Stacked}
              name="earliestMoveInDate"
              onChange={this.handleChangeEarliestMoveInDate}
              placeholder={moment().format('MM/DD/YYYY')}
              timezone={listing?.addressUnit.address.timezone}
              value={listing ? moment(listing.earliestMoveInDate) : undefined}
            />
            <DatePicker
              label="Latest possible move out date"
              labelFormat={Input.enums.LabelFormats.Stacked}
              name="leaseEndDate"
              onChange={this.handleChangeLeaseEndDate}
              placeholder={moment().add(1, 'year').format('MM/DD/YYYY')}
              timezone={listing?.addressUnit.address.timezone}
              value={listing ? moment(listing.leaseEndDate) : undefined}
            />
            <Button
              align={Button.enums.Alignments.Right}
              label="Save"
              size={Button.enums.Sizes.Small}
              type={Button.enums.Types.Submit}
            />
          </Form>
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

interface Listing {
  addressUnit: {
    address: {
      timezone: string;
      uuid: string;
    };
    uuid: string;
  };
  maxLeaseTerm: number | null;
  minLeaseTerm: number | null;
  earliestMoveInDate?: string;
  leaseEndDate?: string;
  updatedAt: string;
  uuid: string;
}

interface Viewer {
  listing: Listing;
  uuid: string;
}

interface Response {
  viewer: Viewer;
}

interface QueryProps {
  isLoading: boolean;
  listing: Listing | null;
}

export default compose(
  withUpdateListing,
  withQuery<InputProps, Response, {}, QueryProps>(query, {
    options: (props) => ({
      variables: {
        listingUuid: props.listingUuid,
      },
      skip: !props.isOpen,
    }),
    props: (props) => ({
      isLoading: props.loading,
      listing: props.data?.viewer.listing || null,
    }),
  }),
)(UpdateAvailabilityModal);
