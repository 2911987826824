import _ from 'lodash';
import classNames from 'classnames';
import memoize from 'memoize-one';
import React, { Component, ComponentType, Fragment } from 'react';
import isEqual from 'react-fast-compare';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import ChevronDown from '~tools/svgs/icons/interface/chevron-down.svg';

import { compose } from '~tools/react/hocs/utils';
import withDevice, { DeviceProps } from '~tools/react/hocs/withDevice';
import withStyles from '~tools/react/hocs/withStyles';

import ThemedModal from '~tools/react/components/ThemedModal';
import Button from '~tools/react/components/Button';
import DynamicHeightToggler from '~tools/react/components/DynamicHeightToggler';
import Heading from '~tools/react/components/Heading';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import VerticalMenuHeader from './components/VerticalMenuHeader';
import VerticalMenuLink from './components/VerticalMenuLink';

import * as enums from './enums';
import { Action } from './types';
import styles from './VerticalMenu.scss';

export interface VerticalMenuSection {
  links: {
    hasNotification?: boolean;
    isCompleted?: boolean;
    isDisabled?: boolean;
    isExact?: boolean;
    isHidden?: boolean;
    isNew?: boolean;
    label: string;
    path: string;
    secondaryLinks?: {
      hasNotification?: boolean;
      isCompleted?: boolean;
      isDisabled?: boolean;
      isExact?: boolean;
      isNew?: boolean;
      label: string;
      path: string;
    }[];
  }[];
  name?: string;
}

interface InputProps {
  color?: enums.Colors;
  header?: {
    backLink?: {
      path: string;
      label: string;
    };
    hasSpacing?: boolean;
    modalTitle?: string;
    title: string;
  };
  hasCollapsibleSections?: boolean;
  hasHeader?: boolean;
  hasArrows?: boolean;
  primaryButton?: Action;
  secondaryButton?: Action;
  sections: VerticalMenuSection[];
  stickyPosition?: {
    top: string;
  };
}

type Props =
  InputProps &
  DeviceProps &
  RouteComponentProps;

interface State {
  isOpen: boolean;
  openSectionName: string;
}

class VerticalMenu extends Component<Props, State> {
  static defaultProps = {
    color: enums.Colors.Blue,
    hasHeader: true,
    hasArrows: true,
  };
  static enums = enums;

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      openSectionName: props.hasCollapsibleSections ? this.getOpenSectionNameFromPath(props.sections, props.location.pathname) : '',
    };
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!isEqual(prevProps.sections, this.props.sections) && prevProps.hasCollapsibleSections) {
      const openSectionName = this.getOpenSectionNameFromPath(this.props.sections, this.props.location.pathname);
      if (openSectionName) this.setState({ openSectionName });
    }

    if (prevProps.location.pathname !== this.props.location.pathname && prevState.isOpen) {
      this.setState({ isOpen: false });
    }
  }

  handleSectionClick = (element) => {
    if (!element || !this.props.hasCollapsibleSections) return;

    if (element.currentTarget.id === this.state.openSectionName) {
      this.setState({ openSectionName: '' });
      return;
    }

    this.setState({ openSectionName: element.currentTarget.id });
  };

  handleToggle = () => this.setState({ isOpen: !this.state.isOpen });

  render() {
    const color = this.props.color || enums.Colors.Blue;
    const menu = (
      <nav styleName="vertical-menu" style={this.props.stickyPosition ? { position: 'sticky', top: this.props.stickyPosition.top } : {}}>
        {this.props.hasHeader && this.props.header ? (
          <Fragment>
            <VerticalMenuHeader
              backLink={this.props.header.backLink}
              title={this.props.header.title}
              hasSubheaders={this.props.sections.length > 1}
            />
            {this.props.header.hasSpacing !== false ? (
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
            ) : null}
          </Fragment>
        ) : null}
        <div styleName="vertical-menu-content-container-wrapper">
          <div styleName="vertical-menu__content-container">
            {_.map(this.props.sections, (section, index) => (
              <div
                key={section.name || `vertical-menu-${index}`}
                className={classNames({
                  [styles['vertical-menu__links']]: true,
                  [styles['vertical-menu__links--open']]: this.state.openSectionName === section.name || !section.name,
                  [styles['vertical-menu__links--collapsed']]: this.props.hasCollapsibleSections && this.state.openSectionName !== section.name && !!section.name,
                })}>
                {section.name ? (
                  <div // eslint-disable-line
                    onClick={this.handleSectionClick}
                    id={section.name}
                    styleName="vertical-menu__subheader">
                    <Heading
                      content={section.name}
                      size={Heading.enums.Sizes.XXSmall}
                      font={Heading.enums.Fonts.Secondary}
                    />
                    {this.props.hasArrows ? <ChevronDown styleName="chevron-down" /> : null}
                  </div>
                ) : null}
                <DynamicHeightToggler
                  isOpen={
                    !this.props.hasCollapsibleSections ||
                    this.state.openSectionName === section.name ||
                    !section.name
                  }>
                  {_.map(_.filter(section.links, l => !l.isHidden), (link) => {
                    let isExpanded = false;
                    let hasChildren = false;

                    // if any children are active, set this link as expanded
                    if (link.secondaryLinks && link.secondaryLinks.length) {
                      hasChildren = true;
                      _.forEach(link.secondaryLinks, (secondaryLink) => {
                        if (
                          this.props.location.pathname === secondaryLink.path ||
                          (!secondaryLink.isExact && this.props.location.pathname.indexOf(secondaryLink.path) !== -1)
                        ) {
                          isExpanded = true;
                        }
                      });
                    }

                    // if this link is active and has children, set it as expanded
                    const isActive = this.props.location.pathname === link.path ||
                      (!link.isExact && this.props.location.pathname.indexOf(link.path) === 0);
                    if (isActive && hasChildren) {
                      isExpanded = true;
                    }

                    return (
                      <VerticalMenuLink
                        color={color}
                        hasNotification={link.hasNotification}
                        isActive={this.props.location.pathname === link.path}
                        isCompleted={link.isCompleted}
                        isDisabled={link.isDisabled}
                        isExpanded={isExpanded}
                        isExact={link.isExact}
                        isNew={link.isNew}
                        key={link.label}
                        label={link.label}
                        path={link.path}>
                        {_.map(link.secondaryLinks, secondaryLink => (
                          <VerticalMenuLink
                            color={color}
                            hasNotification={secondaryLink.hasNotification}
                            isActive={this.props.location.pathname === secondaryLink.path}
                            isCompleted={secondaryLink.isCompleted}
                            isDisabled={link.isDisabled}
                            isExact={secondaryLink.isExact}
                            isNew={secondaryLink.isNew}
                            key={secondaryLink.label}
                            label={secondaryLink.label}
                            path={secondaryLink.path}
                            style={VerticalMenuLink.enums.Styles.Secondary}
                          />
                        ))}
                      </VerticalMenuLink>
                    );
                  })}
                </DynamicHeightToggler>
              </div>
            ))}
            <div styleName="vertical-menu__button-container">
              {this.props.primaryButton && 'onClick' in this.props.primaryButton ? (
                <Button
                  color={Button.enums.Colors.Blue}
                  style={Button.enums.Styles.Outline}
                  label={this.props.primaryButton.label}
                  onClick={this.props.primaryButton.onClick}
                  size={Button.enums.Sizes.Small}
                />
              ) : null}
              {this.props.primaryButton && 'path' in this.props.primaryButton ? (
                <Link to={this.props.primaryButton.path}>
                  <Button
                    color={Button.enums.Colors.Blue}
                    style={Button.enums.Styles.Outline}
                    label={this.props.primaryButton.label}
                    size={Button.enums.Sizes.Small}
                    width={Button.enums.Widths.Full}
                  />
                </Link>
              ) : null}
              {this.props.secondaryButton && 'onClick' in this.props.secondaryButton ? (
                <Button
                  color={Button.enums.Colors.Gray}
                  label={this.props.secondaryButton.label}
                  style={Button.enums.Styles.Outline}
                  onClick={this.props.secondaryButton.onClick}
                  size={Button.enums.Sizes.Small}
                />
              ) : null}
              {this.props.secondaryButton && 'path' in this.props.secondaryButton ? (
                <Link to={this.props.secondaryButton.path}>
                  <Button
                    color={Button.enums.Colors.Gray}
                    label={this.props.secondaryButton.label}
                    style={Button.enums.Styles.Outline}
                    size={Button.enums.Sizes.Small}
                    width={Button.enums.Widths.Full}
                  />
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </nav>
    );

    if (this.props.isMobile) {
      return (
        <Fragment>
          <button
            styleName="vertical-menu-toggler"
            onClick={this.handleToggle}>
            <Heading
              content={this.state.openSectionName || this.props.header?.title || 'Menu'}
              size={Heading.enums.Sizes.XSmall}
              font={Heading.enums.Fonts.Secondary}
            />
            <ChevronDown />
          </button>
          <ThemedModal
            title={this.props.header?.modalTitle || this.props.header?.title || 'Menu'}
            isOpen={this.state.isOpen}
            onClose={this.handleToggle}>
            <ThemedModal.ThemedModalSection>
              {menu}
            </ThemedModal.ThemedModalSection>
          </ThemedModal>
        </Fragment>
      );
    }

    return menu;
  }

  // eslint-disable-next-line react/sort-comp
  getOpenSectionNameFromPath = memoize((sections, pathname) => {
    let openSectionName;
    _.forEach(sections, (section) => {
      _.forEach(section.links, (link) => {
        if (openSectionName) return;
        if (pathname.indexOf(link.path) !== -1) openSectionName = section.name;
        _.forEach(link.secondaryLinks, (secondaryLink) => {
          if (openSectionName) return;
          if (pathname.indexOf(secondaryLink.path) !== -1) openSectionName = section.name;
        });
      });
    });

    return openSectionName || '';
  });
}

export default compose(
  withDevice,
  withRouter,
  withStyles(styles),
)(VerticalMenu) as ComponentType<InputProps>;
