import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Fragment, PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';

import {
  StreetDirectionAbbreviations,
  StreetGenericAbbreviations,
} from '~tools/constants/streets';

import { formatUnitName } from '~web-manage/lib/common/utils/addressUnit';

import { pageToCursor } from '~tools/react/graphql/utils';
import withQuery from '~tools/react/graphql/withQuery';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import Avatar from '~tools/react/components/Avatar';
import Card from '~tools/react/components/Card';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Text from '~tools/react/components/Text';
import Tag from '~tools/react/components/Tag';
import Table from '~tools/react/components/Table';
import Row from '~tools/react/components/Row';

import LoadingVisual from '~web-manage/lib/common/components/__deprecated/LoadingVisual';

import { Viewing } from './types';

import query from './Viewings.gql';

const NUMBER_OF_VIEWINGS_TO_FETCH = 10;

type InputProps = RouteComponentProps;

type Props = QueryProps & InputProps;

class Viewings extends PureComponent<Props> {
  render() {
    if (this.props.isLoading) {
      return (
        <ManageStage>
          <Card
            header={{ title: 'All viewings' }}>
            <LoadingVisual />
          </Card>
        </ManageStage>
      );
    }

    const viewings = this.props.viewings;
    return (
      <ManageStage>
        <Card
          header={{ title: 'All viewings' }}>
          <Table>
            <Table.TableHead>
              <Table.TableRow>
                <Table.TableHeader>Scheduled</Table.TableHeader>
                <Table.TableHeader />
                <Table.TableHeader>Date</Table.TableHeader>
                <Table.TableHeader>Time</Table.TableHeader>
                <Table.TableHeader>Unit</Table.TableHeader>
                <Table.TableHeader>Renter</Table.TableHeader>
                <Table.TableHeader />
              </Table.TableRow>
            </Table.TableHead>
            <Table.TableBody>
              {viewings.length > 0 ? (
                <Fragment>
                  {_.map(viewings, (viewing) => {
                    const addressUnit = viewing.listing.addressUnit;
                    const streetAddressParts = addressUnit.address.streetAddress1.split(' ');
                    const streetNumber = _.take(streetAddressParts, 1);
                    const streetNameParts = _.drop(streetAddressParts, 1);

                    const finalIndex = streetNameParts.length - 1;
                    const generic = _.toUpper(streetNameParts[finalIndex]);
                    const genericAbbreviation = _.upperFirst(_.toLower(StreetGenericAbbreviations[generic]));
                    streetNameParts[finalIndex] = genericAbbreviation || streetNameParts[finalIndex];
                    if (streetNameParts.length > 2) {
                      const direction = _.toUpper(streetNameParts[0]);
                      const directionAbbreviation = _.upperFirst(_.toLower(StreetDirectionAbbreviations[direction]));
                      streetNameParts[0] = directionAbbreviation ? `${directionAbbreviation}.` : streetNameParts[0];
                    }
                    const abbreviatedStreetAddress = `${streetNumber} ${streetNameParts.join(' ')}`;

                    const nameParts = viewing.account.fullName.split(' ');
                    const shortName = nameParts.length > 1 ? `${nameParts[0]} ${nameParts[1].charAt(0)}. ` : `${nameParts[0]} `;

                    return (
                      <Table.TableRow
                        key={viewing.uuid}
                        link={{ path: `/viewings/${viewing.uuid}` }}>
                        <Table.TableData>
                          <Text
                            content={`${moment(viewing.createdAt).fromNow()}`}
                            overflow={Text.enums.OverflowValues.Ellipsis}
                            shouldWrap={false}
                            size={Text.enums.Sizes.Small}
                          />
                        </Table.TableData>
                        <Table.TableData>
                          <Tag
                            color={Tag.enums.Colors.Green}
                            icon={Tag.enums.Icons.Check}
                            label="Confirmed"
                          />
                        </Table.TableData>
                        <Table.TableData>
                          <Text
                            content={`${moment.tz(viewing.calendarEvent.startsAt, viewing.listing.addressUnit.address.timezone).format('MMM D')}`}
                            overflow={Text.enums.OverflowValues.Ellipsis}
                            shouldWrap={false}
                            size={Text.enums.Sizes.Small}
                          />
                        </Table.TableData>
                        <Table.TableData>
                          <Text
                            content={`${moment.tz(viewing.calendarEvent.startsAt, viewing.listing.addressUnit.address.timezone).format('ha')} - ${moment.tz(viewing.calendarEvent.endsAt, viewing.listing.addressUnit.address.timezone).add(1, 'millisecond').format('ha')}`}
                            overflow={Text.enums.OverflowValues.Ellipsis}
                            shouldWrap={false}
                            size={Text.enums.Sizes.Small}
                          />
                        </Table.TableData>
                        <Table.TableData>
                          <Text
                            content={`${abbreviatedStreetAddress}${viewing.listing.addressUnit.name ? `, ${formatUnitName(viewing.listing.addressUnit)}` : ''}`}
                            overflow={Text.enums.OverflowValues.Ellipsis}
                            shouldWrap={false}
                            size={Text.enums.Sizes.Small}
                          />
                        </Table.TableData>
                        <Table.TableData>
                          <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                            <Avatar
                              size={Avatar.enums.Sizes.XSmall}
                              imageUrl={viewing.account.photoUrl}
                            />
                            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                            <Text
                              content={shortName}
                              overflow={Text.enums.OverflowValues.Ellipsis}
                              shouldWrap={false}
                              size={Text.enums.Sizes.Small}
                            />
                          </Row>
                        </Table.TableData>
                      </Table.TableRow>
                    );
                  })}
                  {this.props.viewingsPageInfo ? (
                    <Table.TablePaginator
                      endCursor={this.props.viewingsPageInfo.endCursor || ''}
                      resultsPerPage={NUMBER_OF_VIEWINGS_TO_FETCH}
                      startCursor={this.props.viewingsPageInfo.startCursor || ''}
                      totalResults={this.props.viewingsTotal}
                    />
                  ) : null}
                </Fragment>
              ) : (
                <Table.TableEmptyState
                  label="Once renters start booking viewings, you'll see all the details here!"
                />
              )}
            </Table.TableBody>
          </Table>
        </Card>
      </ManageStage>
    );
  }
}

interface PageInfo {
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
}

interface Response {
  viewer: {
    uuid: string;
    viewings: Viewing[];
    viewingsPageInfo: PageInfo;
    viewingsTotal: number;
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  viewings: Viewing[];
  viewingsPageInfo?: PageInfo;
  viewingsTotal: number;
}

export default withQuery<InputProps, Response, {}, QueryProps>(query, {
  options: (props) => {
    const pageString = queryString.parse(props.location.search).page;
    const page = _.parseInt(typeof pageString === 'string' ? pageString : '1') ?? undefined;
    const after = page ? pageToCursor(page, NUMBER_OF_VIEWINGS_TO_FETCH) : undefined;
    return {
      fetchPolicy: 'no-cache',
      variables: {
        after,
        first: NUMBER_OF_VIEWINGS_TO_FETCH,
      },
    };
  },
  props: props => ({
    isLoading: props.loading,
    viewings: props.data?.viewer?.viewings || [],
    viewingsPageInfo: props.data?.viewer?.viewingsPageInfo,
    viewingsTotal: props.data?.viewer?.viewingsTotal || 0,
  }),
})(Viewings);
