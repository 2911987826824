import classNames from 'classnames';
import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './MapView.scss';

interface Props {
  children: ReactNode;
  isHidden: boolean;
}

function MapView(props: Props) {
  return (
    <div
      className={classNames({
        [styles['map-view']]: true,
        [styles['map-view--hidden']]: props.isHidden,
      })}>
      <div styleName="map-view__child">
        {props.children}
      </div>
    </div>
  );
}

export default withStyles(styles)(MapView);
