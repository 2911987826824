// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._yprBwo{display:-webkit-box;display:flex;-webkit-box-flex:1;flex:1;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row;height:50px;-webkit-box-pack:justify;justify-content:space-between;min-height:50px;position:relative;z-index:2}._yprBwo ._x28unE{align-self:center;color:#3c4858;font-size:14px;font-weight:500;margin:0}._yprBwo ._prWPYG{display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row;height:35px;margin-top:auto}._yprBwo ._7RAz5B{align-self:center}", ""]);
// Exports
exports.locals = {
	"tab-header": "_yprBwo",
	"tab-header__title": "_x28unE",
	"tab-header__tabs": "_prWPYG",
	"tab-header__action": "_7RAz5B"
};
module.exports = exports;
