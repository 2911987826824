import { ApolloQueryResult, NetworkStatus } from 'apollo-client';
import React, { PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import withQuery from '~tools/react/graphql/withQuery';
import { compose } from '~tools/react/hocs/utils';

import { FlipStatuses, SearchableFilterInput } from '~tools/types/graphqlSchema';

import SearchTypeahead from './components/SearchTypeahead';
import { Icons as SearchableTypeaheadItemIcons } from './components/SearchTypeahead/components/SearchTypeaheadItem/enums';

import query from './Search.gql';

import { SearchableTypes } from './enums';
import { Searchable, SearchableItem } from './types';

type Props = RouteComponentProps & QueryProps;

interface State {
  currentSearch: string;
}

class Search extends PureComponent<Props, State> {
  state: State = { currentSearch: '' };

  onChangeSearch = (arg: string) => {
    this.setState({ currentSearch: arg });
    if (!arg) return;
    this.props.refetch({ filter: { smart: { icontains: arg } }, skip: false });
  }

  onSelectSearchableItem = (item: SearchableItem) => {
    this.props.history.push(item.path);
  }

  render() {
    const searchables = this.props.searchables;
    // eslint-disable-next-line array-callback-return
    const items = searchables.map((searchable) => {
      // eslint-disable-next-line no-underscore-dangle
      switch (searchable.__typename) {
        case SearchableTypes.PrivatePropertyManagerContract: {
          return {
            description: `${searchable.addressUnit.address.city}, ${searchable.addressUnit.address.state}`,
            label: `${searchable.addressUnit.address.streetAddress1} #${(searchable.addressUnit.name ?? '').replace('#', '')}`,
            icon: SearchableTypeaheadItemIcons.Unit,
            path: `/units/${searchable.uuid}`,
          };
        }
        case SearchableTypes.PrivateLease: {
          let lessorStatus = 'Tenant';
          if (searchable.application.status !== FlipStatuses.FLIPPED) lessorStatus = 'Applicant';
          return {
            description: `${lessorStatus} at ${searchable.addressUnit.address.streetAddress1}`,
            label: `${searchable.lessee.fullName}`,
            icon: SearchableTypeaheadItemIcons.Lease,
            path: `/leases/${searchable.uuid}`,
          };
        }
      }
    });

    return (
      <SearchTypeahead
        isLoading={this.props.networkStatus === NetworkStatus.setVariables}
        placeholder="Search units or leases..."
        onChange={this.onChangeSearch}
        onSelectItem={this.onSelectSearchableItem}
        items={this.state.currentSearch ? items : []}
        value={this.state.currentSearch}
      />
    );
  }
}

interface Variables {
  filter: SearchableFilterInput;
  skip: boolean;
}

interface Response {
  viewer: {
    uuid: string;
    manageSearchables: Searchable[];
  }
}

interface QueryProps {
  networkStatus: NetworkStatus;
  refetch: (args: Variables) => Promise<ApolloQueryResult<Response>>;
  searchables: Searchable[];
}

export default compose(
  withQuery<{}, Response, Variables, QueryProps>(query, {
    options: () => ({
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {
        filter: {},
        skip: true,
      },
    }),
    props: (props) => ({
      networkStatus: props.networkStatus,
      refetch: props.refetch,
      searchables: props.data?.viewer.manageSearchables ?? [],
    }),
  }),
  withRouter,
)(Search);
