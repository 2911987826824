import React from 'react';

import AuthStage from '~tools/react/stages/AuthStage';

import LoginForm from '~tools/react/containers/LoginForm';

import Center from '~tools/react/components/Center';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

function Login() {
  return (
    <AuthStage title="Sign in to your account">
      <LoginForm />
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
      <div>
        <Center>
          <HorizontalRule size={HorizontalRule.enums.Sizes.Small} />
        </Center>
      </div>
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
      <Text
        isMarkdown
        color={Text.enums.Colors.Secondary}
        content={`Don't have an account? [Sign up](/signup)`}
      />
    </AuthStage>
  );
}

export default Login;
