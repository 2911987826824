import _ from 'lodash';
import React, { Component } from 'react';

import Button from '~tools/react/components/Button';
import withStyles from '~tools/react/hocs/withStyles';

import Tab from './components/Tab';
import * as enums from './enums';

import styles from './TabHeader.scss';

interface Tab {
  hotKey?: string;
  id: string;
  isActive?: boolean;
  isExact?: boolean;
  label: string;
  path?: string;
}

interface Props {
  action?: {
    color?: enums.ActionColors;
    label: string;
    onClick: () => void;
  };
  onClickTab?: (tabName: string) => void;
  title?: string;
  tabs?: Tab[];
}

class TabHeader extends Component<Props> {
  static enums = enums;

  render() {
    return (
      <nav styleName="tab-header">
        {this.props.title ? <h4 styleName="tab-header__title">{this.props.title}</h4> : null}
        <div styleName="tab-header__tabs">
          {_.map(this.props.tabs, (tab) => (
            <Tab
              id={tab.id}
              isActive={tab.isActive}
              isExact={tab.isExact}
              key={tab.label}
              label={tab.label}
              onClickTab={this.props.onClickTab}
              path={tab.path}
            />
          ))}
        </div>
        {this.props.action ? (
          <div styleName="tab-header__action">
            <Button
              style={Button.enums.Styles.Outline}
              size={Button.enums.Sizes.XSmall}
              color={this.props.action.color ? this.props.action.color : Button.enums.Colors.Blue}
              onClick={this.props.action.onClick}
              label={this.props.action.label}
            />
          </div>
        ) : null}
      </nav>
    );
  }
}

export default withStyles(styles)(TabHeader);
