import { Offer } from './types';

export const getOfferValueAndCost = (offer: Offer | undefined, amountInCents: number) => {
  if (!offer) {
    return {
      costInCents: amountInCents,
      valueInCents: amountInCents,
      costInDollars: amountInCents / 100,
      valueInDollars: amountInCents / 100,
    };
  }

  const isActive = offer.isActive;
  const minimumInCents = offer.minimumThresholdInCents ? offer.minimumThresholdInCents : 0;
  const offerAmountInCents = offer.amountInCents ? offer.amountInCents : null;
  const percentAmount = offer.percentAmount;

  if (!isActive ||
      (amountInCents < minimumInCents) ||
      (offerAmountInCents && (amountInCents + offerAmountInCents) < 0) ||
      (offerAmountInCents && offerAmountInCents > amountInCents)) {
    return {
      costInCents: amountInCents,
      valueInCents: amountInCents,
      costInDollars: amountInCents / 100,
      valueInDollars: amountInCents / 100,
    };
  }

  let multiple = 1;
  if (offer.isMultipleOfMinimum && minimumInCents > 0) {
    multiple = Math.floor(amountInCents / minimumInCents);

    // Cap it at a 10x multiple, just in case minimum is something low
    if (multiple > 10) {
      multiple = 10;
    }
  }

  let cost = amountInCents;
  let value = amountInCents;

  if (offerAmountInCents) {
    const adjustedAmount = amountInCents + (offerAmountInCents * multiple);

    cost = (offerAmountInCents > 0) ? amountInCents : adjustedAmount;
    value = (offerAmountInCents > 0) ? adjustedAmount : amountInCents;
  }

  if (percentAmount) {
    const adjustedAmount = amountInCents + (amountInCents * (percentAmount / 100));

    cost = (percentAmount > 0) ? amountInCents : adjustedAmount;
    value = (percentAmount > 0) ? adjustedAmount : amountInCents;
  }

  return {
    costInCents: cost,
    valueInCents: value,
    costInDollars: cost / 100,
    valueInDollars: value / 100,
  };
};
