import classNames from 'classnames';
import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './StepLine.scss';

interface Props {
  isFilled?: boolean;
  hasGradient?: boolean;
}

function StepLine({ isFilled = false, hasGradient = false }: Props) {
  return (
    <div
      className={classNames({
        [styles['step-line']]: true,
        [styles['step-line--filled']]: isFilled,
        [styles['step-line--gradient']]: hasGradient,
      })}
    />
  );
}

export default withStyles(styles)(StepLine);
