// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._qroPQz{display:-webkit-box;display:flex}._orGRyD{background-size:cover;border-radius:10px;display:inline-block;height:48px;margin-right:8px;width:48px}", ""]);
// Exports
exports.locals = {
	"image-list": "_qroPQz",
	"image": "_orGRyD"
};
module.exports = exports;
