import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { ComponentType, useEffect, useState } from 'react';

import Center from '~tools/react/components/Center';
import Spinner from '~tools/react/components/Spinner';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import withCreateStripeSetupIntent, {
  CreateStripeSetupIntentProps,
} from '~tools/react/graphql/mutations/paymentMethods/withCreateStripeSetupIntent';
import withSetDefaultPaymentMethod, {
  SetDefaultPaymentMethodProps,
} from '~tools/react/graphql/mutations/paymentMethods/withSetDefaultPaymentMethod';
import { compose } from '~tools/react/hocs/utils';
import { StripeAccountHolderTypes, StripePaymentMethodTypes } from '~tools/types/graphqlSchema';

import Globals from '~web-core/lib/common/globals';

import PaymentElementForm from './components/PaymentElementForm';

const stripePromise = loadStripe(Globals.STRIPE_KEY);

interface InputProps {
  defaultValues: {
    name?: string;
    email?: string;
  };
  submitButtonText?: string;
  onAddPaymentMethod: (paymentMethodId: string) => unknown;
  paymentMethodTypes?: StripePaymentMethodTypes[];
  collectBillingDetails?: boolean;
  hideTerms?: boolean;
}

const FormComponent = (props: InputProps) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <PaymentElementForm
        defaultValues={props.defaultValues}
        elements={elements}
        onAddPaymentMethod={props.onAddPaymentMethod}
        stripe={stripe}
        submitButtonText={props.submitButtonText}
        hideTerms={props.hideTerms}
        collectBillingDetails={props.collectBillingDetails}
      />
    )}
  </ElementsConsumer>
);

type Props = InputProps & CreateStripeSetupIntentProps & SetDefaultPaymentMethodProps;

const PaymentElement = (props: Props) => {
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
  const resetClientSecret = async () => {
    const newClientSecret = await props.createStripeSetupIntent({
      accountHolderType: StripeAccountHolderTypes.CUSTOMER,
      paymentMethodTypes: props.paymentMethodTypes,
    });
    setClientSecret(newClientSecret);
  };

  const handleAddPaymentMethod = async (paymentMethodId: string) => {
    await props.setDefaultPaymentMethod(paymentMethodId);
    await props.onAddPaymentMethod(paymentMethodId);
    resetClientSecret();
  };

  useEffect(() => {
    resetClientSecret();
  }, []);

  if (!clientSecret) {
    return (
      <Center>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
        <Spinner />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
      </Center>
    );
  }

  return (
    <Elements
      key={clientSecret}
      options={{
        fonts: [
          {
            family: 'Avenir',
            src: "url('https://cdn.caretaker.com/fonts/avenir/Avenir-Medium.woff')",
          },
        ],
        appearance: {
          theme: 'stripe',
          variables: {
            borderRadius: '5px',
            colorPrimary: '#1F8EED',
          },
        },
        clientSecret,
      }}
      stripe={stripePromise}>
      <FormComponent
        collectBillingDetails={props.collectBillingDetails}
        defaultValues={props.defaultValues}
        onAddPaymentMethod={handleAddPaymentMethod}
        submitButtonText={props.submitButtonText}
        hideTerms={props.hideTerms}
      />
    </Elements>
  );
};

export default compose(
  withCreateStripeSetupIntent,
  withSetDefaultPaymentMethod,
)(PaymentElement) as ComponentType<InputProps>;
