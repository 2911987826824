// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._vqQ9B1._VYQrGo{border-right:1px solid #e6e8eb}._vqQ9B1._rrEKnD{border-bottom:1px solid #e6e8eb}", ""]);
// Exports
exports.locals = {
	"unit-panel": "_vqQ9B1",
	"unit-panel--border-right": "_VYQrGo",
	"unit-panel--border-bottom": "_rrEKnD"
};
module.exports = exports;
