import _ from 'lodash';
import memoize from 'memoize-one';
import React, { ComponentType, Fragment, PureComponent } from 'react';

import { QualificationTypes } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';
import withAttachVerificationMethodToRequirement, { AttachVerificationMethodToRequirementProps } from '~tools/react/graphql/mutations/requirements/withAttachVerificationMethodToRequirement';
import withDetachVerificationMethodFromRequirement, { DetachVerificationMethodFromRequirementProps } from '~tools/react/graphql/mutations/requirements/withDetachVerificationMethodFromRequirement';

import Button from '~tools/react/components/Button';
import Form, { Checkbox } from '~tools/react/components/Form';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import ThemedModal from '~tools/react/components/ThemedModal';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import { Requirement, VerificationMethod } from './types';

interface InputProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  qualificationType: QualificationTypes;
  requirements: Requirement[];
  verificationMethods: VerificationMethod[];
}

type Props =
  InputProps &
  AttachVerificationMethodToRequirementProps &
  DetachVerificationMethodFromRequirementProps;

interface State {
  isLoading: boolean;
}

class VerificationMethodsModal extends PureComponent<Props, State> {
  getMemoizedVerificationMethodsForType = memoize((
    verificationMethods: VerificationMethod[],
    type: QualificationTypes,
  ) => {
    const VERIFICATION_METHOD_SLUGS_FOR_QUALIFICATION_TYPES = {
      [QualificationTypes.ASSETS]: [],
      [QualificationTypes.CIVIL_HISTORY]: [],
      [QualificationTypes.CREDIT_SCORE]: [],
      [QualificationTypes.CRIMINAL_HISTORY]: [],
      [QualificationTypes.INCOME]: [
        'most-recent-tax-return',
        '2-most-recent-paystubs-minimum-1-month',
        '2-most-recent-bank-statements-minimum-2-months',
        '2-most-recent-rent-payments',
        'employment-letter-validation',
        'cpa-letter',
      ],
    };
    const slugs = VERIFICATION_METHOD_SLUGS_FOR_QUALIFICATION_TYPES[type];
    return _.compact(
      _.map(slugs, slug => _.find(verificationMethods, verificationMethod => verificationMethod.slug === slug)),
    );
  });

  state: State = {
    isLoading: false,
  }

  handleClose = () => {
    this.props.onClose();
  }

  handleSubmit = async (data: {
    [key: string]: boolean;
  }) => {
    const verificationMethodUuids = _.compact(_.map(data, (value, key) => (value ? key : undefined)));
    const requirements = _.filter(this.props.requirements, r => r.qualification.type === this.props.qualificationType);
    const requirementUuids = _.map(requirements, 'uuid');
    const existingVerificationMethodUuids = _.difference(_.map(requirements[0]?.verificationMethods, 'uuid'), verificationMethodUuids);

    this.setState({ isLoading: true });
    await Promise.all(
      _.flattenDeep(
        _.concat(
          _.map(verificationMethodUuids, (verificationMethodUuid) =>
            _.map(requirementUuids, requirementUuid =>
              this.props.attachVerificationMethodToRequirement(requirementUuid, {
                verificationMethodUuid,
              }),
            ),
          ),
          _.map(existingVerificationMethodUuids, (verificationMethodUuid) =>
            _.map(requirementUuids, requirementUuid =>
              this.props.detachVerificationMethodFromRequirement(requirementUuid, {
                verificationMethodUuid,
              }),
            ),
          ),
        ),
      ),
    );
    this.setState({ isLoading: false }, () => {
      this.handleClose();
    });
  }

  render() {
    const requirements = _.filter(this.props.requirements, r => r.qualification.type === this.props.qualificationType);
    const activeVerificationMethods = requirements[0] ? requirements[0].verificationMethods : [];
    const verificationMethods = this.getMemoizedVerificationMethodsForType(
      this.props.verificationMethods,
      this.props.qualificationType,
    );

    return (
      <ThemedModal
        isOpen={this.props.isOpen}
        onClose={this.handleClose}
        title="Manage verification methods"
        width={ThemedModal.enums.Widths.Medium}>
        <Form onSubmit={this.handleSubmit}>
          <ThemedModal.ThemedModalSection>
            {_.map(verificationMethods, (verificationMethod, index) => (
              <Fragment key={verificationMethod.uuid}>
                <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                  <Checkbox
                    name={verificationMethod.uuid}
                    isChecked={!!_.find(activeVerificationMethods, v => (v.uuid === verificationMethod.uuid))}
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                  <div>
                    <Text
                      content={verificationMethod.name}
                      isEmphasized
                    />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                    <Text
                      content={verificationMethod.description}
                      size={Text.enums.Sizes.Small}
                    />
                  </div>
                </Row>
                {index < verificationMethods.length - 1 ? (
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
                ) : null}
              </Fragment>
            ))}
          </ThemedModal.ThemedModalSection>
          <ThemedModal.ThemedModalFooter>
            <Button
              isLoading={this.state.isLoading}
              label="Save"
              type={Button.enums.Types.Submit}
              width={Button.enums.Widths.Full}
            />
          </ThemedModal.ThemedModalFooter>
        </Form>
      </ThemedModal>
    );
  }
}



export default compose(
  withAttachVerificationMethodToRequirement,
  withDetachVerificationMethodFromRequirement,
)(VerificationMethodsModal) as ComponentType<InputProps>;
