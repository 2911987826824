import _ from 'lodash';
import React, { Fragment, PureComponent } from 'react';

import Step, { StepStatuses } from './components/Step';
import StepLine from './components/StepLine';

import { Step as StepType } from './types';

interface Props {
  activeStepId: string;
  isComplete?: boolean;
  steps: StepType[];
}

class SteppedProgressBar extends PureComponent<Props> {
  render() {
    return (
      <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', }}>
        {_.map(this.props.steps, (step, index) => {
          let status: StepStatuses = StepStatuses.Upcoming;
          if (step.id === this.props.activeStepId) status = StepStatuses.Active;
          // If the active step appears later in the array, this step is complete
          if (_.some(_.slice(this.props.steps, index + 1), s => s.id === this.props.activeStepId)) {
            status = StepStatuses.Complete;
          }

          return (
            <Fragment key={`stepped-progress-bar-${step.label}`}>
              {index !== 0 ? (
                <StepLine
                  isFilled={status === StepStatuses.Complete}
                  hasGradient={status === StepStatuses.Active}
                />
              ) : null}
              <Step
                label={step.label}
                status={this.props.isComplete ? StepStatuses.Complete : status}
              />
            </Fragment>
          );
        })}
      </div>
    );
  }
}

export default SteppedProgressBar;
