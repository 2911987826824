import classNames from 'classnames';
import React, { Component, ComponentType, MouseEvent, ReactNode } from 'react';
import _ from 'lodash';

import withStyles from '~tools/react/hocs/withStyles';

import AlertSVG from '~tools/svgs/icons/interface/alert-triangle.svg';
import CheckSVG from '~tools/svgs/icons/interface/check-circle.svg';
import ChevronDownSVG from '~tools/svgs/icons/interface/chevron-down.svg';
import LockSVG from '~tools/svgs/icons/interface/lock.svg';

import DynamicHeightToggler from '~tools/react/components/DynamicHeightToggler';
import Markdown from '~tools/react/components/Markdown';
import Text from '~tools/react/components/Text';
import Tag from '~tools/react/components/Tag';
import Button from '~tools/react/components/Button';
import {
  Colors as ButtonColors,
  Icons as ButtonIcons,
  Sizes as ButtonSizes,
  Styles as ButtonStyles,
} from '~tools/react/components/Button/enums';

import * as enums from './enums';

import styles from './DashboardAccordionItem.scss';

interface InputProps {
  button?: {
    color?: ButtonColors;
    icon?: ButtonIcons;
    id?: string | number;
    isDisabled?: boolean;
    isLoading?: boolean;
    label: string;
    onClick?: (e: MouseEvent<EventTarget>, id?: string | number) => void;
    size?: ButtonSizes;
    style?: ButtonStyles;
  }
  children?: ReactNode;
  content?: string;
  highlightColor?: enums.HighlightColors;
  id?: number;
  isLocked?: boolean;
  isOpen?: boolean;
  onClick?: (id: number) => void;
  status?: enums.Statuses;
  title: string;
  tag?: {
    color?: enums.TagColors;
    icon?: enums.TagIcons;
    label: string;
  };
}

interface ParentProps {
  id: number;
  isOpen: boolean;
  onClick: (id: number) => void;
}

type Props = InputProps & ParentProps;

class DashboardAccordionItem extends Component<Props> {
  handleClick = () => this.props.onClick(this.props.id);

  render() {
    const status = this.props.isLocked ? undefined : this.props.status;
    let isOpen = this.props.isOpen;
    if (status === enums.Statuses.Completed) isOpen = false;

    return (
      <li
        className={classNames({
          [styles['accordion-item']]: true,
          [styles['accordion-item--open']]: isOpen,
          [styles['accordion-item--locked']]: this.props.isLocked,
          [styles[`accordion-item--status-${_.kebabCase(status)}`]]: status,
          [styles[`accordion-item--highlight-color-${_.kebabCase(this.props.highlightColor)}`]]: this.props.highlightColor,
        })}
        onClick={!isOpen ? this.handleClick : undefined}>
        <div styleName="accordion-item__header">
          <button
            styleName="accordion-item__header__button"
            onClick={this.handleClick}>
            <div styleName="accordion-item__header__button__label">
              {status === enums.Statuses.Completed ? (
                <CheckSVG styleName="check-svg" />
              ) : null}
              {status === enums.Statuses.Failed ? (
                <AlertSVG styleName="alert-svg" />
              ) : null}
              {!status && !this.props.isLocked ? (
                <ChevronDownSVG styleName="chevron-down-svg" />
              ) : null}
              {this.props.isLocked ? (
                <LockSVG styleName="lock-svg" />
              ) : null}
              <Text
                content={this.props.title}
                isEmphasized
                color={this.props.status === enums.Statuses.Completed ? Text.enums.Colors.Success : undefined}
                size={Text.enums.Sizes.Medium}
                tag={Text.enums.Tags.Span}
              />
            </div>
            {this.props.tag ? (
              <Tag
                color={this.props.tag.color}
                icon={this.props.tag.icon}
                label={this.props.tag.label}
                size={Tag.enums.Sizes.Small}
              />
            ) : null}
          </button>
          {this.props.button && isOpen ? (
            <Button
              color={this.props.button.color}
              icon={this.props.button.icon}
              id={this.props.button.id}
              isDisabled={this.props.button.isDisabled}
              isLoading={this.props.button.isLoading}
              label={this.props.button.label}
              onClick={this.props.button.onClick}
              size={this.props.button.size}
              style={this.props.button.style}
            />
          ) : null}
        </div>
        <DynamicHeightToggler isOpen={isOpen}>
          <div
            className={classNames({
              [styles['accordion-item__content']]: true,
              [styles['accordion-item__content--markdown']]: !!this.props.content,
            })}>
            {this.props.content ? (
              <Markdown
                content={this.props.content}
                style={Markdown.enums.Styles.None}
              />
            ) : this.props.children}
          </div>
        </DynamicHeightToggler>
      </li>
    );
  }
}

export default withStyles(styles)(DashboardAccordionItem) as ComponentType<InputProps>;
