import withMutation from '~tools/react/graphql/withMutation';
import { VerificationVerifiedEntityTypes } from '~tools/types/graphqlSchema';

import ConfirmVerificationWithTokenMutation from './confirmVerificationWithToken.gql';

interface Response {
  confirmVerificationWithToken: {
    createdAt: string;
    failedAt: string | null;
    updatedAt: string;
    uuid: string;
    verificationMethod: {
      name: string;
      slug: string;
      uuid: string;
    };
    verifiedAt: string | null;
    verifiedEntityType: VerificationVerifiedEntityTypes | null;
  };
}

export interface ConfirmVerificationWithTokenProps {
  confirmVerificationWithToken: (verificationToken: string) => Promise<{ uuid: string }>;
}

export default withMutation<{}, Response, { verificationToken: string }, ConfirmVerificationWithTokenProps>(ConfirmVerificationWithTokenMutation, {
  props: props => ({
    confirmVerificationWithToken: async (verificationToken: string) => {
      const res = await props.mutate({
        variables: { verificationToken },
      });

      if (!res || !res.data || !res.data.confirmVerificationWithToken) throw new Error('Error confirming this verification.');

      return res.data.confirmVerificationWithToken;
    },
  }),
});
