import classNames from 'classnames';
import React, { PureComponent } from 'react';

import Spinner from '~tools/react/components/Spinner';
import withStyles from '~tools/react/hocs/withStyles';

import styles from './AccessFormPhoto.scss';
import TrashIcon from './svgs/trash.svg';

interface Props {
  photo: {
    url: string,
    uuid?: string,
  };
  onDeletePhoto: (photo: { url: string, uuid?: string }) => Promise<void>;
}

interface State {
  isDeleting: boolean;
}

class AccessFormPhoto extends PureComponent<Props, State> {
  state = {
    isDeleting: false,
  };

  private handleDeleteClick = async () => {
    this.setState({ isDeleting: true });
    try {
      await this.props.onDeletePhoto({ url: this.props.photo.url, uuid: this.props.photo.uuid });
    } catch (_err) {
      this.setState({ isDeleting: false });
    }
  }

  render() {
    return (
      <div
        style={{ backgroundImage: `url(${this.props.photo.url})` }}
        styleName={classNames({
          'access-form-photo': true,
          'access-form-photo--deleting': this.state.isDeleting,
        })}>
        <button
          disabled={this.state.isDeleting}
          onClick={this.handleDeleteClick}
          styleName="access-form-photo--delete-button">
          {this.state.isDeleting ? <Spinner /> : <TrashIcon />}
        </button>
      </div>
    );
  }
}

export default withStyles(styles)(AccessFormPhoto);
