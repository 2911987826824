import { ApolloQueryResult } from 'apollo-client';
import hoistStatics from 'hoist-non-react-statics';
import { ComponentType } from 'react';

import withQuery from '~tools/react/graphql/withQuery';
import { PaymentSubscriptionPlans } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';
import withAuth, { AuthProps } from '~tools/react/hocs/withAuth';

import query from './withPaymentSubscription.gql';

interface PaymentSubscription {
  activatedAt: string;
  createdAt: string;
  plan: PaymentSubscriptionPlans;
  uuid: string;
}

interface Response {
  viewer: {
    balance: {
      defaultTopupSourceId: string | null;
      uuid: string;
    } | null;
    paymentSubscription: PaymentSubscription | null;
    uuid: string;
  };
}

export { PaymentSubscriptionPlans };

export interface PaymentSubscriptionProps {
  hasInitializedBalance: boolean;
  isLoadingPaymentSubscription: boolean;
  paymentSubscription: PaymentSubscription | null;
  refetchPaymentSubscription: () => Promise<ApolloQueryResult<Response>>;
}

export default function withPaymentSubscription<T>(ComposedComponent: ComponentType<T & PaymentSubscriptionProps>) {
  const WithPaymentSubscription = compose(
    withAuth,
    withQuery<T & AuthProps, Response, {}, PaymentSubscriptionProps>(query, {
      options: props => ({
        fetchPolicy: 'cache-and-network',
        skip: !props.isLoggedIn,
      }),
      props: props => ({
        hasInitializedBalance: !!props.data?.viewer.balance?.defaultTopupSourceId,
        isLoadingPaymentSubscription: props.loading,
        paymentSubscription: props.data?.viewer?.paymentSubscription || null,
        refetchPaymentSubscription: props.refetch,
      }),
    })
  )(ComposedComponent);

  WithPaymentSubscription.ComposedComponent = ComposedComponent;
  WithPaymentSubscription.displayName = `withPaymentSubscription(${ComposedComponent.displayName || ComposedComponent.name || 'Component'})`;

  return hoistStatics(WithPaymentSubscription, ComposedComponent);
}
