import { ApolloQueryResult } from 'apollo-client';
import React, { ComponentType, Fragment, PureComponent } from 'react';

import Alert from '~tools/react/components/Alert';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import ThemedModal from '~tools/react/components/ThemedModal';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import PaymentElement from '~tools/react/containers/PaymentElement';
import withUpdatePaymentSubscription, {
  UpdatePaymentSubscriptionProps,
} from '~tools/react/graphql/mutations/paymentSubscriptions/withUpdatePaymentSubscription';
import withQuery from '~tools/react/graphql/withQuery';
import { compose } from '~tools/react/hocs/utils';
import withPaymentSubscription, { PaymentSubscriptionProps } from '~tools/react/hocs/withPaymentSubscription';
import { PaymentSubscriptionPlans as Plans } from '~tools/types/graphqlSchema';
import { extractMessageFromError } from '~tools/utils/error';

import query from './UpdatePaymentMethodModal.gql';

interface InputProps {
  isOpen: boolean;
  onClose: () => void;
}

type Props = InputProps & QueryProps & PaymentSubscriptionProps & UpdatePaymentSubscriptionProps;

interface State {
  errorMessage?: string;
}

class UpdatePaymentMethodModal extends PureComponent<Props, State> {
  state: State = {};

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.props.refetch();
    }
  };

  handleError = (error) => {
    this.setState({
      errorMessage: extractMessageFromError(error),
    });
  };

  handleUpdatePaymentMethod = async (paymentMethodId: string) => {
    const viewer = this.props.viewer;
    const activePaymentSubscription = viewer?.activePaymentSubscription;

    this.setState({ errorMessage: undefined });
    if (!activePaymentSubscription) return;

    try {
      await this.props.updatePaymentSubscription(activePaymentSubscription.uuid, {
        paymentMethodId,
      });
      await this.props.refetchPaymentSubscription();
      await this.props.refetch();

      this.props.onClose();
    } catch (err) {
      this.handleError(err);
    }
  };

  render() {
    return (
      <ThemedModal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title="Change payment method"
        width={ThemedModal.enums.Widths.Medium}>
        <ThemedModal.ThemedModalSection>
          {this.state.errorMessage ? (
            <Fragment>
              <Alert
                color={Alert.enums.Colors.Red}
                description={this.state.errorMessage}
                icon={Alert.enums.Icons.Alert}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
            </Fragment>
          ) : null}
          <Row flexBehavior={Row.enums.FlexBehaviors.Grow}>
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXSmall} />
            <Row flexBehavior={Row.enums.FlexBehaviors.Grow}>
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXSmall} />
              <PaymentElement
                paymentMethodTypes={[PaymentElement.enums.PaymentMethodTypes.CARD]}
                defaultValues={{
                  name: this.props.viewer?.fullName,
                  email: this.props.viewer?.email,
                }}
                onAddPaymentMethod={this.handleUpdatePaymentMethod}
              />
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXSmall} />
            </Row>
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXSmall} />
          </Row>
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

interface Viewer {
  activePaymentSubscription: {
    plan: Plans;
    uuid: string;
  } | null;
  email: string;
  fullName: string;
  uuid: string;
}

interface Response {
  viewer: Viewer | null;
}

interface QueryProps extends Response {
  isLoading: boolean;
  refetch: () => Promise<ApolloQueryResult<Response>>;
}

export default compose(
  withPaymentSubscription,
  withUpdatePaymentSubscription,
  withQuery<{}, Response, {}, QueryProps>(query, {
    options: () => ({
      fetchPolicy: 'no-cache',
    }),
    props: (props) => ({
      isLoading: props.loading,
      refetch: props.refetch,
      viewer: props.data?.viewer ?? null,
    }),
  }),
)(UpdatePaymentMethodModal) as ComponentType<InputProps>;
