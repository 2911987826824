export { Icons as ActionIcons } from '~tools/react/components/Button/enums';
export { Colors as TagColors } from '~tools/react/components/Tag/enums';

export enum BackgroundColors {
  Gray = 'GRAY',
}

export enum HighlightColors {
  Blue = 'BLUE',
  Gray = 'GRAY',
  Green = 'GREEN',
  Red = 'RED',
  Yellow = 'YELLOW',
}

export enum Spacing {
  None = 'NONE',
  Small = 'SMALL',
}
