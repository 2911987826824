// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._mmJ7Eo{margin:0 auto;width:100%}._mmJ7Eo._80AYnY{margin:0}@media screen and (max-width:1127px){._mmJ7Eo._wdj8M9{padding:0 8px}}", ""]);
// Exports
exports.locals = {
	"marketing-width-constraint": "_mmJ7Eo",
	"marketing-width-constraint--no-margin": "_80AYnY",
	"marketing-width-constraint--mobile-padding": "_wdj8M9"
};
module.exports = exports;
