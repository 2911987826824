import classNames from 'classnames';
import _ from 'lodash';
import React, { Component, ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Text from '~tools/react/components/Text';

import * as enums from './enums';

import styles from './FormField.scss';

interface Props {
  children: ReactNode;
  label?: string;
  labelFormat?: enums.LabelFormats;
  secondaryLabel?: {
    color?: enums.SecondaryLabelColors;
    link?: {
      path: string;
      shouldOpenNewTab?: boolean;
    };
    text: string;
  };
  subLabel?: string;
  name?: string;
}

class FormField extends Component<Props> {
  static enums = enums;

  render() {
    const format = !_.isUndefined(this.props.label) ? this.props.labelFormat || enums.LabelFormats.Inline : undefined;
    return (
      <div
        className={classNames({
          [styles.field]: true,
          [styles[`field--label-format-${_.kebabCase(format)}`]]: format,
        })}>
        {!_.isUndefined(this.props.label) ? (
          <div styleName="field__labels">
            <label htmlFor={this.props.name} styleName="field__label-primary">{this.props.label}</label>
            {this.props.secondaryLabel ? (
              <div styleName="field__label-secondary">
                <Text
                  color={this.props.secondaryLabel.color}
                  content={this.props.secondaryLabel.text}
                  hasHoverStyles={!!this.props.secondaryLabel.link}
                  link={this.props.secondaryLabel.link ? {
                    isTabIndexed: false,
                    ...this.props.secondaryLabel.link,
                  } : undefined}
                  size={Text.enums.Sizes.Small}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        {this.props.children}
      </div>
    );
  }
}

const StyledFormField = withStyles(styles)(FormField);

export default _.assign(StyledFormField, { enums });
