import classNames from 'classnames';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './Tab.scss';

interface Props {
  id: string;
  isActive?: boolean;
  isExact?: boolean;
  label: string;
  onClickTab?: (tabName: string) => void;
  path?: string;
}

class Tab extends Component<Props> {
  static defaultProps = {
    isExact: true,
  };

  onClick = () => {
    if (this.props.onClickTab) this.props.onClickTab(this.props.id);
  }

  render() {
    if (this.props.path) {
      return (
        <NavLink
          activeClassName={styles['tab--active']}
          className={classNames({
            [styles.tab]: true,
            [styles['tab--active']]: this.props.isActive,
          })}
          exact={this.props.isExact}
          onClick={this.onClick}
          to={this.props.path}>
          {this.props.label}
        </NavLink>
      );
    }

    return (
      <button
        className={classNames({
          [styles.tab]: true,
          [styles['tab--active']]: this.props.isActive,
        })}
        onClick={this.onClick}>
        {this.props.label}
      </button>
    );
  }
}

export default withStyles(styles)(Tab);
