// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._RnEQ9Q{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;height:34px}", ""]);
// Exports
exports.locals = {
	"inline-payment-method": "_RnEQ9Q"
};
module.exports = exports;
