// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-webkit-keyframes _vqQQOM{0%{opacity:.5}to{opacity:1}}@keyframes _vqQQOM{0%{opacity:.5}to{opacity:1}}._J2VHVQ{-webkit-animation:_vqQQOM .6s linear infinite alternate;animation:_vqQQOM .6s linear infinite alternate;-webkit-backface-visibility:hidden;backface-visibility:hidden;background:#e5e9f2;border-radius:3px;display:grid;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;grid-gap:12px;height:12px;width:120px;will-change:opacity}._J2VHVQ._APRsWA{height:24px}._J2VHVQ._qrob3V{height:32px}._J2VHVQ._WVjHRv{height:38px}._J2VHVQ._x28krx{width:33%}._J2VHVQ._BX05Px{width:66.7%}._J2VHVQ._GN6OkX{width:100%}", ""]);
// Exports
exports.locals = {
	"skeleton-display-text": "_J2VHVQ",
	"shimmer": "_vqQQOM",
	"skeleton-display-text--size-small": "_APRsWA",
	"skeleton-display-text--size-medium": "_qrob3V",
	"skeleton-display-text--size-large": "_WVjHRv",
	"skeleton-display-text--width-small": "_x28krx",
	"skeleton-display-text--width-medium": "_BX05Px",
	"skeleton-display-text--width-large": "_GN6OkX"
};
module.exports = exports;
