import _ from 'lodash';
import memoize from 'memoize-one';
import React, { ComponentType, Fragment, PureComponent } from 'react';

import CreditScore from '~tools/react/components/CreditScore';
import Heading from '~tools/react/components/Heading';
import Text from '~tools/react/components/Text';
import ThemedModal from '~tools/react/components/ThemedModal';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import withQuery from '~tools/react/graphql/withQuery';
import { compose } from '~tools/react/hocs/utils';
import { QualificationTypes } from '~tools/types/graphqlSchema';
import { getOrdinal } from '~tools/utils/number';
import { formatAsUSD } from '~tools/utils/string';

import BorderedView from './components/BorderedView';
import QualificationView from './components/QualificationView';
import VerificationsView from './components/VerificationsView';

import query from './TenantQualificationsModal.gql';
import { Applicant, QueryProps, QueryResponse, QueryVariables, Requirement } from './types';
import { getQualificationName } from './utils';

interface InputProps {
  applicationUuid: string;
  isOpen: boolean;
  onClose: () => void;
}

type Props = InputProps & QueryProps;

const orderedQualificationTypes = [
  QualificationTypes.INCOME,
  QualificationTypes.ASSETS,
  QualificationTypes.CREDIT_SCORE,
  QualificationTypes.CIVIL_HISTORY,
  QualificationTypes.CRIMINAL_HISTORY,
];

class TenantQualificationsModal extends PureComponent<Props> {
  getIsRequirementVerified = memoize((requirement: Requirement) => {
    const applicant = this.props.application?.applicants[0];
    if (!applicant) return false;
    switch (requirement.qualification.type) {
      case QualificationTypes.INCOME: {
        const incomeSource = this.getActiveIncomeSource(applicant);
        return !!incomeSource?.verifiedAt;
      }
      case QualificationTypes.CREDIT_SCORE: {
        if (applicant.activeCreditReport) {
          return !!applicant.activeCreditReport.creditScore;
        }
        return undefined;
      }
      case QualificationTypes.CIVIL_HISTORY: {
        if (applicant.activeCreditReport) {
          return applicant.activeCreditReport.hasEvictionRecords;
        }
        return undefined;
      }
      case QualificationTypes.CRIMINAL_HISTORY: {
        if (applicant.activeCreditReport) {
          return applicant.activeCreditReport.hasCriminalRecords;
        }
        return undefined;
      }
      default: return undefined;
    }
  })

  getActiveIncomeSource = memoize((applicant: Applicant | null) => {
    if (!applicant) return null;
    return applicant.incomeSources.length ?
      _.orderBy(
        applicant.incomeSources,
        ['verifiedAt', 'annualIncome'],
        ['asc', 'desc']
      )[0] :
      null;
  });

  getSortedRequirements = memoize((requirements: Requirement[]) => {
    return _.sortBy(requirements, (requirement) => {
      return _.indexOf(orderedQualificationTypes, requirement.qualification.type);
    });
  });

  render() {
    const application = this.props.application;
    const applicant = application?.applicants[0];
    const requirements = application?.listing.resolvedRentalPolicy?.requirements;
    if (!applicant || !requirements) return null;

    const sortedRequirements = this.getSortedRequirements(requirements);
    const incomeSource = this.getActiveIncomeSource(applicant);

    return (
      <ThemedModal
        title="Qualification details"
        isOpen={this.props.isOpen}
        width={ThemedModal.enums.Widths.Medium}
        onClose={this.props.onClose}>
        <ThemedModal.ThemedModalSection>
          {_.map(sortedRequirements, (requirement) => {
            const qualificationName = getQualificationName(requirement.qualification);
            const isVerified = this.getIsRequirementVerified(requirement);
            return (
              <QualificationView
                key={requirement.uuid}
                title={qualificationName}
                isVerified={isVerified}>
                {(() => {
                  switch (requirement.qualification.type) {
                    case QualificationTypes.INCOME: {
                      return (
                        <Fragment>
                          {incomeSource ? (
                            <BorderedView>
                              <Text
                                content={`*${applicant.fullName}*`}
                                size={Text.enums.Sizes.Small}
                                isMarkdown
                              />
                              <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                              <Text
                                content={`Earns *$${formatAsUSD(incomeSource.annualIncome)}* at *${incomeSource.company}* as *${incomeSource.jobTitle}*`}
                                color={Text.enums.Colors.Secondary}
                                isMarkdown
                                size={Text.enums.Sizes.Small}
                              />
                            </BorderedView>
                          ) : null}
                          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                          {_.map(requirement.verificationMethods, (verificationMethod, i) => {
                            return (
                              <Fragment key={verificationMethod.uuid}>
                                {i > 0 ? <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} /> : null}
                                {_.map(verificationMethod.documentTypes, (documentType) => {
                                  const uploads = _.filter(incomeSource?.uploads ?? [], (upload) => upload.documentType?.slug === documentType.slug);
                                  return (
                                    <VerificationsView
                                      isIncomplete={uploads.length < documentType.requiredCount}
                                      title={verificationMethod.name}
                                      key={documentType.uuid}>
                                      {_.times(documentType.requiredCount, (index) => {
                                        const upload = uploads[index];
                                        const documentCopy = `${getOrdinal(index + 1)} ${_.lowerCase(documentType.name)}`;
                                        return (
                                          <VerificationsView.VerificationRow
                                            action={upload ? {
                                              label: 'View',
                                              link: {
                                                path: upload.url,
                                                shouldOpenNewTab: true,
                                              },
                                            } : undefined}
                                            title={upload ? documentCopy : `${documentCopy} not provided`}
                                            key={index}
                                            isDisabled={!upload}
                                          />
                                        );
                                      })}
                                    </VerificationsView>
                                  );
                                })}
                              </Fragment>
                            );
                          })}
                        </Fragment>
                      );
                    }
                    case QualificationTypes.ASSETS: {
                      return (
                        <Fragment>
                          {_.map(requirement.verificationMethods, (verificationMethod, i) => {
                            return (
                              <Fragment key={verificationMethod.uuid}>
                                {i > 0 ? <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} /> : null}
                                {_.map(verificationMethod.documentTypes, (documentType) => {
                                  const uploads = _.filter(applicant.uploads, (upload) => upload.documentType?.slug === documentType.slug);
                                  return (
                                    <VerificationsView
                                      isIncomplete={uploads.length < documentType.requiredCount}
                                      title={verificationMethod.name}
                                      key={documentType.uuid}>
                                      {_.times(documentType.requiredCount, (index) => {
                                        const upload = uploads[index];
                                        const documentCopy = `${getOrdinal(index + 1)} ${_.lowerCase(documentType.name)}`;
                                        return (
                                          <VerificationsView.VerificationRow
                                            action={upload ? {
                                              label: 'View',
                                              link: {
                                                path: upload.url,
                                                shouldOpenNewTab: true,
                                              },
                                            } : undefined}
                                            title={upload ? documentCopy : `${documentCopy} not provided`}
                                            key={index}
                                            isDisabled={!upload}
                                          />
                                        );
                                      })}
                                    </VerificationsView>
                                  );
                                })}
                              </Fragment>
                            );
                          })}
                        </Fragment>
                      );
                    }
                    case QualificationTypes.CREDIT_SCORE: {
                      return (
                        <Fragment>
                          <Heading
                            content="Credit score"
                            font={Heading.enums.Fonts.Secondary}
                            size={Heading.enums.Sizes.XXSmall}
                          />
                          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                          {applicant.activeCreditReport?.creditScore ? (
                            <BorderedView>
                              <CreditScore score={applicant.activeCreditReport.creditScore} />
                            </BorderedView>
                          ) : (
                            <Text
                              color={Text.enums.Colors.Secondary}
                              content={`${applicant.firstName} has not submitted any background data`}
                            />
                          )}
                        </Fragment>
                      );
                    }
                    case QualificationTypes.CIVIL_HISTORY: {
                      const creditReport = applicant.activeCreditReport;
                      return (
                        <Fragment>
                          <Heading
                            content="Eviction history"
                            font={Heading.enums.Fonts.Secondary}
                            size={Heading.enums.Sizes.XXSmall}
                          />
                          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                          {creditReport ? (
                            <Text
                              color={creditReport.hasEvictionRecords ? Text.enums.Colors.Error : undefined}
                              content={creditReport.hasEvictionRecords ? `${applicant.firstName} has a record of evictions` : `${applicant.firstName} has a clean eviction history`}
                            />
                          ) : (
                            <Text
                              color={Text.enums.Colors.Secondary}
                              content={`${applicant.firstName} has not submitted any background data`}
                            />
                          )}
                        </Fragment>
                      );
                    }
                    case QualificationTypes.CRIMINAL_HISTORY: {
                      const creditReport = applicant.activeCreditReport;
                      return (
                        <Fragment>
                          <Heading
                            content="Criminal history"
                            font={Heading.enums.Fonts.Secondary}
                            size={Heading.enums.Sizes.XXSmall}
                          />
                          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                          {creditReport ? (
                            <Text
                              color={creditReport.hasCriminalRecords ? Text.enums.Colors.Error : undefined}
                              content={creditReport.hasCriminalRecords ? `${applicant.firstName} has a criminal record` : `${applicant.firstName} has a clean criminal history`}
                            />
                          ) : (
                            <Text
                              color={Text.enums.Colors.Secondary}
                              content={`${applicant.firstName} has not submitted any background data`}
                            />
                          )}
                        </Fragment>
                      );
                    }
                    default: return null;
                  }
                })()}
              </QualificationView>
            );
          })}
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

export default compose(
  withQuery<InputProps, QueryResponse, QueryVariables, QueryProps>(query, {
    options: (props) => ({
      variables: { applicationUuid: props.applicationUuid },
    }),
    props: (props) => ({
      application: props.data?.viewer?.application ?? null,
      isLoading: props.loading,
      refetch: props.refetch,
    }),
  }),
)(TenantQualificationsModal) as ComponentType<InputProps>;
