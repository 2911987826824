import _ from 'lodash';
import React, { ComponentType, PureComponent } from 'react';

import { NotificationTypes } from '~tools/types/graphqlSchema';
import withMarkNotificationsAsRead, { MarkNotificationsAsReadProps } from '~tools/react/graphql/mutations/notifications/withMarkNotificationsAsRead';
import withMarkNotificationAsRead, { MarkNotificationAsReadProps } from '~tools/react/graphql/mutations/notifications/withMarkNotificationAsRead';

import { compose } from '~tools/react/hocs/utils';
import withDevice, { DeviceProps } from '~tools/react/hocs/withDevice';

import Button from '~tools/react/components/Button';
import Center from '~tools/react/components/Center';
import Dropdown from '~tools/react/components/Dropdown';
import EmptyState from '~tools/react/components/EmptyState';
import Heading from '~tools/react/components/Heading';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import Notification from './components/Notification';
import NotificationsView from './components/NotificationsView';
import NotificationsEmpty from './components/NotificationsEmpty';

interface InputProps {
  notifications: {
    context: string;
    createdAt: string;
    readAt: string | null;
    text: string;
    type: NotificationTypes;
    uuid: string;
  }[];
  onClick: (evt: React.MouseEvent) => void;
  refetch: () => Promise<void>;
}

type Props = InputProps & DeviceProps & MarkNotificationAsReadProps & MarkNotificationsAsReadProps;

interface State {
  isMarkingAllAsRead: boolean;
}

class Notifications extends PureComponent<Props, State> {
  state: State = { isMarkingAllAsRead: false };

  handleMarkAllAsRead = async () => {
    this.setState({ isMarkingAllAsRead: true });
    await this.props.markNotificationsAsRead();
    this.setState({ isMarkingAllAsRead: false });
  }

  handleMarkAsRead = (uuid: string) => {
    this.props.markNotificationAsRead(uuid);
  };

  render() {
    const unread = _.filter(this.props.notifications, node => !node.readAt);
    const read = _.filter(this.props.notifications, node => !!node.readAt);

    return (
      <Dropdown
        onClick={this.props.onClick}
        carotPosition={{
          right: this.props.isMobile ? '62px' : '24px',
        }}
        position={{
          right: this.props.isMobile ? '-58px' : '10px',
          top: '52px',
        }}>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
        <Center width={Center.enums.Widths.Full}>
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Medium} />
          <Heading
            content="Notifications"
            font={Heading.enums.Fonts.Secondary}
            priority={Heading.enums.Priorities.Two}
            size={this.props.isMobile ? Heading.enums.Sizes.XXSmall : Heading.enums.Sizes.XSmall}
          />
          {unread.length > 0 ? (
            <Button
              align={Button.enums.Alignments.Right}
              isLoading={this.state.isMarkingAllAsRead}
              label="Mark all as read"
              onClick={this.handleMarkAllAsRead}
              size={Button.enums.Sizes.XSmall}
              style={Button.enums.Styles.Outline}
            />
          ) : null}
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Medium} />
        </Center>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
        <HorizontalRule />
        <NotificationsView>
          {this.props.notifications.length === 0 ? (
            <NotificationsEmpty>
              <EmptyState
                illustration={EmptyState.enums.Illustrations.NoActivity}
                text="You don't have any notifications yet."
              />
            </NotificationsEmpty>
          ) : null}
          {_.take(
            _.map(unread, node => (
              <Notification
                context={node.context}
                createdAt={node.createdAt}
                id={node.uuid}
                key={node.uuid}
                onMarkAsRead={this.handleMarkAsRead}
                readAt={node.readAt}
                text={node.text}
                type={node.type}
              />
            )),
            5,
          )}
          {_.take(
            _.map(read, node => (
              <Notification
                context={node.context}
                createdAt={node.createdAt}
                id={node.uuid}
                key={node.uuid}
                onMarkAsRead={this.handleMarkAsRead}
                readAt={node.readAt}
                text={node.text}
                type={node.type}
              />
            )),
            5 - unread.length,
          )}
        </NotificationsView>
      </Dropdown>
    );
  }
}

export default compose(
  withDevice,
  withMarkNotificationAsRead,
  withMarkNotificationsAsRead,
)(Notifications) as ComponentType<InputProps>;
