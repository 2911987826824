// import loadable from '@loadable/component';
import _ from 'lodash';

import * as enums from './enums';

// loadable() is not used here for now, because we have seen cases where <Toast /> errors while
// rendering when it's referenced from several different components in the same scene.
// See https://github.com/caretaker-labs/atlas/pull/483 and the bugsnag errors referenced there
//
// const Toast = loadable(() => import(/* webpackChunkName: "toast" */'./Toast'));

import Toast from './Toast';

export default _.assign(Toast, { enums });
