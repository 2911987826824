import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';
import isEqual from 'react-fast-compare';

import Link from '~tools/react/components/utility/Link';
import Markdown from '~tools/react/components/Markdown';

import withStyles from '~tools/react/hocs/withStyles';

import * as enums from './enums';

import styles from './Text.scss';

interface Props {
  altText?: string;
  align?: enums.Align;
  color?: enums.Colors;
  content: string;
  decoration?: enums.Decorations;
  font?: enums.Fonts;
  hasHoverStyles?: boolean;
  isEmphasized?: boolean;
  isMarkdown?: boolean;
  link?: {
    isTabIndexed?: boolean;
    path: string;
    shouldOpenNewTab?: boolean;
  };
  onClick?: () => void;
  overflow?: enums.OverflowValues;
  shouldPreserveNewlines?: boolean;
  shouldWrap?: boolean;
  size?: enums.Sizes;
  style?: enums.Styles;
  tag?: enums.Tags;
  // weight is deprecated and intentionally omitted here
}

class Text extends Component<Props> {
  static defaultProps = {
    color: enums.Colors.Primary,
    isMarkdown: false,
    shouldWrap: true,
    size: enums.Sizes.Medium,
    tag: enums.Tags.P,
  };
  static enums = enums;

  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const classes = classNames({
      [styles.text]: true,
      [styles['text--hover']]: this.props.hasHoverStyles,
      [styles['text--emphasized']]: this.props.isEmphasized,
      [styles['text--markdown']]: this.props.isMarkdown,
      [styles['text--no-wrap']]: !this.props.shouldWrap,
      [styles['text--on-click']]: !!this.props.onClick,
      [styles['text--preserve-newlines']]: this.props.shouldPreserveNewlines,
      [styles[`text--color-${_.kebabCase(this.props.color)}`]]: this.props.color,
      [styles[`text--decoration-${_.kebabCase(this.props.decoration)}`]]: this.props.decoration,
      [styles[`text--overflow-${_.kebabCase(this.props.overflow)}`]]: this.props.overflow,
      [styles[`text--font-${_.kebabCase(this.props.font)}`]]: this.props.font,
      [styles[`text--size-${_.kebabCase(this.props.size)}`]]: this.props.size,
      [styles[`text--style-${_.kebabCase(this.props.style)}`]]: this.props.style,
      [styles[`text--align-${_.kebabCase(this.props.align)}`]]: this.props.align,
      // @ts-ignore Deprecating this so removing it from Props
      [styles[`text--weight-${_.kebabCase(this.props.weight)}`]]: this.props.weight,
    });
    const children = this.props.isMarkdown ? (
      <Markdown style={Markdown.enums.Styles.None} content={this.props.content} />
    ) : this.props.content;

    if (this.props.link) {
      return (
        <Link
          altText={this.props.altText}
          className={classes}
          isTabIndexed={this.props.link.isTabIndexed}
          shouldOpenNewTab={this.props.link.shouldOpenNewTab}
          to={this.props.link.path}>
          {children}
        </Link>
      );
    }

    // we retype it to match the tagnames
    const HtmlTag = _.toLower(this.props.tag) as 'p' | 'span';
    return (
      <HtmlTag
        title={this.props.altText}
        onClick={this.props.onClick ? this.props.onClick : undefined}
        className={classes}>
        {children}
      </HtmlTag>
    );
  }
}

export default withStyles(styles)(Text);
