// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-webkit-keyframes _J2Vd8z{0%{background:transparent}to{background:#000}}@keyframes _J2Vd8z{0%{background:transparent}to{background:#000}}._KpPmyw{-webkit-animation:_J2Vd8z .25s cubic-bezier(.215,.61,.355,1) .25s forwards;animation:_J2Vd8z .25s cubic-bezier(.215,.61,.355,1) .25s forwards;height:100vh;overflow:hidden;padding:40px;width:100vw}@media screen and (max-width:915px){._KpPmyw{height:100vh;padding:24px}}._KpPmyw>div{height:100%;-webkit-transform:scale(1);transform:scale(1);-webkit-transition:-webkit-filter .2s cubic-bezier(.215,.61,.355,1),-webkit-transform .2s cubic-bezier(.215,.61,.355,1);transition:-webkit-filter .2s cubic-bezier(.215,.61,.355,1),-webkit-transform .2s cubic-bezier(.215,.61,.355,1);transition:filter .2s cubic-bezier(.215,.61,.355,1),transform .2s cubic-bezier(.215,.61,.355,1);transition:filter .2s cubic-bezier(.215,.61,.355,1),transform .2s cubic-bezier(.215,.61,.355,1),-webkit-filter .2s cubic-bezier(.215,.61,.355,1),-webkit-transform .2s cubic-bezier(.215,.61,.355,1);width:100%}@media screen and (max-width:915px){._KpPmyw>div{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:reverse;flex-direction:column-reverse}}._KpPmyw._MOJZoV>div{-webkit-filter:blur(20px);filter:blur(20px);-webkit-transform:scale(.975);transform:scale(.975)}", ""]);
// Exports
exports.locals = {
	"photos-view": "_KpPmyw",
	"fadeIn": "_J2Vd8z",
	"photos-view--faded": "_MOJZoV"
};
module.exports = exports;
