import UpdatePhotoMutation from '~tools/react/graphql/mutations/photos/updatePhoto.gql';
import withMutation from '~tools/react/graphql/withMutation';
import { UpdatePhotoInputType as UpdatePhotoInput } from '~tools/types/graphqlSchema';

interface Response {
  updatePhoto: {
    position: number;
    url: string,
    uuid: string,
    tags: {
      uuid: string;
      name: string;
    }[];
  }
}

export interface UpdatePhotoProps {
  updatePhoto: (uuid: string, input: UpdatePhotoInput) => Promise<Response>;
}

export default withMutation<{}, Response, { uuid: string, input: UpdatePhotoInput }, UpdatePhotoProps>(UpdatePhotoMutation, {
  props: props => ({
    updatePhoto: async (uuid: string, input: UpdatePhotoInput) => {
      const res = await props.mutate({
        variables: { uuid, input }
      });

      if (!res || !res.data) throw new Error('Error in updating photo');
      if (!res.data.updatePhoto) throw new Error('No updatePhoto returned');

      return res.data;
    },
  }),
});
