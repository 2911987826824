import { ApolloQueryResult } from 'apollo-client';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';

import { AccessInstructionAccessorEntityTypes, ShipmentStatuses, ShipmentTypes } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withCreatePhoto, { CreatePhotoProps, PhotoEntityTypes } from '~tools/react/graphql/mutations/photos/withCreatePhoto';
import withDeletePhoto, { DeletePhotoProps } from '~tools/react/graphql/mutations/photos/withDeletePhoto';
import withUpdateAccessInstruction, { UpdateAccessInstructionProps } from '~tools/react/graphql/mutations/accessInstructions/withUpdateAccessInstruction';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';
import DataGrid from '~web-manage/lib/common/components/DataGrid';
import LoadingVisual from '~web-manage/lib/common/components/__deprecated/LoadingVisual';

import { formatUnitName } from '~web-manage/lib/common/utils/addressUnit';

import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import Tag from '~tools/react/components/Tag';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import SmartLockAccessCodeRow from '~tools/react/containers/SmartLockAccessCodeRow';

import AccessPhoto from './components/AccessPhoto';
import EditableAccessNotes from './components/EditableAccessNotes';
import SmartLockShippingBanner from './components/SmartLockShippingBanner';
import SetupPromptOverlay from './components/SetupPromptOverlay';

import AccessPhotosModal, { PhotoUpload } from './containers/AccessPhotosModal';
import KeyboxSetupModal from './containers/KeyboxSetupModal';
import SmartLockSetupModal from './containers/SmartLockSetupModal';

import { Order } from './types';

import query from './SmartLock.gql';

interface State {
  isAccessPhotosModalOpen: boolean;
  isKeyboxSetupModalOpen: boolean;
  isSmartLockSetupModalOpen: boolean;
}

type Props = QueryProps &
  RouteComponentProps &
  CreatePhotoProps &
  DeletePhotoProps &
  UpdateAccessInstructionProps;

class SmartLock extends PureComponent<Props, State> {
  state = {
    isAccessPhotosModalOpen: false,
    isKeyboxSetupModalOpen: false,
    isSmartLockSetupModalOpen: false,
  }

  componentDidMount() {
    const qs = queryString.parse(this.props.location.search);
    if (qs.modal === 'setup') {
      this.handleOpenSmartLockSetupModal();
      this.props.history.replace(this.props.location.pathname);
    }
  }

  handleOpenSmartLockSetupModal = () => {
    const smartLockOrderItem = this.props.order?.orderItems.find(oi => oi.product.tableName === 'smartlocks');
    const isKeybox = smartLockOrderItem?.product.sku === 'KBX-IGH-01';

    if (isKeybox) {
      this.setState({ isKeyboxSetupModalOpen: true });
    } else {
      this.setState({ isSmartLockSetupModalOpen: true });
    }
  };

  handleCloseSmartLockSetupModal = () => {
    this.setState({
      isKeyboxSetupModalOpen: false,
      isSmartLockSetupModalOpen: false,
    });
  };

  handleSaveAccessNotes = async (newNotes: string) => {
    const order = this.props.order;
    const propertyManagerContract = order?.associatedEntity;
    const accessInstructionUuid = propertyManagerContract?.addressUnit.accessInstruction?.uuid;
    if (!accessInstructionUuid) return;

    await this.props.updateAccessInstruction(accessInstructionUuid, {
      notes: newNotes,
    });
  }

  handleCloseAccessPhotosModal = () => this.setState({ isAccessPhotosModalOpen: false })
  handleOpenAccessPhotosModal = () => this.setState({ isAccessPhotosModalOpen: true })

  handleDeletePhoto = async (uuid: string) => {
    await this.props.deletePhoto(uuid);
    await this.props.refetch();
  }

  handleSavePhotos = async (photos: PhotoUpload[]) => {
    const order = this.props.order;
    const propertyManagerContract = order?.associatedEntity;
    const accessInstruction = propertyManagerContract?.addressUnit.accessInstruction;
    if (!accessInstruction) return;

    await Promise.all(_.map(photos, p => (
      this.props.createPhoto({
        entityType: PhotoEntityTypes.ACCESS_INSTRUCTION,
        entityUuid: accessInstruction.uuid,
        key: p.key,
      })
    )));
    await this.props.refetch();
  }

  handleSmartLockSetupComplete = async () => {
    await this.props.refetch();
  };

  render() {
    const order = this.props.order;
    const propertyManagerContract = order?.associatedEntity?.__typename === 'PrivatePropertyManagerContract' ? order?.associatedEntity : null;
    if (this.props.isLoading || !order || !propertyManagerContract) {
      return (
        <ManageStage>
          <LoadingVisual />
        </ManageStage>
      );
    }
    const addressUnit = propertyManagerContract.addressUnit;
    const shipment = _.find(order.shipments, s => s.type === ShipmentTypes.SHIPMENT);
    // const returnShipment = _.find(order.shipments, s => s.type === ShipmentTypes.RETURN);

    const isSmartLockSetup = !!addressUnit.accessInstruction?.notes
      && addressUnit.accessInstruction.photos?.length > 0
      && addressUnit.accessInstruction.accessorEntityType === AccessInstructionAccessorEntityTypes.SMART_LOCK;

    const smartLockOrderItem = order.orderItems.find(oi => oi.product.tableName === 'smartlocks');
    const isKeybox = smartLockOrderItem?.product.sku === 'KBX-IGH-01';

    return (
      <ManageStage>
        <Breadcrumbs
          items={[
            { path: '/smart-locks', label: 'All smart locks' },
            { label: addressUnit.name ? `${addressUnit.address.streetAddress1}, ${formatUnitName(addressUnit)}` : addressUnit.address.streetAddress1 },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
        <SmartLockShippingBanner
          address={addressUnit.address}
          shippingAddressUnit={order.shippingAddressUnit}
          status={shipment?.status}
          trackingUrl={shipment?.trackingUrl || undefined}
        />
        <Card
          header={{
            title: `Smart lock at ${addressUnit.address.streetAddress1}${addressUnit.name ? `, ${addressUnit.name}` : `.`}`,
          }}>
          {shipment?.status === ShipmentStatuses.DELIVERED && !isSmartLockSetup ? (
            <Card.CardAlert
              action={{
                label: 'Set up your smart lock',
                onClick: this.handleOpenSmartLockSetupModal,
              }}
              icon={Card.CardAlert.enums.Icons.Alert}
              message="This smart lock needs to be set up"
            />
          ) : null}
          <Card.CardSection>
            <SetupPromptOverlay
              action={shipment?.status === ShipmentStatuses.DELIVERED ? {
                label: 'Get started',
                onClick: this.handleOpenSmartLockSetupModal,
              } : undefined}
              isOverlayOpen={!isSmartLockSetup}
              subtitle={shipment?.status === ShipmentStatuses.DELIVERED ? 'Potential tenants can schedule viewings as soon as this is done' : undefined}
              title={shipment?.status === ShipmentStatuses.DELIVERED ? 'Set up your smart lock' : 'Your smart lock will arrive soon!'}>
              <DataGrid>
                <DataGrid.DataGridColumn>
                  <DataGrid.DataGridRow label="Smart lock status">
                    <Tag
                      color={isSmartLockSetup ? Tag.enums.Colors.Green : Tag.enums.Colors.Red}
                      label={isSmartLockSetup ? 'Ready to use' : 'Pending setup'}
                      size={Tag.enums.Sizes.Medium}
                    />
                  </DataGrid.DataGridRow>
                  <DataGrid.DataGridRow label="Access code">
                    <SmartLockAccessCodeRow smartLock={addressUnit.smartLock} />
                  </DataGrid.DataGridRow>
                  <DataGrid.DataGridRow label="Access photos">
                    <div>
                      {_.map(addressUnit.accessInstruction?.photos, photo => (
                        <AccessPhoto key={photo.uuid} photo={photo} />
                      ))}
                      {!addressUnit.accessInstruction?.photos.length ? (
                        <Text content="No photos have been uploaded" />
                      ) : null}
                    </div>
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                    <Button
                      label="Edit photos"
                      onClick={this.handleOpenAccessPhotosModal}
                      size={Button.enums.Sizes.XSmall}
                      style={Button.enums.Styles.Outline}
                    />
                  </DataGrid.DataGridRow>
                </DataGrid.DataGridColumn>
                <DataGrid.DataGridColumn>
                  {smartLockOrderItem?.product ? (
                    <DataGrid.DataGridRow label="Model">
                      <Text content={smartLockOrderItem.product.description} />
                    </DataGrid.DataGridRow>
                  ) : null}
                  <DataGrid.DataGridRow label="Additional notes">
                    <EditableAccessNotes
                      onSave={this.handleSaveAccessNotes}
                      value={addressUnit.accessInstruction?.notes ?? ''}
                    />
                  </DataGrid.DataGridRow>
                </DataGrid.DataGridColumn>
              </DataGrid>
            </SetupPromptOverlay>
          </Card.CardSection>
          {addressUnit.accessInstruction ? (
            <AccessPhotosModal
              isOpen={this.state.isAccessPhotosModalOpen}
              onClose={this.handleCloseAccessPhotosModal}
              onDeletePhoto={this.handleDeletePhoto}
              onSave={this.handleSavePhotos}
              photos={addressUnit.accessInstruction?.photos}
            />
          ) : null}
          {isKeybox ? (
            <KeyboxSetupModal
              isOpen={this.state.isKeyboxSetupModalOpen}
              propertyManagerContractUuid={propertyManagerContract.uuid}
              onClose={this.handleCloseSmartLockSetupModal}
              onSmartLockSetupComplete={this.handleSmartLockSetupComplete}
            />
          ) : (
            <SmartLockSetupModal
              isOpen={this.state.isSmartLockSetupModalOpen}
              propertyManagerContractUuid={propertyManagerContract.uuid}
              onClose={this.handleCloseSmartLockSetupModal}
              onSmartLockSetupComplete={this.handleSmartLockSetupComplete}
            />
          )}

        </Card>
      </ManageStage>
    );
  }
}

interface Response {
  viewer: {
    order: Order | null;
    uuid: string;
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  refetch: () => Promise<ApolloQueryResult<Response>>;
  order: Order | null;
}

interface Variables {
  uuid: string;
}

export default compose(
  withCreatePhoto,
  withDeletePhoto,
  withUpdateAccessInstruction,
  withQuery<RouteComponentProps<{ orderUuid: string }>, Response, Variables, QueryProps>(query, {
    options: props => ({
      ssr: false,
      skip: !props.match.params.orderUuid,
      variables: {
        orderUuid: props.match.params.orderUuid,
      },
    }),
    props: props => ({
      isLoading: props.loading,
      order: props.data?.viewer?.order || null,
      refetch: props.refetch,
    }),
  }),
)(SmartLock);
