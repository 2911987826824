// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._VYQt2N{display:-webkit-box;display:flex;flex-wrap:wrap;margin-bottom:32px;margin-left:-8px;position:relative;width:calc(100% + 16px)}._VYQt2N._DBDheN{-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;margin-left:0;width:100%}._VYQt2N._DBDheN:active{cursor:-webkit-grabbing!important;cursor:grabbing!important}", ""]);
// Exports
exports.locals = {
	"photos-view": "_VYQt2N",
	"photos-view--reordering": "_DBDheN"
};
module.exports = exports;
