import classNames from 'classnames';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Markdown from '~tools/react/components/Markdown';
import withStyles from '~tools/react/hocs/withStyles';

import Lease from '~tools/svgs/icons/interface/user.svg';
import Unit from '~tools/svgs/icons/interface/map-pin.svg';

import styles from './SearchTypeaheadItem.scss';

import * as enums from './enums';

interface Props {
  currentSearch: string;
  description?: string;
  isSelected: boolean;
  label: string;
  onClick: () => void;
  path: string;
  icon: enums.Icons;
}

const enumToIcon = {
  [enums.Icons.Lease]: Lease,
  [enums.Icons.Unit]: Unit,
};

class SearchTypeaheadItem extends PureComponent<Props> {
  render() {
    const queryRegex = new RegExp(this.props.currentSearch, 'gmi');
    const Icon = enumToIcon[this.props.icon];

    return (
      <Link
        className={classNames({
          [styles['search-typeahead-item']]: true,
          [styles['search-typeahead-item--selected']]: this.props.isSelected,
        })}
        onClick={this.props.onClick}
        to={this.props.path}
        type="button">
        <Icon />
        <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
        <span styleName="search-typeahead-item__label">
          <Markdown
            style={Markdown.enums.Styles.None}
            content={this.props.label.replace(queryRegex, '**$&**').replace('****', '')}
          />
        </span>
        {this.props.description ? (
          <span styleName="search-typeahead-item__description">
            {`\u00A0· ${this.props.description}`}
          </span>
        ) : null}
      </Link>
    );
  }
}

const StyledSearchTypeaheadItem = withStyles(styles)(SearchTypeaheadItem);
export default _.assign(StyledSearchTypeaheadItem, { enums });
