import React, { PureComponent } from 'react';
import withStyles from '~tools/react/hocs/withStyles';

import Image from '~tools/react/components/utility/Image';
import Modal from '~tools/react/components/Modal';
import { getSizeUrl } from '~web-core/lib/common/utils/photo';

import styles from './ThreadItemPhotoAttachment.scss';

interface Props {
  url: string;
  width: number;
  height: number;
}

interface State {
  isModalOpen: boolean;
}

const IMAGE_HEIGHT = 120;
const EXPANDED_IMAGE_HEIGHT = 500;

class ThreadItemPhotoAttachment extends PureComponent<Props, State> {
  state = {
    isModalOpen: false,
  };

  render() {
    const pixelRatio = this.props.width / this.props.height;
    const calculatedWidth = IMAGE_HEIGHT * pixelRatio;
    return (
      <div styleName="thread-item-photo-attachment">
        <Modal onClose={this.toggleModal} isOpen={this.state.isModalOpen}>
          <Image
            src={getSizeUrl(this.props.url,
              { height: EXPANDED_IMAGE_HEIGHT, width: EXPANDED_IMAGE_HEIGHT * pixelRatio }
            )}
          />
        </Modal>
        <button
          style={{
            backgroundImage: `url("${getSizeUrl(this.props.url, { height: IMAGE_HEIGHT, width: calculatedWidth })}")`,
            width: calculatedWidth,
          }}
          styleName="thread-item-photo-attachment__photo"
          onClick={this.toggleModal}
        />
      </div>
    );
  }

  toggleModal = () => this.setState({ isModalOpen: !this.state.isModalOpen });
}

export default withStyles(styles)(ThreadItemPhotoAttachment);
