import classNames from 'classnames';
import { Line } from '@vx/shape';
import moment from 'moment-timezone';
import React, { Component, MouseEvent } from 'react';
import isEqual from 'react-fast-compare';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './BarPart.scss';

interface Data {
  Expected: number;
  groupStartsAt: string;
  Received: number;
}

interface BarData {
  color: string;
  data: Data;
  height: number;
  key: string;
  width: number
  x: number;
  y: number;
}

interface Props {
  bar: BarData;
  offset: number;
  onHideTooltip: () => void;
  onShowTooltip: (args: {
    tooltipData: BarData;
    tooltipLeft: number;
    tooltipTop: number;
  }) => void;
  tooltipGroupStartsAt?: string;
  yMax: number;
}

const flipBlue = '#1F8EED';
const flipOrange = '#FF7849';

class BarPart extends Component<Props> {
  tooltipTimeout: NodeJS.Timeout | null = null;

  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }

  handleMouseOver = (event: MouseEvent) => {
    if (this.tooltipTimeout) clearTimeout(this.tooltipTimeout);
    const top = event.nativeEvent.offsetY;
    const left = this.props.bar.x + this.props.bar.width + this.props.offset;
    this.props.onShowTooltip({
      tooltipData: this.props.bar,
      tooltipLeft: left,
      tooltipTop: top,
    });
  }

  render() {
    const isActive = moment.utc().startOf('month').isSame(this.props.bar.data.groupStartsAt);
    const totalAmountInCents = this.props.bar.data.Expected + this.props.bar.data.Received;
    const activeBarTopCopy = totalAmountInCents ? `${(
      (this.props.bar.data.Received / (this.props.bar.data.Expected + this.props.bar.data.Received)) * 100
    ).toFixed(2).replace('.00', '')}% paid` : 'No payments yet';

    return (
      <g>
        <rect
          className={classNames({
            [styles.bar]: true,
            [styles['bar--faded']]: !!this.props.tooltipGroupStartsAt && this.props.tooltipGroupStartsAt !== this.props.bar.data.groupStartsAt,
            [styles['bar--pulse']]: isActive && this.props.bar.key === 'Received' && !this.props.tooltipGroupStartsAt && this.props.tooltipGroupStartsAt !== this.props.bar.data.groupStartsAt,
          })}
          fill={this.props.bar.color}
          height={this.props.bar.height}
          onMouseMove={this.handleMouseOver}
          rx="2px"
          ry="2px"
          width={this.props.bar.width}
          x={this.props.bar.x}
          y={this.props.bar.y}
        />
        {this.props.bar.key === 'Expected' && this.props.tooltipGroupStartsAt === this.props.bar.data.groupStartsAt ? (
          <g>
            <text
              x={this.props.bar.x + (this.props.bar.width / 2)}
              y={-8}
              className={styles['active-bar-top-text']}
              textAnchor="middle"
              fill={flipBlue}>
              {activeBarTopCopy}
            </text>
            <Line
              from={{ x: this.props.bar.x + (this.props.bar.width / 2), y: 0 }}
              stroke={flipOrange}
              strokeWidth={1}
              style={{ pointerEvents: 'none' }}
              to={{ x: this.props.bar.x + (this.props.bar.width / 2), y: this.props.yMax }}
            />
          </g>
        ) : null}
        {isActive && this.props.bar.key === 'Expected' && !this.props.tooltipGroupStartsAt && this.props.tooltipGroupStartsAt !== this.props.bar.data.groupStartsAt ? (
          <g>
            <text
              x={this.props.bar.x + (this.props.bar.width / 2)}
              y={-8}
              textAnchor="middle"
              className={styles['active-bar-top-text']}
              fill={flipBlue}>
              {activeBarTopCopy}
            </text>
            <Line
              from={{ x: this.props.bar.x + (this.props.bar.width / 2), y: 0 }}
              stroke={flipOrange}
              strokeWidth={1}
              style={{ pointerEvents: 'none' }}
              to={{ x: this.props.bar.x + (this.props.bar.width / 2), y: this.props.yMax }}
            />
          </g>
        ) : null}
      </g>
    );
  }
}

export default withStyles(styles)(BarPart);
