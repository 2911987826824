export { LabelFormats } from '~tools/react/components/Form/components/FormField/enums';

export enum FileTypes {
  Doc = 'application/msword', // .doc/.dot
  DocM = 'application/vnd.ms-word.document.macroEnabled.12', // // .docm/.dotm
  DocX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx/.dotx
  JPEG = 'image/jpeg',
  PDF = 'application/pdf',
  PNG = 'image/png',
  Text = 'text/plain',
  TIFF = 'image/tiff',
}

export enum Icons {
  PlusCircle = 'PLUS_CIRCLE',
}
