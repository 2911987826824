import React, { PureComponent } from 'react';

import { AddressUnitUnitTypes, ListingFurnishingTypes } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withUpdateListing, { UpdateListingProps } from '~tools/react/graphql/mutations/listings/withUpdateListing';
import withUpdateAddressUnit, { UpdateAddressUnitProps } from '~tools/react/graphql/mutations/addressUnits/withUpdateAddressUnit';
import { convertEnumToSelectOptions } from '~tools/react/components/Form/components/Select/utils';

import Button from '~tools/react/components/Button';
import Form, { PaddleSelect, Select } from '~tools/react/components/Form';
import ThemedModal from '~tools/react/components/ThemedModal';

import query from './UpdateSpaceModal.gql';

interface InputProps {
  isOpen: boolean;
  listingUuid: string;
  onClose: () => void;
  onUpdated: () => Promise<void>;
}

interface FormData {
  bedrooms: number;
  bathrooms: number;
  furnishingType: ListingFurnishingTypes;
  unitType?: AddressUnitUnitTypes;
}

type Props = InputProps &
  QueryProps &
  UpdateListingProps &
  UpdateAddressUnitProps;

class UpdateSpaceModal extends PureComponent<Props> {
  handleSubmit = async (data: FormData) => {
    const listing = this.props.listing;
    if (!listing) return;

    await this.props.updateListing(listing.uuid, {
      bedrooms: data.bedrooms,
      bathrooms: data.bathrooms,
      furnishingType: data.furnishingType,
    });

    await this.props.updateAddressUnit(listing.addressUnit.uuid, {
      unitType: data.unitType,
    });

    await this.props.onUpdated();
    this.props.onClose();
  };

  render() {
    const listing = this.props.listing;

    return (
      <ThemedModal
        width={ThemedModal.enums.Widths.Small}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title="Space">
        <ThemedModal.ThemedModalSection>
          <Form onSubmit={this.handleSubmit}>
            <PaddleSelect
              label="Bedrooms"
              labelFormat={PaddleSelect.enums.LabelFormats.Stacked}
              name="bedrooms"
              options={[{
                label: 'Studio',
                value: 0,
              }, {
                label: '1 bedroom',
                value: 1,
              }, {
                label: '2 bedrooms',
                value: 2,
              }, {
                label: '3 bedrooms',
                value: 3,
              }, {
                label: '4 bedrooms',
                value: 4,
              }, {
                label: '5 bedrooms',
                value: 5,
              }, {
                label: '6 bedrooms',
                value: 6,
              }]}
              placeholder="- bedrooms"
              value={listing?.bedrooms}
            />
            <PaddleSelect
              label="Bathrooms"
              labelFormat={PaddleSelect.enums.LabelFormats.Stacked}
              name="bathrooms"
              options={[{
                label: '0 bathrooms',
                value: 0,
              }, {
                label: '0.5 bathrooms',
                value: 0.5,
              }, {
                label: '1 bathroom',
                value: 1,
              }, {
                label: '1.5 bathrooms',
                value: 1.5,
              }, {
                label: '2 bathrooms',
                value: 2,
              }, {
                label: '2.5 bathrooms',
                value: 2.5,
              }, {
                label: '3 bathrooms',
                value: 3,
              }, {
                label: '3.5 bathrooms',
                value: 3.5,
              }, {
                label: '4 bathrooms',
                value: 4,
              }, {
                label: '4.5 bathrooms',
                value: 4.5,
              }, {
                label: '5 bathrooms',
                value: 5,
              }, {
                label: '5.5 bathrooms',
                value: 5.5,
              }, {
                label: '6 bathrooms',
                value: 6,
              }, {
                label: '6.5 bathrooms',
                value: 6.5,
              }, {
                label: '7 bathrooms',
                value: 7,
              }, {
                label: '7.5 bathrooms',
                value: 7.5,
              }, {
                label: '8 bathrooms',
                value: 8,
              }]}
              placeholder="- bathrooms"
              value={listing?.bathrooms}
            />
            <Select
              options={[{
                label: 'Furnished',
                value: ListingFurnishingTypes.FURNISHED,
              }, {
                label: 'Partially Furnished',
                value: ListingFurnishingTypes.PARTIALLY_FURNISHED,
              }, {
                label: 'Unfurnished',
                value: ListingFurnishingTypes.UNFURNISHED,
              }]}
              label="Is the room furnished?"
              labelFormat={Select.enums.LabelFormats.Stacked}
              name="furnishingType"
              placeholder="Select type of furnishing"
              value={listing?.furnishingType}
            />
            <Select
              options={convertEnumToSelectOptions(AddressUnitUnitTypes)}
              label="What is the type of this unit?"
              labelFormat={Select.enums.LabelFormats.Stacked}
              name="unitType"
              placeholder="Select the unit's type"
              value={listing?.addressUnit.unitType ?? undefined}
            />
            <Button
              align={Button.enums.Alignments.Right}
              label="Save"
              size={Button.enums.Sizes.Small}
              type={Button.enums.Types.Submit}
            />
          </Form>
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

interface Listing {
  uuid: string;
  bathrooms?: number;
  bedrooms?: number;
  furnishingType?: ListingFurnishingTypes;
  updatedAt: string;
  addressUnit: {
    uuid: string;
    unitType: AddressUnitUnitTypes | null;
  }
}

interface Viewer {
  listing: Listing;
  uuid: string;
}

interface Response {
  viewer: Viewer;
}

interface QueryProps {
  isLoading: boolean;
  listing: Listing | null;
}

export default compose(
  withUpdateListing,
  withUpdateAddressUnit,
  withQuery<InputProps, Response, {}, QueryProps>(query, {
    options: (props) => ({
      variables: {
        listingUuid: props.listingUuid,
      },
      skip: !props.isOpen,
    }),
    props: (props) => ({
      isLoading: props.loading,
      listing: props.data?.viewer.listing || null,
    }),
  }),
)(UpdateSpaceModal);
