import React, { Component } from 'react';

import Button from '~tools/react/components/Button';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Tag from '~tools/react/components/Tag';
import Text from '~tools/react/components/Text';

import { getLocalizedTimeValues } from '../../utils';
import { TimeSlot } from '../../types';

interface Props {
  onDelete: (timeSlot: TimeSlot) => Promise<void>;
  timeSlot: TimeSlot;
  timezone: string;
}

interface State {
  isLoading: boolean;
}

class ViewingTimeRow extends Component<Props, State> {
  state: State = {
    isLoading: false,
  }

  handleDelete = async () => {
    this.setState({ isLoading: true });

    try {
      await this.props.onDelete(this.props.timeSlot);
    } catch (err) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const timeSlot = this.props.timeSlot;
    const { dayText, endTimeText, startTimeText } = getLocalizedTimeValues(timeSlot, this.props.timezone);

    return (
      <Row
        flexBehavior={Row.enums.FlexBehaviors.Default}
        verticalAlignment={Row.enums.VerticalAlignments.Center}>
        <Tag
          color={Tag.enums.Colors.Green}
          label="Active"
        />
        <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
        <Text
          content={`*${dayText}* from *${startTimeText}* to *${endTimeText}*`}
          isMarkdown
          size={Text.enums.Sizes.Small}
        />
        <Button
          align={Button.enums.Alignments.Right}
          color={Button.enums.Colors.Red}
          isLoading={this.state.isLoading}
          label="Delete"
          onClick={this.handleDelete}
          size={Button.enums.Sizes.XSmall}
          style={Button.enums.Styles.Outline}
        />
      </Row>
    );
  }
}

export default ViewingTimeRow;
