import React from 'react';
import { Link } from 'react-router-dom';

import Text from '~tools/react/components/Text';
import withStyles from '~tools/react/hocs/withStyles';

import BrandIcon from '~tools/svgs/brand/icon.svg';
import ChevronRight from '~web-core/lib/common/svgs/icons/directional/chevron-right.svg';

import styles from './ThreadItemContentAnswerAttachment.scss';

interface Props {
  title: string;
  slug: string;
  shortAnswer: string | null;
}

function ThreadItemContentAnswerAttachment(props: Props) {
  const answer = props.shortAnswer || 'Read the full details at the Caretaker Knowledge Base';

  return (
    <Link
      styleName="thread-item-content-answer-attachment"
      target="_blank"
      to={`/questions/${props.slug}`}>
      <article>
        <header styleName="thread-item-content-answer-attachment__header">
          <div styleName="thread-item-content-answer-attachment__header-left">
            <div styleName="thread-item-content-answer-attachment__flip-badge">
              <BrandIcon />
            </div>
            <Text
              content={props.title}
              size={Text.enums.Sizes.Small}
            />
          </div>
          <div styleName="thread-item-content-answer-attachment__header-right">
            <span styleName="thread-item-content-answer-attachment__header-right__text">Read more</span>
            <ChevronRight />
          </div>
        </header>
        <div styleName="thread-item-content-answer-attachment__text-group">
          <Text
            content={answer}
            size={Text.enums.Sizes.Small}
            color={Text.enums.Colors.Secondary}
          />
        </div>
      </article>
    </Link>
  );
}

export default withStyles(styles)(ThreadItemContentAnswerAttachment);
