import { ApolloQueryResult } from 'apollo-client';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';
import pluralize from 'pluralize';
import React, { Fragment, MouseEvent, PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Alert from '~tools/react/components/Alert';
import AmenitiesList from '~tools/react/components/AmenitiesList';
import AmenitiesModal from '~tools/react/components/AmenitiesModal';
import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import ConfirmationModal from '~tools/react/components/ConfirmationModal';
import DropdownMenu, { DropdownMenuSection } from '~tools/react/components/DropdownMenu';
import Grid from '~tools/react/components/Grid';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import CreateIssueModal from '~tools/react/containers/CreateIssueModal';
import PurchaseBoostModal from '~tools/react/containers/PurchaseBoostModal';
import withFetchOrCreateConditionInspection, {
  FetchOrCreateConditionInspectionProps,
} from '~tools/react/graphql/mutations/conditionInspections/withFetchOrCreateConditionInspection';
import withListListing, {
  ListListingProps,
  ListListingStatusEnum,
} from '~tools/react/graphql/mutations/listings/withListListing';
import withUnlistListing, { UnlistListingProps } from '~tools/react/graphql/mutations/listings/withUnlistListing';
import withTerminatePropertyManagerContract, {
  TerminatePropertyManagerContractProps,
} from '~tools/react/graphql/mutations/propertyManagerContracts/withTerminatePropertyManagerContract';
import withQuery from '~tools/react/graphql/withQuery';
import { compose } from '~tools/react/hocs/utils';
import withPaymentSubscription, { PaymentSubscriptionProps } from '~tools/react/hocs/withPaymentSubscription';
import { DocumentTags, ListingFurnishingTypes, ListingStatuses } from '~tools/types/graphqlSchema';
import { formatAsUSD } from '~tools/utils/string';

import Globals from '~web-core/lib/common/globals';

import LoadingVisual from '~web-manage/lib/common/components/__deprecated/LoadingVisual';
import ManageStage from '~web-manage/lib/common/stages/ManageStage';
import { formatUnitName } from '~web-manage/lib/common/utils/addressUnit';

import Calendar from './components/Calendar';
import PhotoGrid from './components/PhotoGrid';
import RoomsIcon from './components/RoomsIcon';
import UnitDetailsOverlay from './components/UnitDetailsOverlay';
import GetAutoReplyEmailModal from './containers/GetAutoReplyEmailModal';
import RequestInspectionModal from './containers/RequestInspectionModal';
import UnitMaintenanceSection from './containers/UnitMaintenanceSection';
import UnitTenantSection from './containers/UnitTenantSection';
import UpdateAmenitiesModal from './containers/UpdateAmenitiesModal';
import UpdateAvailabilityModal from './containers/UpdateAvailabilityModal';
// import UnitSmartLockSection from './containers/UnitSmartLockSection';
import UpdateDescriptionModal from './containers/UpdateDescriptionModal';
import UpdatePhotosModal from './containers/UpdatePhotosModal';
import UpdatePricingModal from './containers/UpdatePricingModal';
import UpdateSpaceModal from './containers/UpdateSpaceModal';
import { LeaseDocument, PropertyManagerContract } from './types';

import query from './Unit.gql';

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

interface QueryProps {
  isLoading: boolean;
  propertyManagerContract: PropertyManagerContract | null;
  refetch: () => Promise<ApolloQueryResult<Response>>;
  viewer: {
    uuid: string;
    role: 0 | 9;
  } | null;
}

interface RouteParams {
  propertyManagerContractUuid: string;
  tab: string;
}

type Props = RouteComponentProps<RouteParams> &
  QueryProps &
  ListListingProps &
  TerminatePropertyManagerContractProps &
  UnlistListingProps &
  PaymentSubscriptionProps &
  FetchOrCreateConditionInspectionProps;

interface State {
  isAmenitiesModalOpen: boolean;
  isCreateIssueModalOpen: boolean;
  isGetAutoReplyEmailModalOpen: boolean;
  isPurchaseBoostModalOpen: boolean;
  isRequestInspectionModalOpen: boolean;
  isTerminateConfirmationModalOpen: boolean;
  isUnitSettingsMenuOpen: boolean;
  isUpdateAmenitiesModalOpen: boolean;
  isUpdateAvailabilityModalOpen: boolean;
  isUpdateDescriptionModalOpen: boolean;
  isUpdatePhotosModalOpen: boolean;
  isUpdatePricingModalOpen: boolean;
  isUpdateSpaceModalOpen: boolean;
}

class Unit extends PureComponent<Props, State> {
  state: State = {
    isAmenitiesModalOpen: false,
    isCreateIssueModalOpen: false,
    isGetAutoReplyEmailModalOpen: false,
    isPurchaseBoostModalOpen: false,
    isRequestInspectionModalOpen: false,
    isTerminateConfirmationModalOpen: false,
    isUnitSettingsMenuOpen: false,
    isUpdateAmenitiesModalOpen: false,
    isUpdateAvailabilityModalOpen: false,
    isUpdateDescriptionModalOpen: false,
    isUpdatePhotosModalOpen: false,
    isUpdatePricingModalOpen: false,
    isUpdateSpaceModalOpen: false,
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleCloseUnitSettingsMenu);
  }

  handleCloseAmenitiesModal = () => this.setState({ isAmenitiesModalOpen: false });
  handleOpenAmenitiesModal = () => this.setState({ isAmenitiesModalOpen: true });

  handleClosePurchaseBoostModal = () => this.setState({ isPurchaseBoostModalOpen: false });
  handleOpenPurchaseBoostModal = () => this.setState({ isPurchaseBoostModalOpen: true });

  handleCloseUpdateAvailabilityModal = () => this.setState({ isUpdateAvailabilityModalOpen: false });
  handleOpenUpdateAvailabilityModal = () => this.setState({ isUpdateAvailabilityModalOpen: true });

  handleCloseUpdateAmenitiesModal = () => this.setState({ isUpdateAmenitiesModalOpen: false });
  handleOpenUpdateAmenitiesModal = () => this.setState({ isUpdateAmenitiesModalOpen: true });

  handleCloseUpdateDescriptionModal = () => this.setState({ isUpdateDescriptionModalOpen: false });
  handleOpenUpdateDescriptionModal = () => this.setState({ isUpdateDescriptionModalOpen: true });

  handleCloseUpdatePhotosModal = () => this.setState({ isUpdatePhotosModalOpen: false });
  handleOpenUpdatePhotosModal = () => this.setState({ isUpdatePhotosModalOpen: true });

  handleCloseUpdatePricingModal = () => this.setState({ isUpdatePricingModalOpen: false });
  handleOpenUpdatePricingModal = () => this.setState({ isUpdatePricingModalOpen: true });

  handleCloseUpdateSpaceModal = () => this.setState({ isUpdateSpaceModalOpen: false });
  handleOpenUpdateSpaceModal = () => this.setState({ isUpdateSpaceModalOpen: true });

  handleCloseTerminateConfirmationModal = () => this.setState({ isTerminateConfirmationModalOpen: false });
  handleOpenTerminateConfirmationModal = () => this.setState({ isTerminateConfirmationModalOpen: true });

  handleOpenCreateIssueModal = () => this.setState({ isCreateIssueModalOpen: true });
  handleCloseCreateIssueModal = () => this.setState({ isCreateIssueModalOpen: false });

  handleCloseRequestInspectionModal = () => this.setState({ isRequestInspectionModalOpen: false });
  handleOpenRequestInspectionModal = () => this.setState({ isRequestInspectionModalOpen: true });

  handleCloseGetAutoReplyEmailModal = () => this.setState({ isGetAutoReplyEmailModalOpen: false });
  handleOpenGetAutoReplyEmailModal = () => this.setState({ isGetAutoReplyEmailModalOpen: true });

  handleCloseUnitSettingsMenu = () => {
    document.removeEventListener('click', this.handleCloseUnitSettingsMenu);
    this.setState({ isUnitSettingsMenuOpen: false });
  };
  handleOpenUnitSettingsMenu = (e: MouseEvent<EventTarget>) => {
    e.nativeEvent.stopImmediatePropagation();

    document.addEventListener('click', this.handleCloseUnitSettingsMenu);
    this.setState({ isUnitSettingsMenuOpen: true });
  };

  handleToggleStatus = async () => {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return;

    const listing = propertyManagerContract.listing;
    if (listing.status === ListingStatuses.LISTED) {
      await this.props.unlistListing(listing.uuid);
      return;
    }

    await this.props.listListing(listing.uuid, { status: ListListingStatusEnum.PUBLIC });
  };

  handleTerminatePropertyManagerContract = async () => {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return;

    await this.props.terminatePropertyManagerContract(propertyManagerContract.uuid);
    this.handleCloseTerminateConfirmationModal();
  };

  handleFetchOrCreateInspection = async () => {
    const addressUnit = this.props.propertyManagerContract?.addressUnit;
    if (!addressUnit) return;

    const conditionInspection = await this.props.fetchOrCreateConditionInspection({
      addressUnitUuid: addressUnit.uuid,
    });

    // Domain jump to web-core
    window.location.assign(`${Globals.WEB_CORE_DOMAIN}/inspections/${conditionInspection.uuid}/setup/bedrooms`);
  };

  handleIssueCreated = (issueUuid: string) => {
    this.props.history.push(`/issues/${issueUuid}`);
  };
  handleLeaseCreated = async () => {
    await this.props.refetch();
  };

  handleSetupSmartLock = () => {
    const smartLockOrder = this.props.propertyManagerContract?.orders.find((o) =>
      o.orderItems.some((oi) => oi.product.tableName === 'smartlocks'),
    );

    if (!smartLockOrder) return;
    this.props.history.push(`/smart-locks/${smartLockOrder.uuid}?modal=setup`);
  };

  render() {
    const viewer = this.props.viewer;
    const propertyManagerContract = this.props.propertyManagerContract;

    if (this.props.isLoading || !propertyManagerContract || !viewer) {
      return (
        <ManageStage>
          <LoadingVisual />
        </ManageStage>
      );
    }

    const addressUnit = propertyManagerContract.addressUnit;
    const listing = propertyManagerContract.listing;
    // const addressUnit.lease = addressUnit.lease;
    const photos = _.take(_.orderBy(listing.photos, 'position'), 12);

    const isArchived = propertyManagerContract.endsAt ? dayjs().isAfter(propertyManagerContract.endsAt) : false;
    const isManagedByFlip = propertyManagerContract.propertyManager.organization.name === 'Caretaker Technologies, Inc';
    // Temporarily send regular non-MBF users back to the old UI
    if (!isManagedByFlip && viewer.role !== 9) {
      window.location.replace(`${Globals.WEB_CORE_DOMAIN}/listings/${propertyManagerContract.listing.uuid}/overview`);
      return (
        <ManageStage>
          <LoadingVisual />
        </ManageStage>
      );
    }

    // const paymentSubscription = this.props.paymentSubscription;
    // const isManagedLandlord = paymentSubscription && _.includes(
    //   [
    //     PaymentSubscriptionPlans.LANDLORD,
    //     PaymentSubscriptionPlans.ADVANCED,
    //     PaymentSubscriptionPlans.ENTERPRISE,
    //   ],
    //   paymentSubscription.plan,
    // );

    // const addressUnitSmartLock = propertyManagerContract.addressUnit.smartLock;
    // const smartLockOrder = propertyManagerContract.orders.find(
    //  o => _.some(o.orderItems, oi => oi.product.tableName === 'smartlocks')
    // );
    // const isSmartAccessShown = isManagedLandlord || addressUnitSmartLock || smartLockOrder;

    let earliestMoveInDate = dayjs(listing.earliestMoveInDate).tz(addressUnit.address.timezone).format('MMMM Do, YYYY');
    if (listing.earliestMoveInDate && new Date(listing.earliestMoveInDate) < new Date()) earliestMoveInDate = 'Today';
    else if (!listing.earliestMoveInDate) earliestMoveInDate = '-';

    let latestMoveOutDate = dayjs(listing.leaseEndDate).tz(addressUnit.address.timezone).format('MMMM Do, YYYY');
    if (listing.leaseEndDate && new Date(listing.leaseEndDate) < new Date()) latestMoveOutDate = 'Today';
    else if (!listing.leaseEndDate) latestMoveOutDate = '-';

    let leaseDuration =
      listing.minLeaseTerm === listing.maxLeaseTerm
        ? `${listing.minLeaseTerm} ${pluralize('month', listing.minLeaseTerm)}`
        : `${listing.minLeaseTerm} - ${listing.maxLeaseTerm} months`;
    if (!listing.minLeaseTerm) {
      leaseDuration = '-';
    } else if (new Date(listing.earliestMoveInDate) < new Date()) {
      const today = dayjs().tz(addressUnit.address.timezone);
      const diffMonths = Math.round(
        dayjs(listing.leaseEndDate).tz(addressUnit.address.timezone).diff(today, 'months', true),
      );
      leaseDuration =
        diffMonths > listing.minLeaseTerm
          ? `${listing.minLeaseTerm} - ${diffMonths} months`
          : `${diffMonths} ${pluralize('month', diffMonths)}`;
    }

    const allAmenities = _.orderBy(listing.amenities, 'type');
    const shortAmenitiesList = _.take(allAmenities, 6);

    const unitSettingsMenuItems: {
      label: string;
      path: string;
      shouldOpenNewTab?: boolean;
    }[] = [];
    if (listing.status !== ListingStatuses.DRAFT) {
      unitSettingsMenuItems.push({
        label: 'View public listing',
        path: `${Globals.WEB_APARTMENT_DOMAIN}/listings/${listing.slug}`,
        shouldOpenNewTab: true,
      });
    }
    const primaryDropdownSections = this.getPrimaryDropdownSections();

    const primarySceneAlert = this.getPrimarySceneAlert();

    return (
      <ManageStage>
        <Breadcrumbs
          items={[
            { path: '/units', label: 'All units' },
            {
              label: addressUnit.name
                ? `${addressUnit.address.streetAddress1}, ${formatUnitName(addressUnit)}`
                : addressUnit.address.streetAddress1,
            },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
        {primarySceneAlert ? (
          <Fragment>
            <Alert {...primarySceneAlert} />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
          </Fragment>
        ) : null}
        <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
          <div>
            <Heading
              content={
                addressUnit.name
                  ? `${addressUnit.address.streetAddress1}, *${formatUnitName(addressUnit)}*`
                  : `*${addressUnit.address.streetAddress1}*`
              }
              font={Heading.enums.Fonts.Secondary}
              isMarkdown
              size={Heading.enums.Sizes.Medium}
            />
            <Text
              content={`${addressUnit.address.city}, ${addressUnit.address.state} ${addressUnit.address.zipCode}`}
              color={Text.enums.Colors.Secondary}
              size={Text.enums.Sizes.Large}
            />
          </div>
          {listing.status !== ListingStatuses.DRAFT ? (
            <Button
              align={Button.enums.Alignments.Right}
              color={Button.enums.Colors.Gray}
              icon={Button.enums.Icons.ExternalLink}
              label="View public listing"
              link={{
                path: `${Globals.WEB_APARTMENT_DOMAIN}/listings/${listing.slug}`,
                shouldOpenNewTab: true,
              }}
              size={Button.enums.Sizes.Small}
              style={Button.enums.Styles.Outline}
            />
          ) : null}
          {primaryDropdownSections.length > 0 ? (
            <Fragment>
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
              <div
                style={{
                  marginLeft: listing.status === ListingStatuses.DRAFT ? 'auto' : undefined,
                  position: 'relative',
                }}>
                <Button
                  align={Button.enums.Alignments.Right}
                  color={Button.enums.Colors.Gray}
                  icon={Button.enums.Icons.Ellipsis}
                  onClick={
                    this.state.isUnitSettingsMenuOpen
                      ? this.handleCloseUnitSettingsMenu
                      : this.handleOpenUnitSettingsMenu
                  }
                  size={Button.enums.Sizes.Small}
                  style={Button.enums.Styles.Outline}
                />
                {this.state.isUnitSettingsMenuOpen ? (
                  <DropdownMenu
                    carotPosition={{
                      right: '25px',
                    }}
                    position={{
                      right: '-13px',
                      top: '54px',
                    }}
                    sections={primaryDropdownSections}
                  />
                ) : null}
              </div>
            </Fragment>
          ) : null}
        </Row>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
        {/* TODO: Add CTA to setup tenant qualifications criteria, service provider team/maintenance settings and a unit credit card once financials gets refactored */}
        <Card>
          <UnitTenantSection
            addressUnitUuid={addressUnit.uuid}
            leaseUuid={addressUnit.lease?.uuid}
            onCreateLease={this.handleLeaseCreated}
          />
        </Card>
        <Card
          alert={this.getScheduleAlert()}
          header={{
            actions: [
              {
                isDisabled: isArchived,
                label: 'Manage access settings',
                link: {
                  path: `/units/${propertyManagerContract.uuid}/access`,
                },
              },
            ],
            title: 'Access activity',
          }}>
          <Card.CardSection>
            <Calendar
              timezone={addressUnit.address.timezone}
              availableTimes={_.map(addressUnit.calendar?.availableTimeSlots, (timeSlot) => ({
                account: null,
                label: 'Available for viewings',
                ...timeSlot,
              }))}
              scheduledTimes={_.map(listing.viewings, (viewing) => ({
                ...viewing,
                calendarEvent: {
                  account: null,
                  link: {
                    label: 'Manage viewing',
                    path: `/viewings/${viewing.uuid}`,
                  },
                  ...viewing.calendarEvent,
                },
              }))}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
          </Card.CardSection>
          {/* {isSmartAccessShown ? (
            <UnitSmartLockSection
              propertyManagerContractUuid={propertyManagerContract.uuid}
            />
          ) : null} */}
        </Card>

        <Card header={{ title: 'Maintenance' }}>
          <UnitMaintenanceSection propertyManagerContractUuid={propertyManagerContract.uuid} />
        </Card>

        <UnitDetailsOverlay
          isArchived={isArchived}
          isOverlayOpen={listing.status === ListingStatuses.DRAFT}
          listingUuid={listing.uuid}>
          <Card
            alert={this.getListingAlert()}
            header={{
              title: 'Details',
            }}
            overflow={Card.enums.OverflowValues.Hidden}>
            <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
              <div
                style={{
                  width: 'calc(100% - 401px)',
                }}>
                <Card.CardSection
                  action={{
                    icon: Card.CardSection.enums.ActionIcons.Edit,
                    onClick: this.handleOpenUpdatePricingModal,
                  }}
                  title="Pricing">
                  <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                    <div style={{ minWidth: '145px' }}>
                      <Text
                        color={Text.enums.Colors.Secondary}
                        content="Rent"
                        size={Text.enums.Sizes.Medium}
                      />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                      <Text
                        color={Text.enums.Colors.Secondary}
                        content="Security deposit"
                        size={Text.enums.Sizes.Medium}
                      />
                    </div>
                    <div>
                      <Text
                        content={listing.rentInCents ? `$${formatAsUSD(listing.rentInCents / 100, true)} USD /mo` : '-'}
                        size={Text.enums.Sizes.Medium}
                      />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                      <Text
                        content={
                          listing.defaultSecurityDepositInCents
                            ? `$${formatAsUSD(listing.defaultSecurityDepositInCents / 100, true)} USD`
                            : '-'
                        }
                        size={Text.enums.Sizes.Medium}
                      />
                    </div>
                  </Row>
                </Card.CardSection>
                <Card.CardSection
                  action={{
                    icon: Card.CardSection.enums.ActionIcons.Edit,
                    onClick: this.handleOpenUpdateAvailabilityModal,
                  }}
                  title="Availability">
                  <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                    <div style={{ minWidth: '145px' }}>
                      <Text
                        color={Text.enums.Colors.Secondary}
                        content="Duration"
                        size={Text.enums.Sizes.Medium}
                      />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                      <Text
                        color={Text.enums.Colors.Secondary}
                        content="Earliest move in"
                        size={Text.enums.Sizes.Medium}
                      />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                      <Text
                        color={Text.enums.Colors.Secondary}
                        content="Latest move out"
                        size={Text.enums.Sizes.Medium}
                      />
                    </div>
                    <div>
                      <Text
                        content={leaseDuration}
                        size={Text.enums.Sizes.Medium}
                      />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                      <Text
                        content={earliestMoveInDate}
                        size={Text.enums.Sizes.Medium}
                      />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                      <Text
                        content={latestMoveOutDate}
                        size={Text.enums.Sizes.Medium}
                      />
                    </div>
                  </Row>
                </Card.CardSection>
                <Card.CardSection
                  action={{
                    icon: Card.CardSection.enums.ActionIcons.Edit,
                    onClick: this.handleOpenUpdateSpaceModal,
                  }}
                  title="Space">
                  <Grid
                    columns={Grid.enums.Columns.Two}
                    spacing={Grid.enums.Spacing.Medium}>
                    <RoomsIcon
                      icon={RoomsIcon.enums.Icons.Bed}
                      label={
                        listing.bedrooms === 0
                          ? 'Studio'
                          : `${listing.bedrooms || '-'} ${pluralize('bedroom', listing.bedrooms)}`
                      }
                    />
                    <RoomsIcon
                      icon={RoomsIcon.enums.Icons.Bath}
                      label={`${_.isNil(listing.bathrooms) ? '-' : listing.bathrooms} ${pluralize(
                        'bathroom',
                        listing.bathrooms,
                      )}`}
                    />
                    {listing.furnishingType === ListingFurnishingTypes.FURNISHED ? (
                      <RoomsIcon
                        icon={RoomsIcon.enums.Icons.FullyFurnished}
                        label="Fully furnished"
                        tooltipText="This listing is move in ready."
                      />
                    ) : null}
                    {listing.furnishingType === ListingFurnishingTypes.PARTIALLY_FURNISHED ? (
                      <RoomsIcon
                        icon={RoomsIcon.enums.Icons.PartiallyFurnished}
                        label="Partially furnished"
                        tooltipText="This listing has the basics (at least a bed and couch)."
                      />
                    ) : null}
                    {listing.furnishingType === ListingFurnishingTypes.UNFURNISHED ? (
                      <RoomsIcon
                        icon={RoomsIcon.enums.Icons.Unfurnished}
                        label="Unfurnished"
                        tooltipText="This listing has no furniture."
                      />
                    ) : null}
                    {addressUnit.unitType ? (
                      <RoomsIcon
                        icon={RoomsIcon.enums.Icons.UnitType}
                        label={_.capitalize(addressUnit.unitType)}
                      />
                    ) : null}
                  </Grid>
                </Card.CardSection>
                <Card.CardSection
                  action={{
                    icon: Card.CardSection.enums.ActionIcons.Edit,
                    onClick: this.handleOpenUpdateDescriptionModal,
                  }}
                  title="Description">
                  <div style={{ padding: '0 81px 0 0' }}>
                    <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                      <div style={{ minWidth: '145px' }}>
                        <Text
                          color={Text.enums.Colors.Secondary}
                          content="Description"
                          shouldWrap={false}
                          size={Text.enums.Sizes.Medium}
                        />
                      </div>
                      <div style={{ minWidth: 0 }}>
                        <Text
                          content={listing.description || '-'}
                          overflow={Text.enums.OverflowValues.Ellipsis}
                          shouldPreserveNewlines
                          shouldWrap={false}
                        />
                      </div>
                    </Row>
                  </div>
                </Card.CardSection>
                <Card.CardSection
                  action={{
                    icon: Card.CardSection.enums.ActionIcons.Edit,
                    onClick: this.handleOpenUpdateAmenitiesModal,
                  }}
                  title="Amenities">
                  <div style={{ minHeight: '150px' }}>
                    <AmenitiesList>
                      {_.map(shortAmenitiesList, (amenity) => (
                        <AmenitiesList.AmenitiesListItem
                          hasBorders={false}
                          icon={amenity.enum}
                          size={AmenitiesList.AmenitiesListItem.enums.Sizes.Medium}
                          key={amenity.enum}
                          label={amenity.name}
                        />
                      ))}
                    </AmenitiesList>
                    {allAmenities.length > 6 ? (
                      <Button
                        align={Button.enums.Alignments.Right}
                        label={`See all ${allAmenities.length} amenities`}
                        onClick={this.handleOpenAmenitiesModal}
                        size={Button.enums.Sizes.XSmall}
                        style={Button.enums.Styles.Outline}
                      />
                    ) : null}
                  </div>
                </Card.CardSection>
              </div>
              <div
                style={{
                  borderLeft: '1px solid #E6E8EB',
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  minWidth: '400px',
                  width: '400px',
                }}>
                <Card.CardSection
                  action={{
                    icon: Card.CardSection.enums.ActionIcons.Edit,
                    onClick: this.handleOpenUpdatePhotosModal,
                  }}
                  title="Images">
                  <PhotoGrid
                    images={_.map(photos, (photo) => ({
                      id: photo.uuid,
                      position: photo.position,
                      url: photo.url,
                    }))}
                  />
                </Card.CardSection>
              </div>
            </Row>
          </Card>
        </UnitDetailsOverlay>
        <AmenitiesModal
          amenities={allAmenities}
          isOpen={this.state.isAmenitiesModalOpen}
          onClose={this.handleCloseAmenitiesModal}
        />
        <PurchaseBoostModal
          insightsPath={`/units/${propertyManagerContract.uuid}/marketing`}
          isOpen={this.state.isPurchaseBoostModalOpen}
          listingUuid={listing.uuid}
          onClose={this.handleClosePurchaseBoostModal}
        />
        <UpdateAmenitiesModal
          isOpen={this.state.isUpdateAmenitiesModalOpen}
          listingUuid={listing.uuid}
          onClose={this.handleCloseUpdateAmenitiesModal}
        />
        <UpdateAvailabilityModal
          isOpen={this.state.isUpdateAvailabilityModalOpen}
          listingUuid={listing.uuid}
          onClose={this.handleCloseUpdateAvailabilityModal}
        />
        <UpdateDescriptionModal
          isOpen={this.state.isUpdateDescriptionModalOpen}
          listingUuid={listing.uuid}
          onClose={this.handleCloseUpdateDescriptionModal}
        />
        <UpdatePhotosModal
          isOpen={this.state.isUpdatePhotosModalOpen}
          listingUuid={listing.uuid}
          onClose={this.handleCloseUpdatePhotosModal}
        />
        <UpdatePricingModal
          isOpen={this.state.isUpdatePricingModalOpen}
          listingUuid={listing.uuid}
          onClose={this.handleCloseUpdatePricingModal}
        />
        <UpdateSpaceModal
          isOpen={this.state.isUpdateSpaceModalOpen}
          listingUuid={listing.uuid}
          onClose={this.handleCloseUpdateSpaceModal}
          onUpdated={this.props.refetch}
        />
        <ConfirmationModal
          cancelAction={{
            label: 'Nevermind',
            onClick: this.handleCloseTerminateConfirmationModal,
          }}
          confirmAction={{
            color: ConfirmationModal.enums.ButtonColors.Red,
            label: 'Archive',
            onClick: this.handleTerminatePropertyManagerContract,
          }}
          description={`All of your information associated with *${
            addressUnit.address.streetAddress1
          }, ${formatUnitName(
            addressUnit,
          )}* will remain available, but you won't be able to list your listing or accept new applicants.\n\n*Please note, this cannot be undone*.`}
          isOpen={this.state.isTerminateConfirmationModalOpen}
          onClose={this.handleCloseTerminateConfirmationModal}
          title="Are you sure you want to archive this unit?"
        />
        <CreateIssueModal
          addressUnitUuid={propertyManagerContract.addressUnit.uuid}
          isOpen={this.state.isCreateIssueModalOpen}
          onClose={this.handleCloseCreateIssueModal}
          onIssueCreated={this.handleIssueCreated}
        />
        <RequestInspectionModal
          isOpen={this.state.isRequestInspectionModalOpen}
          addressUnitUuid={addressUnit.uuid}
          onClose={this.handleCloseRequestInspectionModal}
        />
        {listing ? (
          <GetAutoReplyEmailModal
            isOpen={this.state.isGetAutoReplyEmailModalOpen}
            listingReferenceId={listing.referenceId}
            onClose={this.handleCloseGetAutoReplyEmailModal}
          />
        ) : null}
      </ManageStage>
    );
  }

  getPrimaryDropdownSections = () => {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return [];
    const primaryDropdownSections: DropdownMenuSection[] = [];
    const isArchived = propertyManagerContract.endsAt ? dayjs().isAfter(propertyManagerContract.endsAt) : false;
    if (!isArchived) {
      primaryDropdownSections.push({
        items: [
          {
            label: 'Verify unit',
            path: `/units/${propertyManagerContract.uuid}/verification`,
          },
          {
            label: 'Update requirements',
            path: `/units/${propertyManagerContract.uuid}/applications`,
          },
        ],
      });

      if (this.props.paymentSubscription) {
        primaryDropdownSections[0].items.push({
          label: 'Report an issue',
          onClick: this.handleOpenCreateIssueModal,
        });
        primaryDropdownSections[0].items.push({
          label: 'Request an inspection',
          onClick: this.handleOpenRequestInspectionModal,
        });

        if (this.props.propertyManagerContract?.listing) {
          primaryDropdownSections[0].items.push({
            label: 'Get auto-reply email',
            onClick: this.handleOpenGetAutoReplyEmailModal,
          });
        }
      }
    }

    if (!propertyManagerContract.endsAt) {
      primaryDropdownSections.push({
        items: [
          {
            color: DropdownMenu.enums.ItemColors.Red,
            label: 'Archive unit',
            onClick: this.handleOpenTerminateConfirmationModal,
          },
        ],
      });
    }

    return primaryDropdownSections;
  };

  getListingAlert = () => {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return;

    const listing = propertyManagerContract.listing;
    if (listing.status !== ListingStatuses.DRAFT) {
      return {
        action: {
          isChecked: listing.status === ListingStatuses.LISTED,
          label: listing.status === ListingStatuses.LISTED ? 'Listed' : 'Unlisted',
          onChange: this.handleToggleStatus,
        },
        color: listing.status === ListingStatuses.LISTED ? Card.enums.AlertColors.Blue : Card.enums.AlertColors.Gray,
        message:
          listing.status === ListingStatuses.LISTED
            ? `This unit is *listed* and available to lease.`
            : `This unit is *unlisted*.`,
      };
    }

    return {
      action: {
        isChecked: false,
        isDisabled: true,
        label: 'Unlisted',
        onChange: this.handleToggleStatus,
      },
      color: Card.enums.AlertColors.Gray,
      message: 'This unit needs more information to be listed as available.',
    };
  };

  getPrimarySceneAlert = () => {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return;

    const isArchived = propertyManagerContract.endsAt ? dayjs().isAfter(propertyManagerContract.endsAt) : false;
    if (isArchived) {
      return {
        color: Alert.enums.Colors.Gray,
        description:
          "This unit can't take on new lessees or new issue reports. Existing leases will remain active until their end date.",
        icon: Alert.enums.Icons.Info,
        title: 'This unit has been archived',
      };
    }

    return undefined;
  };

  getScheduleAlert = () => {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return;

    const listing = propertyManagerContract.listing;
    const isArchived = propertyManagerContract.endsAt ? dayjs().isAfter(propertyManagerContract.endsAt) : false;
    if (isArchived) return;

    const isListingListed = listing.status === ListingStatuses.LISTED;
    if (!isListingListed) return;

    const availableTimeSlots = propertyManagerContract.addressUnit.calendar?.availableTimeSlots ?? [];
    if (availableTimeSlots.length === 0) {
      return {
        action: {
          label: 'Add them now',
          link: {
            path: `/units/${propertyManagerContract.uuid}/access`,
          },
        },
        color: Card.enums.AlertColors.Red,
        icon: Card.enums.AlertIcons.Alert,
        message:
          "Viewing windows haven't been added to your listing yet - interested renters aren't able to move forward.",
      };
    }

    if (!propertyManagerContract.addressUnit.accessInstruction?.notes) {
      return {
        action: {
          label: 'Add them now',
          link: {
            path: `/units/${propertyManagerContract.uuid}/access`,
          },
        },
        color: Card.enums.AlertColors.Red,
        icon: Card.enums.AlertIcons.Alert,
        message: 'Interested renters need access instructions to successfully schedule and book viewings of your unit',
      };
    }

    return undefined;
  };

  getLeaseDocumentUrl = (document: LeaseDocument | null) => {
    if (!document) return;
    let docUrl = document.eSignedUrl;
    if (!docUrl && document.tag === DocumentTags.UPLOAD && document.upload) {
      docUrl = document.upload.url;
    } else if (!docUrl) {
      docUrl = document.accessUrl;
    }
    return docUrl;
  };
}

interface Response {
  viewer: {
    propertyManagerContract: PropertyManagerContract | null;
    role: 0 | 9;
    uuid: string;
  };
}

interface Variables {
  propertyManagerContractUuid: string;
}

export default compose(
  withListListing,
  withPaymentSubscription,
  withTerminatePropertyManagerContract,
  withUnlistListing,
  withFetchOrCreateConditionInspection,
  withQuery<RouteComponentProps<{ propertyManagerContractUuid: string }>, Response, Variables, QueryProps>(query, {
    options: (props) => ({
      variables: {
        propertyManagerContractUuid: props.match.params.propertyManagerContractUuid,
      },
      skip: !props.match.params.propertyManagerContractUuid,
      ssr: false,
    }),
    props: (props) => ({
      isLoading: props.loading,
      propertyManagerContract: props.data?.viewer?.propertyManagerContract || null,
      refetch: props.refetch,
      viewer: props.data?.viewer
        ? {
            role: props.data.viewer.role,
            uuid: props.data.viewer.uuid,
          }
        : null,
    }),
  }),
)(Unit);
