import { UpdateBalanceInput } from '~tools/types/graphqlSchema';
import withMutation from '~tools/react/graphql/withMutation';

import UpdateBalanceMutation from './updateBalance.gql';

export interface Balance {
  availableAmountInCents: number;
  createdAt: string;
  defaultTopupSource: {
    accountHolderName?: string;
    accountHolderType?: string;
    bankName: string;
    currency: string;
    id: string;
    last4: string;
  } | null;
  defaultTopupSourceId: string | null;
  nextTopupDueAt: string;
  targetAmountInCents: number;
  updatedAt: string;
  uuid: string;
}

interface Data {
  updateBalance: Balance;
}

interface Variables {
  input?: UpdateBalanceInput;
  uuid: string;
}

export interface UpdateBalanceProps {
  updateBalance: (uuid: string, input?: UpdateBalanceInput) => Promise<Balance>;
}

export default withMutation<{}, Data, Variables, UpdateBalanceProps>(UpdateBalanceMutation, {
  props: props => ({
    updateBalance: async (uuid, input) => {
      const res = await props.mutate({
        variables: {
          uuid,
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error updating balance.');

      return res.data.updateBalance;
    },
  }),
});
