import loadable from '@loadable/component';
import _ from 'lodash';

import * as enums from './enums';

const LoadableReceipt = loadable(() => import(/* webpackChunkName: "receipt" */ './Receipt'));

export default _.assign(LoadableReceipt, { enums });

export type { ReceiptCharge } from './types';
