import React, { Fragment, PureComponent } from 'react';

import { extractMessageFromError } from '~tools/utils/error';

import withChangePassword, { ChangePasswordProps } from '~tools/react/graphql/mutations/accounts/withChangePassword';

import Alert from '~tools/react/components/Alert';
import Button from '~tools/react/components/Button';
import Form, { Input } from '~tools/react/components/Form';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import ThemedModal from '~tools/react/components/ThemedModal';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

interface InputProps {
  isOpen: boolean;
  onClose: () => void;
}

type Props =
  InputProps &
  ChangePasswordProps;

interface State {
  currentPassword: string;
  error?: string;
  isLoading: boolean;
  newPassword: string;
  newPasswordConfirmation: string;
}

class ChangePasswordModal extends PureComponent<Props, State> {
  state: State = {
    currentPassword: '',
    isLoading: false,
    newPassword: '',
    newPasswordConfirmation: '',
  };

  handleChangeNewPasswordConfirmation = (newPasswordConfirmation: string) => this.setState({ newPasswordConfirmation });
  handleChangeCurrentPassword = (currentPassword: string) => this.setState({ currentPassword });
  handleChangeNewPassword = (newPassword: string) => this.setState({ newPassword });

  handleChangePassword = async (data: {
    currentPassword: string;
    newPassword: string;
    newPasswordConfirmation: string;
  }) => {
    this.setState({
      isLoading: true,
      error: '',
    });
    if (data.newPassword !== data.newPasswordConfirmation) {
      this.setState({
        isLoading: false,
        error: 'The passwords don\'t match.',
      });
      return Promise.resolve();
    }

    try {
      this.setState({
        error: undefined,
      });
      await this.props.changePassword({
        currentPassword: data.currentPassword,
        password: data.newPassword,
      });
      this.setState({
        newPasswordConfirmation: '',
        currentPassword: '',
        newPassword: '',
      });
    } catch (err) {
      this.setState({
        isLoading: false,
        error: extractMessageFromError(err),
      });
    }

    return Promise.resolve();
  }

  render() {
    return (
      <ThemedModal
        isLoading={this.state.isLoading}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title="Change your password"
        width={ThemedModal.enums.Widths.Small}>
        <ThemedModal.ThemedModalSection>
          {this.state.error ? (
            <Fragment>
              <Alert
                color={Alert.enums.Colors.Red}
                description={this.state.error}
                icon={Alert.enums.Icons.Alert}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
            </Fragment>
          ) : null}
          <Form onSubmit={this.handleChangePassword}>
            <Form.FormSection>
              <Input
                isDisabled={this.state.isLoading}
                isRequired
                label="Current password"
                name="currentPassword"
                onChange={this.handleChangeCurrentPassword}
                placeholder="****************"
                type={Input.enums.Types.Password}
                value={this.state.currentPassword}
              />
            </Form.FormSection>
            <HorizontalRule />
            <Form.FormSection>
              <Input
                isDisabled={this.state.isLoading}
                isRequired
                label="New password"
                name="newPassword"
                onChange={this.handleChangeNewPassword}
                placeholder="****************"
                type={Input.enums.Types.Password}
                value={this.state.newPassword}
              />
            </Form.FormSection>
            <Form.FormSection>
              <Input
                isDisabled={this.state.isLoading}
                isRequired
                label="Confirm password"
                name="newPasswordConfirmation"
                onChange={this.handleChangeNewPasswordConfirmation}
                placeholder="****************"
                type={Input.enums.Types.Password}
                value={this.state.newPasswordConfirmation}
              />
            </Form.FormSection>
            <Form.FormSection>
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
              <Button
                align={Button.enums.Alignments.Right}
                isLoading={this.state.isLoading}
                label="Save"
                size={Button.enums.Sizes.Small}
                type={Button.enums.Types.Submit}
              />
            </Form.FormSection>
          </Form>
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

export default withChangePassword(ChangePasswordModal);
