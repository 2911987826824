// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._YXrqRA{background:#fff;border:1px solid #e6e8eb;display:block;height:800px;width:800px}@media screen and (max-width:915px){._YXrqRA{height:100%;min-height:400px;width:100%}}", ""]);
// Exports
exports.locals = {
	"sublease-preview": "_YXrqRA"
};
module.exports = exports;
