// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._J2VTbd{border:1px solid #e6e8eb;border-radius:10px;display:block;-webkit-box-flex:1;flex:1}._J2VTbd:hover{background-color:#fff}._J2VTbd ._zaN863{-webkit-box-align:center;align-items:center;border-bottom:1px solid #e6e8eb;display:-webkit-box;display:flex;-webkit-box-pack:justify;justify-content:space-between;padding:12px 15px}._J2VTbd ._zaN863 ._6OAy28{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex}._J2VTbd ._zaN863 ._6OAy28 ._br0Cd7{-webkit-box-align:center;align-items:center;background:#1f8eed;border-radius:50%;color:#fff;display:-webkit-box;display:flex;flex-shrink:0;height:24px;-webkit-box-pack:center;justify-content:center;margin-right:8px;width:24px}._J2VTbd ._zaN863 ._6OAy28 ._br0Cd7 svg{height:14px;width:14px}._J2VTbd ._zaN863 ._6OAy28 ._br0Cd7 svg path{fill:#fff}._J2VTbd ._zaN863 ._prWV6v{-webkit-box-align:center;align-items:center;color:#ccd9ea;display:-webkit-box;display:flex;font-size:14px}@media screen and (min-width:1127px){._J2VTbd ._zaN863 ._prWV6v{color:#1f8eed;margin-left:24px}}@media screen and (max-width:1127px){._J2VTbd ._zaN863 ._prWV6v ._GN67J3{display:none}}._J2VTbd ._nrBivq{padding:12px 15px}", ""]);
// Exports
exports.locals = {
	"thread-item-content-answer-attachment": "_J2VTbd",
	"thread-item-content-answer-attachment__header": "_zaN863",
	"thread-item-content-answer-attachment__header-left": "_6OAy28",
	"thread-item-content-answer-attachment__flip-badge": "_br0Cd7",
	"thread-item-content-answer-attachment__header-right": "_prWV6v",
	"thread-item-content-answer-attachment__header-right__text": "_GN67J3",
	"thread-item-content-answer-attachment__text-group": "_nrBivq"
};
module.exports = exports;
