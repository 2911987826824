import React from 'react';
import withStyles from '~tools/react/hocs/withStyles';

import ImageCarousel from '~tools/react/components/ImageCarousel';

import styles from './ScheduleViewingPhotos.scss';

interface Props {
  photos: {
    url: string;
    uuid: string;
  }[];
}

function ScheduleViewingPhotos(props: Props) {
  return (
    <div styleName="schedule-viewing-photos">
      <ImageCarousel
        hasBottomBorderRadius
        hasTopBorderRadius
        images={props.photos.map((img, idx) => ({
          ...img,
          position: idx,
          id: img.uuid,
        }))}
      />
    </div>
  );
}

export default withStyles(styles)(ScheduleViewingPhotos);
