export { Brands as DescriptionIcons } from '~web-manage/lib/common/components/MiniChargeCardVisual/enums';

export enum Colors {
  Blue = 'BLUE',
  Green = 'GREEN',
  Red = 'RED',
}

export enum DescriptionAlignment {
  Right = 'RIGHT',
}
