export const getBoundsFromCenter = (coordinates: { lat: number, lng: number }, zoom?: number) => {
  const zoomValue = zoom || 12;
  const coordinateDistance = 1 / (zoomValue * zoomValue * zoomValue);

  return {
    maxLat: coordinates.lat + coordinateDistance,
    minLat: coordinates.lat - coordinateDistance,
    maxLng: coordinates.lng + coordinateDistance,
    minLng: coordinates.lng - coordinateDistance,
  };
};

export const getLatLongWithRadius = (longitude: number, latitude: number) => {
  if (!longitude || !latitude) {
    return {
      lat: 0,
      long: 0,
    };
  }

  const RADIUS_SIZE = 2;

  let lat = latitude;
  let long = longitude;

  if (lat > 0) {
    lat += RADIUS_SIZE;
  } else {
    lat -= RADIUS_SIZE;
  }

  if (long > 0) {
    long += RADIUS_SIZE;
  } else {
    long -= RADIUS_SIZE;
  }

  return {
    lat,
    long,
  };
};
