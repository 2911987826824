import _ from 'lodash';
import classNames from 'classnames';
import React, { ComponentType, ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Text from '~tools/react/components/Text';

import * as enums from './enums';

import styles from './DataGridRow.scss';

interface SharedProps {
  alignContents?: enums.AlignContents,
  emptyStateText?: string;
  label: string;
}

interface ChildrenProps extends SharedProps {
  children?: ReactNode;
}

interface DescriptionProps extends SharedProps {
  description?: string;
}

type Props =
  ChildrenProps |
  DescriptionProps;

function DataGridRow(props: Props) {
  return (
    <div
      className={classNames({
        [styles['data-grid-row']]: true,
        [styles['data-grid-row--align-end']]: props.alignContents === enums.AlignContents.End,
        [styles['data-grid-row--align-start']]: props.alignContents === enums.AlignContents.Start,
      })}>
      <div styleName="data-grid-row__left">
        <Text
          color={Text.enums.Colors.Secondary}
          content={props.label}
          size={Text.enums.Sizes.Medium}
        />
      </div>
      <div styleName="data-grid-row__right">
        <div styleName="data-grid-row__right__children">
          {'children' in props && props.children ? props.children : null}
          {'description' in props && props.description ? (
            <Text
              content={props.description}
              size={Text.enums.Sizes.Medium}
            />
          ) : null}
          {(!('children' in props && props.children) && !('description' in props && props.description)) ? (
            <Text
              color={Text.enums.Colors.Secondary}
              content={props.emptyStateText ?? '-'}
              size={Text.enums.Sizes.Medium}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

// @ts-ignore
const StyledDataGridRow = withStyles(styles)(DataGridRow);

export default StyledDataGridRow as ComponentType<Props>;
