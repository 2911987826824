import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './MaskedSmartLockCode.scss';
import * as enums from './enums';

interface Props {
  code: string | null;
  style?: enums.Styles;
}

function MaskedSmartLockCode(props: Props) {
  const shouldUseMask = !props.code;
  const maskString = '••••••••';
  const code = (shouldUseMask ? maskString : props.code) || maskString;

  return (
    <div styleName={classNames({
      'masked-smart-lock-code': true,
      'masked-smart-lock-code--compact': props.style === enums.Styles.Compact,
    })}>
      {code.split('').map((digit: string, idx: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={idx} styleName="masked-smart-lock-code__digit">{digit}</div>
      ))}
    </div>
  );
}

const wrappedComponent = withStyles(styles)(MaskedSmartLockCode);
export default _.assign(wrappedComponent, { enums });
