import _ from 'lodash';
import classNames from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { Component } from 'react';

import { StripeExternalAccountTypes } from '~tools/types/graphqlSchema';

import withStyles from '~tools/react/hocs/withStyles';

import SkeletonBodyText from '~tools/react/components/SkeletonBodyText';
import SkeletonCircle from '~tools/react/components/SkeletonCircle';
import DashboardDataGrid from '~tools/react/components/DashboardDataGrid';
import * as dataGridEnums from '~tools/react/components/DashboardDataGrid/enums';

import Check from '~tools/svgs/icons/interface/check.svg';
import Clock from '~tools/svgs/icons/interface/clock.svg';
import Cross from '~tools/svgs/icons/interface/cross.svg';
import Ellipsis from '~tools/svgs/icons/interface/ellipsis.svg';

import { formatAsUSD } from '~tools/utils/string';
import { formatTimestamp } from '~tools/utils/time';

import * as enums from './enums';

import styles from './DashboardPayoutCard.scss';

dayjs.extend(utc);

interface Props {
  amountInCents: number,
  bankAccount?: {
    bankName: string;
    routingNumber: string;
  };
  card?: {
    brand: string;
    last4: string;
  };
  date?: string;
  isLoading?: boolean;
  isToggled?: boolean;
  status: enums.PayoutStatuses;
  type?: enums.StripeExternalAccountTypes;
}

interface State {
  isToggled: boolean;
}

class DashboardPayoutCard extends Component<Props, State> {
  static enums = enums;

  static defaultProps = {
    isToggled: false,
    isLoading: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isToggled: !!this.props.isToggled,
    };
  }

  handleCardToggle = () => this.setState({ isToggled: !this.state.isToggled });

  render() {
    const IconElm = this.getIcon();

    return (
      <button
        onClick={this.handleCardToggle}
        className={classNames({
          [styles['dashboard-payout-card']]: true,
          [styles[`dashboard-payout-card--color-${_.kebabCase(this.props.status)}`]]: this.props.status,
          [styles['dashboard-payout-card--toggled']]: this.state.isToggled,
        })}>
        <div styleName="dashboard-payout-card__status-icon">
          {!this.props.isLoading ? (
            <IconElm styleName="dashboard-payout-card__status-icon__svg" />
          ) : (
            <SkeletonCircle size={SkeletonCircle.enums.Sizes.Small} />
          )}
        </div>
        <div styleName="dashboard-payout-card__status-text">
          {this.props.isLoading ? (
            <div styleName="dashboard-payout-card__status-text__loading">
              <SkeletonBodyText />
            </div>
          ) : null}
          <DashboardDataGrid
            isLoading={this.props.isLoading}
            items={this.getDataGridItems()}
            title={this.getTitle()}
          />
        </div>
        <div styleName="dashboard-payout-card__status-amount">
          {this.props.isLoading ? (
            <SkeletonBodyText />
          ) : `$${formatAsUSD(this.props.amountInCents / 100, true)} USD`}
        </div>
      </button>
    );
  }

  getDataGridItems = () => {
    if (this.props.isLoading) return [];

    const items: {
      description: string;
      descriptionIcon?: dataGridEnums.DescriptionIcons;
      label: string;
    }[] = [];
    items.push({
      label: 'Amount',
      description: `$${formatAsUSD(this.props.amountInCents / 100, true)} USD`,
    });

    if (this.props.date) {
      switch (this.props.status) {
        case enums.PayoutStatuses.IN_TRANSIT: {
          items.push({
            label: 'Expected arrival',
            description: formatTimestamp(dayjs.utc(this.props.date), 'MMM D, YYYY'),
          });
          break;
        }
        case enums.PayoutStatuses.SCHEDULED: {
          items.push({
            label: 'Scheduled for',
            description: formatTimestamp(dayjs.utc(this.props.date), 'MMM D, YYYY'),
          });
          break;
        }
        case enums.PayoutStatuses.PAID: {
          items.push({
            label: 'Completed on',
            description: formatTimestamp(dayjs.utc(this.props.date), 'MMM D, YYYY'),
          });
          break;
        }
        case enums.PayoutStatuses.PENDING: {
          items.push({
            label: 'Expected arrival',
            description: formatTimestamp(dayjs.utc(this.props.date), 'MMM D, YYYY'),
          });
          break;
        }
        default:
          break;
      }
    }

    if (this.props.type === StripeExternalAccountTypes.BANK_ACCOUNT && this.props.bankAccount) {
      const bankAccount = this.props.bankAccount;

      items.push({
        label: 'Account number',
        descriptionIcon: dataGridEnums.DescriptionIcons.Bank,
        description: _.size(bankAccount.routingNumber) === 4 ? `•••••••• ${bankAccount.routingNumber}` : bankAccount.routingNumber,
      });
      items.push({
        label: 'Institution',
        description: bankAccount.bankName,
      });
    } else if (this.props.type === StripeExternalAccountTypes.CARD && this.props.card) {
      const card = this.props.card;
      const descriptionIcon = card.brand as dataGridEnums.DescriptionIcons;

      items.push({
        description: `•••• •••• •••• ${_.get(card, 'last4', '')}`,
        descriptionIcon,
        label: 'Card number',
      });
    }

    return items;
  };

  getIcon = () => {
    const statusToIcon = {
      [enums.PayoutStatuses.FAILED]: Cross,
      [enums.PayoutStatuses.IN_TRANSIT]: Ellipsis,
      [enums.PayoutStatuses.PAID]: Check,
      [enums.PayoutStatuses.PENDING]: Ellipsis,
      [enums.PayoutStatuses.SCHEDULED]: Clock,
    };

    return _.get(statusToIcon, this.props.status, Ellipsis);
  };

  getTitle = () => {
    if (this.props.isLoading) return '';

    const statusToTitle = {
      [enums.PayoutStatuses.FAILED]: 'Failed',
      [enums.PayoutStatuses.PENDING]: 'Pending',
      [enums.PayoutStatuses.SCHEDULED]: 'Scheduled',
      [enums.PayoutStatuses.IN_TRANSIT]: 'In transit',
      [enums.PayoutStatuses.PAID]: 'Paid out',
    };

    return statusToTitle[this.props.status];
  };
}

export default withStyles(styles)(DashboardPayoutCard);
