// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._KpPbRJ{display:-webkit-inline-box;display:inline-flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;float:left;position:relative;width:200px}._KpPbRJ ._EGndyp{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-pack:justify;justify-content:space-between;width:100%}._KpPbRJ h2{color:#fff;font-size:28px;font-weight:100;margin:0}._KpPbRJ ._br0K6e{margin-top:40px;width:100%}._KpPbRJ ._br0K6e button:last-child{margin-top:12px}._1qA2j2{border:none;color:#fff;display:-webkit-box;display:flex;font-size:16px;font-weight:300;padding:12px 0;-webkit-transition:.2s cubic-bezier(.215,.61,.355,1);transition:.2s cubic-bezier(.215,.61,.355,1)}._1qA2j2._zaN8X5:after{align-self:center;background:#fff;border-radius:5px;content:\"\";height:5px;margin-left:12px;width:5px}@media screen and (max-width:915px){._1qA2j2{display:none}}._1qA2j2 span{-webkit-box-align:start;align-items:flex-start;display:-webkit-box;display:flex;width:36px}", ""]);
// Exports
exports.locals = {
	"filter-view": "_KpPbRJ",
	"filter-view__wrapper": "_EGndyp",
	"filter-view__btns": "_br0K6e",
	"section-tab": "_1qA2j2",
	"section-tab--altered": "_zaN8X5"
};
module.exports = exports;
