import { CreatePropertyManagerContractInput } from '~tools/types/graphqlSchema';
import withMutation from '~tools/react/graphql/withMutation';

import CreatePropertyManagerContractMutation from './createPropertyManagerContract.gql';

interface PropertyManagerContract {
  uuid: string;
}

interface Data {
  createPropertyManagerContract: PropertyManagerContract;
}

interface Variables {
  input: CreatePropertyManagerContractInput;
}

export interface CreatePropertyManagerContractProps {
  createPropertyManagerContract: (input: CreatePropertyManagerContractInput) => Promise<PropertyManagerContract>;
}

export default withMutation<{}, Data, Variables, CreatePropertyManagerContractProps>(CreatePropertyManagerContractMutation, {
  props: props => ({
    createPropertyManagerContract: async (input: CreatePropertyManagerContractInput) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating property manager contract.');

      return res.data.createPropertyManagerContract;
    },
  }),
});
