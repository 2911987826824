import loadable from '@loadable/component';
import classNames from 'classnames';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { ChangeEvent, FocusEvent, Fragment, KeyboardEvent, PureComponent, ReactElement } from 'react';
import InputMask from 'react-input-mask';

import { DATE } from '~web-core/lib/common/constants/regex';
import { KeyCodes } from '~web-core/lib/common/enums/javascript';

import withStyles from '~tools/react/hocs/withStyles';

import ArrowRight from '~tools/svgs/icons/interface/arrow-right.svg';
import LocationPin from '~tools/svgs/icons/interface/map-pin.svg';
import MagnifyingGlass from '~tools/svgs/icons/interface/search.svg';

import FormField from '~tools/react/components/Form/components/FormField';

import * as enums from './enums';
import * as utils from './utils';

import styles from './Input.scss';

const LibPhoneNumber = loadable.lib(() => import(/* webpackChunkName: "libphonenumber-js" */'libphonenumber-js/min'));

const iconToComponent = {
  [enums.Icons.MagnifyingGlass]: <MagnifyingGlass />,
  [enums.Icons.LocationPin]: <LocationPin />,
};

interface CurrencyInputWrapperProps {
  isCurrency: boolean;
  children: ReactElement;
}

// This is pretty hacky, but its proven to be
// hard to do nicely since this component is pretty fickle
const CurrencyInputWrapper = (props: CurrencyInputWrapperProps) => {
  if (props.isCurrency) {
    return (
      <div styleName="input-currency">
        <Fragment>
          {props.children}
          <span>$</span>
        </Fragment>
      </div>
    );
  }

  return props.children;
};

interface State {
  value: string;
  isInvalid: boolean;
}

interface Props {
  icon?: enums.Icons;
  isAutoComplete?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  label?: string;
  labelFormat?: enums.LabelFormats;
  mask?: string;
  maxAmount?: number;
  maxDate?: Moment;
  maxLength?: number;
  minAmount?: number;
  minDate?: Moment;
  name: string;
  onBlur?: (value: string, event: FocusEvent<HTMLInputElement>) => void;
  onChange?: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (value: string) => void;
  onKeyDown?: (keyCode: number, event: KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  regexPattern?: RegExp;
  secondaryLabel?: {
    color?: enums.SecondaryLabelColors;
    link?: {
      path: string;
      shouldOpenNewTab?: boolean;
    };
    text: string;
  };
  size?: enums.Sizes;
  type?: enums.Types;
  value?: string | Moment | number | undefined;
}

class Input extends PureComponent<Props, State> {
  static enums = enums;
  static utils = utils;
  static defaultProps = {
    isDisabled: false,
    isRequired: false,
    size: enums.Sizes.Medium,
    type: enums.Types.Text,
  };

  libPhoneNumberJs: any = null;
  canUseDateInputType = utils.canUseDateInputType();

  constructor(props: Props) {
    super(props);
    let value = _.isNil(props.value) ? '' : props.value;
    if (props.type === enums.Types.Date && props.value) {
      if (this.canUseDateInputType) value = moment(props.value).format('YYYY-MM-DD');
      else value = moment(props.value).format('MM/DD/YYYY');
    }

    this.state = {
      isInvalid: false,
      value: _.toString(value),
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.value !== nextProps.value) {
      let value = _.toString(nextProps.value);
      if (nextProps.type === enums.Types.Date && nextProps.value) {
        if (this.canUseDateInputType) value = moment(nextProps.value).format('YYYY-MM-DD');
        else value = moment(nextProps.value).format('MM/DD/YYYY');
      } else if (nextProps.type === enums.Types.Tel && nextProps.value) {
        const formatData = this.formatPhoneNumber(_.toString(nextProps.value));
        value = formatData.value;
      }
      this.setState({ value });
    }
  }

  handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (this.props.type === enums.Types.Tel) {
      const element = e.currentTarget;
      const selectionStart = (element.selectionStart !== null) ? element.selectionStart : undefined;
      const selectionEnd = element.selectionEnd !== null ? element.selectionEnd : undefined;
      switch (e.keyCode) {
        case KeyCodes.DELETE:
        case KeyCodes.BACKSPACE:
          e.preventDefault();

          if (selectionStart !== selectionEnd) {
            const value = element.value.slice(0, selectionStart) + element.value.slice(selectionEnd);
            this.handleChange(value, element);
            break;
          }

          this.handleChange(element.value, element, e.keyCode);
          break;
        default:
          break;
      }
    }

    if (this.props.onKeyDown) this.props.onKeyDown(e.keyCode, e);
  }

  handleChange = (rawValue: string, element: HTMLInputElement, keyCode?: KeyCodes) => {
    if (this.props.type === enums.Types.Tel) {
      const parseData = this.parsePhoneNumber(rawValue, element, keyCode);
      const parseCaretPosition = parseData.caretPosition;
      const parseValue = parseData.value;
      if (parseValue.length > (this.props.maxLength || 14)) return rawValue;

      const phoneNumber = this.libPhoneNumberJs.parsePhoneNumberFromString(parseValue, 'US');
      const isPhoneNumberInvalid = phoneNumber && !phoneNumber.isValid();
      if (isPhoneNumberInvalid) element.setCustomValidity('Phone number is invalid.');
      else element.setCustomValidity('');

      const isElementInvalid = !element.checkValidity();
      const formatData = this.formatPhoneNumber(parseValue, parseCaretPosition);
      const formatCaretPosition = formatData.caretPosition;
      const formatValue = formatData.value;

      this.setState({
        isInvalid: isPhoneNumberInvalid || isElementInvalid,
        value: formatValue,
      }, () => {
        element.setSelectionRange(formatCaretPosition, formatCaretPosition);
      });
      return formatValue;
    }

    if (this.props.maxLength && this.props.maxLength < rawValue.length) {
      return this.state.value;
    }

    const isInvalid = !element.checkValidity();
    const value = rawValue;
    this.setState({
      isInvalid,
      value,
    });

    return value;
  }

  handleChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
    const element = e.currentTarget;
    const value = this.handleChange(element.value, element);
    if (this.props.onChange) this.props.onChange(value, e);
  }

  handleNumericChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value: number = _.toNumber(e.currentTarget.value);
    if (
      (this.props.maxAmount && value > this.props.maxAmount) ||
      (this.props.minAmount && value < this.props.minAmount)
    ) {
      this.setState({
        isInvalid: true,
        value: _.toString(value),
      });
      return;
    }

    const onChange = this.props.onChange;
    if (onChange) onChange(_.toString(value), e);

    this.setState({
      isInvalid: false,
      value: _.toString(value),
    });
  }

  handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (this.props.onBlur && !this.state.isInvalid) {
      this.props.onBlur(e.currentTarget.value, e);
    }
  }

  handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (this.props.onFocus) {
      this.props.onFocus(e.currentTarget.value);
    }
  }

  handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (this.props.type !== enums.Types.Date) return;

    const value = e.currentTarget.value;
    if (utils.canUseDateInputType()) {
      this.setState({ value });
      return;
    }

    let isInvalid = false;
    if (moment(value).isValid()) {
      const maxDate = moment(this.props.maxDate);
      const minDate = moment(this.props.minDate);
      if (this.props.maxDate && maxDate.isValid()) {
        isInvalid = moment(value).isAfter(maxDate);
      }
      if (this.props.minDate && minDate.isValid()) {
        isInvalid = isInvalid || moment(value).isBefore(minDate);
      }
    }
    this.setState({ value, isInvalid });
  }

  handlePercentageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value: number = _.toNumber(e.currentTarget.value);
    if (value > 100) return; // Just stop entry beyond 100

    if (value < 0) {
      this.setState({
        isInvalid: true,
        value: _.toString(value),
      });
      return;
    }

    const onChange = this.props.onChange;
    if (onChange) onChange(_.toString(value), e);

    this.setState({
      isInvalid: false,
      value: _.toString(value),
    });
  };

  render() {
    const pattern = this.props.regexPattern ? this.props.regexPattern.source : undefined;
    let inputElement: ReactElement | null = null;
    const inputClassNames = classNames({
      [styles.input]: true,
      [styles['input--icon']]: this.props.icon,
      [styles['input--invalid']]: this.props.isInvalid || this.state.isInvalid,
      [styles[`input--icon-${_.kebabCase(this.props.icon)}`]]: this.props.icon,
      [styles[`input--size-${_.kebabCase(this.props.size)}`]]: this.props.size,
    });

    switch (this.props.type) {
      case enums.Types.Date: {
        if (this.canUseDateInputType) {
          inputElement = (
            <input
              autoComplete={this.props.isAutoComplete ? 'on' : 'off'}
              disabled={this.props.isDisabled}
              max={this.props.maxDate ? moment(this.props.maxDate).format('YYYY-MM-DD') : undefined}
              min={this.props.minDate ? moment(this.props.minDate).format('YYYY-MM-DD') : undefined}
              name={this.props.name}
              onBlur={this.handleBlur}
              onChange={this.handleChangeEvent}
              onFocus={this.handleFocus}
              onKeyDown={this.handleKeyDown}
              pattern={DATE}
              placeholder={this.props.placeholder}
              readOnly={Boolean(this.props.isReadOnly)}
              required={this.props.isRequired}
              className={inputClassNames}
              type={_.lowerCase(this.props.type)}
              value={this.state.value}
            />
          );
          break;
        }
        inputElement = (
          <InputMask
            disabled={this.props.isDisabled}
            mask="99/99/9999"
            max={this.props.maxDate ? moment(this.props.maxDate).format('MM/DD/YYYY') : undefined}
            min={this.props.minDate ? moment(this.props.minDate).format('MM/DD/YYYY') : undefined}
            name={this.props.name}
            onChange={this.handleDateChange}
            className={inputClassNames}
            value={this.state.value}
          />
        );
        break;
      }
      case enums.Types.Mask:
        inputElement = (
          <InputMask
            autoComplete={this.props.isAutoComplete ? 'on' : 'off'}
            disabled={this.props.isDisabled}
            name={this.props.name}
            onBlur={this.handleBlur}
            onChange={this.handleChangeEvent}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            pattern={pattern}
            placeholder={this.props.placeholder}
            required={this.props.isRequired}
            className={inputClassNames}
            type={_.lowerCase(this.props.type)}
            value={this.state.value}
            mask={this.props.mask || ''}
          />
        );
        break;
      case enums.Types.Number:
      case enums.Types.Currency:
        inputElement = (
          <CurrencyInputWrapper isCurrency={this.props.type === enums.Types.Currency}>
            <input
              autoComplete={this.props.isAutoComplete ? 'on' : 'off'}
              disabled={this.props.isDisabled}
              max={this.props.maxAmount}
              name={this.props.name}
              min={this.props.minAmount}
              onBlur={this.handleBlur}
              onChange={this.handleNumericChange}
              onFocus={this.handleFocus}
              onKeyDown={this.handleKeyDown}
              pattern={pattern}
              placeholder={this.props.placeholder}
              required={this.props.isRequired}
              className={inputClassNames}
              type={_.lowerCase(enums.Types.Number)}
              value={this.state.value}
            />
          </CurrencyInputWrapper>
        );
        break;
      case enums.Types.Time:
      case enums.Types.Email:
      case enums.Types.Password:
      case enums.Types.Text: {
        inputElement = (
          <input
            autoComplete={this.props.isAutoComplete ? 'on' : 'off'}
            disabled={this.props.isDisabled}
            name={this.props.name}
            onChange={this.handleChangeEvent}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            pattern={pattern}
            placeholder={this.props.placeholder}
            readOnly={Boolean(this.props.isReadOnly)}
            required={this.props.isRequired}
            className={inputClassNames}
            type={_.kebabCase(this.props.type)}
            value={this.state.value}
          />
        );
        break;
      }
      case enums.Types.DatetimeLocal: {
        inputElement = (
          <input
            autoComplete={this.props.isAutoComplete ? 'on' : 'off'}
            disabled={this.props.isDisabled}
            name={this.props.name}
            max={this.props.maxDate ? this.props.maxDate.format('YYYY-MM-DDTHH:mm') : undefined}
            min={this.props.minDate ? this.props.minDate.format('YYYY-MM-DDTHH:mm') : undefined}
            onChange={this.handleChangeEvent}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            pattern={pattern}
            placeholder={this.props.placeholder}
            readOnly={Boolean(this.props.isReadOnly)}
            required={this.props.isRequired}
            className={inputClassNames}
            type={_.kebabCase(this.props.type)}
            value={this.state.value}
          />
        );
        break;
      }
      case enums.Types.Tel: {
        inputElement = (
          <Fragment>
            <input
              autoComplete={this.props.isAutoComplete ? 'on' : 'off'}
              disabled={this.props.isDisabled}
              name={this.props.name}
              onChange={this.handleChangeEvent}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              onKeyDown={this.handleKeyDown}
              pattern={pattern}
              placeholder={this.props.placeholder}
              readOnly={Boolean(this.props.isReadOnly)}
              required={this.props.isRequired}
              className={inputClassNames}
              type={_.kebabCase(this.props.type)}
              value={this.state.value}
            />
            <LibPhoneNumber ref={this.setLibPhoneNumberJsRef} />
          </Fragment>
        );
        break;
      }
      case enums.Types.DateRange: {
        inputElement = (
          <div className={inputClassNames}>
            <input
              autoComplete={this.props.isAutoComplete ? 'on' : 'off'}
              disabled={this.props.isDisabled}
              name={this.props.name}
              max={this.props.maxDate ? this.props.maxDate.format('YYYY-MM-DDTHH:mm') : undefined}
              min={this.props.minDate ? this.props.minDate.format('YYYY-MM-DDTHH:mm') : undefined}
              onChange={this.handleChangeEvent}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              onKeyDown={this.handleKeyDown}
              pattern={pattern}
              placeholder={this.props.placeholder}
              readOnly={Boolean(this.props.isReadOnly)}
              required={this.props.isRequired}
              type={_.kebabCase(this.props.type)}
              value={this.state.value}
            />
            <ArrowRight />
            <input
              autoComplete={this.props.isAutoComplete ? 'on' : 'off'}
              disabled={this.props.isDisabled}
              name={this.props.name}
              max={this.props.maxDate ? this.props.maxDate.format('YYYY-MM-DDTHH:mm') : undefined}
              min={this.props.minDate ? this.props.minDate.format('YYYY-MM-DDTHH:mm') : undefined}
              onChange={this.handleChangeEvent}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              onKeyDown={this.handleKeyDown}
              pattern={pattern}
              placeholder={this.props.placeholder}
              readOnly={Boolean(this.props.isReadOnly)}
              required={this.props.isRequired}
              type={_.kebabCase(this.props.type)}
              value={this.state.value}
            />
          </div>
        );
        break;
      }
      case enums.Types.Percentage: {
        inputElement = (
          <div styleName="input-percentage">
            <input
              autoComplete={this.props.isAutoComplete ? 'on' : 'off'}
              disabled={this.props.isDisabled}
              max={100}
              maxLength={2}
              name={this.props.name}
              min={0}
              onBlur={this.handleBlur}
              onChange={this.handlePercentageChange}
              onFocus={this.handleFocus}
              onKeyDown={this.handleKeyDown}
              pattern={pattern}
              placeholder={this.props.placeholder}
              required={this.props.isRequired}
              className={inputClassNames}
              type="number"
              value={this.state.value}
            />
            <span>%</span>
          </div>
        );
        break;
      }
      default:
        inputElement = null;
    }

    return (
      <FormField
        label={this.props.label}
        labelFormat={this.props.labelFormat}
        secondaryLabel={this.props.secondaryLabel}>
        {inputElement}
        {this.props.icon ? iconToComponent[this.props.icon] : null}
      </FormField>
    );
  }

  setLibPhoneNumberJsRef = (element: any) => {
    this.libPhoneNumberJs = element;
    if (this.props.type === enums.Types.Tel && this.props.value) {
      const formatData = this.formatPhoneNumber(_.toString(this.props.value));
      this.setState({ value: formatData.value });
    }
  };

  formatPhoneNumber = (value: string, caretPosition?: number | null): {
    caretPosition: number;
    value: string;
  } => {
    if (!this.libPhoneNumberJs) return { caretPosition: caretPosition || 0, value };

    const formatter = new this.libPhoneNumberJs.AsYouType('US');
    const newValue = formatter.input(value);
    const template = formatter.getTemplate();

    let newCaretPosition = caretPosition;
    if (template) {
      if (newCaretPosition !== null && newCaretPosition !== undefined) {
        let index = 0;
        let isFound = false;

        let possiblyLastInputCharacterIndex = -1;
        while (index < newValue.length && index < template.length) {
          // Character placeholder found
          if (newValue[index] !== template[index]) {
            if (newCaretPosition === 0) {
              isFound = true;
              newCaretPosition = index;
              break;
            }

            possiblyLastInputCharacterIndex = index;
            newCaretPosition -= 1;
          } else if (
            (index === newValue.length - 1 && index >= template.length - 1) ||
            (index === template.length - 1 && index >= newValue.length - 1)
          ) {
            possiblyLastInputCharacterIndex = index;
          }

          index += 1;
        }

        // If the caret was positioned after last input character,
        // then the text caret index is just after the last input character.
        if (!isFound) newCaretPosition = possiblyLastInputCharacterIndex + 1;
      } else {
        newCaretPosition = newValue.length;
      }
    }

    return { value: newValue, caretPosition: newCaretPosition as number };
  }

  parsePhoneNumber = (value: string, element: HTMLInputElement, keyCode?: KeyCodes): {
    caretPosition: number;
    value: string;
  } => {
    const parseCharacter = (character: string) => character.replace(/[^0-9\+]/g, '');
    const caretPosition = element.selectionStart;

    let newValue = '';
    let newCaretPosition = 0;
    let index = 0;
    if (caretPosition !== null) {
      while (index < value.length) {
        newValue += parseCharacter(value[index]);

        if (caretPosition === index) {
          newCaretPosition = newValue.length - 1;
        } else if (caretPosition && caretPosition > index) {
          newCaretPosition = newValue.length;
        }

        index += 1;
      }
    } else {
      newCaretPosition = newValue.length;
    }

    if (keyCode === KeyCodes.BACKSPACE && newCaretPosition > 0) {
      // Remove the previous character
      newValue = newValue.slice(0, newCaretPosition - 1) + newValue.slice(newCaretPosition);
      // Position the caret where the previous (erased) character was
      newCaretPosition -= 1;
    } else if (keyCode === KeyCodes.DELETE) {
      // Remove current digit (if any)
      newValue = newValue.slice(0, newCaretPosition) + newValue.slice(newCaretPosition + 1);
    }

    return { value: newValue, caretPosition: newCaretPosition };
  }
}

export default withStyles(styles)(Input);
