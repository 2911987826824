// This file is being gradually migrated to ~tools/react/graphql/utils
// @flow

import _ from 'lodash';
import { graphql } from 'react-apollo';

export function removeNodesAndEdges(data) {
  if (_.isObject(data) && !_.isFunction(data) && !_.isArray(data)) {
    if (data.edges) {
      return _.map(data.edges, (item) => {
        if (item.node) {
          const obj = {};
          Object.keys(item.node).forEach((o) => {
            if (typeof item.node[o] === 'object') {
              obj[o] = removeNodesAndEdges(item.node[o]);
            } else {
              obj[o] = item.node[o];
            }
          });
          return obj;
        }
        return item;
      });
    }

    const keys = Object.keys(data);
    const output = {};
    _.map(keys, (key) => {
      const value = data[key];
      if (value && value.edges) {
        if (!_.isNil(value.breadcrumbs)) output[`${key}Breadcrumbs`] = value.breadcrumbs;
        if (!_.isNil(value.pageInfo)) output[`${key}PageInfo`] = value.pageInfo;
        if (!_.isNil(value.total)) output[`${key}Total`] = value.total;
        if (!_.isNil(value.viewport)) output[`${key}Viewport`] = value.viewport;
        output[key] = _.map(value.edges, (item) => {
          if (item.node) {
            const obj = {};
            Object.keys(item.node).forEach((o) => {
              if (typeof item.node[o] === 'object') {
                obj[o] = removeNodesAndEdges(item.node[o]);
              } else {
                obj[o] = item.node[o];
              }
            });
            return obj;
          }
          return item;
        });
      } else {
        output[key] = removeNodesAndEdges(value);
      }
    });

    return output;
  }

  if (_.isArray(data)) {
    return _.map(data, item => removeNodesAndEdges(item));
  }

  return data;
}

export default function extendedGraphQL(query, config) {
  if (!config) {
    return graphql(query, {
      props: props => ({ ...props, data: removeNodesAndEdges(props.data) }),
    });
  }

  let propsFunction = propsList => ({
    ...propsList,
    data: removeNodesAndEdges(propsList.data),
  });

  const configProps = config.props;
  if (configProps) {
    propsFunction = props => configProps({ ...props, data: removeNodesAndEdges(props.data) });
  }

  const configOptions = config.options;
  if (_.isFunction(configOptions)) {
    return graphql(query, {
      ...config,
      options: props => ({
        ...configOptions(props),
      }),
      props: propsFunction,
    });
  }

  return graphql(query, {
    ...config,
    options: {
      ...config.options,
    },
    props: propsFunction,
  });
}

export function prepData(data) {
  return removeNodesAndEdges(data);
}

export {
  compose,
} from '~tools/react/hocs/utils';

export {
  offsetToCursor,
  cursorToOffset,
  pageToCursor,
} from '~tools/react/graphql/utils';
