import _ from 'lodash';
import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import TableBody from './components/TableBody';
import TableData from './components/TableData';
import TableEmptyState from './components/TableEmptyState';
import TableHead from './components/TableHead';
import TableHeader from './components/TableHeader';
import TablePaginator from './components/TablePaginator';
import TableRow from './components/TableRow';

import styles from './Table.scss';

interface Props {
  children: ReactNode;
}

function Table(props: Props) {
  return (
    <table styleName="table">
      {props.children}
    </table>
  );
}

const StyledTable = withStyles(styles)(Table);
export default _.assign(StyledTable, {
  TableBody,
  TableData,
  TableEmptyState,
  TableHead,
  TableHeader,
  TablePaginator,
  TableRow,
});
