// Note: This is purely temporary and the tools Table component should be updated, I just didn't want to do it yet

import _ from 'lodash';
import React, { Fragment, ReactNode } from 'react';

import Center from '~tools/react/components/Center';
import Spinner from '~tools/react/components/Spinner';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import withStyles from '~tools/react/hocs/withStyles';

import TableData from './components/TableData';
import TableEmptyState from './components/TableEmptyState';
import TableHeader from './components/TableHeader';
import TablePaginator from './components/TablePaginator';
import TableRow from './components/TableRow';

import styles from './Table.scss';

interface Props {
  children: ReactNode,
  isLoading?: boolean,
}

function Table(props: Props) {
  if (props.isLoading) {
    return (
      <Fragment>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXLarge} />
        <Center><Spinner /></Center>
      </Fragment>
    );
  }

  return (
    <div styleName="table">
      <table styleName="table__content">
        <tbody>
          {props.children}
        </tbody>
      </table>
    </div>
  );
}

Table.defaultProps = {
  isLoading: false,
};

const StyledTable = withStyles(styles)(Table);
export default _.assign(StyledTable, { TableData, TableEmptyState, TableHeader, TableRow, TablePaginator });
