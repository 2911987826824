// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._aeRvmy{-webkit-box-align:center;align-items:center;border:0;border-radius:50%;display:-webkit-box;display:flex;height:40px;-webkit-box-pack:center;justify-content:center;min-width:40px;padding:0;-webkit-transition:background-color .25s;transition:background-color .25s;width:40px}._aeRvmy svg{color:#8492a6;height:18px;-webkit-transition:color .25s;transition:color .25s;width:18px}._aeRvmy:active{background-color:#f6f8fa;box-shadow:none}._aeRvmy:disabled{opacity:.5;pointer-events:none}._aeRvmy._GN6aYa{background-color:#eff2f7}._aeRvmy._GN6aYa:disabled{opacity:1}._aeRvmy._1qAWQV:active svg{color:#1f8eed}._aeRvmy._1qAWQV:hover{background-color:#e4f6fe}._aeRvmy._1qAWQV:hover svg{color:#1f8eed}._aeRvmy._DBDo7a:active svg{color:#21bc7a}._aeRvmy._DBDo7a:hover{background-color:#e6fcf9}._aeRvmy._DBDo7a:hover svg{color:#21bc7a}._aeRvmy._7RAPGa:active svg{color:#ff5167}._aeRvmy._7RAPGa:hover{background-color:#ffeaed}._aeRvmy._7RAPGa:hover svg{color:#ff5167}", ""]);
// Exports
exports.locals = {
	"icon-button": "_aeRvmy",
	"icon-button--loading": "_GN6aYa",
	"icon-button--color-blue": "_1qAWQV",
	"icon-button--color-green": "_DBDo7a",
	"icon-button--color-red": "_7RAPGa"
};
module.exports = exports;
