import _ from 'lodash';
import React, { Fragment, PureComponent } from 'react';

import { AmenityTypes } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withUpdateListing, { UpdateListingProps } from '~tools/react/graphql/mutations/listings/withUpdateListing';

import Button from '~tools/react/components/Button';
import Form, { Checkbox } from '~tools/react/components/Form';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import Text from '~tools/react/components/Text';
import ThemedModal from '~tools/react/components/ThemedModal';

import query from './UpdateAmenitiesModal.gql';

interface InputProps {
  isOpen: boolean;
  listingUuid: string;
  onClose: () => void;
}

type Props = InputProps & QueryProps & UpdateListingProps;

class UpdateAmenitiesModal extends PureComponent<Props> {
  handleSubmit = async (data: {
    [uuid: string]: boolean;
  }) => {
    const amenityUuids = _.compact(_.map(data, (value, key) => (value ? key : undefined)));
    await this.props.updateListing(this.props.listingUuid, { amenityUuids });
    this.props.onClose();
  };

  render() {
    const listing = this.props.listing;

    return (
      <ThemedModal
        width={ThemedModal.enums.Widths.Medium}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title="Amenities">
        <ThemedModal.ThemedModalSection>
          <Form onSubmit={this.handleSubmit}>
            <Form.FormSection columns={Form.FormSection.enums.Columns.Two}>
              <div>
                <Text content="Common amenities" />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
                {_.map(_.filter(this.props.amenities, { type: AmenityTypes.PRIMARY }), (amenity, index) => (
                  <Fragment key={amenity.uuid}>
                    {index > 0 ? <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} /> : null}
                    <Checkbox
                      isChecked={!!_.find(listing?.amenities, { name: amenity.name })}
                      key={amenity.name}
                      label={amenity.name}
                      name={amenity.uuid}
                    />
                  </Fragment>
                ))}
              </div>
              <div>
                <Text content="Additional amenities" />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
                {_.map(_.filter(this.props.amenities, { type: AmenityTypes.SECONDARY }), (amenity, index) => (
                  <Fragment key={amenity.uuid}>
                    {index > 0 ? <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} /> : null}
                    <Checkbox
                      isChecked={!!_.find(listing?.amenities, { name: amenity.name })}
                      key={amenity.name}
                      label={amenity.name}
                      name={amenity.uuid}
                    />
                  </Fragment>
                ))}
              </div>
            </Form.FormSection>
            <Button
              align={Button.enums.Alignments.Right}
              label="Save"
              size={Button.enums.Sizes.Small}
              type={Button.enums.Types.Submit}
            />
          </Form>
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

interface Amenity {
  name: string;
  type: AmenityTypes;
  uuid: string;
}

interface Listing {
  amenities: Amenity[];
  updatedAt: string;
  uuid: string;
}

interface Viewer {
  listing: Listing;
  uuid: string;
}

interface Response {
  amenities: Amenity[];
  viewer: Viewer;
}

interface QueryProps {
  amenities: Amenity[];
  isLoading: boolean;
  listing: Listing | null;
}

export default compose(
  withUpdateListing,
  withQuery<InputProps, Response, {}, QueryProps>(query, {
    options: (props) => ({
      variables: {
        listingUuid: props.listingUuid,
      },
      skip: !props.isOpen,
    }),
    props: (props) => ({
      amenities: props.data?.amenities || [],
      isLoading: props.loading,
      listing: props.data?.viewer.listing || null,
    }),
  }),
)(UpdateAmenitiesModal);
