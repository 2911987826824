import React from 'react';
import { Redirect } from 'react-router';

import PayWallCard from '~tools/react/components/PayWallCard/PayWallCard';
import { compose } from '~tools/react/hocs/utils';
import withPaymentSubscription, { PaymentSubscriptionProps } from '~tools/react/hocs/withPaymentSubscription';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';

type Props = PaymentSubscriptionProps;

function Maintain(props: Props) {
  if (!props.paymentSubscription) {
    return (
      <ManageStage>
        <PayWallCard
          featureName="Maintenance"
          title="Keep your homes in pristiine condition"
          intro="Control the quality, cost, and timing of maintenance and repairs - without doing all the work yourself."
          bullets={[
            'Limit maintenance and repair work to your preapproved team of service providers',
            'Build spending controls into your payment methods so that no one ever overspends on maintenance or repair work',
            'Track property condition meticulously with collaborative home inspections and service history reports',
          ]}
        />
      </ManageStage>
    );
  }

  return (
    <Redirect to="/issues" />
  );
}

export default compose(
  withPaymentSubscription,
)(Maintain);
