import hoistStatics from 'hoist-non-react-statics';
import _ from 'lodash';
import memoize from 'memoize-one';
import moment, { Moment } from 'moment-timezone';
import React, { ComponentType, PureComponent } from 'react';
import isEqual from 'react-fast-compare';
import uuid from 'uuid';

import {
  LeaseRoles,
  RentPaymentRoles,
} from '~tools/types/graphqlSchema';

import { getDifferenceInMonths } from '~tools/utils/time';

import withQuery from '~tools/react/graphql/withQuery';
import * as cardEnums from '~tools/react/components/Card/enums';

import { formatAsUSD } from '~tools/utils/string';

import * as enums from './enums';

import query from './withRentPaymentVolume.gql';

interface Lease {
  endDate: string;
  nextRentPaymentMonthStarting: string;
  rentInCents: number;
  startDate: string;
  uuid: string;
  addressUnit: {
    uuid: string;
    address: {
      uuid: string;
      timezone: string;
    };
  };
}

interface RentPayment {
  amountInCents: number;
  createdAt: string;
  monthStarting?: string;
  monthEnding?: string;
  uuid: string;
  lease: {
    uuid: string;
    addressUnit: {
      uuid: string;
      address: {
        uuid: string;
        timezone: string;
      };
    };
  };
}

interface QueryProps {
  isLoading: boolean;
  leases: Lease[];
  rentPayments: RentPayment[];
}

interface Response {
  viewer: {
    uuid: string;
    rentPayments: RentPayment[];
    leases: Lease[];
  } | null;
}

export interface RentPaymentVolumeProps {
  header: {
    title: string;
    subtitle?: string;
    actions?: {
      icon?: cardEnums.ActionIcons;
      label: string;
      link: {
        path: string;
      };
    }[];
  };
  futureDataPoints: number;
  pastDataPoints: number;
  unit: enums.Units;
  height: number;
}

interface RentPaymentVolumeBlock {
  Received: number;
  Expected: number;
  groupStartsAt: string;
}

export interface RentPaymentVolumeProvidedProps extends QueryProps {
  rentPaymentVolume: RentPaymentVolumeBlock[];
}

type AllProps =
  RentPaymentVolumeProvidedProps &
  RentPaymentVolumeProps;

interface State {
  allRentPaymentVolume: RentPaymentVolumeBlock[];
}

function withRentPaymentVolume<T>(ComposedComponent: ComponentType<T & AllProps>) {
  class WithRentPaymentVolume extends PureComponent<T & QueryProps & RentPaymentVolumeProps, State> {
    static ComposedComponent = ComposedComponent;
    static displayName = `withRentPaymentVolume(${ComposedComponent.displayName || ComposedComponent.name || 'Component'})`;

    state: State = {
      allRentPaymentVolume: [],
    };

    getMemoizedEstimatedRentPayments = memoize((leases: Lease[]) => {
      const placeholderLease = {
        endDate: '',
        nextRentPaymentMonthStarting: '',
        rentInCents: 0,
        startDate: '',
        uuid: '',
        addressUnit: {
          uuid: '',
          address: {
            uuid: '',
            timezone: 'UTC',
          },
        },
      };
      const now = moment.utc();
      const startingDate = now.clone().subtract(this.props.pastDataPoints + 1, this.props.unit);
      const endingDate = now.clone().add(this.props.futureDataPoints + 1, this.props.unit);
      const placeholderRentPayments: RentPayment[] = _.times(
        endingDate.diff(startingDate, this.props.unit),
        (index) => ({
          createdAt: moment().toISOString(),
          uuid: uuid.v4(),
          amountInCents: 0,
          lease: placeholderLease,
          monthEnding: startingDate.clone().add(index, this.props.unit).endOf(this.props.unit).toISOString(),
          monthStarting: startingDate.clone().add(index, this.props.unit).startOf(this.props.unit).toISOString(),
        }),
      );
      const estimatedPayments: RentPayment[][] = _.map(leases, (lease) => {
        const rentPayments: RentPayment[] = [];
        const createHistoricRentPayment = (passedMonthEnding?: Moment) => {
          const monthStarting = this.getNextRentPaymentMonthStarting(lease, rentPayments);
          const amountInCents = this.getNextRentPaymentAmount(lease, rentPayments);
          let monthEnding = passedMonthEnding;
          if (monthEnding && monthEnding.isSameOrBefore(monthStarting)) return;
          if (!monthEnding) monthEnding = this.getNextRentPaymentMonthEnding(lease, rentPayments);

          rentPayments.push({
            createdAt: moment().toISOString(),
            uuid: uuid.v4(),
            amountInCents,
            lease,
            monthEnding: monthEnding?.toISOString(),
            monthStarting: monthStarting?.toISOString(),
          });
        };

        const createAllPayments = (createUpTo) => {
          const paidUpTo = this.getPaidUpToDate(lease, rentPayments);
          if (!paidUpTo) return rentPayments;

          // If there are still months to create, recurse
          const historicMonthsLeft = getDifferenceInMonths(paidUpTo, createUpTo);
          if (historicMonthsLeft > 1) {
            createHistoricRentPayment();
            return createAllPayments(createUpTo);
          }

          createHistoricRentPayment(createUpTo);

          return rentPayments;
        };

        // All of this logic is actually pretty heavy for the frontend so
        // only calculate predicted payments up to the end of the graph range
        // (should probably do this on the backend eventually for a variety of reasons)
        // - Roger
        const endOfRange = moment()
          .tz(lease.addressUnit.address.timezone)
          .add(this.props.futureDataPoints, this.props.unit)
          .endOf(this.props.unit);
        const leaseEndDate = moment(lease.endDate)
          .tz(lease.addressUnit.address.timezone)
          .endOf('day');

        const createUpTo = leaseEndDate.isAfter(endOfRange) ? endOfRange : leaseEndDate;
        return createAllPayments(createUpTo);
      });

      return _.flatten([...estimatedPayments, ...placeholderRentPayments]);
    });

    getAllRentPaymentVolume = memoize((viewerLeases: Lease[], viewerRentPayments: RentPayment[]) => {
      const groupedRentPayments = _.groupBy(
        _.filter(viewerRentPayments, rentPayment => rentPayment.monthStarting !== rentPayment.monthEnding),
        rentPayment => moment(rentPayment.monthStarting).utc().startOf(this.props.unit).toISOString(),
      );
      const groupedRentPaymentVolume = _.map(groupedRentPayments, (rentPayments, key) => ({
        groupStartsAt: key,
        totalAmountInCents: _.sumBy(rentPayments, rentPayment => rentPayment.amountInCents || 0),
      }));
      const orderedGroupedRentPaymentVolume = _.orderBy(groupedRentPaymentVolume, group => group.groupStartsAt);

      const estimatedRentPayments = _.filter(
        this.getMemoizedEstimatedRentPayments(viewerLeases),
        rentPayment => (
          moment(rentPayment.monthStarting)
            .tz(rentPayment.lease.addressUnit.address.timezone)
            .startOf(this.props.unit)
            .isSameOrAfter(
              moment.utc().subtract(this.props.pastDataPoints, this.props.unit).startOf(this.props.unit),
            )
          &&
          moment(rentPayment.monthStarting)
            .tz(rentPayment.lease.addressUnit.address.timezone)
            .startOf(this.props.unit)
            .isSameOrBefore(
              moment.utc().add(this.props.futureDataPoints, this.props.unit).endOf(this.props.unit),
            )
        ),
      );
      const groupedEstimatedRentPayments = _.groupBy(
        estimatedRentPayments,
        rentPayment => moment(rentPayment.monthStarting).utc().startOf(this.props.unit).toISOString(),
      );
      const groupedEstimatedRentPaymentVolume = _.map(groupedEstimatedRentPayments, (rentPayments, key) => ({
        groupStartsAt: key,
        totalAmountInCents: _.sumBy(rentPayments, rentPayment => rentPayment.amountInCents || 0),
      }));
      const orderedGroupedEstimatedRentPaymentVolume = _.orderBy(
        groupedEstimatedRentPaymentVolume,
        group => group.groupStartsAt,
      );
      const allRentPaymentVolume = _.map(orderedGroupedEstimatedRentPaymentVolume, (estimatedGroup) => {
        const volumeInCents = _.find(
          orderedGroupedRentPaymentVolume,
          group => group.groupStartsAt === estimatedGroup.groupStartsAt,
        )?.totalAmountInCents || 0;
        return {
          Received: volumeInCents,
          Expected: Math.max(estimatedGroup.totalAmountInCents - volumeInCents, 0),
          groupStartsAt: estimatedGroup.groupStartsAt,
        };
      });
      return allRentPaymentVolume;
    })

    componentDidMount() {
      const allRentPaymentVolume = this.getAllRentPaymentVolume(this.props.leases, this.props.rentPayments);
      this.setState({ allRentPaymentVolume });
    }

    componentDidUpdate(prevProps: QueryProps) {
      if (!isEqual(this.props, prevProps)) {
        const allRentPaymentVolume = this.getAllRentPaymentVolume(this.props.leases, this.props.rentPayments);
        this.setState({ allRentPaymentVolume });
      }
    }

    render() {
      const allRentPaymentVolume = this.state.allRentPaymentVolume;
      return (
        <ComposedComponent
          {...this.props}
          isLoading={(this.props.rentPayments.length > 0 && allRentPaymentVolume.length === 0) || this.props.isLoading}
          rentPaymentVolume={allRentPaymentVolume}
        />
      );
    }

    getPaidUpToDate(lease: Lease, rentPayments: RentPayment[]) {
      // Lease is "paid up" to the moment before it starts by default, start of graph range if that's first
      const leaseStart = moment(lease.startDate).tz(lease.addressUnit.address.timezone).subtract(1, 'days').endOf('day');
      const startOfRange = moment()
        .tz(lease.addressUnit.address.timezone)
        .subtract(this.props.pastDataPoints, this.props.unit)
        .endOf(this.props.unit);
      const paidUpTo = leaseStart.isBefore(startOfRange) ? startOfRange : leaseStart;

      // If a rent payment has already been made, use that
      const latestRentPayment = _.last(rentPayments);
      if (!latestRentPayment) return paidUpTo;

      return moment(latestRentPayment.monthEnding).tz(lease.addressUnit.address.timezone);
    }

    getNextRentPaymentAmount(lease: Lease, rentPayments: RentPayment[]) {
      const paidUpTo = this.getPaidUpToDate(lease, rentPayments);
      const rentInCents = lease.rentInCents;
      const followingMonthRentRateInCents = rentInCents / (paidUpTo.clone().add(1, 'months').daysInMonth() * 24 * 60 * 60 * 1000);
      const paidUpToMonthRentRateInCents = rentInCents / (paidUpTo.daysInMonth() * 24 * 60 * 60 * 1000);
      const timeLeftUntilLeaseEnd = moment(lease.endDate).tz(lease.addressUnit.address.timezone).diff(paidUpTo, 'ms');
      const timeLeftInPartialMonth = paidUpTo.clone().endOf('month').diff(paidUpTo, 'ms');

      if (paidUpTo.isSameOrAfter(moment(lease.endDate).tz(lease.addressUnit.address.timezone).endOf('day'))) {
        return 0;
      }

      // If rent isn't paid but not up to the end of this month, next rent is a partial rent payment
      if (paidUpTo.isBefore(paidUpTo.clone().endOf('month').endOf('day'))) {
        // If lease is ending within the month, next rent is the remainder of the lease
        if (timeLeftUntilLeaseEnd < timeLeftInPartialMonth) {
          return paidUpToMonthRentRateInCents * timeLeftUntilLeaseEnd;
        }

        return paidUpToMonthRentRateInCents * timeLeftInPartialMonth;
      }

      // Lease has at least one more full month left and is paid up to the end of the month
      if (moment(lease.endDate).tz(lease.addressUnit.address.timezone).endOf('day').isSameOrAfter(paidUpTo.clone().add(1, 'months').endOf('month').endOf('day'))) {
        return rentInCents;
      }

      // Lease is ending, prorate remaining days
      return followingMonthRentRateInCents * timeLeftUntilLeaseEnd;
    }

    getNextRentPaymentMonthStarting(lease: Lease, rentPayments: RentPayment[]): Moment | undefined {
      const paidUpTo = this.getPaidUpToDate(lease, rentPayments);

      // If paidUpTo is null, rent isn't due yet
      if (!paidUpTo) return undefined;

      // If the lease is ending, rent is no longer due
      if (paidUpTo.isSameOrAfter(moment(lease.endDate).tz(lease.addressUnit.address.timezone).endOf('day'))) return undefined;

      // Otherwise, next starting time is always the current paidUpTo time + 1ms
      return paidUpTo.add(1, 'ms');
    }

    getNextRentPaymentMonthEnding(lease: Lease, rentPayments: RentPayment[]): Moment | undefined {
      const nextRentPaymentMonthStarting = this.getNextRentPaymentMonthStarting(lease, rentPayments);

      // If there's no next starting date, there's no next ending date
      if (!nextRentPaymentMonthStarting) return undefined;

      const endDate = moment(lease.endDate).tz(lease.addressUnit.address.timezone).endOf('day');
      const endOfMonth = nextRentPaymentMonthStarting.clone().endOf('month');

      // If lease is ending, return end date
      if (endDate.isBefore(endOfMonth)) return endDate;

      // Otherwise just end at the end of the month
      return endOfMonth;
    }

    formatAsUSD = (value: string) => `$${formatAsUSD(value)}`;
  }

  const WithRentPaymentVolumeAndQuery = withQuery<
    T & RentPaymentVolumeProps,
    Response,
    {},
    QueryProps
  >(query, {
    options: (props) => ({
      fetchPolicy: 'network-only',
      variables: {
        leasesFilter: {
          isFullyExecuted: true,
          role: {
            eq: LeaseRoles.LESSOR,
          },
        },
        rentPaymentsFilter: {
          paidPeriodStartsAt: {
            gte: moment.utc().subtract(props.pastDataPoints, props.unit).startOf(props.unit).toISOString(),
          },
          role: {
            eq: RentPaymentRoles.PAYEE,
          },
        },
      },
    }),
    props: props => ({
      isLoading: props.loading,
      leases: props.data?.viewer?.leases ?? [],
      rentPayments: props.data?.viewer?.rentPayments ?? [],
    }),
  })(WithRentPaymentVolume);

  return hoistStatics(WithRentPaymentVolumeAndQuery, ComposedComponent);
}

export default withRentPaymentVolume;
