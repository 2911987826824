import React, { PureComponent } from 'react';
import memoize from 'memoize-one';
import moment, { Moment } from 'moment';
import _ from 'lodash';

import withStyles from '~tools/react/hocs/withStyles';

import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';

import CalendarDay from './components/CalendarDay';

import styles from './Calendar.scss';

import * as utils from './utils';

interface Account {
  firstName: string;
  fullName: string;
  photoUrl: string;
  uuid: string;
}

interface CalendarEvent {
  account: Account | null;
  endsAt: string;
  label?: string;
  link?: {
    label: string;
    path: string;
  };
  startsAt: string;
  uuid: string;
}

interface TimeSlot extends CalendarEvent {
  dayOfWeek: number;
}

interface Props {
  availableTimes: TimeSlot[];
  scheduledTimes: {
    account: Account | null;
    calendarEvent: CalendarEvent;
    uuid: string;
  }[];
  timezone: string;
}

interface State {
  date: Moment;
}

class Calendar extends PureComponent<Props, State> {
  state: State = {
    date: moment.tz(this.props.timezone),
  };

  getMemoizedEvents = memoize((availableTimes: TimeSlot[], scheduledTimes: CalendarEvent[]) => {
    const data: {
      [key: string]: {
        account: Account | null;
        endsAt: Moment;
        label?: string;
        link?: {
          label: string;
          path: string;
        };
        startsAt: Moment;
        uuid: string;
      }[];
    } = {};
    _.map(availableTimes, (time) => {
      const startsAt = utils.convertTimeSlotToNearestDate(time.startsAt, time.dayOfWeek, this.props.timezone);
      const endsAt = utils.convertTimeSlotToNearestDate(time.endsAt, time.dayOfWeek, this.props.timezone);
      if (startsAt.clone().format('MMM') !== moment.tz(this.state.date, this.props.timezone).format('MMM')) return;

      const event = {
        endsAt,
        startsAt,
        account: null,
        label: time.label,
        link: time.link,
        uuid: time.uuid,
      };
      const key = startsAt.clone().startOf('day').toISOString();
      if (data[key]) {
        data[key].push(event);
      } else {
        data[key] = [event];
      }
    });
    _.map(scheduledTimes, (time) => {
      const startsAt = moment.tz(time.startsAt, this.props.timezone);
      const endsAt = moment.tz(time.endsAt, this.props.timezone);
      if (startsAt.clone().format('MMM') !== moment.tz(this.state.date, this.props.timezone).format('MMM')) return;

      const event = {
        startsAt,
        endsAt,
        label: time.label,
        link: time.link,
        account: time.account,
        uuid: time.uuid,
      };
      const key = startsAt.clone().startOf('day').toISOString();
      if (data[key]) {
        data[key].push(event);
      } else {
        data[key] = [event];
      }
    });
    return data;
  });

  handleClickNext = () => {
    this.setState({
      date: moment.tz(this.state.date, this.props.timezone).add(1, 'week'),
    });
  }

  handleClickPrevious = () => {
    this.setState({
      date: moment.tz(this.state.date, this.props.timezone).subtract(1, 'week'),
    });
  }

  render() {
    const today = moment.tz(this.props.timezone);
    const firstDay = moment.tz(this.state.date, this.props.timezone).startOf('week');
    const lastDay = firstDay.clone().endOf('week');

    let monthLabel = `*${firstDay.format('MMMM')}* ${firstDay.format('Do')} - ${lastDay.format('Do')}`;
    if (firstDay.month() !== lastDay.month()) monthLabel = `*${firstDay.format('MMM')}* ${firstDay.format('Do')} - *${lastDay.format('MMM')}* ${lastDay.format('Do')}`;

    const memoizedTimes = this.getMemoizedEvents(this.props.availableTimes, _.map(this.props.scheduledTimes, time => ({
      ...time.calendarEvent,
      account: time.account,
    })));
    return (
      <div styleName="calendar">
        <div styleName="calendar__header">
          <Heading
            content={`*It's ${today.format('dddd')},* ${today.format('MMMM Do')}`}
            font={Heading.enums.Fonts.Secondary}
            isMarkdown
            priority={Heading.enums.Priorities.Three}
            size={Heading.enums.Sizes.XXSmall}
          />
          <Heading
            align={Heading.enums.Align.Right}
            content={`${monthLabel}`}
            font={Heading.enums.Fonts.Secondary}
            isMarkdown
            priority={Heading.enums.Priorities.Three}
            size={Heading.enums.Sizes.XXSmall}
          />
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
          <Button
            color={Button.enums.Colors.Gray}
            icon={Button.enums.Icons.ChevronLeft}
            onClick={this.handleClickPrevious}
            size={Button.enums.Sizes.XSmall}
            style={Button.enums.Styles.Outline}
          />
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
          <Button
            color={Button.enums.Colors.Gray}
            icon={Button.enums.Icons.ChevronRight}
            onClick={this.handleClickNext}
            size={Button.enums.Sizes.XSmall}
            style={Button.enums.Styles.Outline}
          />
        </div>
        <Card
          hasMargin={false}
          shadow={Card.enums.Shadows.Small}>
          <div styleName="calendar-grid">
            {_.times(7, x => {
              const date = firstDay.clone().add(x, 'days').toISOString();
              return (
                <CalendarDay
                  date={date}
                  events={memoizedTimes[date]}
                  key={x}
                  timezone={this.props.timezone}
                />
              );
            })}
          </div>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(Calendar);
