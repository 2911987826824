// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._orGm9Z{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-flex:1;flex:1;height:24px;position:relative;width:100%}._orGm9Z ._VYQYqr{background:#fff;border-radius:10px;box-shadow:0 2px 8px rgba(31,45,61,.2);color:#53d391;display:inline-block;font-weight:700;height:24px;line-height:24px;position:absolute;text-align:center;width:45px}._orGm9Z ._mmJUK3{color:#ff5167}._orGm9Z ._WVjPrM{color:#ff7849}._orGm9Z ._prWbAq{color:#f2a615}._orGm9Z ._80AAw9{color:#53d391}._orGm9Z ._5aA1N7{color:#21bc7a}._orGm9Z ._jrVWp6{background:-webkit-gradient(linear,right top, left top,from(#21bc7a),color-stop(#fffe6d),to(#ff5167));background:linear-gradient(270deg,#21bc7a,#fffe6d,#ff5167);border-radius:10px;display:-webkit-box;display:flex;-webkit-box-flex:1;flex:1;height:8px;width:100%}", ""]);
// Exports
exports.locals = {
	"credit-score": "_orGm9Z",
	"credit-score__slider": "_VYQYqr",
	"credit-score__slider--red": "_mmJUK3",
	"credit-score__slider--orange": "_WVjPrM",
	"credit-score__slider--yellow": "_prWbAq",
	"credit-score__slider--green": "_80AAw9",
	"credit-score__slider--dark-green": "_5aA1N7",
	"credit-score__bar": "_jrVWp6"
};
module.exports = exports;
