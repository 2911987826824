export enum BOOST_CAMPAIGN_TYPES {
  RECURRING = 'RECURRING',
  LIFETIME = 'LIFETIME',
}

export const BOOST_SUBHEADLINE_CTA = 'Find a renter ASAP by boosting your listing';
export const BOOST_HEADLINE_CTA = 'Need a tenant yesterday?';
export const BOOST_BUY_BUTTON_LABEL = 'Promote my listing';

export const MINIMUM_RECURRING_BOOST_AMOUNT_ALLOWED_IN_DOLLARS = 5;
export const MINIMUM_LIFETIME_BOOST_AMOUNT_ALLOWED_IN_DOLLARS = 20;
export const DEFAULT_DAILY_BOOST_AMOUNT_IN_DOLLARS = 10;
export const DEFAULT_LIFETIME_BOOST_AMOUNT_IN_DOLLARS = 50;
export const DEFAULT_CAMPAIGN_TYPE = BOOST_CAMPAIGN_TYPES.RECURRING;
export const DEFAULT_BOOST_AMOUNT = DEFAULT_DAILY_BOOST_AMOUNT_IN_DOLLARS;
