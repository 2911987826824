import _ from 'lodash';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import Card from '~tools/react/components/Card';
import GenericLoadingVisual from '~tools/react/components/GenericLoadingVisual';
import Tag from '~tools/react/components/Tag';
import Text from '~tools/react/components/Text';
import { getAbbreviatedStreetAddress } from '~tools/utils/geography/addresses';
import Avatar from '~tools/react/components/Avatar';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import { IssuesFilterInput, IssueStatuses } from '~tools/types/graphqlSchema';
import Heading from '~tools/react/components/Heading';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import Row from '~tools/react/components/Row';
import { pageToCursor } from '~tools/react/graphql/utils';
import CreateIssueModal from '~tools/react/containers/CreateIssueModal';
import Button from '~tools/react/components/Button';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';
import { formatUnitName } from '~web-manage/lib/common/utils/addressUnit';
import Table from '~tools/react/components/Table';

import IssuesFilterDropdown from './components/IssuesFilterDropdown';
// import MaintenanceOnboardingCard from './containers/MaintenanceOnboardingCard';

import { AddressUnit, Issue } from './types';
import query from './Issues.gql';

const NUMBER_OF_ISSUES_TO_FETCH = 10;

type Props = QueryProps
  & RouteComponentProps;

interface State {
  isCreateIssueModalOpen: boolean;
}

class Issues extends Component<Props, State> {
  state: State = {
    isCreateIssueModalOpen: false,
  };

  handleOpenCreateIssueModal = () => this.setState({ isCreateIssueModalOpen: true });
  handleCloseCreateIssueModal = () => this.setState({ isCreateIssueModalOpen: false });

  handleIssueCreated = async (issueUuid: string) => {
    await this.props.refetch();
    this.props.history.push(`/issues/${issueUuid}`);
  };

  handleSaveFilters = (
    status: IssueStatuses | null,
    propertyManagerContractUuid: string | null
  ) => {
    const qs = queryString.stringify({
      status: status ? _.kebabCase(status) : undefined,
      propertyManagerContractUuid: propertyManagerContractUuid || undefined,
    });

    this.props.history.push(`/issues?${qs}`);
  };

  render() {
    const loadingScreen = (
      <ManageStage>
        <Card>
          <Card.CardSection>
            <GenericLoadingVisual />
          </Card.CardSection>
        </Card>
      </ManageStage>
    );

    if (this.props.isLoading) return loadingScreen;

    const issues = this.props.issues;
    if (!issues) return loadingScreen;

    const qs = queryString.parse(this.props.location.search);

    let status: IssueStatuses | undefined;
    if (_.isArray(qs.status)) {
      status = _.upperCase(qs.status[0]) as IssueStatuses;
    } else if (qs.status) {
      status = _.upperCase(qs.status) as IssueStatuses;
    }

    let propertyManagerContractUuid: string | undefined;
    if (_.isArray(qs.propertyManagerContractUuid)) {
      propertyManagerContractUuid = qs.propertyManagerContractUuid[0];
    } else if (qs.propertyManagerContractUuid) {
      propertyManagerContractUuid = qs.propertyManagerContractUuid;
    }

    let subtitle: string;
    let title: string;
    let filteredAddressUnit: AddressUnit | undefined;
    if (propertyManagerContractUuid && issues.length) {
      filteredAddressUnit = issues[0].addressUnit;
      title = 'Unit issues';
      subtitle = `${getAbbreviatedStreetAddress(filteredAddressUnit.address.streetAddress1)}, ${formatUnitName(filteredAddressUnit)}`;
    } else if (status) {
      title = `${_.capitalize(status)} issues`;
      subtitle = `View ${_.capitalize(status)} issues for all your units`;
    } else {
      title = 'All issues';
      subtitle = 'View maintenance issues for all your units';
    }

    return (
      <ManageStage>
        {/*
        This is gone for the foreseeable future
         {!this.props.isMaintenanceOnboarded ? (
          <MaintenanceOnboardingCard />
        ) : null} */}
        <Card>
          <Card.CardSection>
            <Row
              flexBehavior={Row.enums.FlexBehaviors.Default}
              verticalAlignment={Row.enums.VerticalAlignments.Center}>
              <div>
                <Heading
                  content={title}
                  font={Heading.enums.Fonts.Secondary}
                  priority={Heading.enums.Priorities.One}
                  size={Heading.enums.Sizes.XSmall}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                <Heading
                  content={subtitle}
                  font={Heading.enums.Fonts.Secondary}
                  priority={Heading.enums.Priorities.Two}
                  size={Heading.enums.Sizes.XXXSmall}
                />
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <IssuesFilterDropdown
                  statusFilter={status ?? null}
                  propertyManagerContractFilter={propertyManagerContractUuid && filteredAddressUnit ? {
                    uuid: propertyManagerContractUuid,
                    addressUnit: filteredAddressUnit,
                  } : null}
                  onSaveFilters={this.handleSaveFilters}
                />
              </div>
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
              <Button
                color={Button.enums.Colors.Gray}
                icon={Button.enums.Icons.Plus}
                label="Report issue"
                size={Button.enums.Sizes.Small}
                style={Button.enums.Styles.Outline}
                onClick={this.handleOpenCreateIssueModal}
              />
            </Row>
          </Card.CardSection>
          <Table>
            <Table.TableHead>
              <Table.TableRow>
                <Table.TableHeader>Created</Table.TableHeader>
                <Table.TableHeader>Status</Table.TableHeader>
                <Table.TableHeader>Damage type</Table.TableHeader>
                <Table.TableHeader>Unit</Table.TableHeader>
                <Table.TableHeader>Reporter</Table.TableHeader>
                <Table.TableHeader>Updated</Table.TableHeader>
                <Table.TableHeader></Table.TableHeader>
              </Table.TableRow>
            </Table.TableHead>
            <Table.TableBody>
              {issues.map(issue => {
                const shortName = `${issue.reporterAccount.firstName
                  } ${issue.reporterAccount.lastName.charAt(0)
                  }.`;

                const abbreviatedStreetAddress = getAbbreviatedStreetAddress(issue.addressUnit.address.streetAddress1);

                return (
                  <Table.TableRow key={issue.uuid} link={{ path: `/issues/${issue.uuid}` }}>
                    {/* Created */}
                    <Table.TableData>
                      <Text
                        content={moment(issue.createdAt).fromNow()}
                        overflow={Text.enums.OverflowValues.Ellipsis}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Small}
                      />
                    </Table.TableData>

                    {/* Status */}
                    <Table.TableData>
                      {issue.issueResolution ? (
                        <Tag
                          color={Tag.enums.Colors.Green}
                          icon={Tag.enums.Icons.Check}
                          label="Resolved"
                        />
                      ) : (
                        <Tag
                          color={Tag.enums.Colors.Orange}
                          label="Outstanding"
                        />
                      )}
                    </Table.TableData>

                    {/* Damage type */}
                    <Table.TableData>
                      <Text
                        content={_.capitalize(_.lowerCase(issue.serviceType))}
                        overflow={Text.enums.OverflowValues.Ellipsis}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Small}
                      />
                    </Table.TableData>

                    {/* Unit */}
                    <Table.TableData>
                      <Text
                        content={`${abbreviatedStreetAddress}, ${formatUnitName(issue.addressUnit)}`}
                        overflow={Text.enums.OverflowValues.Ellipsis}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Small}
                      />
                    </Table.TableData>

                    {/* Reporter */}
                    <Table.TableData>
                      <Row
                        flexBehavior={Row.enums.FlexBehaviors.Default}
                        verticalAlignment={Row.enums.VerticalAlignments.Center}>
                        <Avatar
                          size={Avatar.enums.Sizes.XSmall}
                          imageUrl={issue.reporterAccount.photoUrl}
                        />
                        <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                        <Text
                          content={shortName}
                          overflow={Text.enums.OverflowValues.Ellipsis}
                          shouldWrap={false}
                          size={Text.enums.Sizes.Small}
                        />
                      </Row>
                    </Table.TableData>

                    {/* Updated */}
                    <Table.TableData>
                      <Text
                        content={moment(issue.updatedAt).fromNow()}
                        overflow={Text.enums.OverflowValues.Ellipsis}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Small}
                      />
                    </Table.TableData>
                  </Table.TableRow>
                );
              })}
              {this.props.issues.length === 0 ? (
                <Table.TableEmptyState
                  label="Once any of your units report any issues, you'll see them listed here!"
                />
              ) : null}
              {this.props.issuesPageInfo ? (
                <Table.TablePaginator
                  startCursor={this.props.issuesPageInfo.startCursor || ''}
                  endCursor={this.props.issuesPageInfo.endCursor || ''}
                  resultsPerPage={NUMBER_OF_ISSUES_TO_FETCH}
                  totalResults={this.props.issuesTotal}
                />
              ) : null}
            </Table.TableBody>
          </Table>
        </Card>
        <CreateIssueModal
          isOpen={this.state.isCreateIssueModalOpen}
          onClose={this.handleCloseCreateIssueModal}
          onIssueCreated={this.handleIssueCreated}
        />
      </ManageStage>
    );
  }

  getUnitName = (unit: AddressUnit) => {
    if (!unit.name) return '-';
    return formatUnitName(unit);
  }
}

interface PageInfo {
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
}

interface QueryProps {
  isLoading: boolean;
  isMaintenanceOnboarded: boolean;
  issues: Issue[];
  issuesPageInfo?: PageInfo;
  issuesTotal: number;
  refetch: () => Promise<any>;
}

interface Response {
  viewer: {
    balance: {
      defaultTopupSourceId: string | null;
      uuid: string;
    } | null;
    issues: Issue[];
    issuesPageInfo: PageInfo;
    issuesTotal: number;
    maintenanceSetting: {
      uuid: string;
    } | null;
    servicePreferences: {
      uuid: string;
    }[];
    uuid: string;
  } | null;
}

interface Variables {
  after?: string;
  first: number;
  issuesFilter?: IssuesFilterInput;
}

export default compose(
  withQuery<RouteComponentProps, Response, Variables, QueryProps>(query, {
    options: props => {
      const qs = queryString.parse(props.location.search);

      const page = _.parseInt(typeof qs.page === 'string' ? qs.page : '1') ?? undefined;
      const after = page ? pageToCursor(page, NUMBER_OF_ISSUES_TO_FETCH) : undefined;

      let propertyManagerContractUuid;
      if (_.isArray(qs.propertyManagerContractUuid)) {
        propertyManagerContractUuid = qs.propertyManagerContractUuid[0];
      } else if (qs.propertyManagerContractUuid) {
        propertyManagerContractUuid = qs.propertyManagerContractUuid;
      }

      let status;
      if (_.isArray(qs.status)) {
        status = _.toUpper(_.snakeCase(qs.status[0]));
      } else if (qs.status) {
        status = _.toUpper(_.snakeCase(qs.status));
      }

      return {
        variables: {
          after,
          first: NUMBER_OF_ISSUES_TO_FETCH,
          filter: {
            status: status ? {
              eq: status,
            } : undefined,
            propertyManagerContractUuid,
          },
        },
      };
    },
    props: props => ({
      isLoading: props.loading,
      isMaintenanceOnboarded:
        !!props.data?.viewer?.maintenanceSetting &&
        props.data.viewer.servicePreferences.length > 0 &&
        !!props.data.viewer.balance?.defaultTopupSourceId,
      issues: props.data?.viewer?.issues || [],
      issuesPageInfo: props.data?.viewer?.issuesPageInfo,
      issuesTotal: props.data?.viewer?.issuesTotal || 0,
      refetch: props.refetch,
    }),
  }),
)(Issues);
