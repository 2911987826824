import React, { Component } from 'react';
import isEqual from 'react-fast-compare';
import { Link } from 'react-router-dom';

import { getSizeUrl } from '~web-core/lib/common/utils/photo';

import Avatar from '~tools/react/components/Avatar';
import withDevice, { DeviceProps } from '~tools/react/hocs/withDevice';
import withStyles from '~tools/react/hocs/withStyles';

import { ArrowLeft } from '~web-core/lib/common/svgs/icons/directional';

import styles from './InteractiveMapMarkerListingCard.scss';

interface Props {
  imageUrl: string;
  isMobile?: boolean;
  slug: string;
  title: string;
  user: {
    imageUrl: string;
  };
}

class InteractiveMapMarkerListingCard extends Component<Props & DeviceProps> {
  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    return (
      <div styleName="listing-card">
        <div
          style={{
            backgroundImage: `url("${getSizeUrl(this.props.imageUrl, { width: 260, height: 140 }) || 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}")`,
          }}
          styleName="listing-card__image">
          <p>{this.props.title}</p>
          <Avatar
            imageUrl={this.props.user.imageUrl}
            size={Avatar.enums.Sizes.Small}
          />
        </div>
        <Link
          rel={this.props.isMobile ? '' : 'noopener noreferrer'}
          target={this.props.isMobile ? '' : '_blank'}
          to={`/listing/${this.props.slug}`}
          styleName="listing-card__button">
          View Listing <ArrowLeft />
        </Link>
      </div>
    );
  }
}

export default withStyles(styles)(withDevice(InteractiveMapMarkerListingCard));
