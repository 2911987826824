import { UpdateLeaseInputType } from '~tools/types/graphqlSchema';

import withMutation from '~tools/react/graphql/withMutation';

import UpdateLeaseMutation from './updateLease.gql';

interface Lease {
  createdAt: string;
  endDate: string;
  rentInCents: number;
  securityDepositInCents: number;
  startDate: string;
  uuid: string;
  document: {
    uuid: string;
  } | null;
}

interface Data {
  updateLease: Lease;
}

interface Variables {
  input: UpdateLeaseInputType;
  uuid: string;
}

export interface UpdateLeaseProps {
  updateLease: (uuid: string, input: UpdateLeaseInputType) => Promise<Lease>;
}

export default withMutation<{}, Data, Variables, UpdateLeaseProps>(UpdateLeaseMutation, {
  props: props => ({
    updateLease: async (uuid, input) => {
      const res = await props.mutate({
        variables: {
          input,
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error updating lease.');

      return res.data.updateLease;
    },
  }),
});
