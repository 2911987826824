// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._RnEk6W{display:-webkit-box;display:flex;flex-wrap:wrap}._yprhPO{-webkit-box-flex:1;flex-grow:1;height:80px;-webkit-transition:all .2s;transition:all .2s}._yprhPO._x28Xkv{-webkit-box-flex:0;flex-grow:0;width:80px}", ""]);
// Exports
exports.locals = {
	"takeover-access-form__photos": "_RnEk6W",
	"takeover-access-form__dropzone": "_yprhPO",
	"takeover-access-form__dropzone--small": "_x28Xkv"
};
module.exports = exports;
