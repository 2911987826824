import { CopyPhotoToListingInputType } from '~tools/types/graphqlSchema';
import withMutation from '~tools/react/graphql/withMutation';

import CopyPhotoToListingMutation from './copyPhotoToListing.gql';

interface Listing {
  photos: {
    position: number;
    url: string;
    uuid: string;
  }[];
  updatedAt: string;
  uuid: string;
}

interface Tag {
  name: string;
  uuid: string;
}

interface Photo {
  height: number;
  listing?: Listing;
  position: number;
  tags?: Tag[];
  url: string;
  uuid: string;
  width: number;
}

interface Data {
  copyPhotoToListing: Photo;
}

interface Variables {
  uuid: string;
  input: CopyPhotoToListingInputType;
}

export interface CopyPhotoToListingProps {
  copyPhotoToListing: (uuid: string, input: CopyPhotoToListingInputType) => Promise<Photo>;
}

export default withMutation<{}, Data, Variables, CopyPhotoToListingProps>(CopyPhotoToListingMutation, {
  props: props => ({
    copyPhotoToListing: async (uuid: string, input: CopyPhotoToListingInputType) => {
      const res = await props.mutate({
        variables: {
          uuid,
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error copying photo to listing.');

      return res.data.copyPhotoToListing;
    },
  }),
});
