import React, { ComponentType, PureComponent } from 'react';

import Text from '~tools/react/components/Text';
import Form, { TextArea } from '~tools/react/components/Form';
import ThemedModal from '~tools/react/components/ThemedModal';
import withQuery from '~tools/react/graphql/withQuery';
import { compose } from '~tools/react/hocs/utils';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import withUpdateServicePreference, { UpdateServicePreferenceProps } from '~tools/react/graphql/mutations/servicePreferences/withUpdateServicePreference';
import Button from '~tools/react/components/Button';

import query from './UpdateServicePreferenceNotesModal.gql';

interface InputProps {
  isOpen: boolean;
  servicePreferenceUuid: string;
  onClose: () => void;
  onSubmit?: () => void;
}

type Props = InputProps
  & QueryProps
  & UpdateServicePreferenceProps;

interface State {
  isLoading: boolean;
}

interface FormData {
  notes: string;
}

class UpdateServicePreferenceNotesModal extends PureComponent<Props, State> {
  state: State = {
    isLoading: false,
  };

  handleSubmit = async (formData: FormData) => {
    const servicePreference = this.props.servicePreference;
    if (!servicePreference) return;

    if (formData.notes !== servicePreference.notes) {
      try {
        this.setState({ isLoading: true });
        await this.props.updateServicePreference(servicePreference.uuid, {
          notes: formData.notes,
        });
        this.setState({ isLoading: false });
      } catch (err) {
        this.setState({ isLoading: false });
        throw err;
      }
    }

    if (this.props.onSubmit) this.props.onSubmit();
    this.props.onClose();
  };

  render() {
    return (
      <ThemedModal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title="Edit notes"
        width={ThemedModal.enums.Widths.Small}>
        <ThemedModal.ThemedModalSection>
          <Text
            content="Enter anything you know about this provider that would be useful for you or Caretaker to know in the future."
          />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
          <Form onSubmit={this.handleSubmit}>
            <TextArea
              isRequired={false}
              label="Notes"
              labelFormat={TextArea.enums.LabelFormats.Stacked}
              name="notes"
              placeholder="Notes..."
              size={TextArea.enums.Sizes.Large}
              value={this.props.servicePreference?.notes}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
            <Button
              isLoading={this.props.isLoading || this.state.isLoading}
              label="Submit"
              type={Button.enums.Types.Submit}
              width={Button.enums.Widths.Full}
            />
          </Form>
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

interface ServicePreference {
  uuid: string;
  notes: string;
}

interface Response {
  viewer: {
    servicePreference: ServicePreference | null;
    uuid: string;
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  servicePreference: ServicePreference | null;
}

export default compose(
  withQuery<InputProps, Response, {}, QueryProps>(query, {
    options: props => ({
      variables: {
        servicePreferenceUuid: props.servicePreferenceUuid,
      },
      skip: !props.servicePreferenceUuid,
    }),
    props: props => ({
      isLoading: props.loading,
      servicePreference: props.data?.viewer?.servicePreference ?? null,
    }),
  }),
  withUpdateServicePreference,
)(UpdateServicePreferenceNotesModal) as ComponentType<InputProps>;
