import classNames from 'classnames';
import _ from 'lodash';
import React, { Children, Component, ComponentType, ReactElement } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import ChevronRight from '~tools/svgs/icons/interface/chevron-right.svg';

import TableData from '~tools/react/components/Table/components/TableData';

import styles from './TableRow.scss';

interface Props {
  children: ReactElement | ReactElement[];
  id?: string;
  link?: {
    path: string;
  };
  onClick?: (id?: string) => void;
}

class TableRow extends Component<Props> {
  handleClick = () => {
    if (this.props.onClick) this.props.onClick(this.props.id);
  }

  render() {
    return (
      <tr
        className={classNames({
          [styles['table-row']]: true,
          [styles[`table-row--linked`]]: !!(this.props.link || this.props.onClick),
        })}>
        {_.map(Children.toArray<ReactElement>(this.props.children), (child) => {
          if (child.type === TableData) {
            return React.cloneElement(child, {
              ...child.props,
              link: this.props.link,
              onClick: this.props.onClick ? this.handleClick : undefined,
            });
          }

          return child;
        })}
        {this.props.link || this.props.onClick ? (
          <TableData
            alignment={TableData.enums.Alignments.RIGHT}
            link={this.props.link}
            onClick={this.props.onClick ? this.handleClick : undefined}>
            <ChevronRight styleName="chevron-right" />
          </TableData>
        ) : null}
      </tr>
    );
  }
}

export default withStyles(styles)(TableRow) as ComponentType<Props>;
