import React, { Fragment, PureComponent } from 'react';
import withStyles from '~tools/react/hocs/withStyles';

import ConfirmationModal from '~tools/react/components/ConfirmationModal';
import { Check, Cross } from '~web-core/lib/common/svgs/icons/interface';

import styles from './InputAttachment.scss';

function formattedFileSize(size: number) {
  const units = ['B', 'kB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(size) / Math.log(1024));

  // eslint-disable-next-line no-restricted-properties
  const test = size / Math.pow(1024, i);
  return `${test.toFixed(2)} ${units[i]}`;
}

interface Props {
  fileName: string;
  fileSize: number;
  uploadProgress: number;
  uuid: string | null;
  onDelete: (uuid: string) => any;
}

interface State {
  isConfirmationOpen: boolean;
}

class InputAttachment extends PureComponent<Props, State> {
  state = {
    isConfirmationOpen: false,
  };

  handleDelete = () => {
    const { uuid } = this.props;
    if (!uuid) return;
    this.setState({ isConfirmationOpen: false }, () => this.props.onDelete(uuid));
  }

  render() {
    return (
      <Fragment>
        <ConfirmationModal
          cancelAction={{
            label: 'Cancel',
            onClick: this.toggleConfirmation,
          }}
          confirmAction={{
            label: 'Remove',
            onClick: this.handleDelete,
          }}
          description="Are you sure you want to remove this attachment?"
          isOpen={this.state.isConfirmationOpen}
          onClose={this.toggleConfirmation}
          title="Remove attachment"
        />
        <div styleName="attachment">
          {this.props.uploadProgress === 1 ? (
            <Check styleName="attachment__check" />
          ) : null}
          <div styleName="attachment__filename">{this.props.fileName}</div>
          <div styleName="attachment__filesize">{`${formattedFileSize(this.props.fileSize)}`}</div>
          {this.props.uploadProgress === 1 ? (
            <button styleName="attachment__close" onClick={this.handleDelete}>
              <Cross styleName="attachment__cross" />
            </button>
          ) : (
            <span styleName="attachment__progress">{`${(this.props.uploadProgress * 100).toFixed()}%`}</span>
          )}
        </div>
      </Fragment>
    );
  }

  toggleConfirmation = () => this.setState({ isConfirmationOpen: !this.state.isConfirmationOpen });
}

export default withStyles(styles)(InputAttachment);
