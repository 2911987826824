import _ from 'lodash';
import isEqual from 'react-fast-compare';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { Component } from 'react';

import Globals from '~web-core/lib/common/globals';

import withStyles from '~tools/react/hocs/withStyles';

import { formatAsUSD } from '~web-core/lib/common/utils/string';
import { getSizeUrl } from '~web-core/lib/common/utils/photo';

import Avatar from '~tools/react/components/Avatar';
import ImageCarousel from '~tools/react/components/ImageCarousel';
import Link from '~tools/react/components/utility/Link';

import DoorSvg from './svgs/door-bw.svg';
import PlaceSvg from './svgs/place-bw.svg';
import BathSvg from './svgs/bath-bw.svg';
import BedSvg from './svgs/bed-bw.svg';

import styles from './MapListingCard.scss';

interface Props {
  listing: {
    description: string;
    earliestMoveInDate: string;
    images: {
      id: string;
      position: number;
      url: string;
    }[];
    locationString: string;
    numBathrooms: number;
    numBedrooms: number;
    rentInDollars: number;
    slug?: string;
    title: string;
    type: string;
    user: {
      fullName: string;
      imageUrl: string;
    };
  };
}

class MapListingCard extends Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }

  handleClick = e => e.stopPropagation();

  render() {
    const images = _.map(_.take(_.sortBy(this.props.listing.images, 'position'), 5), (image) => {
      const newImage = _.clone(image);
      newImage.url = getSizeUrl(image.url, { width: 375, height: 300 });
      return newImage;
    });

    return (
      <div // eslint-disable-line jsx-a11y/click-events-have-key-events
        onClick={this.handleClick}
        styleName="sidebar">
        <div styleName="sidebar__scroller">
          <div styleName="sidebar__carousel">
            <ImageCarousel images={images} />
          </div>
          <div styleName="sidebar__headline">
            <h5>{_.truncate(this.props.listing.title, { length: 50 })}</h5>
            <p>{this.props.listing.locationString}</p>
          </div>
          <div styleName="amenities">
            <div styleName="amenities__item">
              <BedSvg />
              {this.props.listing.numBedrooms ? `${this.props.listing.numBedrooms} ${pluralize('bedroom', this.props.listing.numBedrooms)}` : 'Studio'}
            </div>
            <div styleName="amenities__item">
              <BathSvg />
              {this.props.listing.numBathrooms} {pluralize('bathroom', this.props.listing.numBathrooms)}
            </div>
            <div styleName="amenities__item">
              {this.props.listing.type === 'Entire Place' ? <PlaceSvg /> : <DoorSvg />}
              {this.props.listing.type}
            </div>
          </div>
          <div styleName="sidebar__listing">
            <h5>Lister’s Overview</h5>
            <p>{_.truncate(this.props.listing.description, { length: 160 })}</p>
            {this.props.listing.description.length > 160 && this.props.listing.slug ? (
              <Link
                shouldOpenNewTab={true}
                to={`${Globals.WEB_CORE_DOMAIN}/listing/${this.props.listing.slug}`}>
                Read More
              </Link>
            ) : null}
          </div>
          <div styleName="sidebar__lister">
            <span>
              <h5>{this.props.listing.type}</h5>
              <p>Listed by {this.props.listing.user.fullName}</p>
            </span>
            <Avatar
              imageUrl={this.props.listing.user.imageUrl}
              size={Avatar.enums.Sizes.Large}
            />
          </div>
        </div>
        {this.props.listing.slug ? (
          <div styleName="sidebar__cta">
            <span>
              <p>
                <span>${formatAsUSD(this.props.listing.rentInDollars)}</span> per month
              </p>
              <p>Move in {moment(this.props.listing.earliestMoveInDate).format('MMM D')} </p>
            </span>
            <Link
              shouldOpenNewTab={true}
              to={`${Globals.WEB_CORE_DOMAIN}/listing/${this.props.listing.slug}`}>
              View full listing
            </Link>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(MapListingCard);
