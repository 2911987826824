import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import { ArmChair, EyeHidden, HouseHandMoney, Sofa } from '~web-core/lib/common/svgs/icons/physical-objects';
import ChevronRight from '~tools/svgs/icons/interface/chevron-right.svg';

import Text from '~tools/react/components/Text';
import Link from '~tools/react/components/utility/Link';

import * as enums from './enums';

import styles from './ListingSetupInfoBlock.scss';

const iconToSvg = {
  [enums.Icons.Hidden]: EyeHidden,
  [enums.Icons.Deposit]: HouseHandMoney,
  [enums.Icons.Sofa]: Sofa,
  [enums.Icons.ArmChair]: ArmChair,
};

interface Props {
  description: string;
  icon?: enums.Icons;
  primaryLink?: {
    label: string;
    path: string;
  };
}

function ListingSetupInfoBlock(props: Props) {
  const Icon = props.icon ? iconToSvg[props.icon] : null;

  return (
    <div styleName="manage-listing-info">
      {Icon ? <Icon /> : null}
      <Text content={props.description} />
      {props.primaryLink ? (
        <Link
          shouldOpenNewTab
          to={props.primaryLink.path}>
          {props.primaryLink.label}<ChevronRight />
        </Link>
      ) : null}
    </div>
  );
}

ListingSetupInfoBlock.enums = enums;

export default withStyles(styles)(ListingSetupInfoBlock);
