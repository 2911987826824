import React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

function KeyboxRedirect(props: RouteComponentProps<{ orderUuid: string}>) {
  return (
    <Redirect to={`/smart-locks/${props.match.params.orderUuid}`} />
  );
}

export default withRouter(KeyboxRedirect);
