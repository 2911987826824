// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._WVjEVR{display:-webkit-box;display:flex}._WVjEVR ._5aAebO{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;margin:auto}", ""]);
// Exports
exports.locals = {
	"tiers-view": "_WVjEVR",
	"tiers-view__wrap": "_5aAebO"
};
module.exports = exports;
