// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-webkit-keyframes _VYQfnz{0%{opacity:.5}to{opacity:1}}@keyframes _VYQfnz{0%{opacity:.5}to{opacity:1}}._P9WKYN{-webkit-animation:_VYQfnz .6s linear infinite alternate;animation:_VYQfnz .6s linear infinite alternate;-webkit-backface-visibility:hidden;backface-visibility:hidden;background:#e5e9f2;border-radius:50%;display:grid;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;grid-gap:12px;height:12px;height:32px;width:32px;will-change:opacity}._P9WKYN._BX0DJZ{height:28px;width:28px}._P9WKYN._0N5q6Y{height:32px;width:32px}._P9WKYN._99v9Go{height:38px;width:38px}", ""]);
// Exports
exports.locals = {
	"skeleton-circle": "_P9WKYN",
	"shimmer": "_VYQfnz",
	"skeleton-circle--size-small": "_BX0DJZ",
	"skeleton-circle--size-medium": "_0N5q6Y",
	"skeleton-circle--size-large": "_99v9Go"
};
module.exports = exports;
