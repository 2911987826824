import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Check from '~tools/svgs/icons/interface/check.svg';
import Cross from '~tools/svgs/icons/interface/cross.svg';
import Lock from '~tools/svgs/icons/interface/lock.svg';
import Pencil from '~tools/svgs/icons/interface/edit.svg';

import Spinner from '~tools/react/components/Spinner';

import * as enums from './enums';

import styles from './IconButton.scss';

const enumToIcon = {
  [enums.Icons.Check]: Check,
  [enums.Icons.Cross]: Cross,
  [enums.Icons.Lock]: Lock,
  [enums.Icons.Pencil]: Pencil,
};

interface Props {
  color: enums.Colors;
  icon: enums.Icons;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  type?: enums.Types;
}

function IconButton(props: Props) {
  const Icon = enumToIcon[props.icon];
  return (
    <button
      disabled={props.isDisabled || props.isLoading}
      className={classNames({
        [styles['icon-button']]: true,
        [styles['icon-button--loading']]: props.isLoading,
        [styles[`icon-button--color-${_.kebabCase(props.color)}`]]: props.color,
        [styles[`icon-button--icon-${_.kebabCase(props.icon)}`]]: props.icon,
      })}
      onClick={props.onClick}
      type={_.toLower(props.type || enums.Types.Button)}>
      {props.isLoading ? (
        <Spinner />
      ) : (
        <Icon />
      )}
    </button>
  );
}

export default withStyles(styles)(IconButton);
