import _ from 'lodash';
import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './TiersView.scss';

interface Props {
  children: ReactNode;
}

function TiersView(props: Props) {
  return (
    <div styleName="tiers-view">
      <div styleName="tiers-view__wrap">
        {props.children}
      </div>
    </div>
  );
}

export default withStyles(styles)(TiersView);
