import RemovePayoutAccountMutation from '~tools/react/graphql/mutations/payoutAccounts/removePayoutAccount.gql';
import withMutation from '~tools/react/graphql/withMutation';

interface Data {
  removePayoutAccount: boolean;
}

interface Variables {
  accountId: string;
}

export interface RemovePayoutAccountProps {
  removePayoutAccount: (accountId: string) => Promise<boolean>;
}
export default withMutation<{}, Data, Variables, RemovePayoutAccountProps>(RemovePayoutAccountMutation, {
  props: props => ({
    removePayoutAccount: async (accountId: string) => {
      const res = await props.mutate({
        variables: {
          accountId,
        },
      });
      if (!res || !res.data) throw new Error('Error removing payout account.');

      return res.data.removePayoutAccount;
    },
  }),
});
