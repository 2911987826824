import React, { Component } from 'react';
import _ from 'lodash';

import withStyles from '~tools/react/hocs/withStyles';
import { Cross } from '~web-core/lib/common/svgs/icons/interface';

import styles from './PhotoEditorModalOptionsBar.scss';
import Trash from './svgs/trash.svg';

const MAX_HISTORY = 10;

interface Props {
  onClickDelete: () => void;
  onClose: () => void;
  query: string;
  updateQuery: (query: string, cb: () => void) => void;
}

type State = {
  queryHistory: string[];
}

class PhotoEditorModalOptionsBar extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      queryHistory: ['', ''],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.query !== this.props.query) {
      const queryHistory = this.pushQueryHistory(this.props.query);
      this.setState({ queryHistory });
    }
  }

  handleUndo = () => {
    if (this.state.queryHistory.length > 2) {
      const queryHistory = _.tail(this.state.queryHistory);
      this.props.updateQuery(_.first(this.state.queryHistory) || '', () => this.setState({ queryHistory }));
    }
  };

  render() {
    return (
      <div styleName="photo-options-bar">
        <button styleName={this.state.queryHistory.length > 2 ? 'active' : ''} onClick={this.handleUndo}>
          Undo
        </button>
        <button onClick={this.props.onClickDelete} styleName="active">
          <Trash />
          <span>Delete photo</span>
        </button>
        <button onClick={this.props.onClose} styleName="active">
          <Cross />
          <span>Close editor</span>
        </button>
      </div>
    );
  }

  pushQueryHistory = (nextQuery) => {
    let { queryHistory } = this.state;
    if (queryHistory.length < MAX_HISTORY) {
      queryHistory.unshift(nextQuery);
    } else {
      queryHistory = _.initial(queryHistory);
      queryHistory.unshift(nextQuery);
    }
    return queryHistory;
  };
}

export default withStyles(styles)(PhotoEditorModalOptionsBar);
