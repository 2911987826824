import loadable, { LoadableComponent } from '@loadable/component';
import _ from 'lodash';

import { InputProps } from './types';
import * as enums from './enums';

const LoadableAlert = loadable(() => import(/* webpackChunkName: "alert" */'./Alert')) as LoadableComponent<InputProps>;
const Alert = _.assign(LoadableAlert, { enums });

export default Alert;
