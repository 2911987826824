import React from 'react';

import Stage from '~web-core/lib/common/stages/Stage';

import NotFoundView from './components/NotFoundView';

function NotFound() {
  return (
    <Stage hasFooter={true}>
      <NotFoundView />
    </Stage>
  );
}

export default NotFound;
