import withMutation from '~tools/react/graphql/withMutation';

import ResendLeaseInviteMutation from './resendLeaseInvite.gql';

interface Data {
  resendLeaseInvite: boolean;
}

interface Variables {
  uuid: string;
}

export interface ResendLeaseInviteProps {
  resendLeaseInvite: (uuid: string) => Promise<boolean>;
}

export default withMutation<{}, Data, Variables, ResendLeaseInviteProps>(ResendLeaseInviteMutation, {
  props: props => ({
    resendLeaseInvite: async (uuid) => {
      const res = await props.mutate({
        variables: { uuid },
      });
      if (!res || !res.data) throw new Error('Error resending lease invite.');

      return res.data.resendLeaseInvite;
    },
  }),
});
