// @flow

import React from 'react';

import Heading from '~tools/react/components/Heading';
import withStyles from '~tools/react/hocs/withStyles';
import { Check } from '~web-core/lib/common/svgs/icons/interface';

import styles from './ManageListingNextStepsHeader.scss';

function ManageListingNextStepsHeader() {
  return (
    <div styleName="next-steps-view-header">
      <Check />
      <Heading content="Your rental has been listed!" />
      <Heading
        size={Heading.enums.Sizes.Small}
        font={Heading.enums.Fonts.Secondary}
        content="Here’s a few things to get you started"
      />
    </div>
  );
}

export default withStyles(styles)(ManageListingNextStepsHeader);
