import _ from 'lodash';
import React, { PureComponent } from 'react';

import SkeletonBodyText from '~tools/react/components/SkeletonBodyText';
import SkeletonCircle from '~tools/react/components/SkeletonCircle';
import withStyles from '~tools/react/hocs/withStyles';

import Spinner from './components/Spinner';
import styles from './ThreadItemsView.scss';

interface State {
  isLoading: boolean;
}

interface Props {
  children: React.ReactNode;
  hasNextPage: boolean;
  isLoading: boolean;
  loadMoreMessages: () => Promise<void>;
  onScrolledStateUpdated: ({ isScrolled: boolean }) => void;
}

class ThreadItemsView extends PureComponent<Props, State> {
  state = {
    isLoading: false,
  };

  prevScroll = 0;

  ref: HTMLElement | null = null;

  componentDidMount() {
    if (this.ref) {
      this.ref.addEventListener('scroll', this.handleScroll);
    }
  }

  componentDidUpdate(prevProps) {
    if ((!this.props.isLoading && prevProps.isLoading) ||
      React.Children.count(this.props.children) !== React.Children.count(prevProps.children)) {
      if (this.ref) {
        this.ref.scrollTop = 0;
      }
    }
  }

  componentWillUnmount() {
    if (this.ref) {
      this.ref.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = async () => {
    if (this.ref) {
      if (this.prevScroll === 0 && this.ref.scrollTop > 0) {
        this.prevScroll = this.ref.scrollTop;
        this.props.onScrolledStateUpdated({ isScrolled: true });
      } else if (this.prevScroll > 0 && this.ref.scrollTop === 0) {
        this.prevScroll = this.ref.scrollTop;
        this.props.onScrolledStateUpdated({ isScrolled: false });
      }

      const isScrolledToBottom = this.ref.scrollHeight - this.ref.scrollTop === this.ref.clientHeight;
      if (isScrolledToBottom) {
        if (this.props.hasNextPage && !this.state.isLoading) {
          const height = this.ref.scrollHeight;
          this.setState({ isLoading: true });
          await this.props.loadMoreMessages();
          this.setState({ isLoading: false });
          if (this.ref) {
            this.ref.scrollTop = this.ref.scrollHeight - height;
          }
        }
      }
    }
  }

  render() {
    return (
      <div ref={this.assignRef} styleName="thread-items-view">
        {this.state.isLoading && <Spinner />}
        {this.props.isLoading ?
          _.times(5, i => (
            <div styleName="thread-items-view__loader" key={i}>
              <SkeletonCircle />
              <SkeletonBodyText lines={i % 2 ? 1 : 2} />
            </div>
          )) : this.props.children}
      </div>
    );
  }

  assignRef = (c) => {
    if (c) {
      this.ref = c;
    }
  }
}

export default withStyles(styles)(ThreadItemsView);
