import _ from 'lodash';

export const extractMessageFromError = (error: any) => {
  let errorMessage = '';
  if (typeof error === 'string') {
    return error;
  }

  if (!errorMessage && error.graphQLErrors) {
    errorMessage = error.graphQLErrors.map(x => x.message)[0]
      ? error.graphQLErrors.map(x => x.message)[0]
      : '';
  }

  if (!errorMessage && error.networkError && error.networkError.result && error.networkError.result.errors) {
    errorMessage = error.networkError.result.errors.map(x => x.message)[0]
      ? error.networkError.result.errors.map(x => x.message)[0]
      : '';
  }

  if (!errorMessage && error.message) {
    errorMessage = error.message.replace('GraphQL error: ', '');
  }

  if (_.isObject(errorMessage)) {
    const messages = _.map(errorMessage, (value) => {
      if (_.isArray(value)) return value[0];
      return value;
    });
    errorMessage = messages.join(', ');
  }

  return errorMessage;
};
