import withMutation from '~tools/react/graphql/withMutation';

import RequestApplication from './requestApplication.gql';

export interface Application {
  uuid: string;
  applicationRequestedAt: string;
}

interface Data {
  requestApplication: Application;
}

interface Variables {
  uuid: string;
}

export interface RequestApplicationProps {
  requestApplication: (uuid: string) => Promise<Application>;
}

export default withMutation<{}, Data, Variables, RequestApplicationProps>(RequestApplication, {
  props: props => ({
    requestApplication: async (uuid: string) => {
      const res = await props.mutate({
        variables: {
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error requesting application.');

      return res.data.requestApplication;
    },
  }),
});
