import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { compose } from '~tools/react/graphql';
import Button from '~tools/react/components/Button';
import Center from '~tools/react/components/Center';
import Heading from '~tools/react/components/Heading';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import withStyles from '~tools/react/hocs/withStyles';

import styles from './AnimatedStepsFlowStepLocked.scss';

interface InputProps {
  lockedStepAction?: {
    label: string;
    path: string;
  };
}

type Props = InputProps & RouteComponentProps;

class AnimatedStepsFlowStepLocked extends Component<Props> {
  onClickLockedStepAction = () => {
    if (this.props.lockedStepAction) {
      this.props.history.push(this.props.lockedStepAction.path);
    }
  }

  render() {
    return (
      <div>
        <Heading
          align={Heading.enums.Align.Center}
          content="Slow down!"
          size={Heading.enums.Sizes.XLarge}
        />
        <Text
          align={Text.enums.Align.Center}
          content="This step is still locked."
          size={Text.enums.Sizes.XLarge}
        />
        {this.props.lockedStepAction ? (
          <Fragment>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
            <Center>
              <Button
                label={this.props.lockedStepAction.label}
                onClick={this.onClickLockedStepAction}
              />
            </Center>
          </Fragment>
        ) : null}
      </div>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles),
)(AnimatedStepsFlowStepLocked);
