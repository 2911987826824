import withMutation from '~tools/react/graphql/withMutation';
import { CreateRentalPolicyInputType } from '~tools/types/graphqlSchema';

import CreateRentalPolicyMutation from './createRentalPolicy.gql';

interface RentalPolicy {
  uuid: string;
}

interface Data {
  createRentalPolicy: RentalPolicy;
}

interface Variables {
  input: CreateRentalPolicyInputType;
}

export interface CreateRentalPolicyProps {
  createRentalPolicy: (input: CreateRentalPolicyInputType) => Promise<RentalPolicy>;
}

export default withMutation<{}, Data, Variables, CreateRentalPolicyProps>(CreateRentalPolicyMutation, {
  props: props => ({
    createRentalPolicy: async (input: CreateRentalPolicyInputType) => {
      const res = await props.mutate({
        variables: { input },
      });
      if (!res || !res.data) throw new Error('Error creating rental policy.');

      return res.data.createRentalPolicy;
    },
  }),
});
