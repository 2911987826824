// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2dAe3w{display:block;display:-webkit-box;display:flex;flex-wrap:wrap}._APRJQn{-webkit-box-flex:1;flex-grow:1;height:80px;-webkit-transition:all .2s;transition:all .2s}._APRJQn._rrE0Jy{-webkit-box-flex:0;flex-grow:0;width:80px}", ""]);
// Exports
exports.locals = {
	"access-photos-modal__photos": "_2dAe3w",
	"access-photos-modal__dropzone": "_APRJQn",
	"access-photos-modal__dropzone--small": "_rrE0Jy"
};
module.exports = exports;
