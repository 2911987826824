import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';

import StatusCardSection from '~web-manage/lib/common/components/StatusCardSection';

import query from './LeasesStatusSection.gql';

interface Lease {
  nextRentPaymentMonthStarting: string | null;
  uuid: string
}

interface QueryProps {
  isLoading: boolean;
  leases: Lease[];
}

interface InputProps {
  isLoading: boolean;
}

type Props =
  InputProps &
  QueryProps;

class LeasesStatusSection extends PureComponent<Props> {
  render() {
    if (this.props.isLoading) {
      return (
        <StatusCardSection
          isLoading={this.props.isLoading}
          description="Leases detail loading..."
          status={StatusCardSection.enums.Statuses.Unknown}
          title="Leases"
        />
      );
    }

    const leases = this.props.leases;
    if (leases.length === 0) {
      return (
        <StatusCardSection
          description="You'll see lease activity here once you've signed with your first tenant."
          status={StatusCardSection.enums.Statuses.Unknown}
          title="Leases"
        />
      );
    }

    const paidLeases = _.filter(this.props.leases, (lease) => {
      if (!lease.nextRentPaymentMonthStarting || moment(lease.nextRentPaymentMonthStarting).isAfter()) return true;

      return false;
    });
    const paidPercentage = (paidLeases.length / leases.length) * 100;

    let status = StatusCardSection.enums.Statuses.Ok;
    if (paidPercentage < 90) status = StatusCardSection.enums.Statuses.Warning;
    if (paidPercentage < 70) status = StatusCardSection.enums.Statuses.Degraded;
    return (
      <StatusCardSection
        isLoading={this.props.isLoading}
        description="active leases are up to date on rent payments."
        highlight={`*${paidLeases.length}* of *${leases.length}*`}
        status={status}
        link={status !== StatusCardSection.enums.Statuses.Ok ? {
          label: 'View overdue leases',
          path: '/',
        } : undefined}
        title="Leases"
      />
    );
  }
}

interface Response {
  viewer: {
    leases: Lease[];
    uuid: string;
  } | null;
}

export default compose(
  withQuery<InputProps, Response, {}, QueryProps>(query, {
    props: props => ({
      isLoading: props.loading,
      leases: props.data?.viewer?.leases ?? [],
    }),
  }),
)(LeasesStatusSection);
