import withMutation from '~tools/react/graphql/withMutation';
import { SubleaseContextInput } from '~tools/types/graphqlSchema';

import GenerateSubletAgreement from './generateSubletAgreement.gql';

export interface Document {
  uuid: string;
}

interface Data {
  generateSubletAgreement: Document;
}

interface Variables {
  applicationUuid: string;
  input: SubleaseContextInput;
}

export interface GenerateSubletAgreementProps {
  generateSubletAgreement: (applicationUuid: string, input: SubleaseContextInput) => Promise<Document>;
}

export default withMutation<{}, Data, Variables, GenerateSubletAgreementProps>(GenerateSubletAgreement, {
  props: props => ({
    generateSubletAgreement: async (applicationUuid: string, input: SubleaseContextInput) => {
      const res = await props.mutate({
        variables: {
          applicationUuid,
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error generating sublease agreement.');

      return res.data.generateSubletAgreement;
    },
  }),
});
