import _ from 'lodash';
import React, { PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Image from '~tools/react/components/utility/Image';

import styles from './ImageList.scss';

interface Props {
  images: {
    url: string;
    uuid: string;
  }[];
}

class ImageList extends PureComponent<Props> {
  render() {
    return (
      <div styleName="image-list">
        {_.map(this.props.images, (image) => (
          <Image
            key={image.uuid}
            src={image.url}
            size={{
              w: 48,
              h: 48,
            }}
            styleName="image"
          />
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(ImageList);
