// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._XP8jka{-webkit-box-align:center;align-items:center;position:relative;width:100%}._XP8jka,._XP8jka ._J2VCyo{display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row}._XP8jka ._J2VCyo{-webkit-box-pack:justify;justify-content:space-between}._XP8jka ._rrEWGm{color:#273444;font-size:14px;font-weight:300}._XP8jka ._vqQ5NO{margin-left:10px}._XP8jka._mmJAWn{-webkit-box-align:start;align-items:flex-start;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column}._XP8jka._mmJAWn ._J2VCyo{margin:4px 0 8px;width:100%}._XP8jka._6OA8Na{display:grid;grid-gap:16px;grid-template-columns:120px 1fr;min-height:35px}._XP8jka._6OA8Na ._rrEWGm{width:100%}@media screen and (max-width:915px){._XP8jka._6OA8Na{-webkit-box-align:start;align-items:flex-start;display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column}._XP8jka._6OA8Na ._J2VCyo{margin:4px 0 8px;width:100%;text-align:left}}._XP8jka._qrobda{display:grid;grid-gap:16px;grid-template-columns:80px 1fr}._XP8jka._qrobda ._aeRAvR{text-align:right}._XP8jka._qrobda ._rrEWGm{width:100%}@media screen and (max-width:915px){._XP8jka._qrobda{-webkit-box-align:start;align-items:flex-start;display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column}._XP8jka._qrobda ._J2VCyo{margin:4px 0 8px;text-align:left}}._XP8jka._DBDoxo{width:25%}._XP8jka+._XP8jka{grid-template-columns:auto 1fr}", ""]);
// Exports
exports.locals = {
	"field": "_XP8jka",
	"field__labels": "_J2VCyo",
	"field__label-primary": "_rrEWGm",
	"field__label-secondary": "_vqQ5NO",
	"field--label-format-stacked": "_mmJAWn",
	"field--label-format-inline": "_6OA8Na",
	"field--label-format-short-inline": "_qrobda",
	"field__label": "_aeRAvR",
	"field--size-twenty-five": "_DBDoxo"
};
module.exports = exports;
