import _ from 'lodash';
import memoize from 'memoize-one';
import React, { Component, Fragment } from 'react';
import { RouteComponentProps } from 'react-router';

import analytics from '~web-core/lib/common/utils/analytics';
import * as events from '~web-core/lib/common/constants/analytics';

import Globals from '~web-manage/lib/common/globals';
import { compose } from '~tools/react/hocs/utils';

import withQuery from '~tools/react/graphql/withQuery';
import withUnlistListing, { UnlistListingProps } from '~tools/react/graphql/mutations/listings/withUnlistListing';
import withListListing, { ListListingProps, ListListingStatusEnum } from '~tools/react/graphql/mutations/listings/withListListing';
import withUpdateListing, { UpdateListingProps } from '~tools/react/graphql/mutations/listings/withUpdateListing';
import withUpdateAddressUnit, { UpdateAddressUnitProps } from '~tools/react/graphql/mutations/addressUnits/withUpdateAddressUnit';
import { AddressUnitUnitTypes } from '~tools/types/graphqlSchema';

import { extractMessageFromError } from '~tools/utils/error';

import ModalStage from '~web-manage/lib/common/stages/ModalStage';

import Alert from '~tools/react/components/Alert';
import AnimatedStepsFlow from '~tools/react/components/AnimatedStepsFlow';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import WidthConstraintView from '~tools/react/components/WidthConstraintView';
import TopBarLoader from '~tools/react/components/TopBarLoader';

import IncompleteFieldsModal from './components/IncompleteFieldsModal';

import ListingSetupAmenities from './scenes/ListingSetupAmenities';
import ListingSetupAvailability from './scenes/ListingSetupAvailability';
import ListingSetupDescription from './scenes/ListingSetupDescription';
import ListingSetupPayments from './scenes/ListingSetupPayments';
import ListingSetupPhotos from './scenes/ListingSetupPhotos';
import ListingSetupRooms from './scenes/ListingSetupRooms';

import query from './ListingSetup.gql';

import * as enums from './enums';
import { Listing } from './types';

const REQUIRED_STEPS = [
  enums.Steps.Availability,
  enums.Steps.Description,
  enums.Steps.Payments,
  enums.Steps.Photos,
  enums.Steps.Rooms,
];

type InputProps = RouteComponentProps<{ listingUuid: string }>;

export type Props =
  InputProps &
  QueryProps &
  ListListingProps &
  UnlistListingProps &
  UpdateListingProps &
  UpdateAddressUnitProps;

export interface State {
  error: string | null;
  isLoading: boolean;
  isIncompleteFieldsModalOpen: boolean;
}

class ListingSetup extends Component<Props, State> {
  state: State = {
    error: null,
    isIncompleteFieldsModalOpen: false,
    isLoading: false,
  };

  isListingCompleted = memoize(completedState => _.every(_.pick(completedState, REQUIRED_STEPS)));
  getMemoizedCompletedState = memoize(listing => ({
    [enums.Steps.Rooms]: !!(_.isNumber(listing.bedrooms) && _.isNumber(listing.bathrooms)),
    [enums.Steps.Amenities]: !!(listing.amenities && listing.amenities.length > 0),
    [enums.Steps.Payments]: !!(listing.rentInCents && listing.rentInCents > 0),
    [enums.Steps.Availability]: !!(listing.earliestMoveInDate && listing.minLeaseTerm),
    [enums.Steps.Description]: !!_.isString(listing.description),
    [enums.Steps.Photos]: !!(listing.photos && listing.photos.length > 0),
    [enums.Steps.Extras]: !!(listing.boosts && listing.boosts.length > 0),
  }));

  handleListListing = async () => {
    const completedState = this.getMemoizedCompletedState(this.props.listing);
    const isListingCompleted = this.isListingCompleted(completedState);
    if (isListingCompleted) {
      this.setState({ isLoading: true, error: null });
      try {
        await this.props.listListing(this.props.match.params.listingUuid, { status: ListListingStatusEnum.PUBLIC });
        analytics.track(events.POSTED_LISTING);
        this.props.history.push(`/listings/${this.props.match.params.listingUuid || ''}/next-steps`);
      } catch (err) {
        this.setState({ isLoading: false, error: extractMessageFromError(err) });
      }
    } else {
      this.openIncompleteFieldsModal();
    }
  }

  handleUpdateListing = async (data) => {
    const listing = this.props.listing;
    if (!listing) return;

    const listingStatus = listing.status;
    const listingUuid = this.props.match.params.listingUuid;
    const previousCompletedState = this.getMemoizedCompletedState(listing);

    this.setState({ isLoading: true });
    await this.props.updateListing(listingUuid, data);
    this.setState({ isLoading: false });

    const completedState = this.getMemoizedCompletedState(listing);
    _.map(completedState, (isCompleted, key) => {
      if (!previousCompletedState[key] && isCompleted) {
        analytics.track(events.COMPLETED_LISTING_STEP, {
          listingUuid,
          listingStatus,
          step: key,
          url: `${Globals.DOMAIN}/listings/${listingUuid}/${_.kebabCase(key)}`,
        });
      }
    });

    // Unlist listing if it's public and incomplete after update
    if (listingStatus === enums.Statuses.Listed && !this.isListingCompleted(completedState)) {
      await this.props.unlistListing(listingUuid);
    }
  }

  handleUpdateAddressUnit = async (addressUnit: { unitType: AddressUnitUnitTypes }) => {
    const addressUnitUuid = this.props.listing?.addressUnit?.uuid;
    if (!addressUnitUuid) return;

    this.setState({ isLoading: true });
    await this.props.updateAddressUnit(addressUnitUuid, addressUnit);
    this.setState({ isLoading: false });
  };

  render() {
    const listing = this.props.listing;
    if (!listing) return null;

    const listingUuid = this.props.match.params.listingUuid;
    const completedState = this.getMemoizedCompletedState(listing);

    return (
      <ModalStage
        closeRedirectPath={`/units/${listing.propertyManagerContract?.uuid}`}
        title="List your unit">
        <TopBarLoader isLoading={this.state.isLoading} />
        <IncompleteFieldsModal
          completedState={this.getMemoizedCompletedState(listing)}
          isOpen={this.state.isIncompleteFieldsModalOpen}
          onClose={this.closeIncompleteFieldsModal}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXLarge} />
        <Row
          flexBehavior={Row.enums.FlexBehaviors.Default}>
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXLarge} />
          <WidthConstraintView
            hasAutoMargin={false}
            size={WidthConstraintView.enums.Sizes.Medium}>
            {this.state.error ? (
              <Fragment>
                <Alert color={Alert.enums.Colors.Red} description={this.state.error} />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              </Fragment>
            ) : null }
            <AnimatedStepsFlow>
              <AnimatedStepsFlow.AnimatedStepsFlowSection name={enums.Sections.PropertyBasics}>
                <AnimatedStepsFlow.AnimatedStepsFlowStep
                  hasFooter={false}
                  icon={AnimatedStepsFlow.enums.Icons.BedLights}
                  isCompleted={completedState[enums.Steps.Rooms]}
                  label={enums.Steps.Rooms}
                  path={`/listings/${listingUuid}/${_.kebabCase(enums.Steps.Rooms)}`}
                  subtitle="The number of rooms in your unit"
                  title="Rooms">
                  <ListingSetupRooms
                    listingUuid={listingUuid}
                    onUpdateListing={this.handleUpdateListing}
                    onUpdateAddressUnit={this.handleUpdateAddressUnit}
                  />
                </AnimatedStepsFlow.AnimatedStepsFlowStep>
                <AnimatedStepsFlow.AnimatedStepsFlowStep
                  hasFooter={false}
                  icon={AnimatedStepsFlow.enums.Icons.Dog}
                  isCompleted={completedState[enums.Steps.Amenities]}
                  label={enums.Steps.Amenities}
                  path={`/listings/${listingUuid}/${_.kebabCase(enums.Steps.Amenities)}`}
                  subtitle="What kind of perks does this place come with?"
                  title="Amenities">
                  <ListingSetupAmenities
                    listingUuid={listingUuid}
                    onUpdateListing={this.handleUpdateListing}
                  />
                </AnimatedStepsFlow.AnimatedStepsFlowStep>
              </AnimatedStepsFlow.AnimatedStepsFlowSection>
              <AnimatedStepsFlow.AnimatedStepsFlowSection name={enums.Sections.LeasingDetails}>
                <AnimatedStepsFlow.AnimatedStepsFlowStep
                  hasFooter={false}
                  icon={AnimatedStepsFlow.enums.Icons.MoneyBags}
                  isCompleted={completedState[enums.Steps.Payments]}
                  label={enums.Steps.Payments}
                  path={`/listings/${listingUuid}/${_.kebabCase(enums.Steps.Payments)}`}
                  subtitle="How do you want to price this unit?"
                  title="Payments">
                  <ListingSetupPayments
                    listingUuid={listingUuid}
                    onUpdateListing={this.handleUpdateListing}
                  />
                </AnimatedStepsFlow.AnimatedStepsFlowStep>
                <AnimatedStepsFlow.AnimatedStepsFlowStep
                  hasFooter={false}
                  icon={AnimatedStepsFlow.enums.Icons.Box}
                  isCompleted={completedState[enums.Steps.Availability]}
                  label={enums.Steps.Availability}
                  path={`/listings/${listingUuid}/${_.kebabCase(enums.Steps.Availability)}`}
                  subtitle="When is the space available?"
                  title="Availability">
                  <ListingSetupAvailability
                    listingUuid={listingUuid}
                    onUpdateListing={this.handleUpdateListing}
                  />
                </AnimatedStepsFlow.AnimatedStepsFlowStep>
              </AnimatedStepsFlow.AnimatedStepsFlowSection>
              <AnimatedStepsFlow.AnimatedStepsFlowSection name={enums.Sections.PostDetails}>
                <AnimatedStepsFlow.AnimatedStepsFlowStep
                  hasFooter={false}
                  icon={AnimatedStepsFlow.enums.Icons.Paper}
                  isCompleted={completedState[enums.Steps.Description]}
                  label={enums.Steps.Description}
                  path={`/listings/${listingUuid}/${_.kebabCase(enums.Steps.Description)}`}
                  subtitle="What makes your unit great?"
                  title="Description">
                  <ListingSetupDescription
                    listingUuid={listingUuid}
                    onUpdateListing={this.handleUpdateListing}
                  />
                </AnimatedStepsFlow.AnimatedStepsFlowStep>
                <AnimatedStepsFlow.AnimatedStepsFlowStep
                  icon={AnimatedStepsFlow.enums.Icons.Photos}
                  isCompleted={completedState[enums.Steps.Photos]}
                  label={enums.Steps.Photos}
                  path={`/listings/${listingUuid}/${_.kebabCase(enums.Steps.Photos)}`}
                  primaryAction={{
                    label: 'Finish',
                    onClick: this.handleListListing,
                  }}
                  subtitle="Add at least one photo of the space"
                  title="Photos">
                  <ListingSetupPhotos
                    listingUuid={listingUuid}
                    onUpdateListing={this.handleUpdateListing}
                  />
                </AnimatedStepsFlow.AnimatedStepsFlowStep>
              </AnimatedStepsFlow.AnimatedStepsFlowSection>
            </AnimatedStepsFlow>
          </WidthConstraintView>
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXLarge} />
        </Row>
      </ModalStage>
    );
  }

  closeIncompleteFieldsModal = () => this.setState({ isIncompleteFieldsModalOpen: false });
  openIncompleteFieldsModal = () => this.setState({ isIncompleteFieldsModalOpen: true });
}

interface Response {
  viewer: {
    listing: Listing | null;
    photoUrl: string;
    uuid: string;
  }
}

interface QueryProps {
  avatarPhotoUrl: string | null;
  isLoading: boolean;
  listing: Listing | null;
}

interface Variables {
  listingUuid: string;
}

export default compose(
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: props => ({
      variables: {
        listingUuid: props.match.params.listingUuid,
      },
      skip: !props.match.params.listingUuid,
      ssr: false,
    }),
    props: props => ({
      avatarPhotoUrl: props.data?.viewer?.photoUrl || null,
      isLoading: props.loading,
      listing: props.data?.viewer?.listing || null,
    }),
  }),
  withListListing,
  withUnlistListing,
  withUpdateListing,
  withUpdateAddressUnit,
)(ListingSetup);
