import React from 'react';

import Card from '~tools/react/components/Card';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import ReviewIllo from './svgs/review.svg';

interface Props {
  renterFirstName: string;
}

function ThirdPartyReviewStep(props: Props) {
  return (
    <Card.CardSection>
      <div style={{ display: 'flex', justifyContent: 'space-between', }}>
        <div>
          <Heading
            content="Landlord review is in progress"
            font={Heading.enums.Fonts.Secondary}
            priority={Heading.enums.Priorities.Three}
            size={Heading.enums.Sizes.XXSmall}
          />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
          <Heading
            content={`You're almost done! We are working closely with your landlord to get ${props.renterFirstName}'s application accepted. This usually takes a few days but can take up to a week depending on how fast your landlord responds. Check below for updates.`}
            font={Heading.enums.Fonts.Secondary}
            priority={Heading.enums.Priorities.Four}
            size={Heading.enums.Sizes.XXXSmall}
          />
        </div>
        <div style={{ display: 'flex', }}>
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Large} />
          <ReviewIllo />
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Large} />
        </div>
      </div>
    </Card.CardSection>
  );
}

export default ThirdPartyReviewStep;
