import withMutation from '~tools/react/graphql/withMutation';

import UpdatePersonMutation from './updatePerson.gql';

interface Person {
  bio?: string;
  email?: string;
  firstName: string;
  lastName?: string;
  website?: string;
  uuid: string;
}

interface UpdatePersonInput {
  bio?: string | null;
  email?: string | null;
  firstName?: string;
  lastName?: string | null;
  website?: string | null;
}

interface Data {
  updatePerson: Person;
}

interface Variables {
  input: UpdatePersonInput;
  uuid: string;
}

export interface UpdatePersonProps {
  updatePerson: (uuid: string, input: UpdatePersonInput) => Promise<Person>;
}

export default withMutation<{}, Data, Variables, UpdatePersonProps>(UpdatePersonMutation, {
  props: props => ({
    updatePerson: async (uuid, input) => {
      const res = await props.mutate({
        variables: {
          input,
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error updating person.');

      return res.data.updatePerson;
    }
  }),
});
