import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './ThreadNewDivider.scss';

interface Props {
  zIndex?: number;
}

function ThreadNewDivider(props: Props) {
  return (
    <div style={{ zIndex: props.zIndex }} styleName="thread-new">
      <span>NEW</span>
    </div>
  );
}

export default withStyles(styles)(ThreadNewDivider);
