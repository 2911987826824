// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._vqQdo6{position:relative;z-index:1}._vqQdo6 ._prWfEn{box-shadow:0 19px 40px 0 rgba(0,0,0,.05),0 8px 18px 0 rgba(14,102,177,.05);opacity:.95;position:absolute;top:67px;width:250px;z-index:1}._vqQdo6 ._P9WZ3v{border:none;border-radius:3px;color:#1f8eed;font-size:14px;font-weight:300;margin:0;-webkit-transition:all .25s;transition:all .25s;width:100%}._vqQdo6 ._P9WZ3v::-webkit-input-placeholder{color:#aab7c9}._vqQdo6 ._P9WZ3v::-moz-placeholder{color:#aab7c9}._vqQdo6 ._P9WZ3v:-ms-input-placeholder{color:#aab7c9}._vqQdo6 ._P9WZ3v::-ms-input-placeholder{color:#aab7c9}._vqQdo6 ._P9WZ3v::placeholder{color:#aab7c9}._vqQdo6 ._P9WZ3v:focus{border-color:#dfe1e4;outline:none}._vqQdo6 ._APRWo9{font-size:14px;font-weight:500;letter-spacing:1px;text-transform:uppercase}._vqQdo6 ._APRWo9:hover{color:#1f8eed}._vqQdo6._aeRDND{z-index:10}", ""]);
// Exports
exports.locals = {
	"date-picker": "_vqQdo6",
	"date-picker__dropdown": "_prWfEn",
	"date-picker__field": "_P9WZ3v",
	"date-picker__today": "_APRWo9",
	"date-picker--open": "_aeRDND"
};
module.exports = exports;
