import _ from 'lodash';
import React, { ComponentType, Fragment, PureComponent } from 'react';

import withQuery from '~tools/react/graphql/withQuery';
import { DocumentTags } from '~tools/types/graphqlSchema';
import { compose } from '~tools/react/hocs/utils';

import withUnacceptApplication, { UnacceptApplicationProps } from '~tools/react/graphql/mutations/applications/withUnacceptApplication';

import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import ConfirmationModal from '~tools/react/components/ConfirmationModal';
import GenericLoadingVisual from '~tools/react/components/GenericLoadingVisual';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import HelloSignModal from '~tools/react/containers/HelloSignModal';

import query from './SignLeaseStep.gql';
import { QueryProps, QueryResponse, QueryVariables } from './types';

import SigningIllo from './svgs/signing.svg';

interface InputProps {
  applicationUuid: string;
  hasSigned?: true;
}

type Props = InputProps & QueryProps & UnacceptApplicationProps;

interface State {
  isStepBackConfirmationModalOpen: boolean;
  isSigningModalOpen: boolean;
  isUnaccepting: boolean;
}

class SignLeaseStep extends PureComponent<Props, State> {
  state: State = {
    isStepBackConfirmationModalOpen: false,
    isSigningModalOpen: false,
    isUnaccepting: false,
  };

  handleSignedDocument = async () => {
    this.setState({ isSigningModalOpen: false });
    await this.props.refetch();
  }

  handleCloseSigningModal = () => {
    this.setState({ isSigningModalOpen: false });
  }

  handleClickSign = async () => {
    this.setState({ isSigningModalOpen: true });
  }

  handleClickStepBack = async () => {
    this.toggleStepBackConfirmationModal();
  }

  handleStepBack = async () => {
    this.setState({ isUnaccepting: true });
    await this.props.unacceptApplication(this.props.applicationUuid);
    this.setState({ isUnaccepting: false, isStepBackConfirmationModalOpen: false });
  }

  render() {
    const application = this.props.application;
    const applicant = application?.applicants[0];
    const viewer = this.props.viewer;
    if (!application || !applicant || !viewer || this.props.isLoading) {
      return (
        <Card.CardSection title="Lease details">
          <GenericLoadingVisual />
        </Card.CardSection>
      );
    }

    const lease = application.lease;
    const document = lease.document;
    let documentUrl = document.accessUrl;
    if (document.eSignedUrl) documentUrl = document.eSignedUrl;
    else if (document.tag === DocumentTags.UPLOAD && document.upload) documentUrl = document.upload.url;

    const viewerHasSigned = !!_.find(document.documentUsers, dU => dU.user.uuid === viewer.uuid)?.signedAt || this.props.hasSigned;

    return (
      <Fragment>
        <ConfirmationModal
          cancelAction={{
            label: 'Cancel',
            onClick: this.toggleStepBackConfirmationModal,
          }}
          confirmAction={{
            label: 'Un-accept',
            onClick: this.handleStepBack,
          }}
          description="Are you sure you want to un-accept this applicant? You will still be able to accept them again, and you will be able to edit your lease details."
          isLoading={this.state.isUnaccepting}
          isOpen={this.state.isStepBackConfirmationModalOpen}
          onClose={this.toggleStepBackConfirmationModal}
          title="Un-accept applicant"
        />
        {lease.document ? (
          <HelloSignModal
            documentUuid={lease.document.uuid}
            isOpen={this.state.isSigningModalOpen}
            onSuccess={this.handleSignedDocument}
            onCancel={this.handleCloseSigningModal}
          />
        ) : null}
        <Card.CardSection>
          <div style={{ display: 'flex', justifyContent: 'space-between', }}>
            <div>
              <Heading
                content="Lease signing and security deposit"
                font={Heading.enums.Fonts.Secondary}
                priority={Heading.enums.Priorities.Three}
                size={Heading.enums.Sizes.XXSmall}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
              <Heading
                content={`Waiting on ${applicant.firstName} to sign the lease and put down the security deposit.`}
                font={Heading.enums.Fonts.Secondary}
                priority={Heading.enums.Priorities.Four}
                size={Heading.enums.Sizes.XXXSmall}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
              <Text
                color={Text.enums.Colors.Info}
                content="Learn about security deposits"
                isEmphasized
                hasHoverStyles
                link={{ path: 'https://caretaker.com/questions/how-do-security-deposits-work-for-leaseholders-and-landlords', shouldOpenNewTab: true }}
                size={Text.enums.Sizes.Small}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Large} />
              <SigningIllo />
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Large} />
            </div>
          </div>
        </Card.CardSection>
        <Card.CardSection>
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
              color={Button.enums.Colors.Red}
              label="Step back"
              isLoading={this.state.isUnaccepting}
              onClick={this.handleClickStepBack}
              size={Button.enums.Sizes.Small}
              style={Button.enums.Styles.Outline}
            />
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
            {/* This is an edge-case state, listers should always sign first while accepting an applicant */}
            {!viewerHasSigned ? (
              <Fragment>
                <Button
                  label="Sign lease"
                  onClick={this.handleClickSign}
                  size={Button.enums.Sizes.Small}
                />
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
              </Fragment>
            ) : null}
            <Button
              label="View lease document"
              size={Button.enums.Sizes.Small}
              style={Button.enums.Styles.Outline}
              link={{
                path: documentUrl,
                shouldOpenNewTab: true,
              }}
            />
          </div>
        </Card.CardSection>
      </Fragment>
    );
  }

  toggleStepBackConfirmationModal = () => {
    this.setState({ isStepBackConfirmationModalOpen: !this.state.isStepBackConfirmationModalOpen });
  }
}

export default compose(
  withUnacceptApplication,
  withQuery<InputProps, QueryResponse, QueryVariables, QueryProps>(query, {
    options: (props) => ({
      variables: { applicationUuid: props.applicationUuid },
    }),
    props: (props) => ({
      application: props.data?.viewer?.application ?? null,
      isLoading: props.loading,
      refetch: props.refetch,
      viewer: props.data?.viewer ? {
        uuid: props.data.viewer.uuid,
      } : null,
    }),
  }),
)(SignLeaseStep) as ComponentType<InputProps>;
