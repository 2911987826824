import { scaleBand } from 'd3-scale';
export default function bandScale(_ref) {
  var range = _ref.range,
      rangeRound = _ref.rangeRound,
      domain = _ref.domain,
      padding = _ref.padding,
      paddingInner = _ref.paddingInner,
      paddingOuter = _ref.paddingOuter,
      align = _ref.align,
      tickFormat = _ref.tickFormat;
  var scale = scaleBand();
  if (range) scale.range(range);
  if (rangeRound) scale.rangeRound(rangeRound);
  if (domain) scale.domain(domain);
  if (padding) scale.padding(padding);
  if (paddingInner) scale.paddingInner(paddingInner);
  if (paddingOuter) scale.paddingOuter(paddingOuter);
  if (align) scale.align(align); // @TODO should likely get rid of these.
  // @ts-ignore

  if (tickFormat) scale.tickFormat = tickFormat; // @ts-ignore

  scale.type = 'band';
  return scale;
}