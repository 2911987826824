import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './NotFoundView.scss';

interface Props {
  copy?: string;
  link?: {
    copy: string;
    path: string;
  };
}

function NotFoundView(props: Props) {
  const copy = props.copy || 'Uh oh. We couldn\'t find that page.';
  const link = props.link || {
    copy: 'Head back home',
    path: '/',
  };

  return (
    <div styleName="not-found">
      <Helmet title="Page not found | Caretaker" />
      <img src="https://images.caretaker.com/static/assets/illos/404.png" alt="404 - Page not found." />
      <p>{copy}</p>
      <Link to={link.path}>{link.copy}</Link>
    </div>
  );
}

export default withStyles(styles)(NotFoundView);
