// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._ZJN3QV{max-width:100px}", ""]);
// Exports
exports.locals = {
	"apartment-name-view": "_ZJN3QV"
};
module.exports = exports;
