import _ from 'lodash';
import moment from 'moment-timezone';
import queryString from 'query-string';
import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ShipmentTypes } from '~tools/types/graphqlSchema';

import { getAbbreviatedStreetAddress } from '~tools/utils/geography/addresses';
import { pageToCursor } from '~tools/react/graphql/utils';
import withQuery from '~tools/react/graphql/withQuery';

import { formatUnitName } from '~web-manage/lib/common/utils/addressUnit';
import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import Card from '~tools/react/components/Card';
import SteppedProgressBar from '~tools/react/components/SteppedProgressBar';
import Text from '~tools/react/components/Text';

import Table from '~tools/react/components/Table';

import query from './SmartLocks.gql';

type Props = InputProps & QueryProps;

enum Steps {
  Shipped = 'SHIPPED',
  Delivered = 'DELIVERED',
  SetUp = 'SET_UP',
  Returned = 'RETURNED'
}

const NUMBER_OF_ORDERS_TO_FETCH = 10;

class SmartLocks extends PureComponent<Props> {
  render() {
    const orders = this.props.orders;

    return (
      <ManageStage>
        <Card
          header={{
            subtitle: 'Manage your smart locks',
            title: 'All smart locks',
          }}>
          <Table>
            <Table.TableHead>
              <Table.TableRow>
                <Table.TableHeader>Ordered</Table.TableHeader>
                <Table.TableHeader>Unit</Table.TableHeader>
                <Table.TableHeader>Status</Table.TableHeader>
                <Table.TableHeader />
              </Table.TableRow>
            </Table.TableHead>
            <Table.TableBody>
              {_.map(orders, (order) => {
                const addressUnit = order.propertyManagerContract?.addressUnit;
                let displayedUnitAddress = '';
                if (addressUnit) {
                  displayedUnitAddress = `${
                    getAbbreviatedStreetAddress(addressUnit.address.streetAddress1)
                  } ${
                    formatUnitName(addressUnit)
                  }`;
                }

                const shipment = _.find(order.shipments, s => s.type === ShipmentTypes.SHIPMENT);
                const returnShipment = _.find(order.shipments, s => s.type === ShipmentTypes.RETURN);
                const smartLockOrderItem = _.find(order.orderItems, (orderItem) => !!orderItem.inventoryItems[0]?.smartLock);

                let activeStepId = Steps.Shipped;
                if (shipment?.shippedAt) {
                  activeStepId = Steps.Delivered;
                }
                if (shipment?.deliveredAt) {
                  activeStepId = Steps.SetUp;
                }
                if (returnShipment?.shippedAt) {
                  activeStepId = Steps.Returned;
                }

                return (
                  <Table.TableRow
                    key={order.uuid}
                    link={{ path: `/smart-locks/${order.uuid}` }}>
                    <Table.TableData>
                      <Text
                        content={moment(order.createdAt).format('MM/DD/YY h:mma')}
                        overflow={Text.enums.OverflowValues.Ellipsis}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Small}
                      />
                    </Table.TableData>
                    <Table.TableData>
                      <Text
                        content={displayedUnitAddress}
                        overflow={Text.enums.OverflowValues.Ellipsis}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Small}
                      />
                    </Table.TableData>
                    <Table.TableData>
                      <SteppedProgressBar
                        activeStepId={activeStepId}
                        isComplete={!!returnShipment?.deliveredAt}
                        steps={[{
                          label: activeStepId === Steps.Shipped ? 'Processing' : 'Shipped',
                          id: Steps.Shipped,
                        }, {
                          label: activeStepId === Steps.Delivered ? 'En route' : 'Delivered',
                          id: Steps.Delivered,
                        }, {
                          label: smartLockOrderItem?.inventoryItems[0]?.smartLock?.isSetup ? 'Active' : 'Pending setup',
                          id: Steps.SetUp,
                        }, {
                          label: returnShipment?.shippedAt && !returnShipment?.deliveredAt ? 'Returning' : 'Returned',
                          id: Steps.Returned,
                        }]}
                      />
                    </Table.TableData>
                  </Table.TableRow>
                );
              })}
              {this.props.ordersPageInfo ? (
                <Table.TablePaginator
                  startCursor={this.props.ordersPageInfo.startCursor || ''}
                  endCursor={this.props.ordersPageInfo.endCursor || ''}
                  resultsPerPage={NUMBER_OF_ORDERS_TO_FETCH}
                  totalResults={this.props.ordersTotal}
                />
              ) : null}
            </Table.TableBody>
          </Table>
        </Card>
      </ManageStage>
    );
  }
}

interface Order {
  createdAt: string;
  orderItems: {
    inventoryItems: {
      smartLock: {
        isSetup: boolean;
        uuid: string;
      } | null;
      uuid: string;
    }[];
    uuid: string;
  }[];
  propertyManagerContract: {
    uuid: string;
    addressUnit: {
      address: {
        city: string;
        country: string;
        neighborhood: string | null;
        state: string;
        streetAddress1: string;
        uuid: string;
        zipCode: string;
      };
      name: string | null;
      uuid: string;
    };
  } | null;
  shipments: {
    carrier: string;
    deliveredAt: string | null;
    method: string | null;
    shippedAt: string | null;
    status: string;
    type: ShipmentTypes;
    uuid: string;
  }[];
  uuid: string;
}

interface Response {
  viewer: {
    orders: Order[];
    ordersTotal: number;
    ordersPageInfo: {
      endCursor: string | null;
      startCursor: string | null;
    };
    uuid: string;
  }
}

interface Variables {
  after?: string;
  first?: number;
}

type InputProps = RouteComponentProps;

interface QueryProps {
  isLoading: boolean;
  orders: Order[];
  ordersTotal: number;
  ordersPageInfo: {
    endCursor: string | null;
    startCursor: string | null;
  } | null;
}

export default withQuery<InputProps, Response, Variables, QueryProps>(query, {
  options: (props) => {
    const pageString = queryString.parse(props.location.search).page;
    const page = _.parseInt(typeof pageString === 'string' ? pageString : '1') ?? undefined;
    const after = page > 1 ? pageToCursor(page, NUMBER_OF_ORDERS_TO_FETCH) : undefined;
    return {
      variables: {
        after,
        first: NUMBER_OF_ORDERS_TO_FETCH,
      },
    };
  },
  props: (props) => ({
    isLoading: props.loading,
    orders: props.data?.viewer?.orders || [],
    ordersPageInfo: props.data?.viewer?.ordersPageInfo || null,
    ordersTotal: props.data?.viewer?.ordersTotal || 0,
  }),
})(SmartLocks);
