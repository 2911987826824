import withMutation from '~tools/react/graphql/withMutation';

import AttachVerificationMethodToRequirementMutation from './attachVerificationMethodToRequirement.gql';

interface Requirement {
  updatedAt: string;
  uuid: string;
  verificationMethods: {
    createdAt: string;
    description: string | null;
    name: string;
    updatedAt: string;
    uuid: string;
  }[];
}

interface AttachVerificationMethodToRequirementInput {
  verificationMethodUuid: string;
}

interface Data {
  attachVerificationMethodToRequirement: Requirement;
}

interface Variables {
  input: AttachVerificationMethodToRequirementInput;
  uuid: string;
}

export interface AttachVerificationMethodToRequirementProps {
  attachVerificationMethodToRequirement: (uuid: string, input: AttachVerificationMethodToRequirementInput) => Promise<Requirement>;
}

export default withMutation<{}, Data, Variables, AttachVerificationMethodToRequirementProps>(AttachVerificationMethodToRequirementMutation, {
  props: props => ({
    attachVerificationMethodToRequirement: async (uuid, input) => {
      const res = await props.mutate({
        variables: {
          input,
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error attaching verification method to requirement.');

      return res.data.attachVerificationMethodToRequirement;
    }
  }),
});
