// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._7RA255{table-layout:fixed}@media screen and (max-width:1127px){._7RA255{overflow-x:scroll;width:100%}}._7RA255 ._vqQUkj{font-size:14px;width:100%}", ""]);
// Exports
exports.locals = {
	"table": "_7RA255",
	"table__content": "_vqQUkj"
};
module.exports = exports;
