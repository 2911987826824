import _ from 'lodash';
import moment from 'moment-timezone';

import { TimeSlot } from './types';

export function getDayText(dayNumber: number) {
  return ({
    0: 'Sundays',
    1: 'Mondays',
    2: 'Tuesdays',
    3: 'Wednesdays',
    4: 'Thursdays',
    5: 'Fridays',
    6: 'Saturdays',
  })[dayNumber];
}

export function formatHour(hour: number) {
  if (hour === 0 || hour === 24) return '12am';
  if (hour === 12) return '12pm';
  return (hour > 12) ? `${hour - 12}pm` : `${hour}am`;
}

// Return an integer that represents the placement of an hour timestring ('14:00:00', Wednesday) within the week
// The BE returns timeslot end times as one second before the hour (eg '13:59:59'), so there's an optional arg to
// correct for them by adding a second.
function getHourValue(timeString: string, dayOfWeek?: number, isEndOfRange = false) {
  const date = moment.utc(`${moment.utc().startOf('day').format('YYYY-MM-DD')}T${timeString}`).local();
  const adjustedDate = isEndOfRange ? date.add(1, 'second') : date;
  const dayComponent = dayOfWeek != null ? dayOfWeek * 24 : 0;

  return adjustedDate.get('hour') + dayComponent;
}

// Time slot times are stored in UTC time, but we display in the listing's timezone
// This function uses similar logic as web-admin's convertTimeSlotToNearestDate() to format days
// and times for display
export function getLocalizedTimeValues(timeSlot: TimeSlot, timezone: string) {
  const dayStart = moment.utc().startOf('day').set('day', timeSlot.dayOfWeek);
  const startMoment = moment.utc(`${dayStart.format('YYYY-MM-DD')}T${timeSlot.startsAt}`).tz(timezone);

  // the day component of this Moment object could be off by a day, but we only need/use it for the time
  const endMoment = moment.utc(`${dayStart.format('YYYY-MM-DD')}T${timeSlot.endsAt}`).add(1, 'second').tz(timezone);

  const dayIndex = startMoment.get('day');
  return {
    dayIndex,
    dayText: getDayText(dayIndex),
    endTimeInt: endMoment.get('hour'),
    endTimeText: endMoment.format('ha'),
    startTimeInt: startMoment.get('hour'),
    startTimeText: startMoment.format('ha'),
  };
}

// Input: Array of one hour time slots
// Output: Array of time slots, with each bunch of connected slots combined into a larger continuous one
export function getCombinedTimeSlots(timeSlots: TimeSlot[], timezone: string): TimeSlot[] {
  const output: TimeSlot[] = [];
  let currentAggregate: TimeSlot | null = null;

  // Sort the combined slots by local time, to ensure late Saturday (early Sunday UTC) time slots
  // don't get moved to the front of the array
  const sortedSlots = _.sortBy(timeSlots, slot => {
    const { dayIndex, startTimeInt } = getLocalizedTimeValues(slot, timezone);
    return (dayIndex * 24) + startTimeInt;
  });

  sortedSlots.forEach(slot => {
    if (!currentAggregate) {
      currentAggregate = { ...slot };
      return;
    }

    const isContiguous = (
      getHourValue(slot.startsAt, slot.dayOfWeek, false) ===
      getHourValue(currentAggregate.endsAt, slot.dayOfWeek, true)
    );
    if (isContiguous) {
      currentAggregate.endsAt = slot.endsAt;
    } else {
      if (currentAggregate) output.push(currentAggregate);
      currentAggregate = { ...slot };
    }
  });

  if (currentAggregate) output.push(currentAggregate);
  return output;
}
