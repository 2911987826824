import _ from 'lodash';
import moment from 'moment';
import React, { MouseEvent, PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import DropdownMenu, { DropdownMenuSectionItem } from '~tools/react/components/DropdownMenu';
import GenericLoadingVisual from '~tools/react/components/GenericLoadingVisual';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import SteppedProgressBar, { Step as SteppedProgressBarStep } from '~tools/react/components/SteppedProgressBar';
import Thread from '~tools/react/containers/Thread';
import ThreadSupportModal from '~tools/react/containers/Thread/components/ThreadSupportModal';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import DataRow from '~web-manage/lib/common/components/DataRow';
import TenantQualificationsModal from '~web-manage/lib/common/containers/TenantQualificationsModal';

import withQuery from '~tools/react/graphql/withQuery';
import withRequestSupport, { RequestSupportProps } from '~tools/react/graphql/mutations/threads/withRequestSupport';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import { compose } from '~tools/react/hocs/utils';
import { ApplicationStatuses, DocumentTypes } from '~tools/types/graphqlSchema';
import { formatAsUSD } from '~tools/utils/string';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';
import { formatFullAddress } from '~web-manage/lib/common/utils/addressUnit';

import InterestedStep from './containers/InterestedStep';
import ReviewStep from './containers/ReviewStep';
import SignLeaseStep from './containers/SignLeaseStep';
import UpdateLeaseModal from './containers/UpdateLeaseModal';
import UploadCustomLeaseModal from './containers/UploadCustomLeaseModal';

import ThirdPartyReviewStep from './components/ThirdPartyReviewStep';
import CompleteStep from './components/CompleteStep';

import query from './Applicant.gql';
import { Application, QueryProps, QueryResponse, QueryVariables } from './types';

type InputProps = RouteComponentProps<{ applicationUuid: string }>;

type Props = QueryProps & InputProps & RequestSupportProps;

interface State {
  isApplicantActionsDropdownOpen: boolean;
  isQualificationsModalOpen: boolean;
  isSupportModalOpen: boolean;
  isUpdateLeaseModalOpen: boolean;
  isUploadCustomLeaseModalOpen: boolean;
  isRequestSupportBannerShown: boolean;
  hasSigned?: true;
}

interface ApplicationStep {
  name: ApplicationSteps;
  description: string;
}

enum ApplicationSteps {
  Complete = 'COMPLETE',
  Interested = 'INTERESTED',
  Review = 'REVIEW',
  SignLease = 'SIGN_LEASE',
  ThirdPartyReview = 'THIRD_PARTY_REVIEW', // subscriber-specific
}

class Applicant extends PureComponent<Props, State> {
  state: State = {
    isApplicantActionsDropdownOpen: false,
    isQualificationsModalOpen: false,
    isSupportModalOpen: false,
    isUpdateLeaseModalOpen: false,
    isUploadCustomLeaseModalOpen: false,
    isRequestSupportBannerShown: false,
  };

  componentDidMount() {
    if (this.getIsSubManagementContract() && !this.props.application?.thread.supportRequestedAt) {
      this.setState({ isRequestSupportBannerShown: true });
    }
  }
  // TODO - I think the above isn't needed any more - check - SV

  handleCloseApplicantActionsDropdown = () => {
    document.removeEventListener('click', this.handleCloseApplicantActionsDropdown);
    this.setState({ isApplicantActionsDropdownOpen: false });
  }

  handleOpenApplicantActionsDropdown = (e: MouseEvent<EventTarget>) => {
    e.nativeEvent.stopImmediatePropagation();

    document.addEventListener('click', this.handleCloseApplicantActionsDropdown);
    this.setState({ isApplicantActionsDropdownOpen: true });
  }

  handleOpenUploadCustomLeaseModal = () => this.setState({ isUploadCustomLeaseModalOpen: true });
  handleCloseUploadCustomLeaseModal = async () => {
    this.setState({ isUploadCustomLeaseModalOpen: false });
    await this.props.refetch();
  };

  handleToggleSupportModal = () => {
    this.setState({ isSupportModalOpen: !this.state.isSupportModalOpen });
  }

  handleRequestSupport = () => {
    this.setState({
      isSupportModalOpen: false,
      isRequestSupportBannerShown: false,
    });
    if (!this.props.application) return;
    this.props.requestSupport(this.props.application.thread.uuid);
  }

  handleSigningComplete = () => {
    this.setState({ hasSigned: true });
  }

  handleCloseRequestSupportBanner = () => {
    this.setState({ isRequestSupportBannerShown: false });
  }

  render() {
    const application = this.props.application;
    const applicant = application?.applicants[0];
    if (!application || !applicant || this.props.isLoading) {
      return (
        <ManageStage>
          <Card>
            <Card.CardSection>
              <GenericLoadingVisual />
            </Card.CardSection>
          </Card>
        </ManageStage>
      );
    }

    const activeApplicationStep = this.getActiveApplicationStep(application);
    const lease = application.lease;
    return (
      <ManageStage>
        <ThreadSupportModal
          isOpen={this.state.isSupportModalOpen}
          hideRequestHelp={!!application.thread.supportRequestedAt}
          onClickRequestHelp={this.handleRequestSupport}
          onClose={this.handleToggleSupportModal}
        />
        <TenantQualificationsModal
          isOpen={this.state.isQualificationsModalOpen}
          applicationUuid={application.uuid}
          onClose={this.toggleQualificationsModal}
        />
        <UpdateLeaseModal
          isOpen={this.state.isUpdateLeaseModalOpen}
          leaseUuid={application.lease.uuid}
          onClose={this.toggleUpdateLeaseModal}
          onSubmit={this.props.refetch}
          shouldGenerateNewDocument={!!lease.document}
          title={application.lease.document?.type === DocumentTypes.UPLOAD ?
            'Generate lease' :
            'Edit lease details'}
        />
        <UploadCustomLeaseModal
          applicationUuid={application.uuid}
          isOpen={this.state.isUploadCustomLeaseModalOpen}
          leaseUuid={application.lease.uuid}
          onClose={this.handleCloseUploadCustomLeaseModal}
          onSubmit={this.props.refetch}
        />
        <Breadcrumbs
          items={[
            { path: '/applicants', label: 'All applicants' },
            { label: applicant.fullName },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
        <Card alert={this.getStatusAlert()}>
          <Card.CardSection>
            <Row
              flexBehavior={Row.enums.FlexBehaviors.Default}
              verticalAlignment={Row.enums.VerticalAlignments.Center}>
              <div>
                <Heading
                  content={applicant.fullName}
                  font={Heading.enums.Fonts.Secondary}
                  priority={Heading.enums.Priorities.One}
                  size={Heading.enums.Sizes.XSmall}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                <Heading
                  content={formatFullAddress(application.lease.addressUnit)}
                  font={Heading.enums.Fonts.Secondary}
                  priority={Heading.enums.Priorities.Two}
                  size={Heading.enums.Sizes.XXXSmall}
                />
              </div>
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
              <div
                style={{
                  marginLeft: 'auto',
                  position: 'relative',
                }}>
                <Button
                  align={Button.enums.Alignments.Right}
                  color={Button.enums.Colors.Gray}
                  icon={Button.enums.Icons.Ellipsis}
                  onClick={this.state.isApplicantActionsDropdownOpen ?
                    this.handleCloseApplicantActionsDropdown :
                    this.handleOpenApplicantActionsDropdown}
                  size={Button.enums.Sizes.Small}
                  style={Button.enums.Styles.Outline}
                />
                {this.state.isApplicantActionsDropdownOpen ? (
                  <DropdownMenu
                    carotPosition={{
                      right: '25px',
                    }}
                    position={{
                      right: '-13px',
                      top: '54px',
                    }}
                    sections={[{
                      items: this.getApplicantActionItems(),
                    }]}
                  />
                ) : null}
              </div>
            </Row>
          </Card.CardSection>
          <Card.CardSection
            subtitle={activeApplicationStep.description}
            title="Tenant progress">
            <SteppedProgressBar
              activeStepId={activeApplicationStep.name}
              steps={this.getApplicationSteps(application)}
            />
          </Card.CardSection>
          <Card.CardSection title="Lease details">
            {application.lease.document?.type === DocumentTypes.UPLOAD ? (
              <DataRow>
                <DataRow.DataRowItem
                  label="Upload date"
                  description={moment(application.lease.document.createdAt).format('MMMM DD, YYYY')}
                />
                <DataRow.DataRowItem
                  label="Lease document"
                  description={application.lease.document.filename}
                  link={{
                    path: application.lease.document.upload?.url || '#',
                    shouldOpenNewTab: !!application.lease.document.upload?.url,
                  }}
                />
              </DataRow>
            ) : null}
            {application.lease.document?.type === DocumentTypes.GENERATED ? (
              <DataRow>
                <DataRow.DataRowItem
                  label="Security deposit"
                  description={`$${formatAsUSD(lease.securityDepositInCents / 100)}`}
                />
                <DataRow.DataRowItem
                  label="Monthly rent"
                  description={`$${formatAsUSD(lease.rentInCents / 100)} / mo`}
                />
                <DataRow.DataRowItem
                  label="Start date"
                  description={moment(lease.startDate).format('MMM DD, YYYY')}
                />
                <DataRow.DataRowItem
                  label="End date"
                  description={moment(lease.endDate).format('MMM DD, YYYY')}
                />
                <DataRow.DataRowItem
                  label="Lease document"
                  description="Preview PDF"
                  link={{
                    path: application.lease.document.accessUrl,
                    shouldOpenNewTab: true,
                  }}
                />
              </DataRow>
            ) : null}
          </Card.CardSection>
          {activeApplicationStep.name === ApplicationSteps.Interested ? (
            <InterestedStep applicationUuid={application.uuid} />
          ) : null}
          {activeApplicationStep.name === ApplicationSteps.Review ? (
            <ReviewStep
              documentUuid={application.lease.document?.uuid}
              applicationUuid={application.uuid}
              isSubscriber={this.getIsSubscriber()}
              onOpenQualificationsModal={this.toggleQualificationsModal}
              onSigningComplete={this.handleSigningComplete}
            />
          ) : null}
          {activeApplicationStep.name === ApplicationSteps.SignLease ? (
            <SignLeaseStep
              applicationUuid={application.uuid}
              hasSigned={this.state.hasSigned}
            />
          ) : null}
          {activeApplicationStep.name === ApplicationSteps.ThirdPartyReview ? (
            <ThirdPartyReviewStep renterFirstName={applicant.firstName} />
          ) : null}
          {activeApplicationStep.name === ApplicationSteps.Complete ? (
            <CompleteStep
              renterFirstName={applicant.firstName}
              leasePath={`/leases/${application.lease.uuid}`}
            />
          ) : null}
        </Card>
        <Card
          header={{
            actions: [{
              label: application.thread.supportRequestedAt ?
                'Support requested' :
                'Request support',
              onClick: this.handleToggleSupportModal,
              isDisabled: !!application.thread.supportRequestedAt,
              icon: application.thread.supportRequestedAt ?
                Card.enums.ActionIcons.Check :
                undefined,
            }],
            title: 'Activity',
            subtitle: 'Updates and messages between you and your tenant',

          }}>
          {this.state.isRequestSupportBannerShown ? (
            <>
              <Card.CardAlert
                action={{
                  label: '',
                  icon: Card.enums.ActionIcons.Cross,
                  onClick: this.handleCloseRequestSupportBanner,
                }}
                color={Card.CardAlert.enums.Colors.Blue}
                message={`Our support team is standing by to help get ${applicant.firstName} approved, invite us with "Request support".`}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
              <HorizontalRule />
            </>
          ) : null}
          <div style={{ display: 'flex', height: '500px' }}>
            <Thread
              uuid={application.thread.uuid}
              isMetadataDisabled
              supportRequestedAt={application.thread.supportRequestedAt}
            />
          </div>
        </Card>
      </ManageStage>
    );
  }

  getActiveApplicationStep = (application: Application): ApplicationStep => {
    const applicant = application.applicants[0];
    switch (application.status) {
      case ApplicationStatuses.OPENED:
        return {
          name: ApplicationSteps.Interested,
          description: `${applicant.firstName} is interested in your unit. Finalize the details and then you can review their application.`,
        };
      case ApplicationStatuses.APPLIED:
      case ApplicationStatuses.WITHDRAWN:
      case ApplicationStatuses.REJECTED: {
        let description = `${applicant.firstName} applied to your rental! Accept or reject their application.`;
        if (this.getIsManagedUnit()) {
          description = `${applicant.firstName} applied to your rental. Review the lease details below carefully - you won\'t be able to make changes once you\'ve signed.`;
        }
        return {
          name: ApplicationSteps.Review,
          description,
        };
      }
      case ApplicationStatuses.ACCEPTED:
        return {
          name: ApplicationSteps.SignLease,
          description: `Waiting for ${applicant.firstName} to countersign the lease and put down the security deposit!`,
        };
      case ApplicationStatuses.IN_REVIEW:
        return {
          name: ApplicationSteps.ThirdPartyReview,
          description: 'Caretaker is working to get the application approved by your landlord. Check below for updates!',
        };
      default:
        return {
          name: ApplicationSteps.Complete,
          description: `You're all set! ${applicant.firstName}\'s application was approved!`,
        };
    }
  }

  getApplicationSteps = (application: Application): SteppedProgressBarStep[] => {
    const applicant = application.applicants[0];

    return _.compact([this.getIsManagedUnit() ? undefined : {
      label: 'Interested',
      id: ApplicationSteps.Interested,
    }, {
      label: this.getIsManagedUnit() ? 'Finalize details' : 'Review application',
      id: ApplicationSteps.Review,
    }, {
      label: `${applicant.firstName} signs lease, pays security deposit`,
      id: ApplicationSteps.SignLease,
    }, this.getIsSubManagementContract() ? {
      label: 'Building review',
      id: ApplicationSteps.ThirdPartyReview,
    } : undefined, {
      label: 'You\'re done!',
      id: ApplicationSteps.Complete,
    }]);
  }

  getIsSubscriber = () => {
    return !!this.props.activePaymentSubscription;
  }

  getIsSubManagementContract = () => {
    return this.props.application?.listing.propertyManagerContract?.isSubManagementContract;
  }

  getIsManagedUnit = () => {
    return !!this.props.application?.listing.propertyManagerContract;
  }

  getStatusAlert = () => {
    const application = this.props.application;
    if (!application || !_.includes([ApplicationStatuses.REJECTED, ApplicationStatuses.WITHDRAWN], application.status)) return undefined;

    const applicant = application.applicants[0];

    return {
      color: Card.enums.AlertColors.Red,
      icon: Card.enums.AlertIcons.Alert,
      message: `${applicant.firstName}'s application has been ${_.lowerCase(application.status)}`,
    };
  }

  getApplicantActionItems = () => {
    const application = this.props.application;
    if (!application) return [];
    const activeApplicationStep = this.getActiveApplicationStep(application);

    const applicantActionItems: DropdownMenuSectionItem[] = [{
      label: 'View unit',
      path: `/units/${application.listing.propertyManagerContract?.uuid}`,
    }];

    if (_.includes([
      ApplicationSteps.Review,
      ApplicationSteps.Interested,
    ], activeApplicationStep.name)) {
      applicantActionItems.push({
        label: application.lease.document?.type === DocumentTypes.UPLOAD ?
          'Generate lease' :
          'Edit lease details',
        onClick: this.toggleUpdateLeaseModal,
      });

      applicantActionItems.push({
        label: 'Upload custom lease',
        onClick: this.handleOpenUploadCustomLeaseModal,
      });
    }

    if (_.includes([
      ApplicationSteps.SignLease,
      ApplicationSteps.ThirdPartyReview,
      ApplicationSteps.Complete,
    ], activeApplicationStep.name)) {
      applicantActionItems.push({
        label: 'View qualifications',
        onClick: this.toggleQualificationsModal,
      });
    }

    return applicantActionItems;
  }

  toggleUpdateLeaseModal = () => {
    this.setState({ isUpdateLeaseModalOpen: !this.state.isUpdateLeaseModalOpen });
  }

  toggleQualificationsModal = () => {
    this.setState({ isQualificationsModalOpen: !this.state.isQualificationsModalOpen });
  }
}

export default compose(
  withRequestSupport,
  withQuery<InputProps, QueryResponse, QueryVariables, QueryProps>(query, {
    options: (props) => ({
      skip: !props.match.params.applicationUuid,
      variables: { uuid: props.match.params.applicationUuid },
    }),
    props: (props) => ({
      activePaymentSubscription: props.data?.viewer?.activePaymentSubscription ?? null,
      application: props.data?.viewer?.application ?? null,
      isLoading: props.loading,
      refetch: props.refetch,
    }),
  }),
)(Applicant);
