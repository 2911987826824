// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._nrBtX9{display:grid;grid-template-columns:minmax(100px,180px) minmax(200px,525px);margin-bottom:16px}._nrBtX9._qroQ3a{-webkit-box-align:end;align-items:end}._nrBtX9._vqQj2K{-webkit-box-align:start;align-items:start}._nrBtX9 ._yprIG3{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;max-height:34px;min-height:34px}._nrBtX9 ._J2VdAr{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;-webkit-box-pack:start;justify-content:flex-start;min-height:34px}._nrBtX9 ._J2VdAr ._zaNzJm{display:block;margin:auto 0}@media screen and (max-width:1127px){._nrBtX9{grid-gap:4px;grid-template-columns:unset}}._nrBtX9:last-child{margin-bottom:0}", ""]);
// Exports
exports.locals = {
	"data-grid-row": "_nrBtX9",
	"data-grid-row--align-end": "_qroQ3a",
	"data-grid-row--align-start": "_vqQj2K",
	"data-grid-row__left": "_yprIG3",
	"data-grid-row__right": "_J2VdAr",
	"data-grid-row__right__children": "_zaNzJm"
};
module.exports = exports;
