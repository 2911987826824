export {
  Colors as TagColors,
  Icons as TagIcons,
} from '~tools/react/components/Tag/enums';

export enum HighlightColors {
  Blue = 'BLUE',
  Gray = 'GRAY',
  Green = 'GREEN',
  Red = 'RED',
  Yellow = 'YELLOW',
}

export enum Statuses {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
}
