import _ from 'lodash';
import classNames from 'classnames';
import isEqual from 'react-fast-compare';
import React, { Component } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './ToggleSwitch.scss';

interface Option {
  label: string;
  value: string | number;
}

interface Props {
  isMobile?: boolean;
  label?: string;
  name: string;
  onChange?: (value: number | string | undefined, name: string) => void;
  options: [Option, Option];
  value?: number | string;
}

interface State {
  value: string | number;
}

class ToggleSwitch extends Component<Props, State> {
  state = {
    value: this.props.value || '',
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (!isEqual(this.props, nextProps)) return true;
    if (!isEqual(this.state, nextState)) return true;

    return false;
  }

  handleSelect = (e) => {
    if (this.state.value === e.currentTarget.value) {
      this.setState({ value: '' });
      if (this.props.onChange) this.props.onChange(undefined, this.props.name);
      return;
    }

    this.setState({ value: e.currentTarget.value });
    if (this.props.onChange) this.props.onChange(e.currentTarget.value, this.props.name);
  };

  render() {
    return (
      <div styleName="toggle-switch">
        {this.props.isMobile && this.props.label ? (
          <label>{this.props.label}</label>
        ) : null}
        {_.map(this.props.options, option => (
          <button
            className={classNames({
              [styles.button]: true,
              [styles['button--active']]: this.state.value === option.value,
            })}
            key={option.value}
            onClick={this.handleSelect}
            value={option.value}>
            {option.label}
          </button>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(ToggleSwitch);
