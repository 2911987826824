// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._WVjtN6{-webkit-box-align:center;align-items:center;border:0;border-radius:50%;display:-webkit-box;display:flex;height:40px;-webkit-box-pack:center;justify-content:center;min-width:40px;padding:0;-webkit-transition:background-color .25s;transition:background-color .25s;width:40px}._WVjtN6 svg{height:24px;width:24px}._WVjtN6 svg path{stroke:#8492a6;-webkit-transition:stroke .25s;transition:stroke .25s}._WVjtN6:active{background-color:#f6f8fa;box-shadow:none}._WVjtN6:disabled{opacity:.5;pointer-events:none}._WVjtN6._vqQ6mK{background-color:#eff2f7}._WVjtN6._vqQ6mK svg{height:18px;width:18px}._WVjtN6._vqQ6mK:disabled{opacity:1}._WVjtN6._EGn0yn svg,._WVjtN6._yprP0d svg{height:18px;width:18px}._WVjtN6._5aAtd2:active svg path{stroke:#1f8eed}._WVjtN6._5aAtd2:hover{background-color:#e4f6fe}._WVjtN6._5aAtd2:hover svg path{stroke:#1f8eed}._WVjtN6._zaNQyw:active svg path{stroke:#21bc7a}._WVjtN6._zaNQyw:hover{background-color:#e6fcf9}._WVjtN6._zaNQyw:hover svg path{stroke:#21bc7a}._WVjtN6._drDaYO:active svg path{stroke:#ff5167}._WVjtN6._drDaYO:hover{background-color:#ffeaed}._WVjtN6._drDaYO:hover svg path{stroke:#ff5167}", ""]);
// Exports
exports.locals = {
	"icon-button": "_WVjtN6",
	"icon-button--loading": "_vqQ6mK",
	"icon-button--icon-lock": "_EGn0yn",
	"icon-button--icon-pencil": "_yprP0d",
	"icon-button--color-blue": "_5aAtd2",
	"icon-button--color-green": "_zaNQyw",
	"icon-button--color-red": "_drDaYO"
};
module.exports = exports;
