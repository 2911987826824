// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._APR02E{position:relative;width:75%}", ""]);
// Exports
exports.locals = {
	"chart-view": "_APR02E"
};
module.exports = exports;
