import { ApolloQueryResult } from 'apollo-client';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import _ from 'lodash';

import Globals from '~web-core/lib/common/globals';

import { formatAsUSD } from '~tools/utils/string';
import { TransactionStatuses } from '~tools/types/graphqlSchema';
import withQuery from '~tools/react/graphql/withQuery';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';
import DataRow from '~web-manage/lib/common/components/DataRow';
import Table from '~web-manage/lib/common/components/__deprecated/Table';

import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Card from '~tools/react/components/Card';
import Center from '~tools/react/components/Center';
import Heading from '~tools/react/components/Heading';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Spinner from '~tools/react/components/Spinner';
import Tag from '~tools/react/components/Tag';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import ClaimModal from '~tools/react/containers/ClaimModal';

import DropdownMenuButton from './components/DropdownMenuButton';

import query from './SecurityDeposit.gql';

import { SecurityDeposit as SecurityDepositType } from './types';

type InputProps =
  RouteComponentProps<{ securityDepositUuid: string }>;

type Props =
  QueryProps &
  InputProps;

interface State {
  isClaimModalOpen: boolean;
  selectedClaimUuid?: string;
}

class SecurityDeposit extends Component<Props, State> {
  state: State = {
    isClaimModalOpen: false,
  };

  handleClickClaim = (claimUuid: string) => {
    this.setState({
      isClaimModalOpen: true,
      selectedClaimUuid: claimUuid,
    });
  }

  handleCloseClaimModal = () => {
    this.props.refetch();
    this.setState({ isClaimModalOpen: false });
  }
  handleOpenClaimModal = () => this.setState({ isClaimModalOpen: true });

  handleReturnSecurityDeposit = () => {};

  render() {
    const securityDeposit = this.props.securityDeposit;
    if (!securityDeposit || this.props.isLoading) {
      return (
        <ManageStage isLoading={this.props.isLoading}>
          <Breadcrumbs
            items={[
              { path: '/security-deposits', label: 'All security deposits' },
              { label: '-' },
            ]}
            style={Breadcrumbs.enums.Styles.Compact}
          />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          <Card>
            <Card.CardSection>
              <Center>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
                <Spinner />
              </Center>
            </Card.CardSection>
          </Card>
        </ManageStage>
      );
    }

    const claimedAmountInCents = securityDeposit.initialAmountInCents - securityDeposit.currentAmountInCents;
    const debitTransaction = securityDeposit.debitTransaction;
    const charges: {
      deltaAmountInCents: number;
      label: string;
      labelMeta?: string;
    }[] = [{
      deltaAmountInCents: securityDeposit.initialAmountInCents,
      label: 'Security deposit',
    }];

    if (securityDeposit.currentAmountInCents < securityDeposit.initialAmountInCents) {
      charges.push({
        deltaAmountInCents: -(securityDeposit.initialAmountInCents - securityDeposit.currentAmountInCents),
        label: 'Claims',
      });
    }

    if (debitTransaction.processorFeeInCents) {
      charges.push({
        deltaAmountInCents: debitTransaction.processorFeeInCents,
        label: 'Payment processing fees',
        labelMeta: `${parseFloat(`${Globals.CARD_PROCESSING_FEE_PERCENTAGE}`).toFixed(2)}%`,
      });
    }

    let statusLabel;
    let statusIcon;
    let statusColor;
    if (debitTransaction.refundedAt) {
      statusLabel = 'Refunded';
      statusIcon = Tag.enums.Icons.Check;
      statusColor = Tag.enums.Colors.Gray;
    } else {
      switch (debitTransaction.status) {
        case TransactionStatuses.SUCCEEDED:
        case TransactionStatuses.PAID:
          statusLabel = 'In escrow';
          statusIcon = Tag.enums.Icons.Lock;
          statusColor = Tag.enums.Colors.Blue;
          break;
        case TransactionStatuses.IN_TRANSIT:
        case TransactionStatuses.PENDING:
          statusLabel = _.startCase(debitTransaction.status);
          statusIcon = Tag.enums.Icons.Clock;
          statusColor = Tag.enums.Colors.Green;
          break;
        case TransactionStatuses.CANCELED:
        case TransactionStatuses.FAILED:
          statusLabel = _.startCase(debitTransaction.status);
          statusIcon = Tag.enums.Icons.Cross;
          statusColor = Tag.enums.Colors.Red;
          break;
        default:
          statusLabel = _.startCase(debitTransaction.status);
          statusColor = Tag.enums.Colors.Gray;
      }
    }

    return (
      <ManageStage>
        <ClaimModal
          isOpen={this.state.isClaimModalOpen}
          securityDepositUuid={securityDeposit.uuid}
          claimUuid={this.state.selectedClaimUuid}
          onClose={this.handleCloseClaimModal}
        />
        <Breadcrumbs
          items={[
            { path: '/security-deposits', label: 'All security deposits' },
            { label: `${securityDeposit.uuid}` },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
        <Card>
          <Card.CardSection>
            <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
              <div>
                <Heading
                  content="Security deposit"
                  font={Heading.enums.Fonts.Secondary}
                  size={Heading.enums.Sizes.XXXSmall}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                <Row
                  flexBehavior={Row.enums.FlexBehaviors.Default}
                  verticalAlignment={Row.enums.VerticalAlignments.Center}>
                  <Heading
                    content={`*$${formatAsUSD(securityDeposit.initialAmountInCents / 100, true)}* USD`}
                    font={Heading.enums.Fonts.Secondary}
                    isMarkdown
                    size={Heading.enums.Sizes.Medium}
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                  <Tag
                    color={statusColor}
                    label={statusLabel}
                    size={Tag.enums.Sizes.Large}
                    icon={statusIcon}
                  />
                </Row>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <DropdownMenuButton
                  menuSections={[
                    {
                      items: [
                        {
                          label: 'File damage claim',
                          onClick: this.handleOpenClaimModal,
                        },
                      ],
                    },
                    // {
                    //   items: [
                    //     {
                    //       color: DropdownMenuButton.enums.ItemColors.Red,
                    //       label: 'Return security deposit',
                    //       onClick: this.handleReturnSecurityDeposit,
                    //     },
                    //   ],
                    // },
                  ]}
                />
              </div>
            </Row>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
            <HorizontalRule />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
            <DataRow>
              <DataRow.DataRowItem
                label="Lessee"
                link={{
                  path: `/leases/${securityDeposit.lease.uuid}`,
                }}
                description={securityDeposit.account.fullName}
              />
              <DataRow.DataRowItem
                label="Current amount"
                description={`$${formatAsUSD(securityDeposit.currentAmountInCents / 100, true)}`}
              />
              <DataRow.DataRowItem
                color={claimedAmountInCents > 0 ? DataRow.DataRowItem.enums.Colors.Error : undefined}
                label="Claimed amount"
                description={`$${formatAsUSD(claimedAmountInCents / 100, true)}`}
              />
            </DataRow>
          </Card.CardSection>
        </Card>
        <Card
          header={{
            actions: [{
              icon: Card.enums.ActionIcons.Paperclip,
              label: 'File damage claim',
              onClick: this.handleOpenClaimModal,
            }],
            subtitle: 'File claims if there is any unforeseen damage to the property. All reports are manually reviewed by Caretaker.',
            title: 'Claims',
          }}>
          <Card.CardSection spacing={Card.CardSection.enums.Spacing.None}>
            <Table>
              <Table.TableRow isHeader>
                <Table.TableHeader>Amount</Table.TableHeader>
                <Table.TableHeader>Description</Table.TableHeader>
                <Table.TableHeader>Status</Table.TableHeader>
              </Table.TableRow>
              {securityDeposit.claims.length > 0 ? (
                securityDeposit.claims.map((claim) => {
                  let status = 'PENDING';
                  if (claim.rejectedAt) status = 'REJECTED';
                  if (claim.approvedAt) status = 'APPROVED';
                  return (
                    <Table.TableRow
                      identifier={claim.uuid}
                      onClick={this.handleClickClaim}
                      key={claim.uuid}>
                      <Table.TableData>
                        ${formatAsUSD(claim.amountInCents / 100)}
                      </Table.TableData>
                      <Table.TableData>
                        {claim.description}
                      </Table.TableData>
                      <Table.TableData>
                        <Tag
                          label={status}
                          color={Tag.enums.Colors.Blue}
                        />
                      </Table.TableData>
                    </Table.TableRow>
                  );
                })
              ) : (
                <Table.TableEmptyState label="There are no claims yet." />
              )}
            </Table>
          </Card.CardSection>
        </Card>
      </ManageStage>
    );
  }
}

interface Response {
  viewer: {
    securityDeposit: SecurityDepositType | null;
    uuid: string;
  } | null;
}

interface Variables {
  after?: string;
  first?: number;
}

interface QueryProps {
  isLoading: boolean;
  refetch: () => Promise<ApolloQueryResult<Response>>;
  securityDeposit: SecurityDepositType | null;
}

export default withQuery<InputProps, Response, Variables, QueryProps>(query, {
  options: ({ match }) => ({
    variables: {
      securityDepositUuid: match.params.securityDepositUuid,
    },
    ssr: true,
  }),
  props: props => ({
    isLoading: props.loading,
    securityDeposit: props.data?.viewer?.securityDeposit ?? null,
    refetch: props.refetch,
  }),
})(SecurityDeposit);
