// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._yprQZ2{-webkit-box-align:center;align-items:center;color:#8492a6;display:-webkit-box;display:flex;font-size:14px;font-weight:300;-webkit-box-pack:center;justify-content:center;margin:24px 0 0}._yprQZ2 ._ernAP0{height:24px;margin-right:12px;width:20px}", ""]);
// Exports
exports.locals = {
	"secure-footer": "_yprQZ2",
	"secure-footer__svg": "_ernAP0"
};
module.exports = exports;
