import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import React, { Component, ComponentType } from 'react';

import { LeaseTypes } from '~tools/types/graphqlSchema';

import { extractMessageFromError } from '~tools/utils/error';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withCreateDocument, { CreateDocumentProps, DocumentTags } from '~tools/react/graphql/mutations/documents/withCreateDocument';
import withCreateLeaseInvite, { CreateLeaseInviteProps } from '~tools/react/graphql/mutations/leaseInvites/withCreateLeaseInvite';
import withCreateLease, { CreateLeaseProps } from '~tools/react/graphql/mutations/leases/withCreateLease';
import withCreateUpload, { CreateUploadProps, UploadTags } from '~tools/react/graphql/mutations/uploads/withCreateUpload';

import Alert from '~tools/react/components/Alert';
import Button from '~tools/react/components/Button';
import Dropzone, { DropzoneLoadData } from '~tools/react/containers/Dropzone';
import Form, { Input } from '~tools/react/components/Form';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import ThemedModal from '~tools/react/components/ThemedModal';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import query from './CreateLeaseModal.gql';

dayjs.extend(utc);
dayjs.extend(timezone);

interface State {
  error?: string;
  isDisabled: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  lease: {
    documentUuid?: string;
    endDate?: string;
    rentInDollars?: string;
    securityDepositInDollars?: string;
    startDate?: string;
  };
  tenant: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
  };
}

interface InputProps {
  addressUnitUuid: string;
  isOpen: boolean;
  onClose: () => void;
  onCreateLease: () => void | Promise<void>;
}

type Props =
  InputProps &
  QueryProps &
  CreateLeaseInviteProps &
  CreateDocumentProps &
  CreateLeaseProps &
  CreateUploadProps;

class CreateLeaseModal extends Component<Props, State> {
  state: State = {
    isDisabled: false,
    isLoading: false,
    isSubmitted: false,
    lease: {},
    tenant: {},
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.setState({
        isDisabled: false,
        isLoading: false,
        isSubmitted: false,
        lease: {},
        tenant: {},
      });
    }
  }

  handleSubmitForm = async () => {
    this.setState({ isSubmitted: true });

    const addressUnit = this.props.addressUnit;
    const viewer = this.props.viewer;
    const leaseData = this.state.lease;
    const tenantData = this.state.tenant;
    if (
      !addressUnit ||
      !viewer ||
      !leaseData.startDate ||
      !leaseData.endDate ||
      !leaseData.rentInDollars ||
      !leaseData.securityDepositInDollars ||
      !leaseData.documentUuid ||
      !tenantData.firstName ||
      !tenantData.lastName ||
      !tenantData.email
    ) {
      return;
    }

    this.setState({ isLoading: true });
    try {
      const lease = await this.props.createLease({
        addressUnitUuid: addressUnit.uuid,
        documentUuid: leaseData.documentUuid,
        endDate: dayjs.tz(leaseData.endDate, addressUnit.address.timezone).endOf('day').toISOString(),
        lessorUuid: viewer.uuid,
        rentInCents: parseInt(leaseData.rentInDollars, 10) * 100,
        securityDepositInCents: parseInt(leaseData.securityDepositInDollars, 10) * 100,
        startDate: dayjs.tz(leaseData.startDate, addressUnit.address.timezone).startOf('day').toISOString(),
        type: LeaseTypes.MASTER,
      });
      await this.props.createLeaseInvite({
        email: tenantData.email,
        leaseUuid: lease.uuid,
      });
      await this.props.onCreateLease();

      this.setState({
        isDisabled: true,
        isLoading: false,
      });
      this.props.onClose();
    } catch (err) {
      this.setState({
        error: extractMessageFromError(err),
        isLoading: false,
      });
    }
  };

  render() {
    return (
      <ThemedModal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        width={ThemedModal.enums.Widths.Small}
        title="Upload lease">
        {this.state.error ? (
          <ThemedModal.ThemedModalSection>
            <Alert
              color={Alert.enums.Colors.Red}
              title={this.state.error}
            />
          </ThemedModal.ThemedModalSection>
        ) : null}
        <ThemedModal.ThemedModalSection
          icon={ThemedModal.ThemedModalSection.enums.Icons.Document}
          title="Lease details">
          <Form.FormSection columns={Form.FormSection.enums.Columns.Two}>
            <Input
              isInvalid={this.state.isSubmitted && !this.state.lease.startDate}
              isRequired
              label="Start date"
              labelFormat={Input.enums.LabelFormats.Stacked}
              name="startDate"
              onChange={this.changeStartDate}
              placeholder="mm/dd/yyyy"
              type={Input.enums.Types.Date}
            />
            <Input
              isInvalid={this.state.isSubmitted && !this.state.lease.endDate}
              isRequired
              label="End date"
              labelFormat={Input.enums.LabelFormats.Stacked}
              name="endDate"
              onChange={this.changeEndDate}
              placeholder="mm/dd/yyyy"
              type={Input.enums.Types.Date}
            />
          </Form.FormSection>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          <Form.FormSection columns={Form.FormSection.enums.Columns.Two}>
            <Input
              isInvalid={this.state.isSubmitted && !this.state.lease.rentInDollars}
              isRequired
              label="Monthly rent"
              labelFormat={Input.enums.LabelFormats.Stacked}
              maxAmount={20000}
              name="rentInDollars"
              onChange={this.changeRentInDollars}
              placeholder="1750"
              type={Input.enums.Types.Currency}
            />
            <Input
              isInvalid={this.state.isSubmitted && !this.state.lease.securityDepositInDollars}
              isRequired
              label="Security deposit"
              labelFormat={Input.enums.LabelFormats.Stacked}
              maxAmount={20000}
              name="securityDepositInDollars"
              onChange={this.changeSecurityDepositInDollars}
              placeholder="1750"
              type={Input.enums.Types.Currency}
            />
          </Form.FormSection>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          <Form.FormSection>
            <Dropzone
              isInvalid={this.state.isSubmitted && !this.state.lease.documentUuid}
              label="Lease document"
              onLoad={this.loadDocument}
              placeholder="Click or drag here to upload"
              placeholderIcon={Dropzone.enums.Icons.PlusCircle}
              shouldAllowMultipleFiles={false}
              shouldRenderFileManager
              uploadType={Dropzone.enums.UploadTypes.Document}
            />
          </Form.FormSection>
        </ThemedModal.ThemedModalSection>
        <ThemedModal.ThemedModalSection
          icon={ThemedModal.ThemedModalSection.enums.Icons.User}
          title="Tenant info">
          <Form.FormSection columns={Form.FormSection.enums.Columns.Two}>
            <Input
              isInvalid={this.state.isSubmitted && !this.state.tenant.firstName}
              isRequired
              label="First name"
              labelFormat={Input.enums.LabelFormats.Stacked}
              name="firstName"
              onChange={this.changeFirstName}
              placeholder="John"
              type={Input.enums.Types.Text}
            />
            <Input
              isInvalid={this.state.isSubmitted && !this.state.tenant.lastName}
              isRequired
              label="Last name"
              labelFormat={Input.enums.LabelFormats.Stacked}
              name="lastName"
              onChange={this.changeLastName}
              placeholder="Smith"
              type={Input.enums.Types.Text}
            />
          </Form.FormSection>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          <Form.FormSection>
            <Input
              isInvalid={this.state.isSubmitted && !this.state.tenant.email}
              isRequired
              label="Email"
              labelFormat={Input.enums.LabelFormats.Stacked}
              name="email"
              onChange={this.changeEmail}
              placeholder="john_smith@renter.com"
              type={Input.enums.Types.Email}
            />
          </Form.FormSection>
        </ThemedModal.ThemedModalSection>
        <ThemedModal.ThemedModalSection>
          <div
            style={{
              display: 'flex',
              width: '100%',
            }}>
            <Button
              align={Button.enums.Alignments.Right}
              color={Button.enums.Colors.Gray}
              label="Cancel"
              onClick={this.props.onClose}
              size={Button.enums.Sizes.Small}
              style={Button.enums.Styles.Outline}
            />
            <HorizontalSpacing
              size={HorizontalSpacing.enums.Sizes.XSmall}
            />
            <Button
              color={Button.enums.Colors.Blue}
              icon={Button.enums.Icons.Plus}
              isDisabled={this.state.isDisabled}
              isLoading={this.state.isLoading}
              label="Add lease &amp; invite tenant"
              onClick={this.handleSubmitForm}
              size={Button.enums.Sizes.Small}
              type={Button.enums.Types.Submit}
            />
          </div>
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }

  changeFirstName = (value: string) => this.setState({
    tenant: {
      ...this.state.tenant,
      firstName: value,
    },
  });
  changeLastName = (value: string) => this.setState({
    tenant: {
      ...this.state.tenant,
      lastName: value,
    },
  });
  changeEmail = (value: string) => this.setState({
    tenant: {
      ...this.state.tenant,
      email: value,
    },
  });

  changeStartDate = (value: string) => this.setState({
    lease: {
      ...this.state.lease,
      startDate: value,
    },
  });
  changeEndDate = (value: string) => this.setState({
    lease: {
      ...this.state.lease,
      endDate: value,
    },
  });
  changeRentInDollars = (value: string) => this.setState({
    lease: {
      ...this.state.lease,
      rentInDollars: value,
    },
  });
  changeSecurityDepositInDollars = (value: string) => this.setState({
    lease: {
      ...this.state.lease,
      securityDepositInDollars: value,
    },
  });

  loadDocument = async (attrs: DropzoneLoadData) => {
    const addressUnit = this.props.addressUnit;
    if (!addressUnit) return;

    const upload = await this.props.createUpload({
      filename: attrs.file.name,
      fileType: attrs.file.type,
      tag: UploadTags.MASTER_LEASE,
      url: attrs.file.uri,
    });
    const document = await this.props.createDocument({
      displayName: `Master Lease Agreement for ${addressUnit.address.streetAddress1}${addressUnit.name ? `, ${addressUnit.name}` : ''}`,
      filename: attrs.file.name,
      fullyExecutedAt: dayjs().toISOString(),
      isSigningEnabled: false,
      tag: DocumentTags.UPLOAD,
      uploadUuid: upload.uuid,
    });

    this.setState({
      lease: {
        ...this.state.lease,
        documentUuid: document.uuid,
      },
    });
  };
}

interface AddressUnit {
  address: {
    streetAddress1: string;
    timezone: string;
  };
  name: string;
  uuid: string;
}

interface Viewer {
  uuid: string;
}

interface Response {
  addressUnit: AddressUnit | null;
  viewer: Viewer | null;
}

interface QueryProps {
  addressUnit: AddressUnit | null;
  viewer: Viewer | null;
}

interface Variables {
  addressUnitUuid: string;
}

export default compose(
  withCreateLeaseInvite,
  withCreateDocument,
  withCreateLease,
  withCreateUpload,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: props => ({
      variables: {
        addressUnitUuid: props.addressUnitUuid,
      },
    }),
    props: props => ({
      addressUnit: props.data?.addressUnit ?? null,
      viewer: props.data?.viewer ?? null,
    }),
  }),
)(CreateLeaseModal) as ComponentType<InputProps>;
