import React, { Fragment } from 'react';

import Button from '~tools/react/components/Button';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import withStyles from '~tools/react/hocs/withStyles';

import ResponsiveStepButtons from '../../../../components/ResponsiveStepButtons';

import * as enums from './enums';
import styles from './SmartLockSetupStep.scss';

interface Props {
  children?: React.ReactNode;
  description: string;
  illustration: enums.Illustrations;
  isMobile: boolean;
  isShown: boolean;
  onClickBack?: () => void;
  onClickForward?: () => void;
  stepNumber: number;
  title: string;
}

function SmartLockSetupStep(props: Props) {
  return (
    <div styleName="takeover-smart-lock-setup-step">
      <div styleName="takeover-smart-lock-setup-step__contents">
        <div styleName="takeover-smart-lock-setup-step__number">{props.stepNumber}</div>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
        <Heading content={props.title} />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
        <Text
          content={props.description}
          size={Text.enums.Sizes.XLarge}
        />
        {props.children || (
          <Fragment>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
            <ResponsiveStepButtons
              isMobile={props.isMobile}
              isShown={props.isShown}>
              {props.onClickBack && (
                <Fragment>
                  <Button
                    color={Button.enums.Colors.Gray}
                    label="Go back"
                    onClick={props.onClickBack}
                    style={Button.enums.Styles.Outline}
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Medium} />
                </Fragment>
              )}
              <Button
                label="Continue"
                onClick={props.onClickForward}
              />
            </ResponsiveStepButtons>
          </Fragment>
        )}
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
      </div>
    </div>
  );
}

export default withStyles(styles)(SmartLockSetupStep);
