import hoistStatics from 'hoist-non-react-statics';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, ComponentType } from 'react';

import * as enums from './enums';

export interface DeviceProps {
  isMobile: boolean;
  platform: enums.Platforms;
}

interface State {
  isMobile: boolean;
  platform: enums.Platforms;
}

function withDevice<T>(ComposedComponent: ComponentType<T & DeviceProps>) {
  class WithDevice extends Component<T, State> {
    static ComposedComponent = ComposedComponent;

    static displayName = `withDevice(${ComposedComponent.displayName || ComposedComponent.name || 'Component'})`;
    static contextTypes = { userAgent: PropTypes.string.isRequired };

    constructor(props: T, context) {
      super(props);

      let isMobile = false;
      let platform = enums.Platforms.Desktop;
      if (this.isMobile(context.userAgent)) {
        isMobile = true;
        platform = enums.Platforms.Phone;
      }

      this.state = {
        isMobile,
        platform,
      };
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
      let newState = this.state;
      if (window.innerWidth <= enums.BreakPoints.Phone) {
        newState = {
          isMobile: true,
          platform: enums.Platforms.Phone,
        };
      } else if (window.innerWidth <= enums.BreakPoints.Tablet) {
        newState = {
          isMobile: true,
          platform: enums.Platforms.Tablet,
        };
      } else {
        newState = {
          isMobile: false,
          platform: enums.Platforms.Desktop,
        };
      }

      if (!_.isEqual(newState, this.state)) {
        this.setState(newState);
      }
    }

    render() {
      return (
        <ComposedComponent
          {...this.props}
          isMobile={this.state.isMobile}
          platform={this.state.platform}
        />
      );
    }

    isMobile = (userAgent) => {
      if (/Mobi|Android/i.test(userAgent)) return true;

      return false;
    }
  }

  return hoistStatics(WithDevice, ComposedComponent);
}

withDevice.enums = enums;

export default withDevice;
