import _ from 'lodash';
import React, { PureComponent } from 'react';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';

import StatusCardSection from '~web-manage/lib/common/components/StatusCardSection';

import query from './MaintenanceStatusSection.gql';

interface Issue {
  issueResolution: {
    uuid: string;
  } | null;
  uuid: string;
}

interface QueryProps {
  isLoading: boolean;
  issues: Issue[];
}

interface InputProps {
  isLoading: boolean;
}

type Props =
  InputProps &
  QueryProps;

class MaintenanceStatusSection extends PureComponent<Props> {
  render() {
    if (this.props.isLoading) {
      return (
        <StatusCardSection
          isLoading={this.props.isLoading}
          description="Maintenance details loading..."
          status={StatusCardSection.enums.Statuses.Unknown}
          title="Maintenance"
        />
      );
    }

    const issues = this.props.issues;
    if (issues.length === 0) {
      return (
        <StatusCardSection
          description="Your units haven't had any reports of issues yet."
          link={{
            label: 'Set up your operating account',
            path: '/issues',
          }}
          status={StatusCardSection.enums.Statuses.NoData}
          title="Maintenance"
        />
      );
    }

    const resolvedIssues = _.filter(issues, i => !!i.issueResolution);
    const resolvedPercentage = (resolvedIssues.length / issues.length) * 100;
    const unresolvedCount = issues.length - resolvedIssues.length;

    let status;
    if (resolvedIssues.length === issues.length) {
      status = StatusCardSection.enums.Statuses.Ok;
    } else if (resolvedPercentage > 70 || unresolvedCount < 3) {
      status = StatusCardSection.enums.Statuses.Warning;
    } else {
      status = StatusCardSection.enums.Statuses.Degraded;
    }

    return (
      <StatusCardSection
        isLoading={this.props.isLoading}
        description="issues reported in the last 30 days have been marked as resolved by our team."
        highlight={`*${resolvedIssues.length}* of *${issues.length}*`}
        status={status}
        link={status !== StatusCardSection.enums.Statuses.Ok ? {
          label: 'View unresolved issues',
          path: '/issues',
        } : undefined}
        title="Maintenance"
      />
    );
  }
}

interface Response {
  viewer: {
    issues: Issue[];
    uuid: string;
  } | null;
}

export default compose(
  withQuery<InputProps, Response, {}, QueryProps>(query, {
    props: props => ({
      isLoading: props.loading,
      issues: props.data?.viewer?.issues ?? [],
    }),
  }),
)(MaintenanceStatusSection);
