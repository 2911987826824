import _ from 'lodash';
import { ApolloQueryResult } from 'apollo-client';
import { RouteComponentProps } from 'react-router-dom';
import React, { Fragment, PureComponent } from 'react';

import withQuery from '~tools/react/graphql/withQuery';

import { formatTimestamp } from '~tools/utils/time';
import { compose } from '~tools/react/hocs/utils';
// import { formatAsUSD } from '~tools/utils/string';
// import { IssuedCardAuthorizationStatuses, IssueServiceTypes } from '~tools/types/graphqlSchema';
import { IssueServiceTypes } from '~tools/types/graphqlSchema';

import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Card from '~tools/react/components/Card';
import { Action as CardAction } from '~tools/react/components/Card/types';
import Button from '~tools/react/components/Button';
// import ChargeCardVisual from '~tools/react/components/ChargeCardVisual';
import CreateIssueResolutionModal from '~tools/react/containers/CreateIssueResolutionModal';
import GenericLoadingVisual from '~tools/react/components/GenericLoadingVisual';
// import Heading from '~tools/react/components/Heading';
// import HorizontalRule from '~tools/react/components/HorizontalRule';
import ImageModal from '~tools/react/components/ImageModal';
// import Row from '~tools/react/components/Row';
// import Table from '~tools/react/components/Table';
import Tag from '~tools/react/components/Tag';
import Text from '~tools/react/components/Text';
// import VerticalRule from '~tools/react/components/VerticalRule';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import { formatShortAddress } from '~web-manage/lib/common/utils/addressUnit';
import ManageStage from '~web-manage/lib/common/stages/ManageStage';
import ImageList from '~web-manage/lib/common/components/ImageList';
import DataGrid from '~web-manage/lib/common/components/DataGrid';

import { Issue as IssueType, Photo } from './types';
import query from './Issue.gql';

type Props = QueryProps & InputProps;

interface State {
  isCreateIssueResolutionModalOpen: boolean;
  isImageModalOpen: boolean;
  imageModalPhotos: Photo[];
  imageModalHeadline: string;
  isLoading: boolean;
}

class Issue extends PureComponent<Props, State> {
  state: State = {
    isCreateIssueResolutionModalOpen: false,
    isImageModalOpen: false,
    imageModalPhotos: [],
    imageModalHeadline: '',
    isLoading: false,
  };

  handleIssueResolutionCreated = () => this.props.refetch();

  handleOpenIssueImagesModal = () => {
    const issue = this.props.issue;
    if (!issue?.photos.length) return;

    this.setState({
      imageModalHeadline: issue.description,
      imageModalPhotos: issue.photos,
      isImageModalOpen: true,
    });
  };

  handleOpenIssueResolutionImagesModal = () => {
    const issueResolution = this.props.issue?.issueResolution;
    if (!issueResolution?.photos.length) return;

    this.setState({
      imageModalHeadline: issueResolution.notes,
      imageModalPhotos: issueResolution.photos,
      isImageModalOpen: true,
    });
  };

  handleCloseImageModal = () => {
    this.setState({
      imageModalHeadline: '',
      imageModalPhotos: [],
      isImageModalOpen: false,
    });
  };

  handleOpenCreateIssueResolutionModal = () => this.setState({ isCreateIssueResolutionModalOpen: true });
  handleCloseCreateIssueResolutionModal = () => this.setState({ isCreateIssueResolutionModalOpen: false });

  render() {
    const issue = this.props.issue;
    if (this.props.isLoading || this.state.isLoading || !issue) {
      return (
        <ManageStage>
          <GenericLoadingVisual />
        </ManageStage>
      );
    }

    // const issuedCardAuthorizations = issue.issuedCard?.issuedCardAuthorizations ?? [];
    const serviceTypeHeader = issue.serviceType === IssueServiceTypes.OTHER ?
      'Issue' :
      `${_.capitalize(issue.serviceType)} issue`;

    const actions: CardAction[] = [];
    if (!issue.issueResolution) {
      actions.push({
        color: Card.enums.ActionColors.Blue,
        icon: Card.enums.ActionIcons.Check,
        label: 'Resolve issue',
        onClick: this.handleOpenCreateIssueResolutionModal,
      });
    }

    return (
      <ManageStage>
        <Breadcrumbs
          items={[
            { path: `/issues`, label: 'Issues' },
            { label: serviceTypeHeader },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
        <Card
          header={{
            actions,
            title: serviceTypeHeader,
            subtitle: `${formatShortAddress(issue.addressUnit)}`,
            tag: issue.issueResolution ?
              {
                color: Tag.enums.Colors.Green,
                icon: Tag.enums.Icons.Check,
                label: 'Resolved',
              } : {
                color: Tag.enums.Colors.Orange,
                label: 'Unresolved',
              },
          }}>
          <Card.CardSection title="Details">
            <DataGrid>
              <DataGrid.DataGridColumn>
                <DataGrid.DataGridRow
                  label="Description"
                  description={issue.description}
                />
                <DataGrid.DataGridRow
                  label="Service type"
                  description={_.capitalize(issue.serviceType)}
                />
                <DataGrid.DataGridRow
                  label="Reported on"
                  description={formatTimestamp(issue.createdAt, 'MMM D, YYYY, h:mm A')}
                />
                <DataGrid.DataGridRow
                  label="Images">
                  {issue.photos.length > 0 ? (
                    <Fragment>
                      <ImageList
                        images={issue.photos}
                      />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                      <Button
                        label="View images"
                        onClick={this.handleOpenIssueResolutionImagesModal}
                        size={Button.enums.Sizes.XSmall}
                        style={Button.enums.Styles.Outline}
                      />
                    </Fragment>
                  ) : (
                    <Text
                      color={Text.enums.Colors.Secondary}
                      content="No images uploaded"
                    />
                  )}
                </DataGrid.DataGridRow>
              </DataGrid.DataGridColumn>
            </DataGrid>
          </Card.CardSection>
          <Card.CardSection title="Resolution details">
            {issue.issueResolution ? (
              <DataGrid>
                <DataGrid.DataGridColumn>
                  <DataGrid.DataGridRow
                    label="Notes"
                    description={issue.issueResolution.notes}
                  />
                  <DataGrid.DataGridRow
                    label="Resolved on"
                    description={formatTimestamp(issue.issueResolution.createdAt, 'MMM D, YYYY, h:mm A')}
                  />
                  <DataGrid.DataGridRow
                    label="Images">
                    {issue.issueResolution.photos.length > 0 ? (
                      <Fragment>
                        <ImageList
                          images={issue.issueResolution.photos}
                        />
                        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                        <Button
                          label="View images"
                          onClick={this.handleOpenIssueResolutionImagesModal}
                          size={Button.enums.Sizes.XSmall}
                          style={Button.enums.Styles.Outline}
                        />
                      </Fragment>
                    ) : (
                      <Text
                        color={Text.enums.Colors.Secondary}
                        content="No images uploaded"
                      />
                    )}
                  </DataGrid.DataGridRow>
                </DataGrid.DataGridColumn>
              </DataGrid>
            ) : (
              <Text
                content="Mark this maintenance issue as resolved when it's been repaired or completed."
              />
            )}
          </Card.CardSection>
        </Card>
        {/* <Card
          header={{
            actions: [
              {
                label: 'View all expenses',
                link: {
                  path: '/expenses',
                },
              },
            ],
            title: 'Expenses',
            subtitle: 'Expenses associated with this reported issue',
          }}>
          <Row flexBehavior={Row.enums.FlexBehaviors.Grow}>
            {issue.issuedCard ? (
              <Fragment>
                <div style={{ flex: 'unset' }}>
                  <Card.CardSection title="Charge card">
                    <ChargeCardVisual
                      cardBrand={issue.issuedCard.providerCard.brand}
                      expirationDate={`${issue.issuedCard.providerCard.expMonth}/${issue.issuedCard.providerCard.expYear}`}
                      fullName="Caretaker"
                      last4={issue.issuedCard.providerCard.last4}
                    />
                  </Card.CardSection>
                  <Card.CardSection title="Total authorized">
                    <div style={{ display: 'flex', alignItems: 'center', maxHeight: '32px' }}>
                      <Heading
                        content={`$${formatAsUSD(_.sumBy(issuedCardAuthorizations, 'amountInCents') / 100, true)} *USD*`}
                        font={Heading.enums.Fonts.Secondary}
                        isMarkdown
                        size={Heading.enums.Sizes.Medium}
                      />
                    </div>
                  </Card.CardSection>
                </div>
                <VerticalRule />
              </Fragment>
            ) : null}
            <Card.CardSection
              title="Transactions"
              spacing={Card.CardSection.enums.Spacing.None}>
              <HorizontalRule />
              <Table>
                <Table.TableHead>
                  <Table.TableRow>
                    <Table.TableHeader>Date</Table.TableHeader>
                    <Table.TableHeader width={Table.TableHeader.enums.Widths.AUTO}>Memo</Table.TableHeader>
                    <Table.TableHeader>Amount</Table.TableHeader>
                    <Table.TableHeader />
                    <Table.TableHeader />
                  </Table.TableRow>
                </Table.TableHead>
                <Table.TableBody>
                  {issuedCardAuthorizations.map((issuedCardAuthorization) => {
                    return (
                      <Table.TableRow
                        key={issuedCardAuthorization.uuid}
                        link={{
                          path: `/authorizations/${issuedCardAuthorization.uuid}`,
                        }}>
                        <Table.TableData>
                          <Text
                            content={formatTimestamp(issuedCardAuthorization.createdAt, 'MMM D, YYYY')}
                            overflow={Text.enums.OverflowValues.Ellipsis}
                            shouldWrap={false}
                            size={Text.enums.Sizes.Small}
                          />
                        </Table.TableData>
                        <Table.TableData
                          width={Table.TableData.enums.Widths.AUTO}>
                          <Text
                            content={issuedCardAuthorization.statementDescriptor || ''}
                            overflow={Text.enums.OverflowValues.Ellipsis}
                            shouldWrap={false}
                            size={Text.enums.Sizes.Small}
                          />
                        </Table.TableData>
                        <Table.TableData
                          alignment={Table.TableData.enums.Alignments.RIGHT}>
                          <Text
                            content={
                              `${issuedCardAuthorization.amountInCents > 0 ? '+' : '−'}${issuedCardAuthorization.status !== IssuedCardAuthorizationStatuses.PENDING ? '*' : ''}$${formatAsUSD(Math.abs(issuedCardAuthorization.amountInCents) / 100, true)}${issuedCardAuthorization.status !== IssuedCardAuthorizationStatuses.PENDING ? '*' : ''}`
                            }
                            shouldWrap={false}
                            size={Text.enums.Sizes.Medium}
                            isMarkdown
                          />
                        </Table.TableData>
                        <Table.TableData>
                          <Text
                            content="USD"
                            size={Text.enums.Sizes.Small}
                            color={Text.enums.Colors.Secondary}
                            shouldWrap={false}
                          />
                        </Table.TableData>
                      </Table.TableRow>
                    );
                  })}
                  {issuedCardAuthorizations.length === 0 ? (
                    <Table.TableEmptyState
                      label="No expenses incurred on this issue yet. If any are, you'll see them here!"
                    />
                  ) : null}
                </Table.TableBody>
              </Table>
            </Card.CardSection>
          </Row>
        </Card> */}
        <ImageModal
          isMobile={false}
          isOpen={this.state.isImageModalOpen}
          photos={this.state.imageModalPhotos}
          headline={this.state.imageModalHeadline}
          onClose={this.handleCloseImageModal}
        />
        <CreateIssueResolutionModal
          isOpen={this.state.isCreateIssueResolutionModalOpen}
          issueUuid={issue.uuid}
          onClose={this.handleCloseCreateIssueResolutionModal}
          onIssueResolutionCreated={this.handleIssueResolutionCreated}
        />
      </ManageStage>
    );
  }
}

interface QueryProps {
  issue: IssueType | null;
  isLoading: boolean;
  refetch: () => Promise<ApolloQueryResult<Response>>;
}

interface Variables {
  issueUuid: string;
}

interface Response {
  viewer: {
    uuid: string;
    issue: IssueType | null;
  } | null;
}

type InputProps = RouteComponentProps<{
  issueUuid: string;
}>;

export default compose(
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: props => ({
      fetchPolicy: 'no-cache',
      ssr: false,
      skip: !props.match.params.issueUuid,
      variables: {
        issueUuid: props.match.params.issueUuid,
      },
    }),
    props: props => ({
      issue: props.data?.viewer?.issue ?? null,
      isLoading: props.loading,
      refetch: props.refetch,
    }),
  }),
)(Issue);
