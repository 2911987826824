// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._EGntwm{background-color:#fff;border:none;color:#273444;flex-shrink:0;font-weight:300;text-align:left;-webkit-transition:all .1s cubic-bezier(.215,.61,.355,1);transition:all .1s cubic-bezier(.215,.61,.355,1);width:100%}._EGntwm:not(:last-child){border-bottom:1px solid #e6e8eb}._EGntwm._vqQAa0{background-color:#f6f8fa;color:#1f8eed}._EGntwm._drDoKN{font-size:16px;padding:16px 24px}._EGntwm._APRHNM{font-size:19px;padding:14px 18px}._EGntwm:hover{background-color:#f6f8fa}", ""]);
// Exports
exports.locals = {
	"typeahead-item": "_EGntwm",
	"typeahead-item--selected": "_vqQAa0",
	"typeahead-item--size-medium": "_drDoKN",
	"typeahead-item--size-large": "_APRHNM"
};
module.exports = exports;
