import React, { ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Stage from '~web-core/lib/common/stages/Stage';
import NavBar from '~web-manage/lib/common/components/NavBar';

import Text from '~tools/react/components/Text';

interface InputProps {
  children: ReactNode;
  closeRedirectPath?: string;
  title?: string;
}

type Props = InputProps & RouteComponentProps;

function ModalStage(props: Props) {
  return (
    <Stage
      alert={null}
      hasAppBanner={false}
      hasNavigation={false}
      isProtected
      shouldLoadSubscriptions={false}>
      <NavBar isConstrained={false}>
        <NavBar.NavBarIcon
          icon={NavBar.NavBarIcon.enums.Icons.Cross}
          link={props.closeRedirectPath ? {
            path: props.closeRedirectPath,
            shouldOpenNewTab: false,
          } : undefined}
          onClick={!props.closeRedirectPath ? props.history.goBack : undefined}
        />
        <NavBar.NavBarDivider />
        {props.title ? (
          <Text
            content={props.title}
            isEmphasized
          />
        ) : null}
      </NavBar>
      {props.children}
    </Stage>
  );
}

export default withRouter(ModalStage);
