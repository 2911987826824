// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._orGR0A{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-flex:1;flex:1;font-weight:300;text-align:left}._orGR0A:last-of-type{padding-right:0}", ""]);
// Exports
exports.locals = {
	"table__td": "_orGR0A"
};
module.exports = exports;
