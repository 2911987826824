// @flow
import React, { type Node } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './DashboardListingVerifyConfirmationModalContentView.scss';

type Props = {
  children: Node,
};

function DashboardListingVerifyConfirmationModalContentView(props: Props) {
  return (
    <div styleName="listing-verify-content">
      {props.children}
    </div>
  );
}

export default withStyles(styles)(DashboardListingVerifyConfirmationModalContentView);
