import { ApolloQueryResult } from 'apollo-client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';
import queryString from 'query-string';
import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router';

import ArrowLink from '~tools/react/components/ArrowLink';
import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Card from '~tools/react/components/Card';
import Center from '~tools/react/components/Center';
import Heading from '~tools/react/components/Heading';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Spinner from '~tools/react/components/Spinner';
import Table from '~tools/react/components/Table';
import Tag from '~tools/react/components/Tag';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import { pageToCursor } from '~tools/react/graphql/utils';
import withQuery from '~tools/react/graphql/withQuery';
import {
  BalanceTransactionStatuses,
  BalanceTransactionTypes,
  IssuedCardAuthorizationStatuses,
  ServiceTypes,
} from '~tools/types/graphqlSchema';
import { formatAsUSD } from '~tools/utils/string';
import { formatTimestamp } from '~tools/utils/time';

import DataGrid from '~web-manage/lib/common/components/DataGrid';
import IconBlock from '~web-manage/lib/common/components/IconBlock';
import InfoTooltip from '~web-manage/lib/common/components/InfoTooltip';
import InlinePaymentMethodVisual from '~web-manage/lib/common/components/InlinePaymentMethodVisual';
import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import UpdateFundingAccountModal from './containers/UpdateFundingAccountModal';
import { Balance as BalanceType } from './types';

import query from './Expenses.gql';

dayjs.extend(relativeTime);

const NUMBER_OF_BALANCE_TRANSACTIONS_TO_FETCH = 20;

type InputProps = RouteComponentProps;
type Props = QueryProps & InputProps;

interface State {
  isUpdateFundingAccountModalOpen: boolean;
}

class Expenses extends PureComponent<Props, State> {
  state: State = {
    isUpdateFundingAccountModalOpen: false,
  };

  handleCloseUpdateFundingAccountModal = async () => {
    this.setState({ isUpdateFundingAccountModalOpen: false });
    await this.props.refetch();
  };
  handleOpenUpdateFundingAccountModal = () => this.setState({ isUpdateFundingAccountModalOpen: true });

  render() {
    const balance = this.props.balance;
    if (!balance || this.props.isLoading) {
      return (
        <ManageStage isLoading={this.props.isLoading}>
          <Card>
            <Card.CardSection>
              <Center>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
                <Spinner />
              </Center>
            </Card.CardSection>
          </Card>
        </ManageStage>
      );
    }

    return (
      <ManageStage>
        <UpdateFundingAccountModal
          isOpen={this.state.isUpdateFundingAccountModalOpen}
          onClose={this.handleCloseUpdateFundingAccountModal}
        />
        <Breadcrumbs
          items={[
            { path: `/finances`, label: 'Finances' },
            { path: `/expenses`, label: 'Expenses' },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
        <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
          <div>
            <Heading
              content="Expenses"
              font={Heading.enums.Fonts.Secondary}
              isMarkdown
              size={Heading.enums.Sizes.Small}
            />
          </div>
          {/* <Button
            align={Button.enums.Alignments.Right}
            color={Button.enums.Colors.Gray}
            icon={Button.enums.Icons.Edit}
            label="Update payout settings"
            link={{
              path: `/settings/payouts`,
            }}
            size={Button.enums.Sizes.Small}
            style={Button.enums.Styles.Outline}
          /> */}
        </Row>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
        <Row flexBehavior={Row.enums.FlexBehaviors.Grow}>
          <div style={{ flex: '2', display: 'flex' }}>
            <Card
              width={Card.enums.Widths.Full}
              isLoading={this.props.isLoading}
              shadow={Card.enums.Shadows.Small}
            >
              <Card.CardSection spacing={Card.CardSection.enums.Spacing.Small}>
                <Heading
                  content="Operating balance"
                  color={Heading.enums.Colors.Secondary}
                  font={Heading.enums.Fonts.Secondary}
                  size={Heading.enums.Sizes.XXSmall}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                <Heading
                  content={`$${formatAsUSD(balance.availableAmountInCents / 100, true)} *USD*`}
                  font={Heading.enums.Fonts.Secondary}
                  isMarkdown
                  size={Heading.enums.Sizes.Medium}
                />
                <VerticalSpacing
                  quantity={2}
                  size={VerticalSpacing.enums.Sizes.Small}
                />
                <HorizontalRule />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
                <Row
                  flexBehavior={Row.enums.FlexBehaviors.Default}
                  verticalAlignment={Row.enums.VerticalAlignments.Center}
                >
                  <Text
                    color={Text.enums.Colors.Secondary}
                    content={`Min. balance requirement: $${formatAsUSD(balance.targetAmountInCents / 100)} USD`}
                    isMarkdown
                    shouldWrap={false}
                    size={Text.enums.Sizes.Small}
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXSmall} />
                  <InfoTooltip
                    size={14}
                    tooltipText={`The min. operating balance requirement is $${formatAsUSD(
                      balance.targetAmountInCents / 100,
                      true,
                    )} USD.`}
                  />
                </Row>
              </Card.CardSection>
            </Card>
          </div>
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Large} />
          <div style={{ flex: 3 }}>
            <Card
              isLoading={this.props.isLoading}
              shadow={Card.enums.Shadows.Small}
            >
              <Card.CardSection spacing={Card.CardSection.enums.Spacing.Small}>
                <Heading
                  content="Funding account"
                  color={Heading.enums.Colors.Secondary}
                  font={Heading.enums.Fonts.Secondary}
                  size={Heading.enums.Sizes.XXSmall}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                <DataGrid>
                  <DataGrid.DataGridColumn>
                    <DataGrid.DataGridRow label="Next top-up">
                      <Row
                        flexBehavior={Row.enums.FlexBehaviors.Shrink}
                        verticalAlignment={Row.enums.VerticalAlignments.Center}
                      >
                        <Text
                          shouldWrap={false}
                          size={Text.enums.Sizes.Medium}
                          content={`$${formatAsUSD(
                            Math.max(balance.targetAmountInCents - balance.availableAmountInCents, 0) / 100,
                            true,
                          )} USD on ${formatTimestamp(balance.nextTopupDueAt, 'MMM Do, YYYY')}`}
                        />
                        <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                        <InfoTooltip
                          tooltipText={`This amount returns the operating balance to the minimum required amount ($${formatAsUSD(
                            balance.targetAmountInCents / 100,
                            true,
                          )} USD).`}
                        />
                      </Row>
                    </DataGrid.DataGridRow>
                    <DataGrid.DataGridRow label="Payment method">
                      <InlinePaymentMethodVisual
                        type={InlinePaymentMethodVisual.enums.Types.US_BANK_ACCOUNT}
                        usBankAccount={{
                          last4: balance.defaultTopupSource.last4,
                          bankName: balance.defaultTopupSource.bankName,
                        }}
                      />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                      <ArrowLink
                        label="Change funding account"
                        onClick={this.handleOpenUpdateFundingAccountModal}
                        color={ArrowLink.enums.Colors.Blue}
                        size={ArrowLink.enums.Sizes.Small}
                      />
                    </DataGrid.DataGridRow>
                  </DataGrid.DataGridColumn>
                </DataGrid>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
              </Card.CardSection>
            </Card>
          </div>
        </Row>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
        <Card
          header={{
            title: 'Transactions',
          }}
        >
          <Table>
            <Table.TableHead>
              <Table.TableRow>
                <Table.TableHeader>Date</Table.TableHeader>
                <Table.TableHeader width={Table.TableHeader.enums.Widths.AUTO}>Description</Table.TableHeader>
                <Table.TableHeader />
                <Table.TableHeader width={Table.TableHeader.enums.Widths.AUTO}>Service</Table.TableHeader>
                <Table.TableHeader width={Table.TableHeader.enums.Widths.AUTO}>Unit</Table.TableHeader>
                <Table.TableHeader>Status</Table.TableHeader>
                <Table.TableHeader alignment={Table.TableHeader.enums.Alignments.RIGHT}>Amount</Table.TableHeader>
                <Table.TableHeader />
                <Table.TableHeader />
              </Table.TableRow>
            </Table.TableHead>
            <Table.TableBody>
              {_.sortBy(balance.balanceTransactions, (bt) => {
                const nowInSeconds = dayjs().unix();
                if (bt.issuedCardAuthorization?.status === IssuedCardAuthorizationStatuses.PENDING) {
                  return (nowInSeconds - dayjs(bt.createdAt).unix()) / 1000;
                }

                return nowInSeconds - dayjs(bt.createdAt).unix();
              }).map((balanceTransaction) => {
                const issue = balanceTransaction.issuedCardAuthorization?.issuedCard?.issue;
                const status = balanceTransaction.issuedCardAuthorization?.status ?? balanceTransaction.status;

                let textColor = Text.enums.Colors.Primary;
                if (status === IssuedCardAuthorizationStatuses.PENDING) textColor = Text.enums.Colors.Secondary;

                let amountTextColor = Text.enums.Colors.Secondary;
                if (
                  _.includes([BalanceTransactionStatuses.AVAILABLE, IssuedCardAuthorizationStatuses.CLOSED], status)
                ) {
                  amountTextColor = Text.enums.Colors.Primary;

                  if (balanceTransaction.amountInCents > 0) amountTextColor = Text.enums.Colors.Success;
                }

                let icon;
                let iconColor;
                let tagIcon = Tag.enums.Icons.Clock;
                let tagStatus = 'Pending';
                let tagColor = Tag.enums.Colors.Gray;
                let link;
                switch (issue?.serviceType) {
                  case ServiceTypes.PLUMBING: {
                    icon = IconBlock.enums.Icons.Droplet;
                    break;
                  }
                  case ServiceTypes.ELECTRICAL: {
                    icon = IconBlock.enums.Icons.Zap;
                    break;
                  }
                  case ServiceTypes.GENERAL: {
                    icon = IconBlock.enums.Icons.Wrench;
                    break;
                  }
                  default: {
                    icon = IconBlock.enums.Icons.Document;
                    break;
                  }
                }

                switch (balanceTransaction.type) {
                  case BalanceTransactionTypes.TOPUP: {
                    icon = IconBlock.enums.Icons.DollarSign;
                    iconColor = IconBlock.enums.Colors.Green;
                    if (balanceTransaction.status === BalanceTransactionStatuses.AVAILABLE) {
                      iconColor = IconBlock.enums.Colors.Gray;
                      tagIcon = Tag.enums.Icons.Check;
                      tagColor = Tag.enums.Colors.Green;
                      tagStatus = 'Completed';
                    }
                    break;
                  }
                  case BalanceTransactionTypes.ISSUING_AUTHORIZATION_HOLD: {
                    const issuedCardAuthorization = balanceTransaction.issuedCardAuthorization;
                    link = issuedCardAuthorization
                      ? {
                          path: `/authorizations/${issuedCardAuthorization.uuid}`,
                        }
                      : undefined;
                    if (balanceTransaction.issuedCardAuthorization?.status === IssuedCardAuthorizationStatuses.CLOSED) {
                      iconColor = IconBlock.enums.Colors.Gray;
                      tagColor = Tag.enums.Colors.Green;
                      tagIcon = Tag.enums.Icons.Check;
                      tagStatus = 'Completed';
                    }
                    break;
                  }
                }

                return (
                  <Table.TableRow
                    key={balanceTransaction.uuid}
                    link={link}
                  >
                    <Table.TableData>
                      <Text
                        color={textColor}
                        content={formatTimestamp(balanceTransaction.createdAt, 'MMM D, YYYY')}
                        overflow={Text.enums.OverflowValues.Ellipsis}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Small}
                      />
                    </Table.TableData>
                    <Table.TableData width={Table.TableData.enums.Widths.AUTO}>
                      <Text
                        color={textColor}
                        content={
                          balanceTransaction.issuedCardAuthorization?.statementDescriptor ||
                          balanceTransaction.description ||
                          ''
                        }
                        overflow={Text.enums.OverflowValues.Ellipsis}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Small}
                      />
                      {/* {balanceTransaction.issuedCardAuthorization?.issuedCard.providerCard ? (
                        <InlinePaymentMethodVisual
                          type={InlinePaymentMethodVisual.enums.Types.CARD}
                          card={{
                            last4: balanceTransaction.issuedCardAuthorization.issuedCard.providerCard.last4,
                            expMonth: balanceTransaction.issuedCardAuthorization.issuedCard.providerCard.expMonth,
                            expYear: balanceTransaction.issuedCardAuthorization.issuedCard.providerCard.expYear,
                            brand: balanceTransaction.issuedCardAuthorization.issuedCard.providerCard.brand,
                          }}
                        />
                      ) : null} */}
                    </Table.TableData>
                    <Table.TableData>
                      <IconBlock
                        icon={icon}
                        color={iconColor}
                        size={IconBlock.enums.Sizes.Small}
                      />
                    </Table.TableData>
                    <Table.TableData width={Table.TableData.enums.Widths.AUTO}>
                      {issue ? (
                        <Text
                          color={textColor}
                          content={_.startCase(_.toLower(issue.serviceType))}
                          size={Text.enums.Sizes.Small}
                        />
                      ) : (
                        <Text
                          color={Text.enums.Colors.Secondary}
                          content="-"
                          size={Text.enums.Sizes.Small}
                        />
                      )}
                    </Table.TableData>
                    <Table.TableData width={Table.TableData.enums.Widths.AUTO}>
                      {issue?.addressUnit ? (
                        <Text
                          color={textColor}
                          content={`${issue?.addressUnit.address.streetAddress1}${
                            issue?.addressUnit.name ? `, ${issue?.addressUnit.name}` : ''
                          }`}
                          size={Text.enums.Sizes.Small}
                        />
                      ) : (
                        <Text
                          color={Text.enums.Colors.Secondary}
                          content="-"
                          size={Text.enums.Sizes.Small}
                        />
                      )}
                    </Table.TableData>
                    <Table.TableData>
                      {/* {balanceTransaction.issuedCardAuthorization?.status === IssuedCardAuthorizationStatuses.PENDING ? (
                        <Icon
                          svg={Icon.enums.SVGs.Clock}
                          color={Icon.enums.Colors.Gray}
                          size={{
                            height: '16px',
                            width: '16px',
                          }}
                        />
                      ) : null}
                      {balanceTransaction.issuedCardAuthorization?.status === IssuedCardAuthorizationStatuses.CLOSED ? (
                        <Icon
                          svg={Icon.enums.SVGs.Check}
                          color={Icon.enums.Colors.Green}
                          size={{
                            height: '16px',
                            width: '16px',
                          }}
                        />
                      ) : null} */}
                      <Tag
                        color={tagColor}
                        icon={tagIcon}
                        label={tagStatus}
                        size={Tag.enums.Sizes.Small}
                      />
                    </Table.TableData>
                    <Table.TableData alignment={Table.TableData.enums.Alignments.RIGHT}>
                      <Text
                        color={amountTextColor}
                        content={`${balanceTransaction.amountInCents > 0 ? '+' : '−'}${
                          status !== IssuedCardAuthorizationStatuses.PENDING ? '*' : ''
                        }$${formatAsUSD(Math.abs(balanceTransaction.amountInCents) / 100, true)}${
                          status !== IssuedCardAuthorizationStatuses.PENDING ? '*' : ''
                        }`}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Medium}
                        isMarkdown
                      />
                    </Table.TableData>
                    <Table.TableData>
                      <Text
                        content="USD"
                        size={Text.enums.Sizes.Small}
                        color={Text.enums.Colors.Secondary}
                        shouldWrap={false}
                      />
                    </Table.TableData>
                  </Table.TableRow>
                );
              })}
              {balance.balanceTransactions.length === 0 ? (
                <Table.TableEmptyState label="Any future adjustments to your balance will be shown here!" />
              ) : null}
              {this.props.balanceTransactionsPageInfo ? (
                <Table.TablePaginator
                  startCursor={this.props.balanceTransactionsPageInfo.startCursor || ''}
                  endCursor={this.props.balanceTransactionsPageInfo.endCursor || ''}
                  resultsPerPage={NUMBER_OF_BALANCE_TRANSACTIONS_TO_FETCH}
                  totalResults={this.props.balanceTransactionsTotal}
                />
              ) : null}
            </Table.TableBody>
          </Table>
        </Card>
      </ManageStage>
    );
  }
}

interface PageInfo {
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
}

interface Response {
  viewer: {
    balance:
      | (BalanceType & {
          balanceTransactionsPageInfo: PageInfo;
          balanceTransactionsTotal: number;
        })
      | null;
    uuid: string;
  } | null;
}

interface QueryProps {
  balance: BalanceType | null;
  balanceTransactionsPageInfo?: PageInfo;
  balanceTransactionsTotal: number;
  isLoading: boolean;
  refetch: () => Promise<ApolloQueryResult<Response>>;
}

export default withQuery<InputProps, Response, {}, QueryProps>(query, {
  options: (props) => {
    const qs = queryString.parse(props.location.search);

    const page = _.parseInt(typeof qs.page === 'string' ? qs.page : '1') ?? undefined;
    const after = page ? pageToCursor(page, NUMBER_OF_BALANCE_TRANSACTIONS_TO_FETCH) : undefined;

    return {
      variables: {
        after,
        first: NUMBER_OF_BALANCE_TRANSACTIONS_TO_FETCH,
        filter: {
          type: {
            in: [BalanceTransactionTypes.ISSUING_AUTHORIZATION_HOLD, BalanceTransactionTypes.TOPUP],
          },
        },
      },
      ssr: true,
    };
  },
  props: (props) => ({
    isLoading: props.loading,
    balance: props.data?.viewer?.balance ?? null,
    balanceTransactionsPageInfo: props.data?.viewer?.balance?.balanceTransactionsPageInfo,
    balanceTransactionsTotal: props.data?.viewer?.balance?.balanceTransactionsTotal || 0,
    refetch: props.refetch,
  }),
})(Expenses);
