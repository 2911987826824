import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import DataRowItem from './components/DataRowItem';

import styles from './DataRow.scss';

interface Props {
  children: ReactNode;
}

function DataRow(props: Props) {
  return (
    <div styleName="data-row">
      {props.children}
    </div>
  );
}

DataRow.DataRowItem = DataRowItem;

export default withStyles(styles)(DataRow);
