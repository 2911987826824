import { canUseDOM } from 'exenv';

export const canUseDateInputType = () => {
  if (canUseDOM) {
    const input = document.createElement('input');
    input.setAttribute('type', 'date');
    const notADateValue = 'not-a-date';
    input.setAttribute('value', notADateValue);
    return input.value !== notADateValue;
  }
  return false;
};
