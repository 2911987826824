import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Spinner from '~tools/react/components/Spinner';

import * as enums from './enums';

import styles from './GenericLoadingVisual.scss';

interface Props {
  color?: enums.Colors;
  height?: string;
}

function GenericLoadingVisual(props: Props) {
  return (
    <div
      style={props.height ? {
        height: props.height,
        minHeight: props.height,
      } : undefined}
      styleName="loader">
      <Spinner color={props.color} />
    </div>
  );
}

export default withStyles(styles)(GenericLoadingVisual);
