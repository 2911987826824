import _ from 'lodash';
import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './ThreadView.scss';

interface Props {
  children: ReactNode;
}

function ThreadView(props: Props) {
  return (
    <div styleName="thread-view">
      {props.children}
    </div>
  );
}

export default withStyles(styles)(ThreadView);
