export {
  LabelFormats,
  SecondaryLabelColors,
} from '~tools/react/components/Form/components/FormField/enums';

export enum Sizes {
  Medium = 'MEDIUM',
  Large = 'LARGE',
}

export enum Types {
  Currency = 'CURRENCY',
  Date = 'DATE',
  DateRange = 'DATE_RANGE',
  DatetimeLocal = 'DATETIME_LOCAL',
  Email = 'EMAIL',
  Mask = 'MASK',
  Number = 'NUMBER',
  Password = 'PASSWORD',
  Percentage = 'PERCENTAGE',
  Tel = 'TEL',
  Text = 'TEXT',
  Time = 'TIME',
}

export enum Icons {
  LocationPin = 'LOCATION_PIN',
  MagnifyingGlass = 'MAGNIFYING_GLASS',
}
