import _ from 'lodash';
import React, { ComponentType } from 'react';
import { Route, Switch, withRouter } from 'react-router';

import App from '~web-manage/lib/common/App';

const Routes = withRouter(App);

function renderedRoutes(routes) {
  return _.map(
    routes,
    (Component, path) => (
      <Route
        component={Component}
        exact
        key={path}
        path={path}
      />
    ),
  );
}

interface Props {
  routes: {
    [route: string]: ComponentType,
  };
}

const RouteRenderer = (props: Props) => (
  <Routes>
    <Switch>
      {renderedRoutes(props.routes)}
    </Switch>
  </Routes>
);

export default RouteRenderer;
