import React, { Component } from 'react';

import { Typeahead } from '~tools/react/components/Form';
import { Props as TypeaheadProps } from '~tools/react/components/Form/components/Typeahead/Typeahead';
import withStyles from '~tools/react/hocs/withStyles';

import Cross from './svgs/cross.svg';
import styles from './ClearableTypeahead.scss';

interface Props extends TypeaheadProps {
  onClear: () => void;
}

interface State {
  hasSelectedItem: boolean;
}

class ClearableTypeahead extends Component<Props> {
  state: State = {
    hasSelectedItem: false,
  }

  handleBlur = (arg) => {
    if (this.props.onBlur) this.props.onBlur(arg);
  }

  handleClick = (arg) => {
    this.setState({ hasSelectedItem: true });
    this.props.onClick(arg);
  }

  handleChange = (arg: string) => {
    if (this.state.hasSelectedItem) this.setState({ hasSelectedItem: false });
    if (this.props.onChange) this.props.onChange(arg);
  }

  handleClear = () => {
    this.setState({ hasSelectedItem: false });
    this.props.onClear();
  }

  render() {
    return (
      <div styleName="clearable-typeahead">
        <Typeahead
          isLoading={this.props.isLoading}
          isReadOnly={this.props.isReadOnly || this.state.hasSelectedItem}
          isRequired={this.props.isRequired}
          items={this.props.items}
          label={this.props.label}
          name={this.props.name}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onClick={this.handleClick}
          placeholder={this.props.placeholder}
          value={this.props.value}
        />
        {this.state.hasSelectedItem ? (
          <button
            onClick={this.handleClear}
            type="button"
            styleName="clearable-typeahead__clear">
            <Cross />
          </button>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(ClearableTypeahead);
