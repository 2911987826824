import classNames from 'classnames';
import React, { Fragment, PureComponent } from 'react';

import Alert from '~tools/react/components/Alert';
import Button from '~tools/react/components/Button';
import { TextArea } from '~tools/react/components/Form';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import Dropzone, { DropzoneLoadCallback } from '~tools/react/containers/Dropzone';
import withStyles from '~tools/react/hocs/withStyles';

import { AccessInstruction } from '../../types';
import ResponsiveStepButtons from '../ResponsiveStepButtons';
import AccessFormPhoto from './components/AccessFormPhoto';
import styles from './AccessForm.scss';

export interface UploadedPhoto {
  key: string;
  bucketUrl: string;
  bucket: string;
}

interface Props {
  accessInstruction: AccessInstruction | null;
  defaultAccessInstructionNotes: string | null;
  isMobile: boolean;
  isShown: boolean;
  onCancel: () => void;
  onDeletePhoto: (uuid: string) => Promise<void>;
  onSubmit: (photos: UploadedPhoto[], locationDescription: string) => void;
}

interface State {
  locationDescription: string | null;
  uploadError: string | null;
  uploadedPhotos: UploadedPhoto[];
}

class AccessForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      locationDescription:
        props.accessInstruction ? props.accessInstruction.notes : props.defaultAccessInstructionNotes,
      uploadError: null,
      uploadedPhotos: [],
    };
  }

  private handleLocationDescriptionChange = (locationDescription: string) => this.setState({ locationDescription });

  private handleClickFinish = () => {
    if (!this.getAllPhotos().length || !this.state.locationDescription) return;
    this.props.onSubmit(this.state.uploadedPhotos, this.state.locationDescription);
  }

  private handleUploadError = (err: Error) => this.setState({ uploadError: err.message })

  private handleUploadPhoto: DropzoneLoadCallback = async (newPhoto: UploadedPhoto) => {
    this.setState(prevState => ({
      uploadError: null,
      uploadedPhotos: [...prevState.uploadedPhotos, newPhoto],
    }));
  }

  private handleDeletePhoto = async (photo: { url: string, uuid?: string }) => {
    if (photo.uuid) {
      await this.props.onDeletePhoto(photo.uuid);
    } else {
      this.setState(prevState => ({
        uploadedPhotos: prevState.uploadedPhotos.filter(p => this.getPhotoUrl(p) !== photo.url),
      }));
    }
  }

  render() {
    const photos = this.getAllPhotos();

    return (
      <Fragment>
        {this.state.uploadError && (
          <Fragment>
            <Alert
              color={Alert.enums.Colors.Red}
              title="Unable to upload photo"
              description="Please try again"
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          </Fragment>
        )}
        <div styleName="takeover-access-form__photos">
          {photos.map(photo => (
            <AccessFormPhoto
              photo={photo}
              key={photo.url}
              onDeletePhoto={this.handleDeletePhoto}
            />
          ))}
          <div styleName={classNames({
            'takeover-access-form__dropzone': true,
            'takeover-access-form__dropzone--small': photos.length > 0,
          })}>
            <Dropzone
              placeholder={!photos.length ? 'Upload Photo' : undefined}
              placeholderIcon={Dropzone.enums.Icons.PlusCircle}
              // Ensure the dropzone resets after we upload a photo
              key={photos.length}
              onError={this.handleUploadError}
              onLoad={this.handleUploadPhoto}
              uploadType={Dropzone.enums.UploadTypes.Image}
            />
          </div>
        </div>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
        <TextArea
          name="location"
          onChange={this.handleLocationDescriptionChange}
          placeholder="The smart lock is on the apartment door."
          value={this.state.locationDescription || undefined}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
        <ResponsiveStepButtons
          isMobile={this.props.isMobile}
          isShown={this.props.isShown}>
          <Button
            color={Button.enums.Colors.Gray}
            label="Go back"
            onClick={this.props.onCancel}
            style={Button.enums.Styles.Outline}
          />
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Medium} />
          <Button
            isDisabled={!photos.length || !this.state.locationDescription}
            label="Finish"
            onClick={this.handleClickFinish}
          />
        </ResponsiveStepButtons>
      </Fragment>
    );
  }

  private getPhotoUrl = (p: UploadedPhoto) => `${p.bucketUrl}${p.key}`;

  private getAllPhotos = (): { url: string, uuid?: string }[] => {
    const existingPhotos = this.props.accessInstruction ?
      this.props.accessInstruction.photos.map(p => ({ url: p.url, uuid: p.uuid })) :
      [];
    const newPhotos = this.state.uploadedPhotos.map(p => ({ url: this.getPhotoUrl(p) }));
    return [...existingPhotos, ...newPhotos];
  }
}

export default withStyles(styles)(AccessForm);
