import _ from 'lodash';
import queryString from 'query-string';

import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router';

import { PaymentSubscriptionPlans } from '~tools/types/graphqlSchema';

import Stage from '~web-core/lib/common/stages/Stage';

import analytics, { Events } from '~tools/utils/analytics';

import Globals from '~web-core/lib/common/globals';

import { compose } from '~tools/react/hocs/utils';
import withAuth, { AuthProps } from '~tools/react/hocs/withAuth';
import withDevice, { DeviceProps } from '~tools/react/hocs/withDevice';
import withCreateListing, { CreateListingProps } from '~tools/react/graphql/mutations/listings/withCreateListing';
import withFetchOrCreateListingForPropertyManagerContract, { FetchOrCreateListingForPropertyManagerContractProps } from '~tools/react/graphql/mutations/listings/withFetchOrCreateListingForPropertyManagerContract';
import withCreatePaymentSubscription, { CreatePaymentSubscriptionProps } from '~tools/react/graphql/mutations/paymentSubscriptions/withCreatePaymentSubscription';
import withCreatePropertyManagerContract, { CreatePropertyManagerContractProps } from '~tools/react/graphql/mutations/propertyManagerContracts/withCreatePropertyManagerContract';
import withValidatePropertyManagerContractEligibility, { ValidatePropertyManagerContractEligibilityProps } from '~tools/react/graphql/mutations/propertyManagerContracts/withValidatePropertyManagerContractEligibility';

import MarketingWidthConstraintView from '~tools/react/components/MarketingWidthConstraintView';
import Button from '~tools/react/components/Button';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import PricingTierCard, { Tiers } from '~tools/react/components/PricingTierCard';
import Row from '~tools/react/components/Row';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import WidthConstraintView from '~tools/react/components/WidthConstraintView';
import AuthModal from '~tools/react/containers/AuthModal';
import withQuery from '~tools/react/graphql/withQuery';

import ExistingUnitsRedirect from './containers/ExistingUnitsRedirect';

import ListersBrandShapes from './components/ListersBrandShapes';
import Logo from './components/Logo';
import TiersFooterView from './components/TiersFooterView';
import TiersView from './components/TiersView';

import query from './Start.gql';


interface QueryProps {
  addressUnit: {
    name: string | null;
    uuid: string;
    address: {
      city: string;
      googlePlaceId: string | null;
      latitude: number;
      longitude: number;
      neighborhood: string;
      standardizedAddress: string;
      state: string;
      streetAddress1: string;
      uuid: string;
      zipCode: string;
    };
  } | null;
  propertyManagers: {
    uuid: string;
  }[];
}

type InputProps = RouteComponentProps<{ addressUnitUuid: string }>;

type Props = RouteComponentProps & AuthProps &   InputProps & QueryProps & CreateListingProps &
CreatePaymentSubscriptionProps &
CreatePropertyManagerContractProps &
FetchOrCreateListingForPropertyManagerContractProps &
ValidatePropertyManagerContractEligibilityProps &
DeviceProps;

interface State {
  isLoading: boolean;
  selectedPlan?: Tiers;
  isAuthModalOpen: boolean;
  activeAuthModalView: string;


}

class Start extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      activeAuthModalView: AuthModal.enums.Views.Register,
      isAuthModalOpen: false,

    };
  }

  handleChangeAuthModalView = (view: string) => this.setState({ activeAuthModalView: view });
  handleCloseAuthModal = async () => {
    this.setState({ isAuthModalOpen: false });
    if (this.props.isLoggedIn) this.handleContinue();
  };

  handleContinue = async () => {
    const selectedPlan = this.state.selectedPlan;
    if (!selectedPlan) return;
if (selectedPlan !== Tiers.LITE){
    analytics.track(Events.SubmitSelectedPlan, { plan: _.startCase(_.toLower(selectedPlan)) });
    const urlQuery = queryString.stringify({ plan: _.toLower(selectedPlan) });
    this.props.history.push(`/landlord/start?${urlQuery}`);
    return;
} else if (!this.props.isLoggedIn) {
  analytics.track(Events.SubmitSelectedPlan, { plan: _.startCase(_.toLower(selectedPlan)) });

  this.props.history.push(`/lite/start`);
  return;
}

this.setState({ isLoading: true });
try {
  await this.props.createPaymentSubscription({
    plan: PaymentSubscriptionPlans.LITE,
    quantity: 1,
  });
  const flipPropertyManager = this.props.propertyManagers[0];
  const propertyManagerContract = await this.props.createPropertyManagerContract({
    addressUnitUuid: this.props.match.params.addressUnitUuid,
    propertyManagerUuid: flipPropertyManager.uuid,
    startsAt: new Date().toString(),
  });
  const listing = await this.props.fetchOrCreateListingForPropertyManagerContract({
    propertyManagerContractUuid: propertyManagerContract.uuid,
  });
  this.props.history.push(`/listings/${listing.uuid}/rooms`);
} catch (err) {
  this.setState({ isLoading: false });
  throw err;
}
}

  handleSelectPlan = (selectedPlan: Tiers) => {
    if (this.state.selectedPlan === selectedPlan) {
      this.setState({ selectedPlan: undefined });
      return;
    }

    this.setState({ selectedPlan });

    analytics.track(Events.SelectPlan, { plan: _.startCase(_.toLower(selectedPlan)) });
  };

  render() {
    const selectedPlan = this.state.selectedPlan;
    return (
      <Stage
        alert={null}
        hasAppBanner={false}
        hasFooter={false}
        hasNavigation={false}
        shouldLoadSubscriptions={false}
      >
         <AuthModal
          activeView={this.state.activeAuthModalView}
          isOpen={this.state.isAuthModalOpen}
          onChangeView={this.handleChangeAuthModalView}
          onClose={this.handleCloseAuthModal}
        />
        <ExistingUnitsRedirect />
        <WidthConstraintView size={WidthConstraintView.enums.Sizes.Large}>
          <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
            <Logo />
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                height: '70px',
                justifyContent: 'center',
              }}
            >
              <Button
                label="Go back"
                link={{
                  path: `${Globals.WEB_CORE_DOMAIN}`,
                }}
                style={Button.enums.Styles.Secondary}
                size={Button.enums.Sizes.Small}
              />
            </div>
          </Row>
        </WidthConstraintView>
        <WidthConstraintView>
          <MarketingWidthConstraintView>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
            <Heading
              isMarkdown
              align={Heading.enums.Align.Center}
              content="Choose the plan that's right for you."
              size={this.props.isMobile ? Heading.enums.Sizes.Large : Heading.enums.Sizes.XLarge}
            />
            {/* <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} /> */}
            {/* <Heading
              isMarkdown
              align={Heading.enums.Align.Center}
              content="Have questions? Give us a call and we'll help you choose the right plan: [1-844-888-3547](tel:844-888-3547)."
              font={Heading.enums.Fonts.Secondary}
              size={this.props.isMobile ? Heading.enums.Sizes.XXSmall : Heading.enums.Sizes.Small}
            /> */}
            {/* <Alert
              color={Alert.enums.Colors.Blue}
              description="Want help deciding? Call [1-844-888-3547](tel:844-888-3547)"
            /> */}
            <VerticalSpacing
              quantity={2}
              size={VerticalSpacing.enums.Sizes.XLarge}
            />
            <TiersView>
              <div style={{ position: 'relative', zIndex: 1, backgroundColor: '#FFF' }}>
                <Row flexBehavior={Row.enums.FlexBehaviors.Grow}>
                  <PricingTierCard
                    hasFeatures={!this.props.isMobile}
                    isFaded={selectedPlan && selectedPlan !== PricingTierCard.enums.Tiers.LITE}
                    isSelected={selectedPlan === PricingTierCard.enums.Tiers.LITE}
                    onClick={this.handleSelectPlan}
                    tier={PricingTierCard.enums.Tiers.LITE}
                  />
                  {/* TODO - Put a big ol "FREE tag" by the Starter header */}
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Medium} />
                  <PricingTierCard
                    hasFeatures={!this.props.isMobile}
                    isFaded={selectedPlan && selectedPlan !== PricingTierCard.enums.Tiers.BASIC}
                    isSelected={selectedPlan === PricingTierCard.enums.Tiers.BASIC}
                    onClick={this.handleSelectPlan}
                    tier={PricingTierCard.enums.Tiers.BASIC}
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Medium} />
                  <PricingTierCard
                    hasAnchorLink
                    hasFeatures={!this.props.isMobile}
                    isFaded={
                      selectedPlan && selectedPlan !== PricingTierCard.enums.Tiers.ENTERPRISE
                    }
                    isSelected={selectedPlan === PricingTierCard.enums.Tiers.ENTERPRISE}
                    tier={PricingTierCard.enums.Tiers.ENTERPRISE}
                  />
                </Row>
              </div>
              {!this.props.isMobile ? <ListersBrandShapes /> : null}
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
              <TiersFooterView isOpen={!!selectedPlan}>
                <Button
                  align={Button.enums.Alignments.Right}
                  icon={Button.enums.Icons.ChevronRight}
                  isDisabled={!this.state.selectedPlan}
                  isLoading={this.state.isLoading}
                  label="Continue"
                  onClick={this.handleContinue}
                  type={Button.enums.Types.Button}
                  width={this.props.isMobile ? Button.enums.Widths.Full : undefined}
                />
              </TiersFooterView>
            </TiersView>
          </MarketingWidthConstraintView>
        </WidthConstraintView>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
      </Stage>
    );
  }
}
interface Variables {
  uuid: string;
}
type Response = QueryProps;

export default compose(withAuth, withDevice,
  withCreateListing,
  withCreatePaymentSubscription,
  withCreatePropertyManagerContract,
  withFetchOrCreateListingForPropertyManagerContract,
  withValidatePropertyManagerContractEligibility,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: ownProps => ({
      variables: {
        uuid: ownProps.match.params.addressUnitUuid,
      },
    }),
    props: (props) => ({
      addressUnit: props.data?.addressUnit ?? null,
      isLoading: props.loading,
      propertyManagers: props.data?.propertyManagers ?? [],
    }),
  }),

)(Start);
