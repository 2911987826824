import classNames from 'classnames';
import _ from 'lodash';
import React, { ComponentType, Fragment, MouseEvent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Button from '~tools/react/components/Button';

import * as enums from './enums';

import styles from './ListingSetupNavbar.scss';

interface Action {
  onClick: (e: MouseEvent<EventTarget>) => void;
  label: string;
  isLoading?: boolean;
  isDisabled?: boolean;
}

interface Link {
  path: string;
  label: string;
}

interface BaseProps {
  marginTopSize?: enums.MarginTopSize;
}

interface ActionProps extends BaseProps {
  primaryAction: Action;
  secondaryAction?: Action;
  type: enums.Types.Action;
}

interface LinkProps extends BaseProps {
  primaryLink: Link;
  secondaryLink?: Link;
  type: enums.Types.Link;
}

type Props = ActionProps | LinkProps;

function ListingSetupNavbar(props: Props) {
  return (
    <Fragment>
      <div styleName="manage-listing-navbar-spacing" />
      <div
        className={classNames({
          [styles['manage-listing-navbar']]: true,
          [styles[`manage-listing-navbar-margin-${_.kebabCase(props.marginTopSize)}`]]: props.marginTopSize,
        })}>
        {'secondaryLink' in props || 'secondaryAction' in props ? (
          <Button
            color={Button.enums.Colors.Gray}
            isDisabled={props.type === enums.Types.Action ? props.secondaryAction?.isDisabled : undefined}
            label={props.type === enums.Types.Action ? props.secondaryAction?.label : props.secondaryLink?.label}
            onClick={props.type === enums.Types.Action ? props.secondaryAction?.onClick : undefined}
            type={Button.enums.Types.Button}
            style={Button.enums.Styles.Outline}
          />
        ) : null}
        <Button
          isDisabled={props.type === enums.Types.Action ? props.primaryAction.isDisabled : undefined}
          isLoading={props.type === enums.Types.Action ? props.primaryAction.isLoading : undefined}
          label={
            (props.type === enums.Types.Action ? props.primaryAction.label : props.primaryLink.label) || 'Continue'
          }
          link={props.type === enums.Types.Link ? { path: props.primaryLink?.path } : undefined}
          onClick={props.type === enums.Types.Action ? props.primaryAction.onClick : undefined}
          type={Button.enums.Types.Submit}
        />
      </div>
    </Fragment>
  );
}

ListingSetupNavbar.defaultProps = {
  marginTopSize: enums.MarginTopSize.Large,
  type: enums.Types.Link,
};
ListingSetupNavbar.enums = enums;

export default withStyles(styles)(ListingSetupNavbar) as ComponentType<Props>;
