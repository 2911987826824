import React, { ComponentType, PureComponent } from 'react';

import { compose } from '~tools/react/hocs/utils';
import Button from '~tools/react/components/Button';
import Dropzone from '~tools/react/containers/Dropzone';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Link from '~tools/react/components/utility/Link';
import Row from '~tools/react/components/Row';
import ThemedModal from '~tools/react/components/ThemedModal';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import withCreateDocument, { CreateDocumentProps, DocumentTags } from '~tools/react/graphql/mutations/documents/withCreateDocument';
import withCreateUpload, { CreateUploadProps, UploadTags } from '~tools/react/graphql/mutations/uploads/withCreateUpload';
import withUpdateLease, { UpdateLeaseProps } from '~tools/react/graphql/mutations/leases/withUpdateLease';

interface InputProps {
  applicationUuid: string;
  leaseUuid: string;
  onClose: () => void;
  onSubmit: () => void;
  isOpen: boolean;
}

interface State {
  awsUpload: {
    file: File;
    data: {
      key: string;
      bucketUrl: string;
      bucket: string;
    };
  } | null;
  isLoading: boolean;
}

type Props = InputProps & CreateUploadProps & CreateDocumentProps & UpdateLeaseProps;

class UploadDocumentModal extends PureComponent<Props, State> {
  state: State = {
    awsUpload: null,
    isLoading: false,
  };

  handleClickConfirm = async () => {
    this.setState({ isLoading: true });

    const awsUpload = this.state.awsUpload;
    if (!awsUpload) return;
    const { file, data } = awsUpload;

    const upload = await this.props.createUpload({
      filename: file.name,
      fileType: file.type,
      url: `${data.bucketUrl}${data.key}`,
      tag: UploadTags.CUSTOM,
    });

    const document = await this.props.createDocument({
      displayName: file.name,
      filename: file.name,
      flipUuid: this.props.applicationUuid,
      isSigningEnabled: true,
      tag: DocumentTags.UPLOAD,
      uploadUuid: upload.uuid,
    });

    await this.props.updateLease(this.props.leaseUuid, {
      documentUuid: document.uuid,
    });

    this.setState({ isLoading: false });
    this.props.onSubmit();
    this.props.onClose();
  }

  handleUploadDocument = async (data: {
    key: string;
    bucketUrl: string;
    bucket: string;
  }, file: File) => {
    this.setState({
      awsUpload: {
        data,
        file,
      },
    });
  }

  handleRemoveUplaod = async () => {
    this.setState({ awsUpload: null });
  };

  render() {
    // Rounded to 2 decimals
    const fileSizeMB = this.state.awsUpload ?
      (Math.round((this.state.awsUpload.file.size / 1000000) * 100) / 100).toFixed(2) :
      '';

    return (
      <ThemedModal
        onClose={this.props.onClose}
        width={ThemedModal.enums.Widths.Medium}
        title="Upload custom lease"
        isOpen={this.props.isOpen}>
        <ThemedModal.ThemedModalSection>
          {this.state.awsUpload ? (
            <Row
              flexBehavior={Row.enums.FlexBehaviors.Default}
              verticalAlignment={Row.enums.VerticalAlignments.Center}>
              <div>
                <Link
                  shouldOpenNewTab={true}
                  to={`${this.state.awsUpload.data.bucketUrl}${this.state.awsUpload.data.key}`}
                >
                  <Heading
                    content={this.state.awsUpload.file.name}
                    color={Heading.enums.Colors.Brand}
                    font={Heading.enums.Fonts.Secondary}
                    priority={Heading.enums.Priorities.Five}
                    size={Heading.enums.Sizes.XXSmall}
                  />
                </Link>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                <Heading
                  content={`${fileSizeMB} MB`}
                  font={Heading.enums.Fonts.Secondary}
                  priority={Heading.enums.Priorities.Two}
                  size={Heading.enums.Sizes.XXXSmall}
                />
              </div>
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
              <Button
                align={Button.enums.Alignments.Right}
                color={Button.enums.Colors.Red}
                onClick={this.handleRemoveUplaod}
                size={Button.enums.Sizes.XSmall}
                style={Button.enums.Styles.Outline}
                label="Remove"
              />
            </Row>
          ) : null}
          {!this.state.awsUpload ? (
            <>
              <Text
                content="Upload a custom PDF for the applicant to sign for this unit. This will replace the lease Caretaker generates."
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              <Dropzone
                placeholder="Upload document"
                placeholderIcon={Dropzone.enums.Icons.PlusCircle}
                onLoad={this.handleUploadDocument}
                uploadType={Dropzone.enums.UploadTypes.Document}
              />
            </>
          ) : null}
        </ThemedModal.ThemedModalSection>
        <ThemedModal.ThemedModalFooter isStickyOnMobile>
          <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
            <Button
              align={Button.enums.Alignments.Right}
              color={Button.enums.Colors.Blue}
              isLoading={this.state.isLoading}
              isDisabled={!this.state.awsUpload}
              label="Confirm"
              onClick={this.handleClickConfirm}
              size={Button.enums.Sizes.Medium}
            />
          </Row>
        </ThemedModal.ThemedModalFooter>
      </ThemedModal>
    );
  }
}

export default compose(
  withCreateDocument,
  withCreateUpload,
  withUpdateLease,
)(UploadDocumentModal) as ComponentType<InputProps>;
