import withMutation from '~tools/react/graphql/withMutation';

import DeleteRentalPolicyMutation from './deleteRentalPolicy.gql';

interface Data {
  deleteRentalPolicy: boolean;
}

interface Variables {
  uuid: string;
}

export interface DeleteRentalPolicyProps {
  deleteRentalPolicy: (uuid: string) => Promise<boolean>;
}

export default withMutation<{}, Data, Variables, DeleteRentalPolicyProps>(DeleteRentalPolicyMutation, {
  props: props => ({
    deleteRentalPolicy: async (uuid: string) => {
      const res = await props.mutate({
        variables: { uuid },
      });
      if (!res || !res.data) throw new Error('Error deleting rental policy.');

      return res.data.deleteRentalPolicy;
    },
  }),
});
