import withMutation from '~tools/react/graphql/withMutation';

import UpdateOrganizationMutation from '../updateOrganization.gql';

interface Organization {
  email: string | null;
  name: string;
  summary: string | null;
  uuid: string;
  website: string | null;
  organizationPersons: {
    uuid: string;
    person: {
      email: string | null;
      firstName: string;
      lastName: string | null;
      uuid: string;
    };
  }[];
  phoneNumbers: {
    phoneNumber: string;
    type: string | null;
    uuid: string;
  }[];
  propertyManager: {
    uuid: string;
  } | null;
}

interface UpdateOrganizationInput {
  email?: string;
  logoPhotoUuid?: string | null;
  name?: string;
  summary?: string;
  website?: string;
}

interface Data {
  updateOrganization: Organization;
}

interface Variables {
  input: UpdateOrganizationInput;
  uuid: string;
}

export interface UpdateOrganizationProps {
  updateOrganization: (uuid: string, input: UpdateOrganizationInput) => Promise<Organization>;
}

export default withMutation<{}, Data, Variables, UpdateOrganizationProps>(UpdateOrganizationMutation, {
  props: props => ({
    updateOrganization: async (uuid, input) => {
      const res = await props.mutate({
        variables: {
          input,
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error updating organization.');

      return res.data.updateOrganization;
    }
  }),
});
