import withMutation from '~tools/react/graphql/withMutation';

import CancelPaymentSubscriptionMutation from './cancelPaymentSubscription.gql';

interface Response {
  uuid: string;
}

interface Data {
  cancelPaymentSubscription: Response;
}

interface Variables {
  uuid: string;
}

export interface CancelPaymentSubscriptionProps {
  cancelPaymentSubscription: (uuid: string) => Promise<Response>;
}

export default withMutation<{}, Data, Variables, CancelPaymentSubscriptionProps>(CancelPaymentSubscriptionMutation, {
  props: props => ({
    cancelPaymentSubscription: async (uuid: string) => {
      const res = await props.mutate({
        variables: {
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error cancelling payment subscription.');

      return res.data.cancelPaymentSubscription;
    },
  }),
});
