import withMutation from '~tools/react/graphql/withMutation';
import { CreateVoteInputType } from '~tools/types/graphqlSchema';

import CreateVoteMutation from './createVote.gql';

interface Response {
  createVote: {
    uuid: string;
    sentiment: number;
  };
}

export interface CreateVoteProps {
  createVote: (input: CreateVoteInputType) => Promise<{ uuid: string }>;
}

export default withMutation<{}, Response, { input: CreateVoteInputType }, CreateVoteProps>(CreateVoteMutation, {
  props: props => ({
    createVote: async (input: CreateVoteInputType) => {
      const res = await props.mutate({
        variables: { input },
      });

      if (!res || !res.data) throw new Error('Error in creating vote');
      if (!res.data.createVote) throw new Error('No createVote returned');

      return res.data.createVote;
    },
  }),
});
