import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import ChevronDown from '~tools/svgs/icons/interface/chevron-down.svg';
import ChevronUp from '~tools/svgs/icons/interface/chevron-up.svg';
import PlusCircle from '~tools/svgs/icons/interface/plus-circle.svg';

import Spinner from '~tools/react/components/utility/Spinner';

import * as enums from './enums';

import styles from './PaymentSourceButton.scss';

const Icons = {
  [enums.Icons.ChevronDown]: ChevronDown,
  [enums.Icons.ChevronUp]: ChevronUp,
  [enums.Icons.PlusCircle]: PlusCircle,
};

interface Props {
  icon: enums.Icons;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick: (e: React.MouseEvent) => void;
  style: enums.Styles;
}

function PaymentSourceButton(props: Props) {
  const Icon = Icons[props.icon];

  return (
    <button
      disabled={props.isLoading || props.isDisabled}
      onClick={props.onClick}
      className={classNames({
        [styles['payment-source-button']]: true,
        [styles[`payment-source-button--${_.kebabCase(props.style)}`]]: props.style,
      })}>
      {`${props.style === enums.Styles.Add ? 'Add' : 'Select'} bank account`}
      {props.isLoading ? (
        <Spinner
          styleName="payment-source-button__svg"
          width={35}
          height={24}
        />
      ) : (
        <Icon styleName="payment-source-button__svg" />
      )}
    </button>
  );
}

PaymentSourceButton.defaultProps = {
  isDisabled: false,
  isLoading: false,
};

export default _.assign(withStyles(styles)(PaymentSourceButton), { enums });
