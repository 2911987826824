export enum Formats {
  Button = 'BUTTON_ONLY',
  Form = 'FORM',
  Text = 'TEXT',
}

export enum Statuses {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Verified = 'VERIFIED',
}
