// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._qrox62{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;-webkit-box-pack:center;justify-content:center;padding-bottom:16px;text-align:left;width:90%}._qrox62._GN6aPR{border-bottom:1px solid #e6e8eb}._qrox62 ._5aAN8D{-webkit-box-align:center;align-items:center;color:#8492a6;display:-webkit-box;display:flex;font-size:14px;font-weight:300;-webkit-box-pack:start;justify-content:flex-start;line-height:22.4px;margin:0 auto 24px 0}._qrox62 ._5aAN8D svg{height:20px;margin:-2px 4px 0 0;width:20px}._qrox62 ._5aAN8D:hover{color:#273444}", ""]);
// Exports
exports.locals = {
	"vertical-menu-header": "_qrox62",
	"vertical-menu-header--with-border": "_GN6aPR",
	"vertical-menu-header__back-link": "_5aAN8D"
};
module.exports = exports;
