export enum DayTitles {
  Sunday = 'SU',
  Monday = 'MO',
  Tuesday = 'TU',
  Wednesday = 'WE',
  Thursday = 'TH',
  Friday = 'FR',
  Saturday = 'SA',
}

export enum KeyConstants {
  Backspace = 8,
  Down = 40,
  Enter = 13,
  Esc = 27,
  Left = 37,
  Right = 39,
  Up = 38,
}

export enum Views {
  Days = 0,
  Months = 1,
  Years = 2,
}
