// @flow

import classNames from 'classnames';
import _ from 'lodash';
import React, { PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './SkeletonDisplayText.scss';

import * as enums from './enums';

interface Props {
  size?: enums.Sizes;
  width?: enums.Widths;
}

class SkeletonDisplayText extends PureComponent<Props> {
  static defaultProps = {
    size: enums.Sizes.Medium,
  };

  static enums = enums;

  render() {
    return (
      <div
        styleName={classNames({
          'skeleton-display-text': true,
          [`skeleton-display-text--size-${_.kebabCase(this.props.size)}`]: this.props.size,
          [`skeleton-display-text--width-${_.kebabCase(this.props.width)}`]: this.props.width,
        })}
      />
    );
  }
}

export default withStyles(styles)(SkeletonDisplayText);
