// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._NRBdVj{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;margin-bottom:32px}@media screen and (max-width:915px){._NRBdVj{display:none}}._NRBdVj>h5{color:#fff;font-size:12px;margin:0;opacity:.5;padding-bottom:12px;text-transform:uppercase}._NRBdVj>h5:not(:first-child){margin-top:24px}", ""]);
// Exports
exports.locals = {
	"modal-filters-section": "_NRBdVj"
};
module.exports = exports;
