import { CreateListingInputType } from '~tools/types/graphqlSchema';
import withMutation from '~tools/react/graphql/withMutation';

import CreateListingMutation from './createListing.gql';

interface Listing {
  bathrooms: number | null;
  bedrooms: number | null;
  description: string | null;
  earliestMoveInDate: string | null;
  headline: string | null;
  leaseEndDate: string | null;
  listingType: string | null;
  rentInCents: number | null;
  slug: string | null;
  status: string | null;
  uuid: string;
  addressUnit: {
    name: string | null;
    uuid: string;
    address: {
      city: string;
      latitude: number;
      longitude: number;
      neighborhood: string;
      state: string;
      streetAddress1: string;
      uuid: string;
    };
  } | null;
  building: {
    uuid: string;
  };
  photos: {
    position: number;
    url: string;
    uuid: string;
  }[];
  amenities: {
    uuid: string;
    name: string;
  }[];
}

interface Data {
  createListing: Listing;
}

interface Variables {
  input: CreateListingInputType;
}

export interface CreateListingProps {
  createListing: (input: CreateListingInputType) => Promise<Listing>;
}

export default withMutation<{}, Data, Variables, CreateListingProps>(CreateListingMutation, {
  props: props => ({
    createListing: async (input) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating listing.');

      return res.data.createListing;
    },
  }),
});
