// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._5aAup0{background:#fff;border-radius:5px;color:#273444;margin-bottom:40px;min-width:420px;width:100%}@media screen and (max-width:500px){._5aAup0{min-width:auto}}._5aAup0 h1,._5aAup0 p{margin:0;padding:0}._5aAup0 ul{margin:0;padding-left:0}._5aAup0 ul li{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;margin-bottom:12px}._5aAup0 ul li:before{color:#273444;content:\"•\";padding-right:16px}._5aAup0 ul li a{-webkit-box-align:center;align-items:center;color:#fff;display:-webkit-box;display:flex;margin-left:auto}._5aAup0 h1{font-family:DomaineText;font-weight:300}._5aAup0 h5{border-bottom:1px solid #e6e8eb;color:#8492a6;font-size:12px;margin-bottom:12px;margin-top:0;padding-bottom:12px;text-transform:uppercase}._5aAup0 h5:not(:first-child){padding-top:24px}._5aAup0 p{font-size:16px;margin:8px 0}", ""]);
// Exports
exports.locals = {
	"incomplete-fields": "_5aAup0"
};
module.exports = exports;
