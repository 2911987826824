// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._qro1Be{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-pack:center;justify-content:center}._qro1Be._J2VxxO,._qro1Be._MOJppM,._qro1Be._qroAmD,._qro1Be._br0kQ1{background-color:#fff;border-radius:3px;box-shadow:0 0 0 1px rgba(24,32,41,.05),0 2px 5px 0 rgba(24,32,41,.05),0 1px 2px 0 rgba(0,0,0,.05);color:#273444;height:20px;width:35px}._qro1Be._J2VxxO{border:1px solid #0193ce}._qro1Be._J2VxxO ._drDFq6{color:#0193ce;height:19px}._qro1Be._MOJppM{border:1px solid #f27712}._qro1Be._MOJppM ._drDFq6{width:30px}._qro1Be._qroAmD{border:1px solid #c0ccda}._qro1Be._qroAmD ._drDFq6{height:13px}._qro1Be._br0kQ1{border:1px solid #00579f}._qro1Be._br0kQ1 ._drDFq6{color:#00579f;height:17px}._qro1Be._QDo26w{border-radius:3px;box-shadow:0 0 0 1px rgba(24,32,41,.05),0 2px 5px 0 rgba(24,32,41,.05),0 1px 2px 0 rgba(0,0,0,.05);height:20px;width:20px}._qro1Be._QDo26w ._drDFq6{color:#8492a6;height:16px;width:16px}", ""]);
// Exports
exports.locals = {
	"icon": "_qro1Be",
	"icon--american-express": "_J2VxxO",
	"icon--discover": "_MOJppM",
	"icon--master-card": "_qroAmD",
	"icon--visa": "_br0kQ1",
	"icon__svg": "_drDFq6",
	"icon--bank": "_QDo26w"
};
module.exports = exports;
