import moment from 'moment';
import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './ThreadDateDivider.scss';

interface Props {
  timestamp: string;
  zIndex?: number;
}

function ThreadDateDivider(props: Props) {
  const time = moment(props.timestamp);
  return (
    <div style={{ zIndex: props.zIndex }} styleName="thread-date">
      <span>{time.format('MMM D')}</span>
    </div>
  );
}

export default withStyles(styles)(ThreadDateDivider);
