import React, { ReactNode } from 'react';

import Text from '~tools/react/components/Text';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './QandA.scss';

interface Props {
  question: string;
  children: ReactNode;
}

function QandA(props: Props) {
  return (
    <section styleName="q-and-a">
      <header styleName="q-and-a__question">
        <Text
          content={props.question}
          size={Text.enums.Sizes.Large}
        />
      </header>
      <div styleName="q-and-a__answer">
        {props.children}
      </div>
    </section>
  );
}

export default withStyles(styles)(QandA);
