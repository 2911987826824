import _ from 'lodash';
import classNames from 'classnames';
import React, { Fragment, PureComponent } from 'react';

import Alert from '~tools/react/components/Alert';
import Button from '~tools/react/components/Button';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import ThemedModal from '~tools/react/components/ThemedModal';
import Dropzone, { DropzoneLoadCallback } from '~tools/react/containers/Dropzone';
import withStyles from '~tools/react/hocs/withStyles';

import AccessFormPhoto from './components/AccessFormPhoto';

import styles from './AccessPhotosModal.scss';

interface Photo {
  uuid: string;
  createdAt: string;
  url: string;
}

export interface PhotoUpload {
  key: string;
  bucketUrl: string;
  bucket: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onDeletePhoto: (uuid: string) => Promise<void>;
  onSave: (photos: PhotoUpload[]) => Promise<void>;
  photos: Photo[];
}

interface State {
  isSaving: boolean;
  uploadError: string | null;
  uploadedPhotos: PhotoUpload[];
}

class AccessPhotosModal extends PureComponent<Props, State> {
  state: State = {
    isSaving: false,
    uploadError: null,
    uploadedPhotos: [],
  };

  handleDeletePhoto = async (photo: { url: string, uuid?: string }) => {
    if (photo.uuid) {
      await this.props.onDeletePhoto(photo.uuid);
    } else {
      this.setState(prevState => ({
        uploadedPhotos: prevState.uploadedPhotos.filter(p => this.getPhotoUrl(p) !== photo.url),
      }));
    }
  }

  handleUploadError = (err: Error) => this.setState({ uploadError: err.message })

  handleClickFinish = async () => {
    if (!this.getAllPhotos().length) return;

    this.setState({ isSaving: true });
    await this.props.onSave(this.state.uploadedPhotos);
    this.setState({
      isSaving: false,
      uploadedPhotos: [],
    });
    this.props.onClose();
  }

  handleUploadPhoto: DropzoneLoadCallback = async (newPhoto: PhotoUpload) => {
    this.setState(prevState => ({
      uploadError: null,
      uploadedPhotos: [...prevState.uploadedPhotos, newPhoto],
    }));
  }

  render() {
    const photos = this.getAllPhotos();

    return (
      <ThemedModal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        subtitle="Add or delete photos that show how to access the apartment or smart lock"
        title="Edit access photos"
        width={ThemedModal.enums.Widths.Medium}>
        <ThemedModal.ThemedModalSection>
          {this.state.uploadError && (
            <Fragment>
              <Alert
                color={Alert.enums.Colors.Red}
                title="Unable to upload photo"
                description="Please try again"
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
            </Fragment>
          )}
          <div styleName="access-photos-modal__photos">
            {photos.map(photo => (
              <AccessFormPhoto
                photo={photo}
                key={photo.url}
                onDeletePhoto={this.handleDeletePhoto}
              />
            ))}
            <div styleName={classNames({
              'access-photos-modal__dropzone': true,
              'access-photos-modal__dropzone--small': photos.length > 0,
            })}>
              <Dropzone
                placeholder={!photos.length ? 'Upload Photo' : undefined}
                placeholderIcon={Dropzone.enums.Icons.PlusCircle}
                // Ensure the dropzone resets after we upload a photo
                key={photos.length}
                onError={this.handleUploadError}
                onLoad={this.handleUploadPhoto}
                uploadType={Dropzone.enums.UploadTypes.Image}
              />
            </div>
          </div>
        </ThemedModal.ThemedModalSection>
        <ThemedModal.ThemedModalFooter>
          <Button
            isDisabled={this.state.isSaving}
            isLoading={this.state.isSaving}
            label="Save and close"
            onClick={this.handleClickFinish}
            width={Button.enums.Widths.Full}
          />
        </ThemedModal.ThemedModalFooter>
      </ThemedModal>
    );
  }

  getPhotoUrl = (p: PhotoUpload) => `${p.bucketUrl}${p.key}`;

  getAllPhotos = (): { url: string, uuid?: string }[] => {
    const existingPhotos = _.sortBy(this.props.photos, 'createdAt')
      .map(p => ({ url: p.url, uuid: p.uuid }));
    const newPhotos = this.state.uploadedPhotos.map(p => ({ url: this.getPhotoUrl(p) }));
    return [...existingPhotos, ...newPhotos];
  }
}

export default withStyles(styles)(AccessPhotosModal);
