import withMutation from '~tools/react/graphql/withMutation';

import { CreateQualificationInput } from '~tools/types/graphqlAdminSchema';

import CreateQualificationMutation from './createQualification.gql';

export interface Qualification {
  uuid: string;
}

interface Data {
  createQualification: Qualification;
}

interface Variables {
  input: CreateQualificationInput;
}

export interface CreateQualificationProps {
  createQualification: (input: CreateQualificationInput) => Promise<Qualification>;
}

export default withMutation<{}, Data, Variables, CreateQualificationProps>(CreateQualificationMutation, {
  props: props => ({
    createQualification: async (input) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating qualification.');

      return res.data.createQualification;
    },
  }),
});
