import withMutation from '~tools/react/graphql/withMutation';

import TerminatePropertyManagerContractMutation from './terminatePropertyManagerContract.gql';

interface PropertyManagerContract {
  endsAt: string | null;
  uuid: string;
}

interface Data {
  terminatePropertyManagerContract: PropertyManagerContract;
}

interface Variables {
  uuid: string;
}

export interface TerminatePropertyManagerContractProps {
  terminatePropertyManagerContract: (contractUuid: string) => Promise<PropertyManagerContract>;
}

export default withMutation<{}, Data, Variables, TerminatePropertyManagerContractProps>(TerminatePropertyManagerContractMutation, {
  props: props => ({
    terminatePropertyManagerContract: async (uuid: string) => {
      const res = await props.mutate({
        variables: {
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error terminating property manager contract.');

      return res.data.terminatePropertyManagerContract;
    },
  }),
});
