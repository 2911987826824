import withMutation from '~tools/react/graphql/withMutation';
import { ServiceTypes } from '~tools/types/graphqlSchema';

import CreateServicePreferenceMutation from './createServicePreference.gql';

interface ServicePreference {
  uuid: string;
  serviceType: ServiceTypes;
}

interface Response {
  createServicePreference: ServicePreference;
}

interface CreateServicePreferenceInput {
  serviceProviderUuid: string;
  serviceType: ServiceTypes;
}

interface Variables {
  input: CreateServicePreferenceInput;
}

export interface CreateServicePreferenceProps {
  createServicePreference: (input: CreateServicePreferenceInput) => Promise<ServicePreference>;
}

export default withMutation<{}, Response, Variables, CreateServicePreferenceProps>(CreateServicePreferenceMutation, {
  props: props => ({
    createServicePreference: async (input: CreateServicePreferenceInput) => {
      const res = await props.mutate({
        variables: { input },
      });

      if (!res || !res.data) throw new Error('Error creating service preference');
      if (!res.data.createServicePreference) throw new Error('No createServicePreference returned');

      return res.data.createServicePreference;
    },
  }),
});
