import React, { ComponentType, Fragment, PureComponent } from 'react';

import { compose } from '~tools/react/hocs/utils';
import withGenerateSmartLockOneTimeCode, { GenerateSmartLockOneTimeCodeProps } from '~tools/react/graphql/mutations/smartLocks/withGenerateSmartLockOneTimeCode';

import Button from '~tools/react/components/Button';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import MaskedSmartLockCode from './components/MaskedSmartLockCode';

interface InputProps {
  smartLock: {
    uuid: string;
  } | null;
}

type Props = InputProps & GenerateSmartLockOneTimeCodeProps;

interface State {
  isGeneratingAccessCode: boolean;
  smartLockAccessCode: string | null;
}

class SmartLockAccessCodeRow extends PureComponent<Props, State> {
  state = {
    isGeneratingAccessCode: false,
    smartLockAccessCode: null,
  }

  handleGenerateAccessCode = async () => {
    const smartLock = this.props.smartLock;
    if (!smartLock) return;
    this.setState({ isGeneratingAccessCode: true });
    const accessCode = await this.props.generateSmartLockOneTimeCode(smartLock.uuid);
    this.setState({ smartLockAccessCode: accessCode, isGeneratingAccessCode: false });
  }

  render() {
    return (
      <Fragment>
        <MaskedSmartLockCode
          code={this.state.smartLockAccessCode}
          style={MaskedSmartLockCode.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
        <Button
          color={Button.enums.Colors.Blue}
          isDisabled={!this.props.smartLock || this.state.isGeneratingAccessCode}
          isLoading={this.state.isGeneratingAccessCode}
          label="Reveal single-use code"
          onClick={this.handleGenerateAccessCode}
          size={Button.enums.Sizes.XSmall}
          style={Button.enums.Styles.Outline}
        />
      </Fragment>
    );
  }
}

export default compose(
  withGenerateSmartLockOneTimeCode,
)(SmartLockAccessCodeRow) as ComponentType<InputProps>;
