// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._br0M3Z{display:grid;grid-template-rows:auto}._br0M3Z._QDo3pB{grid-gap:0}._br0M3Z._vqQ90M{grid-gap:16px}._br0M3Z._zaN7Xz{grid-gap:24px}._br0M3Z._80AmeE{grid-template-columns:1fr 1fr}._br0M3Z._ernnjX{grid-template-columns:1fr 1fr 1fr}._br0M3Z._99vMQV{grid-template-columns:1fr 1fr 1fr 1fr}._br0M3Z._5aA2Ky{grid-template-columns:1fr 1fr 1fr 1fr 1fr}", ""]);
// Exports
exports.locals = {
	"grid": "_br0M3Z",
	"grid--spacing-none": "_QDo3pB",
	"grid--spacing-small": "_vqQ90M",
	"grid--spacing-medium": "_zaN7Xz",
	"grid--columns-two": "_80AmeE",
	"grid--columns-three": "_ernnjX",
	"grid--columns-four": "_99vMQV",
	"grid--columns-five": "_5aA2Ky"
};
module.exports = exports;
