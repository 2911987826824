// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._br03En{display:block;font-size:144px;text-align:center}", ""]);
// Exports
exports.locals = {
	"locked-emoji": "_br03En"
};
module.exports = exports;
