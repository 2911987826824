import _ from 'lodash';

import PhotoEditorModalFilters from './PhotoEditorModalFilters';

import PhotoEditorModalFilterFlip from './components/PhotoEditorModalFilterFlip';
import PhotoEditorModalFilterSlider from './components/PhotoEditorModalFilterSlider';
import PhotoEditorModalFiltersSection from './components/PhotoEditorModalFiltersSection';
import PhotoEditorModalFilterTags from './components/PhotoEditorModalFilterTags';

export default _.assign(PhotoEditorModalFilters, {
  PhotoEditorModalFilterFlip,
  PhotoEditorModalFilterSlider,
  PhotoEditorModalFiltersSection,
  PhotoEditorModalFilterTags,
});
