"use strict";

exports.__esModule = true;
exports.default = useTooltip;

var _react = _interopRequireWildcard(require("react"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function useTooltip() {
  var _useState = (0, _react.useState)({
    tooltipOpen: false,
    tooltipLeft: undefined,
    tooltipTop: undefined,
    tooltipData: undefined
  }),
      _useState$ = _useState[0],
      tooltipOpen = _useState$.tooltipOpen,
      tooltipLeft = _useState$.tooltipLeft,
      tooltipTop = _useState$.tooltipTop,
      tooltipData = _useState$.tooltipData,
      setTooltipState = _useState[1];

  var updateTooltip = function updateTooltip(_ref) {
    var tooltipOpen = _ref.tooltipOpen,
        tooltipLeft = _ref.tooltipLeft,
        tooltipTop = _ref.tooltipTop,
        tooltipData = _ref.tooltipData;
    return setTooltipState(function (prevState) {
      return _extends({}, prevState, {
        tooltipOpen: tooltipOpen,
        tooltipLeft: tooltipLeft,
        tooltipTop: tooltipTop,
        tooltipData: tooltipData
      });
    });
  };

  var showTooltip = function showTooltip(_ref2) {
    var tooltipLeft = _ref2.tooltipLeft,
        tooltipTop = _ref2.tooltipTop,
        tooltipData = _ref2.tooltipData;
    return updateTooltip({
      tooltipOpen: true,
      tooltipLeft: tooltipLeft,
      tooltipTop: tooltipTop,
      tooltipData: tooltipData
    });
  };

  var hideTooltip = function hideTooltip() {
    return updateTooltip({
      tooltipOpen: false,
      tooltipLeft: undefined,
      tooltipTop: undefined,
      tooltipData: undefined
    });
  };

  return {
    tooltipOpen: tooltipOpen,
    tooltipLeft: tooltipLeft,
    tooltipTop: tooltipTop,
    tooltipData: tooltipData,
    updateTooltip: updateTooltip,
    showTooltip: showTooltip,
    hideTooltip: hideTooltip
  };
}