import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './BrandedBackground.scss';

import ArrowsSVG from './svgs/arrows.svg';

interface Props {
  bottom?: string;
  left?: string;
  right?: string;
  top?: string;
}

function BrandedBackground(props: Props) {
  return (
    <div
      style={{
        bottom: props.bottom,
        left: props.left,
        right: props.right,
        top: props.top,
      }}
      styleName="branded-bg">
      <ArrowsSVG />
    </div>
  );
}

export default withStyles(styles)(BrandedBackground);
