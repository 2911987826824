import _ from 'lodash';
import React, { Component, ReactNode } from 'react';
import { matchPath, RouteComponentProps, withRouter } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { compose } from '~tools/react/hocs/utils';
import withStyles from '~tools/react/hocs/withStyles';
import withDevice, { DeviceProps } from '~tools/react/hocs/withDevice';

import VerticalMenuView from '~tools/react/components/VerticalMenuView';

import AnimatedStepsFlowNotFound from './components/AnimatedStepsFlowNotFound';
import AnimatedStepsFlowSection from './components/AnimatedStepsFlowSection';
import AnimatedStepsFlowStep from './components/AnimatedStepsFlowStep';

import { Colors } from './enums';
import styles from './AnimatedStepsFlow.scss';

interface InputProps {
  children: ReactNode;
  color: Colors;
  lockedStepAction?: {
    label: string;
    path: string;
  };
}

type Props =
  InputProps &
  RouteComponentProps &
  DeviceProps;

class AnimatedStepsFlow extends Component<Props> {
  static AnimatedStepsFlowStep = AnimatedStepsFlowStep;
  static AnimatedStepsFlowSection = AnimatedStepsFlowSection;

  static defaultProps = {
    color: Colors.Blue,
  };

  render() {
    const children = React.Children.toArray(this.props.children);

    const sections = React.Children.map(children, section => ({
      // @ts-ignore
      name: section.props.name,
      // @ts-ignore
      steps: React.Children.map(section.props.children, step => React.cloneElement(step, {
        ...step.props,
        children: React.Children.map(step.props.children, (child) => {
          if (child.type !== AnimatedStepsFlowStep) return child;

          return null;
        }),
        secondarySteps: _.compact(React.Children.map(step.props.children, (child) => {
          if (child.type === AnimatedStepsFlowStep) return child;

          return undefined;
        })),
      })),
    }));

    const primarySteps = _.flatten(_.map(sections, 'steps'));
    const secondarySteps = _.flatten(_.map(primarySteps, step => step.props.secondarySteps));
    const steps = _.concat(primarySteps, secondarySteps);
    const activeIndex = _.findIndex(steps, step => matchPath(this.props.location.pathname, {
      path: step.props.path,
      exact: step.props.isExact,
    }));
    const activeStep = steps[activeIndex];
    const previousStep = steps[activeIndex - 1];
    const nextStep = steps[activeIndex + 1];

    if (this.props.isMobile) {
      return (
        <div styleName="process">
          {activeStep ? (
            React.cloneElement(activeStep, {
              color: this.props.color,
              lockedStepAction: this.props.lockedStepAction,
              primaryAction: activeStep.props.primaryAction,
              primaryLink: !activeStep.props.primaryAction ? (
                activeStep.props.primaryLink || { path: nextStep ? nextStep.props.path : '' }
              ) : null,
              secondaryAction: activeStep.props.secondaryAction || null,
              secondaryLink: !activeStep.props.secondaryAction ? (
                activeStep.props.secondaryLink || { path: previousStep ? previousStep.props.path : '' }
              ) : null,
            })
          ) : <AnimatedStepsFlowNotFound />}
        </div>
      );
    }

    return (
      <VerticalMenuView
        color={this.props.color}
        sections={_.map(sections, section => ({
          name: section.name,
          links: _.map(section.steps, step => ({
            isCompleted: !!step.props.isCompleted,
            isDisabled: !!step.props.isDisabled,
            isExact: !!step.props.isExact,
            label: step.props.label,
            path: step.props.path,
            secondaryLinks: _.compact(React.Children.map(step.props.secondarySteps, (secondaryStep) => {
              if (secondaryStep.type === AnimatedStepsFlowStep) {
                return {
                  isCompleted: !!secondaryStep.props.isCompleted,
                  isDisabled: !!secondaryStep.props.isDisabled,
                  isExact: !!secondaryStep.props.isExact,
                  label: secondaryStep.props.label,
                  path: secondaryStep.props.path,
                };
              }

              return undefined;
            })),
          })),
        }))}>
        <TransitionGroup styleName="process">
          <CSSTransition
            key={activeStep ? activeStep.props.label : 'no-route'}
            timeout={{ enter: 400, exit: 200 }}
            classNames={{
              enter: styles['fade-enter'],
              enterActive: styles['fade-enter-active'],
              exit: styles['fade-exit'],
              exitActive: styles['fade-exit-active'],
            }}>
            {activeStep ? (
              React.cloneElement(activeStep, {
                color: this.props.color,
                primaryAction: activeStep.props.primaryAction,
                primaryLink: !activeStep.props.primaryAction ? (
                  activeStep.props.primaryLink || { path: nextStep ? nextStep.props.path : '' }
                ) : null,
                secondaryAction: activeStep.props.secondaryAction || null,
                secondaryLink: !activeStep.props.secondaryAction ? (
                  activeStep.props.secondaryLink || { path: previousStep ? previousStep.props.path : '' }
                ) : null,
              })
            ) : <AnimatedStepsFlowNotFound />}
          </CSSTransition>
        </TransitionGroup>
      </VerticalMenuView>
    );
  }
}

export default compose(
  withDevice,
  withRouter,
  withStyles(styles),
)(AnimatedStepsFlow);
