
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ListingUpdateFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Listing"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"upcomingViewerViewing"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"calendarEvent"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"endsAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"startsAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"uuid"},"arguments":[],"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":157}};
    doc.loc.source = {"body":"fragment ListingUpdateFragment on Listing {\n  uuid\n  upcomingViewerViewing {\n    uuid\n    calendarEvent {\n      endsAt\n      startsAt\n      uuid\n    }\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
