// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._YXrvM3{-webkit-box-align:center;align-items:center;border-radius:24px;display:-webkit-box;display:flex;padding:8px 12px}._DBDmPR{background:#eff2f7}._DBDmPR span{color:#8492a6}._drDmky{background-color:#fff;box-shadow:0 2px 8px rgba(31,45,61,.2)}._drDmky span{color:#182029}._VYQ0kn{background-color:#e4f5fe}._VYQ0kn span{color:#0253b9}._YXrvM3 span{font-size:14px;font-weight:300}._YXrvM3 ._MOJxkd{background:#fff;border-radius:12px;box-shadow:0 1px 2px rgba(31,45,61,.2);height:12px;width:12px}", ""]);
// Exports
exports.locals = {
	"step": "_YXrvM3",
	"step--upcoming": "_DBDmPR",
	"step--active": "_drDmky",
	"step--complete": "_VYQ0kn",
	"incomplete-circle": "_MOJxkd"
};
module.exports = exports;
