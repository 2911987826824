import React, { ReactNode } from 'react';

import classNames from 'classnames';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './PhotoEditorModalFilters.scss';

interface Props {
  children: ReactNode;
  isActive: boolean;
}

function PhotoEditorModalFilters(props: Props) {
  return (
    <div styleName={classNames({ 'photo-editor-filters': true, 'photo-editor-filters--active': props.isActive })}>
      <div styleName="photo-editor-filters__view" id="filterView" />
      <div styleName="photo-editor-filters__tabs">
        <h2>Edit Photo</h2>
        {props.children}
      </div>
    </div>
  );
}

export default withStyles(styles)(PhotoEditorModalFilters);
