import React, { PureComponent } from 'react';

import Link from '~tools/react/components/utility/Link';
import Image from '~tools/react/components/utility/Image';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './AccessPhoto.scss';

interface Props {
  photo: {
    url: string,
    uuid?: string,
  };
}

class AccessPhoto extends PureComponent<Props> {
  render() {
    return (
      <Link
        styleName="access-photo-link"
        to={this.props.photo.url}
        shouldOpenNewTab>
        <Image
          src={this.props.photo.url}
          size={{
            w: 48,
            h: 48,
          }}
          styleName="access-photo"
        />
      </Link>
    );
  }
}

export default withStyles(styles)(AccessPhoto);
