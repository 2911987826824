// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._prWGpz{position:relative}._prWGpz ._VYQAk3{margin:24px;min-width:300px}._prWGpz ._VYQAk3 ._RnEI3B{display:-webkit-box;display:flex;-webkit-box-pack:justify;justify-content:space-between}", ""]);
// Exports
exports.locals = {
	"issues-filter-dropdown": "_prWGpz",
	"issues-filter-dropdown__menu": "_VYQAk3",
	"issues-filter-dropdown__menu__actions": "_RnEI3B"
};
module.exports = exports;
