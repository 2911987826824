import React from 'react';
import classNames from 'classnames';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './StatusCircle.scss';

interface Props {
  active: boolean;
}

const StatusCircle = (props: Props) => {
  const campaignStatusClasses = classNames({
    'status-circle': true,
    active: props.active,
  });

  // This needs a space as a child, otherwise it won't show
  // eslint-disable-next-line react/self-closing-comp
  return <div styleName={campaignStatusClasses}> </div>;
};

export default withStyles(styles)(StatusCircle);
