import React, { Fragment, PureComponent } from 'react';
import classNames from 'classnames';

import Alert from '~tools/react/components/Alert';
import Spinner from '~tools/react/components/Spinner';
import Script from '~tools/react/components/utility/Script';
import withStyles from '~tools/react/hocs/withStyles';

import globals from '~web-core/lib/common/globals';

import styles from './DocumentEmbedView.scss';

interface Props {
  embedUrl: string | null;
  isLoading: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
  shouldPostMessage?: boolean;
  containerId?: string;
}

class DocumentEmbedView extends PureComponent<Props> {
  handleHelloSignMessage = (eventData) => {
    // if (this.props.shouldPostMessage) {
    //   window.postMessage(eventData.event);
    // }
    switch (eventData.event) {
      case (window as any).HelloSign.EVENT_SIGNED:
        (window as any).HelloSign.close();
        if (this.props.onSuccess) this.props.onSuccess();
        break;
      case (window as any).HelloSign.EVENT_CANCELED:
        if (this.props.onCancel) this.props.onCancel();
        break;
      default:
        break;
    }
  };

  handleHelloSignInit = () => {
    (window as any).HelloSign.init(globals.HELLOSIGN_CLIENT_ID);

    let container: HTMLElement | null = null;
    if (this.props.containerId) {
      container = document.getElementById(this.props.containerId);
    }

    (window as any).HelloSign.open({
      allowCancel: true,
      container,
      height: 800,
      skipDomainVerification: globals.ENV !== 'production',
      url: this.props.embedUrl,
      messageListener: this.handleHelloSignMessage,
      uxVersion: 2,
    });
  };

  render() {
    if (this.props.isLoading) {
      return (
        <div
          className={classNames({
            [styles.loading]: true,
            [styles['loading--container']]: !!this.props.containerId,
          })}>
          <Spinner />
        </div>
      );
    }

    return (
      <Fragment>
        {this.props.embedUrl ? (
          <Script
            onLoad={this.handleHelloSignInit}
            url="https://s3.amazonaws.com/cdn.hellosign.com/public/js/hellosign-embedded.LATEST.min.js"
          />
        ) : (
          <div
            className={classNames({
              [styles.error]: true,
              [styles['error--container']]: !!this.props.containerId,
            })}>
            <Alert
              color={Alert.enums.Colors.Red}
              title="Error loading document"
              description="Make sure it's a valid document and esigning is enabled."
            />
          </div>
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(DocumentEmbedView);
