import React from 'react';

import { QualificationTypes } from '~tools/types/graphqlSchema';
import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Card from '~tools/react/components/Card';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import AccountQualificationManager from '~web-manage/lib/common/containers/AccountQualificationManager';
import ManageStage from '~web-manage/lib/common/stages/ManageStage';

function ApplicationsSettings() {
  return (
    <ManageStage>
      <Breadcrumbs
        items={[
          { path: `/settings`, label: 'Settings' },
          { path: `/settings/applications`, label: 'Applications settings' },
        ]}
        style={Breadcrumbs.enums.Styles.Compact}
      />
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
      <Card
        header={{
          subtitle: 'Set the minimum qualifications for new tenants of your units. These settings can be overridden in the unit\'s settings.',
          title: 'Global tenant requirements',
        }}>
        <Card.CardSection>
          <AccountQualificationManager
            type={QualificationTypes.INCOME}
          />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          <HorizontalRule />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          <AccountQualificationManager
            type={QualificationTypes.CREDIT_SCORE}
          />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          <HorizontalRule />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          <AccountQualificationManager
            type={QualificationTypes.CIVIL_HISTORY}
          />
        </Card.CardSection>
      </Card>
    </ManageStage>
  );
}

export default ApplicationsSettings;
