import withMutation from '~tools/react/graphql/withMutation';
import { RemovePropertyManagerContractFromServicePreferenceInput } from '~tools/types/graphqlSchema';

import RemovePropertyManagerContractFromServicePreferenceMutation from './removePropertyManagerContractFromServicePreference.gql';

interface ServicePreference {
  uuid: string;
}

interface Response {
  removePropertyManagerContractFromServicePreference: ServicePreference;
}

interface Variables {
  input: RemovePropertyManagerContractFromServicePreferenceInput;
  uuid: string;
}

export interface RemovePropertyManagerContractFromServicePreferenceProps {
  removePropertyManagerContractFromServicePreference: (uuid: string, input: RemovePropertyManagerContractFromServicePreferenceInput) => Promise<ServicePreference>;
}

export default withMutation<{}, Response, Variables, RemovePropertyManagerContractFromServicePreferenceProps>(RemovePropertyManagerContractFromServicePreferenceMutation, {
  props: props => ({
    removePropertyManagerContractFromServicePreference: async (uuid: string, input: RemovePropertyManagerContractFromServicePreferenceInput) => {
      const res = await props.mutate({
        variables: {
          input,
          uuid,
        },
      });

      if (!res || !res.data) throw new Error('Error updating service preference');
      if (!res.data.removePropertyManagerContractFromServicePreference) throw new Error('No removePropertyManagerContractFromServicePreference returned');

      return res.data.removePropertyManagerContractFromServicePreference;
    },
  }),
});
