import _ from 'lodash';
import React, { ComponentType, PureComponent } from 'react';

import { compose } from '~tools/react/hocs/utils';
import { PhoneNumberTypes, ServiceTypes } from '~tools/types/graphqlSchema';
import ThemedModal from '~tools/react/components/ThemedModal';
import withAttachPhoneNumberToPerson, { AttachPhoneNumberToPersonProps } from '~tools/react/graphql/mutations/phoneNumbers/withAttachPhoneNumberToPerson';
import withCreateOrganization, { CreateOrganizationProps } from '~tools/react/graphql/mutations/organizations/withCreateOrganization';
import withCreateOrganizationPerson, { CreateOrganizationPersonProps } from '~tools/react/graphql/mutations/organizationPersons/withCreateOrganizationPerson';
import withCreatePerson, { CreatePersonProps } from '~tools/react/graphql/mutations/persons/withCreatePerson';
import withCreateServicePreference, { CreateServicePreferenceProps } from '~tools/react/graphql/mutations/servicePreferences/withCreateServicePreference';
import withCreateServiceProvider, { CreateServiceProviderProps } from '~tools/react/graphql/mutations/serviceProviders/withCreateServiceProvider';
import withDetachPhoneNumberFromPerson, { DetachPhoneNumberFromPersonProps } from '~tools/react/graphql/mutations/phoneNumbers/withDetachPhoneNumberFromPerson';
import withFetchOrCreatePhoneNumber, { FetchOrCreatePhoneNumberProps } from '~tools/react/graphql/mutations/phoneNumbers/withFetchOrCreatePhoneNumber';
import withUpdateOrganization, { UpdateOrganizationProps } from '~tools/react/graphql/mutations/organizations/withUpdateOrganization';
import withUpdatePerson, { UpdatePersonProps } from '~tools/react/graphql/mutations/persons/withUpdatePerson';
import withUpdateServiceProvider, { UpdateServiceProviderProps } from '~tools/react/graphql/mutations/serviceProviders/withUpdateServiceProvider';
import withAttachPhoneNumberToOrganization, { AttachPhoneNumberToOrganizationProps } from '~tools/react/graphql/mutations/phoneNumbers/withAttachPhoneNumberToOrganization';

import ServiceProviderForm, { SubmitData } from './components/ServiceProviderForm';

export interface InputProps {
  isOpen: boolean;
  onClose: () => void;
  onServicePreferenceCreated?: (servicePreferenceUuid: string) => void;
}

type Props = InputProps &
  AttachPhoneNumberToPersonProps &
  CreateOrganizationPersonProps &
  CreateOrganizationProps &
  CreatePersonProps &
  CreateServicePreferenceProps &
  CreateServiceProviderProps &
  DetachPhoneNumberFromPersonProps &
  FetchOrCreatePhoneNumberProps &
  UpdateOrganizationProps &
  UpdatePersonProps &
  UpdateServiceProviderProps &
  AttachPhoneNumberToOrganizationProps;

interface Organization {
  uuid: string;
  organizationPersons: {
    person: {
      email: string | null;
      firstName: string;
      lastName: string | null;
      uuid: string;
    };
    uuid: string;
  }[];
}

interface ServiceProvider {
  uuid: string;
  serviceType: ServiceTypes;
}

interface State {
  isLoading: boolean;
}

class CreateServicePreferenceModal extends PureComponent<Props, State> {
  state: State = {
    isLoading: false,
  };

  handleServiceProviderFormSubmit = async (submitData: SubmitData) => {
    this.setState({ isLoading: true });

    try {
      // Create / update the organization
      let organization: Organization;
      if (submitData.organizationUuid) {
        organization = await this.props.updateOrganization(submitData.organizationUuid, {
          email: submitData.formData.email,
          website: submitData.formData.website,
        });
      } else {
        organization = await this.props.createOrganization({
          email: submitData.formData.email,
          name: submitData.formData.name,
          website: submitData.formData.website,
        });
      }

      // Create / update the phone number
      if (submitData.formData.phoneNumber) {
        const phoneNumber = await this.props.fetchOrCreatePhoneNumber({
          phoneNumber: submitData.formData.phoneNumber,
          type: PhoneNumberTypes.OFFICE,
        });

        await this.props.attachPhoneNumberToOrganization(phoneNumber.uuid, {
          organizationUuid: organization.uuid,
        });
      }

      // Create / update the service provider
      let serviceProvider: ServiceProvider;
      if (submitData.serviceProviderUuid) {
        serviceProvider = await this.props.updateServiceProvider(submitData.serviceProviderUuid, {
          serviceType: submitData.formData.serviceType,
        });
      } else {
        serviceProvider = await this.props.createServiceProvider({
          serviceType: submitData.formData.serviceType,
          organizationUuid: organization.uuid,
        });
      }

      // Create the service preference
      const servicePreference = await this.props.createServicePreference({
        serviceProviderUuid: serviceProvider.uuid,
        serviceType: submitData.formData.serviceType,
      });

      this.setState({ isLoading: false });

      if (this.props.onServicePreferenceCreated) this.props.onServicePreferenceCreated(servicePreference.uuid);
      this.props.onClose();
    } catch (err) {
      this.setState({ isLoading: false });
      throw err;
    }
  }

  render() {
    return (
      <ThemedModal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title="Add service provider"
        width={ThemedModal.enums.Widths.Small}>
        <ThemedModal.ThemedModalSection>
          <ServiceProviderForm
            isLoading={this.state.isLoading}
            onSubmit={this.handleServiceProviderFormSubmit}
          />
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

export default compose(
  withAttachPhoneNumberToPerson,
  withCreateOrganization,
  withCreateOrganizationPerson,
  withCreatePerson,
  withCreateServicePreference,
  withCreateServiceProvider,
  withDetachPhoneNumberFromPerson,
  withFetchOrCreatePhoneNumber,
  withUpdateOrganization,
  withUpdatePerson,
  withUpdateServiceProvider,
  withAttachPhoneNumberToOrganization,
)(CreateServicePreferenceModal) as ComponentType<InputProps>;
