import _ from 'lodash';
import loadable from '@loadable/component';

import NavBarAvatar from './components/NavBarAvatar';
import NavBarDivider from './components/NavBarDivider';
import NavBarIcon from './components/NavBarIcon';
import NavBarLogo from './components/NavBarLogo';

const NavBar = loadable(() => import(/* webpackChunkName: "nav-bar" */'./NavBar'));

export default _.assign(NavBar, {
  NavBarAvatar,
  NavBarDivider,
  NavBarIcon,
  NavBarLogo,
});
