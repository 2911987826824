// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._aeRJx3{-webkit-box-pack:center;justify-content:center;margin:0 auto 0 0}._aeRJx3,._aeRJx3 ._ypreXq{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex}._aeRJx3 ._ypreXq svg{height:24px;opacity:1;-webkit-transition:opacity .1s;transition:opacity .1s}._aeRJx3 ._ypreXq:hover svg{opacity:.8}@media screen and (max-width:1127px){._aeRJx3{margin:24px 0 16px}}", ""]);
// Exports
exports.locals = {
	"flip-logo": "_aeRJx3",
	"flip-logo__svg": "_ypreXq"
};
module.exports = exports;
