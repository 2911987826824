// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._KpPfN0{-webkit-box-align:center;align-items:center;background-color:#fff;border:none;display:-webkit-box;display:flex;padding:8px 12px;-webkit-transition:all .1s cubic-bezier(.215,.61,.355,1);transition:all .1s cubic-bezier(.215,.61,.355,1);width:100%}._KpPfN0 svg{color:#8492a6}._KpPfN0 ._WVjw3K{color:#182029;font-size:14px;font-weight:300}._KpPfN0 ._aeRrVo{color:#8492a6;font-size:14px;font-weight:100}._KpPfN0:not(:last-child){border-bottom:1px solid #e6e8eb}._KpPfN0._krPoM5{background-color:#f6f8fa;color:#1f8eed}._KpPfN0:hover{background-color:#f6f8fa}", ""]);
// Exports
exports.locals = {
	"search-typeahead-item": "_KpPfN0",
	"search-typeahead-item__label": "_WVjw3K",
	"search-typeahead-item__description": "_aeRrVo",
	"search-typeahead-item--selected": "_krPoM5"
};
module.exports = exports;
