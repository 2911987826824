// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._drDt8x{margin:auto;opacity:0;position:absolute;-webkit-transition:all .25s;transition:all .25s;z-index:0}._drDt8x._br0Inn{width:100%}._drDt8x._QDoUj2{-webkit-transform:translateX(-100px);transform:translateX(-100px)}._drDt8x._P9WxPb{-webkit-transform:translateX(100px);transform:translateX(100px)}._drDt8x._GN6vKw{opacity:1;-webkit-transform:translateX(0);transform:translateX(0);z-index:1}", ""]);
// Exports
exports.locals = {
	"animated-resizer-section": "_drDt8x",
	"animated-resizer-section--full-width": "_br0Inn",
	"animated-resizer-section--left": "_QDoUj2",
	"animated-resizer-section--right": "_P9WxPb",
	"animated-resizer-section--shown": "_GN6vKw"
};
module.exports = exports;
