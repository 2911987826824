// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._32AYbG{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;height:calc(100vh - 212px);-webkit-box-pack:center;justify-content:center;min-height:700px;overflow:hidden}._32AYbG img{max-height:367px;max-width:1000px;width:100%}._32AYbG p{color:#666;font-size:24px;font-weight:300;margin:40px 0}@media screen and (max-width:915px){._32AYbG p{font-size:20px}}._32AYbG a{background-color:#1f8eed;border-radius:4px;box-shadow:0 3px 7px 0 rgba(0,0,0,.18);color:#fff;font-weight:700;letter-spacing:2px;padding:15px 25px;text-transform:uppercase;-webkit-transition:all .25s;transition:all .25s}._32AYbG a:hover{opacity:.9;-webkit-transform:translateY(-1px);transform:translateY(-1px)}@media screen and (max-width:915px){._32AYbG{height:75vh}}", ""]);
// Exports
exports.locals = {
	"not-found": "_32AYbG"
};
module.exports = exports;
