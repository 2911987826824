import _ from 'lodash';
import memoize from 'memoize-one';
import moment from 'moment';
import pluralize from 'pluralize';
import React, { Fragment, PureComponent } from 'react';

import {
  QualificationThresholdTypes,
  QualificationTypes,
  RequirementEntityTypes,
} from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';
import { formatAsUSD } from '~tools/utils/string';

import withAttachVerificationMethodToRequirement, { AttachVerificationMethodToRequirementProps } from '~tools/react/graphql/mutations/requirements/withAttachVerificationMethodToRequirement';
import withCreateQualification, { CreateQualificationProps } from '~tools/react/graphql/mutations/qualifications/withCreateQualification';
import withCreateRequirement, { CreateRequirementProps } from '~tools/react/graphql/mutations/requirements/withCreateRequirement';
import withDeleteRequirement, { DeleteRequirementProps } from '~tools/react/graphql/mutations/requirements/withDeleteRequirement';

import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import DynamicHeightToggler from '~tools/react/components/DynamicHeightToggler';
import { Checkbox } from '~tools/react/components/Form';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import AndHorizontalRule from './components/AndHorizontalRule';
import RequirementManager from './containers/RequirementManager';
import VerificationMethodsModal from './containers/VerificationMethodsModal';

import {
  CreateQualificationInput,
  Listing,
  Qualification,
  Requirement,
  VerificationMethod,
} from './types';

import {
  getDefaultRequirementsForType,
  getDefaultVerificationMethodSlugsForType,
  getMaxRequirementCount,
  getStaticAttributesForType,
} from './utils';

interface InputProps {
  isLoading: boolean;
  listing?: Listing;
  refetch: () => Promise<void>;
  rentalPolicyUuid: string | null;
  requirements: Requirement[];
  verificationMethods: VerificationMethod[];
  type: QualificationTypes;
}

type Props =
  InputProps &
  AttachVerificationMethodToRequirementProps &
  CreateQualificationProps &
  CreateRequirementProps &
  DeleteRequirementProps;

interface State {
  isAddingRequirementFormOpen: boolean;
  isVerificationMethodsModalOpen: boolean;
}

class QualificationManager extends PureComponent<Props, State> {
  getMemoizedRequirementsByType = memoize((requirements: Requirement[], qualificationType: QualificationTypes) =>
    _.filter(requirements, r => r.qualification.type === qualificationType),
  );

  state: State = {
    isAddingRequirementFormOpen: false,
    isVerificationMethodsModalOpen: false,
  }

  handleEnable = async () => {
    const defaultRequirements = getDefaultRequirementsForType(this.props.type);
    for (let i = 0; i < defaultRequirements.length; i += 1) {
      await this.handleCreateRequirement(defaultRequirements[i]);
    }
    await this.props.refetch();
  }
  handleDisable = async () => {
    await Promise.all(_.map(this.props.requirements, requirement => this.props.deleteRequirement(requirement.uuid)));
    await this.props.refetch();
  }

  handleOpenAddRequirementForm = () => this.setState({ isAddingRequirementFormOpen: true });
  handleOpenVerificationMethodsModal = () => this.setState({ isVerificationMethodsModalOpen: true });
  handleCloseVerificationMethodsModal = () => this.setState({ isVerificationMethodsModalOpen: false });

  handleCreateRequirement = async (qualificationAttrs: Qualification) => {
    if (!this.props.rentalPolicyUuid) return;

    const qualification = await this.props.createQualification(qualificationAttrs as CreateQualificationInput);
    const requirement = await this.props.createRequirement({
      entityType: RequirementEntityTypes.RENTAL_POLICY,
      entityUuid: this.props.rentalPolicyUuid,
      qualificationUuid: qualification.uuid,
    });

    const defaultVerificationMethodSlugs = getDefaultVerificationMethodSlugsForType(this.props.type);
    await Promise.all(
      _.map(
        defaultVerificationMethodSlugs,
        (slug) => {
          const verificationMethod = _.find(this.props.verificationMethods, v => v.slug === slug) as VerificationMethod;
          return this.props.attachVerificationMethodToRequirement(requirement.uuid, {
            verificationMethodUuid: verificationMethod.uuid,
          });
        },
      ),
    );
    await this.props.refetch();
    this.setState({ isAddingRequirementFormOpen: false });
  }
  handleDeleteRequirement = async (requirementUuid?: string) => {
    this.setState({ isAddingRequirementFormOpen: false });
    if (!requirementUuid) return;

    await this.props.deleteRequirement(requirementUuid);
    await this.props.refetch();
  }
  handleUpdateRequirement = async (requirementUuid: string, qualification: Qualification) => {
    await this.props.deleteRequirement(requirementUuid);
    await this.handleCreateRequirement(qualification);
  }

  render() {
    const listing = this.props.listing;
    const sortedRequirements = _.orderBy(this.props.requirements, r => moment(r.createdAt).valueOf());
    const requirements = _.filter(sortedRequirements, r => r.qualification.type === this.props.type);
    const activeVerificationMethods = requirements[0] ? requirements[0].verificationMethods : [];

    return (
      <Fragment>
        <VerificationMethodsModal
          isLoading={this.props.isLoading}
          isOpen={this.state.isVerificationMethodsModalOpen}
          onClose={this.handleCloseVerificationMethodsModal}
          qualificationType={this.props.type}
          requirements={this.props.requirements}
          verificationMethods={this.props.verificationMethods}
        />
        <Row
          flexBehavior={Row.enums.FlexBehaviors.Default}
          verticalAlignment={Row.enums.VerticalAlignments.Center}>
          <Checkbox
            isChecked={!!requirements.length}
            name={_.kebabCase(this.props.type)}
            onChange={requirements.length ? this.handleDisable : this.handleEnable}
          />
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
          <Text
            color={requirements.length ? Text.enums.Colors.Info : undefined}
            content={this.props.type === QualificationTypes.CIVIL_HISTORY ? 'Eviction History' : _.startCase(_.toLower(this.props.type))}
            isEmphasized
          />
          {requirements.length ? (
            <Fragment>
              <div style={{ marginLeft: 'auto' }} />
              {this.props.type === QualificationTypes.INCOME ? (
                <Fragment>
                  <Text
                    content={`We'll verify this data with *${activeVerificationMethods.length}* verification ${pluralize('method', activeVerificationMethods.length)}`}
                    isMarkdown
                    size={Text.enums.Sizes.Small}
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                  <Text
                    color={Text.enums.Colors.Info}
                    content="Change"
                    hasHoverStyles
                    isEmphasized
                    isMarkdown
                    onClick={this.handleOpenVerificationMethodsModal}
                    size={Text.enums.Sizes.Small}
                  />
                </Fragment>
              ) : (
                <Text
                  content="This data will be verified with our partner, *TransUnion*"
                  isMarkdown
                  size={Text.enums.Sizes.Small}
                />
              )}
            </Fragment>
          ) : null}
        </Row>
        <DynamicHeightToggler isOpen={!!requirements.length}>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          <Card shadow={Card.enums.Shadows.None}>
            <Card.CardSection>
              {requirements.map((requirement, index) => (
                <Fragment key={requirement.uuid}>
                  {index > 0 ? (
                    <Fragment>
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                      <AndHorizontalRule />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                    </Fragment>
                  ) : null}
                  <RequirementManager
                    // Boolean threshold types happen to be the defaults right now. This will need to be updated
                    isDisabled={requirement.qualification.thresholdType === QualificationThresholdTypes.BOOLEAN}
                    note={
                      listing &&
                      requirement.qualification.type === QualificationTypes.INCOME &&
                      requirement.qualification.thresholdType !== QualificationThresholdTypes.BOOLEAN ?
                        `$${formatAsUSD((listing.rentInCents / 100) * requirement.qualification.thresholdValue)}/yr` :
                        undefined
                    }
                    onCreateRequirement={this.handleCreateRequirement}
                    onDeleteRequirement={this.handleDeleteRequirement}
                    onUpdateRequirement={this.handleUpdateRequirement}
                    qualification={requirement.qualification}
                    requirementUuid={requirement.uuid}
                    viewerDidCreate={requirement.viewerDidCreate}
                  />
                </Fragment>
              ))}
              {this.state.isAddingRequirementFormOpen ? (
                <Fragment>
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                  <AndHorizontalRule />
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                  <RequirementManager
                    onCreateRequirement={this.handleCreateRequirement}
                    onDeleteRequirement={this.handleDeleteRequirement}
                    onUpdateRequirement={this.handleUpdateRequirement}
                    qualification={getStaticAttributesForType(this.props.type)}
                  />
                </Fragment>
              ) : null}
              {!this.state.isAddingRequirementFormOpen &&
                requirements.length < getMaxRequirementCount(this.props.type) ? (
                  <Fragment>
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                    <AndHorizontalRule />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                    <Row
                      flexBehavior={Row.enums.FlexBehaviors.Default}
                      verticalAlignment={Row.enums.VerticalAlignments.Center}>
                      <Button
                        align={Button.enums.Alignments.Center}
                        icon={Button.enums.Icons.Plus}
                        label="Add requirement"
                        onClick={this.handleOpenAddRequirementForm}
                        size={Button.enums.Sizes.XSmall}
                        style={Button.enums.Styles.Outline}
                      />
                    </Row>
                  </Fragment>
                ) : null}
            </Card.CardSection>
          </Card>
        </DynamicHeightToggler>
      </Fragment>
    );
  }
}

export default compose(
  withAttachVerificationMethodToRequirement,
  withCreateQualification,
  withCreateRequirement,
  withDeleteRequirement,
)(QualificationManager);
