import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import SkeletonBodyText from '~tools/react/components/SkeletonBodyText';
import Link from '~tools/react/components/utility/Link';

import * as enums from './enums';

import { Props } from './types';

import styles from './TableData.scss';

function TableData(props: Props) {
  const content = (
    <span styleName="table-data__span">
      {props.isLoading ? (
        <SkeletonBodyText />
      ) : props.children}
    </span>
  );

  return (
    <td
      className={classNames({
        [styles['table-data']]: true,
        [styles[`table-data--alignment-${_.kebabCase(props.alignment)}`]]: props.alignment,
        [styles[`table-data--width-${_.kebabCase(props.width)}`]]: props.width,
      })}>
      {props.link ? (
        <Link
          to={props.link.path}
          onClick={props.onClick}
          styleName="table-data__content">
          {content}
        </Link>
      ) : (
        <div
          onClick={props.onClick}
          styleName="table-data__content">
          {content}
        </div>
      )}
    </td>
  );
}

TableData.defaultProps = {
  alignment: enums.Alignments.LEFT,
  isLoading: false,
  width: enums.Widths.MINIMIZED,
};

export default withStyles(styles)(TableData);
