import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Globals from '~web-core/lib/common/globals';

import BrandLogoSvg from '~tools/svgs/brand/logo.svg';

import Link from '~tools/react/components/utility/Link';

import styles from './FlipLogo.scss';

function FlipLogo() {
  return (
    <div styleName="flip-logo">
      <Link
        styleName="flip-logo__svg"
        to={Globals.WEB_CORE_DOMAIN}>
        <BrandLogoSvg />
      </Link>
    </div>
  );
}

export default withStyles(styles)(FlipLogo);
