import _ from 'lodash';
import dayjs from 'dayjs';
import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withAuth, { AuthProps } from '~tools/react/hocs/withAuth';
import withPaymentSubscription, { PaymentSubscriptionPlans, PaymentSubscriptionProps } from '~tools/react/hocs/withPaymentSubscription';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import Card from '~tools/react/components/Card';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import OnboardingCard from './containers/OnboardingCard';
import RentPaymentsVolumeChart from './containers/RentPaymentsVolumeChart';

import MarketingStatusSection from './containers/MarketingStatusSection';
import ApplicationsStatusSection from './containers/ApplicationsStatusSection';
import LeasesStatusSection from './containers/LeasesStatusSection';
import MaintenanceStatusSection from './containers/MaintenanceStatusSection';

import query from './Home.gql';

interface RouteParams {
  propertyManagerContractUuid: string;
  tab: string;
}

interface Viewer {
  firstName: string;
  createdAt: string;
  stripeConnectAccount: {
    id: string;
    payoutsEnabled: boolean;
    requirements: {
      currentlyDue: string[];
      disabledReason: string;
      pastDue: string[];
    };
  } | null;
  uuid: string;
}

interface QueryProps {
  isLoading: boolean;
  viewer: Viewer | null;
}

type Props =
  RouteComponentProps<RouteParams> &
  QueryProps &
  AuthProps &
  PaymentSubscriptionProps;

class Home extends PureComponent<Props> {
  render() {
    const viewer = this.props.viewer;
    const hasCompletedOnboarding =
      viewer?.stripeConnectAccount &&
      viewer.stripeConnectAccount.requirements &&
      !viewer?.stripeConnectAccount?.requirements.disabledReason;
    const paymentSubscription = this.props.paymentSubscription;
    const isPaidSubscription = paymentSubscription && _.includes(
      [
        PaymentSubscriptionPlans.LANDLORD,
        PaymentSubscriptionPlans.ADVANCED,
        PaymentSubscriptionPlans.BASIC,
        PaymentSubscriptionPlans.ENTERPRISE,
      ],
      paymentSubscription.plan,
    );

    const isNew = dayjs(viewer?.createdAt).add(7, 'days').isAfter(dayjs());
    return (
      <ManageStage>
        {!hasCompletedOnboarding ? (
          <OnboardingCard />
        ) : null}
        <Card
          isLoading={this.props.isLoading}
          header={{
            title: `Welcome${isNew ? ' to your new mission control' : ' back'}, ${viewer?.firstName}`,
            subtitle: isNew ? 'We surface the critical need-to-know information from your operation here so you don\'t have to go find it' : undefined,
          }}>
          <MarketingStatusSection />
          <ApplicationsStatusSection />
          <LeasesStatusSection />
          {isPaidSubscription ? (
            <MaintenanceStatusSection />
          ) : null}
        </Card>

        <RentPaymentsVolumeChart
          header={{
            title: 'Incoming rent payments',
            subtitle: isNew ? 'A simple snapshot of rent payments across your entire portfolio' : undefined,
            actions: [
              {
                icon: Card.enums.ActionIcons.ChevronRight,
                label: 'Payout settings',
                link: {
                  path: '/account/payments',
                },
              },
            ],
          }}
          futureDataPoints={3}
          pastDataPoints={3}
          height={225}
          unit={RentPaymentsVolumeChart.enums.Units.Month}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
      </ManageStage>
    );
  }
}

interface Response {
  viewer: Viewer;
}

export default compose(
  withAuth,
  withPaymentSubscription,
  withQuery<AuthProps, Response, {}, QueryProps>(query, {
    options: props => ({
      skip: !props.isLoggedIn,
    }),
    props: props => ({
      isLoading: props.loading,
      viewer: props.data?.viewer || null,
    }),
  }),
)(Home);
