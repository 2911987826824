import _ from 'lodash';

import {
  StreetDirectionAbbreviations,
  StreetGenericAbbreviations,
} from '~tools/constants/streets';

export const getAbbreviatedStreetAddress = (streetAddress: string) => {
  const streetAddressParts = streetAddress.split(' ');
  const streetNumber = _.take(streetAddressParts, 1);
  const streetNameParts = _.drop(streetAddressParts, 1);

  const finalIndex = streetNameParts.length - 1;
  const generic = _.toUpper(streetNameParts[finalIndex]);
  const genericAbbreviation = _.upperFirst(_.toLower(StreetGenericAbbreviations[generic]));
  streetNameParts[finalIndex] = genericAbbreviation || streetNameParts[finalIndex];
  if (streetNameParts.length > 2) {
    const direction = _.toUpper(streetNameParts[0]);
    const directionAbbreviation = _.upperFirst(_.toLower(StreetDirectionAbbreviations[direction]));
    streetNameParts[0] = directionAbbreviation ? `${directionAbbreviation}.` : streetNameParts[0];
  }

  return `${streetNumber} ${streetNameParts.join(' ')}`;
};
