export enum Platforms {
  Desktop = 'DESKTOP',
  Phone = 'PHONE',
  Tablet = 'TABLET',
}

export enum BreakPoints {
  Phone = 736,
  Tablet = 1127,
}
