// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._P9WM9m{-webkit-box-align:center;align-items:center;background:#fff;border-top:1px solid #e6e8eb;bottom:0;display:-webkit-box;display:flex;-webkit-box-pack:justify;justify-content:space-between;margin-top:40px;padding:16px 0;position:-webkit-sticky;position:sticky;width:100%}@media screen and (max-width:1127px){._P9WM9m{bottom:0;left:0;margin-left:0;padding:16px;position:fixed;right:0;width:auto}._P9WM9m button:first-child,._P9WM9m button:last-child{font-size:14px;height:32px;line-height:32px;padding:0 12px}}", ""]);
// Exports
exports.locals = {
	"next-steps-footer": "_P9WM9m"
};
module.exports = exports;
