import React, { ReactNode } from 'react';

import SkeletonBodyText from '~tools/react/components/SkeletonBodyText';
import withStyles from '~tools/react/hocs/withStyles';

import styles from './TableData.scss';

interface Props {
  children: ReactNode;
  isLoading?: boolean;
}

function TableData(props: Props) {
  if (props.isLoading) {
    return (
      <div styleName="table__td">
        <SkeletonBodyText />
      </div>
    );
  }

  return (
    <div styleName="table__td">
      {props.children}
    </div>
  );
}

TableData.defaultProps = {
  isLoading: false,
};

export default withStyles(styles)(TableData);
