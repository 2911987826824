import React, { PureComponent } from 'react';
import _ from 'lodash';

import withStyles from '~tools/react/hocs/withStyles';
import Text from '~tools/react/components/Text';
import Link from '~tools/react/components/utility/Link';
import Tag from '~tools/react/components/Tag';
import { ConditionReportRequiredServiceTypes } from '~tools/types/graphqlSchema';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';

import * as tagEnums from '~tools/react/components/Tag/enums';

import { ReportPhoto } from '../../../../types';
import styles from './ReportRow.scss';

interface Props {
  displayReport: {
    uuid: string;
    description: string;
    photos: ReportPhoto[];
  };
  requiredServiceType: ConditionReportRequiredServiceTypes;
  tag: {
    color?: tagEnums.Colors;
    icon?: tagEnums.Icons;
    label: string;
  };
  onOpenImageModal: (headline: string, photos: ReportPhoto[]) => void;
}

class ReportRow extends PureComponent<Props, {}> {
  handleViewImages = () => {
    this.props.onOpenImageModal(
      this.props.displayReport.description,
      this.props.displayReport.photos,
    );
  };

  render() {
    const primaryPhotoUrl = _.minBy(this.props.displayReport.photos, p => p.position)?.url;

    const header = this.props.requiredServiceType === ConditionReportRequiredServiceTypes.OTHER ?
      `Damage report` :
      `${_.capitalize(this.props.requiredServiceType)} damage report`;

    return (
      <div styleName="report-row">
        <div styleName="report-row__left">
          {primaryPhotoUrl ? (
            <button
              styleName="report-row__left__image"
              onClick={this.handleViewImages}>
              <img
                alt=""
                src={`${primaryPhotoUrl}?w=160`}
              />
            </button>
          ) : (
            <div style={{ width: '80px', paddingTop: '14px' }}>
              <Text content="No photo" color={Text.enums.Colors.Secondary} align={Text.enums.Align.Center} />
            </div>
          )}
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
          <div>
            <Text
              content={header}
              isEmphasized
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
            <Text content={this.props.displayReport.description} />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
            {this.props.displayReport.photos.length ? (
              <div styleName="report-row__left__links">
                <div styleName="report-row__left__links__view-photos">
                  <Link to="#" onClick={this.handleViewImages}>
                View photos ({this.props.displayReport.photos.length})
                  </Link>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <Tag
            color={this.props.tag.color}
            icon={this.props.tag.icon}
            label={this.props.tag.label}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ReportRow);
