import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';

import { Statuses } from './enums';
import styles from './Step.scss';

import Active from './svgs/active-marker.svg';
import Check from './svgs/check.svg';

const enumToIcon = {
  [Statuses.Active]: Active,
  [Statuses.Complete]: Check,
};

interface Props {
  label: string;
  status: Statuses;
}

function Step(props: Props) {
  const Icon = enumToIcon[props.status];
  return (
    <div className={classNames({
      [styles.step]: true,
      [styles[`step--${_.kebabCase(props.status)}`]]: true,
    })}>
      {[Statuses.Active, Statuses.Complete].includes(props.status) ? (
        <Icon />
      ) : (
        <div styleName="incomplete-circle" />
      )}
      <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
      <span>{props.label}</span>
    </div>
  );
}

export default withStyles(styles)(Step);
