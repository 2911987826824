import React, { Fragment } from 'react';

import Alert from '~tools/react/components/Alert';
import Button from '~tools/react/components/Button';
import { ReceiptCharge } from '~tools/react/components/Receipt';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import PaymentForm from '~tools/react/containers/PaymentForm';
import withStyles from '~tools/react/hocs/withStyles';

import styles from './BoostPaymentForm.scss';

interface Props {
  buttonLabel: string;
  charges: ReceiptCharge[];
  isLoading: boolean;
  onNavigateBack: () => void;
  onSubmit: (source: string) => Promise<void>;
}

function BoostPaymentForm(props: Props) {
  return (
    <div styleName="boost-payment-form">
      {props.isLoading ? (
        <Fragment>
          <Alert
            color={Alert.enums.Colors.Blue}
            description="Don't refresh the page - this can take a minute..."
          />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
        </Fragment>
      ) : null}
      <PaymentForm
        buttonCopy={props.buttonLabel}
        charges={props.charges}
        includeCardProcessingFee={false}
        onSubmit={props.onSubmit}
      />
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
      <Button
        label="Go back"
        style={Button.enums.Styles.Inverse}
        color={Button.enums.Colors.Blue}
        onClick={props.onNavigateBack}
      />
    </div>
  );
}

export default withStyles(styles)(BoostPaymentForm);
