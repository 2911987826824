import React, { Fragment, ReactNode } from 'react';

import Button from '~tools/react/components/Button';
import Heading from '~tools/react/components/Heading';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './SetupPromptOverlay.scss';

interface Props {
  action?: {
    label: string;
    onClick: () => void;
  };
  children: ReactNode;
  isOverlayOpen: boolean;
  subtitle?: string;
  title: string;
}

function SetupPromptOverlay(props: Props) {
  return (
    <div styleName="setup-prompt">
      {props.isOverlayOpen ? (
        <div styleName="setup-prompt__overlay">
          <Heading
            font={Heading.enums.Fonts.Secondary}
            size={Text.enums.Sizes.XSmall}
            content={props.title}
          />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
          {props.subtitle ? (
            <Text
              size={Text.enums.Sizes.Large}
              content={props.subtitle}
            />
          ) : null}
          {props.action ? (
            <Fragment>
              <VerticalSpacing
                size={VerticalSpacing.enums.Sizes.Medium}
              />
              <Button
                icon={Button.enums.Icons.ChevronRight}
                label={props.action.label}
                onClick={props.action.onClick}
              />
            </Fragment>
          ) : null}
        </div>
      ) : null}
      {props.children}
    </div>
  );
}

export default withStyles(styles)(SetupPromptOverlay);
