import moment from 'moment';
import React, { ComponentType, PureComponent } from 'react';

import { compose } from '~tools/react/hocs/utils';
import { Document, PaymentSubscriptionPlans } from '~tools/types/graphqlSchema';
import withQuery from '~tools/react/graphql/withQuery';
import withUpdateLease, { UpdateLeaseProps } from '~tools/react/graphql/mutations/leases/withUpdateLease';
import withGenerateLeaseAgreement, { GenerateLeaseAgreementProps } from '~tools/react/graphql/mutations/documents/withGenerateLeaseAgreement';
import withGenerateSubletAgreement, { GenerateSubletAgreementProps } from '~tools/react/graphql/mutations/documents/withGenerateSubletAgreement';

import AdminHighlightView from '~tools/react/components/AdminHighlightView';
import Button from '~tools/react/components/Button';
import Form, { Input, TextArea } from '~tools/react/components/Form';
import ThemedModal from '~tools/react/components/ThemedModal';

import query from './UpdateLeaseModal.gql';

interface InputProps {
  isOpen: boolean;
  leaseUuid: string;
  onClose: () => void;
  onSubmit: () => Promise<any>;
  shouldGenerateNewDocument?: boolean;
  title: string;
}

interface UpdateLeaseModalData {
  endDate: string;
  rentInDollars: number;
  securityDepositInDollars: number;
  startDate: string;
  utilitiesText: string;
  // Admin-only fields
  addendumText: string;
  cancellationDeadlineAt: string;
}

type Props = InputProps &
  QueryProps &
  UpdateLeaseProps &
  GenerateLeaseAgreementProps &
  GenerateSubletAgreementProps;

class UpdateLeaseModal extends PureComponent<Props> {
  handleSubmit = async (data: UpdateLeaseModalData) => {
    const lease = this.props.lease;
    if (!lease) return;

    let documentUuid: string | undefined;
    if (this.props.shouldGenerateNewDocument) {
      const paymentSubscription = this.props.activePaymentSubscription;

      let document: Document;
      if (paymentSubscription?.plan === PaymentSubscriptionPlans.LEASEHOLDER) {
        document = await this.props.generateSubletAgreement(lease.application.uuid, {
          addendumText: data.addendumText,
          cancellationDeadlineAt: data.cancellationDeadlineAt,
          leaseEndsAt: data.endDate,
          leaseStartsAt: data.startDate,
          rentInCents: data.rentInDollars * 100,
          securityDepositInCents: data.securityDepositInDollars * 100,
          sublessee: lease.lessee.fullName,
          sublessor: lease.lessor.fullName,
          utilitiesText: data.utilitiesText,
        });
      } else {
        document = await this.props.generateLeaseAgreement(lease.application.uuid, {
          addendumText: data.addendumText,
          cancellationDeadlineAt: data.cancellationDeadlineAt,
          leaseEndsAt: data.endDate,
          leaseStartsAt: data.startDate,
          rentInCents: data.rentInDollars * 100,
          securityDepositInCents: data.securityDepositInDollars * 100,
          lessee: lease.lessee.fullName,
          lessor: lease.lessor.fullName,
          utilitiesText: data.utilitiesText,
        });
      }
      documentUuid = document.uuid;
    }

    await this.props.updateLease(lease.uuid, {
      documentUuid,
      endDate: data.endDate,
      rentInCents: data.rentInDollars * 100,
      securityDepositInCents: data.securityDepositInDollars * 100,
      startDate: data.startDate,
    });
    await this.props.onSubmit();
    this.props.onClose();
  };

  render() {
    const lease = this.props.lease;
    return (
      <ThemedModal
        width={ThemedModal.enums.Widths.Small}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title={this.props.title}>
        <ThemedModal.ThemedModalSection>
          <Form onSubmit={this.handleSubmit}>
            <Input
              label="Monthly rent"
              labelFormat={Input.enums.LabelFormats.Stacked}
              name="rentInDollars"
              placeholder="2,500"
              type={Input.enums.Types.Currency}
              value={lease ? lease.rentInCents / 100 : undefined}
            />
            <Input
              label="Security deposit"
              labelFormat={Input.enums.LabelFormats.Stacked}
              name="securityDepositInDollars"
              placeholder="2,500"
              value={lease ? lease.securityDepositInCents / 100 : undefined}
              type={Input.enums.Types.Currency}
            />
            <Input
              label="Start date"
              labelFormat={Input.enums.LabelFormats.Stacked}
              name="startDate"
              type={Input.enums.Types.Date}
              value={lease ? moment.tz(lease.startDate, lease.addressUnit.address.timezone).format('YYYY-MM-DD') : undefined}
            />
            <Input
              label="End date"
              labelFormat={Input.enums.LabelFormats.Stacked}
              name="endDate"
              type={Input.enums.Types.Date}
              value={lease ? moment.tz(lease.endDate, lease.addressUnit.address.timezone).format('YYYY-MM-DD') : undefined}
            />
            <TextArea
              label="Utilities description (optional)"
              labelFormat={Input.enums.LabelFormats.Stacked}
              name="utilitiesText"
            />
            {this.props.viewerRole === 9 && this.props.shouldGenerateNewDocument ? (
              <AdminHighlightView>
                <Input
                  label="Cancellation Deadline"
                  labelFormat={Input.enums.LabelFormats.Stacked}
                  name="cancellationDeadlineAt"
                  type={Input.enums.Types.Date}
                />
                <TextArea
                  label="Addendum"
                  labelFormat={Input.enums.LabelFormats.Stacked}
                  name="addendumText"
                  value=""
                />
              </AdminHighlightView>
            ) : null }
            <Button
              align={Button.enums.Alignments.Right}
              label="Save"
              size={Button.enums.Sizes.Small}
              type={Button.enums.Types.Submit}
            />
          </Form>
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

interface Lease {
  addressUnit: {
    address: {
      timezone: string;
      uuid: string;
    };
    uuid: string;
  };
  application: {
    uuid: string;
  };
  lessee: {
    uuid: string;
    fullName: string;
  };
  lessor: {
    uuid: string;
    fullName: string;
  };
  endDate: string;
  rentInCents: number;
  securityDepositInCents: number;
  startDate: string;
  uuid: string;
}

interface PaymentSubscription {
  uuid: string;
  plan: PaymentSubscriptionPlans;
}

interface Viewer {
  activePaymentSubscription: PaymentSubscription | null;
  lease: Lease;
  role: 1 | 9;
  uuid: string;
}

interface Response {
  viewer: Viewer;
}

interface QueryProps {
  activePaymentSubscription: PaymentSubscription | null;
  isLoading: boolean;
  lease: Lease | null;
  viewerRole: 1 | 9;
}

export default compose(
  withQuery<InputProps, Response, { leaseUuid: string }, QueryProps>(query, {
    options: (props) => ({
      variables: {
        leaseUuid: props.leaseUuid,
      },
      skip: !props.isOpen,
    }),
    props: (props) => ({
      activePaymentSubscription: props.data?.viewer?.activePaymentSubscription ?? null,
      isLoading: props.loading,
      lease: props.data?.viewer.lease ?? null,
      viewerRole: props.data?.viewer.role ?? 1,
    }),
  }),
  withGenerateLeaseAgreement,
  withGenerateSubletAgreement,
  withUpdateLease,
)(UpdateLeaseModal) as ComponentType<InputProps>;
