// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._zaNrOD{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;margin-top:48px;width:100%}._zaNrOD button{-webkit-box-align:center;align-items:center;border:1px solid #fff;border-radius:4px;color:#fff;display:-webkit-box;display:flex;-webkit-box-flex:1;flex:1;-webkit-box-pack:center;justify-content:center;padding:12px 16px;-webkit-transition:all .15s cubic-bezier(.215,.61,.355,1);transition:all .15s cubic-bezier(.215,.61,.355,1)}._zaNrOD button._APR600{background:#f6f8fa;border-color:#f6f8fa;-webkit-transform:scale(.975);transform:scale(.975)}._zaNrOD button._APR600 svg path{stroke:#1f8eed}._zaNrOD button svg{margin-left:8px}._zaNrOD button svg path{-webkit-transition:all .15s cubic-bezier(.215,.61,.355,1);transition:all .15s cubic-bezier(.215,.61,.355,1)}._zaNrOD button:not(._APR600):hover{background:#fff;color:#000}._zaNrOD button:not(._APR600):hover svg path{stroke:#000}._zaNrOD button:last-child{margin-top:12px}._zaNrOD button:last-child svg{-webkit-transform:rotate(90deg);transform:rotate(90deg)}._zaNrOD:before{color:#fff;-webkit-box-flex:1;flex:1;font-size:18px;font-weight:300;padding-bottom:16px}", ""]);
// Exports
exports.locals = {
	"filter-flip": "_zaNrOD",
	"active": "_APR600"
};
module.exports = exports;
