import React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';
import LoadingVisual from '~web-manage/lib/common/components/__deprecated/LoadingVisual';

import query from './ListingRedirect.gql';

type InputProps = RouteComponentProps<{ listingUuid: string }>;

type Props = InputProps & QueryProps;

function ListingRedirect(props: Props) {
  const propertyManagerContract = props.viewer?.listing?.propertyManagerContract;
  if (props.isLoading || !propertyManagerContract) {
    return (
      <ManageStage>
        <LoadingVisual />
      </ManageStage>
    );
  }

  return (
    <Redirect to={`/units/${propertyManagerContract.uuid}`} />
  );
}

interface Variables {
  listingUuid: string;
}

interface Response {
  viewer: {
    listing: {
      propertyManagerContract: {
        uuid: string;
      } | null;
      uuid: string;
    } | null;
    uuid: string;
  } | null;
}

interface QueryProps extends Response {
  isLoading: boolean;
}

export default compose(
  withRouter,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: (ownProps) => ({
      skip: !ownProps.match.params.listingUuid,
      variables: {
        uuid: ownProps.match.params.listingUuid,
      },
    }),
    props: (props) => ({
      isLoading: props.loading,
      viewer: props.data?.viewer || null,
    }),
  })
)(ListingRedirect);
