import _pt from "prop-types";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';
import cx from 'classnames';
import { Line } from '@vx/shape';
import { Point } from '@vx/point';
import { Group } from '@vx/group';
import { Text } from '@vx/text';
import center from '../utils/center';
import getLabelTransform from '../utils/labelTransform';
import ORIENT from '../constants/orientation';
import toString from '../utils/toString';
import toNumberOrUndefined from '../utils/toNumberOrUndefined';
export default function Axis(_ref) {
  var children = _ref.children,
      axisClassName = _ref.axisClassName,
      axisLineClassName = _ref.axisLineClassName,
      _ref$hideAxisLine = _ref.hideAxisLine,
      hideAxisLine = _ref$hideAxisLine === void 0 ? false : _ref$hideAxisLine,
      _ref$hideTicks = _ref.hideTicks,
      hideTicks = _ref$hideTicks === void 0 ? false : _ref$hideTicks,
      _ref$hideZero = _ref.hideZero,
      hideZero = _ref$hideZero === void 0 ? false : _ref$hideZero,
      _ref$label = _ref.label,
      label = _ref$label === void 0 ? '' : _ref$label,
      labelClassName = _ref.labelClassName,
      _ref$labelOffset = _ref.labelOffset,
      labelOffset = _ref$labelOffset === void 0 ? 14 : _ref$labelOffset,
      _ref$labelProps = _ref.labelProps,
      labelProps = _ref$labelProps === void 0 ? {
    textAnchor: 'middle',
    fontFamily: 'Arial',
    fontSize: 10,
    fill: '#222'
  } : _ref$labelProps,
      _ref$left = _ref.left,
      left = _ref$left === void 0 ? 0 : _ref$left,
      _ref$numTicks = _ref.numTicks,
      numTicks = _ref$numTicks === void 0 ? 10 : _ref$numTicks,
      _ref$orientation = _ref.orientation,
      orientation = _ref$orientation === void 0 ? ORIENT.bottom : _ref$orientation,
      _ref$rangePadding = _ref.rangePadding,
      rangePadding = _ref$rangePadding === void 0 ? 0 : _ref$rangePadding,
      scale = _ref.scale,
      _ref$stroke = _ref.stroke,
      stroke = _ref$stroke === void 0 ? '#222' : _ref$stroke,
      _ref$strokeWidth = _ref.strokeWidth,
      strokeWidth = _ref$strokeWidth === void 0 ? 1 : _ref$strokeWidth,
      strokeDasharray = _ref.strokeDasharray,
      tickClassName = _ref.tickClassName,
      tickFormat = _ref.tickFormat,
      _ref$tickLabelProps = _ref.tickLabelProps,
      tickLabelProps = _ref$tickLabelProps === void 0 ? function () {
    return (
      /** tickValue, index */
      {
        textAnchor: 'middle',
        fontFamily: 'Arial',
        fontSize: 10,
        fill: '#222'
      }
    );
  } : _ref$tickLabelProps,
      _ref$tickLength = _ref.tickLength,
      tickLength = _ref$tickLength === void 0 ? 8 : _ref$tickLength,
      _ref$tickStroke = _ref.tickStroke,
      tickStroke = _ref$tickStroke === void 0 ? '#222' : _ref$tickStroke,
      tickTransform = _ref.tickTransform,
      tickValues = _ref.tickValues,
      tickComponent = _ref.tickComponent,
      _ref$top = _ref.top,
      top = _ref$top === void 0 ? 0 : _ref$top;
  var values = tickValues || (scale.ticks ? scale.ticks(numTicks) : scale.domain());
  var format = tickFormat || (scale.tickFormat ? scale.tickFormat() : toString);
  var range = scale.range();
  var range0 = Number(range[0]) + 0.5 - rangePadding;
  var range1 = Number(range[range.length - 1]) + 0.5 + rangePadding;
  var isLeft = orientation === ORIENT.left;
  var isTop = orientation === ORIENT.top;
  var axisIsHorizontal = isTop || orientation === ORIENT.bottom;
  var tickSign = isLeft || isTop ? -1 : 1;
  var position = center(scale.copy());
  var axisFromPoint = new Point({
    x: axisIsHorizontal ? range0 : 0,
    y: axisIsHorizontal ? 0 : range0
  });
  var axisToPoint = new Point({
    x: axisIsHorizontal ? range1 : 0,
    y: axisIsHorizontal ? 0 : range1
  });
  var tickLabelFontSize = 10; // track the max tick label size to compute label offset

  if (children) {
    return React.createElement(Group, {
      className: cx('vx-axis', axisClassName),
      top: top,
      left: left
    }, children({
      axisFromPoint: axisFromPoint,
      axisToPoint: axisToPoint,
      horizontal: axisIsHorizontal,
      tickSign: tickSign,
      numTicks: numTicks,
      label: label,
      rangePadding: rangePadding,
      tickLength: tickLength,
      tickFormat: format,
      tickPosition: position,
      ticks: values.map(function (value, index) {
        var scaledValue = toNumberOrUndefined(position(value));
        var from = new Point({
          x: axisIsHorizontal ? scaledValue : 0,
          y: axisIsHorizontal ? 0 : scaledValue
        });
        var to = new Point({
          x: axisIsHorizontal ? scaledValue : tickSign * tickLength,
          y: axisIsHorizontal ? tickLength * tickSign : scaledValue
        });
        return {
          value: value,
          index: index,
          from: from,
          to: to,
          formattedValue: format(value, index)
        };
      })
    }));
  }

  return React.createElement(Group, {
    className: cx('vx-axis', axisClassName),
    top: top,
    left: left
  }, values.map(function (val, index) {
    if (hideZero && (typeof val === 'number' && val === 0 || typeof val === 'string' && val === '0')) {
      return null;
    }

    var scaledValue = toNumberOrUndefined(position(val));
    var tickFromPoint = new Point({
      x: axisIsHorizontal ? scaledValue : 0,
      y: axisIsHorizontal ? 0 : scaledValue
    });
    var tickToPoint = new Point({
      x: axisIsHorizontal ? scaledValue : tickSign * tickLength,
      y: axisIsHorizontal ? tickLength * tickSign : scaledValue
    });
    var tickLabelPropsObj = tickLabelProps(val, index);
    tickLabelFontSize = Math.max(tickLabelFontSize, typeof tickLabelPropsObj.fontSize === 'number' && tickLabelPropsObj.fontSize || 0);
    var tickYCoord = tickToPoint.y + (axisIsHorizontal && !isTop ? tickLabelFontSize : 0);
    var formattedValue = format(val, index);
    return React.createElement(Group, {
      key: "vx-tick-" + val + "-" + index,
      className: cx('vx-axis-tick', tickClassName),
      transform: tickTransform
    }, !hideTicks && React.createElement(Line, {
      from: tickFromPoint,
      to: tickToPoint,
      stroke: tickStroke,
      strokeLinecap: "square"
    }), tickComponent ? tickComponent(_extends({}, tickLabelPropsObj, {
      x: tickToPoint.x,
      y: tickYCoord,
      formattedValue: formattedValue
    })) : React.createElement(Text, _extends({
      x: tickToPoint.x,
      y: tickYCoord
    }, tickLabelPropsObj), formattedValue));
  }), !hideAxisLine && React.createElement(Line, {
    className: cx('vx-axis-line', axisLineClassName),
    from: axisFromPoint,
    to: axisToPoint,
    stroke: stroke,
    strokeWidth: strokeWidth,
    strokeDasharray: strokeDasharray
  }), label && React.createElement(Text, _extends({
    className: cx('vx-axis-label', labelClassName)
  }, getLabelTransform({
    labelOffset: labelOffset,
    labelProps: labelProps,
    orientation: orientation,
    range: range,
    tickLabelFontSize: tickLabelFontSize,
    tickLength: tickLength
  }), labelProps), label));
}