import { ListingStatuses } from '~tools/types/graphqlSchema';

import UnlistListingMutation from '~tools/react/graphql/mutations/listings/unlistListing.gql';
import withMutation from '~tools/react/graphql/withMutation';

interface Listing {
  uuid: string;
  status: ListingStatuses;
}

interface Data {
  unlistListing: Listing;
}

interface Variables {
  uuid: string;
}

export interface UnlistListingProps {
  unlistListing: (uuid: string) => Promise<Listing>;
}

export default withMutation<{}, Data, Variables, UnlistListingProps>(UnlistListingMutation, {
  props: props => ({
    unlistListing: async (uuid) => {
      const res = await props.mutate({
        variables: {
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error unlisting listing.');

      return res.data.unlistListing;
    },
  }),
});
