// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._0N5bMV{width:600px}@media screen and (max-width:1127px){._0N5bMV{width:100%}}", ""]);
// Exports
exports.locals = {
	"checklist-view": "_0N5bMV"
};
module.exports = exports;
