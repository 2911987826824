import _ from 'lodash';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';

import { pageToCursor } from '~tools/react/graphql/utils';
import withQuery from '~tools/react/graphql/withQuery';

import { getAbbreviatedStreetAddress } from '~tools/utils/geography/addresses';
import { formatUnitName } from '~web-manage/lib/common/utils/addressUnit';
import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import Avatar from '~tools/react/components/Avatar';
import Card from '~tools/react/components/Card';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Tag from '~tools/react/components/Tag';
import Text from '~tools/react/components/Text';
import Table from '~tools/react/components/Table';

import LoadingVisual from '~web-manage/lib/common/components/__deprecated/LoadingVisual';

import { ListingQuestion } from './types';

import query from './Questions.gql';

const NUMBER_OF_LISTING_QUESTIONS_TO_FETCH = 10;

type InputProps = RouteComponentProps;

type Props = QueryProps & InputProps;

class Questions extends PureComponent<Props> {
  handleClick = () => {};
  render() {
    if (this.props.isLoading) {
      return (
        <ManageStage>
          <Card
            header={{ title: 'All questions' }}>
            <LoadingVisual />
          </Card>
        </ManageStage>
      );
    }

    const listingQuestions = this.props.listingQuestions;
    return (
      <ManageStage>
        <Card
          header={{ title: 'All questions' }}>
          <Table>
            <Table.TableHead>
              <Table.TableRow>
                <Table.TableHeader>Received</Table.TableHeader>
                <Table.TableHeader />
                <Table.TableHeader>Unit</Table.TableHeader>
                <Table.TableHeader>Renter</Table.TableHeader>
                <Table.TableHeader>Question</Table.TableHeader>
                <Table.TableHeader />
              </Table.TableRow>
            </Table.TableHead>
            <Table.TableBody>
              {_.map(listingQuestions, (listingQuestion) => {
                const addressUnit = listingQuestion.listing.addressUnit;
                const abbreviatedStreetAddress = getAbbreviatedStreetAddress(addressUnit.address.streetAddress1);

                const nameParts = listingQuestion.askerAccount.fullName.split(' ');
                const shortName = nameParts.length > 1 ? `${nameParts[0]} ${nameParts[1].charAt(0)}. ` : `${nameParts[0]} `;

                return (
                  <Table.TableRow key={listingQuestion.uuid} link={{ path: `/questions/${listingQuestion.uuid}` }}>
                    <Table.TableData>
                      <Text
                        content={moment(listingQuestion.createdAt).fromNow()}
                        overflow={Text.enums.OverflowValues.Ellipsis}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Small}
                      />
                    </Table.TableData>
                    <Table.TableData>
                      {listingQuestion.listingAnswer?.archivedAt ? (
                        <Tag
                          label="Archived"
                        />
                      ) : (
                        <Tag
                          color={listingQuestion.listingAnswer ? Tag.enums.Colors.Green : Tag.enums.Colors.Red}
                          icon={listingQuestion.listingAnswer ? Tag.enums.Icons.Check : undefined}
                          label={listingQuestion.listingAnswer ? 'Answered' : 'Unanswered'}
                        />
                      )}
                    </Table.TableData>
                    <Table.TableData>
                      <Text
                        content={`${abbreviatedStreetAddress}, ${formatUnitName(listingQuestion.listing.addressUnit)}`}
                        overflow={Text.enums.OverflowValues.Ellipsis}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Small}
                      />
                    </Table.TableData>
                    <Table.TableData>
                      <Avatar
                        size={Avatar.enums.Sizes.XSmall}
                        imageUrl={listingQuestion.askerAccount.photoUrl}
                      />
                      <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                      <Text
                        content={shortName}
                        overflow={Text.enums.OverflowValues.Ellipsis}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Small}
                      />
                    </Table.TableData>
                    <Table.TableData>
                      <div style={{ maxWidth: '350px', overflow: 'hidden' }}>
                        <Text
                          color={Text.enums.Colors.Secondary}
                          content={listingQuestion.prompt}
                          overflow={Text.enums.OverflowValues.Ellipsis}
                          shouldWrap={false}
                          size={Text.enums.Sizes.Small}
                        />
                      </div>
                    </Table.TableData>
                  </Table.TableRow>
                );
              })}
              {this.props.listingQuestionsPageInfo ? (
                <Table.TablePaginator
                  startCursor={this.props.listingQuestionsPageInfo.startCursor || ''}
                  endCursor={this.props.listingQuestionsPageInfo.endCursor || ''}
                  resultsPerPage={NUMBER_OF_LISTING_QUESTIONS_TO_FETCH}
                  totalResults={this.props.listingQuestionsTotal}
                />
              ) : null}
            </Table.TableBody>
          </Table>
        </Card>
      </ManageStage>
    );
  }
}

interface PageInfo {
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
}

interface Response {
  viewer: {
    uuid: string;
    listingQuestions: ListingQuestion[];
    listingQuestionsPageInfo: PageInfo;
    listingQuestionsTotal: number;
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  listingQuestions: ListingQuestion[];
  listingQuestionsPageInfo?: PageInfo;
  listingQuestionsTotal: number;
}

export default withQuery<InputProps, Response, {}, QueryProps>(query, {
  options: (props) => {
    const pageString = queryString.parse(props.location.search).page;
    const page = _.parseInt(typeof pageString === 'string' ? pageString : '1') ?? undefined;
    const after = page ? pageToCursor(page, NUMBER_OF_LISTING_QUESTIONS_TO_FETCH) : undefined;
    return {
      variables: {
        after,
        first: NUMBER_OF_LISTING_QUESTIONS_TO_FETCH,
      },
    };
  },
  props: props => ({
    isLoading: props.loading,
    listingQuestions: props.data?.viewer?.listingQuestions || [],
    listingQuestionsPageInfo: props.data?.viewer?.listingQuestionsPageInfo,
    listingQuestionsTotal: props.data?.viewer?.listingQuestionsTotal || 0,
  }),
})(Questions);
