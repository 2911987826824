import _ from 'lodash';
import classNames from 'classnames';
import React, { Children, Component, Fragment, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import withStyles from '~tools/react/hocs/withStyles';

import DynamicHeightToggler from '~tools/react/components/DynamicHeightToggler';

import Check from '~tools/svgs/icons/interface/check.svg';
import ChevronDown from '~tools/svgs/icons/interface/chevron-down.svg';
import Lock from '~tools/svgs/icons/interface/lock.svg';

import * as enums from './enums';
import NewBadge from './svgs/new-badge.svg';

import styles from './VerticalMenuLink.scss';

interface Props {
  children?: ReactNode,
  color: enums.Colors,
  hasNotification?: boolean,
  isActive?: boolean,
  isCompleted?: boolean,
  isDisabled?: boolean,
  isExact?: boolean,
  isExpanded?: boolean,
  isNew?: boolean,
  label: string,
  onClick?: () => void,
  path: string,
  style?: enums.Styles,
}

class VerticalMenuLink extends Component<Props> {
  static defaultProps = {
    color: enums.Colors.Blue,
    isDisabled: false,
    isExpanded: false,
    style: enums.Styles.Primary,
  };
  static enums = enums;

  render() {
    return (
      <Fragment>
        <NavLink
          className={classNames({
            [styles['vertical-menu-link']]: true,
            [styles['vertical-menu-link--active']]: this.props.isActive,
            [styles['vertical-menu-link--disabled']]: this.props.isDisabled,
            [styles['vertical-menu-link--expanded']]: this.props.isExpanded && !this.props.isActive,
            [styles[`vertical-menu-link--color-${_.kebabCase(this.props.color)}`]]: this.props.color,
            [styles[`vertical-menu-link--style-${_.kebabCase(this.props.style)}`]]: this.props.style,
          })}
          activeClassName={styles['vertical-menu-link--active']}
          exact={this.props.isExact}
          isActive={this.props.isExact ? undefined : this.isActive}
          onClick={this.props.onClick}
          to={this.props.path}>
          <div styleName="vertical-menu-link__label">
            {this.props.label}
          </div>
          {this.props.isDisabled ? (
            <div styleName="vertical-menu-link__disabled-icon">
              <Lock />
            </div>
          ) : null}
          {this.props.isExpanded || this.props.isCompleted ? (
            <div styleName="vertical-menu-link__completed-icon">
              {this.props.isExpanded ? <ChevronDown /> : <Check />}
            </div>
          ) : null}
          {this.props.isNew && !this.props.isCompleted && !this.props.isExpanded ? (
            <NewBadge />
          ) : null}
          {this.props.hasNotification ? (
            <span />
          ) : null }
        </NavLink>
        {Children.toArray(this.props.children).length ? (
          <DynamicHeightToggler isOpen={!!this.props.isExpanded}>
            <div styleName="dropdown">
              {this.props.children}
            </div>
          </DynamicHeightToggler>
        ) : null}
      </Fragment>
    );
  }

  isActive = (match, location) => {
    return location.pathname.indexOf(this.props.path) === 0;
  }
}

export default withStyles(styles)(VerticalMenuLink);
