import withMutation from '~tools/react/graphql/withMutation';
import { UpdateVoteInputType } from '~tools/types/graphqlSchema';

import UpdateVoteMutation from './updateVote.gql';

interface Response {
  updateVote: {
    uuid: string;
    sentiment: number;
  };
}

export interface UpdateVoteProps {
  updateVote: (uuid: string, input: UpdateVoteInputType) => Promise<{ uuid: string }>;
}

export default withMutation<{}, Response, { uuid: string, input: UpdateVoteInputType }, UpdateVoteProps>(UpdateVoteMutation, {
  props: props => ({
    updateVote: async (uuid: string, input: UpdateVoteInputType) => {
      const res = await props.mutate({
        variables: { uuid, input },
      });

      if (!res || !res.data) throw new Error('Error in creating vote');
      if (!res.data.updateVote) throw new Error('No updateVote returned');

      return res.data.updateVote;
    },
  }),
});
