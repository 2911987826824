import classNames from 'classnames';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Card from '~tools/react/components/Card';

import Event from './components/Event';

import styles from './CalendarDay.scss';

interface Props {
  date: string;
  events?: {
    account: {
      firstName: string;
      fullName: string;
      photoUrl: string;
      uuid: string;
    } | null;
    endsAt: Moment;
    label?: string;
    link?: {
      label: string;
      path: string;
    };
    startsAt: Moment;
    uuid: string;
  }[];
  timezone: string;
}

interface State {
  isEventExpanded: boolean;
  isOverlayMounted: boolean;
  isOverlayOpen: boolean;
}

const MAX_EVENTS = 5;

class CalendarDay extends PureComponent<Props, State> {
  didMount = false;
  didClickOverlay = false;
  timeout: NodeJS.Timeout | null = null;

  state = {
    isEventExpanded: false,
    isOverlayMounted: false,
    isOverlayOpen: false,
  };

  componentDidMount() {
    this.didMount = true;
  }

  componentWillUnmount() {
    this.didMount = false;
    if (this.timeout) clearTimeout(this.timeout);
    document.removeEventListener('click', this.handleCloseOverlay);
  }

  handleClickOverlay = () => {
    this.didClickOverlay = true;
  };
  handleCloseOverlay = () => {
    if (!this.didMount) return;
    if (this.didClickOverlay) {
      this.didClickOverlay = false;
      return;
    }

    document.removeEventListener('click', this.handleCloseOverlay);
    this.setState({ isOverlayOpen: false });
    this.timeout = setTimeout(() => this.setState({ isOverlayMounted: false }), 260);
  };
  handleOpenOverlay = () => {
    document.addEventListener('click', this.handleCloseOverlay);
    this.setState({ isOverlayMounted: true });
    this.timeout = setTimeout(() => this.setState({ isOverlayOpen: true }), 0);
  };

  handleEventExpanded = () => this.setState({ isEventExpanded: true });
  handleEventClosed = () => this.setState({ isEventExpanded: false });

  render() {
    const date = moment.tz(this.props.date, this.props.timezone);
    const today = moment.tz(this.props.timezone).startOf('day');

    const events = _.sortBy(this.props.events, 'startsAt');
    const displayedEvents = events.length > MAX_EVENTS ? _.take(events, MAX_EVENTS - 1) : events;

    const isToday = today.isSame(this.props.date);

    return (
      <div styleName="calendar-day">
        <div
          className={classNames({
            [styles.day]: true,
            [styles['day--active']]: isToday,
            [styles['day--faded']]: today.isAfter(this.props.date),
          })}>
          <div styleName="day__header">
            <h4>{date.date()}</h4>
            <h5>{date.format('dddd')}</h5>
          </div>
          <div styleName="day__content">
            {_.map(displayedEvents, scheduledTime => (
              <Event
                account={scheduledTime.account || undefined}
                endsAt={moment.tz(scheduledTime.endsAt, this.props.timezone)}
                isOtherEventExpanded={this.state.isEventExpanded}
                key={scheduledTime.uuid}
                label={scheduledTime.label}
                link={scheduledTime.link}
                onClosed={this.handleEventClosed}
                onExpanded={this.handleEventExpanded}
                startsAt={moment.tz(scheduledTime.startsAt, this.props.timezone)}
              />
            ))}
            {events.length > MAX_EVENTS ? (
              <button
                styleName="day__button"
                onClick={this.handleOpenOverlay}>
                {events.length - (MAX_EVENTS - 1)} more
              </button>
            ) : null}
          </div>
        </div>
        {this.state.isOverlayMounted ? (
          <div
            className={classNames({
              [styles.overlay]: true,
              [styles['overlay--open']]: this.state.isOverlayOpen,
            })}
            onClick={this.handleClickOverlay}>
            <Card>
              <div styleName="day">
                <div styleName="day__header">
                  <h4>{date.date()}</h4>
                  <h5>{date.format('dddd')}</h5>
                </div>
                <div styleName="day__content">
                  {_.map(events, scheduledTime => (
                    <Event
                      account={scheduledTime.account || undefined}
                      endsAt={moment.tz(scheduledTime.endsAt, this.props.timezone)}
                      isOtherEventExpanded={this.state.isEventExpanded}
                      key={scheduledTime.uuid}
                      label={scheduledTime.label}
                      link={scheduledTime.link}
                      onClosed={this.handleEventClosed}
                      onExpanded={this.handleEventExpanded}
                      startsAt={moment.tz(scheduledTime.startsAt, this.props.timezone)}
                    />
                  ))}
                </div>
              </div>
            </Card>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(CalendarDay);
