// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._rrEirZ{background-color:#eff2f7;width:50%}._rrEirZ,._80ANqk{border-radius:10px;height:10px}", ""]);
// Exports
exports.locals = {
	"progress-bar": "_rrEirZ",
	"progress-bar__progress": "_80ANqk"
};
module.exports = exports;
