import React, { Component, SyntheticEvent } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import withStyles from '~tools/react/hocs/withStyles';
import Button from '~tools/react/components/Button';
import { Types as ButtonTypes } from '~tools/react/components/Button/enums';

import { Colors } from '../../../../enums';
import styles from './AnimatedStepsFlowStepFooter.scss';

interface Action {
  isDisabled?: boolean;
  isLoading?: boolean;
  label: string;
  onClick: (e: SyntheticEvent<EventTarget>) => void;
  type?: ButtonTypes;
}

interface LinkType {
  path: string;
  label: string;
  type?: ButtonTypes;
}

interface Props {
  color: Colors;
  primaryAction?: Action | boolean;
  primaryLink?: LinkType;
  secondaryAction?: Action;
  secondaryLink?: LinkType;
}

interface State {
  isPrimaryActionLoading: boolean;
  isSecondaryActionLoading: boolean;
}

class AnimatedStepsFlowStepFooter extends Component<Props, State> {
  static defaultProps = {
    color: Colors.Blue,
  };

  state = {
    isPrimaryActionLoading: false,
    isSecondaryActionLoading: false,
  };

  handleClickPrimaryAction = async (e) => {
    const onClick = _.get(this.props.primaryAction, 'onClick', undefined);
    if (!onClick) return;

    this.setState({ isPrimaryActionLoading: true });
    try {
      await onClick(e);
      this.setState({ isPrimaryActionLoading: false });
    } catch (err) {
      this.setState({ isPrimaryActionLoading: false });
    }
  };

  handleClickSecondaryAction = async (e) => {
    const onClick = _.get(this.props.secondaryAction, 'onClick', undefined);
    if (!onClick) return;

    this.setState({ isSecondaryActionLoading: true });
    try {
      await onClick(e);
      this.setState({ isSecondaryActionLoading: false });
    } catch (err) {
      this.setState({ isSecondaryActionLoading: false });
    }
  };

  render() {
    const primaryAction = this.props.primaryAction;
    const primaryLink = this.props.primaryLink;
    const secondaryAction = this.props.secondaryAction;
    const secondaryLink = this.props.secondaryLink;

    return (
      <div styleName="step-footer">
        {secondaryLink ? (
          <Button
            color={Button.enums.Colors.Gray}
            label={_.get(secondaryLink, 'label', 'Back')}
            link={{ path: secondaryLink.path }}
            style={Button.enums.Styles.Outline}
            type={Button.enums.Types.Button}
          />
        ) : null}
        {secondaryAction ? (
          <Button
            isDisabled={_.get(secondaryAction, 'isDisabled')}
            isLoading={
              _.isBoolean(_.get(secondaryAction, 'isLoading')) ? secondaryAction.isLoading : this.state.isSecondaryActionLoading
            }
            color={Button.enums.Colors.Gray}
            label={_.get(secondaryAction, 'label', 'Back')}
            onClick={this.handleClickSecondaryAction}
            style={Button.enums.Styles.Outline}
            type={Button.enums.Types.Button}
          />
        ) : null}
        {primaryLink ? (
          <Link to={primaryLink.path}>
            <Button
              color={this.props.color}
              label={_.get(primaryLink, 'label', 'Continue')}
              type={_.get(primaryLink, 'type', Button.enums.Types.Button)}
            />
          </Link>
        ) : null}
        {!primaryLink && primaryAction !== false && (
          <Button
            align={Button.enums.Alignments.Right}
            color={this.props.color}
            isDisabled={_.get(primaryAction, 'isDisabled')}
            isLoading={
              // @ts-ignore
              _.isBoolean(_.get(primaryAction, 'isLoading')) ? primaryAction.isLoading : this.state.isPrimaryActionLoading
            }
            label={_.get(primaryAction, 'label', 'Continue')}
            onClick={this.handleClickPrimaryAction}
            type={_.get(primaryAction, 'type', Button.enums.Types.Button)}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(AnimatedStepsFlowStepFooter);
