import _ from 'lodash';
import dayjs from 'dayjs';
import queryString from 'query-string';
import React, { Fragment, PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import withQuery from '~tools/react/graphql/withQuery';
import { pageToCursor } from '~tools/react/graphql/utils';

import { formatAsUSD } from '~tools/utils/string';
import { formatTimestamp } from '~tools/utils/time';
import { formatFullAddress } from '~web-manage/lib/common/utils/addressUnit';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import Card from '~tools/react/components/Card';
import Text from '~tools/react/components/Text';
import Tag from '~tools/react/components/Tag';
import Table from '~tools/react/components/Table';

import query from './Leases.gql';

type Props = InputProps & QueryProps;

const NUMBER_OF_LEASES_TO_FETCH = 10;

interface AddressUnit {
  name: string | null;
  address: {
    city: string;
    state: string;
    streetAddress1: string;
    uuid: string;
    zipCode: string;
  }
}

class Leases extends PureComponent<Props> {
  render() {
    const leases = this.props.leases;

    return (
      <ManageStage>
        <Card
          header={{
            title: 'All leases',
            subtitle: 'Manage your leases',
          }}>
          <Table>
            <Table.TableHead>
              <Table.TableRow>
                <Table.TableHeader>Lessee</Table.TableHeader>
                <Table.TableHeader />
                <Table.TableHeader>Rent</Table.TableHeader>
                <Table.TableHeader>Unit</Table.TableHeader>
                <Table.TableHeader>End</Table.TableHeader>
                <Table.TableHeader />
              </Table.TableRow>
            </Table.TableHead>
            <Table.TableBody>
              {leases.length > 0 ? (
                <Fragment>
                  {_.map(leases, (lease) => (
                    <Table.TableRow key={lease.uuid} link={{ path: `/leases/${lease.uuid}` }}>
                      <Table.TableData>
                        <Text
                          content={lease.lessee?.fullName || lease.leaseInvite?.email || '-'}
                        />
                      </Table.TableData>
                      <Table.TableData>
                        {!lease.lessee && lease.leaseInvite ? (
                          <Tag
                            color={Tag.enums.Colors.Gray}
                            icon={Tag.enums.Icons.Mail}
                            label="Invited"
                          />
                        ) : null}
                        {lease.lessee && dayjs(lease.startDate).isAfter(dayjs()) ? (
                          <Tag
                            color={Tag.enums.Colors.Orange}
                            icon={Tag.enums.Icons.Clock}
                            label="Upcoming"
                          />
                        ) : null}
                        {lease.lessee &&
                          dayjs(lease.startDate).isBefore(dayjs()) &&
                          dayjs(lease.endDate).isAfter(dayjs()) ? (
                            <Tag
                              color={Tag.enums.Colors.Green}
                              icon={Tag.enums.Icons.Refresh}
                              label="Active"
                            />
                          ) : null}
                        {lease.lessee && dayjs(lease.endDate).isBefore(dayjs()) ? (
                          <Tag
                            color={Tag.enums.Colors.Gray}
                            label="Completed"
                          />
                        ) : null}
                      </Table.TableData>
                      <Table.TableData>
                        <Text
                          content={`$${formatAsUSD(lease.rentInCents / 100, true)} /mo`}
                        />
                      </Table.TableData>
                      <Table.TableData>
                        <Text
                          content={formatFullAddress(lease.addressUnit)}
                        />
                      </Table.TableData>
                      <Table.TableData>
                        <Text
                          content={formatTimestamp(lease.endDate, 'MMM D, YYYY')}
                        />
                      </Table.TableData>
                    </Table.TableRow>
                  ))}
                  {this.props.leasesPageInfo ? (
                    <Table.TablePaginator
                      startCursor={this.props.leasesPageInfo.startCursor || ''}
                      endCursor={this.props.leasesPageInfo.endCursor || ''}
                      resultsPerPage={NUMBER_OF_LEASES_TO_FETCH}
                      totalResults={this.props.leasesTotal}
                    />
                  ) : null}
                </Fragment>
              ) : (
                <Table.TableEmptyState
                  label="Here’s where your first lease will show up once you have a tenant."
                />
              )}
            </Table.TableBody>
          </Table>
        </Card>
      </ManageStage>
    );
  }
}

interface Lease {
  addressUnit: AddressUnit;
  document: {
    fullyExecutedAt: string | null;
    uuid: string;
  } | null;
  endDate: string;
  leaseInvite: {
    email: string;
    uuid: string;
  } | null;
  lessee: {
    fullName: string;
    uuid: string;
  } | null;
  rentInCents: number;
  startDate: string;
  uuid: string
}

interface Response {
  viewer: {
    leases: Lease[];
    leasesTotal: number;
    leasesPageInfo: {
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string | null;
    };
    uuid: string;
  } | null;
}

interface Variables {
  after?: string;
  first?: number;
}

type InputProps = RouteComponentProps;

interface QueryProps {
  isLoading: boolean;
  leases: Lease[];
  leasesTotal: number;
  leasesPageInfo: {
    endCursor: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string | null;
  } | null;
}

export default withQuery<InputProps, Response, Variables, QueryProps>(query, {
  options: (props) => {
    const pageString = queryString.parse(props.location.search).page;
    const page = _.parseInt(typeof pageString === 'string' ? pageString : '1') ?? undefined;
    const after = page > 1 ? pageToCursor(page, NUMBER_OF_LEASES_TO_FETCH) : undefined;
    return {
      variables: {
        after,
        filter: {
         isActive: true,
        },
        first: NUMBER_OF_LEASES_TO_FETCH,
      },
    };
  },
  props: (props) => ({
    isLoading: props.loading,
    leases: props.data?.viewer?.leases ?? [],
    leasesTotal: props.data?.viewer?.leasesTotal ?? 0,
    leasesPageInfo: props.data?.viewer?.leasesPageInfo ?? null,
  }),
})(Leases);
