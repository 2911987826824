import SetPrimaryPayoutAccountMutation from '~tools/react/graphql/mutations/payoutAccounts/setPrimaryPayoutAccount.gql';
import withMutation from '~tools/react/graphql/withMutation';

interface PayoutAccount {
  id: string;
  defaultForCurrency: boolean;
}

interface Data {
  setPrimaryPayoutAccount: PayoutAccount;
}

interface Variables {
  accountId: string;
}

export interface SetPrimaryPayoutAccountProps {
  setPrimaryPayoutAccount: (accountId: string) => Promise<PayoutAccount>;
}
export default withMutation<{}, Data, Variables, SetPrimaryPayoutAccountProps>(SetPrimaryPayoutAccountMutation, {
  props: props => ({
    setPrimaryPayoutAccount: async (accountId: string) => {
      const res = await props.mutate({
        variables: {
          accountId,
        },
      });
      if (!res || !res.data) throw new Error('Error setting primary payout account.');

      return res.data.setPrimaryPayoutAccount;
    },
  }),
});
