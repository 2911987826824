export function removeLeadingSlashes(input: string) {
  return input.replace(/^\/+/g, '');
}

export function formatAsUSD(amount: number | string, isFloat?: boolean): string {
  if (!amount && amount !== 0) return '';

  let stringNum = '';
  if (typeof amount === 'string') {
    stringNum = parseInt(amount, 10).toString();
    if (isFloat) stringNum = parseFloat(amount).toFixed(2);
  } else if (typeof amount === 'number') {
    stringNum = amount.toFixed(0).toString();
    if (isFloat) stringNum = parseFloat(amount.toString()).toFixed(2);
  }

  return stringNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatAsSlug(value: string): string {
  if (!value) return '';

  return value.replace(/, /g, '--').replace(/ /g, '-');
}

export function removeMarkdown(md: string, rawOptions?: {
  listUnicodeChar: string | undefined,
  stripListLeaders: boolean,
  gfm: boolean,
} | undefined): string {
  if (!md) return '';

  const options = rawOptions || {
    gfm: true,
    listUnicodeChar: undefined,
    stripListLeaders: true,
  };

  let output = md || '';

  // Remove horizontal rules (stripListHeaders conflict with this rule,
  // which is why it has been moved to the top)
  output = output.replace(/^(-\s*?|\*\s*?|_\s*?){3,}\s*$/gm, '');

  try {
    if (options.stripListLeaders) {
      if (options.listUnicodeChar) {
        output = output.replace(/^([\s\t]*)([\*\-\+]|\d+\.)\s+/gm, `${options.listUnicodeChar} $1`);
      } else {
        output = output.replace(/^([\s\t]*)([\*\-\+]|\d+\.)\s+/gm, '$1');
      }
    }
    if (options.gfm) {
      output = output
        // Header
        .replace(/\n={2,}/g, '\n')
        // Strikethrough
        .replace(/~~/g, '')
        // Fenced codeblocks
        .replace(/`{3}.*\n/g, '');
    }
    output = output
      // Remove HTML tags
      .replace(/<[^>]*>/g, '')
      // Remove setext-style headers
      .replace(/^[=\-]{2,}\s*$/g, '')
      // Remove footnotes?
      .replace(/\[\^.+?\](\: .*?$)?/g, '')
      .replace(/\s{0,2}\[.*?\]: .*?$/g, '')
      // Remove images
      .replace(/\!\[.*?\][\[\(].*?[\]\)]/g, '')
      // Remove inline links
      .replace(/\[(.*?)\][\[\(].*?[\]\)]/g, '$1')
      // Remove blockquotes
      .replace(/^\s{0,3}>\s?/g, '')
      // Remove reference-style links?
      .replace(/^\s{1,2}\[(.*?)\]: (\S+)( ".*?")?\s*$/g, '')
      // Remove atx-style headers
      .replace(/^(\n)?\s{0,}#{1,6}\s+| {0,}(\n)?\s{0,}#{0,} {0,}(\n)?\s{0,}$/gm, '$1$2$3')
      // Remove emphasis (repeat the line to remove double emphasis)
      .replace(/([\*_]{1,3})(\S.*?\S{0,1})\1/g, '$2')
      .replace(/([\*_]{1,3})(\S.*?\S{0,1})\1/g, '$2')
      // Remove code blocks
      .replace(/(`{3,})(.*?)\1/gm, '$2')
      // Remove inline code
      .replace(/`(.+?)`/g, '$1')
      // Replace two or more newlines with exactly two? Not entirely sure this belongs here...
      .replace(/\n{2,}/g, '\n\n');
  } catch (e) {
    return md;
  }
  return output;
}
