// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._6OAFmv{display:-webkit-box;display:flex;height:100%;position:relative;width:100%;z-index:0}._6OAFmv ._nrBY9K{left:-288px;position:absolute;top:-600px;z-index:0}._6OAFmv ._drDbRB{position:absolute;right:-408px;top:-760px;z-index:0}._6OAFmv ._wdjbkA{bottom:-80px;left:-328px;position:absolute;z-index:0}._6OAFmv ._br0Qbb{bottom:80px;position:absolute;right:-288px;z-index:0}", ""]);
// Exports
exports.locals = {
	"brand-shapes": "_6OAFmv",
	"brand-shapes__left-top": "_nrBY9K",
	"brand-shapes__right-top": "_drDbRB",
	"brand-shapes__left-bottom": "_wdjbkA",
	"brand-shapes__right-bottom": "_br0Qbb"
};
module.exports = exports;
