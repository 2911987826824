// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._O0GMpo{-webkit-box-align:center;align-items:center;background:rgba(0,0,0,.75);display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;height:100vh;-webkit-box-pack:center;justify-content:center;left:0;position:fixed;top:0;width:100vw;z-index:10}@media screen and (max-width:915px){._O0GMpo{padding:24px}}._O0GMpo>button{border:none;height:100%;left:0;position:absolute;top:0;width:100%}._O0GMpo>div{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-pack:center;justify-content:center;margin-top:32px;width:100%}._O0GMpo>div>button:last-child{margin-left:16px}._O0GMpo p{color:#fff;font-size:28px;font-weight:700;line-height:40px;margin:0;text-align:center}", ""]);
// Exports
exports.locals = {
	"confirmation-modal": "_O0GMpo"
};
module.exports = exports;
