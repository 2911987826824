// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._BX0pva{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;margin:0 32px 0 0}._BX0pva p{display:inline-block;margin:0}._BX0pva:last-child{margin:0}._BX0pva ._2dAGyE{height:24px;margin:-1px 12px 0 0;width:24px}._BX0pva ._5aAV2N{color:#c0cad8;height:18px;margin:-3px 0 0 8px;-webkit-transition:all .25s;transition:all .25s}._BX0pva ._5aAV2N svg{height:18px;margin-right:0}._BX0pva ._5aAV2N:hover{color:#273444}@media screen and (max-width:1127px){._BX0pva svg{margin-right:12px}}", ""]);
// Exports
exports.locals = {
	"rooms-icon": "_BX0pva",
	"rooms-icon__svg": "_2dAGyE",
	"rooms-icon__info": "_5aAV2N"
};
module.exports = exports;
