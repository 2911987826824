export enum Align {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT',
}

export enum Colors {
  Brand = 'BRAND',
  Error = 'ERROR',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Success = 'SUCCESS',
}

export enum Fonts {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
}

export enum Sizes {
  XXLarge = 'XXLARGE',
  XLarge = 'XLARGE',
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
  XSmall = 'XSMALL',
  XXSmall = 'XXSMALL',
  XXXSmall = 'XXXSMALL',
}

export enum Styles {
  Negative = 'NEGATIVE',
}

export enum Priorities {
  Five = 5,
  Four = 4,
  One = 1,
  Six = 6,
  Three = 3,
  Two = 2,
}
