import React, { ComponentType, PureComponent } from 'react';
import withStyles from '~tools/react/hocs/withStyles';
import { Link } from 'react-router-dom';

import Button from '~tools/react/components/Button';
import ThemedModal from '~tools/react/components/ThemedModal';

import * as enums from '~web-manage/lib/common/scenes/ListingSetup/enums';

import styles from './IncompleteFieldsModal.scss';

interface Props {
  completedState: { [attr: string]: boolean; };
  isOpen: boolean;
  onClose: () => void;
}

class IncompleteFieldsModal extends PureComponent<Props> {
  render() {
    const completedState = this.props.completedState;
    return (
      <ThemedModal
        title="Your listing isn't ready yet"
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}>
        <ThemedModal.ThemedModalSection>
          <div styleName="incomplete-fields">
            <ul>
              {!completedState[enums.Steps.Rooms] ? (
                <h5>Property basics</h5>
              ) : null}
              {!completedState[enums.Steps.Rooms] ? (
                <li>
                  Bedrooms and bathrooms
                  <Link to="rooms">
                    <Button
                      onClick={this.props.onClose}
                      style={Button.enums.Styles.Outline}
                      size={Button.enums.Sizes.XSmall}
                      label="Finish"
                    />
                  </Link>
                </li>
              ) : null}
              {!completedState[enums.Steps.Payments] || !completedState[enums.Steps.Availability] ? (
                <h5>Payment details</h5>
              ) : null}
              {!completedState[enums.Steps.Payments] ? (
                <li>
                  Rent price
                  <Link to="payments">
                    <Button
                      onClick={this.props.onClose}
                      style={Button.enums.Styles.Outline}
                      size={Button.enums.Sizes.XSmall}
                      label="Finish"
                    />
                  </Link>
                </li>
              ) : null}
              {!completedState[enums.Steps.Availability] ? (
                <li>
                  Earliest move in date and lease duration
                  <Link to="availability">
                    <Button
                      onClick={this.props.onClose}
                      style={Button.enums.Styles.Outline}
                      size={Button.enums.Sizes.XSmall}
                      label="Finish"
                    />
                  </Link>
                </li>
              ) : null}
              {!completedState[enums.Steps.Description] || !completedState[enums.Steps.Photos] ? (
                <h5>Post details</h5>
              ) : null}
              {!completedState[enums.Steps.Description] ? (
                <li>
                  Listing description
                  <Link to="description">
                    <Button
                      onClick={this.props.onClose}
                      style={Button.enums.Styles.Outline}
                      size={Button.enums.Sizes.XSmall}
                      label="Finish"
                    />
                  </Link>
                </li>
              ) : null}
              {!completedState[enums.Steps.Photos] ? (
                <li>
                  At least one photo
                  <Link to="photos">
                    <Button
                      onClick={this.props.onClose}
                      style={Button.enums.Styles.Outline}
                      size={Button.enums.Sizes.XSmall}
                      label="Finish"
                    />
                  </Link>
                </li>
              ) : null}
            </ul>
          </div>
          <Button
            onClick={this.props.onClose}
            style={Button.enums.Styles.Outline}
            width={Button.enums.Widths.Full}
            label="Close"
          />
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

export default withStyles(styles)(IncompleteFieldsModal) as ComponentType<Props>;
