import {
  QualificationSubtypes,
  QualificationThresholdModifiers,
  QualificationThresholdTypes,
  QualificationThresholdUnits,
  QualificationThresholdVariables,
  QualificationTypes,
} from '~tools/types/graphqlSchema';

import {
  Qualification,
} from './types';

export const getMaxRequirementCount = (qualificationType: QualificationTypes) => {
  switch (qualificationType) {
    case QualificationTypes.INCOME: {
      return 3;
    }
    case QualificationTypes.CREDIT_SCORE:
    case QualificationTypes.CIVIL_HISTORY:
    case QualificationTypes.CRIMINAL_HISTORY:
    default: {
      return 2;
    }
  }
};

export const getDefaultRequirementsForType = (qualificationType: QualificationTypes): Qualification[] => {
  switch (qualificationType) {
    case QualificationTypes.INCOME: {
      return [
        {
          thresholdModifier: QualificationThresholdModifiers.EQUAL_TO,
          thresholdType: QualificationThresholdTypes.BOOLEAN,
          thresholdValue: 1,
          type: QualificationTypes.INCOME,
        },
        {
          subtype: QualificationSubtypes.ANNUAL,
          thresholdModifier: QualificationThresholdModifiers.GREATER_THAN,
          thresholdType: QualificationThresholdTypes.MULTIPLIER,
          thresholdValue: 36,
          thresholdVariable: QualificationThresholdVariables.MONTHLY_RENT_IN_USD,
          type: QualificationTypes.INCOME,
        },
      ];
    }
    case QualificationTypes.CREDIT_SCORE: {
      return [
        {
          thresholdModifier: QualificationThresholdModifiers.EQUAL_TO,
          thresholdType: QualificationThresholdTypes.BOOLEAN,
          thresholdValue: 1,
          type: QualificationTypes.CREDIT_SCORE,
        },
        {
          thresholdModifier: QualificationThresholdModifiers.GREATER_THAN,
          thresholdType: QualificationThresholdTypes.NUMBER,
          thresholdValue: 600,
          thresholdUnits: QualificationThresholdUnits.POINTS,
          type: QualificationTypes.CREDIT_SCORE,
        },
      ];
    }
    case QualificationTypes.CIVIL_HISTORY: {
      return [
        {
          thresholdModifier: QualificationThresholdModifiers.EQUAL_TO,
          thresholdType: QualificationThresholdTypes.BOOLEAN,
          thresholdValue: 1,
          type: QualificationTypes.CIVIL_HISTORY,
        },
        {
          subtype: QualificationSubtypes.EVICTION_RECORD,
          thresholdModifier: QualificationThresholdModifiers.LESS_THAN,
          thresholdType: QualificationThresholdTypes.NUMBER,
          thresholdUnits: QualificationThresholdUnits.JUDGEMENTS,
          thresholdValue: 1,
          type: QualificationTypes.CIVIL_HISTORY,
        },
      ];
    }
    case QualificationTypes.CRIMINAL_HISTORY: {
      return [
        {
          thresholdModifier: QualificationThresholdModifiers.EQUAL_TO,
          thresholdType: QualificationThresholdTypes.BOOLEAN,
          thresholdValue: 1,
          type: QualificationTypes.CRIMINAL_HISTORY,
        },
        {
          subtype: QualificationSubtypes.FELONY_RECORD,
          thresholdModifier: QualificationThresholdModifiers.LESS_THAN,
          thresholdType: QualificationThresholdTypes.NUMBER,
          thresholdUnits: QualificationThresholdUnits.JUDGEMENTS,
          thresholdValue: 2,
          type: QualificationTypes.CRIMINAL_HISTORY,
        },
      ];
    }
    default: {
      return [];
    }
  }
};

export const getDefaultVerificationMethodSlugsForType = (qualificationType: QualificationTypes): string[] => {
  switch (qualificationType) {
    case QualificationTypes.INCOME: {
      return [
        '3-most-recent-paystubs-minimum-1-month',
        '3-most-recent-bank-statements-minimum-3-months',
      ];
    }
    case QualificationTypes.CREDIT_SCORE: {
      return [];
    }
    case QualificationTypes.CIVIL_HISTORY: {
      return [];
    }
    case QualificationTypes.CRIMINAL_HISTORY: {
      return [];
    }
    default: {
      return [];
    }
  }
};

export const getStaticAttributesForType = (qualificationType: QualificationTypes) => {
  switch (qualificationType) {
    case QualificationTypes.INCOME: {
      return {
        subtype: QualificationSubtypes.ANNUAL,
        thresholdType: QualificationThresholdTypes.MULTIPLIER,
        thresholdVariable: QualificationThresholdVariables.MONTHLY_RENT_IN_USD,
        type: QualificationTypes.INCOME,
      };
    }
    case QualificationTypes.CREDIT_SCORE: {
      return {
        thresholdType: QualificationThresholdTypes.NUMBER,
        thresholdUnits: QualificationThresholdUnits.POINTS,
        type: QualificationTypes.CREDIT_SCORE,
      };
    }
    case QualificationTypes.CIVIL_HISTORY: {
      return {
        subtype: QualificationSubtypes.EVICTION_RECORD,
        thresholdType: QualificationThresholdTypes.NUMBER,
        thresholdUnits: QualificationThresholdUnits.JUDGEMENTS,
        type: QualificationTypes.CIVIL_HISTORY,
      };
    }
    case QualificationTypes.CRIMINAL_HISTORY:
    default: {
      return {
        subtype: QualificationSubtypes.FELONY_RECORD,
        thresholdType: QualificationThresholdTypes.NUMBER,
        thresholdUnits: QualificationThresholdUnits.JUDGEMENTS,
        type: QualificationTypes.CRIMINAL_HISTORY,
      };
    }
  }
};
