import _ from 'lodash';

import * as enums from './enums';

import AnimatedStepsFlowNotFound from './components/AnimatedStepsFlowNotFound';
import AnimatedStepsFlowSection from './components/AnimatedStepsFlowSection';
import AnimatedStepsFlowStep from './components/AnimatedStepsFlowStep';

import AnimatedStepsFlow from './AnimatedStepsFlow';

export default _.assign(AnimatedStepsFlow, {
  AnimatedStepsFlowNotFound,
  AnimatedStepsFlowSection,
  AnimatedStepsFlowStep,
  enums,
});
