import withMutation from '~tools/react/graphql/withMutation';

import RequestSupportMutation from '~tools/react/graphql/mutations/threads/requestSupport.gql';

interface Thread {
  uuid: string;
  supportRequestedAt: string;
}

interface Data {
  requestSupport: Thread;
}

interface Variables {
  threadUuid: string;
}

export interface RequestSupportProps {
  requestSupport: (threadUuid: string) => Promise<Thread>;
}

export default withMutation<{}, Data, Variables, RequestSupportProps>(RequestSupportMutation, {
  props: props => ({
    requestSupport: async (threadUuid) => {
      const res = await props.mutate({
        variables: {
          threadUuid,
        },
      });
      if (!res || !res.data) throw new Error('Error requesting support.');

      return res.data.requestSupport;
    },
  }),
});
