import React from 'react';

import withPaymentSubscription, { PaymentSubscriptionProps } from '~tools/react/hocs/withPaymentSubscription';

import Card from '~tools/react/components/Card';
import Grid from '~tools/react/components/Grid';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import SettingCard from './components/SettingCard';
import SubscriptionSettingCard from './components/SubscriptionSettingCard';

type Props = PaymentSubscriptionProps;

function Settings(props: Props) {
  return (
    <ManageStage>
      <Card
        header={{
          title: 'Product settings',
        }}>
        <Grid columns={Grid.enums.Columns.Three}>
          <SettingCard
            product={SettingCard.enums.Products.Applications}
            title="Applications"
            subtitle="Set the minimum qualifications"
            link={{
              path: '/settings/applications',
            }}
          />
          <SettingCard
            product={SettingCard.enums.Products.Leases}
            title="Leases"
            subtitle="Control generated lease content"
            link={{
              path: '/settings/leases',
            }}
          />
          <SettingCard
            product={SettingCard.enums.Products.Maintenance}
            title="Maintenance"
            subtitle="Choose how service is automated"
            link={{
              path: '/settings/maintenance',
            }}
          />
        </Grid>
      </Card>
      <Card
        header={{
          title: 'Business settings',
        }}>
        <Grid columns={Grid.enums.Columns.Three}>
          <SettingCard
            icon={SettingCard.enums.Icons.Gear}
            title="General"
            subtitle="Account and business details"
            link={{
              path: '/settings/account',
            }}
          />
          <SettingCard
            icon={SettingCard.enums.Icons.CreditCard}
            title="Payouts"
            subtitle="Payout accounts and settings"
            link={{
              path: '/settings/payouts',
            }}
          />
          <SubscriptionSettingCard
            plan={props.paymentSubscription?.plan}
            title="Subscription"
            link={{
              path: '/settings/billing',
            }}
          />
        </Grid>
      </Card>
    </ManageStage>
  );
}

export default withPaymentSubscription(Settings);
