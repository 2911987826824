import React from 'react';

import AuthStage from '~tools/react/stages/AuthStage';

import RegisterForm from '~tools/react/containers/RegisterForm';

import Center from '~tools/react/components/Center';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

function Register() {
  return (
    <AuthStage title="Create your Caretaker account">
      <RegisterForm />
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
      <div>
        <Center>
          <HorizontalRule size={HorizontalRule.enums.Sizes.Small} />
        </Center>
      </div>
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
      <Text
        isMarkdown
        color={Text.enums.Colors.Secondary}
        content="Already have an account? [Log in](/login)"
      />
    </AuthStage>
  );
}

export default Register;
