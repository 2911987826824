// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._5aAXR3{-webkit-box-align:center;align-items:center;background:-webkit-gradient(linear,left top, left bottom,color-stop(25%, #fff),to(hsla(0,0%,100%,0)));background:linear-gradient(180deg,#fff 25%,hsla(0,0%,100%,0));color:#8492a6;display:-webkit-box;display:flex;margin:8px 0}._5aAXR3 span{background:#eaf0ff;border-radius:20px;color:#0253b9;font-size:12px;font-weight:900;letter-spacing:.5px;padding:1px 10px}._5aAXR3:after,._5aAXR3:before{background:#eaf0ff;content:\"\";display:block;-webkit-box-flex:1;flex:1;height:1px}", ""]);
// Exports
exports.locals = {
	"thread-new": "_5aAXR3"
};
module.exports = exports;
