import React, { Fragment, PureComponent } from 'react';
import withStyles from '~tools/react/hocs/withStyles';

import { Check, Cross } from '~web-core/lib/common/svgs/icons/interface';

import styles from './UploadAttachment.scss';

interface Props {
  fileName: string;
  uuid: string | null;
  onDelete?: (uuid: string) => void;
}

interface State {
  isConfirmationOpen: boolean;
}

class UploadAttachment extends PureComponent<Props, State> {
  state = {
    isConfirmationOpen: false,
  };

  handleDelete = () => {
    const { uuid, onDelete } = this.props;
    if (!uuid || !onDelete) return;
    onDelete(uuid);
  }

  render() {
    return (
      <Fragment>
        <div styleName="upload-attachment">
          <Check styleName="upload-attachment__check" />
          <div styleName="upload-attachment__filename">{this.props.fileName}</div>
          <button styleName="upload-attachment__close" onClick={this.handleDelete}>
            <Cross styleName="upload-attachment__cross" />
          </button>
        </div>
      </Fragment>
    );
  }

  toggleConfirmation = () => this.setState({ isConfirmationOpen: !this.state.isConfirmationOpen });
}

export default withStyles(styles)(UploadAttachment);
