// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._O0GVQK{display:grid;grid-gap:16px;width:100%}._O0GVQK,._O0GVQK._WVjKZX{grid-template-columns:1fr}._O0GVQK._VYQGJe{grid-template-columns:repeat(2,1fr)}@media screen and (max-width:915px){._O0GVQK._VYQGJe{grid-template-columns:1fr}}._O0GVQK._wdjYjq{grid-template-columns:repeat(3,1fr)}@media screen and (max-width:915px){._O0GVQK._wdjYjq{grid-template-columns:1fr}}._O0GVQK._mmJToQ{grid-template-columns:repeat(4,1fr)}@media screen and (max-width:915px){._O0GVQK._mmJToQ{grid-template-columns:1fr}}", ""]);
// Exports
exports.locals = {
	"section": "_O0GVQK",
	"section--columns-one": "_WVjKZX",
	"section--columns-two": "_VYQGJe",
	"section--columns-three": "_wdjYjq",
	"section--columns-four": "_mmJToQ"
};
module.exports = exports;
