// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._prWqk1{background-color:#fff;border-radius:4px;overflow:hidden;position:relative;width:900px}@media screen and (max-width:1127px){._prWqk1{width:100vw}}", ""]);
// Exports
exports.locals = {
	"hello-sign-modal": "_prWqk1"
};
module.exports = exports;
