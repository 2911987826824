// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._0N5iYR{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row;-webkit-box-pack:justify;justify-content:space-between;padding:24px 32px}._0N5iYR p{color:#8492a6;font-size:14px;margin:0}._0N5iYR ._RnEwkk{display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row}", ""]);
// Exports
exports.locals = {
	"table-paginator": "_0N5iYR",
	"table-paginator__buttons": "_RnEwkk"
};
module.exports = exports;
