// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._nrBNA9{background-color:#f6f8fa;border-radius:5px;color:#d3dce6;height:50px;padding:10px;width:50px}._nrBNA9._EGnWyE{background-color:#e4f6fe;color:#1f8eed}._nrBNA9._6OAfGQ,._nrBNA9._5aAkZO{background-color:#e6ebf3;color:#003487}._nrBNA9._YXrDND{background-color:#e6e6e6;color:#000}._nrBNA9._nrBKeJ{background-color:#eff2f7;color:#aab7c9}._nrBNA9._DBDEn0{background-color:#e6fcf9;color:#0f7048}._nrBNA9._P9WAOD{background-color:#ffeaed;color:#ca0021}._nrBNA9._KpPjnW{background-color:#fcedd0;color:#f2a615}._nrBNA9._prWPA6{height:50px;padding:10px;width:50px}._nrBNA9._J2VxJw{height:30px;padding:6px;width:30px}", ""]);
// Exports
exports.locals = {
	"icon-block": "_nrBNA9",
	"icon-block--color-blue": "_EGnWyE",
	"icon-block--color-brand-advanced-plan": "_6OAfGQ",
	"icon-block--color-brand-basic-plan": "_5aAkZO",
	"icon-block--color-brand-enterprise-plan": "_YXrDND",
	"icon-block--color-gray": "_nrBKeJ",
	"icon-block--color-green": "_DBDEn0",
	"icon-block--color-red": "_P9WAOD",
	"icon-block--color-yellow": "_KpPjnW",
	"icon-block--size-medium": "_prWPA6",
	"icon-block--size-small": "_J2VxJw"
};
module.exports = exports;
