import { StripeExternalAccountTypes } from '~tools/types/graphqlSchema';

import AddPayoutAccountMutation from '~tools/react/graphql/mutations/payoutAccounts/addPayoutAccount.gql';
import withMutation from '~tools/react/graphql/withMutation';

export interface PayoutAccount {
  defaultForCurrency: boolean;
  id: string;
  type: StripeExternalAccountTypes;
  bankAccount?: {
    id: string;
    object: string;
    account: string;
    accountName: string;
    accountHolderName: string;
    accountHolderType: string;
    bankName: string;
    country: string;
    currency: string;
    defaultForCurrency: boolean;
    last4: string;
    routingNumber: string;
    status: string;
  }
  card?: {
    id: string;
    addressCity: string;
    addressCountry: string;
    addressLine1: string;
    addressLine1Check: string;
    addressLine2: string;
    addressState: string;
    addressZip: string;
    addressZipCheck: string;
    brand: string;
    country: string;
    customer: string;
    cvcCheck: string;
    dynamicLast4: string;
    expMonth: number;
    expYear: number;
    fingerprint: string;
    funding: string;
    last4: string;
    name: string;
  };
}

interface Data {
  addPayoutAccount: PayoutAccount;
}

interface Variables {
  token: string;
}

export interface AddPayoutAccountProps {
  addPayoutAccount: (token: string) => Promise<PayoutAccount>;
}
export default withMutation<{}, Data, Variables, AddPayoutAccountProps>(AddPayoutAccountMutation, {
  props: props => ({
    addPayoutAccount: async (token: string) => {
      const res = await props.mutate({
        variables: {
          token,
        },
      });
      if (!res || !res.data) throw new Error('Error adding payout account.');

      return res.data.addPayoutAccount;
    },
  }),
});
