// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._O0GJqq{width:100%}._2dAez6{-webkit-box-align:center;align-items:center;border:1px solid #e6e8eb;border-radius:4px;display:-webkit-box;display:flex;padding:24px 32px;position:relative;-webkit-transition:all .2s cubic-bezier(.215,.61,.355,1);transition:all .2s cubic-bezier(.215,.61,.355,1);width:100%}._2dAez6._GN6EjE{border:1px solid #1f8eed}._2dAez6+._2dAez6{margin:16px 0 0}._2dAez6 ._99v9x5{position:absolute;right:32px;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%)}._2dAez6 ._99v9x5 path{stroke:#1f8eed}@media screen and (max-width:1127px){._2dAez6 ._99v9x5{right:12px;top:12px;-webkit-transform:translateY(0);transform:translateY(0)}}._2dAez6 ._QDoMn3{display:none}._2dAez6 ._qro7Zj{margin:0 32px 0 0}@media screen and (max-width:1127px){._2dAez6 ._qro7Zj{margin:0 24px 0 0}}._2dAez6 ._x28zQb{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;text-align:left}._2dAez6:not(:active):hover{box-shadow:0 10px 30px -8px rgba(0,0,0,.1);-webkit-transform:translateY(-1px);transform:translateY(-1px)}@media screen and (max-width:1127px){._2dAez6{padding:16px 24px}._2dAez6:not(:active):hover{box-shadow:none;-webkit-transform:none;transform:none}}", ""]);
// Exports
exports.locals = {
	"icon-radio-buttons": "_O0GJqq",
	"icon-radio-button": "_2dAez6",
	"icon-radio-button--active": "_GN6EjE",
	"icon-radio-button__check": "_99v9x5",
	"icon-radio-button__input": "_QDoMn3",
	"icon-radio-button__svg": "_qro7Zj",
	"icon-radio-button__text": "_x28zQb"
};
module.exports = exports;
