import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Text from '~tools/react/components/Text';
import { Checkbox } from '~tools/react/components/Form';

import styles from './CheckableListItem.scss';

interface Props {
  isChecked?: boolean;
  onToggle: () => void;
  subtitle?: string;
  title: string;
}

function CheckableListItem(props: Props) {
  return (
    <div styleName="checkable-list-item">
      <div styleName="checkable-list-item__checkbox">
        <Checkbox
          isChecked={props.isChecked}
          onChange={props.onToggle}
        />
      </div>
      <div styleName="checkable-list-item__text">
        <Text
          color={Text.enums.Colors.Primary}
          content={props.title}
          isMarkdown
          size={Text.enums.Sizes.Medium}
        />
        {props.subtitle ? (
          <Text
            color={Text.enums.Colors.Secondary}
            content={props.subtitle}
            size={Text.enums.Sizes.Small}
          />
        ) : null}
      </div>
    </div>
  );
}

export default withStyles(styles)(CheckableListItem);
