// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._7RAvw5{max-width:375px;width:100%}", ""]);
// Exports
exports.locals = {
	"receipt-view": "_7RAvw5"
};
module.exports = exports;
