import classNames from 'classnames';
import _ from 'lodash';
import React, { PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import AmexSVG from '~tools/svgs/logos/payments/amex.svg';
import BankSVG from '~tools/svgs/icons/interface/bank.svg';
import DiscoverSVG from '~tools/svgs/logos/payments/discover.svg';
import MasterCardSVG from '~tools/svgs/logos/payments/mastercard.svg';
import VisaSVG from '~tools/svgs/logos/payments/visa.svg';

import * as enums from './enums';

import styles from './MiniChargeCardVisual.scss';

const nameToIcon = {
  [enums.Brands.Bank]: BankSVG,
  [enums.Brands.Discover]: DiscoverSVG,
  [enums.Brands.MasterCard]: MasterCardSVG,
  [enums.Brands.Visa]: VisaSVG,
  [enums.Brands['American Express']]: AmexSVG,
};

interface Props {
  brand: enums.Brands;
}

class MiniChargeCardVisual extends PureComponent<Props> {
  static enums = enums;

  render() {
    const Icon = nameToIcon[this.props.brand];
    if (!Icon) return null;

    return (
      <div
        className={classNames({
          [styles.icon]: true,
          [styles[`icon--${_.kebabCase(this.props.brand)}`]]: this.props.brand,
        })}>
        <Icon styleName="icon__svg" />
      </div>
    );
  }
}

export default withStyles(styles)(MiniChargeCardVisual);
