import moment from 'moment';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import Card from '~tools/react/components/Card';
import GenericLoadingVisual from '~tools/react/components/GenericLoadingVisual';
import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import Button from '~tools/react/components/Button';
import CreateConditionReportModal from '~tools/react/components/CreateConditionReportModal';
import withFetchOrCreateConditionInspection, { FetchOrCreateConditionInspectionProps } from '~tools/react/graphql/mutations/conditionInspections/withFetchOrCreateConditionInspection';
import Text from '~tools/react/components/Text';
import { ConditionReportTypes } from '~tools/types/graphqlSchema';
import MaintainTimeline from '~tools/react/components/MaintainTimeline';
import { TimelineEntry, TimelineEntryTypes } from '~tools/react/components/MaintainTimeline/types';
import DashboardAccordion from '~tools/react/components/DashboardAccordion';

import DataRow from '~web-manage/lib/common/components/DataRow';
import ManageStage from '~web-manage/lib/common/stages/ManageStage';
import { formatUnitName } from '~web-manage/lib/common/utils/addressUnit';
import globals from '~web-manage/lib/common/globals';

import { PropertyManagerContract } from './types';
import query from './UnitMaintain.gql';

interface QueryProps {
  isLoading: boolean;
  propertyManagerContract: PropertyManagerContract | null;
  viewer: {
    uuid: string;
    role: 0 | 9;
  } | null;
  refetch: () => Promise<any>;
}

interface RouteParams {
  propertyManagerContractUuid: string;
  tab: string;
}

type Props =
  RouteComponentProps<RouteParams> &
  QueryProps &
  FetchOrCreateConditionInspectionProps;

interface State {
  isCreateConditionReportModalOpen: boolean;
  isLoading: boolean;
}

class UnitMaintain extends PureComponent<Props, State> {
  state: State = {
    isCreateConditionReportModalOpen: false,
    isLoading: false,
  };

  handleCloseCreateConditionReportModal = () => this.setState({
    isCreateConditionReportModalOpen: false,
  });

  handleReportDamage = () => this.setState({
    isCreateConditionReportModalOpen: true,
  });

  handleFetchOrCreateInspection = async () => {
    const addressUnit = this.props.propertyManagerContract?.addressUnit;
    if (!addressUnit) return;

    this.setState({ isLoading: true });
    const conditionInspection = await this.props.fetchOrCreateConditionInspection({
      addressUnitUuid: addressUnit.uuid,
    });
    this.setState({ isLoading: false });

    // Domain jump to web-core
    window.location.assign(`${globals.WEB_CORE_DOMAIN}/inspections/${conditionInspection.uuid}/setup/bedrooms`);
  };

  render() {
    const loadingScreen = (
      <ManageStage>
        <Card>
          <Card.CardSection>
            <GenericLoadingVisual />
          </Card.CardSection>
        </Card>
      </ManageStage>
    );

    if (this.props.isLoading) return loadingScreen;

    const viewer = this.props.viewer;
    if (!viewer) return loadingScreen;

    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return loadingScreen;

    const addressUnit = propertyManagerContract.addressUnit;
    if (!addressUnit) return loadingScreen;

    const conditionReports = _.flatten(addressUnit.addressUnitRooms.map(a => a.conditionReports));

    const completedInspections = addressUnit.conditionInspections.filter(i => i.lockedAt);

    const latestConditionInspection = _.maxBy(completedInspections, i => new Date(i.lockedAt).getTime());

    const issuesSinceLastInspection = conditionReports.filter(report => {
      return report.reportType === ConditionReportTypes.DAMAGE
        && report.originConditionReport === null
        && (
          !latestConditionInspection
          || moment(report.createdAt).isAfter(moment(latestConditionInspection.lockedAt))
        );
    });

    const resolvedIssues = conditionReports.filter(report => {
      return report.reportType === ConditionReportTypes.DAMAGE
        && report.fixedByConditionReport !== null
        && report.originConditionReport === null;
    });

    const openIssues = conditionReports.filter(report => {
      return report.reportType === ConditionReportTypes.DAMAGE
        && report.fixedByConditionReport === null
        && report.originConditionReport === null;
    });

    const timelineEntries: TimelineEntry[] = [];
    conditionReports.forEach(report => {
      if (!report.conditionInspection) {
        timelineEntries.push({
          uuid: report.uuid,
          createdAt: moment(report.createdAt),
          description: report.description,
          reportType: report.reportType,
          type: TimelineEntryTypes.REPORT,
        });
      }
    });

    addressUnit.conditionInspections
      .filter(i => !!i.lockedAt)
      .forEach(inspection => {
        timelineEntries.push({
          uuid: inspection.uuid,
          createdAt: moment(inspection.createdAt),
          description: '',
          type: TimelineEntryTypes.INSPECTION,
        });
      });

    timelineEntries.sort((a, b) => {
      // Date descending
      return b.createdAt.isBefore(a.createdAt) ? -1 : 1;
    });

    const unlockedInspection = addressUnit.conditionInspections.find(i => {
      return i.lockedAt === null
        && i.inspectorAccount.uuid === this.props.viewer?.uuid;
    });

    return (
      <ManageStage>
        <Breadcrumbs
          items={[
            { path: `/units`, label: 'All units' },
            { path: `/units/${propertyManagerContract.uuid}`, label: `${addressUnit.address.streetAddress1} ${formatUnitName(addressUnit)}` },
            { label: 'Maintain' },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
        <Card header={{ title: 'Unit maintenance' }}>
          <Card.CardSection spacing={Card.CardSection.enums.Spacing.None}>
            <DashboardAccordion>
              <DashboardAccordion.DashboardAccordionItem title="Submit your inspection">
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                <Text
                  content={unlockedInspection ?
                    `Pick up where you left off and complete your inspection.` :
                    `Document the state of your unit so you can protect yourself from future damage.`
                  }
                  size={Text.enums.Sizes.Small}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                <Button
                  icon={Button.enums.Icons.ChevronRight}
                  isLoading={this.state.isLoading}
                  label={unlockedInspection ? 'Resume inspection' : 'Create inspection'}
                  onClick={this.handleFetchOrCreateInspection}
                  size={Button.enums.Sizes.Small}
                />
              </DashboardAccordion.DashboardAccordionItem>
              {/* Only allow reports to be created after there's at least one room to report damage on. */}
              {addressUnit.addressUnitRooms.length > 0 ? (
                <DashboardAccordion.DashboardAccordionItem title="Report damage">
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                  <Text
                    content="If you're experiencing an issue with your unit and would like to track it, document it here."
                    size={Text.enums.Sizes.Small}
                  />
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                  <Button
                    isLoading={this.state.isLoading}
                    label="Report damage"
                    onClick={this.handleReportDamage}
                    size={Button.enums.Sizes.Small}
                  />
                </DashboardAccordion.DashboardAccordionItem>
              ) : null}
            </DashboardAccordion>
          </Card.CardSection>
        </Card>

        {conditionReports.length || latestConditionInspection ? (
          <>
            <Card header={{ title: 'Overview' }}>
              <Card.CardSection>
                <DataRow>
                  <DataRow.DataRowItem
                    label="In progress issues"
                    link={{ path: `/issues?propertyManagerContractUuid=${propertyManagerContract.uuid}&status=in-progress` }}
                    description={`${openIssues.length}`}
                  />
                  <DataRow.DataRowItem
                    label="Resolved issues"
                    link={{ path: `/issues?propertyManagerContractUuid=${propertyManagerContract.uuid}&status=resolved` }}
                    description={`${resolvedIssues.length}`}
                  />
                  <DataRow.DataRowItem
                    label="Last inspected"
                    description={latestConditionInspection ? moment(latestConditionInspection.createdAt).format('MMM DD, YYYY') : 'N/A'}
                    link={latestConditionInspection ?
                      { path: `/inspections/${latestConditionInspection.uuid}` } :
                      undefined
                    }
                  />
                  <DataRow.DataRowItem
                    label="Issues since last inspection"
                    description={`${issuesSinceLastInspection.length}`}
                  />
                </DataRow>
              </Card.CardSection>
            </Card>

            <MaintainTimeline
              cardTitle="Activity"
              propertyManagerContractUuid={this.props.propertyManagerContract?.uuid || ''}
              timelineEntries={timelineEntries}
            />
          </>
        ) : null}

        <CreateConditionReportModal
          addressUnitUuid={propertyManagerContract.addressUnit.uuid}
          addressUnitRooms={propertyManagerContract.addressUnit.addressUnitRooms}
          isOpen={this.state.isCreateConditionReportModalOpen}
          reportType={ConditionReportTypes.DAMAGE}
          onClose={this.handleCloseCreateConditionReportModal}
        />
      </ManageStage>
    );
  }
}

interface Response {
  viewer: {
    propertyManagerContract: PropertyManagerContract | null;
    role: 0 | 9;
    uuid: string;
  }
}

interface Variables {
  propertyManagerContractUuid: string;
}

export default compose(
  withQuery<RouteComponentProps<{ propertyManagerContractUuid: string }>, Response, Variables, QueryProps>(query, {
    options: props => ({
      variables: {
        propertyManagerContractUuid: props.match.params.propertyManagerContractUuid,
      },
      skip: !props.match.params.propertyManagerContractUuid,
      ssr: false,
    }),
    props: props => ({
      isLoading: props.loading,
      propertyManagerContract: props.data?.viewer?.propertyManagerContract || null,
      viewer: props.data?.viewer ?? null,
      refetch: props.refetch,
    }),
  }),
  withFetchOrCreateConditionInspection,
)(UnitMaintain);
