// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._XP86zJ{background-color:transparent;border:none;border-radius:5px;padding:8px 12px;position:relative;text-align:left;-webkit-transition:all .25s;transition:all .25s;width:200px;z-index:1}._XP86zJ._6OAnBD>p{color:#cc1420}._XP86zJ:hover{background-color:#e4f6fe;cursor:pointer}._XP86zJ:hover>p{color:#0253b9}._XP86zJ:hover._6OAnBD{background-color:#ffeaed}._XP86zJ:hover._6OAnBD>p{color:#cc1420}", ""]);
// Exports
exports.locals = {
	"link": "_XP86zJ",
	"link__color-red": "_6OAnBD"
};
module.exports = exports;
