import { mirrorKeys } from '../utils';

const listingFields = mirrorKeys([
  'amenities',
  'apartmentNumber',
  'areaCode',
  'bathrooms',
  'bathroomString',
  'bedrooms',
  'bedroomsString',
  'brokerFee',
  'city',
  'createdAt',
  'depositInCents',
  'depositInDollars',
  'description',
  'directContact',
  'discountRentInDollars',
  'earliestMoveInDate',
  'earliestMoveInDateString',
  'earliestMoveInInDays',
  'furnishingType',
  'guaranteeStatus',
  'headline',
  'isConcierge',
  'isLeaseExtendableWithLandlord',
  'isPremium',
  'isQualified',
  'isRepostable',
  'latitude',
  'leaseDuration',
  'leaseEndDate',
  'leaseType',
  'listedAt',
  'listingType',
  'listingTypeString',
  'locationString',
  'longitude',
  'managerEmail',
  'maxLeaseTerm',
  'minLeaseTerm',
  'neighborhood',
  'photos',
  'priceInCents',
  'priceInDollars',
  'pricingType',
  'primaryPhoto',
  'processing',
  'propertyType',
  'referralEmail',
  'rentInCents',
  'rentInDollars',
  'rentPrice',
  'requiresDeposit',
  'rewardInCents',
  'rewardInDollars',
  'slug',
  'state',
  'status',
  'streetAddress',
  'subleaseReviewedAt',
  'timeRemainingInDays',
  'timeRemainingString',
  'totalRooms',
  'updatedAt',
  'url',
  'user',
  'uuid',
  'views',
]);

export default listingFields;
