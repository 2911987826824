import withMutation from '~tools/react/graphql/withMutation';
import { CreateListingAnswerInput } from '~tools/types/graphqlSchema';

import CreateListingAnswerMutation from './createListingAnswer.gql';

interface Response {
  createListingAnswer: {
    uuid: string;
  };
}

export interface CreateListingAnswerProps {
  createListingAnswer: (input: CreateListingAnswerInput) => Promise<{ uuid: string }>;
}

export default withMutation<{}, Response, { input: CreateListingAnswerInput }, CreateListingAnswerProps>(CreateListingAnswerMutation, {
  props: props => ({
    createListingAnswer: async (input: CreateListingAnswerInput) => {
      const res = await props.mutate({
        variables: { input },
      });

      if (!res || !res.data) throw new Error('Error in creating listing answer');
      if (!res.data.createListingAnswer) throw new Error('No createListingAnswer returned');

      return res.data.createListingAnswer;
    },
  }),
});
