import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './ProgressBar.scss';

interface Props {
  color: string;
  percent: number;
}

const ProgressBar = (props: Props) => (
  <div styleName="progress-bar">
    <div
      styleName="progress-bar__progress"
      style={{ width: `${props.percent}%`, backgroundColor: props.color }}
    />
  </div>
);

export default withStyles(styles)(ProgressBar);
