import { ApolloQueryResult } from 'apollo-client';
import dayjs from 'dayjs';
import _ from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';
import queryString from 'query-string';
import React, { Fragment, PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import ArrowLink from '~tools/react/components/ArrowLink';
import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import ConfirmationModal from '~tools/react/components/ConfirmationModal';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Table from '~tools/react/components/Table';
import Tag from '~tools/react/components/Tag';
import Text from '~tools/react/components/Text';
import UnorderedList from '~tools/react/components/UnorderedList';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import CancelPlanModal from '~tools/react/containers/CancelPlanModal';
import ManagePlanModal from '~tools/react/containers/ManagePlanModal';
import withCancelPaymentSubscription, {
  CancelPaymentSubscriptionProps,
} from '~tools/react/graphql/mutations/paymentSubscriptions/withCancelPaymentSubscription';
import withQuery from '~tools/react/graphql/withQuery';
import { compose } from '~tools/react/hocs/utils';
import {
  InvoiceStatuses,
  PaymentSubscriptionPlans as Plans,
  StripePaymentMethodTypes,
} from '~tools/types/graphqlSchema';
import { formatAsUSD } from '~tools/utils/string';

import Globals from '~web-core/lib/common/globals';

import DataGrid from '~web-manage/lib/common/components/DataGrid';
import InfoTooltip from '~web-manage/lib/common/components/InfoTooltip';
import InlinePaymentMethodVisual from '~web-manage/lib/common/components/InlinePaymentMethodVisual';
import * as enums from '~web-manage/lib/common/components/InlinePaymentMethodVisual/enums';
import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import * as constants from './constants';
import UpdatePaymentMethodModal from './containers/UpdatePaymentMethodModal';

import query from './BillingSettings.gql';

type Props = QueryProps & CancelPaymentSubscriptionProps & RouteComponentProps;

interface State {
  isCancelPlanModalOpen: boolean;
  isManagePlanModalOpen: boolean;
  isPlanChangeSuccessModalOpen: boolean;
  isUpdatePaymentMethodModalOpen: boolean;
}

class BillingSettings extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const urlQuery = queryString.parse(props.location.search);
    this.state = {
      isCancelPlanModalOpen: false,
      isManagePlanModalOpen: !!urlQuery.isManagePlanModalOpen || false,
      isPlanChangeSuccessModalOpen: false,
      isUpdatePaymentMethodModalOpen: false,
    };
  }

  handleChangePlan = async () => {
    await this.props.refetch();

    const activePlan = this.props.viewer?.activePaymentSubscription?.plan || Plans.LITE;
    if (activePlan !== Plans.LITE && activePlan !== Plans.LEASEHOLDER) {
      this.setState({ isPlanChangeSuccessModalOpen: true });
    }
  };

  handleCloseUpdatePaymentMethodModal = async () => {
    this.setState({ isUpdatePaymentMethodModalOpen: false });

    await this.props.refetch();
  };
  handleOpenUpdatePaymentMethodModal = () => this.setState({ isUpdatePaymentMethodModalOpen: true });

  handleCloseCancelPlanModal = () => this.setState({ isCancelPlanModalOpen: false });
  handleOpenCancelPlanModal = () => this.setState({ isCancelPlanModalOpen: true });

  handleCloseManagePlanModal = () => this.setState({ isManagePlanModalOpen: false });
  handleOpenManagePlanModal = () => this.setState({ isManagePlanModalOpen: true });

  handleClosePlanChangeSuccessModal = () => this.setState({ isPlanChangeSuccessModalOpen: false });
  handleOpenPlanChangeSuccessModal = () => this.setState({ isPlanChangeSuccessModalOpen: true });

  render() {
    const viewer = this.props.viewer;
    const activePaymentSubscription = viewer?.activePaymentSubscription;
    const activePlan = activePaymentSubscription?.plan ?? Plans.LITE;

    const defaultPaymentMethod = activePaymentSubscription?.providerSubscription?.defaultPaymentMethod;

    const paymentSubscriptions = viewer?.paymentSubscriptions || [];
    const allInvoiceItems = _.flatMap(paymentSubscriptions, (ps) => ps.invoiceItems);

    const subscriptionItem = activePaymentSubscription?.providerSubscription?.items[0];
    const planType = _.startCase(_.toLower(activePlan));
    const priceInCents = subscriptionItem?.price.unitAmount ?? 0;
    //  const product = subscriptionItem?.price.product;
    const quantity = subscriptionItem?.quantity ?? 1;

    const featureList = constants.PaymentSubscriptionPlanFeatureLists[activePlan];

    const name = constants.PaymentSubscriptionPlanNames[activePlan];

    // const featureList = PaymentSubscriptionPlanFeatureLists[activePlan];
    // const name = PaymentSubscriptionPlanNames[this.props.tier];

    return (
      <ManageStage>
        <CancelPlanModal
          isOpen={this.state.isCancelPlanModalOpen}
          onCancelPlan={this.handleChangePlan}
          onClose={this.handleCloseCancelPlanModal}
        />
        <ManagePlanModal
          isOpen={this.state.isManagePlanModalOpen}
          onChangePlan={this.handleChangePlan}
          onClose={this.handleCloseManagePlanModal}
        />
        <Breadcrumbs
          items={[
            { path: `/settings`, label: 'Settings' },
            { path: `/settings/billing`, label: 'Billing details' },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
        <Card
          color={this.props.isLoading ? undefined : Card.enums.Colors[`Brand${planType}Plan`]}
          header={{
            title: 'Subscription details',
          }}
          isLoading={this.props.isLoading}
        >
          <Card.CardSection>
            <DataGrid>
              <DataGrid.DataGridColumn>
                <DataGrid.DataGridRow label="Your plan">
                  <Row
                    flexBehavior={Row.enums.FlexBehaviors.Default}
                    verticalAlignment={Row.enums.VerticalAlignments.Center}
                  >
                    <Heading
                      content={`Caretaker *${name}*`}
                      font={Heading.enums.Fonts.Secondary}
                      isMarkdown
                      size={Heading.enums.Sizes.Small}
                    />
                    {activePaymentSubscription && activePaymentSubscription.plan !== Plans.LITE ? (
                      <Fragment>
                        <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                        <Tag
                          color={Tag.enums.Colors.Green}
                          label="Active"
                        />
                      </Fragment>
                    ) : null}
                  </Row>
                </DataGrid.DataGridRow>
                <DataGrid.DataGridRow label="Your plan includes">
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                  <UnorderedList
                    items={_.map(featureList, (featureLabel) => ({
                      color: UnorderedList.enums.Colors[`Brand${planType}Plan`],
                      label: featureLabel,
                    }))}
                    hasBorders={false}
                    style={UnorderedList.enums.Styles.Secondary}
                  />
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                  <ArrowLink
                    label="Show all features"
                    color={ArrowLink.enums.Colors[`Brand${planType}Plan`]}
                    link={{
                      path: `${Globals.WEB_CORE_DOMAIN}/pricing`,
                      shouldOpenNewTab: true,
                    }}
                    size={ArrowLink.enums.Sizes.Small}
                  />
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                </DataGrid.DataGridRow>
                <DataGrid.DataGridRow label="Monthly total">
                  {activePaymentSubscription ? (
                    <Row
                      flexBehavior={Row.enums.FlexBehaviors.Shrink}
                      verticalAlignment={Row.enums.VerticalAlignments.Center}
                    >
                      <Text
                        shouldWrap={false}
                        size={Text.enums.Sizes.Medium}
                        content={`$${formatAsUSD((priceInCents * quantity) / 100, true)} USD`}
                      />
                      <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                      <InfoTooltip
                        tooltipText={`$${formatAsUSD(priceInCents / 100, true)} USD/unit × ${quantity} ${pluralize(
                          'unit',
                          quantity,
                        )}`}
                      />
                    </Row>
                  ) : null}
                </DataGrid.DataGridRow>
                <DataGrid.DataGridRow label="Next charge on">
                  {activePaymentSubscription ? (
                    <Text
                      shouldWrap={false}
                      size={Text.enums.Sizes.Medium}
                      isMarkdown
                      content={`${dayjs(activePaymentSubscription.providerSubscription?.currentPeriodEnd).format(
                        'MMMM Do, YYYY',
                      )}`}
                    />
                  ) : null}
                </DataGrid.DataGridRow>
                <DataGrid.DataGridRow label="Payment method">
                  {defaultPaymentMethod && defaultPaymentMethod.type === StripePaymentMethodTypes.CARD ? (
                    <InlinePaymentMethodVisual
                      type={defaultPaymentMethod.type}
                      isDefault
                      card={defaultPaymentMethod.card}
                    />
                  ) : null}
                  {defaultPaymentMethod && defaultPaymentMethod.type === StripePaymentMethodTypes.US_BANK_ACCOUNT ? (
                    <InlinePaymentMethodVisual
                      type={defaultPaymentMethod.type}
                      isDefault
                      usBankAccount={defaultPaymentMethod.usBankAccount}
                    />
                  ) : null}
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                  <Button
                    icon={Button.enums.Icons.Edit}
                    label="Change payment method"
                    onClick={this.handleOpenUpdatePaymentMethodModal}
                    size={Button.enums.Sizes.XSmall}
                    style={Button.enums.Styles.Outline}
                  />
                </DataGrid.DataGridRow>
              </DataGrid.DataGridColumn>
            </DataGrid>
          </Card.CardSection>
          <Card.CardSection>
            <Row
              flexBehavior={Row.enums.FlexBehaviors.Default}
              verticalAlignment={Row.enums.VerticalAlignments.Center}
            >
              {activePaymentSubscription && activePaymentSubscription.plan !== Plans.LITE ? (
                <Fragment>
                  <Button
                    align={Button.enums.Alignments.Right}
                    color={Button.enums.Colors.Red}
                    label="Cancel subscription"
                    onClick={this.handleOpenCancelPlanModal}
                    size={Button.enums.Sizes.Small}
                    style={Button.enums.Styles.Outline}
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                </Fragment>
              ) : null}
              <Button
                align={activePaymentSubscription?.plan === Plans.LITE ? Button.enums.Alignments.Right : undefined}
                color={Button.enums.Colors.Blue}
                label="Change plan"
                onClick={this.handleOpenManagePlanModal}
                size={Button.enums.Sizes.Small}
              />
            </Row>
          </Card.CardSection>
        </Card>
        <Card header={{ title: 'Payment history' }}>
          <Card.CardSection spacing={Card.CardSection.enums.Spacing.None}>
            <Table>
              <Table.TableHead>
                <Table.TableRow>
                  <Table.TableHeader alignment={Table.TableHeader.enums.Alignments.RIGHT}>Amount</Table.TableHeader>
                  <Table.TableHeader />
                  <Table.TableHeader />
                  <Table.TableHeader width={Table.TableHeader.enums.Widths.AUTO}>Plan</Table.TableHeader>
                  <Table.TableHeader>Billing Period</Table.TableHeader>
                  <Table.TableHeader>Date</Table.TableHeader>
                </Table.TableRow>
              </Table.TableHead>
              <Table.TableBody>
                {allInvoiceItems.length > 0 ? (
                  allInvoiceItems.map((invoiceItem) => {
                    let statusColor;
                    let statusText;
                    switch (invoiceItem.invoice.status) {
                      case InvoiceStatuses.PAID:
                        statusColor = Tag.enums.Colors.Green;
                        statusText = 'Paid';
                        break;
                      case InvoiceStatuses.DELETED:
                      case InvoiceStatuses.VOID:
                        statusText = _.toLower(invoiceItem.invoice.status);
                        break;
                      case InvoiceStatuses.UNCOLLECTIBLE:
                      case InvoiceStatuses.OPEN:
                      case InvoiceStatuses.DRAFT:
                      default:
                        statusText = 'Awaiting payment';
                        break;
                    }
                    const invoiceLineItem = invoiceItem.invoice.providerInvoice?.lines[0];

                    return (
                      <Table.TableRow key={invoiceItem.uuid}>
                        <Table.TableData alignment={Table.TableData.enums.Alignments.RIGHT}>
                          <Text
                            isMarkdown
                            isEmphasized
                            content={`*$${formatAsUSD(invoiceItem.amountInCents / 100, true)}*`}
                          />
                        </Table.TableData>
                        <Table.TableData>
                          <Text
                            content="USD"
                            color={Text.enums.Colors.Secondary}
                          />
                        </Table.TableData>
                        <Table.TableData>
                          <Tag
                            color={statusColor}
                            label={statusText}
                          />
                        </Table.TableData>
                        <Table.TableData width={Table.TableData.enums.Widths.AUTO}>
                          <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                            <Text
                              isEmphasized
                              content={invoiceLineItem?.price.product.name ?? ''}
                            />
                            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXSmall} />
                            <Text
                              isEmphasized
                              content={`($${formatAsUSD((invoiceLineItem?.price.unitAmount ?? 0) / 100)}${
                                invoiceLineItem?.price.product.unitLabel ? '/unit per month' : ' per month'
                              })`}
                              color={Text.enums.Colors.Secondary}
                            />
                          </Row>
                        </Table.TableData>
                        <Table.TableData>
                          <Text content={this.getBillingPeriod(invoiceLineItem)} />
                        </Table.TableData>
                        <Table.TableData>
                          <Text
                            content={
                              moment(invoiceItem.invoice.createdAt).year() === moment().year()
                                ? moment(invoiceItem.invoice.createdAt).format('MMM D, h:mm A')
                                : moment(invoiceItem.invoice.createdAt).format('MMM D, YYYY, h:mm A')
                            }
                            color={Text.enums.Colors.Secondary}
                          />
                        </Table.TableData>
                      </Table.TableRow>
                    );
                  })
                ) : (
                  <Table.TableEmptyState label="You have no recorded payments yet" />
                )}
              </Table.TableBody>
            </Table>
          </Card.CardSection>
        </Card>
        <ConfirmationModal
          cancelAction={{
            label: 'Later',
            onClick: this.handleClosePlanChangeSuccessModal,
          }}
          confirmAction={{
            label: 'Add unit',
            onClick: this.gotoAddUnit,
          }}
          description="Your plan has been upgraded! If you're ready to find new renters now, let's go add your unit."
          isOpen={this.state.isPlanChangeSuccessModalOpen}
          onClose={this.handleClosePlanChangeSuccessModal}
          title="You're all set!"
        />
        <UpdatePaymentMethodModal
          isOpen={this.state.isUpdatePaymentMethodModalOpen}
          onClose={this.handleCloseUpdatePaymentMethodModal}
        />
      </ManageStage>
    );
  }

  getBillingPeriod = (invoiceLineItem?: InvoiceLineItem) => {
    if (!invoiceLineItem) return '';

    const invoiceStart = moment(invoiceLineItem.period.start);
    const invoiceEnd = moment(invoiceLineItem.period.end);

    if (invoiceStart.year() !== invoiceEnd.year()) {
      return `${invoiceStart.format('MMM D, YYYY')} — ${invoiceEnd.format('MMM D, YYYY')}`;
    }

    return `${invoiceStart.format('MMM D')} — ${invoiceEnd.format('MMM D, YYYY')}`;
  };

  gotoAddUnit = () => {
    this.props.history.push(`/units?showAddUnitModal=true`);
  };
}

interface InvoiceLineItem {
  id: string;
  amount: number;
  description: string;
  period: {
    end: string;
    start: string;
  };
  price: {
    id: string;
    product: {
      id: string;
      name: string;
      unitLabel: string;
    };
    unitAmount: number;
  };
  proration: boolean;
  quantity: number;
}

interface SharedPaymentMethodProps {
  id: string;
}

interface CardPaymentMethod extends SharedPaymentMethodProps {
  card: {
    brand: enums.Brands;
    expMonth: number;
    expYear: number;
    id: string;
    last4: string;
    name: string;
  };
  type: StripePaymentMethodTypes.CARD;
}

interface UsBankAccountPaymentMethod extends SharedPaymentMethodProps {
  usBankAccount: {
    accountType: string;
    bankName: string;
    last4: string;
    routingNumber: string;
  };
  type: StripePaymentMethodTypes.US_BANK_ACCOUNT;
}

type PaymentMethod = CardPaymentMethod | UsBankAccountPaymentMethod;

interface Viewer {
  activePaymentSubscription: {
    activatedAt: string;
    plan: Plans;
    providerSubscription: {
      currentPeriodEnd: string;
      currentPeriodStart: string;
      defaultPaymentMethod: PaymentMethod | null;
      id: string;
      items: {
        id: string;
        price: {
          active: boolean;
          billingScheme: string;
          id: string;
          product: {
            active: boolean;
            description: string | null;
            id: string;
            name: string;
            unitLabel: string | null;
          };
          unitAmount: number | null;
        };
        quantity: number;
      }[];
      status: string;
    } | null;
    uuid: string;
  } | null;
  paymentSubscriptions: {
    uuid: string;
    invoiceItems: {
      uuid: string;
      amountInCents: number;
      invoice: {
        createdAt: string;
        number: string;
        providerInvoice: {
          id: string;
          description: string;
          lines: InvoiceLineItem[];
        } | null;
        status: string;
        uuid: string;
      };
    }[];
  }[];
  propertyManagerContracts: {
    uuid: string;
  }[];
  propertyManagerContractsTotal: number;
  uuid: string;
}

interface Response {
  viewer: Viewer | null;
}

interface QueryProps extends Response {
  isLoading: boolean;
  refetch: () => Promise<ApolloQueryResult<Response>>;
}

export default compose(
  withCancelPaymentSubscription,
  withQuery<{}, Response, {}, QueryProps>(query, {
    props: (props) => ({
      isLoading: props.loading,
      refetch: props.refetch,
      viewer: props.data?.viewer ?? null,
    }),
  }),
)(BillingSettings);
