import DeletePhotoMutation from '~tools/react/graphql/mutations/photos/deletePhoto.gql';
import withMutation from '~tools/react/graphql/withMutation';

export interface Variables {
  uuid: string;
}

export interface DeletePhotoProps {
  deletePhoto: (uuid: string) => Promise<void>;
}

export default withMutation<{}, {}, Variables, DeletePhotoProps>(DeletePhotoMutation, {
  props: props => ({
    deletePhoto: async (uuid: string) => {
      await props.mutate({
        variables: {
          uuid,
        },
      });
    },
  }),
});
