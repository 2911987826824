// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1qATnv{position:relative;width:100%}@media screen and (max-width:1127px){._1qATnv{margin:0;padding:56px 24px 105px 0;width:100%}}._1qATnv>div{width:100%}._5aAZnB,._J2V8D1{position:absolute;z-index:1}._5aAZnB{opacity:0;-webkit-transform:translateX(10px);transform:translateX(10px);z-index:3}._5aAZnB._ZJNvRv{-webkit-transition:all .2s cubic-bezier(.215,.61,.355,1) .2s;transition:all .2s cubic-bezier(.215,.61,.355,1) .2s}._5aAZnB._ZJNvRv,._J2V8D1{opacity:1;-webkit-transform:translateX(0);transform:translateX(0)}._J2V8D1._O0GvDj{opacity:0;-webkit-transform:translateX(-10px);transform:translateX(-10px);-webkit-transition:all .2s cubic-bezier(.55,.055,.675,.19);transition:all .2s cubic-bezier(.55,.055,.675,.19)}", ""]);
// Exports
exports.locals = {
	"process": "_1qATnv",
	"fade-enter": "_5aAZnB",
	"fade-exit": "_J2V8D1",
	"fade-enter-active": "_ZJNvRv",
	"fade-exit-active": "_O0GvDj"
};
module.exports = exports;
