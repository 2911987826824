import FetchOrCreateAddressMutation from '~tools/react/graphql/mutations/addresses/fetchOrCreateAddress.gql';
import withMutation from '~tools/react/graphql/withMutation';

interface Building {
  uuid: string;
}

interface Address {
  building: Building;
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  neighborhood: string | null;
  state: string;
  streetAddress1: string;
  timezone: string;
  uuid: string;
  zipCode: string;
}

interface FetchOrCreateAddressInput {
  city?: string;
  googlePlaceId?: string;
  latitude?: number;
  longitude?: number;
  state?: string;
  streetAddressUnit1?: string;
  zipCode?: string;
}

interface Data {
  fetchOrCreateAddress: Address;
}

interface Variables {
  input: FetchOrCreateAddressInput;
}

export interface FetchOrCreateAddressProps {
  fetchOrCreateAddress: (input: FetchOrCreateAddressInput) => Promise<Address>;
}

export default withMutation<{}, Data, Variables, FetchOrCreateAddressProps>(FetchOrCreateAddressMutation, {
  props: props => ({
    fetchOrCreateAddress: async (input) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error fetching or creating address.');

      return res.data.fetchOrCreateAddress;
    }
  }),
});
