import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import isEqual from 'react-fast-compare';
import { RouteComponentProps, withRouter } from 'react-router';

import { AmenityTypes, VoteEntityTypes } from '~tools/types/graphqlSchema';

import graphql, { compose } from '~tools/react/graphql';
import withCreateVote, { CreateVoteProps } from '~tools/react/graphql/mutations/votes/withCreateVote';
import withUpdateVote, { UpdateVoteProps } from '~tools/react/graphql/mutations/votes/withUpdateVote';

import AnimatedStepsFlow from '~tools/react/components/AnimatedStepsFlow';
import Button from '~tools/react/components/Button';
import Checkbox from '~tools/react/components/Form/components/Checkbox';
import Form from '~tools/react/components/Form';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import ManageListingAmenitiesView from './components/ManageListingAmenitiesView';
import query from './ListingSetupAmenities.gql';

interface Amenity {
  name: string;
  type: AmenityTypes;
  uuid: string;
}

interface State {
  isLoading: boolean;
  selectedAmenityUuids: string[];
}

interface QueryProps extends RouteComponentProps {
  onUpdateListing: (args: { amenityUuids: string[] }) => Promise<any>;
  amenities: Amenity[];
  buildingAmenities: {
    amenity: Amenity;
    viewerVote: {
      sentiment: number;
      uuid: string;
    } | null;
    uuid: string;
  }[];
  listingAmenities: Amenity[];
}

type Props = QueryProps & CreateVoteProps & UpdateVoteProps;

class ListingSetupAmenities extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      selectedAmenityUuids: props.buildingAmenities
        .filter(bA => !bA.viewerVote || bA.viewerVote?.sentiment === 1)
        .map(bA => bA.amenity.uuid)
        .concat(props.listingAmenities.map(lA => lA.uuid)),
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      !isEqual(nextProps.buildingAmenities, this.props.buildingAmenities) ||
      !isEqual(nextProps.listingAmenities, this.props.listingAmenities)
    ) {
      this.setState({
        selectedAmenityUuids: nextProps.buildingAmenities
          .filter(bA => !bA.viewerVote || bA.viewerVote?.sentiment === 1)
          .map(bA => bA.amenity.uuid)
          .concat(nextProps.listingAmenities.map(lA => lA.uuid)),
      });
    }
  }

  handleCheckChange = async (val, event) => {
    const uuid = event.currentTarget.getAttribute('name');
    if (val) {
      this.setState({
        selectedAmenityUuids: [
          ...this.state.selectedAmenityUuids,
          uuid,
        ],
      }, () => {
        this.props.onUpdateListing({ amenityUuids: this.state.selectedAmenityUuids });
      });
    } else {
      this.setState({
        selectedAmenityUuids: [
          ..._.filter(this.state.selectedAmenityUuids, a => a !== uuid),
        ],
      }, () => {
        this.props.onUpdateListing({ amenityUuids: this.state.selectedAmenityUuids });
      });
    }

    // update sentiment if building amenity
    const buildingAmenity = this.props.buildingAmenities.find(bA => bA.amenity.uuid === uuid);
    if (!buildingAmenity) return;

    // if removed amenity is a build amenity, the user is expressing negative sentiment on it so create/update their vote
    if (buildingAmenity.viewerVote) {
      await this.props.updateVote(buildingAmenity.viewerVote.uuid, { sentiment: val ? 1 : 0 });
    } else {
      await this.props.createVote({
        entity: {
          type: VoteEntityTypes.BUILDING_AMENITY,
          uuid: buildingAmenity.uuid,
        },
        sentiment: val ? 1 : 0,
      });
    }
  }

  handleSubmit = async () => {
    this.setState({ isLoading: true });
    await this.props.onUpdateListing({ amenityUuids: this.state.selectedAmenityUuids });
    this.setState({ isLoading: false });
    this.props.history.push('payments');
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        {this.props.buildingAmenities.length > 0 ? (
          <Text
            color={Text.enums.Colors.Info}
            content="It looks like we already had some amenity information for your building, so we added them for you!"
            size={Text.enums.Sizes.Small}
          />
        ) : null}
        <Form.FormSection columns={Form.FormSection.enums.Columns.Two}>
          <ManageListingAmenitiesView title="Common amenities">
            {_.map(_.filter(this.props.amenities, { type: AmenityTypes.PRIMARY }), (amenity, index) => (
              <Fragment key={amenity.uuid}>
                {index > 0 ? <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} /> : null}
                <Checkbox
                  isChecked={!!_.find(this.state.selectedAmenityUuids, uuid => amenity.uuid === uuid)}
                  key={amenity.name}
                  label={amenity.name}
                  name={amenity.uuid}
                  onChange={this.handleCheckChange}
                />
              </Fragment>
            ))}
          </ManageListingAmenitiesView>
          <ManageListingAmenitiesView title="Additional amenities">
            {_.map(_.filter(this.props.amenities, { type: AmenityTypes.SECONDARY }), (amenity, index) => (
              <Fragment key={amenity.uuid}>
                {index > 0 ? <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} /> : null}
                <Checkbox
                  isChecked={!!_.find(this.state.selectedAmenityUuids, uuid => amenity.uuid === uuid)}
                  key={amenity.name}
                  label={amenity.name}
                  name={amenity.uuid}
                  onChange={this.handleCheckChange}
                />
              </Fragment>
            ))}
          </ManageListingAmenitiesView>
        </Form.FormSection>
        <AnimatedStepsFlow.AnimatedStepsFlowStep.AnimatedStepsFlowStepFooter
          primaryAction={{
            isLoading: this.state.isLoading,
            label: 'Continue',
            type: Button.enums.Types.Submit,
          }}
          secondaryLink={{
            path: 'rooms',
          }}
        />
      </Form>
    );
  }
}

export default compose(
  withRouter,
  withCreateVote,
  withUpdateVote,
  graphql(query, {
    options: props => ({
      variables: {
        listingUuid: props.listingUuid,
      },
    }),
    props: props => ({
      amenities: props.data.amenities,
      buildingAmenities: _.get(props.data.viewer, 'listing.building.buildingAmenities', []),
      isLoading: props.data.loading,
      listingAmenities: _.get(props.data.viewer, 'listing.amenities', []),
    }),
  }),
)(ListingSetupAmenities);
