import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';
import Text from '~tools/react/components/Text';

import styles from './ManageListingAmenitiesView.scss';

interface Props {
  children: React.ReactNode;
  title: string,
}

function ManageListingAmenitiesView(props: Props) {
  return (
    <div styleName="manage-listing-amenities-view">
      <Text size={Text.enums.Sizes.Large} content={props.title} />
      {props.children}
    </div>
  );
}

export default withStyles(styles)(ManageListingAmenitiesView);
