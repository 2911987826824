import React, { PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './SkeletonBlock.scss';

interface Props {
  height?: string;
  width?: string;
  margin?: {
    bottom?: string;
    left?: string;
    right?: string;
    top?: string;
  };
}

class SkeletonBlock extends PureComponent<Props> {
  render() {
    const margin = {
      bottom: this.props.margin?.bottom ?? '0',
      left: this.props.margin?.left ?? '0',
      right: this.props.margin?.right ?? '0',
      top: this.props.margin?.top ?? '0',
    };
    return (
      <div
        style={{
          height: this.props.height ?? '100%',
          width: this.props.width ?? '100%',
          margin: `${margin.top} ${margin.right} ${margin.bottom} ${margin.left}`,
        }}
        styleName="skeleton-block"
      />
    );
  }
}

export default withStyles(styles)(SkeletonBlock);
