// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._WVj3AA{-webkit-box-align:center;align-items:center;background-color:transparent;border:none;display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row;padding:16px 24px;position:relative;text-align:left;-webkit-transition:background-color .25s;transition:background-color .25s;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;width:100%}._WVj3AA ._orGNJG{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column}._WVj3AA ._XP8xaV{display:-webkit-inline-box;display:inline-flex;margin-right:16px;min-width:32px}._WVj3AA._mmJvdV{padding:16px 32px 16px 24px}._WVj3AA._mmJvdV:after{background:#1f8eed;border-radius:50%;content:\"\";height:6px;position:absolute;right:24px;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);width:6px}._WVj3AA:not(:last-child){border-bottom:1px solid #e6e8eb}._WVj3AA:hover{background:#f6f8fa}", ""]);
// Exports
exports.locals = {
	"notification": "_WVj3AA",
	"notification__copy": "_orGNJG",
	"notification__avatar": "_XP8xaV",
	"notification--unread": "_mmJvdV"
};
module.exports = exports;
