import React, { Component, MouseEvent } from 'react';

import Button from '~tools/react/components/Button';
import Dropdown from '~tools/react/components/Dropdown';
import ButtonSelect from '~tools/react/components/Form/components/ButtonSelect';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import PropertyManagerContractsTypeahead, { PropertyManagerContractSearchResult } from '~tools/react/containers/PropertyManagerContractsTypeahead';
import withStyles from '~tools/react/hocs/withStyles';
import { IssueStatuses } from '~tools/types/graphqlSchema';

import { formatShortAddress } from '~web-manage/lib/common/utils/addressUnit';

import { AddressUnit } from '../../types';
import styles from './IssuesFilterDropdown.scss';

interface Props {
  statusFilter: IssueStatuses | null;
  propertyManagerContractFilter: {
    uuid: string;
    addressUnit: AddressUnit;
  } | null;
  onSaveFilters: (
    status: IssueStatuses | null,
    propertyManagerContractUuid: string | null
  ) => void;
}

interface State {
  isFilterMenuOpen: boolean;
  filteredPropertyManagerContract: {
    value: string;
    label: string;
  } | null;
  filteredStatus: IssueStatuses | null;
}

class IssuesFilterDropdown extends Component<Props, State> {
  filterMenuRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.filterMenuRef = React.createRef();

    this.state = {
      isFilterMenuOpen: false,
      filteredStatus: props.statusFilter ?? null,
      filteredPropertyManagerContract: props.propertyManagerContractFilter ? {
        value: props.propertyManagerContractFilter.uuid,
        label: formatShortAddress(props.propertyManagerContractFilter.addressUnit),
      } : null,
    };
  }

  handleDocumentClick = e => {
    if (!this.filterMenuRef?.current) return;
    const isOutsideMenu = !this.filterMenuRef.current.contains(e.target);

    if (isOutsideMenu) {
      this.handleCloseFilterMenu();
    }
  }

  handleOpenFilterMenu = (e: MouseEvent<EventTarget>) => {
    e.nativeEvent.stopImmediatePropagation();

    document.addEventListener('click', this.handleDocumentClick);
    this.setState({ isFilterMenuOpen: true });
  };

  handleCloseFilterMenu = () => {
    document.removeEventListener('click', this.handleDocumentClick);
    this.setState({ isFilterMenuOpen: false });
  };

  handleStatusChange = (newIssueStatus: string | null) => {
    this.setState({ filteredStatus: newIssueStatus as IssueStatuses });
  };

  handlePropertyManagerContractChange = (data: PropertyManagerContractSearchResult | null) => {
    this.setState({
      filteredPropertyManagerContract: data ? {
        value: data.uuid,
        label: formatShortAddress(data.addressUnit),
      } : null
    });
  };

  handleSaveFilters = () => {
    this.handleCloseFilterMenu();
    this.props.onSaveFilters(
      this.state.filteredStatus,
      this.state.filteredPropertyManagerContract?.value ?? null,
    );
  };

  handleClearFilters = () => {
    this.setState({
      filteredPropertyManagerContract: null,
      filteredStatus: null,
    }, this.handleSaveFilters);
  };

  render() {
    return (
      <div styleName="issues-filter-dropdown">
        <Button
          color={Button.enums.Colors.Gray}
          icon={Button.enums.Icons.Filter}
          label="Filters"
          onClick={this.state.isFilterMenuOpen ?
            this.handleCloseFilterMenu :
            this.handleOpenFilterMenu}
          size={Button.enums.Sizes.Small}
          style={Button.enums.Styles.Outline}
        />
        {this.state.isFilterMenuOpen ? (
          <Dropdown
            carotPosition={{
              right: '42px',
            }}
            position={{
              right: '0px',
              top: '54px',
            }}>
            <div
              styleName="issues-filter-dropdown__menu"
              ref={this.filterMenuRef}>
              <ButtonSelect
                label="Status"
                labelFormat={ButtonSelect.enums.LabelFormats.Stacked}
                name="status"
                options={[{
                  label: 'Outstanding',
                  value: IssueStatuses.IN_PROGRESS,
                }, {
                  label: 'Resolved',
                  value: IssueStatuses.RESOLVED,
                }]}
                onChange={this.handleStatusChange}
                value={this.state.filteredStatus ?? undefined}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
              <PropertyManagerContractsTypeahead
                label="Unit address"
                name="propertyManagerContract"
                onChange={this.handlePropertyManagerContractChange}
                placeholder="Address..."
                value={this.state.filteredPropertyManagerContract?.label ?? ''}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
              <div styleName="issues-filter-dropdown__menu__actions">
                <Button
                  color={Button.enums.Colors.Gray}
                  style={Button.enums.Styles.Outline}
                  label="Clear"
                  size={Button.enums.Sizes.XSmall}
                  onClick={this.handleClearFilters}
                />
                <Button
                  style={Button.enums.Styles.Outline}
                  label="Save changes"
                  size={Button.enums.Sizes.XSmall}
                  type={Button.enums.Types.Submit}
                  onClick={this.handleSaveFilters}
                />
              </div>
            </div>
          </Dropdown>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(IssuesFilterDropdown);
