import React, { ComponentType, Fragment, PureComponent } from 'react';
import { SortableElement } from 'react-sortable-hoc';

import { compose } from '~tools/react/hocs/utils';
import withAddPhotoToListing, { AddPhotoToListingProps } from '~tools/react/graphql/mutations/photos/withAddPhotoToListing';
import withSignUploadRequest, { SignUploadRequestProps } from '~tools/react/graphql/mutations/uploads/withSignUploadRequest';
import withUpdatePhoto, { UpdatePhotoProps } from '~tools/react/graphql/mutations/photos/withUpdatePhoto';

import PhotoEditorModal from '~tools/react/components/PhotoEditorModal';

import PhotoVisual from './components/PhotoVisual';

import { Photo, Tag } from './types';

export type Adjustment = { [key: string]: string | number };

interface State {
  cacheTimestamp: number; // This is for updating the fetch of the image, if filters are applied
  error?: Error;
  isEditorModalOpen: boolean;
  photo?: Photo;
  progress: number;
}

interface InputProps {
  index: number;
  isLoading?: boolean;
  isReordering?: boolean;
  listingUuid: string;
  mediaTags: Tag[];
  onDelete?: (arg: Photo) => void;
  onSuccess?: (arg: Photo) => void;
  photo: Photo;
  position: number;
}

type Props = InputProps & AddPhotoToListingProps & SignUploadRequestProps & UpdatePhotoProps;

class EditablePhoto extends PureComponent<Props, State> {
  ref: HTMLImageElement | null = null;

  state: State = {
    cacheTimestamp: new Date().getTime(),
    isEditorModalOpen: false,
    progress: this.props.photo.file ? 0 : 100,
  };

  componentDidMount() {
    if (this.props.photo.file) this.handleFileUpload();
  }

  handleFileUpload = async () => {
    try {
      const file = this.props.photo.file;
      if (!file) return;

      const aws = await this.props.signUploadRequest({ filename: file.name });
      const xhr = new XMLHttpRequest();
      const uploadForm = new FormData();

      uploadForm.append('key', aws.key);
      uploadForm.append('AWSAccessKeyId', aws.accessKeyId);
      uploadForm.append('acl', aws.acl);
      uploadForm.append('policy', aws.policy);
      uploadForm.append('signature', aws.signature);
      uploadForm.append('Content-Type', aws.contentType);
      uploadForm.append('file', file);

      xhr.addEventListener('load', async () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          const p = await this.props.addPhotoToListing({
            height: this.props.photo.height,
            key: aws.key,
            listingUuid: this.props.listingUuid,
            position: this.props.position,
            width: this.props.photo.width,
          });
          this.setState({ photo: p }, () => {
            if (this.props.onSuccess) this.props.onSuccess(p);
          });
        } else {
          this.setState({
            error: new Error('There was an error uploading the image'),
            progress: 100,
          });
        }
      });

      xhr.addEventListener('error', () => {
        this.setState({
          error: new Error('There was an error uploading the image'),
          progress: 100,
        });
      });

      xhr.open('POST', aws.bucketUrl);

      xhr.upload.addEventListener('progress', (e: ProgressEvent) => {
        this.setState({
          error: undefined,
          progress: (e.loaded / e.total) * 100,
        });
      });

      xhr.send(uploadForm);
    } catch (error) {
      this.setState({
        error,
        progress: 100,
      });
    }
  };

  handleDeletePhoto = () => {
    if (!this.props.onDelete) return;

    this.setState({ isEditorModalOpen: false });
    if (this.state.photo) {
      this.props.onDelete({
        uuid: this.state.photo.uuid,
        ...this.props.photo,
      });
      return;
    }

    this.props.onDelete(this.props.photo);
  }

  render() {
    if (this.props.isLoading) {
      return (
        <PhotoVisual
          isLoading
          position={0}
          progress={100}
        />
      );
    }

    const formattedUrl = this.props.isReordering ? `${this.props.photo.url}?fit=min&auto=enhance,compress&h=100&w=738` :
      `${this.props.photo.url}?fit=min&auto=enhance,compress&h=100&w=138&${this.state.cacheTimestamp}`;
    return (
      <Fragment>
        <PhotoEditorModal
          deletePhoto={this.handleDeletePhoto}
          isOpen={this.state.isEditorModalOpen}
          mediaTags={this.props.mediaTags}
          onClose={this.toggleEditor}
          selectedPhoto={this.state.photo || this.props.photo}
          updatePhoto={this.props.updatePhoto}
        />
        <PhotoVisual
          bindRef={this.bindRef}
          error={this.state.error}
          isReordering={this.props.isReordering}
          onClick={this.state.error ? this.handleFileUpload : this.toggleEditor}
          position={this.props.position}
          progress={this.state.progress}
          url={this.props.photo.file ? this.props.photo.url : formattedUrl}
        />
      </Fragment>
    );
  }

  bindRef = (e: HTMLImageElement | null) => { this.ref = e; };

  toggleEditor = (refreshCache?: boolean | null) => {
    this.setState({
      cacheTimestamp: refreshCache ? new Date().getTime() : this.state.cacheTimestamp,
      isEditorModalOpen: !this.state.isEditorModalOpen,
    });
  };
}

export default compose(
  SortableElement,
  withAddPhotoToListing,
  withSignUploadRequest,
  withUpdatePhoto,
)(EditablePhoto) as ComponentType<InputProps>;
