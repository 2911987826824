import React, { PureComponent } from 'react';
import queryString from 'query-string';

import { compose } from '~tools/react/graphql';
import withQuery from '~tools/react/graphql/withQuery';

import { ConditionInspection, InputProps, Props, QueryProps } from './types';
import InspectionPrintable from './components/InspectionPrintable';
import InspectionSummary from './components/InspectionSummary';

import query from './Inspection.gql';

interface State {
  isPrintMode: boolean;
}

class Inspection extends PureComponent<Props, State> {
  state: State = {
    isPrintMode: !!queryString.parse(this.props.location.search).print,
  };

  handleTogglePrintMode = () => {
    this.setState({
      isPrintMode: !this.state.isPrintMode,
    });
  };

  render() {
    return (
      <>
        {this.state.isPrintMode ? (
          <InspectionPrintable
            conditionInspection={this.props.conditionInspection}
            onExitPrintMode={this.handleTogglePrintMode}
          />
        ) : (
          <InspectionSummary
            conditionInspection={this.props.conditionInspection}
            viewerUuid={this.props.viewerUuid}
            onPrint={this.handleTogglePrintMode}
          />
        )}
      </>
    );
  }
}

interface Variables {
  conditionInspectionUuid: string;
}

interface Response {
  viewer: {
    uuid: string;
    conditionInspection: ConditionInspection | null;
  } | null;
}

export default compose(
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: props => ({
      fetchPolicy: 'no-cache',
      ssr: false,
      skip: !props.match.params.conditionInspectionUuid,
      variables: {
        conditionInspectionUuid: props.match.params.conditionInspectionUuid,
      },
    }),
    props: props => ({
      viewerUuid: props.data?.viewer?.uuid ?? null,
      conditionInspection: props.data?.viewer?.conditionInspection ?? null,
      isLoading: props.loading,
      refetch: props.refetch,
    }),
  }),
)(Inspection);
