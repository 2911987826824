// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-webkit-keyframes _99v28B{0%{-webkit-transform:scale(0);transform:scale(0)}to{-webkit-transform:scale(1);transform:scale(1)}}@keyframes _99v28B{0%{-webkit-transform:scale(0);transform:scale(0)}to{-webkit-transform:scale(1);transform:scale(1)}}._wdjSpo{-webkit-box-align:center;align-items:center;background:#eff2f7;border-radius:4px;display:-webkit-box;display:flex;margin:8px 4px 0;max-width:100%;min-height:30px;padding:2px 6px}._wdjSpo ._zaN2zR{height:18px;margin-right:5px;min-width:18px;stroke:#53d391;width:18px}._wdjSpo ._YXrQQa{color:#182029;font-size:14px;font-weight:300;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}@media screen and (max-width:1127px){._wdjSpo ._YXrQQa{font-size:12px}}._wdjSpo ._nrBDZ7{color:#8492a6;font-size:14px;font-weight:100;margin-right:auto;padding-left:5px;white-space:nowrap}@media screen and (max-width:1127px){._wdjSpo ._nrBDZ7{font-size:12px}}._wdjSpo ._DBDVoM{border:none;border-left:1px solid #d3dce6;display:-webkit-box;display:flex;margin:0 0 0 8px;padding:0 0 0 8px}._wdjSpo ._DBDVoM ._ernPPb{height:14px;stroke:#8492a6;width:14px}._wdjSpo ._DBDVoM ._ernPPb path{stroke-width:3px}", ""]);
// Exports
exports.locals = {
	"upload-attachment": "_wdjSpo",
	"upload-attachment__check": "_zaN2zR",
	"upload-attachment__filename": "_YXrQQa",
	"upload-attachment__filesize": "_nrBDZ7",
	"upload-attachment__close": "_DBDVoM",
	"upload-attachment__cross": "_ernPPb",
	"animateIn": "_99v28B"
};
module.exports = exports;
