import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './ReceiptView.scss';

interface Props {
  children: ReactNode;
}

function ReceiptView(props: Props) {
  return (
    <div styleName="receipt-view">
      {props.children}
    </div>
  );
}

export default withStyles(styles)(ReceiptView);
