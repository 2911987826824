// @flow

import React, { Fragment } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import withStyles from '~tools/react/hocs/withStyles';
import Cross from '~tools/svgs/icons/interface/cross.svg';
import SkeletonDisplayText from '~tools/react/components/SkeletonDisplayText';

import styles from './PhotoCard.scss';

type Props = {|
  bindRef?: (?HTMLImageElement) => void,
  hasError?: boolean,
  isReordering?: boolean,
  onClick?: () => void | Promise<*>,
  position?: number,
  progress?: number,
  isLoading?: boolean,
  sorting?: boolean,
  url?: string,
|};

function PhotoCard(props: Props) {
  if (props.isLoading) {
    return (
      <div styleName="photos-photo-wrapper photos-photo-wrapper--loading">
        <SkeletonDisplayText />
      </div>
    );
  }
  return (
    <button
      onClick={props.progress !== 100 || props.isReordering ? null : props.onClick}
      styleName={classNames({
        'photos-photo-wrapper': true,
        'photos-photo-wrapper--uploading': props.progress !== 100,
        'photos-photo-wrapper--error': props.hasError,
        'photos-photo-wrapper--reordering': props.isReordering,
        'photos-photo-wrapper--sorting': props.sorting,
      })}>
      <h5>{props.position + 1}</h5>
      {props.hasError ? <Cross /> : null}
      {props.progress !== 100 ? (
        <Fragment>
          <p>{_.round(props.progress)}%</p>
          <span style={{ transform: props.progress ? `scaleX(${props.progress / 100}` : null }} />
        </Fragment>
      ) : null}
      <img ref={props.bindRef} alt="" styleName="photos-photo" src={props.url} />
    </button>
  );
}

export default withStyles(styles)(PhotoCard);
