// Boosts
export const ENTERED_BOOST_MINIMUM_AMOUNT = 'Entered Boost Minimum Amount';
export const PAUSED_BOOST_CAMPAIGN = 'Paused Boost Campaign';
export const PURCHASED_BOOST = 'Purchased Boost';
export const VIEWED_BOOST_INSIGHTS_MODAL = 'Viewed Boost Insights Modal';
export const VIEWED_BOOST_PAYMENT_FORM = 'Viewed Boost Payment Form';
export const VIEWED_BOOST_MODAL = 'Viewed Boost Modal';
export const VIEWED_INSIGHTS_CAMPAIGN_DETAILS = 'Viewed Insights Campaign Details';
export const VIEWED_PAUSE_BOOST_CONFIRMATION_MODAL = 'Viewed Pause Boost Confirmation Modal';

// PAYMENT SUBSCRIPTIONS
export const CHANGED_PAYMENT_SUBSCRIPTION = 'Changed Payment Subscription';

// LISTINGS
export const COMPLETED_LISTING_STEP = 'Completed Listing Step';
export const CREATED_BOOKMARK = 'Created Bookmark';
export const CREATED_LISTING = 'Created Listing';
export const DELETED_BOOKMARK = 'Deleted Bookmark';
export const INVITED_RENTER = 'Invited renter';
export const POSTED_LISTING = 'Posted Listing';
export const PURCHASED_FLIP_INSTANT = 'Purchased Caretaker Instant';
export const REQUESTED_FLIP_INSTANT = 'Requested Caretaker Instant';
export const SEARCH = 'Search';
export const CREATED_CONVERSATION = 'Created Conversation';
export const SUBMITTED_FLIP_APPLICATION = 'Submitted Caretaker Application';
export const CREATED_LISTING_QUESTION = 'Created Listing Question';
export const BOOKED_VIEWING = 'Booked Viewing';

// SAVED SEARCH
export const CREATED_SEARCH_FILTER_ALERT = 'Created Search Filter Alert';
export const OPENED_SAVEDSEARCH_SNACKBAR = 'Opened Saved Search Snackbar';
export const OPENED_SAVEDSEARCH_MODAL = 'Opened Saved Search Modal';
export const OPENED_SEARCH_AUTH_MODAL = 'Opened Auth Modal on Search Page';
export const ENTERED_SEARCH_ONBOARDING_PARAMS = 'Entered Search Onboarding Parameters';

// APPLICATIONS
export const VERIFIED_EMPLOYMENT_INCOME = 'Verified Employment Income';
export const VIEWED_COMPLETE_APPLICATION_MODAL = 'Viewed Complete Application Modal'; // Submit application confirmation + prompts to put down upfront rent/deposit

export const SUBMIT_PARTNER_BUILDING_LEAD = 'Submitted Partner Building Lead';
