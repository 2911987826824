// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._jrVTmp{-webkit-box-flex:1;flex-grow:1;height:0;overflow:auto;-webkit-overflow-scrolling:touch;padding-bottom:12px}@media screen and (max-width:1127px){._jrVTmp{padding-bottom:120px}}._jrVTmp ._YXrDyJ{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;padding:0 12px 24px}._jrVTmp ._YXrDyJ:first-child{border-top:1px solid #e6e8eb;padding-top:24px}._jrVTmp ._YXrDyJ+div{margin-top:24px}._jrVTmp ._YXrDyJ>span{margin-left:12px;width:88%}._jrVTmp::-webkit-scrollbar-track{background:transparent;height:0;width:0}", ""]);
// Exports
exports.locals = {
	"thread-items-view": "_jrVTmp",
	"thread-items-view__loader": "_YXrDyJ"
};
module.exports = exports;
