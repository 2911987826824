import * as Scale from '@vx/scale';
import { Tooltip } from '@vx/tooltip';
import withTooltip, { WithTooltipProvidedProps } from '@vx/tooltip/lib/enhancers/withTooltip';
import _ from 'lodash';
import memoize from 'memoize-one';
import moment from 'moment-timezone';
import React, { ComponentType, Fragment, PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';
import { compose } from '~tools/react/hocs/utils';
import { formatAsUSD } from '~tools/utils/string';

import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import SkeletonBlock from '~tools/react/components/SkeletonBlock';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import SVGChart from './components/SVGChart';
import SkeletonSVGChart from './components/SkeletonSVGChart';

import styles from './BarChartVisual.scss';

const flipBlue = '#1F8EED';
const flipBlueLight = '#85D7FF';
const blueGray2 = '#273444';
const whiteGray5 = '#D3DCE6';

interface Data {
  Expected: number;
  Received: number;
  groupStartsAt: string;
}

interface TooltipData {
  color: string;
  data: Data;
  height: number;
  key: string;
  width: number
  x: number;
  y: number;
}

interface InputProps {
  data: Data[];
  height: number;
  isLoading?: boolean;
}

type Props = InputProps & WithTooltipProvidedProps<TooltipData>;

class BarChartVisual extends PureComponent<Props> {
  getMemoizedKeys = memoize((data) => _.filter(_.keys(data[0]), k => k !== 'groupStartsAt'));

  handleShowTooltip = (args: {
    tooltipData: TooltipData;
    tooltipLeft: number;
    tooltipTop: number;
  }) => this.props.showTooltip(args);
  handleHideTooltip = () => this.props.hideTooltip();

  render() {
    const keys = this.getMemoizedKeys(this.props.data);
    const color = Scale.scaleOrdinal({
      domain: keys,
      range: [flipBlue, flipBlueLight],
    });

    const currentMonth = moment.utc(this.props.tooltipData ? this.props.tooltipData.data.groupStartsAt : undefined).startOf('month');
    const currentMonthVolume = _.find(this.props.data, month => currentMonth.isSame(month.groupStartsAt));
    const receivedAmountInCents = currentMonthVolume?.Received || 0;
    const expectedAmountInCents = currentMonthVolume?.Expected || 0;

    return (
      <Fragment>
        <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '20px',
            }}>
            <div
              style={{
                backgroundColor: this.props.isLoading ? whiteGray5 : flipBlue,
                borderRadius: '2px',
                height: '10px',
                width: '10px',
              }}
            />
          </div>
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
          <div style={{ whiteSpace: 'nowrap' }}>
            {this.props.isLoading ? (
              <SkeletonBlock
                height="16px"
                width="122px"
                margin={{
                  bottom: '4px',
                }}
              />
            ) : (
              <Text
                color={Text.enums.Colors.Primary}
                content="Received payments"
                size={Text.enums.Sizes.Small}
              />
            )}
            {this.props.isLoading ? (
              <SkeletonBlock
                margin={{
                  top: '5px',
                }}
                height="20px"
                width="75%"
              />
            ) : (
              <Text
                color={Text.enums.Colors.Info}
                content={`$${formatAsUSD(receivedAmountInCents / 100, true)}`}
                size={Text.enums.Sizes.Medium}
              />
            )}
          </div>
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Medium} />
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '20px',
            }}>
            <div
              style={{
                backgroundColor: this.props.isLoading ? whiteGray5 : flipBlueLight,
                borderRadius: '2px',
                height: '10px',
                width: '10px',
              }}
            />
          </div>
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
          <div style={{ whiteSpace: 'nowrap' }}>
            {this.props.isLoading ? (
              <SkeletonBlock
                height="16px"
                width="122px"
                margin={{
                  bottom: '4px',
                }}
              />
            ) : (
              <Text
                color={Text.enums.Colors.Primary}
                content="Expected payments"
                size={Text.enums.Sizes.Small}
              />
            )}
            {this.props.isLoading ? (
              <SkeletonBlock
                margin={{
                  top: '5px',
                }}
                height="20px"
                width="75%"
              />
            ) : (
              <Text
                color={Text.enums.Colors.Secondary}
                content={!this.props.isLoading ? `$${formatAsUSD(expectedAmountInCents / 100, true)}` : '-'}
                size={Text.enums.Sizes.Medium}
              />
            )}
          </div>
          <div style={{ width: '100%' }}>
            {this.props.isLoading ? (
              <SkeletonBlock
                margin={{
                  top: '2px',
                  left: 'auto',
                }}
                height="18px"
                width="50%"
              />
            ) : (
              <Text
                align={Text.enums.Align.Right}
                color={Text.enums.Colors.Secondary}
                content={`${currentMonth.format('MMMM Do')} - ${currentMonth.clone().endOf('month').format('MMMM Do')}`}
                size={Text.enums.Sizes.Small}
              />
            )}
          </div>
        </Row>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
        <div
          styleName="chart-view"
          style={{ height: `${this.props.height}px` }}>
          {this.props.isLoading ? (
            <SkeletonSVGChart
              data={[
                {
                  Received: 6465000,
                  Expected: 0,
                  groupStartsAt: '2020-12-01T00:00:00.000Z',
                },
                {
                  Received: 6645000,
                  Expected: 0,
                  groupStartsAt: '2021-01-01T00:00:00.000Z',
                },
                {
                  Received: 7250000,
                  Expected: 0,
                  groupStartsAt: '2021-02-01T00:00:00.000Z',
                },
                {
                  Received: 6625968,
                  Expected: 0,
                  groupStartsAt: '2021-03-01T00:00:00.000Z',
                },
                {
                  Received: 6915000,
                  Expected: 0,
                  groupStartsAt: '2021-04-01T00:00:00.000Z',
                },
                {
                  Received: 7165000,
                  Expected: 0,
                  groupStartsAt: '2021-05-01T00:00:00.000Z',
                },
                {
                  Received: 7180000,
                  Expected: 0,
                  groupStartsAt: '2021-06-01T00:00:00.000Z',
                },
              ]}
              height={this.props.height}
            />
          ) : (
            <Fragment>
              <SVGChart
                data={this.props.data}
                height={this.props.height}
                onHideTooltip={this.handleHideTooltip}
                onShowTooltip={this.handleShowTooltip}
                tooltipGroupStartsAt={this.props.tooltipData?.data.groupStartsAt}
              />
              {this.props.tooltipOpen && this.props.tooltipData ? (
                <Tooltip
                  top={this.props.tooltipTop}
                  left={(this.props.tooltipLeft || 0) + 24}
                  style={{
                    backgroundColor: 'transparent',
                    borderRadius: 0,
                    boxShadow: 'none',
                    padding: 0,
                  }}>
                  <div
                    style={{
                      backgroundColor: '#FFFFFF',
                      borderRadius: 3,
                      boxShadow: '0 5px 40px 0 rgba(24, 32, 41, 0.08), 0 30px 100px 0 rgba(24, 32, 41, 0.1)',
                      color: blueGray2,
                      left: 0,
                      marginTop: 'calc(-50% - 16px)',
                      minWidth: 60,
                      padding: 8,
                      position: 'relative',
                      zIndex: 1,
                    }}>
                    <div styleName="carot" />
                    <div style={{ marginBottom: '3px', color: color('Expected') }}>
                      <strong style={{ whiteSpace: 'nowrap' }}>Expected</strong>
                    </div>
                    <div>${formatAsUSD(this.props.tooltipData.data.Expected / 100)}</div>
                    <div style={{ margin: '5px 0 3px', color: color('Received') }}>
                      <strong style={{ whiteSpace: 'nowrap' }}>Received</strong>
                    </div>
                    <div>${formatAsUSD(this.props.tooltipData.data.Received / 100)}</div>
                    <div style={{ marginTop: '5px' }}>
                      <small>{this.formatAsMonth(this.props.tooltipData.data)}</small>
                    </div>
                  </div>
                </Tooltip>
              ) : null}
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }

  formatAsMonth = (data: { groupStartsAt: string }) => moment.utc(data.groupStartsAt).format('MMMM YYYY');
}

export default compose(
  withStyles(styles),
  withTooltip,
)(BarChartVisual) as ComponentType<InputProps>;
