// @flow

import React, { type Node } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './ManageListingNextStepsFooter.scss';

type Props = {|
  children: Node,
|};

function ManageListingNextStepsFooter(props: Props) {
  return (
    <div styleName="next-steps-footer">
      {props.children}
    </div>
  );
}

export default withStyles(styles)(ManageListingNextStepsFooter);
