// @flow

import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';
import Text from '~tools/react/components/Text';

import styles from './ManageListingPhotosEmptyState.scss';

function ManageListingPhotosEmptyState() {
  return (
    <div styleName="photos-empty-state">
      <Text color={Text.enums.Colors.Secondary} content="You don't have any photos uploaded" />
    </div>
  );
}

export default withStyles(styles)(ManageListingPhotosEmptyState);
