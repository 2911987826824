import withMutation from '~tools/react/graphql/withMutation';

import ArchiveListingAnswerMutation from './archiveListingAnswer.gql';

interface Response {
  archiveListingAnswer: {
    uuid: string;
  };
}

export interface ArchiveListingAnswerProps {
  archiveListingAnswer: (uuid: string) => Promise<void>
}

export default withMutation<{}, Response, { uuid: string }, ArchiveListingAnswerProps>(ArchiveListingAnswerMutation, {
  props: props => ({
    archiveListingAnswer: async (uuid: string) => {
      await props.mutate({
        variables: { uuid },
      });
    },
  }),
});
