import withMutation from '~tools/react/graphql/withMutation';

import { UpdateClaimInputType as UpdateClaimInput } from '~tools/types/graphqlSchema';

import UpdateClaim from './updateClaim.gql';

interface Claim {
  amountInCents: number;
  approvedAt: string | null;
  description: string | null;
  rejectedAt: string | null;
  uuid: string;
  evidence: {
    filename: string;
    tag: string;
    url: string;
    uuid: string;
  }[];
}

interface Data {
  updateClaim: Claim;
}

interface Variables {
  input: UpdateClaimInput;
  uuid: string;
}

export interface UpdateClaimProps {
  updateClaim: (uuid: string, input: UpdateClaimInput) => Promise<Claim>;
}

export default withMutation<{}, Data, Variables, UpdateClaimProps>(UpdateClaim, {
  props: props => ({
    updateClaim: async (uuid: string, input: UpdateClaimInput) => {
      const res = await props.mutate({
        variables: {
          input,
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error updating claim.');

      return res.data.updateClaim;
    },
  }),
});
