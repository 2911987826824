import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import React, { ComponentType, Fragment, PureComponent } from 'react';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withFetchOrCreateConditionInspection, { FetchOrCreateConditionInspectionProps } from '~tools/react/graphql/mutations/conditionInspections/withFetchOrCreateConditionInspection';
// import { ConditionReportTypes } from '~tools/types/graphqlSchema';

import Globals from '~web-core/lib/common/globals';
import IconBlock from '~web-manage/lib/common/components/IconBlock';
import DataRow from '~web-manage/lib/common/components/DataRow';

import ArrowLink from '~tools/react/components/ArrowLink';
import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import { PropertyManagerContract } from './types';

import query from './UnitMaintenanceSection.gql';

dayjs.extend(utc);
dayjs.extend(timezone);

interface QueryProps {
  isLoading: boolean;
  propertyManagerContract: PropertyManagerContract | null;
  viewer: {
    uuid: string;
    role: 0 | 9;
  } | null;
}

interface InputProps {
  propertyManagerContractUuid?: string;
}

type Props =
  InputProps &
  QueryProps &
  FetchOrCreateConditionInspectionProps;

class UnitMaintenanceSection extends PureComponent<Props> {
  timeout: NodeJS.Timeout | undefined;

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  handleFetchOrCreateInspection = async () => {
    const addressUnit = this.props.propertyManagerContract?.addressUnit;
    if (!addressUnit) return;

    const conditionInspection = await this.props.fetchOrCreateConditionInspection({
      addressUnitUuid: addressUnit.uuid,
    });

    // Domain jump to web-core
    window.location.assign(`${Globals.WEB_CORE_DOMAIN}/inspections/${conditionInspection.uuid}/setup/bedrooms`);
  };

  render() {
    const viewer = this.props.viewer;
    if (!viewer) return null;

    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return null;

    const addressUnit = propertyManagerContract.addressUnit;

    const completedInspections = _.filter(addressUnit.conditionInspections, i => !!i.lockedAt);

    const latestConditionInspection = _.maxBy(completedInspections, i => new Date(i.lockedAt).getTime());

    const unlockedInspection = addressUnit.conditionInspections.find(i => {
      return i.lockedAt === null
        && i.inspectorAccount.uuid === this.props.viewer?.uuid;
    });

    const [resolvedIssues, openIssues] = _.partition(addressUnit.issues, i => !!i.issueResolution);

    // If no inspection for the unit, tell them they need to create one
    // If an inspection has ever been completed, show them Maintenance status

    if (!latestConditionInspection) {
      return (
        <Card.CardSection
          highlightColor={Card.CardSection.enums.HighlightColors.Gray}
          spacing={Card.CardSection.enums.Spacing.Small}>
          <Row
            flexBehavior={Row.enums.FlexBehaviors.Default}>
            <IconBlock
              color={IconBlock.enums.Colors.Gray}
              icon={IconBlock.enums.Icons.Wrench}
            />
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
            <div>
              <Text
                content="*Document the unit's current condition*"
                size={Text.enums.Sizes.Large}
                isMarkdown
              />
              <Text
                content="Create an inspection to start tracking this unit's maintenance condition."
                size={Text.enums.Sizes.Small}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
              <Button
                color={Button.enums.Colors.Gray}
                icon={unlockedInspection ? Button.enums.Icons.Plus : Button.enums.Icons.ChevronRight}
                label={unlockedInspection ? 'Resume inspection' : 'Create inspection'}
                onClick={this.handleFetchOrCreateInspection}
                size={Button.enums.Sizes.XSmall}
                style={Button.enums.Styles.Secondary}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              <ArrowLink
                color={ArrowLink.enums.Colors.Blue}
                label="Tips for home inspections"
                link={{
                  path: `${Globals.WEB_CORE_DOMAIN}/manual/maintenance/inspections`,
                  shouldOpenNewTab: true,
                }}
                size={ArrowLink.enums.Sizes.Small}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
            </div>
          </Row>
        </Card.CardSection>

      );
    }

    return (
      <Fragment>
        <Card.CardSection
          spacing={Card.CardSection.enums.Spacing.Small}>
          <Row
            flexBehavior={Row.enums.FlexBehaviors.Default}>
            <IconBlock
              color={IconBlock.enums.Colors.Blue}
              icon={IconBlock.enums.Icons.Wrench}
            />
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
            <div>
              <Text
                content="*Maintenance issue tracking*"
                size={Text.enums.Sizes.Large}
                isMarkdown
              />
              <Text
                content="Review this unit's the maintenance, repair, and improvement issues"
                size={Text.enums.Sizes.Small}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
              <ArrowLink
                color={ArrowLink.enums.Colors.Blue}
                label="Learn more about issues"
                link={{
                  path: `${Globals.WEB_CORE_DOMAIN}/manual/maintenance/issue-handling`,
                  shouldOpenNewTab: true,
                }}
                size={ArrowLink.enums.Sizes.Small}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
            </div>
          </Row>
        </Card.CardSection>
        <Card.CardSection>
          <DataRow>
            <DataRow.DataRowItem
              label="In-progress issues"
              link={{ path: `/issues?propertyManagerContractUuid=${propertyManagerContract.uuid}&status=in-progress` }}
              description={`${openIssues.length}`}
            />
            <DataRow.DataRowItem
              label="Resolved issues"
              link={{ path: `/issues?propertyManagerContractUuid=${propertyManagerContract.uuid}&status=resolved` }}
              description={`${resolvedIssues.length}`}
            />
            <DataRow.DataRowItem
              label="Last inspected"
              description={latestConditionInspection ? dayjs(latestConditionInspection.createdAt).format('MMM DD, YYYY') : 'N/A'}
              link={latestConditionInspection ?
                { path: `/inspections/${latestConditionInspection.uuid}` } :
                undefined
              }
            />
          </DataRow>
        </Card.CardSection>
      </Fragment>

    );
  }
}

interface Response {
  viewer: {
    propertyManagerContract: PropertyManagerContract | null;
    role: 0 | 9;
    uuid: string;
  }
}

interface Variables {
  propertyManagerContractUuid: string;
}

export default compose(
  withFetchOrCreateConditionInspection,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: props => ({
      variables: {
        propertyManagerContractUuid: props.propertyManagerContractUuid,
      },
      skip: !props.propertyManagerContractUuid,
      ssr: false,
    }),
    props: props => ({
      isLoading: props.loading,
      propertyManagerContract: props.data?.viewer?.propertyManagerContract ?? null,
      refetch: props.refetch,
      viewer: props.data?.viewer ? {
        role: props.data.viewer.role,
        uuid: props.data.viewer.uuid,
      } : null,
    }),
  }),

)(UnitMaintenanceSection) as ComponentType<InputProps>;
