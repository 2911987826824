// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._VYQz8J{background-color:#fff;border:1px solid #e6e8eb;border-right-color:#1f8eed;border-radius:5px 0 0 5px;color:#3c4858;-webkit-box-flex:1;flex:1;font-size:16px;font-weight:300;padding:12px 0;text-align:center;-webkit-transition:all .25s;transition:all .25s;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}._VYQz8J+._VYQz8J{border:1px solid #e6e8eb;border-left:none;border-radius:0 5px 5px 0}._VYQz8J+._VYQz8J._P9Wi7G{border-color:#1f8eed}._VYQz8J:hover{background-color:#f6f8fa}._VYQz8J._P9Wi7G{background-color:#fff;border-color:#1f8eed;color:#1f8eed}@media screen and (max-width:1127px){._VYQz8J{font-size:18px;padding:16px 0}}._0N5wwk{display:-webkit-box;display:flex;margin-bottom:16px;width:100%}._0N5wwk label{color:#182029;font-size:18px;font-weight:300;margin-bottom:16px;width:100%}@media screen and (max-width:1127px){._0N5wwk{flex-wrap:wrap;margin-bottom:32px}}", ""]);
// Exports
exports.locals = {
	"button": "_VYQz8J",
	"button--active": "_P9Wi7G",
	"toggle-switch": "_0N5wwk"
};
module.exports = exports;
