// @flow
import _ from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';

import { deepFreeze } from '~web-core/lib/common/utils/object';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './Cell.scss';

const enums = deepFreeze({
  Formats: {
    Day: 'DAY',
    Month: 'MONTH',
    Year: 'YEAR',
  },
  Styles: {
    Active: 'ACTIVE',
    Disabled: 'DISABLED',
  },
});

type Props = {
  format: $Values<typeof enums.Formats>,
  isDisabled?: boolean,
  isHighlighted: boolean,
  label: string,
  onClick: (value: moment) => *,
  style: $Values<typeof enums.Styles>,
  value: moment,
};

class Cell extends Component<Props> {
  static enums = enums;

  static defaultProps = {
    isHighlighted: false,
  };

  handleClick = () => this.props.onClick(this.props.value);

  render() {
    return (
      <button
        type="button"
        onClick={this.handleClick}
        className={classNames({
          [styles.cell]: true,
          [styles['cell--highlighted']]: this.props.isHighlighted,
          [styles['cell--disabled']]: this.props.isDisabled,
          [styles[`cell--${_.kebabCase(this.props.format)}`]]: this.props.format,
          [styles[`cell--${_.kebabCase(this.props.style)}`]]: this.props.style,
        })}>
        {this.props.label}
      </button>
    );
  }
}

export default withStyles(styles)(Cell);
