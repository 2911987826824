import withMutation from '~tools/react/graphql/withMutation';
import { UpdateAccountInputType } from '~tools/types/graphqlSchema';

import UpdateAccountMutation from './updateAccount.gql';

interface Response {
  dateOfBirth: string | null;
  email: string;
  firstName: string;
  fullName: string;
  hasSignedFcraAgreement: boolean;
  isEmailVerified: boolean;
  isPhoneNumberVerified: boolean;
  lastName: string | null;
  phoneNumber: string | null;
  uuid: string;
}

interface Data {
  updateAccount: Response;
}

interface Variables {
  input: UpdateAccountInputType;
}

export interface UpdateAccountProps {
  updateAccount: (input: UpdateAccountInputType) => Promise<Response>;
}

export default withMutation<{}, Data, Variables, UpdateAccountProps>(UpdateAccountMutation, {
  props: props => ({
    updateAccount: async (input: UpdateAccountInputType) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error updating account.');

      return res.data.updateAccount;
    },
  }),
});
