import _ from 'lodash';
import React, { ComponentType, PureComponent } from 'react';
import dayjs from 'dayjs';

import { StripeExternalAccountTypes, TransactionStatuses } from '~tools/types/graphqlSchema';
import { PayoutStatuses } from '~tools/react/components/DashboardPayoutCard/enums';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';

import Alert from '~tools/react/components/Alert';
import Card from '~tools/react/components/Card';
import DashboardDataGrid from '~tools/react/components/DashboardDataGrid';
import DashboardPayoutCard from '~tools/react/components/DashboardPayoutCard';
import Heading from '~tools/react/components/Heading';
import Receipt from '~tools/react/components/Receipt';
import ThemedModal from '~tools/react/components/ThemedModal';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import { QueryProps, QueryResponse, QueryVariables } from './types';

import query from './PayoutModal.gql';

interface InputProps {
  rentPaymentUuid: string | null;
  isOpen: boolean;
  onClose: () => void;
}

type Props =
  InputProps &
  QueryProps;

class PayoutModal extends PureComponent<Props> {
  render() {
    const rentPayment = this.props.rentPayment;
    if (!rentPayment || this.props.isLoading) {
      return (
        <ThemedModal
          isLoading
          isOpen={this.props.isOpen}
          onClose={this.props.onClose}
          width={ThemedModal.enums.Widths.Medium}
        />
      );
    }

    const creditTransactions = _.filter(
      rentPayment.creditTransactions,
      t => (!t.reversedAt && t.status !== TransactionStatuses.FAILED && t.status !== TransactionStatuses.CANCELED),
    );
    const amountPaidOutInCents = _.reduce(creditTransactions, (acc, transaction) => acc + transaction.amountInCents, 0);

    // Not currently hooked up on the backend and will always be false, just FYI if debugging - Roger
    const isNextPayoutScheduled = rentPayment.nextScheduledTransferAt && rentPayment.nextScheduledTransferAmountInCents;
    const isRentPaymentDated = rentPayment.monthEnding && rentPayment.monthStarting;
    const primaryPayoutAccount = _.find(this.props.payoutAccounts, { defaultForCurrency: true });

    return (
      <ThemedModal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title="Payout details"
        width={ThemedModal.enums.Widths.Medium}>
        <ThemedModal.ThemedModalSection>
          <Heading content="Overview" font={Heading.enums.Fonts.Secondary} size={Heading.enums.Sizes.XXSmall} />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          <Receipt
            charges={[{
              label: 'Total payment',
              deltaAmountInCents: rentPayment.amountInCents,
            }, {
              label: 'Total paid out',
              deltaAmountInCents: -amountPaidOutInCents,
            }]}
            total={{
              label: 'Amount left in escrow',
              value: (rentPayment.amountInCents - amountPaidOutInCents) === 0 ? 'Fully paid out!' : undefined,
            }}
          />
        </ThemedModal.ThemedModalSection>
        <ThemedModal.ThemedModalSection>
          <Heading content="Payouts" font={Heading.enums.Fonts.Secondary} size={Heading.enums.Sizes.XXSmall} />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          {isNextPayoutScheduled ? (
            <DashboardPayoutCard
              amountInCents={rentPayment.nextScheduledTransferAmountInCents || 0}
              bankAccount={
                primaryPayoutAccount?.type === StripeExternalAccountTypes.BANK_ACCOUNT ?
                  primaryPayoutAccount.bankAccount :
                  undefined
              }
              card={
                primaryPayoutAccount?.type === StripeExternalAccountTypes.CARD ?
                  primaryPayoutAccount.card :
                  undefined
              }
              date={rentPayment.nextScheduledTransferAt || dayjs().add(1, 'day').toISOString()}
              isToggled
              status={DashboardPayoutCard.enums.PayoutStatuses.SCHEDULED}
              type={primaryPayoutAccount?.type}
            />
          ) : null}
          {_.map(creditTransactions, transaction => (
            <DashboardPayoutCard
              key={transaction.uuid}
              amountInCents={transaction.amountInCents}
              isToggled={creditTransactions.length === 1 ? true : undefined}
              date={transaction.payout?.processorPayout?.arrivalDate ?? transaction.createdAt}
              status={
                (transaction.payout?.status ?? DashboardPayoutCard.enums.PayoutStatuses.PENDING) as PayoutStatuses
              }
              type={
                transaction.payout?.processorPayout?.card ?
                  StripeExternalAccountTypes.CARD :
                  StripeExternalAccountTypes.BANK_ACCOUNT
              }
              card={transaction.payout?.processorPayout?.card ? {
                last4: transaction.payout.processorPayout.card.last4 ?? null,
                brand: transaction.payout.processorPayout.card.brand,
              } : undefined}
              bankAccount={transaction.payout?.processorPayout?.bankAccount ? {
                bankName: transaction.payout.processorPayout.bankAccount.bankName,
                routingNumber: transaction.payout.processorPayout.bankAccount.last4,
              } : undefined}
            />
          ))}
          {!isNextPayoutScheduled && this.props.rentPayment?.creditTransactions.length === 0 ? (
            <Card shadow={Card.enums.Shadows.Small}>
              <Alert
                color={Alert.enums.Colors.Gray}
                icon={Alert.enums.Icons.Clock}
                title="Payment pending"
                description="You'll see payout details here as soon as the payment transaction succeeds."
              />
            </Card>
          ) : null}
        </ThemedModal.ThemedModalSection>
        {isRentPaymentDated ? (
          <ThemedModal.ThemedModalSection>
            <Heading content="Details" font={Heading.enums.Fonts.Secondary} size={Heading.enums.Sizes.XXSmall} />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
            <DashboardDataGrid
              title="Dates paid for"
              items={[{
                label: 'Starting',
                description: rentPayment.monthStarting ? dayjs(rentPayment.monthStarting).format('MMMM D, YYYY') : 'Pending',
              }, {
                label: 'Ending',
                description: rentPayment.monthEnding ? dayjs(rentPayment.monthEnding).format('MMMM D, YYYY') : 'Pending',
              }]}
            />
          </ThemedModal.ThemedModalSection>
        ) : null}
      </ThemedModal>
    );
  }
}

export default compose(
  withQuery<InputProps, QueryResponse, QueryVariables, QueryProps>(query, {
    options: props => ({
      skip: !props.rentPaymentUuid,
      variables: {
        rentPaymentUuid: props.rentPaymentUuid,
      },
    }),
    props: props => ({
      isLoading: props.loading,
      rentPayment: props.data?.viewer?.rentPayment ?? null,
      payoutAccounts: props.data?.viewer?.payoutAccounts ?? [],
    }),
  }),
)(PayoutModal) as ComponentType<InputProps>;
