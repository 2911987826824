// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._ypriGw{display:-webkit-box;display:flex;flex-wrap:wrap;width:100%}", ""]);
// Exports
exports.locals = {
	"photos-view": "_ypriGw"
};
module.exports = exports;
