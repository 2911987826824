import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import Button from '~tools/react/components/Button';

import { Action } from '../../../../types';

interface Props {
  action: Action;
  label: string;
  onClick?: (action: Action) => void;
  link?: {
    path: string,
    shouldOpenNewTab: boolean,
  };
}

class ThreadItemActionAttachment extends PureComponent<Props> {
  private handleClick = () => {
    if (!this.props.onClick) return;
    this.props.onClick(this.props.action);
  }

  render() {
    if (this.props.link) {
      return (
        <Link to={this.props.link.path} target={this.props.link.shouldOpenNewTab ? '_blank' : undefined}>
          <Button
            align={Button.enums.Alignments.Left}
            label={this.props.label}
            size={Button.enums.Sizes.XSmall}
            type={Button.enums.Types.Button}
            width={Button.enums.Widths.Auto}
          />
        </Link>
      );
    }
    return (
      <Button
        align={Button.enums.Alignments.Left}
        label={this.props.label}
        size={Button.enums.Sizes.XSmall}
        type={Button.enums.Types.Button}
        onClick={this.handleClick}
        width={Button.enums.Widths.Auto}
      />
    );
  }
}

export default ThreadItemActionAttachment;
