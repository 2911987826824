import React, { Component, ComponentType, Fragment } from 'react';

import Alert from '~tools/react/components/Alert';
import ArrowLink from '~tools/react/components/ArrowLink';
import Button from '~tools/react/components/Button';
import Center from '~tools/react/components/Center';
import Spinner from '~tools/react/components/Spinner';
import TabHeader from '~tools/react/components/TabHeader';
import Text from '~tools/react/components/Text';
import ThemedModal from '~tools/react/components/ThemedModal';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import PayoutAccountSelector from '~tools/react/containers/PayoutAccountSelector';
import withUpdateBalance, { UpdateBalanceProps } from '~tools/react/graphql/mutations/balances/withUpdateBalance';
import withQuery from '~tools/react/graphql/withQuery';
import { compose } from '~tools/react/hocs/utils';
import { StripeExternalAccountTypes } from '~tools/types/graphqlSchema';
import { extractMessageFromError } from '~tools/utils/error';
import { formatTimestamp } from '~tools/utils/time';

import SecureFooter from './components/SecureFooter';

import query from './UpdateFundingAccountModal.gql';

interface InputProps {
  isOpen: boolean;
  onClose: () => void;
}

type Props = InputProps & QueryProps & UpdateBalanceProps;

interface State {
  activeSourceId?: string;
  errorMessage?: string;
  isLoading: boolean;
}

class UpdateFundingAccountModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  handleSelectSource = (activeSourceId: string) => this.setState({ activeSourceId });

  handleSubmit = async () => {
    const viewer = this.props.viewer;
    const balance = viewer?.balance;
    if (!viewer || !balance) return;

    this.setState({ isLoading: true });
    try {
      const activeSourceId = this.state.activeSourceId;
      if (!activeSourceId) {
        this.setState({ isLoading: false });
        return;
      }

      await this.props.updateBalance(balance.uuid, { defaultTopupSourceId: activeSourceId });
    } catch (err) {
      this.setState({
        isLoading: false,
        errorMessage: extractMessageFromError(err),
      });
      return;
    }

    this.setState({ isLoading: false });
    this.props.onClose();
  };

  render() {
    if (this.props.isLoading) {
      return (
        <ThemedModal
          isOpen={this.props.isOpen}
          onClose={this.props.onClose}
          title="Update funding account"
          width={ThemedModal.enums.Widths.Small}
        >
          <ThemedModal.ThemedModalSection>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
            <Center>
              <Spinner />
            </Center>
          </ThemedModal.ThemedModalSection>
        </ThemedModal>
      );
    }

    const balance = this.props.viewer?.balance;
    return (
      <ThemedModal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title="Update funding account"
        width={ThemedModal.enums.Widths.Small}
      >
        <ThemedModal.ThemedModalSection>
          <TabHeader
            title="Funding account"
            tabs={[
              {
                isActive: true,
                label: 'Bank',
                id: StripeExternalAccountTypes.BANK_ACCOUNT,
              },
            ]}
          />
          <PayoutAccountSelector
            activePayoutAccountId={this.state.activeSourceId}
            onSelectPayoutAccount={this.handleSelectSource}
          />
          {this.state.errorMessage ? (
            <Fragment>
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
              <Alert
                color={Alert.enums.Colors.Red}
                description={this.state.errorMessage}
              />
            </Fragment>
          ) : null}
        </ThemedModal.ThemedModalSection>
        <ThemedModal.ThemedModalSection>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
          <Text
            align={Text.enums.Align.Center}
            content={`This funding account won't be charged until at least the top-up date${
              balance ? `, ${formatTimestamp(balance.nextTopupDueAt, 'MMM DD, YYYY')}.` : '.'
            }`}
            size={Text.enums.Sizes.Medium}
          />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
          <Center>
            <ArrowLink
              color={ArrowLink.enums.Colors.Blue}
              label="More about Caretaker operating accounts"
              link={{
                path: 'https://caretaker.com/manual/getting-started/operating-accounts',
                shouldOpenNewTab: true,
              }}
              size={ArrowLink.enums.Sizes.Small}
            />
          </Center>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
        </ThemedModal.ThemedModalSection>
        <ThemedModal.ThemedModalSection>
          <Button
            isDisabled={!this.state.activeSourceId}
            isLoading={this.state.isLoading}
            label="Update funding account"
            onClick={this.handleSubmit}
            width={Button.enums.Widths.Full}
          />
          <SecureFooter />
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

interface Viewer {
  balance: {
    availableAmountInCents: number;
    createdAt: string;
    nextTopupDueAt: string;
    targetAmountInCents: number;
    updatedAt: string;
    uuid: string;
  } | null;
  uuid: string;
  email: string;
  fullName: string;
}

interface Response {
  viewer: Viewer | null;
}

interface QueryProps {
  isLoading: boolean;
  viewer: Viewer | null;
}

export default compose(
  withQuery<InputProps, Response, {}, QueryProps>(query, {
    options: {
      ssr: false,
    },
    props: (props) => ({
      isLoading: props.loading,
      viewer: props.data?.viewer ?? null,
    }),
  }),
  withUpdateBalance,
)(UpdateFundingAccountModal) as ComponentType<InputProps>;
