import { ApolloQueryResult } from 'apollo-client';
import _ from 'lodash';
import React, { PureComponent } from 'react';

import { QualificationTypes } from '~tools/types/graphqlSchema';
import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';

import {
  RentalPolicy,
  Requirement,
  VerificationMethod,
} from './types';

import query from './AccountQualificationManager.gql';
import QualificationManager from '../QualificationManager';

interface InputProps {
  type: QualificationTypes;
}

type Props =
  InputProps &
  QueryProps;

interface State {
  isAddingRequirementFormOpen: boolean;
  isVerificationMethodsModalOpen: boolean;
}

class AccountQualificationManager extends PureComponent<Props, State> {
  render() {
    return (
      <QualificationManager
        isLoading={this.props.isLoading}
        refetch={this.props.refetch}
        rentalPolicyUuid={this.props.rentalPolicy?.uuid ?? null}
        requirements={this.props.requirements}
        verificationMethods={this.props.verificationMethods}
        type={this.props.type}
      />
    );
  }
}

interface Response {
  verificationMethods: VerificationMethod[];
  viewer: {
    rentalPolicy: RentalPolicy | null;
    uuid: string;
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  refetch: () => Promise<ApolloQueryResult<Response>>;
  rentalPolicy: RentalPolicy | null;
  requirements: Requirement[];
  verificationMethods: VerificationMethod[];
}

export default compose(
  withQuery<InputProps, Response, {}, QueryProps>(query, {
    props: (props) => ({
      isLoading: props.loading,
      refetch: props.refetch,
      rentalPolicy: props.data?.viewer?.rentalPolicy || null,
      requirements: _.filter(
        props.data?.viewer?.rentalPolicy?.requirements,
        requirement => requirement.qualification.type === props.ownProps.type,
      ),
      verificationMethods: props.data?.verificationMethods || [],
    }),
  }),
)(AccountQualificationManager);
