import { useQuery } from 'react-apollo';

import { StripePaymentMethodTypes } from '~tools/types/graphqlSchema';

import query from './ViewerPaymentInformation.gql';

interface Card {
  id: string;
  createdAt: string;
  type: StripePaymentMethodTypes.CARD;
  card: {
    last4: string;
    brand: string;
    expMonth: number;
    expYear: number;
  };
}

interface UsBankAccount {
  id: string;
  createdAt: string;
  type: StripePaymentMethodTypes.US_BANK_ACCOUNT;
  usBankAccount: {
    accountType: string;
    bankName: string;
    last4: string;
  };
}

export type PaymentMethod = UsBankAccount | Card;

interface ViewerPaymentInformationQuery {
  viewer: {
    uuid: string;
    fullName: string;
    paymentMethods: PaymentMethod[];
    stripeCustomerAccount: {
      defaultPaymentMethod: string | null;
    } | null;
  } | null;
}

export default function useViewerPaymentInformation() {
  const { data, loading, error, refetch } = useQuery<ViewerPaymentInformationQuery>(query, {
    fetchPolicy: 'network-only',
  });

  return {
    loading,
    error,
    refetch,
    data,
  };
}
