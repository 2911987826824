// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._KpPnXM{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-pack:justify;justify-content:space-between}@media screen and (max-width:1127px){._KpPnXM{background-color:#fff;bottom:0;box-shadow:0 -1px 4px 0 transparent,0 -4px 24px 0 rgba(24,32,41,0);height:80px;left:0;padding:0 24px;position:fixed;-webkit-transform:translateY(80px);transform:translateY(80px);-webkit-transition:box-shadow,-webkit-transform .25s;transition:box-shadow,-webkit-transform .25s;transition:box-shadow,transform .25s;transition:box-shadow,transform .25s,-webkit-transform .25s;width:100%;z-index:2}._KpPnXM._zaNoKQ{box-shadow:0 -1px 4px 0 rgba(0,0,0,.04),0 -4px 24px 0 rgba(24,32,41,.12);-webkit-transform:translateY(0);transform:translateY(0)}}", ""]);
// Exports
exports.locals = {
	"tiers-footer-view": "_KpPnXM",
	"tiers-footer-view--open": "_zaNoKQ"
};
module.exports = exports;
