// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._ZJNp7p{border:0;color:#aab7c9;font-size:14px;font-weight:300;margin:0 8px;padding:0 0 16px}._ZJNp7p:first-child{margin-left:0}._ZJNp7p:last-child{margin-right:0}._ZJNp7p:not(._WVj0GD):hover{color:#273444}._ZJNp7p._WVj0GD{box-shadow:inset 0 -2px 0 0 #273444;color:#273444;font-weight:500}", ""]);
// Exports
exports.locals = {
	"tab": "_ZJNp7p",
	"tab--active": "_WVj0GD"
};
module.exports = exports;
