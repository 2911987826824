// MM-DD-YYYY
export const DATE = '(?:(?:0[1-9]|1[0-2])[\/\\-. ]?(?:0[1-9]|[12][0-9])|(?:(?:0[13-9]|1[0-2])[\/\\-. ]?30)|(?:(?:0[13578]|1[02])[\/\\-. ]?31))[\/\\-. ]?(?:19|20)[0-9]{2}';

// 12345
export const ZIP_CODE = /(\d{5}([\-]\d{4})?)/;

// 1234
export const SSN_LAST_4 = /[0-9]{4}/;

// 333-333-3333
export const PHONE_NUMBER = /\d{3}-\d{3}-\d{4}/;
