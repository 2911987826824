import React from 'react';
import _ from 'lodash';

import Card from '~tools/react/components/Card';
import Text from '~tools/react/components/Text';
import { ConditionReportTypes } from '~tools/types/graphqlSchema';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import Link from '~tools/react/components/utility/Link';
import withStyles from '~tools/react/hocs/withStyles';

import { ChevronRight } from '~web-core/lib/common/svgs/icons/directional';

import TimelineBadge from './components/TimelineBadge';
import { TimelineEntry, TimelineEntryTypes } from './types';
import styles from './MaintainTimeline.scss';

interface Props {
  cardTitle: string;
  propertyManagerContractUuid: string;
  timelineEntries: TimelineEntry[];
}

function MaintainTimeline(props: Props) {
  return (
    <Card header={props.cardTitle ? { title: props.cardTitle } : undefined}>
      <div className={styles.timeline}>
        {props.timelineEntries.map(timelineEntry => {
          const linkPath = timelineEntry.type === TimelineEntryTypes.REPORT ?
            `/issues/${timelineEntry.uuid}` :
            `/inspections/${timelineEntry.uuid}`;

          const header = timelineEntry.type === TimelineEntryTypes.REPORT ?
            `${_.capitalize(timelineEntry.reportType)} reported` :
            'Inspection submitted';

          return (
            <div className={styles['timeline-entry']} key={timelineEntry.uuid}>
              <div className={styles['timeline-entry--left']}>
                <Text content={timelineEntry.createdAt.format('MMM DD, YYYY')} />
                <Text
                  content={`at ${timelineEntry.createdAt.format('hh:mm a')}`}
                  size={Text.enums.Sizes.Small}
                  color={Text.enums.Colors.Secondary}
                />
              </div>
              <div className={styles['timeline-entry--center']}>
                {timelineEntry.type === TimelineEntryTypes.INSPECTION ? (
                  <TimelineBadge
                    icon={TimelineBadge.enums.Icons.Check}
                    color={TimelineBadge.enums.Colors.Blue}
                    size={TimelineBadge.enums.Sizes.Large}
                  />
                ) : null}
                {timelineEntry.reportType === ConditionReportTypes.DAMAGE ? (
                  <TimelineBadge
                    icon={TimelineBadge.enums.Icons.Cross}
                    color={TimelineBadge.enums.Colors.Red}
                    size={TimelineBadge.enums.Sizes.Large}
                  />
                ) : null}
                {timelineEntry.reportType === ConditionReportTypes.REPAIR ? (
                  <TimelineBadge
                    icon={TimelineBadge.enums.Icons.Check}
                    color={TimelineBadge.enums.Colors.Green}
                    size={TimelineBadge.enums.Sizes.Large}
                  />
                ) : null}
              </div>
              <div className={styles['timeline-entry--right']}>
                <Text
                  content={header}
                  isEmphasized={true}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                <Text content={timelineEntry.description} />
                <Link to={linkPath}>
                  <div className={styles['timeline-entry--right--link']}>
                    <p>View details</p>
                    <ChevronRight />
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
        {!props.timelineEntries.length ? (
          <Card.CardSection>
            <Text content="No maintenance activities to report. Complete your first inspection to start building your maintenance history." />
          </Card.CardSection>
        ) : null}
      </div>
    </Card>
  );
}

export default withStyles(styles)(MaintainTimeline);
