import classNames from 'classnames';
import _ from 'lodash';
import React, { PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import { Check, Cross } from '~web-core/lib/common/svgs/icons/interface';

import * as enums from './enums';

import styles from './TimelineBadge.scss';

export interface TimelineBadgeProps {
  color?: enums.Colors;
  icon: enums.Icons;
  size?: enums.Sizes;
  style?: enums.Styles;
}

const enumToIcon = {
  [enums.Icons.Check]: Check,
  [enums.Icons.Cross]: Cross,
};

class TimelineBadge extends PureComponent<TimelineBadgeProps> {
  static defaultProps = {
    style: enums.Styles.Secondary,
  }
  static enums = enums;

  render() {
    const Icon = this.props.icon ? enumToIcon[this.props.icon] : null;

    return (
      <div
        className={classNames({
          [styles['timeline-badge']]: true,
          [styles[`timeline-badge--color-${_.kebabCase(this.props.color)}`]]: this.props.color,
          [styles[`timeline-badge--size-${_.kebabCase(this.props.size)}`]]: this.props.size,
          [styles[`timeline-badge--style-${_.kebabCase(this.props.style)}`]]: this.props.style,
        })}>
        <Icon />
      </div>
    );
  }
}

export default withStyles(styles)(TimelineBadge);
