import withMutation from '~tools/react/graphql/withMutation';

import sendEmailVerificationEmail from './sendEmailVerificationEmail.gql';

interface Data {
  sendEmailVerificationEmail: boolean;
}

export interface SendEmailVerificationEmailProps {
  sendEmailVerificationEmail: () => Promise<boolean>;
}

export default withMutation<{}, Data, {}, SendEmailVerificationEmailProps>(sendEmailVerificationEmail, {
  props: props => ({
    sendEmailVerificationEmail: async () => {
      const res = await props.mutate();
      if (!res || !res.data) throw new Error('Error sending email verification email.');

      return res.data.sendEmailVerificationEmail;
    },
  }),
});
