// @flow
/* eslint-disable import/prefer-default-export */

export const deepFreeze = <T>(o: T): T => { // eslint-disable-line arrow-parens
  if (typeof o === 'object' && o !== null) {
    const propNames = Object.getOwnPropertyNames(o);

    // Using native map for the typings. Lodash map would return enum types as a string vs a string literal.
    // propNames is string[] so its a non-issue.
    propNames.map((name) => {
      const prop = o[name];

      if (typeof prop === 'object' && prop !== null) deepFreeze(prop);
      return null;
    });

    return Object.freeze(o);
  }

  return Object.freeze(o);
};
