// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._orGUEG{display:-webkit-box;display:flex;height:1px;position:relative}._orGUEG ._prWCYN{background-color:#fff;bottom:-9px;display:-webkit-box;display:flex;-webkit-box-pack:center;justify-content:center;left:50%;position:absolute;-webkit-transform:translateX(-50%);transform:translateX(-50%);width:50px}", ""]);
// Exports
exports.locals = {
	"and-horizontal-rule": "_orGUEG",
	"and-horizontal-rule__text": "_prWCYN"
};
module.exports = exports;
