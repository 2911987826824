import bufferFrom from 'buffer-from';

const CURSOR_PREFIX = 'arrayconnection:';

export function offsetToCursor(offset: number) {
  return bufferFrom(`${CURSOR_PREFIX}${offset - 1}`, 'utf8').toString('base64');
}

export function cursorToOffset(cursor: string) {
  return parseInt(bufferFrom(`${cursor}`, 'base64').toString('utf8').replace(CURSOR_PREFIX, ''), 10);
}

export function pageToCursor(page: number, pageLength: number) {
  return offsetToCursor((page - 1) * pageLength);
}
