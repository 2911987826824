// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._P9WOxv{position:relative}._P9WOxv ._80Aezp{-webkit-box-align:center;align-items:center;border:none;bottom:0;color:#8492a6;display:-webkit-box;display:flex;height:38px;position:absolute;right:0}._P9WOxv ._80Aezp:hover{color:#3c4858}", ""]);
// Exports
exports.locals = {
	"clearable-typeahead": "_P9WOxv",
	"clearable-typeahead__clear": "_80Aezp"
};
module.exports = exports;
