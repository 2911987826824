import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './Spinner.scss';

interface Props {
  className?: string;
  height?: number;
  styleName?: string;
  width?: number;
}

function Spinner(props: Props) {
  return (
    <svg
      className={props.className}
      fill="none"
      height={props.height ?? 24}
      stroke="currentColor"
      strokeDashoffset="0"
      strokeLinecap="square"
      strokeMiterlimit="5"
      strokeWidth="1.5"
      styleName="spinner"
      viewBox="0 0 24 24"
      width={props.width ?? 24}
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="12"
        cy="12"
        opacity="0.15"
        r="9"
        strokeDasharray="200, 200"
      />
      <circle
        cx="12"
        cy="12"
        opacity="1"
        r="9"
        strokeDasharray="15, 200"
      />
    </svg>
  );
}

export default withStyles(styles)(Spinner);
