import _ from 'lodash';
import queryString from 'query-string';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { compose } from '~tools/react/hocs/utils';
import withPurchaseListingBoost, { PurchaseListingBoostProps } from '~tools/react/graphql/mutations/listingBoosts/withPurchaseListingBoost';

import analytics from '~web-core/lib/common/utils/analytics';
import {
  PURCHASED_BOOST,
  VIEWED_BOOST_MODAL,
  VIEWED_BOOST_PAYMENT_FORM,
} from '~web-core/lib/_common/constants/analytics';

import { formatAsUSD } from '~tools/utils/string';

import Modal from '~tools/react/components/Modal';
import AnimatedResizer from '~tools/react/components/AnimatedResizer';

import withListingBoostOffers, { WithListingBoostOffersProps } from './graphql/withListingBoostOffers';

import BoostAmountView from './components/BoostAmountView';
import BoostPaymentForm from './components/BoostPaymentForm';
import BoostModalHeader from './components/BoostModalHeader';
import BoostModalView from './components/BoostModalView';
import BoostSuccessView from './components/BoostSuccessView';

import {
  BOOST_BUY_BUTTON_LABEL,
  BOOST_CAMPAIGN_TYPES,
  BOOST_HEADLINE_CTA,
  BOOST_SUBHEADLINE_CTA,
  DEFAULT_BOOST_AMOUNT,
  DEFAULT_CAMPAIGN_TYPE,
  DEFAULT_DAILY_BOOST_AMOUNT_IN_DOLLARS,
  DEFAULT_LIFETIME_BOOST_AMOUNT_IN_DOLLARS,
  MINIMUM_LIFETIME_BOOST_AMOUNT_ALLOWED_IN_DOLLARS,
  MINIMUM_RECURRING_BOOST_AMOUNT_ALLOWED_IN_DOLLARS,
} from './settings';
import { Offer } from './types';
import { getOfferValueAndCost } from './utils';

interface State {
  amountInDollars: number;
  campaignType: BOOST_CAMPAIGN_TYPES;
  isBoostProcessing: boolean;
  selectedOffer?: Offer;
  showBoostCheckoutView: boolean;
  showPaymentSuccess: boolean;
}

interface InputProps {
  insightsPath?: string;
  isOpen: boolean;
  listingUuid: string;
  offers: Offer[];
  onBoostSuccess?: () => void;
  onClose: () => void;
}

type Props =
  RouteComponentProps &
  InputProps &
  PurchaseListingBoostProps &
  WithListingBoostOffersProps;

class PurchaseBoostModal extends Component<Props, State> {
  static defaultProps = {
    offers: [],
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      amountInDollars: DEFAULT_BOOST_AMOUNT,
      campaignType: DEFAULT_CAMPAIGN_TYPE,
      isBoostProcessing: false,
      showBoostCheckoutView: false,
      showPaymentSuccess: false,
    };
  }

  componentDidMount() {
    if (this.props.isOpen) {
      // This only gets hit if the user views the modal via query param
      // i.e extras?showBoost=true
      this.handleTrackViewedModal(true);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.handleTrackViewedModal(false);
    }

    const queryParams = queryString.parse(this.props.history.location.search);
    const showBoostOffer = (queryParams && queryParams.showBoostOffer);

    const hasValidOffer = (!this.props.offers.length && nextProps.offers.length) ||
      (this.props.offers.length && _.isNull(this.state.selectedOffer));
    if (showBoostOffer && hasValidOffer) {
      this.setState({
        selectedOffer: _.head(nextProps.offers),
      });
    }

    if (this.props.isOpen && !nextProps.isOpen) {
      this.setState({
        showPaymentSuccess: false,
        showBoostCheckoutView: false,
      });
    }
  }

  handleTrackViewedModal = (openedWithParam: boolean) => {
    const queryParams = queryString.parse(this.props.history.location.search);
    const hasOffer = (queryParams && queryParams.showBoostOffer);

    analytics.track(VIEWED_BOOST_MODAL, {
      minimum_recurring_amount: MINIMUM_RECURRING_BOOST_AMOUNT_ALLOWED_IN_DOLLARS,
      minimum_lifetime_amount: MINIMUM_LIFETIME_BOOST_AMOUNT_ALLOWED_IN_DOLLARS,
      boost_headline_cta: BOOST_HEADLINE_CTA,
      boost_subheadline_cta: BOOST_SUBHEADLINE_CTA,
      daily_default_amount: DEFAULT_DAILY_BOOST_AMOUNT_IN_DOLLARS,
      lifetime_default_amount: DEFAULT_LIFETIME_BOOST_AMOUNT_IN_DOLLARS,
      boost_buy_button_label: BOOST_BUY_BUTTON_LABEL,
      default_campaign_type: DEFAULT_CAMPAIGN_TYPE,
      default_amount_in_dollars: this.state.amountInDollars,
      opened_via_param: openedWithParam,
      has_offer: hasOffer,
    });
  }

  handleChangeAmount = (amountInDollars: string) => this.setState({ amountInDollars: parseInt(amountInDollars, 10) });

  handleBoostPayment = async (source: string) => {
    if (this.state.isBoostProcessing) return;

    this.setState({ isBoostProcessing: true });
    const amountInCents = this.state.amountInDollars * 100;
    const listingUuid = this.props.listingUuid;
    const isDailyBudgetAdset = this.state.campaignType === BOOST_CAMPAIGN_TYPES.RECURRING;
    const offerUuid = this.state.selectedOffer ? this.state.selectedOffer.uuid : null;

    try {
      await this.props.purchaseListingBoost({
        amountInCents,
        isDailyBudgetAdset,
        listingUuid,
        offerUuid,
        source,
      });

      this.setState({
        isBoostProcessing: false,
        showBoostCheckoutView: false,
        showPaymentSuccess: true,
      });

      analytics.track(PURCHASED_BOOST, {
        amount: this.state.amountInDollars,
        minimum_recurring_amount: MINIMUM_RECURRING_BOOST_AMOUNT_ALLOWED_IN_DOLLARS,
        minimum_lifetime_amount: MINIMUM_LIFETIME_BOOST_AMOUNT_ALLOWED_IN_DOLLARS,
        boost_headline_cta: BOOST_HEADLINE_CTA,
        boost_subheadline_cta: BOOST_SUBHEADLINE_CTA,
        boost_buy_button_label: BOOST_BUY_BUTTON_LABEL,
        campaign_type: this.state.campaignType,
        has_offer: !!offerUuid,
      });

      if (this.props.onBoostSuccess) this.props.onBoostSuccess();
    } catch (err) {
      this.setState({ isBoostProcessing: false });
      throw err;
    }
  }

  handleChangeCampaignType = (campaignType?: string | number) => {
    switch (campaignType) {
      case 'RECURRING': {
        this.setState({
          amountInDollars: DEFAULT_DAILY_BOOST_AMOUNT_IN_DOLLARS,
          campaignType: BOOST_CAMPAIGN_TYPES.RECURRING,
        });
        break;
      }
      case 'ONE_TIME': {
        this.setState({
          amountInDollars: DEFAULT_LIFETIME_BOOST_AMOUNT_IN_DOLLARS,
          campaignType: BOOST_CAMPAIGN_TYPES.LIFETIME,
        });
        break;
      }
    }
  }

  handleCheckoutView = () => {
    this.setState({
      showBoostCheckoutView: true,
    }, () => {
      analytics.track(VIEWED_BOOST_PAYMENT_FORM, {
        amount: this.state.amountInDollars,
        campaign_type: this.state.campaignType,
      });
    });
  }
  handleNavigateBack = () => this.setState({ showBoostCheckoutView: false });

  render() {
    const isRecurring = this.state.campaignType === BOOST_CAMPAIGN_TYPES.RECURRING;
    const amountInCents = this.state.amountInDollars ? this.state.amountInDollars * 100 : 0;
    const { costInDollars, valueInDollars } = getOfferValueAndCost(this.state.selectedOffer, amountInCents);
    const buttonLabel = `${BOOST_BUY_BUTTON_LABEL} for $${formatAsUSD(costInDollars, true)}${isRecurring ? '/day' : ''}`;

    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}>
        <BoostModalView isCheckout={this.state.showBoostCheckoutView}>
          {!this.state.showPaymentSuccess &&
            <BoostModalHeader
              headline={BOOST_HEADLINE_CTA}
              subheadline={BOOST_SUBHEADLINE_CTA}
              onClose={this.props.onClose}
            />
          }
          <AnimatedResizer>
            <AnimatedResizer.AnimatedResizerSection
              isShown={!this.state.showPaymentSuccess && !this.state.showBoostCheckoutView}>
              <BoostAmountView
                amountInDollars={this.state.amountInDollars}
                isRecurring={isRecurring}
                offer={this.state.selectedOffer}
                onChangeAmount={this.handleChangeAmount}
                onCheckoutView={this.handleCheckoutView}
                onChangeCampaignType={this.handleChangeCampaignType}
              />
            </AnimatedResizer.AnimatedResizerSection>
            <AnimatedResizer.AnimatedResizerSection
              isShown={this.state.showBoostCheckoutView}>
              <BoostPaymentForm
                charges={this.state.selectedOffer ? [
                  {
                    deltaAmountInCents: _.round(valueInDollars, 2) * 100,
                    label: 'Boost',
                    labelMeta: isRecurring ? 'Daily' : 'One time',
                  },
                  {
                    deltaAmountInCents: _.round(costInDollars - valueInDollars, 2) * 100,
                    label: 'Discount',
                  },
                ] : [
                  {
                    deltaAmountInCents: _.round(valueInDollars, 2) * 100,
                    label: 'Boost',
                    labelMeta: isRecurring ? 'Daily' : 'One time',
                  },
                ]}
                buttonLabel={buttonLabel}
                isLoading={this.state.isBoostProcessing}
                onNavigateBack={this.handleNavigateBack}
                onSubmit={this.handleBoostPayment}
              />
            </AnimatedResizer.AnimatedResizerSection>
            <AnimatedResizer.AnimatedResizerSection
              isShown={this.state.showPaymentSuccess}>
              <BoostSuccessView
                onClose={this.props.onClose}
                insightsPath={this.props.insightsPath}
              />
            </AnimatedResizer.AnimatedResizerSection>
          </AnimatedResizer>
        </BoostModalView>
      </Modal>
    );
  }
}

export default compose(
  withListingBoostOffers,
  withPurchaseListingBoost,
  withRouter,
)(PurchaseBoostModal);
