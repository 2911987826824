import React, { ComponentType, PureComponent } from 'react';

import withRequestApplication, { RequestApplicationProps } from '~tools/react/graphql/mutations/applications/withRequestApplication';
import withQuery from '~tools/react/graphql/withQuery';
import { compose } from '~tools/react/hocs/utils';

import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import GenericLoadingVisual from '~tools/react/components/GenericLoadingVisual';

import query from './InterestedStep.gql';
import { QueryProps, QueryResponse, QueryVariables } from './types';

interface InputProps {
  applicationUuid: string;
}

type Props = QueryProps &
             InputProps &
             RequestApplicationProps;

interface State {
  isRequestingApplication: boolean;
}

class InterestedStep extends PureComponent<Props, State> {
  state: State = {
    isRequestingApplication: false,
  };

  handleClickRequestApplication = async () => {
    this.setState({ isRequestingApplication: true });
    await this.props.requestApplication(this.props.applicationUuid);
    this.setState({ isRequestingApplication: false });
  }

  render() {
    const application = this.props.application;
    const applicant = application?.applicants[0];
    if (!application || !applicant || this.props.isLoading) {
      return (
        <Card.CardSection title="Lease details">
          <GenericLoadingVisual />
        </Card.CardSection>
      );
    }

    return (
      <Card.CardSection>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            isDisabled={!!application.applicationRequestedAt}
            isLoading={this.state.isRequestingApplication}
            label={application.applicationRequestedAt ?
              `You have requested an application` :
              `Request an application from ${applicant.firstName}`
            }
            onClick={this.handleClickRequestApplication}
            size={Button.enums.Sizes.Small}
            style={Button.enums.Styles.Secondary}
          />
        </div>
      </Card.CardSection>
    );
  }
}

export default compose(
  withQuery<InputProps, QueryResponse, QueryVariables, QueryProps>(query, {
    options: (props) => ({
      variables: { applicationUuid: props.applicationUuid },
    }),
    props: (props) => ({
      application: props.data?.viewer?.application ?? null,
      isLoading: props.loading,
      refetch: props.refetch,
    }),
  }),
  withRequestApplication,
)(InterestedStep) as ComponentType<InputProps>;
