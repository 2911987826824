import withMutation from '~tools/react/graphql/withMutation';
import { AddPropertyManagerContractToServicePreferenceInput } from '~tools/types/graphqlSchema';

import AddPropertyManagerContractToServicePreferenceMutation from './addPropertyManagerContractToServicePreference.gql';

interface ServicePreference {
  uuid: string;
}

interface Response {
  addPropertyManagerContractToServicePreference: ServicePreference;
}

interface Variables {
  input: AddPropertyManagerContractToServicePreferenceInput;
  uuid: string;
}

export interface AddPropertyManagerContractToServicePreferenceProps {
  addPropertyManagerContractToServicePreference: (uuid: string, input: AddPropertyManagerContractToServicePreferenceInput) => Promise<ServicePreference>;
}

export default withMutation<{}, Response, Variables, AddPropertyManagerContractToServicePreferenceProps>(AddPropertyManagerContractToServicePreferenceMutation, {
  props: props => ({
    addPropertyManagerContractToServicePreference: async (uuid: string, input: AddPropertyManagerContractToServicePreferenceInput) => {
      const res = await props.mutate({
        variables: {
          input,
          uuid,
        },
      });

      if (!res || !res.data) throw new Error('Error updating service preference');
      if (!res.data.addPropertyManagerContractToServicePreference) throw new Error('No addPropertyManagerContractToServicePreference returned');

      return res.data.addPropertyManagerContractToServicePreference;
    },
  }),
});
