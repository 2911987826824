// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1qAeQ3{-webkit-box-align:start;align-items:flex-start;background:#000;display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;height:100vh;padding:40px 40px 0;position:relative;width:100vw}@media screen and (max-width:1127px){._1qAeQ3{padding:40px 16px 0}}@media screen and (max-width:500px){._1qAeQ3{padding:56px 16px 0}}._1qAeQ3 ._0N5mnr{width:100%}._1qAeQ3 ._0N5mnr>div:last-child{background:#000;border-top:1px solid hsla(0,0%,100%,.25)}@media screen and (max-width:1127px){._1qAeQ3 ._0N5mnr button:first-child{display:none}._1qAeQ3 ._0N5mnr button{width:100%}}", ""]);
// Exports
exports.locals = {
	"building-modal": "_1qAeQ3",
	"building-modal__nav": "_0N5mnr"
};
module.exports = exports;
