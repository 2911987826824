// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._5aAkBq{display:-webkit-box;display:flex;-webkit-box-pack:justify;justify-content:space-between}._5aAkBq ._GN6bPQ{display:-webkit-box;display:flex;margin-right:16px}._5aAkBq ._GN6bPQ ._jrVP57{border:1px solid #e6e8eb;border-radius:5px;height:80px;-o-object-fit:cover;object-fit:cover;width:80px}", ""]);
// Exports
exports.locals = {
	"report-row": "_5aAkBq",
	"report-row__left": "_GN6bPQ",
	"report-row__left__image": "_jrVP57"
};
module.exports = exports;
