import React from 'react';

import Button from '~tools/react/components/Button';
import withStyles from '~tools/react/hocs/withStyles';

import styles from './PhotoEditorModalConfirmation.scss';

interface Props {
  primaryAction: () => void;
  secondaryAction: () => void;
  title: string;
}

function PhotoEditorModalConfirmation(props: Props) {
  return (
    <div styleName="confirmation-modal">
      <p>{props.title}</p>
      <div>
        <Button
          color={Button.enums.Colors.White}
          onClick={props.secondaryAction}
          style={Button.enums.Styles.Outline}
          label="Cancel"
        />
        <Button color={Button.enums.Colors.Blue} onClick={props.primaryAction} label="Confirm" />
      </div>
    </div>
  );
}

export default withStyles(styles)(PhotoEditorModalConfirmation);
