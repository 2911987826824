import { ApolloQueryResult } from 'apollo-client';
import _ from 'lodash';
// import moment from 'moment-timezone';
import React, { ComponentType, PureComponent } from 'react';

import Button from '~tools/react/components/Button';
import CheckableList from '~tools/react/components/CheckableList';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import ThemedModal from '~tools/react/components/ThemedModal';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import withCancelPaymentSubscription, {
  CancelPaymentSubscriptionProps,
} from '~tools/react/graphql/mutations/paymentSubscriptions/withCancelPaymentSubscription';
import withQuery from '~tools/react/graphql/withQuery';
import { compose } from '~tools/react/hocs/utils';
import withPaymentSubscription, { PaymentSubscriptionProps } from '~tools/react/hocs/withPaymentSubscription';
import { PaymentSubscriptionPlans, ShipmentStatuses, ShipmentTypes } from '~tools/types/graphqlSchema';

import ChecklistView from './components/ChecklistView';

import query from './CancelPlanModal.gql';

interface InputProps {
  isOpen: boolean;
  onCancelPlan: () => Promise<void>;
  onClose: () => void;
}

type Props = InputProps & QueryProps & PaymentSubscriptionProps & CancelPaymentSubscriptionProps;

interface State {
  isLoading: boolean;
  isOneChecked: boolean;
  isTwoChecked: boolean;
  isThreeChecked: boolean;
  isFourChecked: boolean;
}

class CancelPlanModal extends PureComponent<Props, State> {
  state: State = {
    isLoading: false,
    isOneChecked: false,
    isTwoChecked: false,
    isThreeChecked: false,
    isFourChecked: false,
  };

  handleToggleCheckOne = () => this.setState({ isOneChecked: !this.state.isOneChecked });
  handleToggleCheckTwo = () => this.setState({ isTwoChecked: !this.state.isTwoChecked });
  handleToggleCheckThree = () => this.setState({ isThreeChecked: !this.state.isThreeChecked });
  handleToggleCheckFour = () => this.setState({ isFourChecked: !this.state.isFourChecked });

  handleCancelPlan = async () => {
    const viewer = this.props.viewer;
    const activePaymentSubscription = viewer?.activePaymentSubscription;
    if (!activePaymentSubscription) return;

    this.setState({ isLoading: true });
    await this.props.cancelPaymentSubscription(activePaymentSubscription.uuid);
    await this.props.refetchPaymentSubscription();
    await this.props.refetch();
    await this.props.onCancelPlan();
    this.setState({ isLoading: false });

    this.props.onClose();
  };

  render() {
    const viewer = this.props.viewer;
    // const dueDate = moment().add(30, 'days').endOf('day').format('MMMM Do, YYYY');

    let hasSmartLocks = false;
    _.map(viewer?.orders, (order) => {
      const shipment = _.find(order.shipments, (s) => s.type === ShipmentTypes.SHIPMENT);
      const returnShipment = _.find(order.shipments, (s) => s.type === ShipmentTypes.RETURN);
      if (shipment?.status === ShipmentStatuses.DELIVERED && returnShipment?.status !== ShipmentStatuses.DELIVERED) {
        hasSmartLocks = true;
      }
    });

    const numUnits = viewer?.propertyManagerContractsTotal || 0;
    const hasMultipleUnits = numUnits > 1;
    return (
      <ThemedModal
        isOpen={this.props.isOpen}
        title="Cancel plan"
        onClose={this.props.onClose}
      >
        <ThemedModal.ThemedModalSection>
          <ChecklistView>
            <Text
              color={Text.enums.Colors.Primary}
              content="Sorry to see you go!"
              size={Text.enums.Sizes.Medium}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
            <Text
              color={Text.enums.Colors.Primary}
              content="We want to make sure nothing catches you off guard in the downgrade process, so make sure you understand the items on the list below. Just check each bubble to acknowledge you understand it and you'll be able to downgrade your account."
              size={Text.enums.Sizes.Medium}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
            <CheckableList>
              {hasMultipleUnits ? (
                <CheckableList.CheckableListItem
                  onToggle={this.handleToggleCheckThree}
                  isChecked={this.state.isThreeChecked}
                  title={`*${numUnits - 1} of my ${numUnits}* active units will be *frozen and disabled*.`}
                />
              ) : null}
              <CheckableList.CheckableListItem
                onToggle={this.handleToggleCheckFour}
                isChecked={this.state.isFourChecked}
                title="All rent and security deposit payments owed to me will continue to be paid out in accordance with the related lease agreements."
              />
            </CheckableList>
          </ChecklistView>
        </ThemedModal.ThemedModalSection>
        <ThemedModal.ThemedModalFooter
          alignment={ThemedModal.ThemedModalFooter.enums.Alignments.Right}
          isStickyOnMobile
        >
          <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
            <Button
              color={Button.enums.Colors.Gray}
              isDisabled={this.state.isLoading}
              label="Nevermind"
              onClick={this.props.onClose}
              style={Button.enums.Styles.Outline}
            />
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
            <Button
              color={Button.enums.Colors.Red}
              isDisabled={
                (!this.state.isOneChecked && hasSmartLocks) ||
                (!this.state.isTwoChecked && hasSmartLocks) ||
                (!this.state.isThreeChecked && hasMultipleUnits) ||
                !this.state.isFourChecked
              }
              isLoading={this.state.isLoading}
              label="Cancel my account"
              onClick={this.handleCancelPlan}
            />
          </Row>
        </ThemedModal.ThemedModalFooter>
      </ThemedModal>
    );
  }
}

interface Order {
  createdAt: string;
  orderItems: {
    inventoryItems: {
      smartLock: {
        isSetup: boolean;
        uuid: string;
      };
      uuid: string;
    }[];
    uuid: string;
  }[];
  shippingAddressUnit: {
    address: {
      streetAddress1: string;
      uuid: string;
    };
    name: string | null;
    uuid: string;
  };
  shipments: {
    carrier: string;
    deliveredAt: string | null;
    method: string | null;
    shippedAt: string | null;
    status: string;
    type: ShipmentTypes;
    uuid: string;
  }[];
  uuid: string;
}

interface Viewer {
  activePaymentSubscription: {
    plan: PaymentSubscriptionPlans;
    uuid: string;
  } | null;
  orders: Order[];
  propertyManagerContracts: {
    uuid: string;
  }[];
  propertyManagerContractsTotal: number;
  uuid: string;
}

interface Response {
  viewer: Viewer | null;
}

interface QueryProps extends Response {
  isLoading: boolean;
  refetch: () => Promise<ApolloQueryResult<Response>>;
}

export default compose(
  withPaymentSubscription,
  withCancelPaymentSubscription,
  withQuery<{}, Response, {}, QueryProps>(query, {
    options: () => ({
      fetchPolicy: 'no-cache',
    }),
    props: (props) => ({
      isLoading: props.loading,
      refetch: props.refetch,
      viewer: props.data?.viewer || null,
    }),
  }),
)(CancelPlanModal) as ComponentType<InputProps>;
