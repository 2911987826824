import { ApolloQueryResult } from 'apollo-client';
import _ from 'lodash';
import React, { ComponentType, PureComponent } from 'react';

import { compose } from '~tools/react/hocs/utils';
import withDevice, { DeviceProps } from '~tools/react/hocs/withDevice';

import withQuery from '~tools/react/graphql/withQuery';
import withCreatePhoto, { CreatePhotoProps, PhotoEntityTypes } from '~tools/react/graphql/mutations/photos/withCreatePhoto';
import withDeletePhoto, { DeletePhotoProps } from '~tools/react/graphql/mutations/photos/withDeletePhoto';
import withCreateAccessInstruction, { AccessInstructionAccessorEntityTypes, AccessInstructionEntityTypes, CreateAccessInstructionProps } from '~tools/react/graphql/mutations/accessInstructions/withCreateAccessInstruction';
import withUpdateAccessInstruction, { UpdateAccessInstructionProps } from '~tools/react/graphql/mutations/accessInstructions/withUpdateAccessInstruction';
import withGenerateSmartLockOneTimeCode, { GenerateSmartLockOneTimeCodeProps } from '~tools/react/graphql/mutations/smartLocks/withGenerateSmartLockOneTimeCode';

import AnimatedResizer from '~tools/react/components/AnimatedResizer';
import Button from '~tools/react/components/Button';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import ThemedModal from '~tools/react/components/ThemedModal';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import AccessForm, { UploadedPhoto } from '../../components/AccessForm';
import MaskedSmartLockCode from '../../components/MaskedSmartLockCode';
import ResponsiveStepButtons from '../../components/ResponsiveStepButtons';
import SmartLockSetupStep from './components/SmartLockSetupStep';

import query from './SmartLockSetupModal.gql';
import { PropertyManagerContract } from './types';

enum Steps {
  INSTALL,
  TEST_UNLOCK,
  TAKE_PHOTO,
}

interface State {
  currentStep: Steps;
  isGeneratingAccessCode: boolean;
  smartLockAccessCode: string | null;
}

interface InputProps {
  isOpen: boolean;
  propertyManagerContractUuid: string;
  onClose: () => void;
  onSmartLockSetupComplete?: () => void;
}

type Props =
  InputProps &
  QueryProps &
  DeviceProps &
  CreatePhotoProps &
  DeletePhotoProps &
  CreateAccessInstructionProps &
  UpdateAccessInstructionProps &
  GenerateSmartLockOneTimeCodeProps;

class SmartLockSetupModal extends PureComponent<Props> {
  state: State = {
    currentStep: Steps.INSTALL,
    isGeneratingAccessCode: false,
    smartLockAccessCode: null,
  };

  handleSubmitAccessForm = async (photos: UploadedPhoto[], smartLockDescription: string) => {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return;

    const addressUnit = propertyManagerContract.addressUnit;

    let accessInstruction;
    if (addressUnit.accessInstruction) {
      accessInstruction = await this.props.updateAccessInstruction(addressUnit.accessInstruction.uuid, {
        accessorEntityType: addressUnit.smartLock ? AccessInstructionAccessorEntityTypes.SMART_LOCK : undefined,
        accessorEntityUuid: addressUnit.smartLock?.uuid,
        notes: smartLockDescription,
      });
    } else {
      let trimmedSmartLockDescription = smartLockDescription.trim();
      if (trimmedSmartLockDescription.substr(-1) !== '.') {
        trimmedSmartLockDescription = `${trimmedSmartLockDescription}.`;
      }

      accessInstruction = await this.props.createAccessInstruction({
        accessorEntityType: addressUnit.smartLock ? AccessInstructionAccessorEntityTypes.SMART_LOCK : undefined,
        accessorEntityUuid: addressUnit.smartLock?.uuid,
        entityType: AccessInstructionEntityTypes.LISTING,
        entityUuid: propertyManagerContract.listing.uuid,
        notes: trimmedSmartLockDescription,
      });
    }

    await Promise.all(_.map(photos, p => (
      this.props.createPhoto({
        entityType: PhotoEntityTypes.ACCESS_INSTRUCTION,
        entityUuid: accessInstruction.uuid,
        key: p.key,
      })
    )));

    if (this.props.onSmartLockSetupComplete) this.props.onSmartLockSetupComplete();
    this.props.onClose();
  }

  handleDeletePhoto = async (uuid: string) => {
    await this.props.deletePhoto(uuid);
    await this.props.refetch();
  }

  handleGenerateAccessCode = async () => {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return;

    const addressUnit = propertyManagerContract.addressUnit;
    if (!addressUnit.smartLock) return;
    this.setState({ isGeneratingAccessCode: true });
    const accessCode = await this.props.generateSmartLockOneTimeCode(addressUnit.smartLock.uuid);
    this.setState({ smartLockAccessCode: accessCode, isGeneratingAccessCode: false });
  }

  handleAdvanceToNextStep = () => this.setState({ currentStep: this.state.currentStep + 1 });
  handleGoBack = () => this.setState({ currentStep: this.state.currentStep - 1 });

  render() {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return null;

    return (
      <ThemedModal
        bodyBackgroundColor={ThemedModal.enums.BodyBackgroundColors.White}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title="Setup your smart lock"
        width={ThemedModal.enums.Widths.Medium}>
        <ThemedModal.ThemedModalSection>
          <AnimatedResizer width={AnimatedResizer.enums.Widths.Full}>
            <AnimatedResizer.AnimatedResizerSection
              isLeft={this.state.currentStep > Steps.INSTALL}
              isShown={this.state.currentStep === Steps.INSTALL}>
              <SmartLockSetupStep
                description="Follow the instructions that came with your deadbolt to install it into your door."
                illustration={SmartLockSetupStep.enums.Illustrations.UnlockSmartLock}
                isMobile={this.props.isMobile}
                isShown={this.state.currentStep === Steps.TEST_UNLOCK}
                stepNumber={1}
                title="Install your smart lock">
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <HorizontalRule />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
                <Button
                  label="Continue"
                  onClick={this.handleAdvanceToNextStep}
                  width={this.props.isMobile ? Button.enums.Widths.Full : Button.enums.Widths.Auto}
                />
              </SmartLockSetupStep>
            </AnimatedResizer.AnimatedResizerSection>

            <AnimatedResizer.AnimatedResizerSection
              isLeft={this.state.currentStep > Steps.TEST_UNLOCK}
              isRight={this.state.currentStep < Steps.TEST_UNLOCK}
              isShown={this.state.currentStep === Steps.TEST_UNLOCK}>
              <SmartLockSetupStep
                description="Enter the access code provided below to test that your new lock is fully integrated with Caretaker."
                illustration={SmartLockSetupStep.enums.Illustrations.UnlockSmartLock}
                isMobile={this.props.isMobile}
                isShown={this.state.currentStep === Steps.TEST_UNLOCK}
                stepNumber={2}
                title="Test unlocking your smart lock">
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
                <HorizontalRule />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <Text
                  color={Text.enums.Colors.Secondary}
                  content="This code will be valid for one use."
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                  <MaskedSmartLockCode code={this.state.smartLockAccessCode} />
                  <Button
                    label="Get access code"
                    onClick={this.handleGenerateAccessCode}
                    size={Button.enums.Sizes.Small}
                    isLoading={this.state.isGeneratingAccessCode}
                    isDisabled={!!this.state.smartLockAccessCode}
                    style={Button.enums.Styles.Outline}
                    width={this.props.isMobile ? Button.enums.Widths.Full : Button.enums.Widths.Auto}
                  />
                </Row>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <HorizontalRule />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
                <ResponsiveStepButtons
                  isMobile={this.props.isMobile}
                  isShown={true}>
                  <Button
                    color={Button.enums.Colors.Gray}
                    label="Go back"
                    onClick={this.handleGoBack}
                    style={Button.enums.Styles.Outline}
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Medium} />
                  <Button
                    label="Continue"
                    onClick={this.handleAdvanceToNextStep}
                  />
                </ResponsiveStepButtons>
              </SmartLockSetupStep>
            </AnimatedResizer.AnimatedResizerSection>

            <AnimatedResizer.AnimatedResizerSection
              isRight={this.state.currentStep < Steps.TAKE_PHOTO}
              isShown={this.state.currentStep === Steps.TAKE_PHOTO}>
              <SmartLockSetupStep
                illustration={SmartLockSetupStep.enums.Illustrations.TakePhoto}
                description={`Add a photo of your attached smart lock and a quick description of where to look for it${
                  propertyManagerContract.addressUnit.accessInstruction?.notes ?
                    ' to your existing notes' :
                    ', along with anything else a viewer may need to know to get into the unit.'
                }`}
                isMobile={this.props.isMobile}
                isShown={this.state.currentStep === Steps.TAKE_PHOTO}
                stepNumber={3}
                title="Take photos">
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <AccessForm
                  accessInstruction={propertyManagerContract.addressUnit.accessInstruction}
                  defaultAccessInstructionNotes={propertyManagerContract.addressUnit.accessInstruction?.notes ?? null}
                  isMobile={this.props.isMobile}
                  isShown={this.state.currentStep === Steps.TAKE_PHOTO}
                  onCancel={this.handleGoBack}
                  onDeletePhoto={this.handleDeletePhoto}
                  onSubmit={this.handleSubmitAccessForm}
                />
              </SmartLockSetupStep>
            </AnimatedResizer.AnimatedResizerSection>
          </AnimatedResizer>
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

interface Response {
  viewer: {
    propertyManagerContract: PropertyManagerContract | null;
    uuid: string;
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  propertyManagerContract: PropertyManagerContract | null;
  refetch: () => Promise<ApolloQueryResult<Response>>;
}

interface Variables {
  uuid: string;
}

export default compose(
  withCreateAccessInstruction,
  withUpdateAccessInstruction,
  withCreatePhoto,
  withDeletePhoto,
  withGenerateSmartLockOneTimeCode,
  withDevice,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: (props) => ({
      skip: !props.isOpen,
      variables: {
        uuid: props.propertyManagerContractUuid,
      },
    }),
    props: (props) => ({
      isLoading: props.loading,
      refetch: props.refetch,
      propertyManagerContract: props.data?.viewer?.propertyManagerContract || null,
    }),
  })
)(SmartLockSetupModal) as ComponentType<InputProps>;
