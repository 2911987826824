// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._0N5ej9{background-color:#fff;border-radius:5px;max-width:450px;width:450px}._0N5ej9._QDof89{-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column}._0N5ej9 ._RnEVkv{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;padding:32px 32px 24px;-webkit-transition:all .35s;transition:all .35s;width:450px}._0N5ej9 ._RnEVkv h1,._0N5ej9 ._RnEVkv h2{margin:0;text-align:center}._0N5ej9 ._RnEVkv h1{color:#182029;font-size:20px;font-weight:500;margin-bottom:16px}._0N5ej9 ._RnEVkv h2{color:#182029;font-size:18px;font-weight:300;line-height:1.6em;margin-bottom:40px}._0N5ej9 ._rrEyMW{margin-bottom:16px;-webkit-transform:rotate(45deg);transform:rotate(45deg)}._0N5ej9 ._VYQFwo{position:absolute;top:0;z-index:0}@media screen and (max-width:767px){._0N5ej9{width:95vw}}", ""]);
// Exports
exports.locals = {
	"boost-modal": "_0N5ej9",
	"boost-modal--checkout": "_QDof89",
	"boost-modal__body-success": "_RnEVkv",
	"boost-modal__body-success-icon": "_rrEyMW",
	"boost-modal__confetti": "_VYQFwo"
};
module.exports = exports;
