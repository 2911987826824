import React, { Component, Fragment } from 'react';
import _ from 'lodash';

import withQuery from '~tools/react/graphql/withQuery';

import { Photo, SavedPhoto } from '~web-manage/lib/common/scenes/ListingSetup/scenes/ListingSetupPhotos/types';

import ImportBuildingPhotosModal from './components/ImportBuildingPhotosModal';
import ImportBuildingPhotosButton from './components/ImportBuildingPhotosButton';

import query from './ImportBuildingPhotos.gql';

interface State {
  isImportModalOpen: boolean;
}

interface InputProps {
  copyPhotosToListing: (photos: SavedPhoto[]) => Promise<void>;
  listingUuid: string;
  photos: Photo[];
}

type Props =
  InputProps &
  QueryProps;

class ImportBuildingPhotos extends Component<Props, State> {
  state = {
    isImportModalOpen: false,
  }

  render() {
    const listing = this.props.listing;
    if (!listing) return null;

    const photos = _.filter(this.props.photos, 'uuid');
    const buildingPhotos = _.filter(
      listing.building.userPhotos,
      photo => !_.find(photos, { uuid: photo.uuid }),
    );
    if (buildingPhotos.length === 0) return null;

    return (
      <Fragment>
        <ImportBuildingPhotosButton
          buildingPhotos={buildingPhotos}
          onClick={this.toggleImportModal}
        />
        <ImportBuildingPhotosModal
          buildingPhotos={buildingPhotos}
          isOpen={this.state.isImportModalOpen}
          onClose={this.toggleImportModal}
          buildingName={listing.building.name}
          copyPhotosToListing={this.props.copyPhotosToListing}
        />
      </Fragment>
    );
  }

  toggleImportModal = () =>
    this.setState({ isImportModalOpen: !this.state.isImportModalOpen });
}

interface Listing {
  building: {
    name: string;
    uuid: string;
    userPhotos: SavedPhoto[];
  };
  uuid: string;
}

interface Response {
  viewer: {
    listing: Listing | null;
    uuid: string;
  } | null;
}

interface Variables {
  listingUuid: string;
}

interface QueryProps {
  isLoading: boolean;
  listing: Listing | null;
}

export default withQuery<InputProps, Response, Variables, QueryProps>(query, {
  options: props => ({
    variables: {
      listingUuid: props.listingUuid,
    },
    skip: !props.listingUuid,
    ssr: false,
  }),
  props: props => ({
    isLoading: props.loading,
    listing: props.data?.viewer?.listing || null,
  }),
})(ImportBuildingPhotos);
