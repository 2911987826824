import { Component, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps {
  children: ReactNode;
}

class App extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    // Scroll to the top on a route change
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default App;
