// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._YXri7O{-webkit-box-pack:center;justify-content:center;margin:0 auto 0 0}._YXri7O,._YXri7O ._ZJN28a{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex}._YXri7O ._ZJN28a svg{height:24px;opacity:1;-webkit-transition:opacity .1s;transition:opacity .1s}._YXri7O ._ZJN28a:hover svg{opacity:.8}@media screen and (max-width:1127px){._YXri7O{margin:24px 0 16px}}", ""]);
// Exports
exports.locals = {
	"logo": "_YXri7O",
	"logo__svg": "_ZJN28a"
};
module.exports = exports;
