import _ from 'lodash';
import React, { PureComponent } from 'react';

import { FlipStatuses } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';

import StatusCardSection from '~web-manage/lib/common/components/StatusCardSection';

import query from './ApplicationsStatusSection.gql';

interface QueryProps {
  isLoading: boolean;
  applications: Application[];
}

interface InputProps {
  isLoading: boolean;
}

type Props =
  InputProps &
  QueryProps;

class ApplicationsStatusSection extends PureComponent<Props> {
  render() {
    if (this.props.isLoading) {
      return (
        <StatusCardSection
          isLoading={this.props.isLoading}
          description="Applications detail loading..."
          status={StatusCardSection.enums.Statuses.Unknown}
          title="Applications"
        />
      );
    }

    const completedStatuses = [
      FlipStatuses.ACCEPTED,
      FlipStatuses.FLIPPED,
      FlipStatuses.REJECTED,
      FlipStatuses.WITHDRAWN,
    ];
    const applications = this.props.applications;
    if (applications.length === 0) {
      return (
        <StatusCardSection
          description="You haven't received any applications from prospective renters yet."
          status={StatusCardSection.enums.Statuses.Unknown}
          title="Applications"
        />
      );
    }

    const completeApplications = _.filter(applications, a => _.includes(completedStatuses, a.status));
    const completedPercentage = (completeApplications.length / applications.length) * 100;

    let status = StatusCardSection.enums.Statuses.Ok;
    if (completedPercentage < 90) status = StatusCardSection.enums.Statuses.Warning;
    if (completedPercentage < 70) status = StatusCardSection.enums.Statuses.Degraded;
    return (
      <StatusCardSection
        isLoading={this.props.isLoading}
        description="applications received in the last 30 days have been reviewed and processed."
        highlight={`*${completeApplications.length}* of *${applications.length}*`}
        link={status !== StatusCardSection.enums.Statuses.Ok ? {
          label: 'Review pending applications',
          path: '/applicants',
        } : undefined}
        status={status}
        title="Applications"
      />
    );
  }
}

interface Application {
  applicants: {
    uuid: string;
    fullName: string;
  }[];
  lease: {
    uuid: string;
    startDate: string;
    endDate: string;
  };
  listing: {
    addressUnit: {
      name: string | null;
      address: {
        city: string;
        state: string;
        streetAddress1: string;
        uuid: string;
        zipCode: string;
      };
    };
    uuid: string;
  };
  status: FlipStatuses;
  uuid: string;
}

interface Response {
  viewer: {
    applications: Application[];
    uuid: string;
  } | null;
}

export default compose(
  withQuery<InputProps, Response, {}, QueryProps>(query, {
    props: props => ({
      applications: props.data?.viewer?.applications ?? [],
      isLoading: props.loading,
    }),
  }),
)(ApplicationsStatusSection);
