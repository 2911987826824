// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._wdjZvB{background-color:#ddd;background-size:cover;border-radius:10px;height:80px;margin-bottom:16px;margin-right:16px;width:80px}._wdjZvB ._yprFmK{-webkit-box-align:center;align-items:center;background-color:rgba(24,32,41,.8);border:none;border-radius:10px;display:-webkit-box;display:flex;height:100%;-webkit-box-pack:center;justify-content:center;opacity:0;pointer-events:none;-webkit-transition:opacity .2s;transition:opacity .2s;width:100%}._wdjZvB._ernUmQ ._yprFmK,._wdjZvB:hover ._yprFmK{color:#fff;opacity:1;pointer-events:auto}", ""]);
// Exports
exports.locals = {
	"access-photo": "_wdjZvB",
	"access-photo--delete-button": "_yprFmK",
	"access-photo--loading": "_ernUmQ"
};
module.exports = exports;
