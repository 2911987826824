import { ListingFurnishingTypes, ListingTypes, UpdateListingInputType } from '~tools/types/graphqlSchema';
import withMutation from '~tools/react/graphql/withMutation';

import UpdateListingMutation from './updateListing.gql';

interface Listing {
  addressUnit: {
    address: {
      city: string;
      latitude: number;
      longitude: number;
      neighborhood: string;
      state: string;
      streetAddress1: string;
      uuid: string;
    };
    name: string | null;
    uuid: string;
  } | null;
  amenities: {
    uuid: string;
    name: string;
  }[];
  bathrooms: number | null;
  bedrooms: number | null;
  description: string | null;
  earliestMoveInDate: string | null;
  furnishingType: ListingFurnishingTypes | null;
  headline: string | null;
  leaseEndDate: string | null;
  listingType: ListingTypes | null;
  photos: {
    position: number;
    url: string;
    uuid: string;
  }[];
  rentInCents: number | null;
  slug: string | null;
  status: string | null;
  uuid: string;
}

interface Data {
  updateListing: Listing;
}

interface Variables {
  uuid: string;
  input: UpdateListingInputType;
}

export interface UpdateListingProps {
  updateListing: (uuid: string, input: UpdateListingInputType) => Promise<Listing>;
}

export default withMutation<{}, Data, Variables, UpdateListingProps>(UpdateListingMutation, {
  props: props => ({
    updateListing: async (uuid, input) => {
      const res = await props.mutate({
        variables: {
          uuid,
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error updating listing.');

      return res.data.updateListing;
    },
  }),
});
