import { ApolloQueryResult } from 'apollo-client';
import _ from 'lodash';
import React, { Component } from 'react';

import withQuery from '~tools/react/graphql/withQuery';
import ClearableTypeahead from '~tools/react/components/ClearableTypeahead';
import { ServiceTypes } from '~tools/types/graphqlSchema';

import query from './OrganizationsTypeahead.gql';

interface NewOrganization {
  name: string;
}

interface ExistingOrganization {
  email: string | null;
  name: string;
  uuid: string;
  website: string | null;
  phoneNumbers: {
    uuid: string;
    type: string;
    phoneNumber: string;
  }[];
  propertyManager: {
    uuid: string;
  } | null;
  serviceProvider: {
    uuid: string;
    serviceType: ServiceTypes;
  } | null;
}

export type Organization = NewOrganization | ExistingOrganization;

interface Variables {
  filter?: {
    smart: {
      istartswith: string;
    };
  };
  first: number;
}

interface Response {
  organizations: ExistingOrganization[];
}

interface QueryProps {
  isLoading: boolean;
  refetch: (args: Variables) => Promise<ApolloQueryResult<Response>>;
  organizations: ExistingOrganization[];
}

interface InputProps {
  isRequired?: boolean;
  label?: string;
  name: string;
  onBlur?: (value: string) => any;
  onChange?: (data: Organization | null) => any;
  placeholder?: string;
  value?: string;
}

type Props = InputProps & QueryProps;

interface State {
  value: string;
}

const MIN_QUERY_LENGTH = 3;
const RESULTS_COUNT = 20;

class OrganizationsTypeahead extends Component<Props, State> {
  static defaultProps = {
    isRequired: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.value || '',
    };
  }

  handleBlur = (value) => {
    if (this.props.onBlur) this.props.onBlur(value);
  }

  handleChange = (value) => {
    this.setState({ value }, () => {
      if (!this.props.isLoading && (value.length >= MIN_QUERY_LENGTH || value.length === 0)) {
        this.refetch();
      }
    });
  }

  handleClick = (data: { label: string, value: string } | undefined) => {
    if (!data?.label) return;
    this.handleChange(data.label);
    const onChange = this.props.onChange;
    if (onChange) {
      const organization = _.find(this.props.organizations, p => p.uuid === data.value);
      if (organization) onChange(organization);
    }
  }

  handleClear = () => {
    this.setState({ value: '' });
    if (this.props.onChange) this.props.onChange(null);
  }

  render() {
    const typeaheadItems = _.map(this.props.organizations, organization => ({
      label: organization.name,
      value: organization.uuid,
    }));

    return (
      <ClearableTypeahead
        isLoading={this.props.isLoading}
        isRequired={this.props.isRequired}
        items={this.state.value.length >= MIN_QUERY_LENGTH ? typeaheadItems : []}
        label={this.props.label}
        name={this.props.name}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onClear={this.handleClear}
        onClick={this.handleClick}
        placeholder={this.props.placeholder}
        value={this.state.value}
      />
    );
  }

  refetch = async () => {
    const queriedTerm = this.state.value;
    await this.props.refetch({
      filter: {
        smart: {
          istartswith: this.state.value,
        },
      },
      first: RESULTS_COUNT,
    });

    if (this.state.value !== queriedTerm) {
      await this.refetch();
    }
  }
}

export default withQuery<InputProps, Response, Variables, QueryProps>(query, {
  options: () => ({
    variables: {
      first: RESULTS_COUNT,
    },
  }),
  props: props => ({
    isLoading: props.loading,
    organizations: props.data?.organizations ?? [],
    refetch: props.refetch,
  }),
})(OrganizationsTypeahead);
