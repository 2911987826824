import React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

import Globals from '~web-manage/lib/common/globals';

import { compose } from '~tools/react/graphql';
import withQuery from '~tools/react/graphql/withQuery';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';
import LoadingVisual from '~web-manage/lib/common/components/__deprecated/LoadingVisual';

import query from './MessagesRedirect.gql';

type InputProps = RouteComponentProps<{ threadUuid: string }>;

type Props = InputProps & QueryProps;

function MessagesRedirect(props: Props) {
  if (props.isLoading || !props.viewer) {
    return (
      <ManageStage>
        <LoadingVisual />
      </ManageStage>
    );
  }

  const thread = props.viewer.thread;

  const WEB_CORE_URL = Globals.WEB_CORE_DOMAIN;

  if (thread.flip) {
    // If viewer is the renter redirect to main website
    if (thread.flip.renter.uuid === props.viewer.uuid) {
      window.location.replace(`${WEB_CORE_URL}/applications/${thread.flip.uuid}/overview`);
      return;
    }

    let isManagedByFlip = false;
    if (thread.flip.listing?.addressUnit) {
      thread.flip.listing.addressUnit.activePropertyManagerContracts.forEach((activePropertyManagerContract) => {
        if (activePropertyManagerContract.propertyManager?.organization?.name === 'Caretaker Technologies, Inc') isManagedByFlip = true;
      });
    }
    if (isManagedByFlip) {
      return (
        <Redirect to={`/leases/${thread.flip.lease.uuid}`} />
      );
    }

    window.location.replace(`${WEB_CORE_URL}/listings/${thread.flip.listing.uuid}/applicants/${thread.flip.uuid}/overview`);
    return;
  }

  return (
    <Redirect to="/support" />
  );
}

interface Variables {
  threadUuid: string;
}

interface Response {
  viewer: {
    uuid: string;
    thread: {
      uuid: string;
      flip: {
        uuid: string;
        lease: {
          uuid: string;
        };
        listing: {
          uuid: string;
          addressUnit: {
            uuid: string;
            activePropertyManagerContracts: {
              uuid: string;
              propertyManager: {
                uuid: string;
                organization: {
                  uuid: string;
                  name: string;
                } | null;
              };
            }[];
          }
        }
        renter: {
          uuid: string;
        };
      } | null;
    };
  } | null;
}

interface QueryProps extends Response {
  isLoading: boolean;
}

export default compose(
  withRouter,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: (ownProps) => ({
      skip: !ownProps.match.params.threadUuid,
      variables: {
        threadUuid: ownProps.match.params.threadUuid,
      },
    }),
    props: (props) => ({
      isLoading: props.loading,
      viewer: props.data?.viewer ?? null,
    }),
  })
)(MessagesRedirect);
