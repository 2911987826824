import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import Globals from '~web-core/lib/common/globals';

import withPaymentSubscription, { PaymentSubscriptionProps } from '~tools/react/hocs/withPaymentSubscription';

import Stage from '~web-core/lib/common/stages/Stage';

import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import VerticalMenu from '~tools/react/components/VerticalMenu';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import WidthConstraintView from '~tools/react/components/WidthConstraintView';

import Navigation from './containers/Navigation';
import OnboardingRedirect from './containers/OnboardingRedirect';

interface InputProps {
  children: ReactNode;
}

type Props =
  InputProps &
  PaymentSubscriptionProps;

function ManageStage(props: Props) {
// const paymentPlan = props.paymentSubscription?.plan ?? null;

  const financeLinks = [{
    label: 'Revenue',
    path: '/revenue',
  }];
  if (props.hasInitializedBalance) {
    financeLinks.push({
      label: 'Expenses',
      path: '/expenses',
    });
  }

  return (
    <Stage
      alert={Globals.ENV === 'demo' ? {
        label: 'This is just a demo. Want the real thing? Start setting up your properties with modern property management',
        path: 'https://manage.caretaker.com/start',
      } : null}
      hasNavigation={false}
      isProtected
      shouldLoadSubscriptions={false}>
      <OnboardingRedirect />
      <Helmet>
        <meta name="googlebot" content="noindex, nofollow, noarchive" />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="viewport" content="width=1137" />
      </Helmet>
      <Navigation paymentSubscription={props.paymentSubscription?.plan} />
      <WidthConstraintView
        hasPaddingOnMobile={false}
        size={WidthConstraintView.enums.Sizes.XLarge}>
        <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
          <div style={{ width: '30%', maxWidth: '275px' }}>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
            <VerticalMenu
              hasHeader={false}
              header={{
                title: 'Manage',
              }}
              stickyPosition={{
                top: '110px',
              }}
              sections={[
                {
                  links: [
                    {
                      label: 'Home',
                      path: '/',
                      isExact: true,
                    },
                  ],
                },
                {
                  links: [
                    {
                      label: 'Units',
                      path: `/units`,
                    },
                    {
                      label: 'Finances',
                      path: `/finances`,
                      secondaryLinks: financeLinks,
                    },
                  ],
                },
                {
                  links: [
                    {
                      isExact: true,
                      label: 'Marketing',
                      path: `/market`,
                      secondaryLinks: [
                        {
                          label: 'Questions',
                          path: '/questions',
                        },
                        {
                          label: 'Viewings',
                          path: '/viewings',
                        },
                      ],
                    },
                    // {
                    //   isExact: true,
                    //   isDisabled: !isManagedLandlord,
                    //   label: 'Access',
                    //   path: `/access`,
                    //   secondaryLinks: [
                    //     {
                    //       label: 'Smart locks',
                    //       path: '/smart-locks',
                    //     },
                    //   ],
                    // },
                    {
                      isExact: false,
                      label: 'Applications',
                      path: '/applicants',
                    },
                    {
                      isExact: false,
                      label: 'Leases',
                      path: `/leases`,
                      secondaryLinks: [
                        {
                          label: 'Security Deposits',
                          path: '/security-deposits',
                        },
                      ],
                    },
                    {
                      isExact: true,
                      // isDisabled: !isManagedLandlord,
                      label: 'Maintenance',
                      path: '/maintain',
                      secondaryLinks: [
                        {
                          label: 'Issues',
                          path: '/issues',
                        },
                        {
                          label: 'Inspections',
                          path: '/inspections',
                        },
                      ],
                    },
                  ],
                },
                {
                  links: [
                    {
                      label: 'Settings',
                      path: '/settings',
                    },
                  ],
                },
              ]}
            />
          </div>
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XLarge} />
          <div style={{ width: '100%', maxWidth: 'calc(100% - 315px)' }}>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
            {props.children}
          </div>
        </Row>
      </WidthConstraintView>
    </Stage>
  );
}

export default withPaymentSubscription(ManageStage);
