import _ from 'lodash';
import React, { Fragment, PureComponent } from 'react';

import {
  QualificationThresholdModifiers,
  QualificationThresholdTypes,
} from '~tools/types/graphqlSchema';

import Form, { Select } from '~tools/react/components/Form';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';

import IconButton from './components/IconButton';
import RequirementValueInput from './components/RequirementValueInput';

import { Qualification } from './types';
import {
  getMemoizedPreface,
  getMemoizedThresholdModifier,
  getMemoizedThresholdModifierOptions,
  getMemoizedThresholdUnits,
  getMemoizedThresholdValue,
  getMemoizedThresholdVariable,
} from './utils';

interface InputProps {
  isDisabled?: boolean;
  note?: string;
  onCreateRequirement: (args: Qualification) => Promise<void>;
  onDeleteRequirement: (requirementUuid?: string) => Promise<void>;
  onUpdateRequirement: (requirementUuid: string, args: Qualification) => Promise<void>;
  qualification: Qualification;
  requirementUuid?: string;
  viewerDidCreate?: boolean;
}

interface State {
  isDeleting: boolean;
  isFormOpen: boolean;
  isSaving: boolean;
  modifier?: QualificationThresholdModifiers;
  value?: number;
}

type Props = InputProps;

class RequirementManager extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const modifierOptions = getMemoizedThresholdModifierOptions(props.qualification);
    this.state = {
      isDeleting: false,
      isFormOpen: !props.requirementUuid || false,
      isSaving: false,
      modifier: modifierOptions.length === 1 ? modifierOptions[0] : props.qualification.thresholdModifier,
      value: props.qualification.thresholdValue,
    };
  }

  handleOpenForm = () => this.setState({ isFormOpen: true });

  handleChangeModifier = (modifier: string) => this.setState({ modifier: modifier as QualificationThresholdModifiers });
  handleChangeValue = (value: string) => this.setState({ value: parseInt(value, 10) });

  handleSave = async (args: {
    value: number;
    modifier: QualificationThresholdModifiers;
  }) => {
    this.setState({ isSaving: true });
    const qualification: Qualification = {
      subtype: this.props.qualification.subtype || undefined,
      thresholdModifier: args.modifier,
      thresholdType: this.props.qualification.thresholdType,
      thresholdUnits: this.props.qualification.thresholdUnits || undefined,
      thresholdValue: args.value,
      thresholdVariable: this.props.qualification.thresholdVariable || undefined,
      type: this.props.qualification.type,
    };
    if (this.props.requirementUuid) {
      await this.props.onUpdateRequirement(this.props.requirementUuid, qualification);
      return;
    }

    await this.props.onCreateRequirement(qualification);
  }
  handleDelete = async () => {
    this.setState({ isDeleting: true });
    await this.props.onDeleteRequirement(this.props.requirementUuid);
  }

  render() {
    const qualification = this.props.qualification;
    const thresholdModifier = getMemoizedThresholdModifier({
      ...qualification,
      thresholdModifier: this.state.modifier,
    });
    const thresholdModifierOptions = getMemoizedThresholdModifierOptions(qualification);
    const thresholdUnits = getMemoizedThresholdUnits(qualification);
    const thresholdValue = getMemoizedThresholdValue({
      ...qualification,
      thresholdValue: this.state.value,
    });
    const thresholdVariable = getMemoizedThresholdVariable(qualification);

    return (
      <Row
        flexBehavior={Row.enums.FlexBehaviors.Default}
        verticalAlignment={Row.enums.VerticalAlignments.Center}>
        <Text
          color={this.props.isDisabled ? Text.enums.Colors.Secondary : undefined}
          content={`${getMemoizedPreface(qualification)} `}
          isEmphasized
          shouldWrap={false}
        />
        <Form onSubmit={this.handleSave}>
          <Row
            flexBehavior={Row.enums.FlexBehaviors.Default}
            verticalAlignment={Row.enums.VerticalAlignments.Center}>
            {this.state.isFormOpen ? (
              <Fragment>
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                <div style={{ width: '115px' }}>
                  <Select
                    isDisabled={this.props.isDisabled || thresholdModifierOptions.length === 1}
                    isRequired
                    name="modifier"
                    onChange={this.handleChangeModifier}
                    options={_.map(thresholdModifierOptions, option => ({
                      label: _.lowerCase(option),
                      value: option,
                    }))}
                    placeholder="Select..."
                    value={this.state.modifier}
                  />
                </div>
              </Fragment>
            ) : null}
            {!this.state.isFormOpen && thresholdModifier ? (
              <Fragment>
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                <Text
                  color={this.props.isDisabled ? Text.enums.Colors.Secondary : Text.enums.Colors.Info}
                  content={thresholdModifier}
                  decoration={Text.enums.Decorations.Underline}
                  isEmphasized
                />
              </Fragment>
            ) : null}
            {this.state.isFormOpen ? (
              <Fragment>
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                <RequirementValueInput
                  isDisabled={this.props.isDisabled}
                  onChange={this.handleChangeValue}
                  type={this.props.qualification.type}
                  value={this.state.value}
                />
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
              </Fragment>
            ) : null}
            {!this.state.isFormOpen && !_.isNil(thresholdValue) ? (
              <Fragment>
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                <Text
                  color={this.props.isDisabled ? Text.enums.Colors.Secondary : Text.enums.Colors.Info}
                  content={thresholdValue}
                  decoration={Text.enums.Decorations.Underline}
                  isEmphasized
                />
              </Fragment>
            ) : null}
            {qualification.thresholdType === QualificationThresholdTypes.MULTIPLIER && thresholdVariable ? (
              <Fragment>
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXSmall} />
                <Text
                  size={Text.enums.Sizes.Large}
                  color={Text.enums.Colors.Secondary}
                  content="×"
                />
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXSmall} />
              </Fragment>
            ) : (
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
            )}
            {(thresholdVariable || thresholdUnits) && thresholdUnits !== 'judgements' ? (
              <Text
                isEmphasized
                color={this.props.isDisabled ? Text.enums.Colors.Secondary : undefined}
                content={thresholdVariable || thresholdUnits}
              />
            ) : null}
            {!this.state.isFormOpen && this.props.note ? (
              <Fragment>
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                <Text
                  color={this.props.isDisabled ? Text.enums.Colors.Secondary : Text.enums.Colors.Info}
                  content={`(${this.props.note})`}
                  isEmphasized
                />
              </Fragment>
            ) : null}
            <div style={{ marginRight: 'auto' }} />
            {this.props.isDisabled ? (
              <IconButton
                color={IconButton.enums.Colors.Blue}
                icon={IconButton.enums.Icons.Lock}
                isDisabled
              />
            ) : (
              <Fragment>
                {this.state.isFormOpen ? (
                  <Fragment>
                    <IconButton
                      color={IconButton.enums.Colors.Green}
                      icon={IconButton.enums.Icons.Check}
                      isDisabled={this.state.isDeleting}
                      isLoading={this.state.isSaving}
                      key="requirement-manager-save"
                      type={IconButton.enums.Types.Submit}
                    />
                    <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                    <IconButton
                      color={IconButton.enums.Colors.Red}
                      icon={IconButton.enums.Icons.Cross}
                      isDisabled={this.state.isSaving}
                      isLoading={this.state.isDeleting}
                      onClick={this.handleDelete}
                    />
                  </Fragment>
                ) : (
                  <IconButton
                    color={IconButton.enums.Colors.Blue}
                    icon={IconButton.enums.Icons.Pencil}
                    isDisabled={this.state.isDeleting}
                    key="requirement-manager-edit"
                    onClick={this.handleOpenForm}
                    type={IconButton.enums.Types.Button}
                  />
                )}
              </Fragment>
            )}
          </Row>
        </Form>
      </Row>
    );
  }
}

export default RequirementManager;
