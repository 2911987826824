import withMutation from '~tools/react/graphql/withMutation';
import { UpdateListingAnswerInput } from '~tools/types/graphqlSchema';

import UpdateListingAnswerMutation from './updateListingAnswer.gql';

interface Response {
  updateListingAnswer: {
    uuid: string;
  };
}

export interface UpdateListingAnswerProps {
  updateListingAnswer: (uuid: string, input: UpdateListingAnswerInput) => Promise<{ uuid: string }>;
}

export default withMutation<{}, Response, { uuid: string, input: UpdateListingAnswerInput }, UpdateListingAnswerProps>(UpdateListingAnswerMutation, {
  props: props => ({
    updateListingAnswer: async (uuid: string, input: UpdateListingAnswerInput) => {
      const res = await props.mutate({
        variables: { uuid, input },
      });

      if (!res || !res.data) throw new Error('Error in updating listing answer');
      if (!res.data.updateListingAnswer) throw new Error('No updateListingAnswer returned');

      return res.data.updateListingAnswer;
    },
  }),
});
