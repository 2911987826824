import withMutation from '~tools/react/graphql/withMutation';

import DeleteRequirementMutation from './deleteRequirement.gql';

interface Data {
  deleteRequirement: boolean;
}

interface Variables {
  uuid: string;
}

export interface DeleteRequirementProps {
  deleteRequirement: (uuid: string) => Promise<boolean>;
}

export default withMutation<{}, Data, Variables, DeleteRequirementProps>(DeleteRequirementMutation, {
  props: props => ({
    deleteRequirement: async (uuid: string) => {
      const res = await props.mutate({
        variables: {
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error deleting requirement.');

      return res.data.deleteRequirement;
    },
  }),
});
