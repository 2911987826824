import _ from 'lodash';

import AnimatedStepsFlowStepFooter from './components/AnimatedStepsFlowStepFooter';
import AnimatedStepsFlowStepLocked from './components/AnimatedStepsFlowStepLocked';

import AnimatedStepsFlowStep from './AnimatedStepsFlowStep';

export default _.assign(AnimatedStepsFlowStep, {
  AnimatedStepsFlowStepFooter,
  AnimatedStepsFlowStepLocked,
});
