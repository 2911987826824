import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './BrandShapes.scss';

import ArrowsSVG from './svgs/arrows.svg';

function BrandShapes() {
  return (
    <div styleName="brand-shapes">
      <ArrowsSVG />
    </div>
  );
}

export default withStyles(styles)(BrandShapes);
