// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._ernr23{-webkit-box-align:center;align-items:center;background:#fff;border-top:1px solid #e6e8eb;bottom:0;display:-webkit-box;display:flex;-webkit-box-pack:justify;justify-content:space-between;margin-top:40px;padding:16px 0;position:-webkit-sticky;position:sticky;width:100%}@media screen and (max-width:1127px){._ernr23{bottom:0;left:0;margin-left:0;padding:16px;position:fixed;right:0;width:auto}}@media screen and (max-width:500px){._ernr23 button:first-child,._ernr23 button:last-child{font-size:14px;height:32px;line-height:32px;padding:0 12px}}._ernr23._DBDPKd{margin-top:0}._ernr23._YXrr8Y{margin-top:32px}._VYQI8W{display:none;height:80px;width:100%}@media screen and (max-width:1127px){._VYQI8W{display:block}}@media screen and (max-width:500px){._VYQI8W{height:65px}}", ""]);
// Exports
exports.locals = {
	"manage-listing-navbar": "_ernr23",
	"manage-listing-navbar-margin-none": "_DBDPKd",
	"manage-listing-navbar-margin-large": "_YXrr8Y",
	"manage-listing-navbar-spacing": "_VYQI8W"
};
module.exports = exports;
