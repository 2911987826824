// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._x28yGw{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-pack:center;justify-content:center}._x28yGw,._x28yGw ._32AYEA{height:18px;max-height:18px;max-width:18px;min-height:18px;min-width:18px;width:18px}._x28yGw._MOJwRr{color:#8492a6}._x28yGw._7RAik6{color:#15a964}._x28yGw._P9WqM9{color:#ca0021}._x28yGw._WVj1dE{color:#1f8eed}._x28yGw._DBDUMM{height:16px;max-height:16px;max-width:16px;min-height:16px;min-width:16px;width:16px}", ""]);
// Exports
exports.locals = {
	"icon": "_x28yGw",
	"icon__svg": "_32AYEA",
	"icon--color-gray": "_MOJwRr",
	"icon--color-green": "_7RAik6",
	"icon--color-red": "_P9WqM9",
	"icon--color-blue": "_WVj1dE",
	"icon--svg-refresh": "_DBDUMM"
};
module.exports = exports;
