import _ from 'lodash';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';

import { CalendarTimeSlotEntityTypes, ListingStatuses } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';

import StatusCardSection from '~web-manage/lib/common/components/StatusCardSection';

import query from './MarketingStatusSection.gql';

interface PropertyManagerContract {
  addressUnit: {
    calendar: {
      availableTimeSlots: {
        eligibleEntityTypes: CalendarTimeSlotEntityTypes[];
        uuid: string;
      }[];
      uuid: string;
    } | null;
    lease: {
      endDate: string;
      uuid: string;
    } | null;
    uuid: string;
  };
  listing: {
    status: ListingStatuses;
    uuid: string;
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  propertyManagerContracts: PropertyManagerContract[];
}

interface InputProps {
  isLoading: boolean;
}

type Props =
  InputProps &
  QueryProps;

class MarketingStatusSection extends PureComponent<Props> {
  render() {
    if (this.props.isLoading) {
      return (
        <StatusCardSection
          isLoading={this.props.isLoading}
          description="Marketing detail loading..."
          status={StatusCardSection.enums.Statuses.Unknown}
          title="Marketing"
        />
      );
    }

    const propertyManagerContracts = this.props.propertyManagerContracts;
    const initializedContracts = _.filter(propertyManagerContracts, (propertyManagerContract) => {
      const status = propertyManagerContract.listing?.status;
      if (!status || status === ListingStatuses.DRAFT) return false;
      return true;
    });

    if (initializedContracts.length === 0) {
      return (
        <StatusCardSection
          description="We need a few more details on your units before we can start marketing them."
          link={{
            label: 'Add marketing details',
            path: '/units',
          }}
          status={StatusCardSection.enums.Statuses.NoData}
          title="Marketing"
        />
      );
    }

    const potentialVacancies = _.filter(initializedContracts, (propertyManagerContract) => {
      const activeLease = propertyManagerContract.addressUnit.lease;
      if (!activeLease || moment(activeLease.endDate).isBefore(moment().add(2, 'months'))) return true;

      return false;
    });
    if (potentialVacancies.length === 0) {
      return (
        <StatusCardSection
          description="current or upcoming vacancies at the moment. Nice!"
          highlight="*0*"
          status={StatusCardSection.enums.Statuses.Ok}
          title="Marketing"
        />
      );
    }

    const listedPotentialVacancies = _.filter(potentialVacancies, (propertyManagerContract) => {
      const availableTimeSlots = propertyManagerContract.addressUnit.calendar?.availableTimeSlots ?? [];
      const availableTimeSlot = _.find(
        availableTimeSlots,
        timeSlot => _.includes(timeSlot.eligibleEntityTypes, CalendarTimeSlotEntityTypes.VIEWING),
      );
      return !!availableTimeSlot && propertyManagerContract.listing?.status === ListingStatuses.LISTED;
    });
    const listedPercentage = (listedPotentialVacancies.length / potentialVacancies.length) * 100;

    let status = StatusCardSection.enums.Statuses.Ok;
    if (listedPercentage < 90) status = StatusCardSection.enums.Statuses.Warning;
    if (listedPercentage < 70) status = StatusCardSection.enums.Statuses.Degraded;
    return (
      <StatusCardSection
        isLoading={this.props.isLoading}
        description="vacant or soon-to-be vacant units are listed and have available viewing windows."
        highlight={`*${listedPotentialVacancies.length}* of *${potentialVacancies.length}*`}
        link={status !== StatusCardSection.enums.Statuses.Ok ? {
          label: 'View affected units',
          path: '/units',
        } : undefined}
        status={status}
        title="Marketing"
      />
    );
  }
}

interface Response {
  viewer: {
    propertyManagerContracts: PropertyManagerContract[];
    uuid: string;
  } | null;
}

export default compose(
  withQuery<InputProps, Response, {}, QueryProps>(query, {
    props: props => ({
      isLoading: props.loading,
      propertyManagerContracts: props.data?.viewer?.propertyManagerContracts ?? [],
    }),
  }),
)(MarketingStatusSection);
