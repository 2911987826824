import React, { ComponentType, PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Button from '~tools/react/components/Button';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import { TextArea } from '~tools/react/components/Form';

import styles from './EditableAccessNotes.scss';

interface Props {
  onSave: (newText: string) => Promise<void>;
  value: string | null;
}

interface State {
  editingValue: string | null;
  isSaving: boolean;
}

class EditableAccessNotes extends PureComponent<Props, State> {
  state: State = {
    editingValue: null,
    isSaving: false,
  }

  handleEnterEditMode = () => {
    this.setState({ editingValue: this.props.value ?? '' });
  }

  handleTextareaChange = (newValue: string) => {
    this.setState({ editingValue: newValue });
  }

  handleSaveChanges = async () => {
    const newValue = this.state.editingValue;
    if (!newValue) return;

    this.setState({ isSaving: true });
    await this.props.onSave(newValue);
    this.setState({
      editingValue: null,
      isSaving: false,
    });
  }

  handleCancel = () => this.setState({ editingValue: null });

  render() {
    if (this.state.editingValue) {
      return (
        <div styleName="editable-access-notes">
          <TextArea
            name="notes"
            size={TextArea.enums.Sizes.Medium}
            onChange={this.handleTextareaChange}
            value={this.state.editingValue}
          />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
          <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
            <Button
              isDisabled={this.state.isSaving}
              isLoading={this.state.isSaving}
              label="Save"
              onClick={this.handleSaveChanges}
              style={Button.enums.Styles.Outline}
              size={Button.enums.Sizes.XSmall}
            />
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXSmall} />
            <Button
              color={Button.enums.Colors.Gray}
              isDisabled={this.state.isSaving}
              label="Cancel"
              onClick={this.handleCancel}
              size={Button.enums.Sizes.XSmall}
              style={Button.enums.Styles.Inverse}
            />
          </Row>
        </div>
      );
    }

    return (
      <div styleName="editable-access-notes">
        <Text
          content={this.props.value ?? ''}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
        <Button
          label="Edit notes"
          onClick={this.handleEnterEditMode}
          size={Button.enums.Sizes.XSmall}
          style={Button.enums.Styles.Outline}
        />
      </div>
    );
  }
}

export default withStyles(styles)(EditableAccessNotes) as ComponentType<Props>;
