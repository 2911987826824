import { FetchOrCreateListingForPropertyManagerContractInput } from '~tools/types/graphqlSchema';
import withMutation from '~tools/react/graphql/withMutation';

import FetchOrCreateListingForPropertyManagerContractMutation from './fetchOrCreateListingForPropertyManagerContract.gql';

interface Listing {
  bathrooms: number | null;
  bedrooms: number | null;
  description: string | null;
  earliestMoveInDate: string | null;
  headline: string | null;
  leaseEndDate: string | null;
  listingType: string | null;
  rentInCents: number | null;
  slug: string | null;
  status: string | null;
  uuid: string;
  addressUnit: {
    name: string | null;
    uuid: string;
    address: {
      city: string;
      latitude: number;
      longitude: number;
      neighborhood: string;
      state: string;
      streetAddress1: string;
      uuid: string;
    };
  } | null;
  amenities: {
    uuid: string;
    name: string;
  }[];
  building: {
    uuid: string;
  };
  photos: {
    position: number;
    url: string;
    uuid: string;
  }[];
}

interface Data {
  fetchOrCreateListingForPropertyManagerContract: Listing;
}

interface Variables {
  input: FetchOrCreateListingForPropertyManagerContractInput;
}

export interface FetchOrCreateListingForPropertyManagerContractProps {
  fetchOrCreateListingForPropertyManagerContract: (input: FetchOrCreateListingForPropertyManagerContractInput) => Promise<Listing>;
}

export default withMutation<{}, Data, Variables, FetchOrCreateListingForPropertyManagerContractProps>(FetchOrCreateListingForPropertyManagerContractMutation, {
  props: props => ({
    fetchOrCreateListingForPropertyManagerContract: async (input: FetchOrCreateListingForPropertyManagerContractInput) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating listing.');

      return res.data.fetchOrCreateListingForPropertyManagerContract;
    },
  }),
});
