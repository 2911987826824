import React from 'react';
import { compose } from '~tools/react/graphql';

import Heading from '~tools/react/components/Heading';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import withStyles from '~tools/react/hocs/withStyles';

import styles from './AnimatedStepsFlowNotFound.scss';

function AnimatedStepsFlowNotFound() {
  return (
    <div>
      <Heading
        align={Heading.enums.Align.Center}
        content="Uh oh."
        size={Heading.enums.Sizes.XLarge}
      />
      <Text
        align={Text.enums.Align.Center}
        content="We couldn't find that page."
        size={Text.enums.Sizes.XLarge}
      />
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
      <img src="https://images.caretaker.com/static/assets/illos/404.png" alt="404 - Page not found." />
    </div>
  );
}

export default compose(
  withStyles(styles),
)(AnimatedStepsFlowNotFound);
