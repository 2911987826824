import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './Spinner.scss';
import Spinner from './svgs/spinner.svg';

function SpinnerWrapper() {
  return (
    <div styleName="spinner">
      <Spinner height="20" width="20" />
    </div>
  );
}

export default withStyles(styles)(SpinnerWrapper);
