import _ from 'lodash';
import React, { ComponentType, PureComponent } from 'react';

import { formatAsUSD } from '~tools/utils/string';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withCreateClaim, { CreateClaimProps } from '~tools/react/graphql/mutations/claims/withCreateClaim';
import withUpdateClaim, { UpdateClaimProps } from '~tools/react/graphql/mutations/claims/withUpdateClaim';
import withAttachEvidence, { AttachEvidenceProps } from '~tools/react/graphql/mutations/claims/withAttachEvidence';
import withCreateUpload, { CreateUploadProps, Upload, UploadTags } from '~tools/react/graphql/mutations/uploads/withCreateUpload';

import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import Form, { FormField, Input, TextArea } from '~tools/react/components/Form';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Tag from '~tools/react/components/Tag';
import ThemedModal from '~tools/react/components/ThemedModal';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import Dropzone, { DropzoneLoadCallback } from '~tools/react/containers/Dropzone';

import UploadAttachment from './components/UploadAttachment';
import UploadAttachmentRow from './components/UploadAttachmentRow';

import query from './ClaimModal.gql';

interface InputProps {
  claimUuid?: string;
  isOpen: boolean;
  onClose: () => void;
  securityDepositUuid: string;
}

type Props =
  InputProps &
  AttachEvidenceProps &
  CreateClaimProps &
  CreateUploadProps &
  UpdateClaimProps &
  QueryProps;

interface State {
  uploads: Upload[];
}

interface FormData {
  claimAmountInDollars: string;
  description: string;
}

class ClaimModal extends PureComponent<Props, State> {
  state: State = {
    uploads: [],
  };

  handleUpdateClaim = async (data: FormData) => {
    const securityDeposit = this.props.securityDeposit;
    if (!securityDeposit) return;
    const claim = securityDeposit.claim;
    if (!claim) return;

    const claimAmountInCents = parseFloat(data.claimAmountInDollars) * 100;

    await this.props.updateClaim(claim.uuid, {
      amountInCents: claimAmountInCents,
      description: data.description,
    });

    if (this.state.uploads.length > 0) {
      await this.props.attachEvidence(claim.uuid, { evidenceUuids: _.map(this.state.uploads, upload => upload.uuid) });
    }

    this.setState({ uploads: [] });
    this.props.onClose();
  }

  handleSubmitClaim = async (data: FormData) => {
    const securityDeposit = this.props.securityDeposit;
    if (!securityDeposit) return;

    const claimAmountInCents = parseFloat(data.claimAmountInDollars) * 100;
    const claim = await this.props.createClaim({
      securityDepositUuid: securityDeposit.uuid,
      amountInCents: claimAmountInCents,
      description: data.description,
    });
    if (this.state.uploads.length > 0) {
      await this.props.attachEvidence(claim.uuid, { evidenceUuids: _.map(this.state.uploads, upload => upload.uuid) });
    }

    this.setState({ uploads: [] });
    this.props.onClose();
  }

  handleDeleteUpload = (uuid: string) => {
    this.setState({ uploads: _.reject(this.state.uploads, { uuid }) });
  }

  handleUploadPhoto: DropzoneLoadCallback = async (signedRequest, file) => {
    const upload = await this.props.createUpload({
      filename: file.name,
      fileType: file.type,
      url: `${signedRequest.bucketUrl}${signedRequest.key}`,
      tag: UploadTags.CLAIM_EVIDENCE,
    });

    this.setState({ uploads: [...this.state.uploads, upload] });
  }

  render() {
    const securityDeposit = this.props.securityDeposit;
    if (!securityDeposit) return null;

    const claim = securityDeposit?.claim;
    const isLocked = !!(claim?.rejectedAt || claim?.approvedAt);
    const uploads = [
      ...this.state.uploads,
      ...claim?.evidence ?? [],
    ];

    return (
      <ThemedModal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        subtitle={claim ? undefined : `
          File claims if there are any unforeseen damages to your property.
          All reports are manually reviewed by Caretaker.`}
        title={claim ? 'Update claim' : 'Make a claim'}
        width={ThemedModal.enums.Widths.Medium}>
        <ThemedModal.ThemedModalSection>
          <Card shadow={Card.enums.Shadows.Small}>
            <Card.CardSection spacing={Card.CardSection.enums.Spacing.Small}>
              <Heading
                content="Current security deposit amount"
                font={Heading.enums.Fonts.Secondary}
                size={Heading.enums.Sizes.XXXSmall}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
              <Row
                flexBehavior={Row.enums.FlexBehaviors.Default}
                verticalAlignment={Row.enums.VerticalAlignments.Center}>
                <Heading
                  content={`*$${formatAsUSD(securityDeposit.currentAmountInCents / 100, true)}* USD`}
                  font={Heading.enums.Fonts.Secondary}
                  isMarkdown
                  size={Heading.enums.Sizes.Medium}
                />
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                <Tag
                  color={securityDeposit.debitTransaction.refundedAt ? Tag.enums.Colors.Gray : Tag.enums.Colors.Blue}
                  label={securityDeposit.debitTransaction.refundedAt ? 'Returned' : 'In Escrow'}
                  size={Tag.enums.Sizes.Large}
                  icon={Tag.enums.Icons.Lock}
                />
              </Row>
            </Card.CardSection>
          </Card>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          <Form onSubmit={claim ? this.handleUpdateClaim : this.handleSubmitClaim}>
            <Form.FormSection>
              <Input
                isDisabled={isLocked}
                isRequired
                label="Damage amount"
                labelFormat={Input.enums.LabelFormats.Stacked}
                name="claimAmountInDollars"
                placeholder="100"
                maxAmount={securityDeposit ? securityDeposit.currentAmountInCents / 100 : 0}
                type={Input.enums.Types.Currency}
                value={claim?.amountInCents ? claim.amountInCents / 100 : undefined}
              />
            </Form.FormSection>
            <Form.FormSection>
              <TextArea
                isDisabled={isLocked}
                isRequired
                placeholder="There is a large hole in the drywall. It appears that it may have been damaged accidentally while moving furniture."
                label="Description"
                labelFormat={TextArea.enums.LabelFormats.Stacked}
                name="description"
                value={claim?.description ?? undefined}
              />
            </Form.FormSection>
            <Form.FormSection>
              <FormField label="Evidence" labelFormat={FormField.enums.LabelFormats.Stacked}>
                {uploads.length > 0 ? (
                  <UploadAttachmentRow>
                    {_.map(uploads, (upload) => (
                      <UploadAttachment
                        fileName={upload.filename}
                        key={upload.uuid}
                        onDelete={isLocked ? undefined : this.handleDeleteUpload}
                        uuid={upload.uuid}
                      />
                    ))}
                  </UploadAttachmentRow>
                ) : null}
                <Dropzone
                  key={this.state.uploads.length}
                  onLoad={this.handleUploadPhoto}
                  placeholder="Upload photos and receipts"
                  placeholderIcon={Dropzone.enums.Icons.PlusCircle}
                  uploadType={Dropzone.enums.UploadTypes.Image}
                />
              </FormField>
            </Form.FormSection>
            <div />
            <Button
              align={Button.enums.Alignments.Right}
              isDisabled={isLocked || uploads.length === 0}
              label={claim ? 'Update claim' : 'Submit claim'}
              type={Button.enums.Types.Submit}
            />
          </Form>
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

interface SecurityDeposit {
  uuid: string;
  currentAmountInCents: number;
  claim: {
    amountInCents: number;
    approvedAt: string | null;
    description: string | null;
    rejectedAt: string | null;
    uuid: string;
    evidence: {
      filename: string;
      tag: string;
      url: string;
      uuid: string;
    }[];
  } | null;
  debitTransaction: {
    refundedAt: string | null;
    uuid: string;
  };
}

interface Response {
  viewer: {
    uuid: string;
    securityDeposit: SecurityDeposit | null;
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  securityDeposit: SecurityDeposit | null;
}

interface Variables {
  securityDepositUuid: string;
  claimUuid?: string;
}

export default compose(
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: props => ({
      variables: {
        claimUuid: props.claimUuid,
        securityDepositUuid: props.securityDepositUuid,
      },
    }),
    props: props => ({
      isLoading: props.loading,
      securityDeposit: props.data?.viewer?.securityDeposit ?? null,
    }),
  }),
  withCreateClaim,
  withUpdateClaim,
  withAttachEvidence,
  withCreateUpload,
)(ClaimModal) as ComponentType<InputProps>;
