// @flow

import React from 'react';
import type { Node } from 'react';

import withStyles from '~tools/react/hocs/withStyles';
import Heading from '~tools/react/components/Heading';

import NextStepsCard from './components/NextStepsCard';
import styles from './NextSteps.scss';

type Props = {|
  children: Node,
|};

function NextSteps(props: Props) {
  return (
    <div styleName="next-steps">
      <Heading size={Heading.enums.Sizes.XSmall} content="Next steps" font={Heading.enums.Fonts.Secondary} />
      {props.children}
    </div>
  );
}

NextSteps.NextStepsCard = NextStepsCard;

export default withStyles(styles)(NextSteps);
