import React, { ComponentType } from 'react';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

import Globals from '~web-core/lib/common/globals';

const stripePromise = loadStripe(Globals.STRIPE_KEY);
export interface StripeProps {
  stripe: Stripe | null;
  elements: StripeElements | null;
}

function withStripe<T>(ComposedComponent: ComponentType<T & StripeProps>) {
  const displayName = ComposedComponent.displayName || ComposedComponent.name || 'Component';
  const InjectedComponent = (props: T) => (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <ComposedComponent
          stripe={stripe}
          elements={elements}
          {...props}
        />
      )}
    </ElementsConsumer>
  );

  function WithStripe(props: T) {
    return (
      <Elements
        options={{
          fonts: [
            {
              family: 'Avenir',
              src: "url('https://cdn.caretaker.com/fonts/avenir/Avenir-Medium.woff')",
            },
          ],
        }}
        stripe={stripePromise}
      >
        {/* @ts-ignore */}
        <InjectedComponent {...props} />
      </Elements>
    );
  }
  WithStripe.displayName = `withStripe(${displayName})`;

  return WithStripe;
}

export default withStripe;
