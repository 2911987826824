import classNames from 'classnames';
import _ from 'lodash';
import React, { PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import AlertSVG from '~tools/svgs/icons/interface/alert-triangle.svg';
import CheckSVG from '~tools/svgs/icons/interface/check-circle.svg';
import ClockSVG from '~tools/svgs/icons/interface/clock.svg';
import ExternalSendSVG from '~tools/svgs/icons/interface/external-send.svg';
import RefreshSVG from '~tools/svgs/icons/interface/refresh.svg';

import * as enums from './enums';

import styles from './Icon.scss';

const propToIcon = {
  [enums.SVGs.Alert]: RefreshSVG,
  [enums.SVGs.Check]: CheckSVG,
  [enums.SVGs.Clock]: ClockSVG,
  [enums.SVGs.ExternalSend]: ExternalSendSVG,
  [enums.SVGs.Refresh]: AlertSVG,
};

interface Props {
  color?: enums.Colors;
  svg: enums.SVGs;
  size?: {
    width: string;
    height: string;
  };
}

class Icon extends PureComponent<Props> {
  static enums = enums;

  render() {
    const SVG = propToIcon[this.props.svg];
    if (!SVG) return null;

    const style = this.props.size ? {
      ...this.props.size,
      maxHeight: this.props.size.height,
      maxWidth: this.props.size.width,
      minHeight: this.props.size.height,
      minWidth: this.props.size.width,
    } : undefined;

    return (
      <div
        className={classNames({
          [styles.icon]: true,
          [styles[`icon--color-${_.kebabCase(this.props.color)}`]]: this.props.color,
          [styles[`icon--svg-${_.kebabCase(this.props.svg)}`]]: this.props.svg,
        })}
        style={style}>
        <SVG
          style={style}
          styleName="icon__svg"
        />
      </div>
    );
  }
}

export default withStyles(styles)(Icon);
