import _ from 'lodash';

export function formatUnitName(unit: { name: string | null }) {
  if (!unit.name) return '';
  if (unit.name.match(' ')) return unit.name;   // Don't add leading '#' for cases like "Suite 234"
  return `${_.toUpper(unit.name.replace(/#/g, ''))}`;
}

export function formatShortAddress(addressUnit: {
  address: {
    streetAddress1: string;
  };
  name: string | null;
}) {
  return `${addressUnit.address.streetAddress1}${addressUnit.name ? ` ${formatUnitName(addressUnit)}` : ''}`;
}

export function formatFullAddress(addressUnit: {
  address: {
    city: string;
    state: string;
    streetAddress1: string;
    zipCode: string;
  };
  name: string | null;
}) {
  return `${addressUnit.address.streetAddress1}${addressUnit.name ? ` ${formatUnitName(addressUnit)}` : ''}, ${addressUnit.address.city || ''}, ${addressUnit.address.state} ${addressUnit.address.zipCode || ''}`;
}
