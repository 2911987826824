// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2dAONe{list-style:none;padding:0}", ""]);
// Exports
exports.locals = {
	"accordion": "_2dAONe"
};
module.exports = exports;
