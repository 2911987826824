// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._YXrApB{height:32px}._YXrApB button{-webkit-box-align:center;align-items:center;border:none;cursor:default;display:-webkit-box;display:flex;height:100%;-webkit-box-pack:center;justify-content:center;padding:0 8px;position:relative;-webkit-transition:all .2s cubic-bezier(.215,.61,.355,1);transition:all .2s cubic-bezier(.215,.61,.355,1);-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}._YXrApB button:first-child{border:1px solid hsla(0,0%,100%,.5);border-radius:3px;color:#fff;font-size:12px;margin-right:8px;opacity:0;padding:6px 8px;text-transform:uppercase;-webkit-transform:translateX(10px);transform:translateX(10px);-webkit-transition:all .2s cubic-bezier(.215,.61,.355,1);transition:all .2s cubic-bezier(.215,.61,.355,1);visibility:hidden}._YXrApB button:first-child svg{height:14px;margin-left:8px;width:14px}._YXrApB button:first-child._BX06oG:hover{border:1px solid #fff}._YXrApB button._BX06oG{cursor:pointer;opacity:1;-webkit-transform:translateX(0);transform:translateX(0);visibility:visible}._YXrApB button:active{-webkit-transform:scale(.95);transform:scale(.95)}._YXrApB button span{background:#fff;border-radius:3px;bottom:-30px;color:#000;font-size:14px;opacity:0;padding:2px 4px;position:absolute;-webkit-transform:translateY(-5px) scale(.5);transform:translateY(-5px) scale(.5);-webkit-transition:all .2s cubic-bezier(.215,.61,.355,1);transition:all .2s cubic-bezier(.215,.61,.355,1);visibility:hidden;white-space:nowrap}._YXrApB button:hover span{opacity:1;-webkit-transform:translateY(0) scale(1);transform:translateY(0) scale(1);visibility:visible}._YXrApB button:last-child{margin-right:-8px;padding-left:2px}._YXrApB button:last-child svg{color:#fff;height:30px;width:30px}._YXrApB:hover ._BX06oG{opacity:.5}._YXrApB:hover ._BX06oG:hover{opacity:1}", ""]);
// Exports
exports.locals = {
	"photo-options-bar": "_YXrApB",
	"active": "_BX06oG"
};
module.exports = exports;
