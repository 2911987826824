import ORIENT from '../constants/orientation';
export default function labelTransform(_ref) {
  var labelOffset = _ref.labelOffset,
      labelProps = _ref.labelProps,
      orientation = _ref.orientation,
      range = _ref.range,
      tickLabelFontSize = _ref.tickLabelFontSize,
      tickLength = _ref.tickLength;
  var sign = orientation === ORIENT.left || orientation === ORIENT.top ? -1 : 1;
  var x;
  var y;
  var transform;

  if (orientation === ORIENT.top || orientation === ORIENT.bottom) {
    var yBottomOffset = orientation === ORIENT.bottom && typeof labelProps.fontSize === 'number' ? labelProps.fontSize : 0;
    x = (Number(range[0]) + Number(range[range.length - 1])) / 2;
    y = sign * (tickLength + labelOffset + tickLabelFontSize + yBottomOffset);
  } else {
    x = sign * ((Number(range[0]) + Number(range[range.length - 1])) / 2);
    y = -(tickLength + labelOffset);
    transform = "rotate(" + sign * 90 + ")";
  }

  return {
    x: x,
    y: y,
    transform: transform
  };
}