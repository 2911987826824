// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._vqQ6DW{display:-webkit-box;display:flex;min-height:100vh;overflow:hidden;width:100%}._vqQ6DW ._RnEMN1{-webkit-box-align:center;align-items:center;background-color:#e4f6fe;display:-webkit-box;display:flex;-webkit-box-flex:45;flex:45;height:100vh;-webkit-box-pack:center;justify-content:center;position:relative;width:45%}@media screen and (max-width:1127px){._vqQ6DW ._RnEMN1{display:none}}._vqQ6DW ._krP92e{background-color:#fff;box-sizing:border-box;display:-webkit-box;display:flex;-webkit-box-flex:55;flex:55;-webkit-box-pack:end;justify-content:flex-end;width:55%}._vqQ6DW ._krP92e ._QDoWnz{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;margin:0 60px 0 0;max-width:400px;text-align:center;width:100%}@media screen and (max-width:1127px){._vqQ6DW ._krP92e ._QDoWnz{margin:0;padding:0 16px}}@media screen and (max-width:1127px){._vqQ6DW ._krP92e{-webkit-box-pack:center;justify-content:center}}._1qA6EQ{position:absolute;right:28px;top:120px}._APRxJw{left:68px;position:absolute;top:600px}", ""]);
// Exports
exports.locals = {
	"auth-page": "_vqQ6DW",
	"auth-page__background": "_RnEMN1",
	"auth-page__view": "_krP92e",
	"auth-page__view__children": "_QDoWnz",
	"brand-shapes-top": "_1qA6EQ",
	"brand-shapes-bottom": "_APRxJw"
};
module.exports = exports;
