// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._99vKDm{border-radius:4px;height:694px;overflow:hidden;width:100%}", ""]);
// Exports
exports.locals = {
	"map-view": "_99vKDm"
};
module.exports = exports;
