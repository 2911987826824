export {
  Colors as ActionColors,
  Icons as ActionIcons,
} from '~tools/react/components/Button/enums';
export {
  Colors as TagColors,
  Icons as TagIcons,
} from '~tools/react/components/Tag/enums';
export { Themes } from '~tools/react/hocs/withTheme/enums';

export { Colors as AlertColors } from './components/CardAlert/enums';
export { Icons as AlertIcons } from './components/CardAlert/enums';

export enum BackgroundColors {
  Transparent = 'TRANSPARENT',
  White = 'WHITE',
}

export enum Colors {
  BrandLitePlan = 'BRAND_LITE_PLAN',
  BrandLeaseholderPlan = 'BRAND_LEASEHOLDER_PLAN',
  BrandBasicPlan = 'BRAND_BASIC_PLAN',
  BrandAdvancedPlan = 'BRAND_ADVANCED_PLAN',
  BrandEnterprisePlan = 'BRAND_ENTERPRISE_PLAN',
  Error = 'ERROR',
  Warning = 'WARNING',
}

export enum OverflowValues {
  Hidden = 'HIDDEN',
}

export enum Shadows {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  None = 'NONE',
  Small = 'SMALL',
}

export enum Widths {
  Auto = 'AUTO',
  Full = 'FULL',
}
