import _ from 'lodash';
import moment from 'moment';
import React, { ComponentType, Fragment, PureComponent } from 'react';

import withAcceptApplication, { AcceptApplicationProps } from '~tools/react/graphql/mutations/applications/withAcceptApplication';
import withGenerateLeaseAgreement, { GenerateLeaseAgreementProps } from '~tools/react/graphql/mutations/documents/withGenerateLeaseAgreement';
import withGenerateSubletAgreement, { GenerateSubletAgreementProps } from '~tools/react/graphql/mutations/documents/withGenerateSubletAgreement';
import withRejectApplication, { RejectApplicationProps } from '~tools/react/graphql/mutations/applications/withRejectApplication';
import withUpdateLease, { UpdateLeaseProps } from '~tools/react/graphql/mutations/leases/withUpdateLease';
import withQuery from '~tools/react/graphql/withQuery';
import { compose } from '~tools/react/hocs/utils';

import { ApplicationStatuses, Document, PaymentSubscriptionPlans, QualificationTypes } from '~tools/types/graphqlSchema';

import { formatAsUSD } from '~tools/utils/string';

import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import CreditScore from '~tools/react/components/CreditScore';
import GenericLoadingVisual from '~tools/react/components/GenericLoadingVisual';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Tag from '~tools/react/components/Tag';
import Text from '~tools/react/components/Text';
import Tooltip from '~tools/react/components/Tooltip';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import HelloSignModal from '~tools/react/containers/HelloSignModal';

import query from './ReviewStep.gql';
import { QueryProps, QueryResponse, QueryVariables } from './types';

interface InputProps {
  applicationUuid: string;
  documentUuid?: string;
  isSubscriber: boolean;
  onOpenQualificationsModal: () => void;
  onSigningComplete: () => void;
}

type Props = QueryProps &
             InputProps &
             AcceptApplicationProps &
             GenerateLeaseAgreementProps &
             GenerateSubletAgreementProps &
             RejectApplicationProps &
             UpdateLeaseProps;

interface State {
  isAccepting: boolean;
  isCustomSubleaseModalOpen: boolean;
  isRejecting: boolean;
  isSigningModalOpen: boolean;
}

class ReviewStep extends PureComponent<Props, State> {
  state: State = {
    isAccepting: false,
    isCustomSubleaseModalOpen: true,
    isRejecting: false,
    isSigningModalOpen: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.documentUuid !== this.props.documentUuid) this.props.refetch();
  }

  handleClickReject = async () => {
    const application = this.props.application;
    if (!application) return;

    this.setState({ isRejecting: true });
    await this.props.rejectApplication(application.uuid);
    await this.props.refetch();
    this.setState({ isRejecting: false });
  }

  handleClickAcceptAndSign = async () => {
    const application = this.props.application;
    if (!application) return;

    const lease = application.lease;
    this.setState({ isAccepting: true });

    if (!lease.document) {
      const paymentSubscription = this.props.activePaymentSubscription;

      let generatedDoc: Document;
      if (paymentSubscription?.plan === PaymentSubscriptionPlans.LEASEHOLDER) {
        generatedDoc = await this.props.generateSubletAgreement(application.uuid, {
          leaseEndsAt: lease.endDate,
          leaseStartsAt: lease.startDate,
          rentInCents: lease.rentInCents,
          sublessee: lease.lessee.fullName,
          sublessor: lease.lessor.fullName,
        });
      } else {
        generatedDoc = await this.props.generateLeaseAgreement(application.uuid, {
          leaseEndsAt: lease.endDate,
          leaseStartsAt: lease.startDate,
          rentInCents: lease.rentInCents,
          lessee: lease.lessee.fullName,
          lessor: lease.lessor.fullName,
        });
      }

      await this.props.updateLease(lease.uuid, {
        documentUuid: generatedDoc.uuid,
      });

      await this.props.refetch();
    }

    this.setState({ isSigningModalOpen: true });
  }

  handleSignedDocument = async () => {
    this.setState({ isSigningModalOpen: false });
    this.props.onSigningComplete();
    await this.props.acceptApplication(this.props.applicationUuid);
    await this.props.refetch();
  }

  handleCloseSigningModal = () => {
    this.setState({
      isSigningModalOpen: false,
      isAccepting: false,
    });
  }

  render() {
    const application = this.props.application;
    const applicant = application?.applicants[0];

    if (!application || !applicant || this.props.isLoading) {
      return (
        <Card.CardSection title="Lease details">
          <GenericLoadingVisual />
        </Card.CardSection>
      );
    }

    const lease = application.lease;
    const filteredRequirements = _.filter(application.listing.resolvedRentalPolicy?.requirements, (requirement) => {
      return _.includes([QualificationTypes.ASSETS, QualificationTypes.INCOME], requirement.qualification.type);
    });
    const primaryIncomeSource = applicant.incomeSources.length ?
      _.orderBy(
        applicant.incomeSources,
        ['verifiedAt', 'annualIncome'],
        ['asc', 'desc']
      )[0] :
      null;
    const creditReport = applicant.activeCreditReport;

    return (
      <Fragment>
        {lease.document ? (
          <HelloSignModal
            documentUuid={lease.document.uuid}
            isOpen={this.state.isSigningModalOpen}
            onSuccess={this.handleSignedDocument}
            onCancel={this.handleCloseSigningModal}
          />
        ) : null}
        <Card.CardSection>
          <Row>
            <div>
              <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                <Heading
                  content={`${applicant.firstName}'s employment`}
                  font={Heading.enums.Fonts.Secondary}
                  priority={Heading.enums.Priorities.Three}
                  size={Heading.enums.Sizes.XXSmall}
                />
                {primaryIncomeSource && primaryIncomeSource.verifiedAt ? (
                  <Fragment>
                    <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                    <Tooltip text={`Caretaker has verified ${applicant.firstName}'s employment`} position={Tooltip.enums.Positions.Up}>
                      <Tag label="Verified" color={Tag.enums.Colors.Green} />
                    </Tooltip>
                  </Fragment>
                ) : null}
                {primaryIncomeSource && !primaryIncomeSource.verifiedAt ? (
                  <Fragment>
                    <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                    <Tooltip text={`${applicant.firstName}'s employment has not been verified yet`} position={Tooltip.enums.Positions.Up}>
                      <Tag label="Unverified" />
                    </Tooltip>
                  </Fragment>
                ) : null}
              </Row>
              {primaryIncomeSource && filteredRequirements.length > 0 ? (
                <Fragment>
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                  <Text
                    color={Text.enums.Colors.Info}
                    content="See details"
                    isEmphasized
                    hasHoverStyles
                    onClick={this.props.onOpenQualificationsModal}
                    size={Text.enums.Sizes.Small}
                  />
                </Fragment>
              ) : null}
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              {primaryIncomeSource ? (
                <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                  <div style={{ minWidth: '145px' }}>
                    <Text
                      color={Text.enums.Colors.Secondary}
                      content="Employer"
                      size={Text.enums.Sizes.Medium}
                    />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                    <Text
                      color={Text.enums.Colors.Secondary}
                      content="Position"
                      size={Text.enums.Sizes.Medium}
                    />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                    <Text
                      color={Text.enums.Colors.Secondary}
                      content="Annual salary"
                      size={Text.enums.Sizes.Medium}
                    />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                    <Text
                      color={Text.enums.Colors.Secondary}
                      content="Start date"
                      size={Text.enums.Sizes.Medium}
                    />
                  </div>
                  <div>
                    <Text
                      content={primaryIncomeSource.company}
                      size={Text.enums.Sizes.Medium}
                    />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                    <Text
                      content={primaryIncomeSource.jobTitle}
                      size={Text.enums.Sizes.Medium}
                    />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                    <Text
                      content={`$${formatAsUSD(primaryIncomeSource.annualIncome)} USD`}
                      size={Text.enums.Sizes.Medium}
                    />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                    <Text
                      content={moment(primaryIncomeSource.startDate).format('MMM DD, YYYY')}
                      size={Text.enums.Sizes.Medium}
                    />
                  </div>
                </Row>
              ) : (
                <Text content={`${applicant.firstName} has no employment on record`} />
              )}
            </div>
            <div>
              <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                <Heading
                  content={`${applicant.firstName}'s background`}
                  font={Heading.enums.Fonts.Secondary}
                  priority={Heading.enums.Priorities.Three}
                  size={Heading.enums.Sizes.XXSmall}
                />
                {creditReport ? (
                  <Fragment>
                    <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                    <Tooltip text="Caretaker has verified this applicant's credit and background" position={Tooltip.enums.Positions.Up}>
                      <Tag label="Verified" color={Tag.enums.Colors.Green} />
                    </Tooltip>
                  </Fragment>
                ) : null}
              </Row>
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              {creditReport ? (
                <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                  <div style={{ minWidth: '145px' }}>
                    <Text
                      color={Text.enums.Colors.Secondary}
                      content="Credit score"
                      size={Text.enums.Sizes.Medium}
                    />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                    <Text
                      color={Text.enums.Colors.Secondary}
                      content="Criminal records"
                      size={Text.enums.Sizes.Medium}
                    />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                    <Text
                      color={Text.enums.Colors.Secondary}
                      content="Eviction records"
                      size={Text.enums.Sizes.Medium}
                    />
                  </div>
                  <div style={{ minWidth: '200px' }}>
                    {creditReport.creditScore ? (
                      <CreditScore score={creditReport.creditScore} />
                    ) : (
                      <Text
                        content="Unavailable"
                        size={Text.enums.Sizes.Medium}
                      />
                    )}
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                    {creditReport.hasCriminalRecords !== null ? (
                      <Row flexBehavior={Row.enums.FlexBehaviors.Shrink}>
                        <Tag
                          color={creditReport.hasCriminalRecords ? Tag.enums.Colors.Red : Tag.enums.Colors.Green}
                          label={creditReport.hasCriminalRecords ? 'Record found' : 'Clean'}
                          style={Tag.enums.Styles.Primary}
                        />
                      </Row>
                    ) : (
                      <Text
                        content="Unavailable"
                        size={Text.enums.Sizes.Medium}
                      />
                    )}
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                    {creditReport.hasEvictionRecords !== null ? (
                      <Row flexBehavior={Row.enums.FlexBehaviors.Shrink}>
                        <Tag
                          color={creditReport.hasEvictionRecords ? Tag.enums.Colors.Red : Tag.enums.Colors.Green}
                          label={creditReport.hasEvictionRecords ? 'Record found' : 'Clean'}
                          style={Tag.enums.Styles.Primary}
                        />
                      </Row>
                    ) : (
                      <Text
                        content="Unavailable"
                        size={Text.enums.Sizes.Medium}
                      />
                    )}
                  </div>
                </Row>
              ) : (
                <Text content={`${applicant.firstName} has not submitted any background data`} />
              )}
            </div>
          </Row>
        </Card.CardSection>
        <Card.CardSection>
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
              isDisabled={_.includes([ApplicationStatuses.REJECTED, ApplicationStatuses.WITHDRAWN], application.status)}
              isLoading={this.state.isAccepting}
              label={this.props.isSubscriber ? 'Accept and sign the lease' : 'Accept and sign the lease'}
              onClick={this.handleClickAcceptAndSign}
              size={Button.enums.Sizes.Small}
            />
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
            <Button
              color={Button.enums.Colors.Red}
              isDisabled={_.includes([ApplicationStatuses.REJECTED, ApplicationStatuses.WITHDRAWN], application.status)}
              isLoading={this.state.isRejecting}
              label="Reject"
              onClick={this.handleClickReject}
              size={Button.enums.Sizes.Small}
              style={Button.enums.Styles.Outline}
            />
          </div>
        </Card.CardSection>
      </Fragment>
    );
  }

  toggleCustomSubleaseModal = () => {
    this.setState({ isCustomSubleaseModalOpen: !this.state.isCustomSubleaseModalOpen });
  }
}

export default compose(
  withQuery<InputProps, QueryResponse, QueryVariables, QueryProps>(query, {
    options: (props) => ({
      variables: { applicationUuid: props.applicationUuid },
    }),
    props: (props) => ({
      activePaymentSubscription: props.data?.viewer?.activePaymentSubscription ?? null,
      application: props.data?.viewer?.application ?? null,
      isLoading: props.loading,
      refetch: props.refetch,
      viewerRole: props.data?.viewer?.role ?? null,
    }),
  }),
  withAcceptApplication,
  withGenerateLeaseAgreement,
  withGenerateSubletAgreement,
  withRejectApplication,
  withUpdateLease,
)(ReviewStep) as ComponentType<InputProps>;
