import _ from 'lodash';
import React, { PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Text from '~tools/react/components/Text';
import Tooltip from '~tools/react/components/Tooltip';

import Info from '~tools/svgs/icons/interface/info.svg';

import BathSvg from './svgs/bath.svg';
import BedSvg from './svgs/bed.svg';
import FullyFurnishedSvg from './svgs/fully-furnished.svg';
import PartiallyFurnishedSvg from './svgs/partially-furnished.svg';
import UnfurnishedSvg from './svgs/unfurnished.svg';
import UnitTypeSvg from './svgs/unit-type.svg';

import styles from './RoomsIcon.scss';

import * as enums from './enums';

const iconToComponent = {
  [enums.Icons.Bath]: BathSvg,
  [enums.Icons.Bed]: BedSvg,
  [enums.Icons.FullyFurnished]: FullyFurnishedSvg,
  [enums.Icons.PartiallyFurnished]: PartiallyFurnishedSvg,
  [enums.Icons.Unfurnished]: UnfurnishedSvg,
  [enums.Icons.UnitType]: UnitTypeSvg,
};

interface Props {
  icon: enums.Icons;
  label: string;
  tooltipText?: string;
}

class RoomsIcon extends PureComponent<Props> {
  static enums = enums;

  render() {
    const Icon = iconToComponent[this.props.icon];

    return (
      <div styleName="rooms-icon">
        <Icon styleName="rooms-icon__svg" />
        <Text
          size={Text.enums.Sizes.Medium}
          content={this.props.label}
        />
        {this.props.tooltipText ? (
          <div styleName="rooms-icon__info">
            <Tooltip
              text={this.props.tooltipText}
              position={Tooltip.enums.Positions.Down}
              length={Tooltip.enums.Lengths.Medium}>
              <Info />
            </Tooltip>
          </div>
        ) : null}
      </div>
    );
  }
}

const StyledRoomsIcon = withStyles(styles)(RoomsIcon);
export default _.assign(StyledRoomsIcon, { enums });
