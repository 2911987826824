import React, { Fragment, ReactNode } from 'react';

import Button from '~tools/react/components/Button';
import Heading from '~tools/react/components/Heading';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './UnitDetailsOverlay.scss';

interface Props {
  children: ReactNode;
  isArchived: boolean;
  isOverlayOpen: boolean;
  listingUuid: string;
}

function UnitDetailsOverlay(props: Props) {
  return (
    <div styleName="unit-details-view">
      {props.isOverlayOpen || props.isArchived ? (
        <div styleName="unit-details-view__overlay">
          <Heading
            font={Heading.enums.Fonts.Secondary}
            size={Heading.enums.Sizes.XSmall}
            content={props.isArchived ? 'This unit has been archived' : 'Set up your listing'}
          />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
          <Text
            size={Text.enums.Sizes.Large}
            content={props.isArchived ?
              'These listing details can no longer be edited.' :
              'Finish adding the details about this unit so you can begin marketing it.'
            }
          />
          {!props.isArchived ? (
            <Fragment>
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              <Button
                label="Get started"
                link={{
                  path: `/listings/${props.listingUuid}/rooms`,
                }}
              />
            </Fragment>
          ) : undefined}
        </div>
      ) : null}
      {props.children}
    </div>
  );
}

export default withStyles(styles)(UnitDetailsOverlay);
