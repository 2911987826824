import { ApolloQueryResult } from 'apollo-client';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { DocumentTags } from '~tools/types/graphqlSchema';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withRequestSupport, { RequestSupportProps } from '~tools/react/graphql/mutations/threads/withRequestSupport';

import { formatAsUSD } from '~tools/utils/string';

import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Card from '~tools/react/components/Card';
import { Action } from '~tools/react/components/Card/types';
import { AlertColors } from '~tools/react/components/Card/enums';
import ClaimModal from '~tools/react/containers/ClaimModal';
import GenericLoadingVisual from '~tools/react/components/GenericLoadingVisual';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import Table from '~tools/react/components/Table';
import Tag from '~tools/react/components/Tag';
import Thread from '~tools/react/containers/Thread';
import ThreadSupportModal from '~tools/react/containers/Thread/components/ThreadSupportModal';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import DataRow from '~web-manage/lib/common/components/DataRow';
import TenantQualificationsModal from '~web-manage/lib/common/containers/TenantQualificationsModal';
import { formatFullAddress } from '~web-manage/lib/common/utils/addressUnit';

import RentPaymentsCard from './containers/RentPaymentsCard';

import query from './Lease.gql';

import { Lease as LeaseType } from './types';

type Props =
  QueryProps &
  InputProps &
  RequestSupportProps;

interface State {
  isClaimModalOpen: boolean;
  isQualificationsModalOpen: boolean;
  isSupportModalOpen: boolean;
  selectedClaimUuid?: string;
  isRequestSupportBannerShown: boolean;
}

class Lease extends PureComponent<Props, State> {
  state: State = {
    isClaimModalOpen: false,
    isQualificationsModalOpen: false,
    isSupportModalOpen: false,
    isRequestSupportBannerShown: false,
  }

  handleCloseClaimModal = () => {
    this.props.refetch();
    this.setState({ isClaimModalOpen: false });
  }

  handleOpenClaimModal = () => {
    this.setState({ isClaimModalOpen: true });
  }

  handleClickClaim = (uuid?: string) => {
    if (!uuid) return;

    this.setState({
      isClaimModalOpen: true,
      selectedClaimUuid: uuid,
    });
  }

  handleDownloadClick = () => {
    const document = this.props.lease?.document;
    if (!document) return;
    let docUrl = document.eSignedUrl;
    if (!docUrl && document.tag === DocumentTags.UPLOAD && document.upload) {
      docUrl = document.upload.url;
    } else if (!docUrl) {
      docUrl = document.accessUrl;
    }
    window.open(docUrl, '_blank');
  };

  handleToggleSupportModal = () => {
    this.setState({ isSupportModalOpen: !this.state.isSupportModalOpen });
  }

  handleClickHelp = async () => {
    this.setState({ isSupportModalOpen: false });
    const thread = this.props.lease?.application?.thread || this.props.lease?.thread;
    if (!thread) return;

    await this.props.requestSupport(thread.uuid);
  }

  handleCloseRequestSupportBanner = () => {
    this.setState({ isRequestSupportBannerShown: false });
  }

  render() {
    const lease = this.props.lease;
    if (this.props.isLoading || !lease) {
      return (
        <ManageStage>
          <GenericLoadingVisual />
        </ManageStage>
      );
    }

    const securityDeposit = lease.securityDeposits[0];
    const alert = this.getAlert();

    const cardActions: Action[] = [
      {
        label: 'View unit',
        link: {
          path: `/units/${lease.addressUnit.viewerPropertyManagerContract?.uuid}`,
        },
      },
    ];

    if (lease.application) {
      cardActions.push({
        label: 'View tenant qualifications',
        onClick: this.toggleQualificationsModal,
      });
    }

    if (lease.document) {
      cardActions.unshift({
        label: 'View lease document',
        onClick: this.handleDownloadClick,
      });
    }

    return (
      <ManageStage>
        <ThreadSupportModal
          isOpen={this.state.isSupportModalOpen}
          hideRequestHelp={!!(lease.application?.thread.supportRequestedAt || lease.thread?.supportRequestedAt)}
          onClickRequestHelp={this.handleClickHelp}
          onClose={this.handleToggleSupportModal}
        />
        {securityDeposit ? (
          <ClaimModal
            isOpen={this.state.isClaimModalOpen}
            securityDepositUuid={securityDeposit.uuid}
            claimUuid={this.state.selectedClaimUuid}
            onClose={this.handleCloseClaimModal}
          />
        ) : null}
        {lease.application ? (
          <TenantQualificationsModal
            applicationUuid={lease.application.uuid}
            isOpen={this.state.isQualificationsModalOpen}
            onClose={this.toggleQualificationsModal}
          />
        ) : null}
        <Breadcrumbs
          items={[
            { path: '/leases', label: 'All leases' },
            { label: lease.lessee.fullName },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
        <Card
          header={{
            actions: cardActions,
            title: lease.lessee.fullName,
            subtitle: formatFullAddress(lease.addressUnit),
          }}>
          {alert ? (
            <Card.CardAlert
              color={alert.color}
              message={alert.message}
              action={alert.action}
            />
          ) : null}
          <Card.CardSection title="Lease details">
            <DataRow>
              <DataRow.DataRowItem
                color={lease.nextRentPaymentMonthStarting ? undefined : DataRow.DataRowItem.enums.Colors.Success}
                label="Next payment"
                description={this.getNextPaymentText()}
              />
              <DataRow.DataRowItem
                color={DataRow.DataRowItem.enums.Colors.Success}
                label="Monthly rent"
                description={`$${formatAsUSD(lease.rentInCents / 100)}`}
              />
              <DataRow.DataRowItem
                color={DataRow.DataRowItem.enums.Colors.Success}
                label="Security deposit"
                description={`$${formatAsUSD(lease.securityDepositInCents / 100)}`}
              />
              <DataRow.DataRowItem
                label="Start date"
                description={moment(lease.startDate).format('MMM DD, YYYY')}
              />
              <DataRow.DataRowItem
                label="End date"
                description={moment(lease.endDate).format('MMM DD, YYYY')}
              />
            </DataRow>
          </Card.CardSection>
        </Card>
        <RentPaymentsCard
          leaseUuid={lease.uuid}
        />
        {securityDeposit ? (
          <Card
            header={{
              actions: [{
                icon: Card.enums.ActionIcons.Plus,
                label: 'Report damages',
                onClick: this.handleOpenClaimModal,
              }],
              subtitle: 'File claims if there is any unforeseen damage to the property. All reports are manually reviewed by Caretaker.',
              title: 'Security deposit',
            }}>
            <Card.CardSection spacing={Card.CardSection.enums.Spacing.None}>
              <Table>
                <Table.TableHead>
                  <Table.TableHeader>Amount</Table.TableHeader>
                  <Table.TableHeader>Description</Table.TableHeader>
                  <Table.TableHeader>Status</Table.TableHeader>
                </Table.TableHead>
                {securityDeposit.claims.length > 0 ? (
                  securityDeposit.claims.map((claim) => {
                    let status = 'PENDING';
                    if (claim.rejectedAt) status = 'REJECTED';
                    if (claim.approvedAt) status = 'APPROVED';
                    return (
                      <Table.TableRow id={claim.uuid} onClick={this.handleClickClaim} key={claim.uuid}>
                        <Table.TableData>
                          ${formatAsUSD(claim.amountInCents / 100)}
                        </Table.TableData>
                        <Table.TableData>
                          {claim.description}
                        </Table.TableData>
                        <Table.TableData>
                          <Tag
                            label={status}
                            color={Tag.enums.Colors.Blue}
                          />
                        </Table.TableData>
                      </Table.TableRow>
                    );
                  })
                ) : (
                  <Table.TableEmptyState label="There are no claims" />
                )}
              </Table>
            </Card.CardSection>
          </Card>
        ) : null}
        <Card
          header={{
            actions: [{
              label: Thread.supportRequestedAt ?
                'Support requested' :
                'Request support',
              onClick: this.handleToggleSupportModal,
              isDisabled: !!Thread.supportRequestedAt,
              icon: Thread.supportRequestedAt ?
                Card.enums.ActionIcons.Check :
                undefined,
            }],
            title: 'Activity',
            subtitle: 'Updates and messages between you and your tenant',

          }}>
          {this.state.isRequestSupportBannerShown ? (
            <>
              <Card.CardAlert
                action={{
                  label: '',
                  icon: Card.enums.ActionIcons.Cross,
                  onClick: this.handleCloseRequestSupportBanner,
                }}
                color={Card.CardAlert.enums.Colors.Blue}
                message='Our support team is standing by to help TODO- COPY HERE.'
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
              <HorizontalRule />
            </>
          ) : null}
          <div style={{ display: 'flex', height: '500px' }}>
            <Thread
              uuid={lease.application?.thread.uuid || lease.thread?.uuid}
              isMetadataDisabled
              supportRequestedAt={Thread.supportRequestedAt}
            />
          </div>
        </Card>

      </ManageStage>
    );
  }

  getAlert = (): { message: string, action?: Action, color?: AlertColors } | null => {
    const lease = this.props.lease;
    if (!lease) return null;

    const isOverdue = lease.nextRentPaymentMonthStarting &&
      moment(lease.nextRentPaymentMonthStarting).isBefore(moment());

    if (isOverdue) {
      return {
        color: AlertColors.Red,
        message: `${lease.lessee.firstName}'s rent payment is overdue.`,
      };
    }

    return null;
  }

  getNextPaymentText = () => {
    const lease = this.props.lease;
    if (!lease) return '';
    if (lease.nextRentPaymentMonthStarting) {
      return `$${formatAsUSD(lease.nextRentPaymentAmountInCents / 100)} on ${moment(lease.nextRentPaymentMonthStarting).format('MMM D, YYYY')}`;
    }
    return 'Payments complete';
  }

  toggleQualificationsModal = () => {
    this.setState({ isQualificationsModalOpen: !this.state.isQualificationsModalOpen });
  }
}

interface Response {
  viewer: {
    uuid: string;
    lease: LeaseType | null;
  } | null;
}

interface QueryProps {
  lease: LeaseType | null;
  isLoading: boolean;
  refetch: () => Promise<ApolloQueryResult<Response>>;
}

interface Variables {
  leaseUuid: string;
}

type InputProps = RouteComponentProps<{ leaseUuid: string }>;

export default compose(
  withRequestSupport,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: props => ({
      ssr: false,
      skip: !props.match.params.leaseUuid,
      variables: {
        uuid: props.match.params.leaseUuid,
      },
    }),
    props: props => ({
      lease: props.data?.viewer?.lease ?? null,
      isLoading: props.loading,
      refetch: props.refetch,
    }),
  }),
)(Lease);
