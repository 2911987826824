import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Globals from '~web-core/lib/common/globals';

import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import BrandShapesBottom from './svgs/brand-shapes-bottom.svg';
import BrandShapesTop from './svgs/brand-shapes-top.svg';

import styles from './AuthView.scss';

interface Props {
  children: ReactNode;
}

function AuthView(props: Props) {
  return (
    <div styleName="auth-page">
      <div styleName="auth-page__view">
        <div styleName="auth-page__view__children">
          {props.children}
          <div style={{ margin: 'auto auto 0 auto' }}>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXLarge} />
            <Row
              isColumnOnMobile={false}
              flexBehavior={Row.enums.FlexBehaviors.Default}>
              <Text
                color={Text.enums.Colors.Secondary}
                content="Home"
                hasHoverStyles
                isEmphasized
                link={{
                  path: Globals.WEB_CORE_DOMAIN,
                }}
              />
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
              <Text
                color={Text.enums.Colors.Secondary}
                content="·"
              />
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
              <Text
                color={Text.enums.Colors.Secondary}
                content="Support"
                hasHoverStyles
                isEmphasized
                link={{
                  path: `${Globals.WEB_CORE_DOMAIN}/support`,
                }}
              />
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
              <Text
                color={Text.enums.Colors.Secondary}
                content="·"
              />
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
              <Text
                color={Text.enums.Colors.Secondary}
                content="Terms"
                hasHoverStyles
                isEmphasized
                link={{
                  path: `${Globals.WEB_CORE_DOMAIN}/terms`,
                }}
              />
            </Row>
          </div>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
        </div>
      </div>
      <div styleName="auth-page__background">
        <BrandShapesTop styleName="brand-shapes-top" />
        <BrandShapesBottom styleName="brand-shapes-bottom" />
      </div>
    </div>
  );
}

export default withStyles(styles)(AuthView);
