import React, { PureComponent } from 'react';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withUpdateListing, { UpdateListingProps } from '~tools/react/graphql/mutations/listings/withUpdateListing';

import Button from '~tools/react/components/Button';
import Form, { Input } from '~tools/react/components/Form';
import ThemedModal from '~tools/react/components/ThemedModal';

import query from './UpdatePricingModal.gql';

interface InputProps {
  isOpen: boolean;
  listingUuid: string;
  onClose: () => void;
}

type Props = InputProps & QueryProps & UpdateListingProps;

class UpdatePricingModal extends PureComponent<Props> {
  handleSubmit = async (data: {
    rentInDollars: number;
    securityDepositInDollars: number;
  }) => {
    await this.props.updateListing(this.props.listingUuid, {
      defaultSecurityDepositInCents: data.securityDepositInDollars * 100,
      rentInCents: data.rentInDollars * 100,
    });
    this.props.onClose();
  };

  render() {
    const listing = this.props.listing;
    return (
      <ThemedModal
        width={ThemedModal.enums.Widths.Small}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title="Pricing">
        <ThemedModal.ThemedModalSection>
          <Form onSubmit={this.handleSubmit}>
            <Input
              label="Monthly rent"
              labelFormat={Input.enums.LabelFormats.Stacked}
              name="rentInDollars"
              placeholder="2,500"
              value={listing ? listing.rentInCents / 100 : undefined}
              type={Input.enums.Types.Currency}
            />
            <Input
              label="Security deposit"
              labelFormat={Input.enums.LabelFormats.Stacked}
              name="securityDepositInDollars"
              placeholder="2,500"
              value={listing ? listing.defaultSecurityDepositInCents / 100 : undefined}
              type={Input.enums.Types.Currency}
            />
            <Button
              align={Button.enums.Alignments.Right}
              label="Save"
              size={Button.enums.Sizes.Small}
              type={Button.enums.Types.Submit}
            />
          </Form>
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

interface Listing {
  defaultSecurityDepositInCents: number;
  rentInCents: number;
  updatedAt: string;
  uuid: string;
}

interface Viewer {
  listing: Listing;
  uuid: string;
}

interface Response {
  viewer: Viewer;
}

interface QueryProps {
  isLoading: boolean;
  listing: Listing | null;
}

export default compose(
  withUpdateListing,
  withQuery<InputProps, Response, {}, QueryProps>(query, {
    options: (props) => ({
      variables: {
        listingUuid: props.listingUuid,
      },
      skip: !props.isOpen,
    }),
    props: (props) => ({
      isLoading: props.loading,
      listing: props.data?.viewer.listing || null,
    }),
  }),
)(UpdatePricingModal);
