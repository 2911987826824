import classNames from 'classnames';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import withStyles from '~tools/react/hocs/withStyles';

import { ChevronLeft } from '~web-core/lib/common/svgs/icons/directional';
import Heading from '~tools/react/components/Heading';

import styles from './VerticalMenuHeader.scss';

interface Props {
  backLink?: {
    path: string;
    label: string;
  };
  title: string,
  hasSubheaders: boolean,
}

class VerticalMenuHeader extends PureComponent<Props> {
  render() {
    return (
      <div
        className={classNames({
          [styles['vertical-menu-header']]: true,
          [styles['vertical-menu-header--with-border']]: !this.props.hasSubheaders,
        })}>
        {this.props.backLink ? (
          <Link
            to={this.props.backLink.path}
            styleName="vertical-menu-header__back-link">
            <ChevronLeft />
            {this.props.backLink.label}
          </Link>
        ) : null}
        <Heading
          content={this.props.title}
          size={Heading.enums.Sizes.XSmall}
          font={Heading.enums.Fonts.Secondary}
        />
      </div>
    );
  }
}

export default withStyles(styles)(VerticalMenuHeader);
