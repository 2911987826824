import _ from 'lodash';

import withMutation from '~tools/react/graphql/withMutation';
import { UpdatePaymentSubscriptionInput } from '~tools/types/graphqlSchema';

import { PaymentSubscriptionPlanPricesInCents } from '~tools/constants/workspace';
import analytics, { Events } from '~tools/utils/analytics';

import UpdatePaymentSubscriptionMutation from './updatePaymentSubscription.gql';

interface Response {
  uuid: string;
}

interface Data {
  updatePaymentSubscription: Response;
}

interface Variables {
  input: UpdatePaymentSubscriptionInput;
  uuid: string;
}

export interface UpdatePaymentSubscriptionProps {
  updatePaymentSubscription: (uuid: string, input: UpdatePaymentSubscriptionInput) => Promise<Response>;
}

export default withMutation<{}, Data, Variables, UpdatePaymentSubscriptionProps>(UpdatePaymentSubscriptionMutation, {
  props: props => ({
    updatePaymentSubscription: async (uuid: string, input: UpdatePaymentSubscriptionInput) => {
      const res = await props.mutate({
        variables: {
          input,
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error updating payment subscription.');

      const value = input.plan ? PaymentSubscriptionPlanPricesInCents[input.plan] : undefined;
      analytics.track(Events.UpdatePaymentSubscription, {
        plan: input.plan ? _.startCase(_.toLower(input.plan || '')) : undefined,
        label: input.plan ? _.startCase(_.toLower(input.plan || '')) : undefined,
        quantity: input.quantity,
        value,
      });

      return res.data.updatePaymentSubscription;
    },
  }),
});
