import _ from 'lodash';
import React, { Component } from 'react';
import { ReactCookieProps, withCookies } from 'react-cookie';

import { formatAsUSD } from '~tools/utils/string';

import { PaymentSubscriptionPlans } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withFetchOrCreateAddress, { FetchOrCreateAddressProps } from '~tools/react/graphql/mutations/addresses/withFetchOrCreateAddress';
import withFetchOrCreateAddressUnit, { AddressUnit, FetchOrCreateAddressUnitProps } from '~tools/react/graphql/mutations/addressUnits/withFetchOrCreateAddressUnit';
import withFetchOrCreateListingForPropertyManagerContract, { FetchOrCreateListingForPropertyManagerContractProps } from '~tools/react/graphql/mutations/listings/withFetchOrCreateListingForPropertyManagerContract';
import withCreatePaymentSubscription, { CreatePaymentSubscriptionProps } from '~tools/react/graphql/mutations/paymentSubscriptions/withCreatePaymentSubscription';
import withCreatePropertyManagerContract, { CreatePropertyManagerContractProps } from '~tools/react/graphql/mutations/propertyManagerContracts/withCreatePropertyManagerContract';
import withRegister, { RegisterProps } from '~tools/react/graphql/mutations/accounts/withRegister';
import withAuth, { AuthProps } from '~tools/react/hocs/withAuth';
import withDevice, { DeviceProps } from '~tools/react/hocs/withDevice';

import {
  PaymentSubscriptionPlanFeatureLists as PlanFeatures,
  PaymentSubscriptionPlanNames as PlanNames,
  PaymentSubscriptionPlanPricesInCents as PlanPrices,
} from '~tools/constants/workspace';

import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import Form, { FormField, Input } from '~tools/react/components/Form';
import GeocodeTypeahead from '~tools/react/components/GeocodeTypeahead';
import Heading from '~tools/react/components/Heading';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Receipt from '~tools/react/components/Receipt';
import Row from '~tools/react/components/Row';
import Tag from '~tools/react/components/Tag';
import Text from '~tools/react/components/Text';
import Tooltip from '~tools/react/components/Tooltip';
import UnorderedList from '~tools/react/components/UnorderedList';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import ApartmentNameView from './components/ApartmentNameView';
import ReceiptView from './components/ReceiptView';

import query from './AddUnits.gql';

interface QueryProps {
  propertyManagers: {
    uuid: string;
  }[];
}

interface InputProps {
  paymentSubscriptionPlan: PaymentSubscriptionPlans;
  onContinue: (addressUnits: { [uuid: string]: AddressUnit; }) => void;
}

type Props =
  InputProps &
  QueryProps &
  CreatePropertyManagerContractProps &
  CreatePaymentSubscriptionProps &
  FetchOrCreateAddressProps &
  FetchOrCreateAddressUnitProps &
  FetchOrCreateListingForPropertyManagerContractProps &
  RegisterProps &
  ReactCookieProps &
  AuthProps &
  DeviceProps;

interface State {
  addressUnits: {
    [uuid: string]: AddressUnit;
  };
  googlePlaceId?: string;
  isLoading: boolean;
}

class AddUnits extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      addressUnits: {},
      isLoading: false,
    };
  }

  handleDelete = (addressUnitUuid: string) => {
    const addressUnits = _.clone(this.state.addressUnits);
    delete addressUnits[addressUnitUuid];

    this.setState({ addressUnits });
  };

  handleContinue = () => this.props.onContinue(this.state.addressUnits);

  handleSubmit = async (data: {
    name: string;
  }) => {
    const googlePlaceId = this.state.googlePlaceId;
    if (!googlePlaceId) return;

    this.setState({ isLoading: true });
    try {
      const address = await this.props.fetchOrCreateAddress({ googlePlaceId });
      const addressUnit = await this.props.fetchOrCreateAddressUnit({ addressUuid: address.uuid, name: data.name });

      const addressUnits = _.clone(this.state.addressUnits);
      addressUnits[addressUnit.uuid] = addressUnit;

      this.setState({
        addressUnits,
        googlePlaceId: undefined,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
      throw err;
    }
  };

  handleTypeaheadSubmit = (data: {
    placeId: string;
  }) => {
    this.setState({ googlePlaceId: data.placeId });
  }

  render() {
    const addressUnitUuids = _.keys(this.state.addressUnits);
    const paymentSubscriptionPlan = this.props.paymentSubscriptionPlan;
    const planName = PlanNames[paymentSubscriptionPlan] ?? _.startCase(_.toLower(paymentSubscriptionPlan));
    return (
      <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
        <div>
          <Heading
            content="Add your units"
            size={Heading.enums.Sizes.Large}
          />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          <Text
            content="You can add units now or later. When you finish creating your account, we'll initialize it with units for each of these addresses."
            size={Text.enums.Sizes.Large}
          />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
          <HorizontalRule />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
          <Form onSubmit={this.handleSubmit}>
            <div>
              <Row
                flexBehavior={Row.enums.FlexBehaviors.Grow}
                isColumnOnMobile={false}>
                <div style={{ width: '100%' }}>
                  <GeocodeTypeahead
                    isRequired
                    key={`${addressUnitUuids.length}-street-address-1`}
                    label="Address"
                    name="streetAddress1"
                    onSubmit={this.handleTypeaheadSubmit}
                    placeholder="1 Caretaker Road, New Britain, CT, USA"
                    size={Input.enums.Sizes.Large}
                  />
                </div>
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                <ApartmentNameView>
                  <Input
                    key={`${addressUnitUuids.length}-name`}
                    label="Apt No."
                    labelFormat={Input.enums.LabelFormats.Stacked}
                    name="name"
                    placeholder="2R"
                    size={Input.enums.Sizes.Large}
                  />
                </ApartmentNameView>
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                <div
                  style={{
                    maxWidth: '115px',
                    width: '115px',
                  }}>
                  <FormField
                    labelFormat={FormField.enums.LabelFormats.Stacked}
                    label="&nbsp;">
                    <Button
                      icon={Button.enums.Icons.Plus}
                      isDisabled={!this.state.googlePlaceId}
                      isLoading={this.state.isLoading}
                      label="Add"
                      size={Button.enums.Sizes.Large}
                      type={Button.enums.Types.Submit}
                      width={Button.enums.Widths.Full}
                    />
                  </FormField>
                </div>
              </Row>
            </div>
          </Form>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
          <Card shadow={Card.enums.Shadows.None}>
            {_.keys(this.state.addressUnits).length ? _.map(this.state.addressUnits, addressUnit => (
              <Card.CardSection key={addressUnit.uuid}>
                <Row
                  flexBehavior={Row.enums.FlexBehaviors.Default}
                  isColumnOnMobile={false}
                  verticalAlignment={Row.enums.VerticalAlignments.Center}>
                  <div style={{ marginRight: 'auto' }}>
                    <Row
                      flexBehavior={Row.enums.FlexBehaviors.Default}
                      isColumnOnMobile={false}>
                      <Text
                        content={addressUnit.address.streetAddress1}
                        size={Text.enums.Sizes.Large}
                        isEmphasized
                      />
                      <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                      {addressUnit.name ? (
                        <Tag
                          label={addressUnit.name}
                          color={Tag.enums.Colors.Blue}
                        />
                      ) : null}
                    </Row>
                    <Text
                      content={`${addressUnit.address.city}, ${addressUnit.address.state} ${addressUnit.address.zipCode}`}
                      size={Text.enums.Sizes.Medium}
                    />
                  </div>
                  <Button
                    color={Button.enums.Colors.Red}
                    icon={Button.enums.Icons.Cross}
                    label="Delete"
                    onClick={() => this.handleDelete(addressUnit.uuid)}
                    size={Button.enums.Sizes.Small}
                    style={Button.enums.Styles.Outline}
                  />
                </Row>
              </Card.CardSection>
            )) : (
              <Card.CardSection>
                <Text
                  color={Text.enums.Colors.Secondary}
                  content="Add at least one unit address to get started."
                  isEmphasized
                  size={Text.enums.Sizes.Large}
                />
              </Card.CardSection>
            )}
          </Card>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXLarge} />
          <HorizontalRule />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
          <Text
            content="Not ready to add everything yet? You'll be able to add or remove units from your account at any point in the future."
          />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
          <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
            <Button
              align={Button.enums.Alignments.Right}
              label="Go back"
              style={Button.enums.Styles.Inverse}
              link={{
                path: '/start?customer=landlord',
              }}
            />
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
            {addressUnitUuids.length <= 0 ? (
              <Tooltip
                text="Add at least one unit to continue"
                position={Tooltip.enums.Positions.Up}>
                <Button
                  label="Continue"
                  isDisabled
                />
              </Tooltip>
            ) : (
              <Button
                label="Continue"
                onClick={this.handleContinue}
              />
            )}
          </Row>
        </div>
        <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Large} />
        <ReceiptView>
          <Card
            header={{
              subtitle: 'There\'s no risk - if Caretaker isn\'t right for you, cancel anytime.',
              title: `Caretaker ${planName}`,
            }}
            shadow={Card.enums.Shadows.None}>
            <Card.CardSection>
              <Heading
                content="Your bill summary"
                font={Heading.enums.Fonts.Secondary}
                size={Heading.enums.Sizes.XXSmall}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
              <Text
                content={`*${`$${formatAsUSD(PlanPrices[this.props.paymentSubscriptionPlan] / 100)}*/unit/month`}`}
                isMarkdown
                size={Text.enums.Sizes.Small}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              <Receipt
                charges={[
                  {
                    deltaAmountInCents: PlanPrices[paymentSubscriptionPlan] * addressUnitUuids.length,
                    label: `${PlanNames[paymentSubscriptionPlan]} Plan`,
                    labelMeta: `$${formatAsUSD(PlanPrices[this.props.paymentSubscriptionPlan] / 100)} x ${addressUnitUuids.length} units`,
                  },
                  {
                    customValue: 'FREE',
                    deltaAmountInCents: 0,
                    label: 'Caretaker Protection Plan',
                  }

                ]}
                total={{
                  label: 'Total',
                  value: `$${formatAsUSD((PlanPrices[paymentSubscriptionPlan] / 100) * addressUnitUuids.length, true)} USD / month`,
                }}
              />
            </Card.CardSection>
          </Card>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
          <Card shadow={Card.enums.Shadows.None}>
            <Card.CardSection title="What you can expect">
              <UnorderedList
                items={_.map(PlanFeatures[this.props.paymentSubscriptionPlan], feature => ({
                  color: UnorderedList.enums.Colors[`Brand${_.capitalize(_.toLower(this.props.paymentSubscriptionPlan))}Plan`],
                  label: feature,
                }))}
                style={UnorderedList.enums.Styles.Secondary}
                size={UnorderedList.enums.Sizes.Small}
                hasBorders={false}
              />
            </Card.CardSection>
          </Card>
        </ReceiptView>
      </Row>
    );
  }
}

interface Variables {
  uuid: string;
}

type Response = QueryProps;

export default compose(
  withAuth,
  withCookies,
  withCreatePaymentSubscription,
  withCreatePropertyManagerContract,
  withDevice,
  withFetchOrCreateAddress,
  withFetchOrCreateAddressUnit,
  withFetchOrCreateListingForPropertyManagerContract,
  withRegister,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    props: (props) => ({
      isLoading: props.loading,
      propertyManagers: props.data?.propertyManagers ?? [],
    }),
  }),
)(AddUnits);
