// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._mmJp97{background-color:#ddd;background-size:cover;border-radius:10px;height:80px;margin-bottom:16px;margin-right:16px;width:80px}._mmJp97 ._2dAp7Y{background-color:rgba(24,32,41,.8);border:none;border-radius:10px;display:-webkit-box;display:flex;height:100%;-webkit-box-pack:center;justify-content:center;opacity:0;pointer-events:none;-webkit-transition:opacity .2s;transition:opacity .2s;width:100%}._mmJp97._NRBC3b ._2dAp7Y,._mmJp97:hover ._2dAp7Y{color:#fff;opacity:1;pointer-events:auto}", ""]);
// Exports
exports.locals = {
	"access-form-photo": "_mmJp97",
	"access-form-photo--delete-button": "_2dAp7Y",
	"access-form-photo--deleting": "_NRBC3b"
};
module.exports = exports;
