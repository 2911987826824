import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';
import { Hand, HandGrab, Photo } from '~web-core/lib/common/svgs/icons/physical-objects';

import styles from './ReorderInstructionsOverlay.scss';

interface Props {
  onClick: () => void;
}

function ReorderInstructionsOverlay(props: Props) {
  return (
    <div styleName="reorder-overlay">
      <div styleName="reorder-overlay__animation">
        <Photo />
        <Hand />
        <HandGrab />
      </div>
      <h5>Hold and drag photos to reorder them</h5>
      <button onClick={props.onClick}>Continue</button>
    </div>
  );
}

export default withStyles(styles)(ReorderInstructionsOverlay);
