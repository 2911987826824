import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import React, { Fragment, PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import withQuery from '~tools/react/graphql/withQuery';
import withPaymentSubscription, { PaymentSubscriptionProps } from '~tools/react/hocs/withPaymentSubscription';

import { compose } from '~tools/react/hocs/utils';
import { formatAsUSD } from '~tools/utils/string';
import { getAbbreviatedStreetAddress } from '~tools/utils/geography/addresses';
import { pageToCursor } from '~tools/react/graphql/utils';
import Card from '~tools/react/components/Card';
import Text from '~tools/react/components/Text';
import PayWallCard from '~tools/react/components/PayWallCard';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import Table from '~tools/react/components/Table';

import query from './SecurityDeposits.gql';

type Props =
  InputProps &
  QueryProps &
  PaymentSubscriptionProps;

const NUMBER_OF_SECURITY_DEPOSITS_TO_FETCH = 10;

interface SecurityDeposit {
  account: {
    fullName: string;
    uuid: string;
  };
  createdAt: string;
  currentAmountInCents: number;
  initialAmountInCents: number;
  lease: {
    addressUnit: {
      address: {
        streetAddress1: string;
        uuid: string;
      };
      uuid: string;
    };
    endDate: string;
    uuid: string;
  };
  uuid: string;
}

class SecurityDeposits extends PureComponent<Props> {
  render() {
    if (!this.props.paymentSubscription) {
      return (
        <ManageStage>
          <PayWallCard
            featureName="Security deposits"
            title="City &amp; state compliant security deposits, taken care of"
            intro="Enable Caretaker's managed security deposits to easily start collecting and holding security deposits for your upcoming tenants."
            bullets={[
              'Compliant everything - easily manage deposits in any locale without talking to a lawyer.',
              'Deductions & returns - we\'ll have deductions itemized and the deposit returned on time.',
              'Dispute center - we\'ll work with your tenants and get to the bottom of any confusion.',
            ]}
            learnMorePath="https://caretaker.com/questions/how-do-security-deposits-work"
          />
        </ManageStage>
      );
    }

    const securityDeposits = this.props.securityDeposits;
    return (
      <ManageStage>
        <Card
          header={{
            title: 'All security deposits',
            subtitle: 'Manage your held security deposits',
          }}>
          <Table>
            <Table.TableHead>
              <Table.TableRow>
                <Table.TableHeader>Address</Table.TableHeader>
                <Table.TableHeader>Lessee</Table.TableHeader>
                <Table.TableHeader>Current Amount</Table.TableHeader>
                <Table.TableHeader>Claims</Table.TableHeader>
                <Table.TableHeader>Lease End</Table.TableHeader>
                <Table.TableHeader></Table.TableHeader>
              </Table.TableRow>
            </Table.TableHead>
            <Table.TableBody>
              {securityDeposits.length > 0 ? (
                <Fragment>
                  {_.map(securityDeposits, (securityDeposit) => (
                    <Table.TableRow
                      key={securityDeposit.uuid}
                      link={{ path: `/security-deposits/${securityDeposit.uuid}` }}>
                      <Table.TableData>
                        <Text
                          content={
                            getAbbreviatedStreetAddress(securityDeposit.lease.addressUnit.address.streetAddress1)
                          }
                        />
                      </Table.TableData>
                      <Table.TableData>
                        <Text
                          content={securityDeposit.account.fullName}
                        />
                      </Table.TableData>
                      <Table.TableData>
                        <Text
                          content={`$${formatAsUSD(securityDeposit.currentAmountInCents / 100)}`}
                        />
                      </Table.TableData>
                      <Table.TableData>
                        <Text
                          content={`$${formatAsUSD((
                            securityDeposit.initialAmountInCents - securityDeposit.currentAmountInCents
                          ) / 100)}`}
                        />
                      </Table.TableData>
                      <Table.TableData>
                        <Text
                          content={moment(securityDeposit.lease.endDate).format('MMMM Do YYYY')}
                        />
                      </Table.TableData>
                    </Table.TableRow>
                  ))}
                  {this.props.securityDepositsPageInfo ? (
                    <Table.TablePaginator
                      startCursor={this.props.securityDepositsPageInfo.startCursor || ''}
                      endCursor={this.props.securityDepositsPageInfo.endCursor || ''}
                      resultsPerPage={NUMBER_OF_SECURITY_DEPOSITS_TO_FETCH}
                      totalResults={this.props.securityDepositsTotal}
                    />
                  ) : null}
                </Fragment>
              ) : (
                <Table.TableEmptyState
                  label="Here’s where your first security deposit will show up once one is put down."
                />
              )}
            </Table.TableBody>
          </Table>
        </Card>
      </ManageStage>
    );
  }
}

interface Response {
  viewer: {
    securityDeposits: SecurityDeposit[];
    securityDepositsTotal: number;
    securityDepositsPageInfo: {
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string | null;
    };
    uuid: string;
  } | null;
}

interface Variables {
  after?: string;
  first?: number;
}

type InputProps = RouteComponentProps;

interface QueryProps {
  isLoading: boolean;
  securityDeposits: SecurityDeposit[];
  securityDepositsTotal: number;
  securityDepositsPageInfo: {
    endCursor: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string | null;
  } | null;
}

export default compose(
  withPaymentSubscription,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: (props) => {
      const pageString = queryString.parse(props.location.search).page;
      const page = _.parseInt(typeof pageString === 'string' ? pageString : '1') ?? undefined;
      const after = page > 1 ? pageToCursor(page, NUMBER_OF_SECURITY_DEPOSITS_TO_FETCH) : undefined;
      return {
        variables: {
          after,
          first: NUMBER_OF_SECURITY_DEPOSITS_TO_FETCH,
        },
      };
    },
    props: (props) => ({
      isLoading: props.loading,
      securityDeposits: props.data?.viewer?.securityDeposits ?? [],
      securityDepositsTotal: props.data?.viewer?.securityDepositsTotal ?? 0,
      securityDepositsPageInfo: props.data?.viewer?.securityDepositsPageInfo ?? null,
    }),
  }),
)(SecurityDeposits);
