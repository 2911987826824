import loadable, { LoadableComponent } from '@loadable/component';
import _ from 'lodash';

import { InputProps } from './ThemedModal';
import ThemedModalFooter from './components/ThemedModalFooter';
import ThemedModalHeader from './components/ThemedModalHeader';
import ThemedModalSection from './components/ThemedModalSection';
import * as enums from './enums';

const LoadableThemedModal = loadable(() => import(/* webpackChunkName: "themed-modal" */'./ThemedModal')) as LoadableComponent<InputProps>;
const ThemedModal = _.assign(LoadableThemedModal, { enums, ThemedModalFooter, ThemedModalHeader, ThemedModalSection });

export default ThemedModal;
