import loadable from '@loadable/component';
import _ from 'lodash';

import FormSection from './components/FormSection';

const Form = loadable(() => import(/* webpackChunkName: "form" */'./Form'));

export default _.assign(Form, { FormSection });

export { default as ButtonSelect } from './components/ButtonSelect';
export { default as Checkbox } from './components/Checkbox';
export { default as FormField } from './components/FormField';
export { default as Input } from './components/Input';
export { default as PaddleSelect } from './components/PaddleSelect';
export { default as Select } from './components/Select';
export { default as StripeCardInput } from './components/StripeCardInput';
export { default as TextArea } from './components/TextArea';
export { default as Typeahead } from './components/Typeahead';
