// @flow

import classNames from 'classnames';
import React from 'react';
import type { Node } from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './ManageListingPhotosView.scss';

type Props = {|
  children: Node,
  isReordering: boolean,
|};

function ManageListingPhotosView(props: Props) {
  if (!props.children) return null;
  return (
    <div styleName={classNames({ 'photos-view': true, 'photos-view--reordering': props.isReordering })}>
      {props.children}
    </div>
  );
}

export default withStyles(styles)(SortableContainer(ManageListingPhotosView));
