import classNames from 'classnames';
import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './TiersFooterView.scss';

interface Props {
  children: ReactNode;
  isOpen?: boolean;
}

function TiersFooterView(props: Props) {
  return (
    <div
      className={classNames({
        [styles['tiers-footer-view']]: true,
        [styles['tiers-footer-view--open']]: props.isOpen,
      })}>
      {props.children}
    </div>
  );
}

export default withStyles(styles)(TiersFooterView);
