import _ from 'lodash';
import React, { ComponentType, Fragment, PureComponent } from 'react';

import withCreateStripeFinancialConnectionSession, {
  CreateStripeFinancialConnectionSessionProps,
} from '~tools/react/graphql/mutations/accounts/withCreateStripeFinancialConnectionSession';
import withAddPayoutAccount, {
  AddPayoutAccountProps,
} from '~tools/react/graphql/mutations/payoutAccounts/withAddPayoutAccount';
import withQuery from '~tools/react/graphql/withQuery';
import { compose } from '~tools/react/hocs/utils';
import withAuth, { AuthProps } from '~tools/react/hocs/withAuth';
import withStripe, { StripeProps } from '~tools/react/hocs/withStripe';
import { StripeAccountHolderTypes, StripeExternalAccountTypes } from '~tools/types/graphqlSchema';

import { PayoutAccount } from '~web-manage/lib/common/scenes/PayoutsSettings/types';

import PaymentSourceButton from './components/PaymentSourceButton';
import PaymentSourceRow from './components/PayoutAccountRow';

import PayoutSourceSelectorQuery from './PayoutAccountSelector.gql';

interface InputProps {
  activePayoutAccountId?: string;
  onSelectPayoutAccount: (uuid: string) => void;
}

type QueryInputProps = InputProps & AuthProps;

type Props = QueryInputProps &
  QueryProps &
  StripeProps &
  CreateStripeFinancialConnectionSessionProps &
  AddPayoutAccountProps;

interface State {
  isAddButtonLoading: boolean;
  isDropdownOpen: boolean;
  isLoading: boolean;
}

class PayoutSourceSelector extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isAddButtonLoading: false,
      isDropdownOpen: false,
      isLoading: false,
    };
  }

  handleAddBank = async () => {
    if (this.state.isAddButtonLoading) return;

    const stripe = this.props.stripe;
    if (!stripe) return;

    this.setState({ isAddButtonLoading: true });
    const clientSecret = await this.props.createStripeFinancialConnectionSession({
      accountHolderType: StripeAccountHolderTypes.ACCOUNT,
    });
    this.setState({ isAddButtonLoading: false });
    const result = await stripe.collectBankAccountToken({
      clientSecret,
    });

    if (result.error) {
      this.setState({ isAddButtonLoading: false });
    } else if (result.token) {
      const paymentSource = await this.props.addPayoutAccount(result.token.id);

      this.props.onSelectPayoutAccount(paymentSource.id);
      this.setState({
        isAddButtonLoading: false,
      });
    }
  };

  handleDropdownToggle = () => this.setState({ isDropdownOpen: !this.state.isDropdownOpen });

  handleSelectBankAccount = (id: string) => {
    this.setState({
      isLoading: false,
      isDropdownOpen: false,
    });
    this.props.onSelectPayoutAccount(id);
  };

  render() {
    const activeBankAccount = this.getActiveBankAccount();
    const inactiveBankAccounts = this.getInactiveBankAccounts();

    const hasInactiveBankAccounts = inactiveBankAccounts.length > 0;
    if (!activeBankAccount && !hasInactiveBankAccounts) {
      return (
        <PaymentSourceButton
          icon={PaymentSourceButton.enums.Icons.PlusCircle}
          isLoading={this.state.isAddButtonLoading}
          onClick={this.handleAddBank}
          style={PaymentSourceButton.enums.Styles.Add}
        />
      );
    }

    return (
      <Fragment>
        {!activeBankAccount ? (
          <PaymentSourceButton
            icon={
              this.state.isDropdownOpen
                ? PaymentSourceButton.enums.Icons.ChevronUp
                : PaymentSourceButton.enums.Icons.ChevronDown
            }
            isDisabled={this.state.isLoading}
            onClick={this.handleDropdownToggle}
            style={PaymentSourceButton.enums.Styles.Select}
          />
        ) : (
          <PaymentSourceRow
            payoutAccount={{
              id: activeBankAccount.id,
              bankAccount: {
                accountName:
                  activeBankAccount?.type === StripeExternalAccountTypes.BANK_ACCOUNT
                    ? activeBankAccount.bankAccount.accountName
                    : activeBankAccount.card.name,
                bankName:
                  activeBankAccount?.type === StripeExternalAccountTypes.BANK_ACCOUNT
                    ? activeBankAccount.bankAccount.bankName
                    : '',
                last4:
                  activeBankAccount?.type === StripeExternalAccountTypes.BANK_ACCOUNT
                    ? activeBankAccount.bankAccount.last4
                    : activeBankAccount.card.last4,
              },
            }}
            isDisabled={this.state.isLoading}
            icon={
              this.state.isDropdownOpen ? PaymentSourceRow.enums.Icons.Cross : PaymentSourceRow.enums.Icons.ChevronDown
            }
            onClick={this.handleDropdownToggle}
          />
        )}
        {this.state.isDropdownOpen ? (
          <Fragment>
            {_.map(inactiveBankAccounts, (bankAccount) => (
              <PaymentSourceRow
                icon={PaymentSourceRow.enums.Icons.ChevronRight}
                key={bankAccount.id}
                onClick={this.handleSelectBankAccount}
                isDisabled={this.state.isLoading}
                payoutAccount={{
                  id: bankAccount.id,
                  bankAccount: {
                    accountName:
                      bankAccount?.type === StripeExternalAccountTypes.BANK_ACCOUNT
                        ? bankAccount.bankAccount.accountName
                        : bankAccount.card.name,
                    bankName:
                      bankAccount?.type === StripeExternalAccountTypes.BANK_ACCOUNT
                        ? bankAccount.bankAccount.bankName
                        : '',
                    last4:
                      bankAccount?.type === StripeExternalAccountTypes.BANK_ACCOUNT
                        ? bankAccount.bankAccount.last4
                        : bankAccount.card.last4,
                  },
                }}
              />
            ))}
            {activeBankAccount || hasInactiveBankAccounts ? (
              <PaymentSourceButton
                icon={PaymentSourceButton.enums.Icons.PlusCircle}
                isDisabled={this.state.isLoading}
                isLoading={this.state.isAddButtonLoading}
                onClick={this.handleAddBank}
                style={PaymentSourceButton.enums.Styles.Add}
              />
            ) : null}
          </Fragment>
        ) : null}
      </Fragment>
    );
  }

  getActiveBankAccount = () => {
    return _.find(this.props.payoutAccounts, (account) => account.id === this.props.activePayoutAccountId);
  };

  getInactiveBankAccounts = () => {
    const activeBankAccount = this.getActiveBankAccount();

    if (!activeBankAccount) return this.props.payoutAccounts;
    return _.filter(this.props.payoutAccounts, (account) => account.id !== activeBankAccount.id);
  };
}

interface Response {
  viewer: {
    payoutAccounts: PayoutAccount[];
  } | null;
}

interface QueryProps {
  payoutAccounts: PayoutAccount[];
}

export default compose(
  withAuth,
  withQuery<QueryInputProps, Response, {}, QueryProps>(PayoutSourceSelectorQuery, {
    options: (props) => ({
      skip: !props.isLoggedIn,
    }),
    props: (props) => ({
      payoutAccounts: props.data?.viewer?.payoutAccounts ?? [],
    }),
  }),
  withAddPayoutAccount,
  withCreateStripeFinancialConnectionSession,
  withStripe,
)(PayoutSourceSelector) as ComponentType<InputProps>;
