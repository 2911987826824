// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._zaNNDX ._WVj3Ra{border-radius:5px;border:1px solid #e6e8eb;margin-top:16px;padding:0}", ""]);
// Exports
exports.locals = {
	"verifications-view": "_zaNNDX",
	"verifications-view__verifications": "_WVj3Ra"
};
module.exports = exports;
