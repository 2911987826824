import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Lock from '~tools/svgs/icons/interface/lock.svg';

import styles from './SecureFooter.scss';

function SecureFooter() {
  return (
    <p styleName="secure-footer">
      <Lock styleName="secure-footer__svg" />
      Your data is secure and encrypted
    </p>
  );
}

export default withStyles(styles)(SecureFooter);
