import withMutation from '~tools/react/graphql/withMutation';
import { LeaseContextInput } from '~tools/types/graphqlSchema';

import GenerateLeaseAgreement from './generateLeaseAgreement.gql';

export interface Document {
  uuid: string;
}

interface Data {
  generateLeaseAgreement: Document;
}

interface Variables {
  applicationUuid: string;
  input: LeaseContextInput;
}

export interface GenerateLeaseAgreementProps {
  generateLeaseAgreement: (applicationUuid: string, input: LeaseContextInput) => Promise<Document>;
}

export default withMutation<{}, Data, Variables, GenerateLeaseAgreementProps>(GenerateLeaseAgreement, {
  props: props => ({
    generateLeaseAgreement: async (applicationUuid: string, input: LeaseContextInput) => {
      const res = await props.mutate({
        variables: {
          applicationUuid,
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error generating lease agreement.');

      return res.data.generateLeaseAgreement;
    },
  }),
});
