import _ from 'lodash';
import queryString from 'query-string';
import React, { ComponentType, PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import withStyles from '~tools/react/hocs/withStyles';

import { compose } from '~tools/react/hocs/utils';
import { cursorToOffset } from '~tools/react/graphql/utils';

import Button from '~tools/react/components/Button';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';

import styles from './TablePaginator.scss';

interface InputProps {
  endCursor: string;
  queryParamName: string;
  resultsPerPage: number;
  startCursor: string;
  totalResults: number;
}

type Props = InputProps & RouteComponentProps;

class TablePaginator extends PureComponent<Props> {
  static defaultProps: Partial<Props> = {
    queryParamName: 'page',
  };

  render() {
    // Raw offset data is zero indexed so just add a lil +1
    const currentStartOffset = cursorToOffset(this.props.startCursor) + 1;
    const currentEndOffset = cursorToOffset(this.props.endCursor) + 1;

    const pathname = this.props.location.pathname;
    const searchParams = queryString.parse(this.props.location.search);
    const pageNumber = searchParams[this.props.queryParamName];
    const page = _.parseInt(typeof pageNumber === 'string' ? pageNumber : '1', 10);

    const hasNextPage = currentEndOffset < this.props.totalResults;
    const hasPreviousPage = currentStartOffset > 1;

    return (
      <tr>
        <td colSpan={100}>
          <div styleName="table-paginator">
            <p>{this.props.totalResults === 0 ? 'No results' : `Displaying ${currentStartOffset} - ${currentEndOffset} of ${this.props.totalResults} results`}</p>
            <div styleName="table-paginator__buttons">
              <Button
                color={Button.enums.Colors.Gray}
                isDisabled={!hasPreviousPage}
                label="Previous"
                link={hasPreviousPage ? {
                  path: `${pathname}?${queryString.stringify({
                    ...searchParams,
                    [this.props.queryParamName]: `${page - 1}`,
                  })}`,
                } : undefined}
                size={Button.enums.Sizes.Small}
                style={Button.enums.Styles.Secondary}
              />
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
              <Button
                color={Button.enums.Colors.Gray}
                isDisabled={!hasNextPage}
                label="Next"
                link={hasNextPage ? {
                  path: `${pathname}?${queryString.stringify({
                    ...searchParams,
                    [this.props.queryParamName]: `${page + 1}`,
                  })}`,
                } : undefined}
                size={Button.enums.Sizes.Small}
                style={Button.enums.Styles.Secondary}
              />
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
)(TablePaginator) as ComponentType<Omit<InputProps, 'queryParamName'> & { queryParamName?: string }>;
