import _ from 'lodash';

// import GoogleAnalytics from './ga';

import {
  AnalyticsIdentifyInput,
  AnalyticsTrackInput,
} from './types';

const platforms: {
  GoogleAnalytics?: any;
} = {
  // GoogleAnalytics: new GoogleAnalytics(),
};

const analytics = {
  identify(rawId: string | AnalyticsIdentifyInput, rawProperties?: AnalyticsIdentifyInput): void {
    let properties;
    let uuid;

    if (_.isString(rawId)) uuid = rawId;
    else if (_.isObject(rawId)) properties = JSON.parse(JSON.stringify(rawId));

    if (uuid && rawProperties) properties = JSON.parse(JSON.stringify(rawProperties));

    if (uuid) _.map(platforms, platform => platform.identify(uuid));
    if (properties) _.map(platforms, platform => platform.set(properties));
  },

  page(route: string): void {
    _.map(platforms, platform => platform.page(route));
  },

  track(event: string, rawProps?: AnalyticsTrackInput): void {
    const props = rawProps || {};
    _.map(platforms, platform => platform.track(event, props));
  },
};

export default analytics;
