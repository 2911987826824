import React, { PureComponent } from 'react';
import { Cookies, withCookies } from 'react-cookie';

import Button from '~tools/react/components/Button';
import Form, { Input } from '~tools/react/components/Form';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import withLogin, { Account, LoginProps } from '~tools/react/graphql/mutations/accounts/withLogin';
import { compose } from '~tools/react/hocs/utils';

import Globals from '~web-core/lib/common/globals';

interface InputProps {
  cookies: Cookies;
  onLogin?: (account: Account) => Promise<void>;
}

interface State {
  isLoading: boolean;
}

type Props = InputProps & LoginProps;

class LoginForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    Form.preload();
    Input.preload();
  }

  handleLogin = async (data) => {
    this.setState({ isLoading: true });
    try {
      const viewer = await this.props.login(data);
      const date = new Date();
      this.props.cookies.set('token', viewer.token, {
        domain: Globals.COOKIE_DOMAIN,
        expires: new Date(date.setFullYear(date.getFullYear() + 2)),
        path: '/',
        sameSite: Globals.ENV !== 'local' ? 'strict' : 'lax',
        secure: Globals.ENV !== 'local',
      });
      if (this.props.onLogin) await this.props.onLogin(viewer);
      this.setState({ isLoading: false });
    } catch (err) {
      console.error(err);
      this.setState({ isLoading: false });
      throw err;
    }
  };

  render() {
    return (
      <Form onSubmit={this.handleLogin}>
        <Form.FormSection>
          <Input
            isRequired
            label="Email"
            labelFormat={Input.enums.LabelFormats.Stacked}
            name="email"
            placeholder="brucewayne@gmail.com"
            type={Input.enums.Types.Email}
          />
        </Form.FormSection>
        <Form.FormSection>
          <Input
            isRequired
            label="Password"
            labelFormat={Input.enums.LabelFormats.Stacked}
            name="password"
            placeholder="····················"
            secondaryLabel={{
              color: Input.enums.SecondaryLabelColors.Info,
              link: {
                path: `${Globals.WEB_CORE_DOMAIN}/reset`,
              },
              text: 'Forgot?',
            }}
            type={Input.enums.Types.Password}
          />
        </Form.FormSection>
        <div>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
          <Button
            isLoading={this.state.isLoading}
            label="Log in"
            width={Button.enums.Widths.Full}
            style={Button.enums.Styles.Secondary}
            type={Button.enums.Types.Submit}
          />
        </div>
      </Form>
    );
  }
}

export default compose(withCookies, withLogin)(LoginForm);
