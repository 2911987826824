// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-webkit-keyframes _80AJmQ{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg);-webkit-transform-origin:center;transform-origin:center}to{-webkit-transform:rotate(1turn);transform:rotate(1turn);-webkit-transform-origin:center;transform-origin:center}}@keyframes _80AJmQ{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg);-webkit-transform-origin:center;transform-origin:center}to{-webkit-transform:rotate(1turn);transform:rotate(1turn);-webkit-transform-origin:center;transform-origin:center}}._erni5D{-webkit-animation:_80AJmQ .8s linear infinite;animation:_80AJmQ .8s linear infinite}", ""]);
// Exports
exports.locals = {
	"spinner": "_erni5D",
	"rotate": "_80AJmQ"
};
module.exports = exports;
