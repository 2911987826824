import classNames from 'classnames';
import _ from 'lodash';
import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import * as enums from './enums';

import styles from './FormSection.scss';

interface Props {
  children: ReactNode;
  columns?: enums.Columns;
}

function FormSection(props: Props) {
  return (
    <div
      className={classNames({
        [styles.section]: true,
        [styles[`section--columns-${_.kebabCase(props.columns)}`]]: props.columns,
      })}>
      {props.children}
    </div>
  );
}

FormSection.defaultProps = { columns: enums.Columns.One };
FormSection.enums = enums;

export default withStyles(styles)(FormSection);
