import withMutation from '~tools/react/graphql/withMutation';

import RevokeLeaseInviteMutation from './revokeLeaseInvite.gql';

interface LeaseInvite {
  createdAt: string;
  email: string;
  expiresAt: string | null;
  revokedAt: string | null;
  updatedAt: string;
  uuid: string;
}

interface Data {
  revokeLeaseInvite: LeaseInvite;
}

interface Variables {
  uuid: string;
}

export interface RevokeLeaseInviteProps {
  revokeLeaseInvite: (uuid: string) => Promise<LeaseInvite>;
}

export default withMutation<{}, Data, Variables, RevokeLeaseInviteProps>(RevokeLeaseInviteMutation, {
  props: props => ({
    revokeLeaseInvite: async (uuid) => {
      const res = await props.mutate({
        variables: {
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error revoking lease invite.');

      return res.data.revokeLeaseInvite;
    },
  }),
});
