import { ApolloQueryResult } from 'apollo-client';
import _ from 'lodash';
// import moment from 'moment-timezone';
import React, { ComponentType, PureComponent } from 'react';

import {
  PaymentSubscriptionPlanNames as PlanNames,
  PaymentSubscriptionPlanPricesInCents as PlanPrices,
} from '~tools/constants/workspace';
import AnimatedResizer from '~tools/react/components/AnimatedResizer';
import Button from '~tools/react/components/Button';
import CheckableList from '~tools/react/components/CheckableList';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import PricingTierCard from '~tools/react/components/PricingTierCard';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import ThemedModal from '~tools/react/components/ThemedModal';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import PaymentForm from '~tools/react/containers/PaymentForm';
import withCancelPaymentSubscription, {
  CancelPaymentSubscriptionProps,
} from '~tools/react/graphql/mutations/paymentSubscriptions/withCancelPaymentSubscription';
import withCreatePaymentSubscription, {
  CreatePaymentSubscriptionProps,
} from '~tools/react/graphql/mutations/paymentSubscriptions/withCreatePaymentSubscription';
import withUpdatePaymentSubscription, {
  UpdatePaymentSubscriptionProps,
} from '~tools/react/graphql/mutations/paymentSubscriptions/withUpdatePaymentSubscription';
import withQuery from '~tools/react/graphql/withQuery';
import { compose } from '~tools/react/hocs/utils';
import withDevice, { DeviceProps } from '~tools/react/hocs/withDevice';
import withPaymentSubscription, { PaymentSubscriptionProps } from '~tools/react/hocs/withPaymentSubscription';
import { PaymentSubscriptionPlans as Plans, ShipmentStatuses, ShipmentTypes } from '~tools/types/graphqlSchema';
import { formatAsUSD } from '~tools/utils/string';

import ChecklistView from './components/ChecklistView';
import CheckoutView from './components/CheckoutView';
import PlansView from './components/PlansView';

import query from './ManagePlanModal.gql';

interface InputProps {
  isOpen: boolean;
  onChangePlan: () => void;
  onClose: () => void;
}

type Props = InputProps &
  QueryProps &
  DeviceProps &
  PaymentSubscriptionProps &
  CancelPaymentSubscriptionProps &
  UpdatePaymentSubscriptionProps &
  CreatePaymentSubscriptionProps;

interface State {
  isConfirmingDowngrade: boolean;
  isConfirmingChange: boolean;
  isLoading: boolean;
  isOneChecked: boolean;
  isTwoChecked: boolean;
  isThreeChecked: boolean;
  isFourChecked: boolean;
  selectedPlan?: Plans;
}

class ManagePlanModal extends PureComponent<Props, State> {
  state: State = {
    isConfirmingDowngrade: false,
    isConfirmingChange: false,
    isLoading: false,
    isOneChecked: false,
    isTwoChecked: false,
    isThreeChecked: false,
    isFourChecked: false,
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isConfirmingDowngrade: false, isConfirmingChange: false });
      this.props.refetch();
    }
  };

  handleClose = () => {
    this.setState({ isConfirmingDowngrade: false, isConfirmingChange: false });
    this.props.onClose();
  };

  handleToggleCheckOne = () => this.setState({ isOneChecked: !this.state.isOneChecked });
  handleToggleCheckTwo = () => this.setState({ isTwoChecked: !this.state.isTwoChecked });
  handleToggleCheckThree = () => this.setState({ isThreeChecked: !this.state.isThreeChecked });
  handleToggleCheckFour = () => this.setState({ isFourChecked: !this.state.isFourChecked });

  handleNavigateBack = () => this.setState({ isConfirmingDowngrade: false, isConfirmingChange: false });
  handleConfirmDowngrade = () => this.setState({ isConfirmingDowngrade: true });
  handleConfirmChange = () => this.setState({ isConfirmingChange: true });

  handleChangePlan = async () => this.handleSubmitForm();

  handleSubmitForm = async (paymentMethodId?: string) => {
    const selectedPlan = this.state.selectedPlan;
    if (!selectedPlan) return;

    const viewer = this.props.viewer;
    const activePaymentSubscription = viewer?.activePaymentSubscription;
    const numUnits = viewer?.propertyManagerContractsTotal ?? 0;
    const defaultPaymentMethodId = viewer?.stripeCustomerAccount.defaultPaymentMethod;

    this.setState({ isLoading: true });
    if (!activePaymentSubscription) {
      await this.props.createPaymentSubscription({
        paymentMethodId: paymentMethodId ?? defaultPaymentMethodId,
        plan: selectedPlan,
        quantity: numUnits,
      });
    } else {
      await this.props.updatePaymentSubscription(activePaymentSubscription.uuid, {
        paymentMethodId: paymentMethodId ?? defaultPaymentMethodId,
        plan: selectedPlan,
        quantity: numUnits,
      });
    }
    await this.props.refetchPaymentSubscription();
    await this.props.refetch();

    this.setState({ isLoading: false });
    this.props.onChangePlan();
    this.props.onClose();
  };

  handleSelectTier = (plan: Plans) => this.setState({ selectedPlan: plan });

  render() {
    const viewer = this.props.viewer;
    const activePlan = viewer?.activePaymentSubscription?.plan || Plans.LITE;
    const selectedPlan = this.state.selectedPlan;
    const numUnits = viewer?.propertyManagerContractsTotal ?? 0;

    const isConfirming = this.state.isConfirmingChange || this.state.isConfirmingDowngrade;
    const isDowngrading =
      activePlan !== selectedPlan &&
      (selectedPlan === Plans.LITE || (selectedPlan === Plans.LEASEHOLDER && numUnits > 1));

    //  const dueDate = moment().add(30, 'days').endOf('day').format('MMMM Do, YYYY');
    let hasSmartLocks = false;
    _.map(viewer?.orders, (order) => {
      const shipment = _.find(order.shipments, (s) => s.type === ShipmentTypes.SHIPMENT);
      const returnShipment = _.find(order.shipments, (s) => s.type === ShipmentTypes.RETURN);
      if (shipment?.status === ShipmentStatuses.DELIVERED && returnShipment?.status !== ShipmentStatuses.DELIVERED) {
        hasSmartLocks = true;
      }
    });

    return (
      <ThemedModal
        isOpen={this.props.isOpen}
        title={this.state.isConfirmingDowngrade ? 'Confirm downgrade' : 'Manage plan'}
        onNavigateBack={isConfirming ? this.handleNavigateBack : undefined}
        onClose={this.handleClose}>
        <AnimatedResizer>
          <AnimatedResizer.AnimatedResizerSection isShown={!isConfirming}>
            <PlansView>
              <ThemedModal.ThemedModalSection>
                <Row isColumnOnMobile>
                  <PricingTierCard
                    hasFeatures={true}
                    isDisabled={activePlan === Plans.LITE}
                    isSelected={activePlan === Plans.LITE || selectedPlan === Plans.LITE}
                    onClick={this.handleSelectTier}
                    tier={Plans.LITE}
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                  <PricingTierCard
                    hasFeatures={true}
                    isDisabled={activePlan === Plans.BASIC}
                    isSelected={activePlan === Plans.BASIC || selectedPlan === Plans.BASIC}
                    onClick={this.handleSelectTier}
                    tier={Plans.BASIC}
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                  <PricingTierCard
                    hasFeatures={true}
                    isDisabled={activePlan === Plans.ENTERPRISE}
                    isSelected={activePlan === Plans.ENTERPRISE || selectedPlan === Plans.ENTERPRISE}
                    onClick={this.handleSelectTier}
                    tier={Plans.ENTERPRISE}
                  />
                </Row>
              </ThemedModal.ThemedModalSection>
              <ThemedModal.ThemedModalFooter
                alignment={ThemedModal.ThemedModalFooter.enums.Alignments.Right}
                isStickyOnMobile>
                <Row
                  flexBehavior={Row.enums.FlexBehaviors.Default}
                  isColumnOnMobile={false}>
                  <Button
                    color={Button.enums.Colors.Gray}
                    isDisabled={this.state.isLoading}
                    label="Cancel"
                    onClick={this.props.onClose}
                    style={Button.enums.Styles.Outline}
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                  <Button
                    color={Button.enums.Colors.Blue}
                    isDisabled={!selectedPlan}
                    isLoading={this.state.isLoading}
                    label="Continue"
                    onClick={isDowngrading ? this.handleConfirmDowngrade : this.handleConfirmChange}
                  />
                </Row>
              </ThemedModal.ThemedModalFooter>
            </PlansView>
          </AnimatedResizer.AnimatedResizerSection>
          <AnimatedResizer.AnimatedResizerSection isShown={this.state.isConfirmingChange}>
            <ThemedModal.ThemedModalSection>
              <CheckoutView>
                <PaymentForm
                  assurance={{
                    copy: 'Caretaker protects you against lost rent and property damages up to $10,000.',
                    questionSlug: 'what-is-the-caretaker-protection-policy',
                  }}
                  charges={
                    selectedPlan
                      ? [
                          {
                            deltaAmountInCents: PlanPrices[selectedPlan] * numUnits,
                            label: `${PlanNames[selectedPlan]} Plan`,
                            labelMeta: `$${formatAsUSD(PlanPrices[selectedPlan] / 100, true)} x ${numUnits} units`,
                            billingPeriod: PaymentForm.enums.BillingPeriods.MONTH,
                          },
                        ]
                      : []
                  }
                  includeCardProcessingFee={false}
                  hasFooter
                  onSubmit={this.handleSubmitForm}
                />
              </CheckoutView>
            </ThemedModal.ThemedModalSection>
          </AnimatedResizer.AnimatedResizerSection>
          <AnimatedResizer.AnimatedResizerSection isShown={this.state.isConfirmingDowngrade}>
            <ThemedModal.ThemedModalSection>
              <ChecklistView>
                <Text
                  color={Text.enums.Colors.Primary}
                  content="Sorry to see you go!"
                  size={Text.enums.Sizes.Medium}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                <Text
                  color={Text.enums.Colors.Primary}
                  content="We want to make sure nothing catches you off guard in the downgrade process, so just make sure you double check the items on the list below. Just check each bubble to acknowledge you understand it and you'll be able to downgrade your account."
                  size={Text.enums.Sizes.Medium}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                <CheckableList>
                  {/* {hasSmartLocks ? (
                    <Fragment>
                      <CheckableList.CheckableListItem
                        onToggle={this.handleToggleCheckOne}
                        isChecked={this.state.isOneChecked}
                        title={`I will return my rental smart lock(s) by *${dueDate}*, 30 days from now, with the provided bubble mailer(s) and label(s).`}
                      />
                      <CheckableList.CheckableListItem
                        onToggle={this.handleToggleCheckTwo}
                        isChecked={this.state.isTwoChecked}
                        title={`If my rental smart lock(s) aren't returned to Caretaker by *${dueDate}* in working condition, the default payment method associated with my account will be charged *$139.99* to replace each missing or damaged smart lock.`}
                      />
                    </Fragment>
                  ) : null} */}
                  {numUnits > 1 ? (
                    <CheckableList.CheckableListItem
                      onToggle={this.handleToggleCheckThree}
                      isChecked={this.state.isThreeChecked}
                      title={`*${numUnits - 1} of my ${numUnits}* active units will be *frozen and disabled*.`}
                    />
                  ) : null}
                  <CheckableList.CheckableListItem
                    onToggle={this.handleToggleCheckFour}
                    isChecked={this.state.isFourChecked}
                    title="All rent and security deposit payments owed to me will continue to be paid out in accordance with the related lease agreements."
                  />
                </CheckableList>
              </ChecklistView>
            </ThemedModal.ThemedModalSection>
            <ThemedModal.ThemedModalFooter
              alignment={ThemedModal.ThemedModalFooter.enums.Alignments.Right}
              isStickyOnMobile>
              <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                <Button
                  color={Button.enums.Colors.Gray}
                  isDisabled={this.state.isLoading}
                  label="Cancel"
                  onClick={this.props.onClose}
                  style={Button.enums.Styles.Outline}
                />
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                <Button
                  color={isDowngrading ? Button.enums.Colors.Red : undefined}
                  isDisabled={
                    (!this.state.isOneChecked && hasSmartLocks) ||
                    (!this.state.isTwoChecked && hasSmartLocks) ||
                    (!this.state.isThreeChecked && numUnits > 1) ||
                    !this.state.isFourChecked
                  }
                  isLoading={this.state.isLoading}
                  label={isDowngrading ? 'Continue' : 'Downgrade'}
                  onClick={this.handleChangePlan}
                />
              </Row>
            </ThemedModal.ThemedModalFooter>
          </AnimatedResizer.AnimatedResizerSection>
        </AnimatedResizer>
      </ThemedModal>
    );
  }
}

interface Order {
  createdAt: string;
  orderItems: {
    inventoryItems: {
      smartLock: {
        isSetup: boolean;
        uuid: string;
      };
      uuid: string;
    }[];
    uuid: string;
  }[];
  shippingAddressUnit: {
    address: {
      streetAddress1: string;
      uuid: string;
    };
    name: string | null;
    uuid: string;
  };
  shipments: {
    carrier: string;
    deliveredAt: string | null;
    method: string | null;
    shippedAt: string | null;
    status: string;
    type: ShipmentTypes;
    uuid: string;
  }[];
  uuid: string;
}

interface Viewer {
  activePaymentSubscription: {
    plan: Plans;
    uuid: string;
  } | null;
  orders: Order[];
  stripeCustomerAccount: {
    defaultPaymentMethod: string | null;
  };
  paymentMethods: {
    id: string;
    isDefault: boolean;
  }[];
  propertyManagerContracts: {
    uuid: string;
  }[];
  propertyManagerContractsTotal: number;
  uuid: string;
}

interface Response {
  viewer: Viewer | null;
}

interface QueryProps extends Response {
  isLoading: boolean;
  refetch: () => Promise<ApolloQueryResult<Response>>;
}

export default compose(
  withDevice,
  withPaymentSubscription,
  withCancelPaymentSubscription,
  withUpdatePaymentSubscription,
  withCreatePaymentSubscription,
  withQuery<{}, Response, {}, QueryProps>(query, {
    options: () => ({
      fetchPolicy: 'no-cache',
    }),
    props: (props) => ({
      isLoading: props.loading,
      refetch: props.refetch,
      viewer: props.data?.viewer || null,
    }),
  }),
)(ManagePlanModal) as ComponentType<InputProps>;
