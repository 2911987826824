import { AddPhotoToListingInputType } from '~tools/types/graphqlSchema';
import withMutation from '~tools/react/graphql/withMutation';

import AddPhotoToListingMutation from './addPhotoToListing.gql';

interface Listing {
  photos: {
    position: number;
    url: string;
    uuid: string;
  }[];
  updatedAt: string;
  uuid: string;
}

interface Tag {
  name: string;
  uuid: string;
}

interface Photo {
  file?: File;
  height: number;
  listing?: Listing;
  position: number;
  tags?: Tag[];
  url: string;
  uuid: string;
  width: number;
}

interface Data {
  addPhotoToListing: Photo;
}

interface Variables {
  input: AddPhotoToListingInputType;
}

export interface AddPhotoToListingProps {
  addPhotoToListing: (input: AddPhotoToListingInputType) => Promise<Photo>;
}

export default withMutation<{}, Data, Variables, AddPhotoToListingProps>(AddPhotoToListingMutation, {
  props: props => ({
    addPhotoToListing: async (input: AddPhotoToListingInputType) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error adding photo to listing.');

      return res.data.addPhotoToListing;
    },
  }),
});
