import { ThreadActionTypes } from '~tools/types/graphqlSchema';

import { ActionThemes } from './enums';
import { Action } from './types';

interface ActionInfo {
  isComplete: boolean;
  label: string;
  theme: ActionThemes;
  link?: {
    path: string;
    shouldOpenNewTab: boolean;
  };
}

export function getInfoForAction(action: Action): ActionInfo | null {
  switch (action.type) {
    case ThreadActionTypes.ADD_CREDIT_SCORE:
      return {
        isComplete: !!action.profile.activeCreditReport,
        label: 'Add credit score',
        theme: ActionThemes.PRIMARY,
        link: {
          path: '/user/credit-and-background',
          shouldOpenNewTab: true,
        },
      };
    case ThreadActionTypes.ADD_EMPLOYMENT:
      return {
        isComplete: action.profile.incomeSources.length > 0,
        label: 'Add employment',
        theme: ActionThemes.PRIMARY,
        link: {
          path: '/user/employment',
          shouldOpenNewTab: true,
        },
      };
    case ThreadActionTypes.REQUEST_BOOKING:
      return {
        isComplete: action.flip.status !== 'OPENED',
        label: 'Request booking',
        theme: ActionThemes.PRIMARY,
      };
    case ThreadActionTypes.SETUP_VIEWINGS:
      return {
        isComplete: !!action.addressUnit.accessInstruction,
        label: 'Set up smart lock',
        theme: ActionThemes.PRIMARY,
        link: {
          path: 'viewings/smart-lock',
          shouldOpenNewTab: false,
        },
      };
    default:
      return null;
  }
}
