import loadable, { LoadableComponent } from '@loadable/component';
import React, { PureComponent } from 'react';

import * as enums from './enums';
import { Props } from './types';

// Wait to load the actual AuthModal until it's mounted to avoid bundle bloat
const AuthModal = loadable(() => import(/* webpackChunkName: "auth-modal" */'./AuthModal')) as LoadableComponent<Props>;
const AuthModalView = loadable(() => import(/* webpackChunkName: "auth-modal-view" */'./components/AuthModalView'));

interface State {
  isOpen: boolean;
}

class AuthModalAnimator extends PureComponent<Props, State> {
  static AuthModalView = AuthModalView;
  static enums = enums;

  timeout: NodeJS.Timeout | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: props.isOpen,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isOpen !== prevProps.isOpen && this.props.isOpen) {
      this.setState({ isOpen: true });
    } else if (this.props.isOpen !== prevProps.isOpen && !this.props.isOpen) {
      this.timeout = setTimeout(() => this.setState({ isOpen: false }), 300);
    }
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  render() {
    if (this.state.isOpen) {
      return (
        <AuthModal {...this.props} />
      );
    }

    return null;
  }
}

export default AuthModalAnimator;
