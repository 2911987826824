import { ApolloQueryResult } from 'apollo-client';
import _ from 'lodash';
import React, { PureComponent } from 'react';

import { QualificationTypes } from '~tools/types/graphqlSchema';
import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';

import {
  Listing,
  Requirement,
  VerificationMethod,
} from './types';

import query from './UnitQualificationManager.gql';
import QualificationManager from '../QualificationManager';

interface InputProps {
  listingUuid: string;
  type: QualificationTypes;
}

type Props =
  InputProps &
  QueryProps;

interface State {
  isAddingRequirementFormOpen: boolean;
  isVerificationMethodsModalOpen: boolean;
}

class UnitQualificationManager extends PureComponent<Props, State> {
  render() {
    return (
      <QualificationManager
        isLoading={this.props.isLoading}
        listing={this.props.listing ?? undefined}
        refetch={this.props.refetch}
        rentalPolicyUuid={this.props.rentalPolicyUuid}
        requirements={this.props.requirements}
        verificationMethods={this.props.verificationMethods}
        type={this.props.type}
      />
    );
  }
}

interface Response {
  verificationMethods: VerificationMethod[];
  viewer: {
    listing: Listing | null;
    uuid: string;
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  listing: Listing | null;
  refetch: () => Promise<ApolloQueryResult<Response>>;
  rentalPolicyUuid: string | null;
  requirements: Requirement[];
  verificationMethods: VerificationMethod[];
}

interface Variables {
  listingUuid: string;
}

export default compose(
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: (props) => ({
      variables: {
        listingUuid: props.listingUuid,
      },
    }),
    props: (props) => ({
      isLoading: props.loading,
      listing: props.data?.viewer?.listing || null,
      refetch: props.refetch,
      rentalPolicyUuid: props.data?.viewer?.listing?.propertyManagerContract?.rentalPolicy?.uuid || null,
      requirements: _.filter(
        props.data?.viewer?.listing?.propertyManagerContract?.rentalPolicy?.requirements,
        requirement => requirement.qualification.type === props.ownProps.type,
      ),
      verificationMethods: props.data?.verificationMethods || [],
    }),
  }),
)(UnitQualificationManager);
