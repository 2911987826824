import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';
import ArrowRight from '~tools/svgs/icons/interface/arrow-right.svg';

import { Photo } from '~web-manage/lib/common/scenes/ListingSetup/scenes/ListingSetupPhotos/types';

import styles from './ImportBuildingPhotosButton.scss';

interface Props {
  buildingPhotos: Photo[];
  onClick: () => void;
}

function ImportBuildingPhotosButton(props: Props) {
  const firstPhoto = props.buildingPhotos[0];
  const elm = (
    <div styleName="photos-building-import__art__section">
      <div styleName="photos-building-import__art__row">
        <div style={{ backgroundImage: `url("${firstPhoto?.url}")` }} />
        <div style={{ backgroundImage: `url("${props.buildingPhotos[1]?.url || firstPhoto?.url}")` }} />
      </div>
      <div styleName="photos-building-import__art__row">
        <div style={{ backgroundImage: `url("${props.buildingPhotos[2]?.url || firstPhoto?.url}")` }} />
        <div style={{ backgroundImage: `url("${props.buildingPhotos[3]?.url || firstPhoto?.url}")` }} />
      </div>
      <div styleName="photos-building-import__art__row">
        <div style={{ backgroundImage: `url("${props.buildingPhotos[4]?.url || firstPhoto?.url}")` }} />
        <div style={{ backgroundImage: `url("${props.buildingPhotos[5]?.url || firstPhoto?.url}")` }} />
      </div>
      <div styleName="photos-building-import__art__row">
        <div style={{ backgroundImage: `url("${props.buildingPhotos[5]?.url || firstPhoto?.url}")` }} />
        <div style={{ backgroundImage: `url("${props.buildingPhotos[6]?.url || firstPhoto?.url}")` }} />
      </div>
    </div>
  );

  return (
    <div styleName="photos-building-import">
      <div>
        <h5>Import photos from building</h5>
        <p>Pick and choose photos uploaded by other users subletting in your building</p>
        <button onClick={props.onClick}>
          Import photos <ArrowRight />
        </button>
      </div>
      <div styleName="photos-building-import__art">
        {elm}
        {elm}
      </div>
    </div>
  );
}

export default withStyles(styles)(ImportBuildingPhotosButton);
