// @flow

import type { Node } from 'react';
import React from 'react';
import classNames from 'classnames';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './ImportBuildingPhotosModalPhotoView.scss';

type Props = {
  children: Node,
  isActive: boolean,
};

function ImportBuildingPhotosModalPhotoView(props: Props) {
  return (
    <div
      styleName={classNames({
        'photo-view': true,
        'photo-view--active': props.isActive,
      })}>
      {props.children}
    </div>
  );
}

export default withStyles(styles)(ImportBuildingPhotosModalPhotoView);
