import classNames from 'classnames';
import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './MarketingWidthConstraintView.scss';

interface Props {
  children: ReactNode,
  hasAutoMargin?: boolean;
  hasPaddingOnMobile?: boolean;
}

function MarketingWidthConstraintView(props: Props) {
  return (
    <section
      className={classNames({
        [styles['marketing-width-constraint']]: true,
        [styles['marketing-width-constraint--no-margin']]: props.hasAutoMargin === false,
        [styles['marketing-width-constraint--mobile-padding']]: props.hasPaddingOnMobile,
      })}>
      {props.children}
    </section>
  );
}

MarketingWidthConstraintView.defaultProps = {
  hasAutoMargin: true,
  hasPaddingOnMobile: true,
};

export default withStyles(styles)(MarketingWidthConstraintView);
