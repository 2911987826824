// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2dA3K8{border-right:1px solid #e6e8eb;padding:24px}._2dA3K8:last-of-type{border-right:none}._2dA3K8 ._BX0oY6{border-radius:18px;box-shadow:0 2px 12px 0 rgba(24,32,41,.08),0 1px 4px 0 rgba(0,0,0,.04);height:36px;margin:7px;width:36px}", ""]);
// Exports
exports.locals = {
	"setting-card": "_2dA3K8",
	"setting-card__icon": "_BX0oY6"
};
module.exports = exports;
