// @flow

import React from 'react';
import { Link } from 'react-router-dom';

import withStyles from '~tools/react/hocs/withStyles';
import { deepFreeze } from '~web-core/lib/common/utils/object';
import Button from '~tools/react/components/Button';
import Heading from '~tools/react/components/Heading';
import Text from '~tools/react/components/Text';

import styles from './NextStepsCard.scss';
import BuildingCheck from './svgs/building-check.svg';
import HouseLease from './svgs/house-lease.svg';
import Money from './svgs/money.svg';

const enums = deepFreeze({
  Icons: {
    Money: 'MONEY',
    HouseLease: 'HOUSE_LEASE',
    BuildingCheck: 'BUILDING_CHECK',
  },
});

type Props = {|
  action?: () => void,
  description: string,
  icon: $Values<typeof enums.Icons>,
  label: string,
  linkPath?: string,
  title: string,
|};

const iconsToSvgs = {
  [enums.Icons.Money]: <Money />,
  [enums.Icons.BuildingCheck]: <BuildingCheck />,
  [enums.Icons.HouseLease]: <HouseLease />,
};

function NextStepsCard(props: Props) {
  const innerElm = (
    <Button
      label={props.label}
      onClick={props.action}
      size={Button.enums.Sizes.Small}
      style={Button.enums.Styles.Outline}
    />
  );
  return (
    <div styleName="next-steps-card">
      <div>
        <Heading size={Heading.enums.Sizes.XXSmall} content={props.title} font={Heading.enums.Fonts.Secondary} />
        <Text size={Heading.enums.Sizes.Small} content={props.description} />
        {props.linkPath ? (
          <Link target="_blank" to={props.linkPath}>
            {innerElm}
          </Link>
        ) : (
          innerElm
        )}
      </div>
      {iconsToSvgs[props.icon]}
    </div>
  );
}

NextStepsCard.enums = enums;

export default withStyles(styles)(NextStepsCard);
