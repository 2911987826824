import _ from 'lodash';
import React, { PureComponent } from 'react';
import { withApollo, WithApolloClient } from 'react-apollo';
import { ReactCookieProps, withCookies } from 'react-cookie';
import { RouteComponentProps, withRouter } from 'react-router';

import Globals from '~web-manage/lib/common/globals';
import NavBar from '~web-manage/lib/common/components/NavBar';

import { PaymentSubscriptionPlans } from '~tools/react/hocs/withPaymentSubscription';
import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';

import { NotificationTypes } from '~tools/types/graphqlSchema';

import DropdownMenu from '~tools/react/components/DropdownMenu';
import SkeletonCircle from '~tools/react/components/SkeletonCircle';

import Notifications from './containers/Notifications';
import Search from './containers/Search';

import query from './Navigation.gql';

interface InputProps {
  paymentSubscription?: PaymentSubscriptionPlans;
}

type Props =
  InputProps &
  WithApolloClient<
    QueryProps &
    ReactCookieProps &
    RouteComponentProps
  >;

interface State {
  isAccountMenuOpen: boolean;
  isNotificationsOpen: boolean;
}

class Navigation extends PureComponent<Props, State> {
  state: State = {
    isAccountMenuOpen: false,
    isNotificationsOpen: false,
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickDocument);
  }

  handleClickDocument = () => {
    this.closeNotifications();
    this.closeAccountMenu();
  };

  handleClickNotifications = (evt: React.MouseEvent) => {
    // Prevent document click handler from running and closing the modal
    evt.nativeEvent.stopImmediatePropagation();
  };

  handleClickAvatar = (evt: React.MouseEvent) => {
    evt.nativeEvent.stopImmediatePropagation();
    document.addEventListener('click', this.handleClickDocument);

    this.setState({
      isAccountMenuOpen: !this.state.isAccountMenuOpen,
      isNotificationsOpen: false,
    });
  };

  handleToggleNotifications = (evt: React.MouseEvent) => {
    evt.nativeEvent.stopImmediatePropagation();
    if (this.state.isNotificationsOpen) {
      this.closeNotifications();
      return;
    }

    this.openNotifications();
  }

  handleLogout = () => {
    if (!this.props.cookies) return;
    this.props.cookies.remove('token', {
      domain: Globals.COOKIE_DOMAIN,
      path: '/',
    });
    this.props.client.resetStore();
    this.props.history.push('/login');
  };

  render() {
    const viewer = this.props.viewer;
    if (!viewer || this.props.isLoading) {
      return (
        <NavBar>
          <Search />
          <NavBar.NavBarIcon
            icon={NavBar.NavBarIcon.enums.Icons.HelpCircle}
            label="Help"
            link={{
              path: `${Globals.WEB_CORE_DOMAIN}/support`,
            }}
          />
          <NavBar.NavBarIcon
            icon={NavBar.NavBarIcon.enums.Icons.Bell}
            label="Activity"
            needsActionCount={0}
            onClick={this.handleToggleNotifications}
          />
          <SkeletonCircle size={SkeletonCircle.enums.Sizes.Small} />
        </NavBar>
      );
    }

    return (
      <NavBar>
        <NavBar.NavBarLogo paymentSubscription={this.props.paymentSubscription} />
        <NavBar.NavBarDivider />
        <NavBar.NavBarAvatar
          name={viewer.fullName}
          imageUrl={viewer.photo?.url || viewer.defaultPhotoUrl}
          onClick={this.handleClickAvatar}>
          {this.state.isAccountMenuOpen ? (
            <DropdownMenu
              carotPosition={{
                left: '25px',
              }}
              position={{
                left: '-18px',
                top: '50px',
              }}
              sections={[{
                items: [{
                  label: 'Log out',
                  onClick: this.handleLogout,
                }],
              }]}
            />
          ) : null}
        </NavBar.NavBarAvatar>
        <Search />
        <NavBar.NavBarDivider />
        <NavBar.NavBarIcon
          icon={NavBar.NavBarIcon.enums.Icons.HelpCircle}
          label="Help"
          link={{
            path: `${Globals.WEB_CORE_DOMAIN}/support`,
            shouldOpenNewTab: true,
          }}
        />
        <NavBar.NavBarIcon
          icon={NavBar.NavBarIcon.enums.Icons.Bell}
          label="Activity"
          needsActionCount={_.filter(viewer.notifications, node => !node.readAt).length}
          onClick={this.handleToggleNotifications}>
          {this.state.isNotificationsOpen ? (
            <Notifications
              notifications={viewer.notifications}
              onClick={this.handleClickNotifications}
              refetch={this.props.refetch}
            />
          ) : null}
        </NavBar.NavBarIcon>
      </NavBar>
    );
  }

  closeNotifications = () => {
    document.removeEventListener('click', this.handleClickDocument);
    this.setState({ isNotificationsOpen: false });
  };

  closeAccountMenu = () => {
    document.removeEventListener('click', this.handleClickDocument);
    this.setState({ isAccountMenuOpen: false });
  };

  openNotifications = () => {
    document.addEventListener('click', this.handleClickDocument);
    this.setState({ isNotificationsOpen: true, isAccountMenuOpen: false });
  };
}

interface Viewer {
  uuid: string;
  defaultPhotoUrl: string;
  firstName: string;
  fullName: string;
  notifications: {
    context: string,
    createdAt: string,
    readAt: string | null,
    text: string,
    type: NotificationTypes,
    uuid: string,
  }[];
  photo: {
    uuid: string;
    url: string;
  } | null;
}

interface Response {
  viewer: Viewer;
}

interface QueryProps {
  isLoading: boolean;
  refetch: () => Promise<any>;
  viewer: Viewer | null;
}

export default compose(
  withApollo,
  withCookies,
  withRouter,
  withQuery<{}, Response, {}, QueryProps>(query, {
    props: (props) => ({
      isLoading: props.loading,
      refetch: props.refetch,
      viewer: props.data?.viewer ?? null,
    }),
  }),
)(Navigation);
