import { ApolloQueryResult } from 'apollo-client';
import _ from 'lodash';
import queryString from 'query-string';
import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Globals from '~web-manage/lib/common/globals';

import { ListingStatuses, PrivatePropertyManagerContractsFilterInput } from '~tools/types/graphqlSchema';

import { pageToCursor } from '~tools/react/graphql/utils';
import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withPaymentSubscription, {
  PaymentSubscriptionProps,
} from '~tools/react/hocs/withPaymentSubscription';

import { downloadFileUrl } from '~tools/utils/download';
import { formatUnitName } from '~web-manage/lib/common/utils/addressUnit';

import Table from '~tools/react/components/Table';
import Card from '~tools/react/components/Card';
import Tag from '~tools/react/components/Tag';
import Text from '~tools/react/components/Text';
import ManagePlanModal from '~tools/react/containers/ManagePlanModal';
import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import AddUnitModal from './containers/AddUnitModal';

import query from './Units.gql';

type Props =
  InputProps &
  QueryProps &
  PaymentSubscriptionProps;

interface State {
  isAddUnitModalOpen: boolean;
  isExportEnabled: boolean;
  isFiltersModalOpen: boolean;
  isManagePlanModalOpen: boolean;
}

const NUMBER_OF_UNITS_TO_FETCH = 10;

class Units extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isAddUnitModalOpen: false,
      isExportEnabled: true,
      isFiltersModalOpen: false,
      isManagePlanModalOpen: false,
    };
  }

  handleChangePlan = () => this.openAddUnitModal();
  handleSetFilters = (filter: PrivatePropertyManagerContractsFilterInput) => {
    this.props.refetch({ filter });
    this.props.refetch();
    this.handleToggleFiltersModal();
  }

  handleToggleFiltersModal = () => {
    this.setState({ isFiltersModalOpen: !this.state.isFiltersModalOpen });
  }

  handleExport = () => {
    if (!this.state.isExportEnabled) return;
    this.setState({ isExportEnabled: false });

    downloadFileUrl(`${Globals.API_CORE_DOMAIN}/user-exports/units`);

    // Leave the button disabled for a few seconds so the user doesn't double download the file.
    setTimeout(() => this.setState({ isExportEnabled: true }), 5000);
  };

  render() {
    const propertyManagerContracts = _.filter(
      this.props.propertyManagerContracts,
      propertyManagerContract => !!propertyManagerContract.addressUnit,
    );
    return (
      <ManageStage>
        <AddUnitModal
          isOpen={this.state.isAddUnitModalOpen}
          onAddUnit={this.props.refetch}
          onClose={this.closeAddUnitModal}
        />
        <ManagePlanModal
          isOpen={this.state.isManagePlanModalOpen}
          onChangePlan={this.handleChangePlan}
          onClose={this.closeManagePlanModal}
        />
        <Card
          header={{
            actions: [
              {
                label: 'Add unit',
                color: Card.enums.ActionColors.BrandAdvancedPlan,
                icon: Card.enums.ActionIcons.Plus,
                onClick: this.openAddUnitModal,
              },
              {
                label: this.state.isExportEnabled ? 'Export' : 'Exported!',
                icon: this.state.isExportEnabled ? Card.enums.ActionIcons.Download : Card.enums.ActionIcons.Check,
                isDisabled: !this.state.isExportEnabled,
                onClick: this.handleExport,
              },
            ],
            subtitle: 'Add and update units',
            title: 'All units',
          }}>
          <Table>
            <Table.TableHead>
              <Table.TableRow>
                <Table.TableHeader>Address</Table.TableHeader>
                <Table.TableHeader>Unit</Table.TableHeader>
                <Table.TableHeader>Occupancy</Table.TableHeader>
                <Table.TableHeader>Market status</Table.TableHeader>
                <Table.TableHeader></Table.TableHeader>
              </Table.TableRow>
            </Table.TableHead>
            <Table.TableBody>
              {_.map(propertyManagerContracts, (propertyManagerContract) => {
                const occupancyStatus = this.getOccupancyStatus(propertyManagerContract.addressUnit);
                const marketStatus = this.getMarketStatus(propertyManagerContract.listing);
                return (
                  <Table.TableRow
                    key={propertyManagerContract.uuid}
                    link={{ path: `/units/${propertyManagerContract.uuid}` }}>
                    <Table.TableData>
                      <Text content={propertyManagerContract.addressUnit.address.streetAddress1} />
                    </Table.TableData>
                    <Table.TableData width={Table.TableData.enums.Widths.AUTO}>
                      <Text content={this.getUnitName(propertyManagerContract.addressUnit)} />
                    </Table.TableData>
                    <Table.TableData>
                      <Tag
                        label={occupancyStatus.label}
                        color={occupancyStatus.tagColor}
                        icon={occupancyStatus.icon}
                      />
                    </Table.TableData>
                    <Table.TableData>
                      <Tag
                        label={marketStatus.label}
                        color={marketStatus.tagColor}
                      />
                    </Table.TableData>
                  </Table.TableRow>
                );
              })}
              {this.props.propertyManagerContractsPageInfo ? (
                <Table.TablePaginator
                  startCursor={this.props.propertyManagerContractsPageInfo.startCursor || ''}
                  endCursor={this.props.propertyManagerContractsPageInfo.endCursor || ''}
                  resultsPerPage={NUMBER_OF_UNITS_TO_FETCH}
                  totalResults={this.props.propertyManagerContractsTotal}
                />
              ) : null}
            </Table.TableBody>
          </Table>
        </Card>
      </ManageStage>
    );
  }

  getUnitName = (unit: AddressUnit) => {
    if (!unit.name) return '-';
    return formatUnitName(unit);
  }

  getOccupancyStatus = (unit: AddressUnit) => {
    if (unit.lease && !unit.lease.lessee) return { label: 'PENDING', tagColor: Tag.enums.Colors.Blue, icon: Tag.enums.Icons.Clock };
    if (unit.lease) return { label: 'OCCUPIED', tagColor: Tag.enums.Colors.Green };
    return { label: 'VACANT', tagColor: Tag.enums.Colors.Gray };
  }

  getMarketStatus = (listing: Listing) => {
    const label = _.upperCase(listing.status);
    switch (listing.status) {
      case ListingStatuses.LISTED:
        return { label, tagColor: Tag.enums.Colors.Green };
      case ListingStatuses.SECURED:
        return { label, tagColor: Tag.enums.Colors.Blue };
      case ListingStatuses.DRAFT:
      case ListingStatuses.UNLISTED:
      default:
        return { label, tagColor: undefined };
    }
  }

  closeManagePlanModal = () => this.setState({ isManagePlanModalOpen: false });
  openManagePlanModal = () => this.setState({ isManagePlanModalOpen: true });

  closeAddUnitModal = () => this.setState({ isAddUnitModalOpen: false });
  openAddUnitModal = () => this.setState({ isAddUnitModalOpen: true });
}

interface AddressUnit {
  uuid: string;
  name: string | null;
  address: {
    streetAddress1: string;
    uuid: string;
  }
  lease: {
    leaseInvite: {
      uuid: string;
    } | null;
    lessee: {
      uuid: string;
    } | null;
    uuid: string;
  } | null;
}

interface Listing {
  status: ListingStatuses;
  uuid: string;
}

interface PropertyManagerContract {
  addressUnit: AddressUnit;
  listing: Listing;
  uuid: string
}

interface Response {
  viewer: {
    uuid: string;
    propertyManagerContracts: PropertyManagerContract[];
    propertyManagerContractsTotal: number;
    propertyManagerContractsPageInfo: {
      endCursor: string | null;
      startCursor: string | null;
    }
  }
}

interface Variables {
  after?: string;
  filter?: {
    isActive?: boolean | null;
  };
  first?: number;
}

type InputProps = RouteComponentProps;

interface QueryProps {
  isLoading: boolean;
  propertyManagerContracts: PropertyManagerContract[];
  propertyManagerContractsTotal: number;
  propertyManagerContractsPageInfo: {
    endCursor: string | null;
    startCursor: string | null;
  } | null;
  refetch: (variables?: Variables) => Promise<ApolloQueryResult<Response>>;
}

export default compose(
  withPaymentSubscription,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: (props) => {
      const pageString = queryString.parse(props.location.search).page;
      const page = _.parseInt(typeof pageString === 'string' ? pageString : '1') ?? undefined;
      const after = page > 1 ? pageToCursor(page, NUMBER_OF_UNITS_TO_FETCH) : undefined;
      return {
        variables: {
          after,
          filter: {
            isActive: true,
          },
          first: NUMBER_OF_UNITS_TO_FETCH,
        },
      };
    },
    props: (props) => ({
      isLoading: props.loading,
      propertyManagerContracts: props.data?.viewer?.propertyManagerContracts ?? [],
      propertyManagerContractsPageInfo: props.data?.viewer?.propertyManagerContractsPageInfo ?? null,
      propertyManagerContractsTotal: props.data?.viewer?.propertyManagerContractsTotal ?? 0,
      refetch: props.refetch,
    }),
  }),
)(Units);
