// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._wdjp9z{width:65%}@media screen and (max-width:1127px){._wdjp9z{width:100%}}", ""]);
// Exports
exports.locals = {
	"chart-view": "_wdjp9z"
};
module.exports = exports;
