import classNames from 'classnames';
import moment from 'moment';
import React, { Fragment, PureComponent } from 'react';

import { NotificationTypes } from '~tools/types/graphqlSchema';
import withStyles from '~tools/react/hocs/withStyles';

import Link from '~tools/react/components/utility/Link';

import Avatar from '~tools/react/components/Avatar';
import Text from '~tools/react/components/Text';

import globals from '~web-manage/lib/common/globals';

import styles from './Notification.scss';

interface Props {
  context: string,
  createdAt: string,
  id: string,
  onMarkAsRead: (uuid: string) => void,
  readAt: string | null,
  text: string,
  type: NotificationTypes;
}

class Notification extends PureComponent<Props> {
  render() {
    const context = JSON.parse(this.props.context);
    const type = this.props.type.toUpperCase();
    const route = this.getRouteFromType(context, type);
    const avatarUrl = this.getUserImageUrl();
    const classes = classNames({
      [styles.notification]: true,
      [styles['notification--unread']]: !this.props.readAt,
    });
    const contents = (
      <Fragment>
        <div styleName="notification__avatar">
          {avatarUrl ? (
            <Avatar imageUrl={avatarUrl} />
          ) : null}
        </div>
        <div styleName="notification__copy">
          <Text
            content={this.props.text}
            size={Text.enums.Sizes.Small}
          />
          <Text
            color={Text.enums.Colors.Secondary}
            content={moment(this.props.createdAt).fromNow()}
            size={Text.enums.Sizes.Small}
          />
        </div>
      </Fragment>
    );

    if (!route) {
      return (
        <button
          className={classes}
          onClick={this.markAsRead}>
          {contents}
        </button>
      );
    }

    return (
      <Link
        className={classes}
        onClick={this.markAsRead}
        to={route}>
        {contents}
      </Link>
    );
  }

  getRouteFromType = (context, type) => {
    switch (type) {
      case NotificationTypes.LEASE_SIGNED_BY_LESSEE:
        return `/leases/${context.lease.uuid}`;
      case NotificationTypes.NEW_LISTING_ANSWER:  // DEPRECATED
        return `${globals.WEB_CORE_DOMAIN}/listing/${context.listing.uuid}#questions`;
      case NotificationTypes.NEW_PRIVATE_LISTING_ANSWER:
        return `${globals.WEB_CORE_DOMAIN}/listing-questions/answered`;
      case NotificationTypes.NEW_PUBLIC_LISTING_ANSWER:
        return `${globals.WEB_CORE_DOMAIN}/listing/${context.listing.uuid}#questions`;
      case NotificationTypes.NEW_LISTING_QUESTION:
        if (context.listingQuestion) return `/questions/${context.listingQuestion.uuid}`;
        return '/questions';
      case NotificationTypes.NEW_MESSAGE:
        return `/messages/${context.conversation.uuid}`;
      case NotificationTypes.ISSUE_REPORTED:
        if (context.conditionReport?.uuid) return `/issues/${context.conditionReport.uuid}`;
        return `/issues`;
      case NotificationTypes.GENERAL:
      default:
        return null;
    }
  };

  getUserImageUrl = () => {
    const context = JSON.parse(this.props.context);

    switch (this.props.type) {
      case NotificationTypes.LEASE_SIGNED_BY_LESSEE: {
        if (!context.renter) return null;
        return context.renter.photoUrl;
      }
      case NotificationTypes.NEW_MESSAGE: {
        if (!context.sender) return null;
        return context.sender.photoUrl;
      }
      case NotificationTypes.GENERAL:
      default:
        return null;
    }
  };

  markAsRead = () => this.props.onMarkAsRead(this.props.id);

  shouldRenderIcon = () => {
    switch (this.props.type) {
      case NotificationTypes.LEASE_SIGNED_BY_LESSEE:
      case NotificationTypes.NEW_MESSAGE:
        return true;
      case NotificationTypes.GENERAL:
      default:
        return false;
    }
  };
}

export default withStyles(styles)(Notification);
