import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';

import GenericLoadingVisual from '~tools/react/components/GenericLoadingVisual';
import Card from '~tools/react/components/Card';

import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import Text from '~tools/react/components/Text';
import Row from '~tools/react/components/Row';
import Heading from '~tools/react/components/Heading';
import Button from '~tools/react/components/Button';
import ImageModal from '~tools/react/components/ImageModal';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';
import { formatShortAddress } from '~web-manage/lib/common/utils/addressUnit';
import globals from '~web-manage/lib/common/globals';

import AuthorHeader from '../AuthorHeader';
import { ConditionInspection, ReportPhoto } from '../../types';
import InspectionRoom from './components/InspectionRoom';

interface Props {
  conditionInspection: ConditionInspection | null;
  viewerUuid: string | null;
  onPrint: () => void;
}

interface State {
  imageModal: {
    headline: string;
    isOpen: boolean;
    photos: ReportPhoto[];
  };
}

class InspectionSummary extends Component<Props, State> {
  state: State = {
    imageModal: {
      headline: '',
      isOpen: false,
      photos: [],
    },
  };

  handleCloseImageModal = () => {
    this.setState({
      imageModal: {
        headline: '',
        isOpen: false,
        photos: [],
      },
    });
  }

  handleOpenImageModal = (headline: string, photos: ReportPhoto[]) => {
    this.setState({
      imageModal: {
        headline,
        photos,
        isOpen: true,
      },
    });
  }

  render() {
    const conditionInspection = this.props.conditionInspection;

    if (!conditionInspection) {
      return (
        <ManageStage>
          <GenericLoadingVisual />
        </ManageStage>
      );
    }

    const addressUnitRooms = _.sortBy(conditionInspection.addressUnit.addressUnitRooms, r => r.name);

    const authorIsViewer = this.props.viewerUuid === conditionInspection.inspectorAccount.uuid;

    return (
      <ManageStage>
        <Card>
          <Card.CardSection>
            <Row
              flexBehavior={Row.enums.FlexBehaviors.Default}
              verticalAlignment={Row.enums.VerticalAlignments.Center}>
              <Heading
                content="Inspection report"
                font={Heading.enums.Fonts.Secondary}
                size={Heading.enums.Sizes.Small}
              />
              <Button
                align={Button.enums.Alignments.Right}
                color={Button.enums.Colors.Gray}
                label="Print this report"
                onClick={this.props.onPrint}
                size={Button.enums.Sizes.Small}
                style={Button.enums.Styles.Outline}
              />
            </Row>
            <Text
              content={`${formatShortAddress(conditionInspection.addressUnit)} · Submitted ${moment(conditionInspection.createdAt).format('MMM DD, YYYY')}`}
              size={Text.enums.Sizes.Small}
            />
          </Card.CardSection>
          {!conditionInspection.lockedAt && authorIsViewer ? (
            <Card.CardAlert
              action={{
                label: 'Resume inspection',
                link: {
                  shouldOpenNewTab: true,
                  path: `${globals.WEB_CORE_DOMAIN}/inspections/${conditionInspection.uuid}/setup/bedrooms`,
                },
              }}
              color={Card.CardAlert.enums.Colors.Blue}
              icon={Card.CardAlert.enums.Icons.Alert}
              message="You haven't completed this inspection."
            />
          ) : null}
          {!conditionInspection.lockedAt && !authorIsViewer ? (
            <Card.CardAlert
              color={Card.CardAlert.enums.Colors.Blue}
              icon={Card.CardAlert.enums.Icons.Alert}
              message="This inspection is in progress, the issues reported below could change until the inspection is finalized."
            />
          ) : null}
          <AuthorHeader
            name={conditionInspection.inspectorAccount.fullName}
            photoUrl={conditionInspection.inspectorAccount.photoUrl}
          />
          <Card.CardSection>
            {addressUnitRooms.map(room => {
              const reports = conditionInspection.conditionReports.filter(r => r.addressUnitRoom.uuid === room.uuid);

              return (
                <div key={room.uuid}>
                  <InspectionRoom
                    room={room}
                    reports={reports}
                    onOpenImageModal={this.handleOpenImageModal}
                  />
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
                </div>
              );
            })}
          </Card.CardSection>
        </Card>
        <ImageModal
          isMobile={false} // TODO
          isOpen={this.state.imageModal.isOpen}
          photos={this.state.imageModal.photos}
          headline={this.state.imageModal.headline}
          onClose={this.handleCloseImageModal}
        />
      </ManageStage>
    );
  }
}

export default InspectionSummary;
