import withMutation from '~tools/react/graphql/withMutation';

import PauseListingBoostMutation from './pauseListingBoost.gql';

interface ListingBoost {
  isActive: boolean;
  uuid: string;
}

interface Data {
  pauseListingBoost: ListingBoost;
}

interface Variables {
  uuid: string;
}

export interface PauseListingBoostProps {
  pauseListingBoost: (uuid: string) => Promise<ListingBoost>;
}

export default withMutation<{}, Data, Variables, PauseListingBoostProps>(PauseListingBoostMutation, {
  props: props => ({
    pauseListingBoost: async (uuid) => {
      const res = await props.mutate({
        variables: {
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error pausing listing boost.');

      return res.data.pauseListingBoost;
    },
  }),
});
