import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './CheckableList.scss';

interface Props {
  children: ReactNode;
}

function CheckableList(props: Props) {
  return (
    <div>
      {props.children}
    </div>
  );
}

export default withStyles(styles)(CheckableList);
