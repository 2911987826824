// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._aeR557,._aeR557 ._GN6tXn{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;position:relative}._aeR557 ._GN6tXn{-webkit-box-align:start;align-items:flex-start;margin-bottom:32px;padding:0 24px 0 56px}._aeR557 ._GN6tXn p{line-height:1.8em!important}._aeR557 ._GN6tXn:before{-webkit-box-align:center;align-items:center;background-color:#1f8eed;border-radius:50%;color:#fff;content:\"Q\";display:-webkit-inline-box;display:inline-flex;font-size:18px;font-weight:700;height:30px;-webkit-box-pack:center;justify-content:center;left:0;line-height:12px;margin-right:32px;min-width:30px;position:absolute;text-align:center;top:3px;width:30px}@media screen and (max-width:1127px){._aeR557 ._GN6tXn{padding-right:0}._aeR557 ._GN6tXn:before{left:10px}}._aeR557 ._br0dx5{padding:0 24px 0 56px;position:relative}._aeR557 ._br0dx5:before{-webkit-box-align:center;align-items:center;background-color:#1f8eed;border-radius:50%;color:#fff;content:\"A\";display:-webkit-inline-box;display:inline-flex;font-size:18px;font-weight:700;height:30px;-webkit-box-pack:center;justify-content:center;left:0;line-height:12px;min-width:30px;opacity:.5;position:absolute;text-align:center;top:0;width:30px}@media screen and (max-width:1127px){._aeR557 ._br0dx5{padding-right:0}._aeR557 ._br0dx5:before{left:10px}}", ""]);
// Exports
exports.locals = {
	"q-and-a": "_aeR557",
	"q-and-a__question": "_GN6tXn",
	"q-and-a__answer": "_br0dx5"
};
module.exports = exports;
