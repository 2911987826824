import React from 'react';
import Text from '~tools/react/components/Text';
import ThemedModal from '~tools/react/components/ThemedModal';
import TextLink from '~tools/react/components/TextLink';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

interface Props {
  isOpen: boolean;
  listingReferenceId: string;
  onClose: () => void;
}

function GetAutoReplyEmailModal(props: Props) {
  return (
    <ThemedModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Get auto-reply email"
      width={ThemedModal.enums.Widths.Small}>
      <ThemedModal.ThemedModalSection>
        <Text
          content="Post this email address on any sites you'd like to advertise your listing. We'll automatically reply to any messages with a link to your listing on Apartment.app."
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
        <TextLink
          path={`inquiries+ct-${props.listingReferenceId}@listings.caretaker.com`}
          size={TextLink.enums.Sizes.Medium}
        />
      </ThemedModal.ThemedModalSection>
    </ThemedModal>
  );
}

export default GetAutoReplyEmailModal;
