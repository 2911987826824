// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._APR5eB{-webkit-box-pack:center;justify-content:center;margin:0 auto 0 0}._APR5eB,._APR5eB ._mmJOro{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex}._APR5eB ._mmJOro svg{height:24px;opacity:1;-webkit-transition:opacity .1s;transition:opacity .1s}._APR5eB ._mmJOro:hover svg{opacity:.8}@media screen and (max-width:1127px){._APR5eB{margin:24px 0 16px}}", ""]);
// Exports
exports.locals = {
	"flip-logo": "_APR5eB",
	"flip-logo__svg": "_mmJOro"
};
module.exports = exports;
