export enum Sections {
  PropertyBasics = 'Property Basics',
  LeasingDetails = 'Leasing Details',
  PostDetails = 'Post Details',
}

export enum Statuses {
  Listed = 'LISTED',
  Unlisted = 'UNLISTED',
}

export enum Steps {
  Amenities = 'Amenities',
  Availability = 'Availability',
  Description = 'Description',
  Extras = 'Extras',
  Payments = 'Payments',
  Photos = 'Photos',
  Requirements = 'Requirements',
  Rooms = 'Rooms',
}
