// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._YXrDne{margin-top:32px}._ern2mA{display:-webkit-box;display:flex}._ern2mA ._QDoXba{display:-webkit-box;display:flex;-webkit-box-align:end;align-items:flex-end;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;min-width:140px;margin-right:24px}._ern2mA ._WVjp0a{z-index:4}._ern2mA ._32AuWX{margin-left:-21px;border-left:1px solid #e6e8eb;padding:0 0 40px 40px;-webkit-box-flex:1;flex-grow:1}._ern2mA ._32AuWX ._aeRIBV{display:-webkit-box;display:flex;-webkit-box-align:center;align-items:center}._ern2mA ._32AuWX ._aeRIBV p{font-size:14px;margin:8px 0}._ern2mA ._32AuWX ._aeRIBV svg{height:18px;width:18px}", ""]);
// Exports
exports.locals = {
	"timeline": "_YXrDne",
	"timeline-entry": "_ern2mA",
	"timeline-entry--left": "_QDoXba",
	"timeline-entry--center": "_WVjp0a",
	"timeline-entry--right": "_32AuWX",
	"timeline-entry--right--link": "_aeRIBV"
};
module.exports = exports;
