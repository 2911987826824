import _ from 'lodash';
import pluralize from 'pluralize';
import React, { Component, SyntheticEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { AddressUnitUnitTypes, ListingFurnishingTypes, ListingTypes } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';

import withQuery from '~tools/react/graphql/withQuery';

import AnimatedStepsFlow from '~tools/react/components/AnimatedStepsFlow';
import Button from '~tools/react/components/Button';
import Form from '~tools/react/components/Form';
import FormField from '~tools/react/components/Form/components/FormField';
import IconRadioButtons from '~tools/react/components/IconRadioButtons';
import Select from '~tools/react/components/Form/components/Select';
import { convertEnumToSelectOptions } from '~tools/react/components/Form/components/Select/utils';

import { OnUpdateListing } from '~web-manage/lib/common/scenes/ListingSetup/types';
import ListingSetupInfoBlock from '~web-manage/lib/common/scenes/ListingSetup/components/ListingSetupInfoBlock';

import query from './ListingSetupRooms.gql';

interface InputProps extends RouteComponentProps<{ listingUuid: string }> {
  onUpdateListing: OnUpdateListing;
  onUpdateAddressUnit: (addressUnit: { unitType: AddressUnitUnitTypes }) => Promise<void>;
}

type Props = InputProps & QueryProps;

interface State {
  furnishingType: ListingFurnishingTypes | undefined;
}

class ListingSetupRooms extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      furnishingType: props.listing?.furnishingType || undefined,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.furnishingType && !this.state.furnishingType) {
      this.setState({ furnishingType: nextProps.furnishingType });
    }
  }

  handleSubmit = () => this.props.history.push('amenities');

  render() {
    const bedroomItems = _.times(9, (i) => {
      if (!i) return { label: 'Studio', value: 0 };
      return { label: pluralize('bedroom', i, true), value: i };
    });
    const bathroomItems = _.times(18, (i) => {
      if (!i) return { label: '0 bathrooms', value: 0 };
      return { label: pluralize('bathroom', i / 2, true), value: i / 2 };
    });
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.FormSection columns={Form.FormSection.enums.Columns.Two}>
          <Select
            isRequired={true}
            options={bedroomItems}
            label="Bedrooms"
            labelFormat={Select.enums.LabelFormats.Stacked}
            name="bedrooms"
            onChange={this.updateRooms}
            placeholder="Select number of bedrooms"
            value={this.props.listing?.bedrooms || undefined}
          />
          <Select
            isRequired={true}
            options={bathroomItems}
            label="Bathrooms"
            labelFormat={Select.enums.LabelFormats.Stacked}
            name="bathrooms"
            onChange={this.updateRooms}
            placeholder="Select number of bathrooms"
            value={this.props.listing?.bathrooms || undefined}
          />
        </Form.FormSection>
        <Form.FormSection columns={Form.FormSection.enums.Columns.Two}>
          <Select
            options={[
              {
                label: 'Furnished',
                value: ListingFurnishingTypes.FURNISHED,
              },
              {
                label: 'Partially Furnished',
                value: ListingFurnishingTypes.PARTIALLY_FURNISHED,
              },
              {
                label: 'Unfurnished',
                value: ListingFurnishingTypes.UNFURNISHED,
              },
            ]}
            label="Is the room furnished?"
            labelFormat={Select.enums.LabelFormats.Stacked}
            name="furnishingType"
            onChange={this.updateRooms}
            placeholder="Select type of furnishing"
            value={this.state.furnishingType}
          />
          <Select
            options={convertEnumToSelectOptions(AddressUnitUnitTypes)}
            label="What is the type of this unit?"
            labelFormat={Select.enums.LabelFormats.Stacked}
            name="unitType"
            onChange={this.updateUnitType}
            placeholder="Select the unit's type"
            value={this.props.listing?.addressUnit?.unitType || undefined}
          />
        </Form.FormSection>
        {this.state.furnishingType ? (
          <Form.FormSection>
            <ListingSetupInfoBlock
              description={this.getFurnishedCopy()}
              icon={this.getFurnishedIcon()}
            />
          </Form.FormSection>
        ) : null}
        <FormField label="Type of listing" labelFormat={FormField.enums.LabelFormats.Stacked}>
          <IconRadioButtons
            onChange={this.updateListingType}
            options={[{
              icon: IconRadioButtons.enums.Icons.BedWindow,
              subtitle: 'One private bedroom with shared common space',
              title: 'Private room',
              value: ListingTypes.PRIVATE_ROOM,
            }, {
              icon: IconRadioButtons.enums.Icons.House,
              subtitle: 'Your renter has control of the entire unit',
              title: 'Entire place',
              value: ListingTypes.ENTIRE_PLACE,
            }]}
            value={this.props.listing?.listingType}
          />
        </FormField>
        <AnimatedStepsFlow.AnimatedStepsFlowStep.AnimatedStepsFlowStepFooter
          primaryAction={{
            label: 'Continue',
            type: Button.enums.Types.Submit,
          }}
          secondaryLink={{
            path: 'location',
          }}
        />
      </Form>
    );
  }

  getFurnishedIcon = () => {
    switch (this.state.furnishingType) {
      case ListingFurnishingTypes.FURNISHED:
        return ListingSetupInfoBlock.enums.Icons.Sofa;
      case ListingFurnishingTypes.PARTIALLY_FURNISHED:
        return ListingSetupInfoBlock.enums.Icons.ArmChair;
      default:
      case ListingFurnishingTypes.UNFURNISHED:
        return undefined;
    }
  }

  getFurnishedCopy = () => {
    switch (this.state.furnishingType) {
      case ListingFurnishingTypes.FURNISHED:
        return 'Your listing is move in ready.';
      case ListingFurnishingTypes.PARTIALLY_FURNISHED:
        return 'This listing has the basics (at least a bed and couch).';
      default:
      case ListingFurnishingTypes.UNFURNISHED:
        return 'Your listing has no furniture.';
    }
  }

  updateRooms = (data, e?: SyntheticEvent<any>) => {
    // fucking burak
    if (!e) return;

    const field = e.currentTarget.getAttribute('name');
    if (field && _.get(this.props, field) !== data) {
      this.props.onUpdateListing({ [field]: data });
    }
    if (field === 'furnishingType') {
      this.setState({ furnishingType: data });
    }
  }

  updateListingType = async (value: string) => this.props.onUpdateListing({ listingType: _.snakeCase(value) });
  updateUnitType = async (value: string) => this.props.onUpdateAddressUnit({ unitType: value as AddressUnitUnitTypes });
}

interface Listing {
  bathrooms: number | null;
  bedrooms: number | null;
  furnishingType: ListingFurnishingTypes;
  listingType: ListingTypes;
  addressUnit: {
    uuid: string;
    unitType: AddressUnitUnitTypes | null;
  };
}

interface Response {
  viewer: {
    listing: Listing | null;
    uuid: string;
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  listing: Listing | null;
}

interface Variables {
  listingUuid: string;
}

export default compose(
  withRouter,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: props => ({
      variables: {
        listingUuid: props.match.params.listingUuid,
      },
      skip: !props.match.params.listingUuid,
      ssr: false,
    }),
    props: props => ({
      isLoading: props.loading,
      listing: props.data?.viewer?.listing || null,
    }),
  }),
)(ListingSetupRooms);
