import _ from 'lodash';
import React, { Component } from 'react';

import withPlacesAutocomplete, { WithPlacesAutocompleteProps } from '~tools/react/hocs/withPlacesAutocomplete';

// Direct (non-loadable) import to avoid race-cases in Forms
import Typeahead from '~tools/react/components/Form/components/Typeahead/Typeahead';

import * as enums from './enums';

interface TypeaheadItem {
  label: string;
  value: string;
}

interface AddressComponent {
  longName: string;
  shortName: string;
}

export interface GeocodeTypeaheadResult {
  addressComponents: {
    [attr: string]: AddressComponent;
  };
  bounds: {
    maxLat: number;
    maxLng: number;
    minLat: number;
    minLng: number;
  };
  center: {
    lng: number;
    lat: number;
  };
  placeId: string;
  value: string;
}

interface InputProps {
  icon?: enums.Icons;
  isDisabled?: boolean;
  isLoading?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  label?: string;
  labelFormat?: enums.LabelFormats;
  name: string;
  onChange?: (value: string) => void;
  onSubmit?: (result: GeocodeTypeaheadResult) => void;
  placeholder?: string;
  size?: enums.Sizes;
  value?: string;
}

interface State {
  hasSubmitted: boolean;
  input: string;
  isLoading: boolean;
  typeaheadItems: TypeaheadItem[];
}

type Props =
  InputProps &
  WithPlacesAutocompleteProps;

class GeocodeTypeahead extends Component<Props, State> {
  static enums = enums;

  constructor(props: Props) {
    super(props);
    this.state = {
      hasSubmitted: true,
      input: props.value || '',
      isLoading: false,
      typeaheadItems: [],
    };
  }

  componentDidUpdate(prevProps: Props) {
    if ((this.props.value || this.props.value === '') && this.props.value !== prevProps.value && this.state.input !== this.props.value) {
      this.setState({ input: this.props.value });
    }
  }

  handleBlur = async (value: string) => {
    if (!value) return;
    if (this.state.hasSubmitted) return;
    this.setState({ isLoading: true });

    if (!this.props.getPlacePredictions) return;

    const placePredictions = await this.props.getPlacePredictions(value);
    if (!placePredictions || !placePredictions.length) return;

    this.handleSelectSuggestion({
      value: placePredictions[0].place_id,
      label: placePredictions[0].description,
    });
  }

  handleSelectSuggestion = async (data: { label: string, value: string }) => {
    this.setState({ isLoading: true, hasSubmitted: true });

    if (!this.props.getPlaceDetails) return;
    const placeResult = await this.props.getPlaceDetails(data.value);
    if (!placeResult) {
      this.setState({ isLoading: false });
      return;
    }

    const southWest = placeResult.geometry.viewport.getSouthWest();
    const northEast = placeResult.geometry.viewport.getNorthEast();
    const center = placeResult.geometry.viewport.getCenter();
    this.setState({
      typeaheadItems: [],
      isLoading: false,
    });
    const addressComponents = {};
    const typesToGmapsTypes = _.invert(enums.AddressTypes);
    _.forEach(placeResult.address_components, address => _.forEach(address.types, (type) => {
      const keyedType = _.get(typesToGmapsTypes, type);
      if (keyedType) {
        addressComponents[type] = {
          longName: address.long_name,
          shortName: address.short_name,
        };
      }
    }));

    const onSubmit = this.props.onSubmit;
    if (!onSubmit) return;
    onSubmit({
      addressComponents,
      bounds: {
        maxLat: northEast.lat(),
        maxLng: northEast.lng(),
        minLat: southWest.lat(),
        minLng: southWest.lng(),
      },
      center: {
        lng: center.lng(),
        lat: center.lat(),
      },
      placeId: placeResult.place_id,
      value: data.label,
    });
  };

  handleChange = (input: string) => {
    this.setState({ input, hasSubmitted: false });
    if (this.props.onChange) this.props.onChange(input);
  }

  handleClick = (data: { label: string, value: string }) => {
    this.handleChange(data.label);
    this.handleSelectSuggestion(data);
  }

  render() {
    return (
      <Typeahead
        icon={this.props.icon || enums.Icons.LocationPin}
        isDisabled={this.props.isDisabled}
        isLoading={this.state.isLoading || this.props.isLoading}
        isReadOnly={this.props.isReadOnly}
        isRequired={this.props.isRequired}
        items={this.state.typeaheadItems}
        label={this.props.label}
        labelFormat={this.props.labelFormat}
        name={this.props.name}
        onBlur={this.handleBlur}
        onChange={this.getSuggestions}
        onClick={this.handleClick}
        placeholder={this.props.placeholder}
        size={this.props.size}
        value={this.state.input}
      />
    );
  }

  getSuggestions = async (input: string) => {
    this.handleChange(input);
    if (!input) {
      this.setState({ typeaheadItems: [] });
      return;
    }

    this.setState({ isLoading: true });
    if (!this.props.getPlacePredictions) return;
    const suggestions = await this.props.getPlacePredictions(input);

    this.setState({
      isLoading: false,
      typeaheadItems: _.map(suggestions, suggestion => ({
        value: suggestion.place_id,
        label: suggestion.description,
      })) || [],
    });
  };
}

export default withPlacesAutocomplete(GeocodeTypeahead);
