import _ from 'lodash';

import withMutation from '~tools/react/graphql/withMutation';

import DeleteViewingMutation from './deleteViewing.gql';
import ListingUpdateFragment from './ListingUpdateFragment.gql';

interface Data {
  deleteViewing: boolean;
}

interface Variables {
  uuid: string;
}

export interface DeleteViewingProps {
  deleteViewing: (uuid: string, listingUuid?: string) => Promise<boolean>;
}

interface ListingFragment {
  uuid: string;
  upcomingViewerViewing: {
    uuid: string;
    calendarEvent: {
      endsAt: string;
      startsAt: string;
      uuid: string;
    };
  } | null;
}

export default withMutation<{}, Data, Variables, DeleteViewingProps>(DeleteViewingMutation, {
  props: props => ({
    deleteViewing: async (uuid, listingUuid) => {
      const res = await props.mutate({
        variables: {
          uuid,
        },
        update: (store, _updateProps) => {
          if (!listingUuid) return;
          const id = `SearchResultListing:${listingUuid}`;
          const cachedListing =
            _.cloneDeep(store.readFragment({ id, fragment: ListingUpdateFragment })) as ListingFragment;
          if (!cachedListing || cachedListing.upcomingViewerViewing?.uuid !== uuid) return;

          cachedListing.upcomingViewerViewing = null;
          store.writeFragment({ id, fragment: ListingUpdateFragment, data: cachedListing });
        },
      });

      if (!res || !res.data) throw new Error('Error deleting viewing.');
      return res.data.deleteViewing;
    },
  }),
});

