export enum Colors {
  Blue = 'BLUE',
  BrandAdvancedPlan = 'BRAND_ADVANCED_PLAN',
  BrandBasicPlan = 'BRAND_BASIC_PLAN',
  BrandEnterprisePlan = 'BRAND_ENTERPRISE_PLAN',
  Gray = 'GRAY',
  Green = 'GREEN',
  Red = 'RED',
  Yellow = 'YELLOW',
}

export enum Icons {
  Bank = 'BANK',
  CreditCard = 'CREDIT_CARD',
  Document = 'DOCUMENT',
  DollarSign = 'DOLLAR_SIGN',
  DoorHandle = 'DOOR_HANDLE',
  Droplet = 'DROPLET',
  Gear = 'GEAR',
  Keybox = 'KEYBOX',
  Lock = 'LOCK',
  Refresh = 'REFRESH',
  User = 'USER',
  Wrench = 'WRENCH',
  Zap = 'ZAP',
}

export enum Sizes {
  Medium = 'MEDIUM',
  Small = 'SMALL',
}
