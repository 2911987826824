export enum Colors {
  Blue = 'BLUE',
  Green = 'GREEN',
  Red = 'RED',
}

export enum Icons {
  Check = 'CHECK',
  Cross = 'CROSS',
  Lock = 'LOCK',
  Pencil = 'PENCIL',
}

export enum Types {
  Button = 'BUTTON',
  Submit = 'SUBMIT',
}
