// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2dA7qG{-webkit-box-align:center;align-items:center;border:1px solid #e6e8eb;border-radius:3px;display:-webkit-box;display:flex;margin:12px 0;padding:16px}@media screen and (max-width:400px){._2dA7qG{-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column}}._2dA7qG p{-webkit-box-flex:1;flex:1;margin:0}@media screen and (max-width:400px){._2dA7qG p{margin:12px 0;text-align:center}}._2dA7qG>svg{margin-right:16px}._2dA7qG a{-webkit-box-align:center;align-items:center;color:#8492a6;display:-webkit-box;display:flex;font-weight:300}._2dA7qG a:hover{text-decoration:underline}._2dA7qG a svg{height:20px;stroke:#8492a6;stroke-width:1.5px;width:20px}", ""]);
// Exports
exports.locals = {
	"manage-listing-info": "_2dA7qG"
};
module.exports = exports;
