import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApolloQueryResult } from 'apollo-client';

import { MessageTypes } from '~web-core/lib/common/types';
import { MessageAttachmentEntityTypes } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/graphql';
import withQuery from '~tools/react/graphql/withQuery';
import withUpdateReadUpTo, { UpdateReadUpToProps } from '~tools/react/graphql/mutations/threads/withUpdateReadUpTo';
import withRequestSupport, { RequestSupportProps } from '~tools/react/graphql/mutations/threads/withRequestSupport';

import ThreadDateDivider from './components/ThreadDateDivider';
import ThreadItemsView from './components/ThreadItemsView';
import ThreadItem from './components/ThreadItem';
import ThreadNewDivider from './components/ThreadNewDivider';
import ThreadSupportModal from './components/ThreadSupportModal';
import ThreadView from './components/ThreadView';

import ThreadInput from './containers/ThreadInput';

import query from './Thread.gql';

import { Action, ActionAttachment, ContentAnswerAttachment, Message, MetadataAttachment, PhotoAttachment, Thread as ThreadType } from './types';
import { getInfoForAction } from './utils';

interface InputProps extends RouteComponentProps {
  isMetadataDisabled?: boolean;
  isInputDisabled?: boolean;
  onClickMessageAction?: (action: Action) => any;
  supportRequestedAt?: string;
  uuid?: string;
}

type Props = InputProps & QueryProps & UpdateReadUpToProps & RequestSupportProps;

interface State {
  isSupportModalOpen: boolean;
  isScrolled: boolean;
}

const metadataEntityTypes = [
  MessageAttachmentEntityTypes.RENT_PAYMENT,
  MessageAttachmentEntityTypes.SECURITY_DEPOSIT,
];

class Thread extends PureComponent<Props, State> {
  state: State = { isSupportModalOpen: false, isScrolled: false };

  componentDidMount() {
    if (!this.props.thread) return;
    if (this.props.thread.uuid) this.props.updateReadUpTo(this.props.thread.uuid);
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.props.thread || !this.props.thread.uuid) return;

    const isViewingNewThread = !prevProps.thread || (prevProps.thread.uuid !== this.props.thread.uuid);
    if (isViewingNewThread) this.props.updateReadUpTo(this.props.thread.uuid);

    if (this.props.supportRequestedAt !== prevProps.supportRequestedAt) this.props.refetch();
  }

  handleSendMessage = () => {
    if (!this.props.thread) return;
    this.props.updateReadUpTo(this.props.thread.uuid);
  }

  handleClickHelp = () => {
    if (!this.props.thread) return;
    this.setState({ isSupportModalOpen: false });
    this.props.requestSupport(this.props.thread.uuid);
  }

  handleScrolledStateUpdated = ({ isScrolled }: { isScrolled: boolean }) => {
    this.setState({ isScrolled });
  }

  render() {
    let hasRenderedNewDivider = false;

    const messages: Message[] = _.get(this.props.thread, 'messages', []);

    const sortedMessages = _.reverse(_.sortBy(messages, 'createdAt'));
    const firstMessage = sortedMessages[0];
    let lastDate = this.props.thread ? this.props.thread.createdAt : null;
    if (firstMessage) lastDate = firstMessage.createdAt;

    return (
      <ThreadView>
        {!this.props.isInputDisabled && (
          <ThreadInput
            onSendMessage={this.handleSendMessage}
            threadUuid={this.props.thread ? this.props.thread.uuid : null}
            toggleSupportModal={this.toggleSupportModal}
            user={this.props.viewer}
            isContainerScrolled={this.state.isScrolled}
          />
        )}
        <ThreadItemsView
          isLoading={this.props.isLoading || !this.props.thread}
          hasNextPage={this.props.thread ? this.props.thread.messagesPageInfo.hasNextPage : false}
          loadMoreMessages={this.props.loadMoreMessages}
          onScrolledStateUpdated={this.handleScrolledStateUpdated}>
          <ThreadSupportModal
            isOpen={this.state.isSupportModalOpen}
            onClickRequestHelp={this.handleClickHelp}
            onClose={this.toggleSupportModal}
            hideRequestHelp={!!_.get(this.props.thread, 'supportRequestedAt')}
          />
          {_.map(sortedMessages, (message, index) => {
            let shouldRenderDateDivider = false;
            let shouldRenderNewDivider = false;

            if (!hasRenderedNewDivider && !message.isReadByViewer && _.get(message, 'user.uuid') !== _.get(this.props.viewer, 'uuid') && index === 0) {
              hasRenderedNewDivider = true;
              shouldRenderNewDivider = true;
            } else if (
              index !== 0 &&
              lastDate &&
              (moment(lastDate).add(1, 'day').isBefore(message.createdAt) ||
              (moment(lastDate).add(1, 'day').isAfter(message.createdAt) &&
              !moment(lastDate).isSame(message.createdAt, 'day')))
            ) {
              shouldRenderDateDivider = true;
              lastDate = message.createdAt;
            }

            if (index === 0 && !shouldRenderNewDivider) {
              shouldRenderDateDivider = true;
            }

            let pillLabel: string | null = null;
            if ('user' in message && !!message.user) {
              if (message.user.isAdmin) pillLabel = 'Caretaker';
              if (message.user.uuid === _.get(this.props.viewer, 'uuid')) pillLabel = 'You';
            }

            const actionAttachments = _.filter(message.attachments, {
              entityType: MessageAttachmentEntityTypes.THREAD_ACTION,
            }) as ActionAttachment[];

            const photoAttachments = _.filter(message.attachments, {
              entityType: MessageAttachmentEntityTypes.PHOTO,
            }) as PhotoAttachment[];

            const contentAnswerAttachments = _.filter(message.attachments, {
              entityType: MessageAttachmentEntityTypes.CONTENT_ANSWER,
            }) as ContentAnswerAttachment[];

            const metadataAttachments = _.filter(message.attachments, attachment =>
              _.includes(metadataEntityTypes, attachment.entityType)
            ) as MetadataAttachment[];

            let messageTitle = '';
            if (message.type === MessageTypes.PEER) messageTitle = message.user.firstName;
            else if (message.type === MessageTypes.ACTIVITY) messageTitle = message.title || 'Activity';

            return (
              <Fragment key={message.uuid}>
                {shouldRenderNewDivider && <ThreadNewDivider />}
                {shouldRenderDateDivider && lastDate && <ThreadDateDivider timestamp={lastDate} />}
                <ThreadItem
                  avatarUrl={message.type === MessageTypes.PEER ? message.user.photoUrl : undefined}
                  content={message.text || ''}
                  iconUrl={message.type === MessageTypes.ACTIVITY ? message.iconUrl : undefined}
                  pillLabel={pillLabel}
                  timestamp={message.createdAt}
                  title={messageTitle}
                  type={message.type}>
                  {photoAttachments.length > 0 ? _.map(photoAttachments, photoAttachment => (
                    <ThreadItem.ThreadItemPhotoAttachment
                      key={photoAttachment.uuid}
                      url={photoAttachment.photo.url}
                      width={photoAttachment.photo.width}
                      height={photoAttachment.photo.height}
                    />
                  )) : undefined}
                  {contentAnswerAttachments.length > 0 ? _.map(contentAnswerAttachments, answerAttachment => (
                    <ThreadItem.ThreadItemContentAnswerAttachment
                      key={answerAttachment.uuid}
                      title={answerAttachment.contentAnswer.title}
                      shortAnswer={answerAttachment.contentAnswer.shortAnswer}
                      slug={answerAttachment.contentAnswer.slug}
                    />
                  )) : undefined}
                  {actionAttachments.length > 0 && _.map(actionAttachments, (actionAttachment) => {
                    const actionInfo = getInfoForAction(actionAttachment.action);
                    if (!actionInfo || actionInfo.isComplete) return null;
                    return (
                      <ThreadItem.ThreadItemActionAttachment
                        action={actionAttachment.action}
                        key={actionAttachment.uuid}
                        label={actionInfo.label}
                        onClick={this.props.onClickMessageAction}
                        link={actionInfo.link}
                      />
                    );
                  })}
                  {!this.props.isMetadataDisabled && metadataAttachments.length > 0 && _.map(metadataAttachments, (metadataAttachment) => {
                    if (!this.props.viewer) return;
                    switch (metadataAttachment.entityType) {
                      // We might need to support custom links based on context (takeover, flip, lease) soon.
                      case MessageAttachmentEntityTypes.RENT_PAYMENT: {
                        return (
                          <ThreadItem.ThreadItemMetadataAttachment
                            key={metadataAttachment.uuid}
                            label="View receipt"
                            url={`rent-payments/${metadataAttachment.rentPayment.uuid}`}
                          />
                        );
                      }
                      case MessageAttachmentEntityTypes.SECURITY_DEPOSIT:
                        return (
                          <ThreadItem.ThreadItemMetadataAttachment
                            key={metadataAttachment.uuid}
                            label="View receipt"
                            url={`security-deposits/${metadataAttachment.securityDeposit.uuid}`}
                          />
                        );
                      default: return null;
                    }
                  })}
                </ThreadItem>
              </Fragment>
            );
          })}
        </ThreadItemsView>
      </ThreadView>
    );
  }

  toggleSupportModal = () => this.setState({ isSupportModalOpen: !this.state.isSupportModalOpen });
}

interface Response {
  viewer: {
    uuid: string;
    firstName: string;
    photoUrl: string;
    thread: ThreadType | null;
  } | null;
}

interface Variables {
  cursor?: string;
  uuid: string;
}

interface QueryProps {
  isLoading: boolean;
  loadMoreMessages: () => Promise<any>,
  thread: ThreadType | null;
  viewer: {
    uuid: string;
    firstName: string;
    photoUrl: string;
  } | null;
  refetch: () => Promise<ApolloQueryResult<Response>>;
}

export default compose(
  withUpdateReadUpTo,
  withRequestSupport,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: ownProps => ({
      skip: !ownProps.uuid,
      variables: {
        uuid: ownProps.uuid,
      },
    }),
    props: (props) => ({
      isLoading: props.loading,
      thread: _.get(props.data, 'viewer.thread', null),
      viewer: _.get(props.data, 'viewer', null),
      refetch: props.refetch,

      loadMoreMessages: () => {
        if (!props.data || !props.data.viewer || !props.data.viewer.thread) return Promise.resolve();
        const { messagesPageInfo } = props.data.viewer.thread;
        if (!messagesPageInfo.hasNextPage) return Promise.resolve();

        // TODO: Figure out how to make this typesafe. It doesn't know about nodes/edges.
        return props.fetchMore({
          query,
          updateQuery: (previousResult: any, { fetchMoreResult }) => {
            if (!fetchMoreResult || !fetchMoreResult.viewer || !fetchMoreResult.viewer.thread) return previousResult;
            const { edges: newEdges, pageInfo } = fetchMoreResult.viewer.thread.messages as any;
            const copy = _.cloneDeep(previousResult);
            copy.viewer.thread.messages = {
              pageInfo,
              __typename: previousResult.viewer.thread.messages.__typename, // eslint-disable-line no-underscore-dangle
              edges: [...previousResult.viewer.thread.messages.edges, ...newEdges],
              total: previousResult.viewer.thread.messages.edges.length + newEdges.length,
            };
            return copy;
          },
          variables: {
            cursor: messagesPageInfo.endCursor,
            uuid: props.ownProps.uuid,
          },
        });
      },
    }),
  }),
  withRouter,
)(Thread);
