// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._GN6D7M{background-color:#fff;border-radius:5px;box-shadow:0 2px 6px 0 rgba(0,0,0,.04),0 10px 25px 0 rgba(33,43,105,.04),0 5px 15px 0 rgba(0,0,0,.08);display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;overflow:hidden;width:260px}._GN6D7M ._BX0VJW{-webkit-box-align:end;align-items:flex-end;background-position:bottom;background-size:100%;display:-webkit-box;display:flex;height:140px;padding:0 16px 12px;position:relative;width:100%}._GN6D7M ._BX0VJW>*{position:relative;z-index:1}._GN6D7M ._BX0VJW:before{background:-webkit-gradient(linear,left bottom, left top,from(rgba(0,0,0,.7)),color-stop(75%, transparent));background:linear-gradient(0deg,rgba(0,0,0,.7),transparent 75%);content:\"\";height:100%;left:0;position:absolute;top:0;width:100%;z-index:0}._GN6D7M ._BX0VJW p{color:#fff;font-size:14px;line-height:26px;margin:0 8px 0 0;overflow:hidden;text-overflow:ellipsis;text-shadow:0 2px 4px rgba(0,0,0,.5);-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;white-space:nowrap;width:100%}@media screen and (max-width:1127px){._GN6D7M ._BX0VJW :last-child{display:none}}._GN6D7M ._5aAQr8{-webkit-box-align:center;align-items:center;background-color:#1f8eed;border:none;color:#fff;display:-webkit-box;display:flex;font-size:14px;-webkit-box-pack:center;justify-content:center;padding:12px;-webkit-transition:all .25s;transition:all .25s;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}._GN6D7M ._5aAQr8:hover{background-color:#45a1f0}._GN6D7M ._5aAQr8 svg{height:18px;margin-left:10px;-webkit-transform:rotate(180deg);transform:rotate(180deg)}._GN6D7M ._5aAQr8 path{stroke:#fff}@media screen and (max-width:1127px){._GN6D7M ._5aAQr8{font-size:16px}}", ""]);
// Exports
exports.locals = {
	"listing-card": "_GN6D7M",
	"listing-card__image": "_BX0VJW",
	"listing-card__button": "_5aAQr8"
};
module.exports = exports;
