import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './TableBody.scss';

interface Props {
  children: ReactNode;
}

function TableBody(props: Props) {
  return (
    <tbody>
      {props.children}
    </tbody>
  );
}

export default withStyles(styles)(TableBody);
