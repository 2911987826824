// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._EGn5MM{display:-webkit-box;display:flex}@media screen and (max-width:1127px){._EGn5MM button{-webkit-box-flex:1;flex:1}}", ""]);
// Exports
exports.locals = {
	"responsive-step-buttons": "_EGn5MM"
};
module.exports = exports;
