import loadable from '@loadable/component';
import _ from 'lodash';

import * as enums from './enums';

import InteractiveMapMarkerListingCard from './components/InteractiveMapMarkerListingCard';

const LoadableInteractiveMapMarker = loadable(() => import(/* webpackChunkName: "interactive-map-marker" */'./InteractiveMapMarker'));

export default _.assign(LoadableInteractiveMapMarker, {
  enums,
  InteractiveMapMarkerListingCard,

  // Legacy support
  MapViewMarkerListingCard: InteractiveMapMarkerListingCard,
});
