import React, { Component, MouseEvent } from 'react';

import Button from '~tools/react/components/Button';
import Dropdown from '~tools/react/components/Dropdown';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import PropertyManagerContractsTypeahead, { PropertyManagerContractSearchResult } from '~tools/react/containers/PropertyManagerContractsTypeahead';
import withStyles from '~tools/react/hocs/withStyles';

import { formatShortAddress } from '~web-manage/lib/common/utils/addressUnit';

import styles from './AddUnitDropdown.scss';

interface Props {
  onAddUnit: (propertyManagerContractUuid: string) => void;
}

interface State {
  isMenuOpen: boolean;
  propertyManagerContract: {
    value: string;
    label: string;
  } | null;
}

class AddUnitDropdown extends Component<Props, State> {
  menuRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.menuRef = React.createRef();

    this.state = {
      isMenuOpen: false,
      propertyManagerContract: null,
    };
  }

  handleDocumentClick = e => {
    if (!this.menuRef?.current) return;
    const isOutsideMenu = !this.menuRef.current.contains(e.target);

    if (isOutsideMenu) {
      this.handleCloseMenu();
    }
  }

  handleOpenMenu = (e: MouseEvent<EventTarget>) => {
    e.nativeEvent.stopImmediatePropagation();

    document.addEventListener('click', this.handleDocumentClick);
    this.setState({ isMenuOpen: true });
  };

  handleCloseMenu = () => {
    document.removeEventListener('click', this.handleDocumentClick);
    this.setState({ isMenuOpen: false });
  };

  handlePropertyManagerContractChange = (data: PropertyManagerContractSearchResult | null) => {
    this.setState({ propertyManagerContract: data ? {
      value: data.uuid,
      label: formatShortAddress(data.addressUnit),
    } : null });
  };

  handleAddUnit = () => {
    const propertyManagerContractUuid = this.state.propertyManagerContract?.value;
    if (!propertyManagerContractUuid) return;

    this.props.onAddUnit(propertyManagerContractUuid);
    this.handleCloseMenu();
  };

  handleClear = () => this.setState({ propertyManagerContract: null });

  render() {
    return (
      <div styleName="add-unit-dropdown">
        <Button
          color={Button.enums.Colors.Gray}
          icon={Button.enums.Icons.Plus}
          label="Add unit"
          onClick={this.state.isMenuOpen ?
            this.handleCloseMenu :
            this.handleOpenMenu}
          size={Button.enums.Sizes.Small}
          style={Button.enums.Styles.Outline}
        />
        {this.state.isMenuOpen ? (
          <Dropdown
            carotPosition={{
              right: '42px',
            }}
            position={{
              right: '0px',
              top: '54px',
            }}>
            <div
              styleName="add-unit-dropdown__menu"
              ref={this.menuRef}>
              <PropertyManagerContractsTypeahead
                label="Unit address"
                name="propertyManagerContract"
                onChange={this.handlePropertyManagerContractChange}
                placeholder="Address..."
                value={this.state.propertyManagerContract?.label ?? ''}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
              <div styleName="add-unit-dropdown__menu__actions">
                <Button
                  color={Button.enums.Colors.Gray}
                  style={Button.enums.Styles.Outline}
                  label="Clear"
                  size={Button.enums.Sizes.XSmall}
                  onClick={this.handleClear}
                />
                <Button
                  style={Button.enums.Styles.Outline}
                  label="Add unit"
                  size={Button.enums.Sizes.XSmall}
                  type={Button.enums.Types.Submit}
                  onClick={this.handleAddUnit}
                />
              </div>
            </div>
          </Dropdown>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(AddUnitDropdown);
