// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._99v56W{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row;-webkit-box-pack:justify;justify-content:space-between;padding:24px}._99v56W p{color:#8492a6;font-size:14px;margin:0}._99v56W ._qrovbV{display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row}", ""]);
// Exports
exports.locals = {
	"table-paginator": "_99v56W",
	"table-paginator__buttons": "_qrovbV"
};
module.exports = exports;
