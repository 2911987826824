import React, { Component } from 'react';
import isEqual from 'react-fast-compare';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './BarPart.scss';

interface BarData {
  height: number;
  key: string;
  width: number
  x: number;
  y: number;
}

interface Props {
  bar: BarData;
}

class BarPart extends Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    return (
      <g>
        <rect
          height={this.props.bar.height}
          rx="2px"
          ry="2px"
          styleName="bar"
          width={this.props.bar.width}
          x={this.props.bar.x}
          y={this.props.bar.y}
        />
      </g>
    );
  }
}

export default withStyles(styles)(BarPart);
