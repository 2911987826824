// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._nrB8q6{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row;-webkit-box-pack:justify;justify-content:space-between;padding:10px 15px}._nrB8q6:not(:last-child){border-bottom:1px solid #e6e8eb}._nrB8q6 p{margin:0}._nrB8q6 ._rrEJPW{min-width:0}._nrB8q6 ._O0GIDP{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row}._nrB8q6 ._O0GIDP a+a,._nrB8q6 ._O0GIDP a+button,._nrB8q6 ._O0GIDP button+a,._nrB8q6 ._O0GIDP button+button{margin-left:8px}", ""]);
// Exports
exports.locals = {
	"verification-row": "_nrB8q6",
	"verification-row__left": "_rrEJPW",
	"verification-row__buttons": "_O0GIDP"
};
module.exports = exports;
