import classNames from 'classnames';
import _ from 'lodash';
import React, { Fragment } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import SkeletonDisplayText from '~tools/react/components/SkeletonDisplayText';

import Cross from '~tools/svgs/icons/interface/cross.svg';

import styles from './PhotoVisual.scss';

interface Props {
  bindRef?: (e: HTMLImageElement | null) => void;
  error?: Error;
  isLoading?: boolean;
  isReordering?: boolean;
  onClick?: () => (void | Promise<void>);
  position: number;
  progress: number;
  url?: string;
}

function PhotoVisual(props: Props) {
  if (props.isLoading) {
    return (
      <div styleName="photo-visual photo-visual--loading">
        <SkeletonDisplayText />
      </div>
    );
  }

  return (
    <button
      onClick={props.progress !== 100 ? undefined : props.onClick}
      styleName={classNames({
        'photo-visual': true,
        'photo-visual--uploading': props.progress !== 100,
        'photo-visual--error': props.error,
        'photo-visual--reordering': props.isReordering,
      })}>
      <h5>{props.position + 1}</h5>
      {props.error ? <Cross /> : null}
      {props.progress !== 100 ? (
        <Fragment>
          <p>{_.round(props.progress)}%</p>
          <span style={{ transform: props.progress ? `scaleX(${props.progress / 100})` : undefined }} />
        </Fragment>
      ) : null}
      <img
        alt=""
        ref={props.bindRef}
        src={props.url}
        styleName="photo-visual__image"
      />
    </button>
  );
}

export default withStyles(styles)(PhotoVisual);
