import _ from 'lodash';
import React, { Children, ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import VerificationRow from './components/VerificationRow';

import UnverifiedIcon from './svgs/unverified.svg';
import VerifiedIcon from './svgs/verified.svg';

import styles from './VerificationsView.scss';

interface Props {
  children?: ReactNode;
  isIncomplete?: boolean;
  title: string;
}

function VerificationsView(props: Props) {
  return (
    <div styleName="verifications-view">
      <div style={{ display: 'flex', alignItems: 'center', }}>
        <Heading
          content={props.title}
          size={Heading.enums.Sizes.XXSmall}
          font={Heading.enums.Fonts.Secondary}
        />
        <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
        {props.isIncomplete ? <UnverifiedIcon /> : <VerifiedIcon />}
      </div>
      {props.children && Children.count(props.children) > 0 ? (
        <div styleName="verifications-view__verifications">
          {props.children}
        </div>
      ) : null}
    </div>
  );
}

const StyledVerificationsView = withStyles(styles)(VerificationsView);

export default _.assign(StyledVerificationsView, { VerificationRow });
