import withMutation from '~tools/react/graphql/withMutation';
import { CreateRequirementInput, RequirementEntityTypes } from '~tools/types/graphqlSchema';

import CreateRequirementMutation from './createRequirement.gql';

export interface Requirement {
  uuid: string;
}

interface Data {
  createRequirement: Requirement;
}

interface Variables {
  input: CreateRequirementInput;
}

export { RequirementEntityTypes };

export interface CreateRequirementProps {
  createRequirement: (input: CreateRequirementInput) => Promise<Requirement>;
}

export default withMutation<{}, Data, Variables, CreateRequirementProps>(CreateRequirementMutation, {
  props: props => ({
    createRequirement: async (input: CreateRequirementInput) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating requirement.');

      return res.data.createRequirement;
    },
  }),
});
