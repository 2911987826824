// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._EGn9AQ{-webkit-box-align:center;align-items:center;background-color:#eff2f7;border-radius:9999px;display:-webkit-box;display:flex;padding:12px;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}._EGn9AQ svg{height:16px;width:16px}._EGn9AQ._ernm1n{font-size:16px}._EGn9AQ._80Aw1z{padding:4px 8px}._EGn9AQ._80Aw1z svg{height:14px;width:14px}._EGn9AQ._80AfMe{background-color:transparent;color:#eff2f7;padding:0}._EGn9AQ._80AfMe._1qAGzv,._EGn9AQ._80AfMe._2dArVN,._EGn9AQ._80AfMe._yprWa8{background-color:transparent}._EGn9AQ._prWsJV._1qAGzv{background-color:#1f8eed}._EGn9AQ._prWsJV._1qAGzv svg path{stroke:#fff}._EGn9AQ._prWsJV._yprWa8{background-color:#ff5167}._EGn9AQ._prWsJV._yprWa8 svg path{stroke:#fff}._EGn9AQ._prWsJV._2dArVN{background-color:#21bc7a}._EGn9AQ._prWsJV._2dArVN svg path{stroke:#fff}._EGn9AQ._KpP7nb._1qAGzv{background-color:#e4f6fe}._EGn9AQ._KpP7nb._1qAGzv svg path{stroke:#0253b9}._EGn9AQ._KpP7nb._2dArVN{background-color:#e6fcf9}._EGn9AQ._KpP7nb._2dArVN svg path{stroke:#0f7048}._EGn9AQ._KpP7nb._yprWa8{background-color:#ffeaed}._EGn9AQ._KpP7nb._yprWa8 svg path{stroke:#cc1420}", ""]);
// Exports
exports.locals = {
	"timeline-badge": "_EGn9AQ",
	"timeline-badge--size-large": "_ernm1n",
	"timeline-badge--size-small": "_80Aw1z",
	"timeline-badge--style-inverse": "_80AfMe",
	"timeline-badge--color-blue": "_1qAGzv",
	"timeline-badge--color-green": "_2dArVN",
	"timeline-badge--color-red": "_yprWa8",
	"timeline-badge--style-primary": "_prWsJV",
	"timeline-badge--style-secondary": "_KpP7nb"
};
module.exports = exports;
