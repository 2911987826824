import classNames from 'classnames';
import { Moment } from 'moment-timezone';
import React, { Fragment, MouseEvent, PureComponent } from 'react';

import Avatar from '~tools/react/components/Avatar';
import Button from '~tools/react/components/Button';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './Event.scss';

interface Props {
  isOtherEventExpanded: boolean;
  onExpanded: () => void;
  onClosed: () => void;
  account?: {
    firstName: string;
    fullName: string;
    photoUrl: string;
  };
  endsAt: Moment;
  label?: string;
  link?: {
    label: string;
    path: string;
  };
  startsAt: Moment;
}

interface State {
  isExpansionOpen: boolean;
  isExpansionMounted: boolean;
}

class Event extends PureComponent<Props, State> {
  didMount = false;
  timeout: NodeJS.Timeout | null = null;

  state = {
    isExpansionOpen: false,
    isExpansionMounted: false,
  };

  componentDidMount() {
    this.didMount = true;
  }

  componentWillUnmount() {
    this.didMount = false;
    if (this.state.isExpansionOpen || this.state.isExpansionMounted) this.props.onClosed();
    if (this.timeout) clearTimeout(this.timeout);
    document.removeEventListener('click', this.handleClose);
  }

  handleClick = (e: MouseEvent<EventTarget>) => {
    if (this.state.isExpansionMounted || this.state.isExpansionOpen) e.nativeEvent.stopImmediatePropagation();
  };

  handleExpand = () => {
    if (!this.didMount) return;
    if (this.state.isExpansionMounted || this.state.isExpansionOpen) return;

    document.addEventListener('click', this.handleClose);
    this.setState({ isExpansionMounted: true });
    this.timeout = setTimeout(() => {
      this.setState({ isExpansionOpen: true });
      this.props.onExpanded();
    }, 0);
  }

  handleClose = () => {
    if (!this.didMount) return;
    if (!this.state.isExpansionOpen || !this.state.isExpansionMounted) return;

    document.removeEventListener('click', this.handleClose);
    this.setState({ isExpansionOpen: false });
    this.props.onClosed();
    this.timeout = setTimeout(() => this.setState({ isExpansionMounted: false }), 260);
  }

  render() {
    return (
      <div
        onClick={this.handleClick}
        styleName="event-slideout">
        <div
          className={classNames({
            [styles.event]: true,
            [styles['event--booked']]: !!this.props.account,
            [styles['event--expanded']]: this.state.isExpansionOpen,
            [styles['event--faded']]: this.props.isOtherEventExpanded && !this.state.isExpansionOpen,
          })}
          onClick={this.handleExpand}>
          <span styleName="event__label">{this.props.account ? this.props.account.firstName : 'Available'}</span>
          <span styleName="event__time">{this.props.startsAt.format('ha')}</span>
        </div>
        {this.state.isExpansionMounted ? (
          <div
            className={classNames({
              [styles['slide-out']]: true,
              [styles['slide-out--expanded']]: this.state.isExpansionOpen,
            })}>
            <Row
              flexBehavior={Row.enums.FlexBehaviors.Default}
              verticalAlignment={Row.enums.VerticalAlignments.Center}>
              {this.props.account ? (
                <Fragment>
                  <Avatar
                    imageUrl={this.props.account.photoUrl}
                    size={Avatar.enums.Sizes.Small}
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                </Fragment>
              ) : null}
              <Text
                content={this.props.account ? this.props.account.fullName : `${this.props.label || ''}`}
                size={Text.enums.Sizes.Medium}
                isEmphasized
              />
            </Row>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
            <Text
              content={`${this.props.startsAt.format('h:mm')} - ${this.props.endsAt.add(1, 'millisecond').format('h:mma')}`}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
            <Text
              content={`${this.props.startsAt.format('dddd, MMMM Do')}`}
            />
            {this.props.link ? (
              <Fragment>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                <Button
                  size={Button.enums.Sizes.XSmall}
                  label={this.props.link.label}
                  link={{
                    path: this.props.link.path,
                  }}
                  icon={Button.enums.Icons.ChevronRight}
                  style={Button.enums.Styles.Outline}
                />
              </Fragment>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(Event);
