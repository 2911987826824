import _ from 'lodash';
import classNames from 'classnames';
import React, { ComponentType, PureComponent } from 'react';

import Link from '~tools/react/components/utility/Link';

import withStyles from '~tools/react/hocs/withStyles';

import ArrowRight from '~tools/svgs/icons/interface/arrow-right.svg';

import styles from './ArrowLink.scss';

import * as enums from './enums';

interface BaseProps {
  align?: enums.Alignments;
  color?: enums.Colors;
  direction?: enums.Directions;
  hasAutoMargin?: boolean;
  isDisabled?: boolean;
  label: string;
  size?: enums.Sizes;
}

interface LinkProps extends BaseProps {
  link: {
    path: string;
    shouldOpenNewTab?: boolean;
  };
}

interface ButtonProps extends BaseProps {
  onClick: () => void;
}

type Props = LinkProps | ButtonProps;

class ArrowLink extends PureComponent<Props> {
  static defaultProps = {
    hasAutoMargin: true,
    direction: enums.Directions.Right,
    size: enums.Sizes.Large,
  }
  static enums = enums;

  render() {
    if (this.props.isDisabled) {
      return (
        <div
          className={classNames({
            [styles['arrow-link']]: true,
            [styles[`arrow-link--align-${_.kebabCase(this.props.align)}`]]: this.props.align,
            [styles['arrow-link--disabled']]: true,
            [styles['arrow-link--auto-margin']]: this.props.hasAutoMargin,
            [styles[`arrow-link--color-${_.kebabCase(this.props.color)}`]]: this.props.color,
            [styles[`arrow-link--direction-${_.kebabCase(this.props.direction)}`]]: this.props.direction,
            [styles[`arrow-link--size-${_.kebabCase(this.props.size)}`]]: this.props.size,
          })}>
          {this.props.direction === enums.Directions.Left ? <ArrowRight /> : null} {this.props.label} {this.props.direction === enums.Directions.Right ? <ArrowRight /> : null}
        </div>
      );
    }

    if ('link' in this.props) {
      return (
        <Link
          className={classNames({
            [styles['arrow-link']]: true,
            [styles[`arrow-link--align-${_.kebabCase(this.props.align)}`]]: this.props.align,
            [styles['arrow-link--auto-margin']]: this.props.hasAutoMargin,
            [styles[`arrow-link--color-${_.kebabCase(this.props.color)}`]]: this.props.color,
            [styles[`arrow-link--direction-${_.kebabCase(this.props.direction)}`]]: this.props.direction,
            [styles[`arrow-link--size-${_.kebabCase(this.props.size)}`]]: this.props.size,
          })}
          shouldOpenNewTab={this.props.link.shouldOpenNewTab}
          to={this.props.link.path}>
          {this.props.direction === enums.Directions.Left ? <ArrowRight /> : null} {this.props.label} {this.props.direction === enums.Directions.Right ? <ArrowRight /> : null}
        </Link>
      );
    }

    if ('onClick' in this.props) {
      return (
        <button
          onClick={this.props.onClick}
          type="button"
          className={classNames({
            [styles['arrow-link']]: true,
            [styles[`arrow-link--align-${_.kebabCase(this.props.align)}`]]: this.props.align,
            [styles['arrow-link--auto-margin']]: this.props.hasAutoMargin,
            [styles[`arrow-link--color-${_.kebabCase(this.props.color)}`]]: this.props.color,
            [styles[`arrow-link--direction-${_.kebabCase(this.props.direction)}`]]: this.props.direction,
            [styles[`arrow-link--size-${_.kebabCase(this.props.size)}`]]: this.props.size,
          })}>
          {this.props.direction === enums.Directions.Left ? <ArrowRight /> : null} {this.props.label} {this.props.direction === enums.Directions.Right ? <ArrowRight /> : null}
        </button>
      );
    }

    return null;
  }
}

export default withStyles(styles)(ArrowLink as ComponentType<Props>) as ComponentType<Props>;
