import React, { Component } from 'react';
import classNames from 'classnames';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './TopBarLoader.scss';

interface Props {
  isLoading: boolean;
}

interface State {
  isLoading: boolean;
}

class TopBarLoader extends Component<Props, State> {
  state: State = { isLoading: false };
  timeout: NodeJS.Timeout | null = null;

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading && !this.props.isLoading) {
      this.setState({ isLoading: true });
    } else if (!nextProps.isLoading && this.props.isLoading) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(
        () => this.setState({ isLoading: false }),
        500,
      );
    }
  }

  render() {
    return (
      <div
        styleName={classNames({
          'top-bar-loader': true,
          'top-bar-loader--full': this.state.isLoading,
          'top-bar-loader--visible': this.props.isLoading,
        })}
      />
    );
  }
}

export default withStyles(styles)(TopBarLoader);
