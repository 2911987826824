import classNames from 'classnames';
import { canUseDOM } from 'exenv';
import 'moment-range';
import moment, { Moment } from 'moment-timezone';
import React, { Component } from 'react';
import _ from 'lodash';

import Calendar from '~tools/react/components/Calendar';
import withStyles from '~tools/react/hocs/withStyles';
import { Input } from '~tools/react/components/Form';
import { DATE } from '~web-core/lib/common/constants/regex';

import { LabelFormats } from '~tools/react/components/Form/components/Input/enums';

import styles from './DatePicker.scss';

const enums = {
  Views: Calendar.enums.Views,
};

interface Props {
  format?: string;
  initialView?: string; // Should be Views enum when Calendar is converted
  isDisabled?: boolean;
  isReadOnly?: boolean;
  maxValue?: Moment;
  minValue?: Moment;
  timezone?: string;
  label?: string;
  onChange?: (value: Moment) => void;
  name: string;
  placeholder: string;
  value?: Moment;
  labelFormat?: LabelFormats;
  isRequired?: boolean,
}

interface State {
  isCalendarOpen: boolean;
  value?: Moment;
}

class DatePicker extends Component<Props, State> {
  static enums = enums;
  static defaultProps = {
    format: 'MM/DD/YYYY',
    initialView: enums.Views.Days,
    placeholder: '',
  };

  didClickCalendar = false;

  state = {
    isCalendarOpen: false,
    value: this.props.value,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) this.setState({ value: nextProps.value });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickDocument);
  }

  handleClickCalendar = () => {
    if (this.state.isCalendarOpen) {
      this.didClickCalendar = true;
      return;
    }

    this.openPicker();
  };

  handleClickDocument = () => {
    if (this.didClickCalendar) {
      this.didClickCalendar = false;
      return;
    }

    this.closePicker();
  };

  handleSelectValue = (selectedValue: Moment, isDay?: boolean) => {
    this.setState({ value: selectedValue });
    if (isDay) {
      if (this.props.onChange) this.props.onChange(selectedValue);
      this.closePicker();
    }
  };

  render() {
    const clientTimezone = canUseDOM ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'UTC';
    const timezone = this.props.timezone || clientTimezone;

    let value = this.state.value;
    if (_.isString(value)) value = moment.tz(value, timezone);

    return (
      <div // eslint-disable-line jsx-a11y/click-events-have-key-events
        onClick={this.handleClickCalendar}
        className={classNames({
          [styles['date-picker']]: true,
          [styles['date-picker--open']]: this.state.isCalendarOpen,
        })}>
        <Input
          isDisabled={this.props.isDisabled}
          isReadOnly={this.props.isReadOnly}
          isRequired={this.props.isRequired}
          label={this.props.label}
          labelFormat={this.props.labelFormat}
          name={this.props.name}
          placeholder={this.props.placeholder}
          regexPattern={new RegExp(DATE)}
          type={Input.enums.Types.Text}
          value={!_.isNil(value) && value ? value.format(this.props.format) : undefined}
        />
        {this.state.isCalendarOpen ? (
          <div styleName="date-picker__dropdown">
            <Calendar
              initialView={this.props.initialView}
              maxValue={this.props.maxValue}
              minValue={this.props.minValue}
              onSelect={this.handleSelectValue}
              value={this.state.value ? moment.tz(this.state.value, timezone) : moment.tz(timezone)}
            />
          </div>
        ) : null}
      </div>
    );
  }

  closePicker = () => {
    if (!this.state.isCalendarOpen) return;

    this.setState({ isCalendarOpen: false });
    document.removeEventListener('click', this.handleClickDocument);
  };

  openPicker = () => {
    if (this.state.isCalendarOpen) return;

    document.addEventListener('click', this.handleClickDocument);
    this.setState({ isCalendarOpen: true });
  };
}

export default withStyles(styles)(DatePicker);
