// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@-webkit-keyframes _80AaDP{0%{background:#f6f8fa}to{background:#e5e9f2}}@keyframes _80AaDP{0%{background:#f6f8fa}to{background:#e5e9f2}}._orGdAr{-webkit-animation:_80AaDP .65s linear infinite alternate;animation:_80AaDP .65s linear infinite alternate;-webkit-backface-visibility:hidden;backface-visibility:hidden;border-radius:3px;will-change:background}", ""]);
// Exports
exports.locals = {
	"skeleton-block": "_orGdAr",
	"shimmer": "_80AaDP"
};
module.exports = exports;
