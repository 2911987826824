import { AddressElement, PaymentElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import React, { Fragment, useState } from 'react';

import Alert from '~tools/react/components/Alert';
import Button from '~tools/react/components/Button';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import TabHeader from '~tools/react/components/TabHeader';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import useViewerPaymentInformation from '~tools/react/graphql/queries/useViewerPaymentInformation';

interface Props {
  stripe: Stripe | null;
  elements: StripeElements | null;
  submitButtonText?: string;
  defaultValues: {
    name?: string;
    email?: string;
  };
  hideTerms?: boolean;
  collectBillingDetails?: boolean;
  onAddPaymentMethod: (paymentMethodId: string) => unknown;
}

function PaymentElementForm(props: Props) {
  /*
   * This query is here so that the payment method selector can be
   * correctly updated when a new payment method is added. There
   * is probably a better way to do this but it'll work for now.
   * - Roger
   */
  const { refetch: refetchPaymentInformation } = useViewerPaymentInformation();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const stripe = props.stripe;
    const elements = props.elements;
    if (!stripe || !elements) return;

    setLoading(true);
    const setupIntent = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: 'if_required',
    });

    const error = setupIntent.error;
    if (error) {
      setErrorMessage(error.message || 'An unexpected error occured.');
      setLoading(false);
      return;
    }

    await refetchPaymentInformation();
    if (typeof setupIntent.setupIntent?.payment_method === 'string') {
      await props.onAddPaymentMethod(setupIntent.setupIntent.payment_method);
    }
    setLoading(false);
  };

  return (
    <form
      style={{ padding: '2px' }}
      onSubmit={handleSubmit}>
      {props.collectBillingDetails ? <TabHeader title="Payment method" /> : null}
      <PaymentElement
        options={{
          terms: props.hideTerms
            ? {
                card: 'never',
              }
            : undefined,
          paymentMethodOrder: ['us_bank_account', 'card'],
          business: { name: 'Caretaker' },
          defaultValues: {
            billingDetails: props.defaultValues,
          },
        }}
      />
      {props.collectBillingDetails ? (
        <Fragment>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
          <HorizontalRule />
          <TabHeader title="Billing address" />
          <AddressElement
            options={{
              mode: 'billing',
            }}
          />
        </Fragment>
      ) : null}
      {errorMessage ? (
        <Fragment>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
          <Alert
            title={errorMessage}
            color={Alert.enums.Colors.Red}
          />
        </Fragment>
      ) : null}
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
      <Button
        color={Button.enums.Colors.Blue}
        isLoading={isLoading}
        isDisabled={!props.stripe || !props.elements}
        label={props.submitButtonText || 'Save payment method'}
        size={Button.enums.Sizes.Medium}
        style={Button.enums.Styles.Outline}
        icon={Button.enums.Icons.Plus}
        type={Button.enums.Types.Submit}
        width={Button.enums.Widths.Full}
      />
    </form>
  );
}

export default PaymentElementForm;
