import React, { Component } from 'react';

import ThemedModal from '~tools/react/components/ThemedModal';
import PaymentForm from '~tools/react/containers/PaymentForm';

import { VerificationMethods } from '~web-manage/lib/common/scenes/Unit/scenes/UnitVerification/enums';

import DashboardListingVerifyConfirmationModalContentView from './components/DashboardListingVerifyConfirmationModalContentView';
import DashboardListingVerifyPostcardConfirmation from './components/DashboardListingVerifyPostcardConfirmation';

interface Props {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onConfirmation: (payload: { method: VerificationMethods; paymentMethodId?: string; ssn?: string }) => Promise<void>;
  verificationMethod: VerificationMethods;
}

class DashboardListingVerifyConfirmationModal extends Component<Props> {
  handleConfirmation = async () =>
    this.props.onConfirmation({
      method: this.props.verificationMethod,
    });

  handleConfirmWithPaymentMethod = async (paymentMethodId: string) =>
    this.props.onConfirmation({
      method: this.props.verificationMethod,
      paymentMethodId,
    });

  render() {
    return (
      <ThemedModal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title={this.getModalTitle()}
        width={ThemedModal.enums.Widths.Medium}>
        <DashboardListingVerifyConfirmationModalContentView>
          {this.props.verificationMethod === VerificationMethods.BILLING_ADDRESS && (
            <PaymentForm
              hideTerms
              paymentMethodTypes={[PaymentForm.enums.PaymentMethodTypes.CARD]}
              charges={[
                {
                  deltaAmountInCents: 100,
                  label: 'Listing verification',
                  labelMeta: 'Authorization only',
                  labelTooltip: 'Your card will only be authorized and a payment will not be charged.',
                },
              ]}
              includeCardProcessingFee={false}
              onSubmit={this.handleConfirmWithPaymentMethod}
              buttonCopy="Verify listing"
              hasFooter
            />
          )}
          {this.props.verificationMethod === VerificationMethods.POSTCARD && (
            <DashboardListingVerifyPostcardConfirmation
              isLoading={this.props.isLoading}
              onCancel={this.props.onClose}
              onSubmit={this.handleConfirmation}
            />
          )}
        </DashboardListingVerifyConfirmationModalContentView>
      </ThemedModal>
    );
  }

  getModalTitle() {
    switch (this.props.verificationMethod) {
      case VerificationMethods.BILLING_ADDRESS: {
        return 'Verify with billing address';
      }
      case VerificationMethods.IDENTITY_CHECK: {
        return 'Verify with identity check';
      }
      case VerificationMethods.POSTCARD: {
        return 'Verify with postcard';
      }
      default: {
        return 'Verify';
      }
    }
  }
}

export default DashboardListingVerifyConfirmationModal;
