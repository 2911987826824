// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._P9WvAE{max-width:25%;padding-left:16px}", ""]);
// Exports
exports.locals = {
	"apartment-name-view": "_P9WvAE"
};
module.exports = exports;
