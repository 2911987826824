import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Link from '~tools/react/components/utility/Link';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import Tooltip from '~tools/react/components/Tooltip';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import ChevronRight from '~tools/svgs/icons/interface/chevron-right.svg';

import * as enums from './enums';

import styles from './DataRowItem.scss';

interface SharedProps {
  label: string;
}

interface DescripionProps extends SharedProps {
  color?: enums.Colors;
  description: string;
  link?: {
    path: string;
    shouldOpenNewTab?: boolean;
  };
  tooltip?: {
    text: string;
  };
}

interface ChildrenProps extends SharedProps {
  children: ReactNode;
}

type Props = DescripionProps | ChildrenProps;

function DataRowItem(props: Props) {
  let contents = 'description' in props ? (
    <Text
      color={props.link ? Text.enums.Colors.Inherit : props.color}
      content={props.description}
      isEmphasized
      size={Text.enums.Sizes.Large}
      decoration={props.tooltip ? Text.enums.Decorations.DottedUnderline : undefined}
      tag={props.link ? Text.enums.Tags.Span : undefined}
    />
  ) : props.children;

  if ('description' in props && props.tooltip) {
    contents = (
      <Tooltip
        text={props.tooltip.text}
        position={Tooltip.enums.Positions.Up}
        length={Tooltip.enums.Lengths.Medium}>
        {contents}
      </Tooltip>
    );
  }

  return (
    <div styleName="data-row-item">
      <Heading
        content={props.label}
        font={Heading.enums.Fonts.Secondary}
        size={Heading.enums.Sizes.XXXSmall}
      />
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
      {'description' in props && props.link ? (
        <Link
          shouldOpenNewTab={props.link.shouldOpenNewTab}
          styleName="data-row-item__link"
          to={props.link.path}>
          <Row
            flexBehavior={Row.enums.FlexBehaviors.Default}
            verticalAlignment={Row.enums.VerticalAlignments.Center}>
            {contents}
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
            <ChevronRight styleName="data-row-item__chevron" />
          </Row>
        </Link>
      ) : contents}
    </div>
  );
}

export default withStyles(styles)(DataRowItem);
