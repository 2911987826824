import { Moment } from 'moment';
import { ConditionReportTypes } from '~tools/types/graphqlSchema';

export enum TimelineEntryTypes {
  REPORT = 'REPORT',
  INSPECTION = 'INSPECTION',
}

export interface TimelineEntry {
  uuid: string;
  createdAt: Moment;
  description: string;
  type: TimelineEntryTypes;
  reportType?: ConditionReportTypes;
}
