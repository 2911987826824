import { ServiceTypes, UpdateServiceProviderInput } from '~tools/types/graphqlSchema';
import withMutation from '~tools/react/graphql/withMutation';

import UpdateServiceProviderMutation from './updateServiceProvider.gql';

interface ServiceProvider {
  uuid: string;
  serviceType: ServiceTypes;
}

interface Response {
  updateServiceProvider: ServiceProvider;
}

interface Variables {
  input: UpdateServiceProviderInput;
  uuid: string;
}

export interface UpdateServiceProviderProps {
  updateServiceProvider: (uuid: string, input: UpdateServiceProviderInput) => Promise<ServiceProvider>;
}

export default withMutation<{}, Response, Variables, UpdateServiceProviderProps>(UpdateServiceProviderMutation, {
  props: props => ({
    updateServiceProvider: async (uuid: string, input: UpdateServiceProviderInput) => {
      const res = await props.mutate({
        variables: {
          input,
          uuid,
        },
      });

      if (!res || !res.data) throw new Error('Error updating service provider');
      if (!res.data.updateServiceProvider) throw new Error('No updateServiceProvider returned');

      return res.data.updateServiceProvider;
    },
  }),
});
