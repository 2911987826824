// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._NRBtq3 ._P9WY2j{display:grid;grid-gap:16px;grid-template-columns:repeat(auto-fit,minmax(100px,1fr));width:100%}._NRBtq3 ._ernb2Q{font-weight:500;margin:0 0 16px}", ""]);
// Exports
exports.locals = {
	"data-grid": "_NRBtq3",
	"data-grid__columns": "_P9WY2j",
	"data-grid__title": "_ernb2Q"
};
module.exports = exports;
