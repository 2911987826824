// @flow
import React from 'react';

import Button from '~tools/react/components/Button';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './DashboardListingVerifyPostcardConfirmation.scss';

type Props = {
  isLoading: boolean,
  onCancel: () => void,
  onSubmit: () => Promise<*>,
};

function DashboardListingVerifyPostcardConfirmation(props: Props) {
  return (
    <div styleName="verify-postcard-confirmation">
      <p>
        Postcard confirmation takes 4-6 business days for the postcard to ship to your listing address.
        When you receive your postcard, you’ll be given some details on how to verify your address.
        <br /><br />
        Click below and your postcard will be on the way!
      </p>
      <div styleName="verify-postcard-confirmation__btns">
        <Button
          color={Button.enums.Colors.White}
          label="Cancel"
          onClick={props.onCancel}
        />
        <Button
          isLoading={props.isLoading}
          label="Send postcard"
          onClick={props.onSubmit}
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(DashboardListingVerifyPostcardConfirmation);
