import classNames from 'classnames';
import React, { ComponentType, PureComponent, ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';
import ChevronRight from '~tools/svgs/icons/interface/chevron-right.svg';

import Link from '~tools/react/components/utility/Link';

import styles from './TableRow.scss';

interface Props {
  identifier?: string;
  children: ReactNode;
  isHeader?: boolean;
  onClick?: (identifier: string) => void;
  link?: {
    path: string;
  };
}

class TableRow extends PureComponent<Props> {
  static defaultProps = {
    isHeader: false,
  };

  handleOnClick = () => {
    if (!this.props.onClick || !this.props.identifier) return;
    this.props.onClick(this.props.identifier);
  }

  render() {
    const link = this.props.link;
    if (link) {
      return (
        <tr
          className={classNames({
            [styles.table__tr]: true,
            [styles['table__tr--header']]: this.props.isHeader,
            [styles['table__tr--linked']]: true,
          })}>
          {React.Children.map(this.props.children, child => (
            <td>
              <Link to={link.path} styleName="table__cell table__cell--linked">
                {child}
              </Link>
            </td>
          ))}
          <td>
            <Link to={link.path} styleName="table__cell table__cell--linked table__cell--chevron">
              <ChevronRight />
            </Link>
          </td>
        </tr>
      );
    }

    if (this.props.onClick) {
      return (
        <tr
          className={classNames({
            [styles.table__tr]: true,
            [styles['table__tr--header']]: this.props.isHeader,
            [styles['table__tr--linked']]: true,
          })}>
          {React.Children.map(this.props.children, child => (
            <td>
              <span onClick={this.handleOnClick} styleName="table__cell table__cell--linked">
                {child}
              </span>
            </td>
          ))}
          <td>
            <span onClick={this.handleOnClick} styleName="table__cell table__cell--linked table__cell--chevron">
              <ChevronRight />
            </span>
          </td>
        </tr>
      );
    }

    const lastChildIndex = React.Children.count(this.props.children) - 1;
    return (
      <tr
        className={classNames({
          [styles.table__tr]: true,
          [styles['table__tr--header']]: this.props.isHeader,
        })}>
        {React.Children.map(this.props.children, (child, idx) => (
          // Add colspan to the last cell of header rows so it expands to cover the space
          // above the link chevrons
          (this.props.isHeader && idx === lastChildIndex) ?
            <td colSpan={2} styleName="table__cell">{child}</td> :
            <td styleName="table__cell">{child}</td>
        ))}
      </tr>
    );
  }
}

export default withStyles(styles)(TableRow) as ComponentType<Props>;
