// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2dAa2z{display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row;-webkit-box-pack:center;justify-content:center}@media screen and (max-width:1127px){._2dAa2z{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column}}._2dAQ09{-webkit-box-align:center;align-items:center;background:#fff;border-radius:50%;box-shadow:0 4px 12px 0 rgba(193,59,2,.16);color:#1f8eed;display:-webkit-box;display:flex;font-family:DomaineText,Times New Roman,Times,Baskerville,Georgia,serif;font-size:28px;font-weight:700;height:40px;-webkit-box-pack:center;justify-content:center;line-height:40px;width:40px}@media screen and (max-width:1127px){._2dAQ09{left:4px;position:relative}}._orGDd9{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex}._orGDd9 svg{height:auto;max-height:40vh;max-width:100%}@media screen and (min-width:1127px){._BX0Yv5{max-width:500px}}", ""]);
// Exports
exports.locals = {
	"takeover-smart-lock-setup-step": "_2dAa2z",
	"takeover-smart-lock-setup-step__number": "_2dAQ09",
	"takeover-smart-lock-setup-step__illustration": "_orGDd9",
	"takeover-smart-lock-setup-step__contents": "_BX0Yv5"
};
module.exports = exports;
