import _ from 'lodash';
import React, { PureComponent } from 'react';

import { MessageTypes } from '~web-core/lib/common/types';

import graphql, { compose } from '~tools/react/graphql';
import sendMessageMutation from '~tools/react/graphql/mutations/messages/sendMessage.gql';
import createPhotoMutation from '~tools/react/graphql/mutations/photos/createPhoto.gql';
import signUploadRequestMutation from '~tools/react/graphql/mutations/uploads/signUploadRequest.gql';

import ThreadQuery from '../../Thread.gql';
import Input from './components/Input';

import { EntityType } from './enums';
import {
  CreatePhotoInput,
  FileUploadInput,
  LocalAttachment,
  Photo,
  SendMessageInput,
  SignUploadRequestInput,
} from './types';

interface Props {
  user: { photoUrl: string } | null;
  threadUuid: string | null;
  isContainerScrolled: boolean;
  signUploadRequest: (input: SignUploadRequestInput) => Promise<any>;
  sendMessage: (input: SendMessageInput) => Promise<void>;
  createPhoto: (input: CreatePhotoInput) => Promise<Photo>;
  onSendMessage: () => void;
}

interface State {
  localAttachments: LocalAttachment[];
}

class ThreadInput extends PureComponent<Props, State> {
  state: State = {
    localAttachments: [],
  };

  handleClickSend = (message: string) => {
    if (!message) return;
    const attachments = _.reject(this.state.localAttachments, { photo: undefined });
    const data = {
      attachments: _.map(attachments, attachment => ({
        entityType: attachment.entityType,
        entityUuid: attachment.photo!.uuid,
        photo: attachment.photo!
      })),
      text: message,
    };
    this.props.sendMessage(data);
    this.props.onSendMessage();
    this.setState({ localAttachments: [] });
  }

  handleRemoveAttachment = (uuid: string) => {
    this.setState({ localAttachments: _.reject(this.state.localAttachments, { photo: { uuid } }) });
  }

  handleFileUpload = async (input: FileUploadInput) => {
    if (!input.file || !this.props.threadUuid) return;

    this.setState({
      localAttachments: _.concat(this.state.localAttachments, [{
        entityType: EntityType.Photo,
        file: input.file,
        progressUploaded: 0,
      }]),
    });

    const aws = await this.props.signUploadRequest({ filename: input.file.name });
    const xhr = new XMLHttpRequest();
    const uploadForm = new FormData();
    uploadForm.append('key', aws.key);
    uploadForm.append('AWSAccessKeyId', aws.accessKeyId);
    uploadForm.append('acl', aws.acl);
    uploadForm.append('policy', aws.policy);
    uploadForm.append('signature', aws.signature);
    uploadForm.append('Content-Type', aws.contentType);
    uploadForm.append('file', input.file);

    xhr.addEventListener('load', async () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        const photo = await this.props.createPhoto({
          entityType: 'THREAD',
          entityUuid: this.props.threadUuid!,
          height: input.height,
          key: aws.key,
          width: input.width,
        });

        this.setState({
          localAttachments: this.state.localAttachments.map(el =>
            (el.file === input.file ? { ...el, photo } : el)
          ),
        });
      }
    });

    xhr.upload.addEventListener('progress', (e) => {
      this.setState({
        localAttachments: this.state.localAttachments.map(el =>
          (el.file === input.file ? { ...el, progressUploaded: e.loaded / e.total } : el)
        )
      });
    });

    xhr.addEventListener('error', () => {
      throw new Error('Network error');
    });

    xhr.open('POST', aws.bucketUrl);

    xhr.send(uploadForm);
  }

  render() {
    return (
      <Input
        attachments={this.state.localAttachments}
        uploadFile={this.handleFileUpload}
        isContainerScrolled={this.props.isContainerScrolled}
        onSubmit={this.handleClickSend}
        removeAttachment={this.handleRemoveAttachment}
      />
    );
  }
}

export default compose(
  graphql(sendMessageMutation, {
    props: props => ({
      sendMessage: async (message: SendMessageInput) => {
        const input: { threadUuid: string, text: string, attachments?: { entityUuid: string, entityType: EntityType }[] } = {
          text: message.text,
          threadUuid: props.ownProps.threadUuid,
        };

        if (message.attachments && message.attachments.length > 0) {
          input.attachments = _.map(message.attachments, att => ({
            entityType: att.entityType,
            entityUuid: att.entityUuid,
          }));
        }

        const res = await props.mutate({
          optimisticResponse: {
            __typename: 'sendMessage',
            sendMessage: {
              __typename: 'Message',
              attachments: _.map(message.attachments, att => ({
                __typename: 'MessageAttachment',
                action: null,
                contentAnswer: null,
                entityType: att.entityType,
                photo: {
                  ...att.photo,
                  __typename: 'Photo',
                  position: null,
                },
                rentPayment: null,
                securityDeposit: null,
                uuid: Math.round(Math.random() * -1000000),
              })),
              createdAt: new Date(),
              isReadByViewer: true,
              iconUrl: null,
              text: message.text,
              title: null,
              type: MessageTypes.PEER,
              user: {
                __typename: 'Profile',
                firstName: props.ownProps.user.firstName,
                isAdmin: false,
                photoUrl: props.ownProps.user.photoUrl,
                uuid: props.ownProps.user.uuid,
              },
              uuid: Math.round(Math.random() * -1000000),
            },
          },
          update: (store, updateProps) => {
            if (updateProps.data.sendMessage) {
              const data = store.readQuery({
                query: ThreadQuery,
                variables: { uuid: props.ownProps.threadUuid },
              });
              data.viewer.thread.messages.edges.push({
                __typename: 'MessageEdge',
                node: updateProps.data.sendMessage,
              });
              store.writeQuery({
                data,
                query: ThreadQuery,
                variables: { uuid: props.ownProps.threadUuid },
              });
            }
          },
          variables: {
            input,
          },
        });
        return res.data.sendMessage;
      },
    }),
  }),
  graphql(signUploadRequestMutation, {
    props: props => ({
      signUploadRequest: async (input: SignUploadRequestInput) => {
        const res = await props.mutate({
          variables: {
            input,
          },
        });

        return res.data.signUploadRequest;
      },
    }),
  }),
  graphql(createPhotoMutation, {
    props: props => ({
      createPhoto: async (input: CreatePhotoInput) => {
        const res = await props.mutate({
          variables: {
            input,
          },
        });

        return res.data.createPhoto;
      },
    }),
  }),
)(ThreadInput);
