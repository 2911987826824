import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { canUseDOM } from '~tools/utils/environment';

interface Props {
  children: ReactNode;
  node?: Element;
}

function Portal(props: Props) {
  if (!canUseDOM) return null;

  const node = props.node || document.body;
  if (!node) return null;

  return createPortal(props.children, node);
}

export default Portal;
