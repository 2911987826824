import { ApolloQueryResult } from 'apollo-client';
import { RouteComponentProps } from 'react-router-dom';
import React, { PureComponent } from 'react';

import { compose } from '~tools/react/hocs/utils';
import { QualificationTypes, RentalPolicyEntityTypes, RentalPolicyTypes } from '~tools/types/graphqlSchema';
import ArrowLink from '~tools/react/components/ArrowLink';
import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Card from '~tools/react/components/Card';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import withCreateRentalPolicy, { CreateRentalPolicyProps } from '~tools/react/graphql/mutations/rentalPolicies/withCreateRentalPolicy';
import withDeleteRentalPolicy, { DeleteRentalPolicyProps } from '~tools/react/graphql/mutations/rentalPolicies/withDeleteRentalPolicy';
import withQuery from '~tools/react/graphql/withQuery';

import { formatUnitName } from '~web-manage/lib/common/utils/addressUnit';
import LoadingVisual from '~web-manage/lib/common/components/__deprecated/LoadingVisual';
import ManageStage from '~web-manage/lib/common/stages/ManageStage';
import UnitQualificationManager from '~web-manage/lib/common/containers/UnitQualificationManager';

import { PropertyManagerContract } from './types';
import query from './UnitApplications.gql';

type InputProps = RouteComponentProps<{ propertyManagerContractUuid: string }>;

type Props = InputProps
  & QueryProps
  & CreateRentalPolicyProps
  & DeleteRentalPolicyProps;

class UnitApplications extends PureComponent<Props> {
  handleChangeUseGlobalSettings = async (isChecked: boolean) => {
    const pmcRentalPolicy = this.props.propertyManagerContract?.rentalPolicy;

    if (isChecked) {
      if (pmcRentalPolicy?.uuid) {
        await this.props.deleteRentalPolicy(pmcRentalPolicy.uuid);
      }
    } else if (!pmcRentalPolicy?.uuid) {
      await this.props.createRentalPolicy({
        entityType: RentalPolicyEntityTypes.PROPERTYMANAGERCONTRACT,
        entityUuid: this.props.propertyManagerContract?.uuid ?? '',
        type: RentalPolicyTypes.BASE,
      });
    }

    await this.props.refetch();
  };

  render() {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (this.props.isLoading || !propertyManagerContract) {
      return (
        <ManageStage>
          <LoadingVisual />
        </ManageStage>
      );
    }

    const addressUnit = propertyManagerContract.addressUnit;
    const address = propertyManagerContract.addressUnit.address;

    return (
      <ManageStage>
        <Breadcrumbs
          items={[
            { path: '/units', label: 'All units' },
            { path: `/units/${propertyManagerContract.uuid}`, label: `${address.streetAddress1} ${formatUnitName(addressUnit)}` },
            { label: 'Applications' },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
        <Card
          header={{
            actions: [{
              isChecked: !this.props.propertyManagerContract?.rentalPolicy?.uuid,
              label: 'Use global settings',
              onChange: this.handleChangeUseGlobalSettings,
            }],
            subtitle: 'Set the minimum qualifications for a new tenant for this unit.',
            title: 'Unit tenant requirements',
          }}>
          <Card.CardSection>
            {propertyManagerContract.rentalPolicy ? (
              <>
                <UnitQualificationManager
                  listingUuid={propertyManagerContract.listing.uuid}
                  type={QualificationTypes.INCOME}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
                <HorizontalRule />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
                <UnitQualificationManager
                  listingUuid={propertyManagerContract.listing.uuid}
                  type={QualificationTypes.CREDIT_SCORE}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
                <HorizontalRule />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
                <UnitQualificationManager
                  listingUuid={propertyManagerContract.listing.uuid}
                  type={QualificationTypes.CIVIL_HISTORY}
                />
              </>
            ) : (
              <ArrowLink
                color={ArrowLink.enums.Colors.Blue}
                label="Edit global requirement settings"
                link={{
                  path: `/settings/applications`,
                }}
                size={ArrowLink.enums.Sizes.Medium}
              />
            )}
          </Card.CardSection>

        </Card>
      </ManageStage>
    );
  }
}

interface Response {
  viewer: {
    propertyManagerContract: PropertyManagerContract | null;
    uuid: string;
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  propertyManagerContract: PropertyManagerContract | null;
  refetch: () => Promise<ApolloQueryResult<Response>>;
}

interface Variables {
  propertyManagerContractUuid: string;
}

export default compose(
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: props => ({
      ssr: false,
      skip: !props.match.params.propertyManagerContractUuid,
      variables: {
        propertyManagerContractUuid: props.match.params.propertyManagerContractUuid,
      },
    }),
    props: props => ({
      propertyManagerContract: props.data?.viewer?.propertyManagerContract || null,
      isLoading: props.loading,
      refetch: props.refetch,
    }),
  }),
  withCreateRentalPolicy,
  withDeleteRentalPolicy,
)(UnitApplications);
