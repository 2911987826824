import _ from 'lodash';
import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Image from '~tools/react/components/utility/Image';

import styles from './PhotoGrid.scss';

interface Props {
  images: {
    id: string;
    position: number;
    url: string;
  }[];
}

const PhotoGrid = (props: Props) => {
  return (
    <div styleName="photo-grid">
      {_.map(props.images, (image) => (
        <Image
          key={image.id}
          size={{
            h: 200,
            w: 200,
          }}
          src={image.url}
          styleName="photo-grid__thumbnail"
        />
      ))}
    </div>
  );
};

export default withStyles(styles)(PhotoGrid);
