export enum Colors {
  Blue = 'BLUE',
  Gray = 'GRAY',
  Green = 'GREEN',
  Red = 'RED',
}

export enum SVGs {
  Alert = 'ALERT',
  Check = 'CHECK',
  Clock = 'CLOCK',
  ExternalSend = 'EXTERNAL_SEND',
  Refresh = 'REFRESH',
}
