export enum Events {
  CreatePaymentSubscription = 'Create Payment Subscription',
  Register = 'Register',
  SelectNewUnitAddress = 'Select New Unit Address',
  SelectNewUnitListingType = 'Select New Unit Listing Type',
  SelectPlan = 'Select Plan',
  SubmitNewUnit = 'Submit New Unit',
  SubmitSelectedPlan = 'Submit Selected Plan',
  UpdatePaymentSubscription = 'Update Payment Subscription',
}
