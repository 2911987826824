// @flow

import React, { Component, type Node } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import { deepFreeze } from '~web-core/lib/common/utils/object';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './AnimationView.scss';

const enums = deepFreeze({
  Animations: {
    FadeUp: 'FADE_UP',
  },
});

type Props = {|
  animation: $Values<typeof enums.Animations>,
  children: Node,
  delay?: string,
|};

class AnimationView extends Component<Props> {
  static enums = enums;

  render() {
    return (
      <div
        styleName={classNames({
          'animation-view': true,
          [`animation-view--${_.kebabCase(this.props.animation)}`]: this.props.animation,
        })}
        style={{
          animationDelay: this.props.delay || '',
        }}>
        {this.props.children}
      </div>
    );
  }
}

export default withStyles(styles)(AnimationView);
