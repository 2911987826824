// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._YXrkEX,._GN6Q3Y{display:inline-block}._YXrkEX{background-color:#ddd;background-size:cover;border-radius:10px;height:48px;margin-right:8px;width:48px}", ""]);
// Exports
exports.locals = {
	"access-photo": "_YXrkEX",
	"access-photo-link": "_GN6Q3Y"
};
module.exports = exports;
