// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._GN6JX3{width:600px}@media screen and (max-width:1127px){._GN6JX3{width:100%}}", ""]);
// Exports
exports.locals = {
	"checklist-view": "_GN6JX3"
};
module.exports = exports;
