import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './ResponsiveStepButtons.scss';

interface Props {
  children: ReactNode;
  isMobile: boolean;
  isShown: boolean;
}

function ResponsiveStepButtons(props: Props) {
  return (
    <div styleName="responsive-step-buttons">
      {props.children}
    </div>
  );
}

export default withStyles(styles)(ResponsiveStepButtons);
