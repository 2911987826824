import SignUploadRequestMutation from '~tools/react/graphql/mutations/uploads/signUploadRequest.gql';
import withMutation from '~tools/react/graphql/withMutation';

import { SignUploadRequestInputType } from '~tools/types/graphqlSchema';

export interface AwsSignature {
  accessKeyId: string;
  acl: string;
  bucket: string;
  bucketUrl: string;
  contentType: string;
  fullUrl: string;
  key: string;
  policy: string;
  signature: string;
}

interface Data {
  signUploadRequest: AwsSignature;
}

interface Variables {
  input: SignUploadRequestInputType;
}

export interface SignUploadRequestProps {
  signUploadRequest: (input: SignUploadRequestInputType) => Promise<AwsSignature>;
}

export default withMutation<{}, Data, Variables, SignUploadRequestProps>(SignUploadRequestMutation, {
  props: props => ({
    signUploadRequest: async (input) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error signing upload request.');

      return res.data.signUploadRequest;
    }
  }),
});
