import _ from 'lodash';
import React, { Fragment } from 'react';

import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import Form, { Input } from '~tools/react/components/Form';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import * as inputEnums from '~tools/react/components/Form/components/Input/enums';

import * as enums from './enums';

type Props = {
  button?: {
    label: string;
    onClick?: () => void;
  },
  description: string,
  form?: {
    inputs: Array<{
      label: string,
      name: string,
      pattern?: RegExp,
      placeholder: string,
      type?: inputEnums.Types;
      value?: any;
    }>,
    onSubmit: (data: { [key: string]: any }) => Promise<any>,
  },
  format?: enums.Formats;
  isLoading: boolean;
  metaText: string;
  status: enums.Statuses;
  title: string;
}

function VerifyMethodCard(props: Props) {
  return (
    <Card>
      <Card.CardSection
        backgroundColor={
          props.status === enums.Statuses.Failed ?
            Card.CardSection.enums.BackgroundColors.Gray :
            undefined
        }
        title={props.title}
        tag={{
          label: props.metaText,
          color: props.metaText === 'Instant' ? Card.CardSection.enums.TagColors.Green : undefined,
        }}>
        {props.format === enums.Formats.Form && props.form && props.button ? (
          <Form onSubmit={props.form.onSubmit}>
            <div style={{ height: '250px' }}>
              <Text
                content={props.description}
                size={Text.enums.Sizes.Small}
              />
              {_.map(props.form.inputs, input => (
                <Fragment key={input.name}>
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
                  <Input
                    isRequired
                    label={input.label}
                    labelFormat={Input.enums.LabelFormats.Stacked}
                    name={input.name}
                    regexPattern={input.pattern}
                    placeholder={input.placeholder}
                    type={input.type || Input.enums.Types.Text}
                    value={input.value}
                  />
                </Fragment>
              ))}
            </div>
            <Button
              isLoading={props.isLoading}
              label={props.button.label}
              size={Button.enums.Sizes.Small}
              type={Button.enums.Types.Submit}
            />
          </Form>
        ) : null}
        {props.format === enums.Formats.Button && props.button && props.button.onClick ? (
          <Fragment>
            <div style={{ height: '250px' }}>
              <Text
                content={props.description}
                size={Text.enums.Sizes.Small}
              />
            </div>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
            <Button
              isLoading={props.isLoading}
              label={props.button.label}
              onClick={props.button.onClick}
              size={Button.enums.Sizes.Small}
              width={Button.enums.Widths.Full}
            />
          </Fragment>
        ) : null}
      </Card.CardSection>
    </Card>
  );
}

VerifyMethodCard.enums = enums;
VerifyMethodCard.defaultProps = {
  format: enums.Formats.Text,
};

export default VerifyMethodCard;
