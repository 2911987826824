import React, { Component } from 'react';

import withSignUploadRequest, { SignUploadRequestProps } from '~tools/react/graphql/mutations/uploads/withSignUploadRequest';

import DropzoneForm from './components/DropzoneForm';
import * as DropzoneFormEnums from './components/DropzoneForm/enums';

import * as enums from './enums';

const enumToFileTypes = {
  [enums.UploadTypes.Image]: [
    DropzoneForm.enums.FileTypes.JPEG,
    DropzoneForm.enums.FileTypes.PNG,
  ],
  [enums.UploadTypes.Document]: [
    DropzoneForm.enums.FileTypes.Doc,
    DropzoneForm.enums.FileTypes.DocX,
    DropzoneForm.enums.FileTypes.DocM,
    DropzoneForm.enums.FileTypes.PDF,
    DropzoneForm.enums.FileTypes.JPEG,
    DropzoneForm.enums.FileTypes.PNG,
    DropzoneForm.enums.FileTypes.TIFF,
    DropzoneForm.enums.FileTypes.Text,
  ],
};

export interface DropzoneLoadData {
  file: {
    name: string;
    type: string;
    uri: string;
  };
  bucket: string;
  bucketUrl: string;
  key: string;
}

export interface DropzoneLoadCallback {
  (arg: DropzoneLoadData, file: File): void;
}

interface InputProps {
  isInvalid?: boolean;
  label?: string;
  labelFormat?: enums.LabelFormats;
  onError?: (error: Error) => void;
  onLoad: DropzoneLoadCallback;
  placeholder?: string;
  placeholderIcon?: DropzoneFormEnums.Icons;
  shouldAllowMultipleFiles?: boolean;
  shouldRenderFileManager?: boolean;
  uploadType: enums.UploadTypes;
}

type Props = InputProps & SignUploadRequestProps;

interface State {
  file?: {
    name: string;
    uri: string;
  };
  uploadProgress: number;
}

class Dropzone extends Component<Props, State> {
  static enums = enums;

  state: State = {
    uploadProgress: 0,
  };

  handleClear = () => this.setState({
    file: undefined,
    uploadProgress: 0,
  });
  handleProgress = (uploadProgress: number) => this.setState({ uploadProgress });
  handleUpload = async (file: File) => {
    Object.defineProperty(file, 'name', {
      writable: true,
      value: file.name.replace('#', ''),
    });

    const data = await this.props.signUploadRequest({ filename: file.name });
    const xhr = new XMLHttpRequest();
    const uploadForm = new FormData();

    uploadForm.append('key', data.key);
    uploadForm.append('AWSAccessKeyId', data.accessKeyId);
    uploadForm.append('acl', data.acl);
    uploadForm.append('policy', data.policy);
    uploadForm.append('signature', data.signature);
    uploadForm.append('Content-Type', data.contentType);
    uploadForm.append('file', file);

    xhr.addEventListener('load', () => {
      this.props.onLoad({
        file: {
          name: file.name,
          type: file.type,
          uri: `${data.bucketUrl}${data.key}`,
        },
        bucket: data.bucket,
        bucketUrl: data.bucketUrl,
        key: data.key,
      }, file);
      this.setState({
        file: {
          name: file.name,
          uri: `${data.bucketUrl}${data.key}`,
        },
      });
    });
    xhr.addEventListener('error', () => {
      const onError = this.props.onError;
      if (onError) onError(new Error('There was an error with the upload.'));
    });

    xhr.open('POST', data.bucketUrl);

    xhr.upload.addEventListener('progress', (e) => {
      this.handleProgress((e.loaded / e.total) * 100);
    });

    xhr.send(uploadForm);
  }

  render() {
    return (
      <DropzoneForm
        file={this.state.file}
        isInvalid={this.props.isInvalid}
        label={this.props.label}
        labelFormat={this.props.labelFormat}
        onClear={this.handleClear}
        onDrop={this.handleUpload}
        placeholder={this.props.placeholder || this.props.label || ''} // this is to avoid breaking old components because label was originally misplaced
        placeholderIcon={this.props.placeholderIcon}
        shouldAllowMultipleFiles={this.props.shouldAllowMultipleFiles}
        shouldRenderFileManager={this.props.shouldRenderFileManager}
        supportedFileTypes={enumToFileTypes[this.props.uploadType]}
        unsupportedTypeMessage={this.getUnsupportedTypeMessage(this.props.uploadType)}
        uploadProgress={this.state.uploadProgress}
      />
    );
  }

  getUnsupportedTypeMessage = (uploadType) => {
    switch (uploadType) {
      case enums.UploadTypes.Document: {
        return 'Uh oh! Looks like we don\'t support that file type. Please upload a PDF, Microsoft Word document, or image.';
      }
      case enums.UploadTypes.Image: {
        return 'Uh oh! Looks like we don\'t support that file type here. Try a .jpeg or .png file.';
      }
      default: {
        return 'That file type is not supported.';
      }
    }
  }
}

export default withSignUploadRequest(Dropzone);
