import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import React, { Fragment, PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import withQuery from '~tools/react/graphql/withQuery';

import { ApplicationStatuses, PageInfo } from '~tools/types/graphqlSchema';
import { pageToCursor } from '~tools/react/graphql/utils';

import Card from '~tools/react/components/Card';
import Tag from '~tools/react/components/Tag';

import { formatFullAddress } from '~web-manage/lib/common/utils/addressUnit';
import ManageStage from '~web-manage/lib/common/stages/ManageStage';
import Table from '~tools/react/components/Table';
import Text from '~tools/react/components/Text';

import query from './Applicants.gql';

import { Application } from './types';

type Props = InputProps & QueryProps;

const NUMBER_OF_APPLICATIONS_TO_FETCH = 10;

class Applicants extends PureComponent<Props> {
  render() {
    const applications = this.props.applications;

    return (
      <ManageStage>
        <Card
          header={{
            title: 'All applicants',
            subtitle: 'Manage your applicants',
          }}>
          <Table>
            <Table.TableHead>
              <Table.TableRow>
                <Table.TableHeader>Opened</Table.TableHeader>
                <Table.TableHeader>Renter</Table.TableHeader>
                <Table.TableHeader>Status</Table.TableHeader>
                <Table.TableHeader>Proposed Term</Table.TableHeader>
                <Table.TableHeader>Unit</Table.TableHeader>
                <Table.TableHeader />
              </Table.TableRow>
            </Table.TableHead>
            <Table.TableBody>
              {applications.length > 0 ? (
                <Fragment>
                  {_.map(applications, (application) => (
                    <Table.TableRow key={application.uuid} link={{ path: `/applicants/${application.uuid}` }}>
                      <Table.TableData>
                        <Text
                          content={moment(application.createdAt).fromNow()}
                        />
                      </Table.TableData>
                      <Table.TableData>
                        <Text
                          content={application.applicants[0].fullName}
                        />
                      </Table.TableData>
                      <Table.TableData>
                        <Tag
                          label={this.getApplicationStatus(application.status).label}
                          color={this.getApplicationStatus(application.status).color}
                        />
                      </Table.TableData>
                      <Table.TableData>
                        <Text
                          content={`${moment(application.lease.startDate).format('MMM D')} - ${moment(application.lease.endDate).format('MMM D')}`}
                        />
                      </Table.TableData>
                      <Table.TableData>
                        <Text
                          content={formatFullAddress(application.listing.addressUnit)}
                        />
                      </Table.TableData>
                    </Table.TableRow>
                  ))}
                  {this.props.applicationsPageInfo ? (
                    <Table.TablePaginator
                      startCursor={this.props.applicationsPageInfo.startCursor ?? ''}
                      endCursor={this.props.applicationsPageInfo.endCursor ?? ''}
                      resultsPerPage={NUMBER_OF_APPLICATIONS_TO_FETCH}
                      totalResults={this.props.applicationsTotal}
                    />
                  ) : null}
                </Fragment>
              ) : (
                <Table.TableEmptyState
                  label="Here’s where your first applicant will show up."
                />
              )}
            </Table.TableBody>
          </Table>
        </Card>
      </ManageStage>
    );
  }

  getApplicationStatus = (applicationStatus: ApplicationStatuses) => {
    switch (applicationStatus) {
      case ApplicationStatuses.APPLIED:
        return {
          label: 'APPLIED',
          color: Tag.enums.Colors.Blue,
        };
      case ApplicationStatuses.REJECTED:
        return {
          label: 'REJECTED',
          color: Tag.enums.Colors.Red,
        };
      case ApplicationStatuses.WITHDRAWN:
        return {
          label: 'WITHDRAWN',
          color: Tag.enums.Colors.Red,
        };
      case ApplicationStatuses.ACCEPTED:
        return {
          label: 'ACCEPTED',
          color: Tag.enums.Colors.Blue,
        };
      case ApplicationStatuses.IN_REVIEW:
        return {
          label: 'LANDLORD REVIEW',
          color: Tag.enums.Colors.Blue,
        };
      case ApplicationStatuses.FLIPPED:
        return {
          label: 'Complete',
          color: Tag.enums.Colors.Green,
        };
      case ApplicationStatuses.OPENED:
      default:
        return {
          label: 'OPEN',
          color: undefined,
        };
    }
  }
}

interface Response {
  viewer: {
    uuid: string;
    applications: Application[];
    applicationsTotal: number;
    applicationsPageInfo: PageInfo;
  } | null;
}

interface Variables {
  after?: string;
  first?: number;
}

type InputProps = RouteComponentProps;

interface QueryProps {
  applications: Application[];
  applicationsTotal: number;
  applicationsPageInfo: PageInfo | null;
}

export default withQuery<InputProps, Response, Variables, QueryProps>(query, {
  options: (props) => {
    const pageString = queryString.parse(props.location.search).page;
    const page = _.parseInt(typeof pageString === 'string' ? pageString : '1') ?? undefined;
    const after = page > 1 ? pageToCursor(page, NUMBER_OF_APPLICATIONS_TO_FETCH) : undefined;
    return {
      variables: {
        after,
        filter: {
          isActive: true,
        },
        first: NUMBER_OF_APPLICATIONS_TO_FETCH,
      },
    };
  },
  props: (props) => ({
    applications: props.data?.viewer?.applications ?? [],
    applicationsTotal: props.data?.viewer?.applicationsTotal ?? 0,
    applicationsPageInfo: props.data?.viewer?.applicationsPageInfo ?? null,
  }),
})(Applicants);
