import withMutation from '~tools/react/graphql/withMutation';
import { ServiceTypes, UpdateServicePreferenceInput } from '~tools/types/graphqlSchema';

import UpdateServicePreferenceMutation from './updateServicePreference.gql';

interface ServicePreference {
  uuid: string;
  serviceType: ServiceTypes;
}

interface Response {
  updateServicePreference: ServicePreference;
}

interface Variables {
  input: UpdateServicePreferenceInput;
  uuid: string;
}

export interface UpdateServicePreferenceProps {
  updateServicePreference: (uuid: string, input: UpdateServicePreferenceInput) => Promise<ServicePreference>;
}

export default withMutation<{}, Response, Variables, UpdateServicePreferenceProps>(UpdateServicePreferenceMutation, {
  props: props => ({
    updateServicePreference: async (uuid: string, input: UpdateServicePreferenceInput) => {
      const res = await props.mutate({
        variables: {
          input,
          uuid,
        },
      });

      if (!res || !res.data) throw new Error('Error updating service preference');
      if (!res.data.updateServicePreference) throw new Error('No updateServicePreference returned');

      return res.data.updateServicePreference;
    },
  }),
});
