import classNames from 'classnames';
import _ from 'lodash';
import React, { PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './SkeletonCircle.scss';

import * as enums from './enums';

interface Props {
  size?: enums.Sizes;
}

class SkeletonCircle extends PureComponent<Props> {
  static enums = enums;

  static defaultProps = {
    size: enums.Sizes.Medium,
  };

  render() {
    return (
      <div
        styleName={classNames({
          'skeleton-circle': true,
          [`skeleton-circle--size-${_.kebabCase(this.props.size)}`]: this.props.size,
        })}
      />
    );
  }
}

export default withStyles(styles)(SkeletonCircle);
