import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// import { SpendingThresholdTypes } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';

import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Card from '~tools/react/components/Card';
import GenericLoadingVisual from '~tools/react/components/GenericLoadingVisual';
// import SetAccountSpendingThresholdModal from '~tools/react/containers/SetAccountSpendingThresholdModal';
import Table from '~tools/react/components/Table';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import CreateServicePreferenceModal from '~web-manage/lib/common/containers/CreateServicePreferenceModal';
import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import { MaintenanceSetting, ServicePreference } from './types';

import query from './MaintenanceSettings.gql';

dayjs.extend(relativeTime);

type Props = QueryProps
  & RouteComponentProps;

interface State {
  isCreateServicePreferenceModalOpen: boolean;
  // isSetAccountSpendingThresholdModalOpen: boolean;
}

class MaintenanceSettings extends Component<Props, State> {
  state: State = {
    isCreateServicePreferenceModalOpen: false,
    // isSetAccountSpendingThresholdModalOpen: false,
  };

  handleAddServiceProvider = () => this.setState({ isCreateServicePreferenceModalOpen: true });
  handleCloseCreateServicePreferenceModal = () => this.setState({ isCreateServicePreferenceModalOpen: false });

  // handleUpdateSpendingThresholds = () => this.setState({ isSetAccountSpendingThresholdModalOpen: true });
  // handleCloseSetAccountSpendingThresholdModal = () => this.setState({ isSetAccountSpendingThresholdModalOpen: false });

  // handleSpendingThresholdSet = () => {
  //   this.setState({ isSetAccountSpendingThresholdModalOpen: false });
  //   this.props.refetch();
  // };

  handleServicePreferenceCreated = async (servicePreferenceUuid: string) => {
    await this.props.refetch();
    this.props.history.push(`/settings/maintenance/service-providers/${servicePreferenceUuid}`);
  };

  render() {
    if (this.props.isLoading) {
      return (
        <ManageStage>
          <Card>
            <Card.CardSection>
              <GenericLoadingVisual />
            </Card.CardSection>
          </Card>
        </ManageStage>
      );
    }

    // const spendingThreshold = this.props.maintenanceSetting?.spendingThreshold;

    // let perIssueThreshold: string;
    // switch (spendingThreshold?.issueThresholdType) {
    //   case SpendingThresholdTypes.CENTS:
    //     perIssueThreshold = `$${(spendingThreshold.issueThresholdAmount ?? 0) / 100}`;
    //     break;
    //   case SpendingThresholdTypes.PERCENTAGE_OF_ANNUAL_RENT:
    //     perIssueThreshold = `${spendingThreshold.issueThresholdAmount}% rent`;
    //     break;
    //   default:
    //     perIssueThreshold = 'Disabled';
    // }

    // let annualThreshold: string;
    // switch (spendingThreshold?.annualThresholdType) {
    //   case SpendingThresholdTypes.CENTS:
    //     annualThreshold = `$${(spendingThreshold.annualThresholdAmount ?? 0) / 100}`;
    //     break;
    //   case SpendingThresholdTypes.PERCENTAGE_OF_ANNUAL_RENT:
    //     annualThreshold = `${spendingThreshold.annualThresholdAmount}% rent`;
    //     break;
    //   default:
    //     annualThreshold = 'Disabled';
    // }

    return (
      <ManageStage>
        <Breadcrumbs
          items={[
            { path: `/settings`, label: 'Settings' },
            { path: `/settings/maintenance`, label: `Maintenance settings` },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
        {/* 
        Spending limits are disabled for now (SV, 2024-6-6)
        <Card header={{
          title: 'Set spending limits',
          subtitle: 'Decide which maintenance expenses require your approval',
          actions: [{
            isLoading: this.props.isLoading,
            label: 'Set spending limits',
            onClick: this.handleUpdateSpendingThresholds,
          }],
        }}>
          <Card.CardSection>
            <Row flexBehavior={Row.enums.FlexBehaviors.Default} verticalAlignment={Row.enums.VerticalAlignments.Center}>
              <div>
                <DataRow>
                  <DataRow.DataRowItem
                    label="Maximum cost per work order"
                    description={perIssueThreshold}
                  />
                  <DataRow.DataRowItem
                    label="Maximum annual maintenance costs"
                    description={annualThreshold}
                  />
                </DataRow>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <ArrowLink
                  color={ArrowLink.enums.Colors.BrandAdvancedPlan}
                  label="See our recommendation"
                  link={{
                    path: `${Globals.WEB_CORE_DOMAIN}/questions/recommended-spending-limits-for-maintenance-and-repairs`,
                    shouldOpenNewTab: true,
                  }}
                  size={ArrowLink.enums.Sizes.Medium}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
              </div>
            </Row>
          </Card.CardSection>
        </Card> */}
        <Card header={{
          subtitle: 'Only request work from trusted service providers',
          title: 'Add your trusted providers',
          actions: [{
            icon: Card.enums.ActionIcons.Plus,
            isLoading: this.props.isLoading,
            label: 'Add service provider',
            onClick: this.handleAddServiceProvider,
          }],
        }}>
          <Table>
            <Table.TableHead>
              <Table.TableRow>
                <Table.TableHeader>Name</Table.TableHeader>
                <Table.TableHeader>Service type</Table.TableHeader>
                <Table.TableHeader>Added</Table.TableHeader>
                <Table.TableHeader />
              </Table.TableRow>
            </Table.TableHead>
            <Table.TableBody>
              {this.props.servicePreferences?.length ? (
                <Fragment>
                  {this.props.servicePreferences.map(servicePreference => {
                    return (
                      <Table.TableRow
                        key={servicePreference.uuid}
                        link={{ path: `/settings/maintenance/service-providers/${servicePreference.uuid}` }}>
                        {/* Name */}
                        <Table.TableData>
                          <Text
                            content={servicePreference.serviceProvider.organization.name}
                            overflow={Text.enums.OverflowValues.Ellipsis}
                            shouldWrap={false}
                            size={Text.enums.Sizes.Small}
                          />
                        </Table.TableData>

                        {/* Service type */}
                        <Table.TableData>
                          <Text
                            content={_.capitalize(servicePreference.serviceType)}
                            overflow={Text.enums.OverflowValues.Ellipsis}
                            shouldWrap={false}
                            size={Text.enums.Sizes.Small}
                          />
                        </Table.TableData>

                        {/* Added */}
                        <Table.TableData>
                          <Text
                            content={dayjs(servicePreference.createdAt).fromNow()}
                            overflow={Text.enums.OverflowValues.Ellipsis}
                            shouldWrap={false}
                            size={Text.enums.Sizes.Small}
                          />
                        </Table.TableData>
                      </Table.TableRow>
                    );
                  })}
                </Fragment>
              ) : (
                <Table.TableEmptyState
                  label="Service providers you've added will appear here."
                />
              )}
            </Table.TableBody>
          </Table>
        </Card>
        <CreateServicePreferenceModal
          isOpen={this.state.isCreateServicePreferenceModalOpen}
          onClose={this.handleCloseCreateServicePreferenceModal}
          onServicePreferenceCreated={this.handleServicePreferenceCreated}
        />
        {/* <SetAccountSpendingThresholdModal
          isOpen={this.state.isSetAccountSpendingThresholdModalOpen}
          onClose={this.handleCloseSetAccountSpendingThresholdModal}
          onSpendingThresholdSet={this.handleSpendingThresholdSet}
        /> */}
      </ManageStage>
    );
  }
}

interface QueryProps {
  isLoading: boolean;
  maintenanceSetting: MaintenanceSetting | null;
  servicePreferences: ServicePreference[];
  refetch: () => Promise<any>;
}

interface Response {
  viewer: {
    uuid: string;
    maintenanceSetting: MaintenanceSetting | null;
    servicePreferences: ServicePreference[];
  }
}

export default compose(
  withQuery<RouteComponentProps, Response, {}, QueryProps>(query, {
    props: props => ({
      isLoading: props.loading,
      servicePreferences: props.data?.viewer?.servicePreferences ?? [],
      maintenanceSetting: props.data?.viewer?.maintenanceSetting ?? null,
      refetch: props.refetch,
    }),
  }),
)(MaintenanceSettings);
