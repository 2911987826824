import _ from 'lodash';
import React, { PureComponent } from 'react';

import Button from '~tools/react/components/Button';
import Form, { Input, Select } from '~tools/react/components/Form';
import Heading from '~tools/react/components/Heading';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import OrganizationsTypeahead, { Organization } from '~tools/react/containers/OrganizationsTypeahead';
import { ServiceTypes } from '~tools/types/graphqlSchema';

export interface SubmitData {
  organizationUuid?: string;
  serviceProviderUuid?: string;
  formData: FormData;
}

interface FormData {
  name: string;
  email: string;
  phoneNumber?: string;
  website?: string;
  serviceType: ServiceTypes;
}

interface Props {
  isLoading: boolean;
  onSubmit: (data: SubmitData) => void;
}

interface State {
  email?: string;
  phoneNumber?: string;
  organization: Organization | null;
  website?: string;
  serviceType?: ServiceTypes;
}

class ServiceProviderForm extends PureComponent<Props> {
  state: State = {
    organization: null,
  }

  handleOrganizationBlur = (organizationName: string | null) => {
    if (organizationName && organizationName !== this.state.organization?.name) {
      this.setState({
        organization: {
          ...(this.state.organization ? this.state.organization : {}),
          name: organizationName,
        },
      });
    }
  }

  handleOrganizationChange = (organization: Organization | null) => {
    if (!organization || !('uuid' in organization)) {
      this.setState({
        email: '',
        organization,
        phoneNumber: undefined,
        website: undefined,
        serviceType: undefined,
      });
    } else {
      this.setState({
        email: organization?.email ?? '',
        organization,
        phoneNumber: organization.phoneNumbers.find(pn => pn.type === 'OFFICE')?.phoneNumber ?? undefined,
        website: organization?.website ?? undefined,
        serviceType: organization?.serviceProvider?.serviceType ?? undefined,
      });
    }
  }

  handleSubmit = (formData: FormData) => {
    if (!formData.email && !formData.phoneNumber) {
      throw new Error('Need either a valid email or phone number');
    }

    this.props.onSubmit({
      serviceProviderUuid: this.state.organization && 'serviceProvider' in this.state.organization ?
        this.state.organization.serviceProvider?.uuid :
        undefined,
      organizationUuid: this.state.organization && 'uuid' in this.state.organization ?
        this.state.organization.uuid :
        undefined,
      formData,
    });
  }

  handleUpdateEmail = (email: string) => this.setState({ email })
  handleUpdateWebsite = (website: string) => this.setState({ website })
  handleUpdateServiceType = (serviceType: string) => this.setState({ serviceType });
  handleUpdatePhoneNumber = (phoneNumber: string) => this.setState({ phoneNumber });

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <div>
          <Heading
            content="Service provider information"
            font={Heading.enums.Fonts.Secondary}
            size={Heading.enums.Sizes.XSmall}
          />
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
          <HorizontalRule />
        </div>
        <OrganizationsTypeahead
          isRequired={true}
          label="Company name"
          name="name"
          onBlur={this.handleOrganizationBlur}
          onChange={this.handleOrganizationChange}
          placeholder="Enter the organization’s name"
        />
        <Input
          label="Email"
          labelFormat={Input.enums.LabelFormats.Stacked}
          name="email"
          onChange={this.handleUpdateEmail}
          placeholder="Company contact email..."
          type={Input.enums.Types.Email}
          value={this.state.email}
        />
        <Input
          label="Phone Number"
          labelFormat={Input.enums.LabelFormats.Stacked}
          name="phoneNumber"
          placeholder="(800) 123-4567"
          type={Input.enums.Types.Tel}
          value={this.state.phoneNumber}
          onChange={this.handleUpdatePhoneNumber}
        />
        <Input
          label="Website (optional)"
          labelFormat={Input.enums.LabelFormats.Stacked}
          name="website"
          onChange={this.handleUpdateWebsite}
          placeholder="Company website URL..."
          value={this.state.website}
        />
        <Select
          isRequired={true}
          label="Service type"
          labelFormat={Input.enums.LabelFormats.Stacked}
          name="serviceType"
          onChange={this.handleUpdateServiceType}
          placeholder="Service type..."
          value={this.state.serviceType}
          options={Object.values(ServiceTypes).map(st => ({
            label: _.capitalize(st),
            value: st,
          }))}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
        <Button
          isDisabled={!this.state.organization}
          isLoading={this.props.isLoading}
          label="Submit"
          type={Button.enums.Types.Submit}
          width={Button.enums.Widths.Full}
        />
      </Form>
    );
  }
}

export default ServiceProviderForm;
