import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import AnimatedStepsFlow from '~tools/react/components/AnimatedStepsFlow';
import Button from '~tools/react/components/Button';
import Form from '~tools/react/components/Form';
import TextArea from '~tools/react/components/Form/components/TextArea';
import graphql, { compose } from '~tools/react/graphql';

import { OnUpdateListing } from '~web-manage/lib/common/scenes/ListingSetup/types';

import query from './ListingSetupDescription.gql';

interface Props extends RouteComponentProps {
  description: string | undefined;
  isLoading: boolean;
  onUpdateListing: OnUpdateListing;
}

interface State {
  description: string;
  isDescriptionAssistanceVisible: boolean;
  assistanceText: {
    why: string,
    whatYouLove: string,
    whatIsNotGreat: string,
  };
}

class ListingSetupDescription extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      assistanceText: {
        whatIsNotGreat: '',
        whatYouLove: '',
        why: '',
      },
      description: props.description || '',
      isDescriptionAssistanceVisible: !props.description,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.description !== nextProps.description && nextProps.description) {
      this.setState({ description: nextProps.description });
    }
  }

  handleSubmit = async () => this.props.history.push('photos');

  handleUpdateWhy = val => this.setState({ assistanceText: { ...this.state.assistanceText, why: val } });

  handleUpdateWhatYouLove = val =>
    this.setState({ assistanceText: { ...this.state.assistanceText, whatYouLove: val } })

  handleUpdateDescription = (val) => {
    if (val !== this.props.description) {
      this.props.onUpdateListing({ description: val });
    }
  }

  handleUpdateWhatIsNotGreat = val =>
    this.setState({ assistanceText: { ...this.state.assistanceText, whatIsNotGreat: val } })

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.FormSection columns={Form.FormSection.enums.Columns.One}>
          {!this.state.isDescriptionAssistanceVisible ? (
            <TextArea
              isDisabled={this.props.isLoading}
              isRequired={true}
              label="Description"
              labelFormat={TextArea.enums.LabelFormats.Stacked}
              name="description"
              onBlur={this.handleUpdateDescription}
              placeholder="Write like how you would talk about your place and situation to a friend. Avoid sales-y talk - honesty is what most renters want to hear."
              rowCount={8}
              value={this.state.description}
            />
          ) : (
            <Fragment>
              <TextArea
                isDisabled={this.props.isLoading}
                isRequired={true}
                label="What are you looking for in a tenant?"
                labelFormat={TextArea.enums.LabelFormats.Stacked}
                name="description"
                onBlur={this.updateDescriptionText}
                onChange={this.handleUpdateWhy}
                placeholder="What do you look for in an ideal renter?"
                rowCount={3}
                value={this.state.assistanceText.why}
              />
              {this.state.assistanceText.why ? (
                <TextArea
                  isDisabled={this.props.isLoading}
                  label="What is the best part about this space?"
                  labelFormat={TextArea.enums.LabelFormats.Stacked}
                  name="description"
                  onBlur={this.updateDescriptionText}
                  onChange={this.handleUpdateWhatYouLove}
                  placeholder="What are highlights and standout features?"
                  rowCount={3}
                  value={this.state.assistanceText.whatYouLove}
                />
              ) : null}
              {this.state.assistanceText.whatYouLove ? (
                <TextArea
                  isDisabled={this.props.isLoading}
                  label="What isn't so great about this place?"
                  labelFormat={TextArea.enums.LabelFormats.Stacked}
                  name="description"
                  onBlur={this.updateDescriptionText}
                  onChange={this.handleUpdateWhatIsNotGreat}
                  placeholder="Just be honest, renters love honesty..."
                  rowCount={3}
                  value={this.state.assistanceText.whatIsNotGreat}
                />
              ) : null}
            </Fragment>
          )}
        </Form.FormSection>
        <div>
          <Button
            color={Button.enums.Colors.Gray}
            size={Button.enums.Sizes.Small}
            label={this.state.isDescriptionAssistanceVisible ? 'Add a custom description' : 'Not sure what to say?'}
            onClick={this.toggleAssistance}
            style={Button.enums.Styles.Outline}
          />
        </div>
        <AnimatedStepsFlow.AnimatedStepsFlowStep.AnimatedStepsFlowStepFooter
          primaryAction={{
            label: 'Continue',
            type: Button.enums.Types.Submit,
          }}
          secondaryLink={{
            path: 'availability',
          }}
        />
      </Form>
    );
  }

  getDescriptionText = () => {
    let str = '';
    if (this.state.assistanceText.why) {
      str += `What are you looking for in a tenant?\n${this.state.assistanceText.why}`;
    }
    if (this.state.assistanceText.whatYouLove) {
      if (str !== '') str += '\n\n';
      str += `What do you love about this place?\n${this.state.assistanceText.whatYouLove}`;
    }
    if (this.state.assistanceText.whatIsNotGreat) {
      if (str !== '') str += '\n\n';
      str += `What isn't so great about this place?\n${this.state.assistanceText.whatIsNotGreat}`;
    }
    return str;
  }

  updateDescriptionText = () => {
    const description = this.state.isDescriptionAssistanceVisible ? this.getDescriptionText() : this.state.description;
    if (description !== this.props.description && description) {
      this.props.onUpdateListing({ description });
      this.setState({ description });
    }
  }

  toggleAssistance = () => {
    const description = this.state.isDescriptionAssistanceVisible &&
      this.getDescriptionText() ? this.getDescriptionText() : this.state.description;
    if (description !== this.props.description && description) this.props.onUpdateListing({ description });
    this.setState({
      description,
      isDescriptionAssistanceVisible: !this.state.isDescriptionAssistanceVisible,
    });
  }
}

export default compose(
  withRouter,
  graphql(query, {
    options: props => ({
      variables: {
        listingUuid: props.listingUuid,
      },
    }),
    props: props => ({
      description: _.get(props.data.viewer, 'listing.description'),
      isLoading: props.data.loading,
    }),
  }),
)(ListingSetupDescription);
