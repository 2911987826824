import withMutation from '~tools/react/graphql/withMutation';

import GenerateSmartLockOneTimeCodeMutation from '~tools/react/graphql/mutations/smartLocks/generateSmartLockOneTimeCode.gql';

interface Data {
  generateSmartLockOneTimeCode: string;
}

interface Variables {
  uuid: string;
}

export interface GenerateSmartLockOneTimeCodeProps {
  generateSmartLockOneTimeCode: (uuid: string) => Promise<string>;
}

export default withMutation<{}, Data, Variables, GenerateSmartLockOneTimeCodeProps>(GenerateSmartLockOneTimeCodeMutation, {
  props: props => ({
    generateSmartLockOneTimeCode: async (uuid) => {
      const res = await props.mutate({
        variables: {
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error generating keycode.');

      return res.data.generateSmartLockOneTimeCode;
    },
  }),
});
