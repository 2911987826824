import { CalendarTimeSlotEntityTypes } from '~tools/types/graphqlAdminSchema';
import withMutation from '~tools/react/graphql/withMutation';

import DetachTimeSlotsFromCalendarMutation from './detachTimeSlotsFromCalendar.gql';

interface Calendar {
  uuid: string;
  availableTimeSlots: {
    dayOfWeek: number;
    endsAt: number;
    startsAt: number;
    uuid: number;
  }[];
}

interface DetachTimeSlotsFromCalendarInput {
  daysOfWeek: number[];
  eligibleEntityTypes: CalendarTimeSlotEntityTypes[];
  endingHour: number;
  startingHour: number;
}

interface Data {
  detachTimeSlotsFromCalendar: Calendar;
}

interface Variables {
  uuid: string;
  input: DetachTimeSlotsFromCalendarInput;
}

export { CalendarTimeSlotEntityTypes };

export interface DetachTimeSlotsFromCalendarProps {
  detachTimeSlotsFromCalendar: (uuid: string, input: DetachTimeSlotsFromCalendarInput) => Promise<Calendar>;
}

export default withMutation<{}, Data, Variables, DetachTimeSlotsFromCalendarProps>(DetachTimeSlotsFromCalendarMutation, {
  props: props => ({
    detachTimeSlotsFromCalendar: async (uuid, input) => {
      const res = await props.mutate({
        variables: {
          uuid,
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error detaching viewing slots from listing.');

      return res.data.detachTimeSlotsFromCalendar;
    },
  }),
});
