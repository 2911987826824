// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._krPYxG{margin-bottom:6px;margin-right:6px;position:relative;-webkit-transition:all .3s cubic-bezier(.175,.885,.32,1.275);transition:all .3s cubic-bezier(.175,.885,.32,1.275)}._krPYxG:hover{-webkit-transform:translateY(-1px);transform:translateY(-1px)}._krPYxG:hover ._5aAFKQ{box-shadow:0 1px 6px 0 rgba(0,0,0,.04),0 6px 16px 0 rgba(24,32,41,.06)}._krPYxG ._5aAFKQ{background:#fff;background-repeat:no-repeat;background-size:cover;border:none;border-radius:6px;box-shadow:0 0 0 0 rgba(24,32,41,0);height:120px;margin:0;-webkit-transition:all .2s cubic-bezier(.215,.61,.355,1);transition:all .2s cubic-bezier(.215,.61,.355,1)}", ""]);
// Exports
exports.locals = {
	"thread-item-photo-attachment": "_krPYxG",
	"thread-item-photo-attachment__photo": "_5aAFKQ"
};
module.exports = exports;
