import withMutation from '~tools/react/graphql/withMutation';

import CreateOrganizationPersonMutation from './createOrganizationPerson.gql';

interface OrganizationPerson {
  organization: {
    uuid: string;
  };
  person: {
    uuid: string;
  };
  title: string | null;
  uuid: string;
  workAddressUnit: {
    uuid: string;
  } | null;
}

interface CreateOrganizationPersonInput {
  organizationUuid: string;
  personUuid: string;
  title?: string;
  workAddressUnitUuid?: string;
}

interface Data {
  createOrganizationPerson: OrganizationPerson;
}

interface Variables {
  input: CreateOrganizationPersonInput;
}

export interface CreateOrganizationPersonProps {
  createOrganizationPerson: (input: CreateOrganizationPersonInput) => Promise<OrganizationPerson>;
}

export default withMutation<{}, Data, Variables, CreateOrganizationPersonProps>(CreateOrganizationPersonMutation, {
  props: props => ({
    createOrganizationPerson: async (input) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating organization person.');

      return res.data.createOrganizationPerson;
    }
  }),
});
