import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';
import Avatar from '~tools/react/components/Avatar';
import Text from '~tools/react/components/Text';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';

import styles from './AuthorHeader.scss';

interface Props {
  name: string;
  photoUrl: string;
}

function AuthorHeader(props: Props) {
  return (
    <div className={styles['author-header']}>
      <Avatar
        imageUrl={props.photoUrl}
        size={Avatar.enums.Sizes.Small}
      />
      <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
      <Text content={`**${props.name}** is the author of this inspection.`} isMarkdown />
    </div>
  );
}

export default withStyles(styles)(AuthorHeader);
