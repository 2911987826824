import { capitalize } from 'lodash';
import React, { ComponentType, Fragment, PureComponent } from 'react';

import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Tag from '~tools/react/components/Tag';
import Text from '~tools/react/components/Text';
import withStyles from '~tools/react/hocs/withStyles';
import { StripePaymentMethodTypes } from '~tools/types/graphqlSchema';

import MiniChargeCardVisual from '~web-manage/lib/common/components/MiniChargeCardVisual';

import * as enums from './enums';

import styles from './InlinePaymentMethodVisual.scss';

interface SharedProps {
  isDefault?: boolean;
}

interface UsBankAccountProps extends SharedProps {
  usBankAccount: {
    bankName: string;
    accountType?: string;
    last4: string;
  };
  type: enums.Types.US_BANK_ACCOUNT;
}

interface CardProps extends SharedProps {
  card: {
    brand: enums.Brands;
    last4: string;
    expMonth: number;
    expYear: number;
  };
  type: enums.Types.CARD;
}

type Props = UsBankAccountProps | CardProps;

class InlinePaymentMethodVisual extends PureComponent<Props> {
  static enums = enums;

  render() {
    return (
      <div styleName="inline-payment-method">
        {this.props.type === StripePaymentMethodTypes.US_BANK_ACCOUNT ? (
          <Fragment>
            <MiniChargeCardVisual brand={MiniChargeCardVisual.enums.Brands.Bank} />
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
            <Text
              content={this.props.usBankAccount.bankName}
              isMarkdown
              shouldWrap={false}
              size={Text.enums.Sizes.Small}
            />
            {this.props.usBankAccount.accountType ? (
              <Fragment>
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                <Text
                  content={`*${capitalize(this.props.usBankAccount.accountType)}*`}
                  isMarkdown
                  shouldWrap={false}
                  size={Text.enums.Sizes.Small}
                />
              </Fragment>
            ) : null}
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
            <Text
              content={`•••• ${this.props.usBankAccount.last4}`}
              font={Text.enums.Fonts.Monospace}
              shouldWrap={false}
              size={Text.enums.Sizes.Small}
            />
          </Fragment>
        ) : null}
        {this.props.type === StripePaymentMethodTypes.CARD ? (
          <Fragment>
            <MiniChargeCardVisual brand={this.props.card.brand as enums.Brands} />
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
            <Text
              content="••••"
              size={Text.enums.Sizes.XSmall}
            />
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXSmall} />
            <Text
              content="••••"
              size={Text.enums.Sizes.XSmall}
            />
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXSmall} />
            <Text
              content="••••"
              size={Text.enums.Sizes.XSmall}
            />
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXSmall} />
            <Text
              content={this.props.card.last4}
              font={Text.enums.Fonts.Monospace}
              size={Text.enums.Sizes.Medium}
            />
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Medium} />
            <Text
              content="Exp."
              size={Text.enums.Sizes.Medium}
            />
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
            <Text
              content={`${this.props.card.expMonth < 10 ? '0' : ''}${this.props.card.expMonth}/${this.props.card.expYear
                .toString()
                .slice(-2)}`}
              font={Text.enums.Fonts.Monospace}
              size={Text.enums.Sizes.Medium}
            />
          </Fragment>
        ) : null}
        {this.props.isDefault ? (
          <Fragment>
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
            <Tag
              label="Default"
              size={Tag.enums.Sizes.Small}
              color={Tag.enums.Colors.Gray}
            />
          </Fragment>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(
  InlinePaymentMethodVisual as unknown as ComponentType<SharedProps>,
) as unknown as ComponentType<Props>;
