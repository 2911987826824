import _ from 'lodash';
import classNames from 'classnames';
import React, { ChangeEvent, Component, DragEvent, Fragment } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import ArrowLink from '~tools/react/components/ArrowLink';
import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import Text from '~tools/react/components/Text';

import PlusCircleSVG from '~tools/svgs/icons/interface/plus-circle.svg';
import DocumentSVG from '~tools/svgs/icons/interface/document.svg';

import * as enums from './enums';

import styles from './DropzoneForm.scss';

interface Props {
  file?: {
    name: string;
    uri: string;
  };
  isInvalid?: boolean;
  label?: string;
  labelFormat?: enums.LabelFormats;
  onClear: () => void;
  onDrop: (file: File, dataURI?: string) => void;
  placeholder: string;
  placeholderIcon?: enums.Icons;
  shouldAllowMultipleFiles?: boolean;
  shouldRenderFileManager?: boolean;
  supportedFileTypes: enums.FileTypes[];
  unsupportedTypeMessage: string;
  uploadProgress: number;
}

interface State {
  foundUnsupportedType: boolean;
  isDragActive: boolean;
}

const iconToComponent = {
  [enums.Icons.PlusCircle]: PlusCircleSVG,
};

class DropzoneForm extends Component<Props, State> {
  static enums = enums;
  static defaultProps = {
    labelFormat: enums.LabelFormats.Stacked,
    placeholder: 'Drag & drop files here or click here to browse for files',
    shouldAllowMultipleFiles: true,
    unsupportedTypeMessage: 'That file type is not supported.',
  };

  state: State = {
    foundUnsupportedType: false,
    isDragActive: false,
  };
  inputRef: HTMLInputElement | null = null;

  componentDidMount() {
    document.addEventListener('paste', this.handlePaste, false);
  }

  componentWillUnmount() {
    document.removeEventListener('paste', this.handlePaste);
  }

  handleDragLeave = () => this.setState({ isDragActive: false });
  handleDragOver = (e: DragEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';

    this.setState({ isDragActive: true });
  }

  handleDropFile = (e: DragEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.handleReadFiles(e.dataTransfer.files);
  }
  handleSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    this.handleReadFiles(e.target.files);
  }
  handleReadFiles = (files: FileList | null) => {
    this.setState({ isDragActive: false });

    let foundUnsupportedType = false;
    const propsFileTypes = this.props.supportedFileTypes || [];
    _.each(files, (item) => {
      if (
        _.values(enums.FileTypes).indexOf(item.type as enums.FileTypes) !== -1 ||
        propsFileTypes.indexOf(item.type as enums.FileTypes) !== -1
      ) {
        this.createPreview(item);
      } else {
        foundUnsupportedType = true;
      }
    });
    this.setState({ foundUnsupportedType });
  }

  handleClick = () => {
    const inputRef = this.inputRef;
    if (inputRef) inputRef.click();
  }

  handlePaste = (e: ClipboardEvent) => {
    const hasFileInClipboard = (clipboard: DataTransfer | null) => {
      if (!clipboard) return false;

      const items = clipboard.items;
      return _.find(items, (item) => {
        const blob = item.getAsFile();
        if (
          blob && (
            _.values(enums.FileTypes).indexOf(blob.type as enums.FileTypes) !== -1 ||
            this.props.supportedFileTypes.indexOf(blob.type as enums.FileTypes) !== -1
          )
        ) {
          return true;
        }

        return false;
      });
    };

    // Only prevent default if it looks like the user is pasting a file
    if (!hasFileInClipboard(e.clipboardData)) return;

    e.preventDefault();
    if (!e.clipboardData) return;
    const items = e.clipboardData.items;
    _.forEach(items, (item) => {
      const blob = item.getAsFile();
      if (
        blob && (
          _.values(enums.FileTypes).indexOf(blob.type as enums.FileTypes) !== -1 ||
          this.props.supportedFileTypes.indexOf(blob.type as enums.FileTypes) !== -1
        )
      ) {
        this.createPreview(blob);
      }
    });
  }

  render() {
    const file = this.props.file;
    const shouldRenderFileManager = this.props.shouldRenderFileManager;
    const IconElm = _.get(iconToComponent, this.props.placeholderIcon || '', null);

    return (
      <Fragment>
        {file && shouldRenderFileManager ? (
          <div
            className={classNames({
              [styles.field]: true,
              [styles[`field--label-format-${_.kebabCase(this.props.labelFormat)}`]]: this.props.labelFormat,
            })}>
            {this.props.label ? (
              <div styleName="field__labels">
                <label styleName="field__label">
                  {this.props.label}
                </label>
              </div>
            ) : null}
            <div styleName="field__dropzone">
              <Card
                hasMargin={false}
                shadow={Card.enums.Shadows.Small}>
                <div styleName="file">
                  <DocumentSVG styleName="file__svg" />
                  <div styleName="file__preview">
                    <Text
                      altText={file.name}
                      color={Text.enums.Colors.Primary}
                      content={file.name}
                      isEmphasized
                      overflow={Text.enums.OverflowValues.Ellipsis}
                      size={Text.enums.Sizes.Medium}
                    />
                    <ArrowLink
                      label="Preview uploaded file"
                      link={{
                        path: file.uri,
                        shouldOpenNewTab: true,
                      }}
                      size={ArrowLink.enums.Sizes.Small}
                    />
                  </div>
                  <Button
                    align={Button.enums.Alignments.Right}
                    color={Button.enums.Colors.Red}
                    icon={Button.enums.Icons.Cross}
                    onClick={this.props.onClear}
                    size={Button.enums.Sizes.Large}
                    style={Button.enums.Styles.Secondary}
                  />
                </div>
              </Card>
            </div>
          </div>
        ) : (
          <div
            className={classNames({
              [styles.field]: true,
              [styles[`field--label-format-${_.kebabCase(this.props.labelFormat)}`]]: this.props.labelFormat,
            })}>
            {this.props.label ? (
              <div styleName="field__labels">
                <label styleName="field__label">
                  {this.props.label}
                </label>
              </div>
            ) : null}
            <button
              className={classNames({
                [styles.dropzone]: true,
                [styles['dropzone--active']]: this.state.isDragActive,
                [styles['dropzone--invalid']]: this.props.isInvalid || this.state.foundUnsupportedType,
              })}
              onClick={this.handleClick}
              onDragLeave={this.handleDragLeave}
              onDragOver={this.handleDragOver}
              onDrop={this.handleDropFile}
              type={Button.enums.Types.Button}>
              <input
                accept={this.props.supportedFileTypes.join(', ')}
                multiple={this.props.shouldAllowMultipleFiles}
                onChange={this.handleSelectFile}
                ref={this.assignRef}
                style={{ display: 'none' }}
                type="file"
              />
              {this.props.uploadProgress < 100 ? (
                <Fragment>
                  <div styleName="placeholder">
                    {IconElm ? <IconElm styleName="placeholder__icon" /> : null}
                    <div styleName="placeholder__text">{this.props.placeholder}</div>
                  </div>
                  <div
                    onDragLeave={this.handleDragLeave}
                    styleName="dropzone__hover-overlay">
                    Drop here to upload
                  </div>
                  <div
                    style={{ height: `${this.props.uploadProgress}%` }}
                    styleName="dropzone__progress-bar"
                  />
                </Fragment>
              ) : null}
              {this.state.foundUnsupportedType ? (
                <div styleName="dropzone__error">
                  {this.props.unsupportedTypeMessage}
                </div>
              ) : null}
            </button>
          </div>
        )}
      </Fragment>
    );
  }

  assignRef = (ref: HTMLInputElement) => { this.inputRef = ref; };

  createPreview = (file: File) => {
    const reader = new FileReader();

    reader.onloadend = (e: ProgressEvent<FileReader>) => { // eslint-disable-line
      if (!reader.result) return;
      if (this.props.onDrop && typeof reader.result === 'string') this.props.onDrop(file, reader.result);
    };

    reader.readAsDataURL(file);
  }
}

export default withStyles(styles)(DropzoneForm);
