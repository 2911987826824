import React, { Component, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

import withStyles from '~tools/react/hocs/withStyles';
import Button from '~tools/react/components/Button';

import * as enums from '~tools/react/components/PhotoEditorModal/enums';

import styles from './PhotoEditorModalFilterView.scss';

import Brightness from '../../svgs/brightness.svg';
import Contrast from '../../svgs/contrast.svg';
import Flip from '../../svgs/flip.svg';
import Rotate from '../../svgs/rotate.svg';
import Saturation from '../../svgs/saturation.svg';
import Tag from '../../svgs/tag.svg';
import Shadow from '../../svgs/shadow.svg';
import Highlight from '../../svgs/highlight.svg';

interface Props {
  children: ReactNode;
  filter: enums.Filters;
  isActive: boolean;
  isEdited?: boolean;
  onClickTab: (tab: enums.Filters) => void;
  primaryAction: () => void;
  secondaryAction?: () => void;
}

interface State {
  isActive: boolean;
}

const filtersToIcons = {
  [enums.Filters.Brightness]: <Brightness />,
  [enums.Filters.Contrast]: <Contrast />,
  [enums.Filters.Flip]: <Flip />,
  [enums.Filters.Rotate]: <Rotate />,
  [enums.Filters.Saturation]: <Saturation />,
  [enums.Filters.Tag]: <Tag />,
  [enums.Filters.Highlight]: <Highlight />,
  [enums.Filters.Shadow]: <Shadow />,
};

const filtersToCopy = {
  [enums.Filters.Brightness]: 'Brightness',
  [enums.Filters.Contrast]: 'Contrast',
  [enums.Filters.Flip]: 'Flip',
  [enums.Filters.Rotate]: 'Rotate',
  [enums.Filters.Saturation]: 'Saturation',
  [enums.Filters.Highlight]: 'Highlights',
  [enums.Filters.Shadow]: 'Shadows',
  [enums.Filters.Tag]: 'Edit Tag',
};

class PhotoEditorModalFilterView extends Component<Props, State> {
  timeout: NodeJS.Timeout | null = null;

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isActive !== this.props.isActive) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this.setState({ isActive: nextProps.isActive }), 150);
    }
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  handleClick = () => this.props.onClickTab(this.props.filter);

  render() {
    const portalElm = document.getElementById('filterView');
    if (this.state.isActive && portalElm) {
      return ReactDOM.createPortal(
        <div styleName="filter-view">
          <div styleName="filter-view__wrapper">
            <h2>{filtersToCopy[this.props.filter]}</h2>
            {filtersToIcons[this.props.filter]}
          </div>
          {this.props.children}
          <div styleName="filter-view__btns">
            <Button onClick={this.props.primaryAction} width={Button.enums.Widths.Full} label="Done" />
            {this.props.isEdited ?
              <Button
                onClick={this.props.secondaryAction}
                color={Button.enums.Colors.White}
                label="Reset filter"
                style={Button.enums.Styles.Outline}
                width={Button.enums.Widths.Full}
              /> : null}
          </div>
        </div>,
        portalElm,
      );
    }
    return (
      <button onClick={this.handleClick} styleName={classNames({ 'section-tab': true, 'section-tab--altered': this.props.isEdited })}>
        <span>{filtersToIcons[this.props.filter]}</span>
        {filtersToCopy[this.props.filter]}
      </button>
    );
  }
}

export default withStyles(styles)(PhotoEditorModalFilterView);
