import React, { Component } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import withStyles from '~tools/react/hocs/withStyles';

import { Photo } from '../../types';
import PhotoEditorModalOptionsBar from '../PhotoEditorModalOptionsBar';
import styles from './PhotoEditorModalImage.scss';

interface Props {
  onClickDelete: () => void;
  onClose: () => void;
  previewImageUrl: string;
  query: string;
  selectedPhoto: Photo | null;
  selectedPhotoTag?: { name: string } | null;
  updateQuery: (query: string, cb: () => void) => void;
}

interface State {
  isLoading: boolean;
}

class PhotoEditorModalImage extends Component<Props, State> {
  ref: HTMLImageElement | null = null;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    if (!this.ref) return;
    this.setState({ isLoading: !!this.ref && !this.ref?.complete });
  }

  componentDidUpdate(lastProps) {
    if (
      this.props.previewImageUrl !== lastProps.previewImageUrl ||
      (!lastProps.previewImageUrl && !this.props.previewImageUrl)
    ) {
      this.setState({ isLoading: !!this.ref && !this.ref.complete });
    }
  }

  handleLoad = () => this.setState({ isLoading: false });

  render() {
    const height = _.get(this.props.selectedPhoto, 'height', 0);
    const width = _.get(this.props.selectedPhoto, 'width', 0);
    return (
      <div styleName="modal-image">
        <div styleName="modal-image__top">
          <h2>{this.props.selectedPhotoTag ? _.upperFirst(_.lowerCase(this.props.selectedPhotoTag.name)) : 'Photo'}</h2>
          <PhotoEditorModalOptionsBar
            onClickDelete={this.props.onClickDelete}
            onClose={this.props.onClose}
            updateQuery={this.props.updateQuery}
            query={this.props.query}
          />
        </div>
        <div
          styleName={classNames({
            'modal-image__wrapper': true,
            'modal-image__wrapper--loading': this.state.isLoading,
          })}>
          {this.state.isLoading ? (
            <svg width="100px" height="100px" viewBox="0 0 100 100" styleName="modal-image__wrapper__loader">
              <circle strokeLinecap="round" cx="50" cy="50" r="45" />
            </svg>
          ) : null}
          <img
            onLoad={this.handleLoad}
            alt=""
            ref={this.assignRef}
            style={{ objectFit: height > width ? 'contain' : 'cover' }}
            src={this.props.previewImageUrl}
          />
        </div>
      </div>
    );
  }

  assignRef = (c) => {
    if (c) {
      this.ref = c;
    }
  };
}

export default withStyles(styles)(PhotoEditorModalImage);
