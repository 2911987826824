import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Globals from '~web-core/lib/common/globals';

import LogoSvg from '~web-core/lib/common/svgs/brand/flip-logo.svg';

import Link from '~tools/react/components/utility/Link';

import styles from './Logo.scss';

function Logo() {
  return (
    <div styleName="logo">
      <Link
        styleName="logo__svg"
        to={Globals.WEB_CORE_DOMAIN}>
        <LogoSvg />
      </Link>
    </div>
  );
}

export default withStyles(styles)(Logo);
