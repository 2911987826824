import _ from 'lodash';
import cookies from 'js-cookie';
import React from 'react';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, concat } from 'apollo-link';
import { ApolloProvider } from 'react-apollo';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { toIdValue } from 'apollo-utilities';

import Globals from '~web-core/lib/common/globals';

/* eslint-disable no-param-reassign */
export function decodeEntities(result, value, key) {
  if (!value) {
    result[key] = value;
    return;
  } else if (typeof value === 'string') {
    result[key] = _.unescape(value);
    return;
  } else if (typeof value === 'object') {
    result[key] = _.transform(value, decodeEntities);
    return;
  }

  result[key] = value;
}
/* eslint-enable no-param-reassign */

const logoutLink = onError((props) => {
  if (props.networkError && 'statusCode' in props.networkError && props.networkError.statusCode === 498) {
    cookies.remove('token', {
      path: '/',
      domain: Globals.COOKIE_DOMAIN,
    });
  }
});

const httpLink = new HttpLink({
  uri: `${Globals.API_CORE_DOMAIN}/graphql`,
  credentials: 'include',
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = cookies.get('token', '/');
  if (token) {
    operation.setContext(() => ({
      headers: {
        'x-flip-token': token,
      },
    }));
  }
  return forward(operation);
});

function dataIdFromObject(object) {
  if (object.__typename && object.uuid) return `${object.__typename}:${object.uuid}`; // eslint-disable-line no-underscore-dangle
  if (object.__typename && object.id) return `${object.__typename}:${object.id}`; // eslint-disable-line no-underscore-dangle
  return '';
}

(window as any).APOLLO_STATE = _.transform((window as any).APOLLO_STATE, decodeEntities);
const client = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject,
    cacheRedirects: {
      User: {
        flip: (root, args) => toIdValue(dataIdFromObject({ __typename: 'Flip', uuid: args.uuid })),
        listing: (root, args) => toIdValue(dataIdFromObject({ __typename: 'PrivateListing', uuid: args.uuid })),
      },
      ContentQuestion: {
        contentAnswer: (root, args) => toIdValue(dataIdFromObject({ __typename: 'ContentAnswer', uuid: args.uuid })),
        contentAnswers: (root, args, { getCacheKey }) => args.ids.map(id => getCacheKey({ __typename: 'ContentAnswer', id })),
      },
    },
  }).restore((window as any).APOLLO_STATE),
  link: concat(authMiddleware, logoutLink.concat(httpLink)),
});

export default function Apollo(props) {
  return (
    <ApolloProvider {...props} client={client} />
  );
}
