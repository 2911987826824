import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { ListingStatuses } from '~tools/types/graphqlSchema';

import { extractMessageFromError } from '~tools/utils/error';
import { compose } from '~tools/react/hocs/utils';

import withQuery from '~tools/react/graphql/withQuery';
import withUnlistListing, { UnlistListingProps } from '~tools/react/graphql/mutations/listings/withUnlistListing';
import withListListing, { ListListingProps, ListListingStatusEnum } from '~tools/react/graphql/mutations/listings/withListListing';

import Alert from '~tools/react/components/Alert';
import AnimatedStepsFlow from '~tools/react/components/AnimatedStepsFlow';
import Button from '~tools/react/components/Button';
// import Checkbox from '~tools/react/components/Form/components/Checkbox';
import DatePicker from '~tools/react/components/DatePicker';
import Form from '~tools/react/components/Form';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Input from '~tools/react/components/Form/components/Input';
import Select from '~tools/react/components/Form/components/Select';

import { OnUpdateListing } from '~web-manage/lib/common/scenes/ListingSetup/types';

import query from './ListingSetupAvailability.gql';

interface InputProps extends RouteComponentProps<{ listingUuid: string }> {
  isCompleted: boolean;
  onUpdateListing: OnUpdateListing;
}

type Props =
  InputProps &
  QueryProps &
  ListListingProps &
  UnlistListingProps;

interface State {
  isSettingMinMax: boolean;
  error: string | null;
}

class ListingSetupAvailability extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
      isSettingMinMax: props.listing?.minLeaseTerm !== props.listing?.maxLeaseTerm,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.listing?.maxLeaseTerm !== this.props.listing?.maxLeaseTerm ||
      nextProps.listing?.minLeaseTerm !== this.props.listing?.minLeaseTerm
    ) {
      this.setState({
        isSettingMinMax: nextProps.listing?.minLeaseTerm !== nextProps.listing?.maxLeaseTerm,
      });
    }
  }

  handleStatusChange = async () => {
    this.setState({ error: null });
    try {
      if (this.props.listing?.status === ListingStatuses.UNLISTED) {
        await this.props.listListing(this.props.match.params.listingUuid, { status: ListListingStatusEnum.PUBLIC });
      } else {
        await this.props.unlistListing(this.props.match.params.listingUuid);
      }
    } catch (e) {
      this.setState({ error: extractMessageFromError(e) });
    }
  }

  handleLeaseExtendableChange = async () => {
    this.setState({ error: null });
    try {
      await this.props.onUpdateListing({
        isLeaseExtendableWithLandlord: !this.props.listing?.isLeaseExtendableWithLandlord,
      });
    } catch (e) {
      this.setState({ error: extractMessageFromError(e) });
    }
  }

  handleSubmit = () => this.props.history.push('description');

  handleSetMinMax = () => this.setState({ isSettingMinMax: !this.state.isSettingMinMax });

  render() {
    const listing = this.props.listing;
    if (!listing) return null;

    const monthItems = _.times(24, x => ({ value: x + 1, label: `${x + 1} month${x !== 0 ? 's' : ''}` }));
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.FormSection columns={Form.FormSection.enums.Columns.One}>
          <DatePicker
            isRequired={true}
            label="Earliest move in date"
            labelFormat={Input.enums.LabelFormats.Stacked}
            name="earliestMoveInDate"
            onChange={this.updateEarliestMoveInDate}
            placeholder="Select a date"
            timezone={listing.addressUnit.address.timezone}
            value={listing.earliestMoveInDate ? moment(listing.earliestMoveInDate) : undefined}
          />
        </Form.FormSection>
        <Form.FormSection
          columns={
            this.state.isSettingMinMax ? Form.FormSection.enums.Columns.Two : Form.FormSection.enums.Columns.One
          }>
          {this.state.isSettingMinMax ? (
            <Fragment>
              <Select
                isRequired={true}
                options={monthItems}
                label="Minimum duration in months"
                labelFormat={Input.enums.LabelFormats.Stacked}
                name="minDuration"
                onChange={this.updateMinDuration}
                placeholder="Select duration"
                value={listing.minLeaseTerm || undefined}
              />
              <Select
                isRequired={true}
                options={monthItems}
                label="Maximum duration in months"
                labelFormat={Input.enums.LabelFormats.Stacked}
                name="maxDuration"
                onChange={this.updateMaxDuration}
                placeholder="Select duration"
                value={listing.maxLeaseTerm || undefined}
              />
            </Fragment>
          ) : (
            <Select
              isRequired={true}
              options={monthItems}
              label="Duration in months"
              labelFormat={Input.enums.LabelFormats.Stacked}
              name="duration"
              onChange={this.updateDuration}
              placeholder="Select duration"
              value={listing.minLeaseTerm || undefined}
            />
          )}
        </Form.FormSection>
        <div>
          <Button
            color={Button.enums.Colors.Gray}
            size={Button.enums.Sizes.Small}
            label={this.state.isSettingMinMax ? 'Set single duration' : 'Set a min and max duration'}
            onClick={this.handleSetMinMax}
            style={Button.enums.Styles.Outline}
          />
        </div>
        <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Medium} />
        {/* <Checkbox
          isChecked={listing.isLeaseExtendableWithLandlord}
          label="When this lease ends, will it be possible to sign a new lease directly with the landlord?"
          onChange={this.handleLeaseExtendableChange}
        /> */}
        {this.state.error ? <Alert description={this.state.error} color={Alert.enums.Colors.Red} /> : null}
        <AnimatedStepsFlow.AnimatedStepsFlowStep.AnimatedStepsFlowStepFooter
          primaryAction={{
            label: 'Continue',
            type: Button.enums.Types.Submit,
          }}
          secondaryLink={{
            path: 'payments',
          }}
        />
      </Form>
    );
  }

  updateEarliestMoveInDate = (data: Moment) => {
    const listing = this.props.listing;
    if (!listing) return;

    if (!listing.earliestMoveInDate || !data.isSame(listing.earliestMoveInDate)) {
      const earliestMoveInDate = data.toDate();
      let leaseEndDate: Date | undefined;
      if (listing.minLeaseTerm) {
        leaseEndDate = data.clone().add(listing.minLeaseTerm, 'months').toDate();
      }
      this.props.onUpdateListing({
        earliestMoveInDate: earliestMoveInDate.toISOString(),
        leaseEndDate: leaseEndDate?.toISOString(),
      });
    }
  }

  updateDuration = (data) => {
    const listing = this.props.listing;
    if (!listing) return;

    this.props.onUpdateListing({
      leaseEndDate: listing.earliestMoveInDate ? moment(listing.earliestMoveInDate).add(_.parseInt(data), 'months').toISOString() : undefined,
      maxLeaseTerm: _.parseInt(data),
      minLeaseTerm: _.parseInt(data),
    });
  }

  updateMinDuration = (data) => {
    const listing = this.props.listing;
    if (!listing) return;

    this.props.onUpdateListing({
      leaseEndDate: listing.earliestMoveInDate ?
        moment(listing.earliestMoveInDate).add(Math.max(listing.maxLeaseTerm || 0, _.parseInt(data)), 'months').toISOString() : undefined,
      maxLeaseTerm: Math.max(listing.maxLeaseTerm || 0, _.parseInt(data)),
      minLeaseTerm: _.parseInt(data),
    });
  }

  updateMaxDuration = (data) => {
    const listing = this.props.listing;
    if (!listing) return;

    this.props.onUpdateListing({
      leaseEndDate: listing.earliestMoveInDate ? moment(listing.earliestMoveInDate).add(_.parseInt(data), 'months').toISOString() : undefined,
      maxLeaseTerm: _.parseInt(data),
      minLeaseTerm: Math.min(listing.minLeaseTerm || 0, _.parseInt(data)) || undefined,
    });
  }
}

interface Listing {
  addressUnit: {
    address: {
      timezone: string;
      uuid: string;
    };
    uuid: string;
  };
  earliestMoveInDate: string | null;
  isLeaseExtendableWithLandlord: boolean;
  maxLeaseTerm: number | null;
  minLeaseTerm: number | null;
  status: ListingStatuses | null;
}

interface Response {
  viewer: {
    listing: Listing | null;
    uuid: string;
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  listing: Listing | null;
}

interface Variables {
  listingUuid: string;
}

export default compose(
  withRouter,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: props => ({
      variables: {
        listingUuid: props.match.params.listingUuid,
      },
      skip: !props.match.params.listingUuid,
      ssr: false,
    }),
    props: props => ({
      isLoading: props.loading,
      listing: props.data?.viewer?.listing || null,
    }),
  }),
  withListListing,
  withUnlistListing,
)(ListingSetupAvailability);
