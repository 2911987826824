import withMutation from '~tools/react/graphql/withMutation';

import CreateOrganizationMutation from '../createOrganization.gql';

interface Organization {
  email: string | null;
  name: string;
  summary: string | null;
  uuid: string;
  website: string | null;
  organizationPersons: {
    uuid: string;
    person: {
      email: string | null;
      firstName: string;
      lastName: string | null;
      uuid: string;
    };
  }[];
  phoneNumbers: {
    phoneNumber: string;
    type: string | null;
    uuid: string;
  }[];
}

interface CreateOrganizationInput {
  email?: string;
  name: string;
  summary?: string;
  website?: string;
}

interface Data {
  createOrganization: Organization;
}

interface Variables {
  input: CreateOrganizationInput;
}

export interface CreateOrganizationProps {
  createOrganization: (input: CreateOrganizationInput) => Promise<Organization>;
}

export default withMutation<{}, Data, Variables, CreateOrganizationProps>(CreateOrganizationMutation, {
  props: props => ({
    createOrganization: async (input) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating organization.');

      return res.data.createOrganization;
    }
  }),
});
