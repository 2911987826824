import React, { ChangeEvent, Component, MouseEvent } from 'react';
import _ from 'lodash';

import withStyles from '~tools/react/hocs/withStyles';

import Button from '~tools/react/components/Button';

import * as enums from './enums';

import styles from './UploadButton.scss';

const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/tiff'];
const MIN_WIDTH = 600;
const MIN_HEIGHT = 400;

interface Props {
  color?: enums.Colors;
  label: string;
  onClick?: (e: MouseEvent<EventTarget>) => void;
  onError: (args: { description: string; title: string; }) => void;
  onSubmit: (args: { imageUrl: string, file: File, height: number, width: number }) => void;
  style?: enums.Styles;
  width?: enums.Widths;
}

class UploadButton extends Component<Props> {
  static enums = enums;

  fileInputRef: HTMLInputElement | null = null;

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const target = e.target;
    if (!target || !target.files) return;

    _.each(target.files, this.submitFile);
  };

  handleClick = (e: MouseEvent<EventTarget>) => {
    if (!this.fileInputRef) return;

    this.fileInputRef.click();
    if (this.props.onClick) this.props.onClick(e);
  }

  render() {
    return (
      <label htmlFor="photo" styleName="photos-upload-button">
        <Button
          color={this.props.color}
          label={this.props.label}
          onClick={this.handleClick}
          style={this.props.style}
          type={Button.enums.Types.Button}
          width={this.props.width}
        />
        <input
          multiple
          name="photo"
          ref={this.bindRef}
          onChange={this.handleChange}
          type="file"
          accept={ACCEPTED_FILE_TYPES.join(', ')}
        />
      </label>
    );
  }

  bindRef = (e: HTMLInputElement) => {
    this.fileInputRef = e;
  }

  submitFile = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      const target = e.target;
      if (!target || !target.result || typeof target.result !== 'string') return;

      const image = new Image();
      image.src = target.result;
      image.onload = () => {
        if (image.height < MIN_HEIGHT || image.width < MIN_WIDTH) {
          this.props.onError({
            description: 'Minimum file dimensions are 600px by 400px',
            title: 'Image too small',
          });
          return;
        }

        this.props.onSubmit({
          file,
          height: image.height,
          imageUrl: image.src,
          width: image.width,
        });
      };
    };

    reader.readAsDataURL(file);
  };
}

export default withStyles(styles)(UploadButton);
