import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './ApartmentNameView.scss';

interface Props {
  children: ReactNode;
}

function ApartmentNameView(props: Props) {
  return (
    <div styleName="apartment-name-view">
      {props.children}
    </div>
  );
}

export default withStyles(styles)(ApartmentNameView);