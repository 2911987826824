export enum Colors {
  Blue = 'BLUE',
  Orange = 'ORANGE',
}

export enum Icons {
  BedLights = 'BEDS_LIGHTS',
  Box = 'BOX',
  Dog = 'DOG',
  HouseMarker = 'HOUSE_MARKER',
  MoneyBags = 'MONEY_BAGS',
  Paper = 'PAPER',
  Photos = 'PHOTOS',
  Star = 'STAR',
}
