export enum Icons {
  BedWindow = 'BED_WINDOW',
  CreditCard = 'CREDIT_CARD',
  Eye = 'EYE',
  EyeHidden = 'EYE_HIDDEN',
  House = 'HOUSE',
  HouseMoney = 'HOUSE_MONEY',
  MoneyBag = 'MONEY_BAG',
  Photo = 'PHOTO',
}
