import React, { ComponentType, Fragment, PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Button from '~tools/react/components/Button';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Tag from '~tools/react/components/Tag';
import Text from '~tools/react/components/Text';

import MiniChargeCardVisual from '~web-manage/lib/common/components/MiniChargeCardVisual';
import * as enums from '~web-manage/lib/common/components/MiniChargeCardVisual/enums';

import styles from './PaymentSourceRow.scss';

interface BaseProps {
  isDefault: boolean;
  isDefaultForTopups?: boolean;
  last4: string;
  id: string;
  onDelete: (id: string) => void;
  onSetDefault: (id: string) => Promise<void>;
}

interface CardProps extends BaseProps {
  brand: string;
  expMonth: number;
  expYear: number;
}

interface BankAccountProps extends BaseProps {
  accountName: string | null;
  bankName: string;
}

type Props = CardProps | BankAccountProps;

interface State {
  isSettingDefault: boolean;
}

class PaymentSourceRow extends PureComponent<Props, State> {
  state: State = { isSettingDefault: false };

  handleDeletePaymentSource = () => {
    this.props.onDelete(this.props.id);
  }

  handleSetDefaultPaymentSource = async () => {
    this.setState({ isSettingDefault: true });
    await this.props.onSetDefault(this.props.id);
    this.setState({ isSettingDefault: false });
  }

  render() {
    return (
      <div styleName="payment-source-row">
        <div styleName="payment-source-row__left">
          {'brand' in this.props ? (
            <Fragment>
              <div styleName="payment-source-row__left__icon">
                <MiniChargeCardVisual
                  brand={this.props.brand as enums.Brands}
                />
              </div>
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
              <Text
                content={`${this.props.brand} Debit`}
                shouldWrap={false}
                isMarkdown
                size={Text.enums.Sizes.Medium}
              />
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Medium} />
              <Text
                content={`•••• ${this.props.last4}`}
                font={Text.enums.Fonts.Monospace}
                shouldWrap={false}
                size={Text.enums.Sizes.Small}
              />
            </Fragment>
          ) : (
            <Fragment>
              <div styleName="payment-source-row__left__icon">
                <MiniChargeCardVisual
                  brand={enums.Brands.Bank}
                />
              </div>
              <Text
                content={this.props.bankName}
                isMarkdown
                shouldWrap={false}
                size={Text.enums.Sizes.Medium}
              />
              {this.props.accountName ? (
                <Fragment>
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                  <Text
                    content={this.props.accountName}
                    isMarkdown
                    shouldWrap={false}
                    size={Text.enums.Sizes.Medium}
                  />
                </Fragment>
              ) : null}
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Medium} />
              <Text
                content={`•••• ${this.props.last4}`}
                font={Text.enums.Fonts.Monospace}
                shouldWrap={false}
                size={Text.enums.Sizes.Small}
              />
            </Fragment>
          )}
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
          {this.props.isDefault ? (
            <Tag
              label="Primary"
              color={Tag.enums.Colors.Green}
              size={Tag.enums.Sizes.Medium}
            />
          ) : null}
          {this.props.isDefault && this.props.isDefaultForTopups ? (
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
          ) : null}
          {this.props.isDefaultForTopups ? (
            <Tag
              label="Expenses"
              color={Tag.enums.Colors.Red}
              size={Tag.enums.Sizes.Medium}
            />
          ) : null}
        </div>
        <div styleName="payment-source-row__right">
          {!this.props.isDefault ? (
            <Fragment>
              <Button
                color={Button.enums.Colors.Gray}
                isLoading={this.state.isSettingDefault}
                label="Set as primary"
                onClick={this.handleSetDefaultPaymentSource}
                size={Button.enums.Sizes.XSmall}
                style={Button.enums.Styles.Outline}
              />
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
              <Button
                color={Button.enums.Colors.Red}
                icon={Button.enums.Icons.Cross}
                label="Delete"
                onClick={this.handleDeletePaymentSource}
                size={Button.enums.Sizes.XSmall}
                style={Button.enums.Styles.Outline}
              />
            </Fragment>
          ) : null}
        </div>
      </div>
    );
  }
}

// @ts-ignore - withStyles hates union props
const StyledPaymentSourceRow = withStyles(styles)(PaymentSourceRow);

export default StyledPaymentSourceRow as ComponentType<Props>;
