export { Colors } from '~tools/react/components/Button/enums';

export enum Alignments {
  Center = 'CENTER',
  Right = 'RIGHT',
}

export enum Sizes {
  Small = 'SMALL',
}
