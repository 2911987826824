export enum FlexBehaviors {
  Default = 'DEFAULT',
  Grow = 'GROW',
  Shrink = 'SHRINK',
}

export enum VerticalAlignments {
  Bottom = 'BOTTOM',
  Center = 'CENTER',
  Top = 'TOP',
}
