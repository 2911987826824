import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './ChartView.scss';

interface Props {
  children: ReactNode;
}

function ChartView(props: Props) {
  return (
    <div styleName="chart-view">
      {props.children}
    </div>
  );
}

export default withStyles(styles)(ChartView);
