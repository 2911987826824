import loadable from '@loadable/component';
import _ from 'lodash';

import * as enums from './enums';
import * as types from './types';
import * as utils from './utils';

const Select = loadable(() => import(/* webpackChunkName: "select" */'./Select'));

export default _.assign(Select, { enums, types, utils });
