export enum Sizes {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE',
}

export enum Widths {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE',
}
