import withMutation from '~tools/react/graphql/withMutation';
import { UpdateAddressUnitInput } from '~tools/types/graphqlSchema';

import UpdateAddressUnitMutation from './updateAddressUnit.gql';

interface Response {
  uuid: string;
}

interface Data {
  updateAddressUnit: Response;
}

interface Variables {
  input: UpdateAddressUnitInput;
  uuid: string;
}

export interface UpdateAddressUnitProps {
  updateAddressUnit: (uuid: string, input: UpdateAddressUnitInput) => Promise<Response>;
}

export default withMutation<{}, Data, Variables, UpdateAddressUnitProps>(UpdateAddressUnitMutation, {
  props: props => ({
    updateAddressUnit: async (uuid: string, input: UpdateAddressUnitInput) => {
      const res = await props.mutate({
        variables: {
          input,
          uuid,
        },
      });

      if (!res || !res.data) throw new Error('Error updating address unit.');
      return res.data.updateAddressUnit;
    },
  }),
});
