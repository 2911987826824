import React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

function UnitMarketListingRedirect(props: RouteComponentProps<{ propertyManagerContractUuid: string}>) {
  return (
    <Redirect to={`/units/${props.match.params.propertyManagerContractUuid}`} />
  );
}

export default withRouter(UnitMarketListingRedirect);
