// @flow

import React, { Component, Fragment } from 'react';
import _ from 'lodash';

import Alert from '~tools/react/components/Alert';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import withStyles from '~tools/react/hocs/withStyles';

import styles from './ManageListingPhotosUploadButton.scss';

const SUPPORTED_PASTE_TYPES = ['image/jpeg', 'image/png', 'image/tiff'];

const MIN_WIDTH = 600;

const MIN_HEIGHT = 400;

type Props = {|
  onSubmit: ({ imageUrl: string, file: ?*, height: number, width: number }) => *,
|};

type State = {|
  error: ?{
    title: string,
    description: string,
  },
|};

class ManageListingPhotosUploadButton extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ error: null });

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    let foundUnsupportedType = false;
    _.each(files, (item) => {
      if (SUPPORTED_PASTE_TYPES.indexOf(item.type) !== -1) {
        this.createPreview(item);
      } else {
        foundUnsupportedType = true;
      }
    });
    if (foundUnsupportedType) {
      this.setState({
        error: { title: 'Unsupported file type', description: 'Only PNG, JPEG, and TIFF files are supported' },
      });
    }
  };

  render() {
    return (
      <Fragment>
        <label styleName="photos-upload-button">
          Upload photo
          <input multiple ref={this.bindRef} onChange={this.handleChange} type="file" name="photo" />
        </label>
        {this.state.error ? (
          <Fragment>
            <Alert
              color={Alert.enums.Colors.Red}
              title={this.state.error.title}
              description={this.state.error.description}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
          </Fragment>
        ) : null}
      </Fragment>
    );
  }

  fileInput = null;

  bindRef = (c) => {
    this.fileInput = c;
  };

  createPreview = (file) => {
    const reader = new FileReader();
    let newFile;
    const that = this;
    const { onSubmit } = this.props;
    reader.onloadend = (e) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = function onLoadEnd() {
        if (this.height < MIN_HEIGHT || this.width < MIN_WIDTH) {
          that.setState({
            error: { title: 'Image too small', description: 'Minimum file dimensions are 600px by 400px' },
          });
          return;
        }
        newFile = {
          file,
          height: this.height,
          width: this.width,
          imageUrl: e.target.result,
        };
        onSubmit(newFile);
      };
    };

    reader.readAsDataURL(file);
  };
}

export default withStyles(styles)(ManageListingPhotosUploadButton);
