import _ from 'lodash';
import React from 'react';

import Globals from '~web-core/lib/common/globals';

import withStyles from '~tools/react/hocs/withStyles';

import { Offer } from '~tools/react/containers/PurchaseBoostModal/types';

import Alert from '~tools/react/components/Alert';
import ArrowLink from '~tools/react/components/ArrowLink';
import Button from '~tools/react/components/Button';
import Form, { Input } from '~tools/react/components/Form';
import HorizontalRule from '~tools/react/components/HorizontalRule/HorizontalRule';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import ToggleSwitch from './components/ToggleSwitch';

import styles from './BoostAmountView.scss';

enum CampaignTypes {
  OneTime = 'ONE_TIME',
  Recurring = 'RECURRING',
}

interface Props {
  amountInDollars: number;
  error?: string;
  isRecurring: boolean;
  offer?: Offer;
  onChangeAmount: (value: string) => void;
  onChangeCampaignType: (type: string | number | undefined) => void;
  onCheckoutView: (data: any) => void;
}

const BoostAmountView = (props: Props) => {
  return (
    <div styleName="boost-amount-view">
      <ToggleSwitch
        name="campaignType"
        options={[
          {
            label: _.capitalize(_.lowerCase(CampaignTypes.Recurring)),
            value: CampaignTypes.Recurring,
          },
          {
            label: _.capitalize(_.lowerCase(CampaignTypes.OneTime)),
            value: CampaignTypes.OneTime,
          },
        ]}
        onChange={props.onChangeCampaignType}
        value={props.isRecurring ? CampaignTypes.Recurring : CampaignTypes.OneTime}
      />
      <Form onSubmit={props.onCheckoutView}>
        <Input
          isRequired
          label={`What's your ${props.isRecurring ? 'daily ' : ''}budget?`}
          labelFormat={Input.enums.LabelFormats.Stacked}
          minAmount={props.isRecurring ? 5 : 20}
          name="amountInDollars"
          onChange={props.onChangeAmount}
          placeholder="$10.00"
          type={Input.enums.Types.Currency}
          value={props.amountInDollars}
        />
        {props.error ? (
          <Alert
            title={props.error}
            color={Alert.enums.Colors.Red}
          />
        ) : null}
        {props.offer && props.offer.isActive ? (
          <Alert
            title={props.offer.description}
            color={Alert.enums.Colors.Blue}
          />
        ) : null}
        <Button
          icon={Button.enums.Icons.ChevronRight}
          label="Promote my listing"
          type={Button.enums.Types.Submit}
        />
      </Form>
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
      <Text
        content="Cancel anytime and receive a refund of any unspent budget."
        size={Text.enums.Sizes.Small}
        color={Text.enums.Colors.Secondary}
      />
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
      <HorizontalRule />
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
      <Text
        content="Boosts are a way to easily turn your listing into an advertisement on Facebook and Instagram."
      />
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
      <ArrowLink
        color={ArrowLink.enums.Colors.Blue}
        label="Learn more about how Boosts work"
        link={{
          path: `${Globals.WEB_CORE_DOMAIN}/questions/what-are-flip-boosts`,
          shouldOpenNewTab: true,
        }}
        size={ArrowLink.enums.Sizes.Medium}
      />
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
    </div>
  );
};

export default withStyles(styles)(BoostAmountView);
