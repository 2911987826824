import * as _ from 'lodash';
import { Option } from './types';

export function convertEnumToSelectOptions(enumObj: any): Option[] {
  const options: Option[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const enumValue in enumObj) {
    if (!Number(enumValue)) {
      options.push({
        label: _.capitalize(enumValue),
        value: enumValue,
      });
    }
  }
  return options;
}
