import _ from 'lodash';
import classNames from 'classnames';
import React, { Fragment, PureComponent } from 'react';

import Globals from '~web-core/lib/common/globals';

// Plan descriptions, names, and feature lists all come from workspace file

import {
  PaymentSubscriptionPlanDescriptions,
  PaymentSubscriptionPlanFeatureLists,
  PaymentSubscriptionPlanNames,
  PaymentSubscriptionPlanPricesInCents,
} from '~tools/constants/workspace';

import { compose } from '~tools/react/hocs/utils';
import withStyles from '~tools/react/hocs/withStyles';
import withDevice, { DeviceProps } from '~tools/react/hocs/withDevice';

import Card from '~tools/react/components/Card';
import Button from '~tools/react/components/Button';
import Heading from '~tools/react/components/Heading';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import Text from '~tools/react/components/Text';
import UnorderedList from '~tools/react/components/UnorderedList';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import { Colors } from '~tools/react/components/Card/enums';
import { Icons, Styles } from '~tools/react/components/Button/enums';

import MapSvg from '~tools/svgs/icons/interface/map.svg';

import * as enums from './enums';

import styles from './PricingTierCard.scss';

interface InputProps {
  anchorLink?: string;
  hasAnchorLink?: boolean;
  hasFeatures?: boolean;
  isDisabled?: boolean;
  isFaded?: boolean;
  isSelected?: boolean;
  onClick?: (tier: enums.Tiers) => void;
  tier: enums.Tiers;
}

type Props =
  InputProps &
  DeviceProps;

class PricingTierCard extends PureComponent<Props> {
  static defaultProps = {
    hasFeatures: true,
  }

  handleClick = () => {
    if (this.props.onClick) this.props.onClick(this.props.tier);
  }

  render() {
    const plan = _.capitalize(_.toLower(this.props.tier));

    const priceInCents = PaymentSubscriptionPlanPricesInCents[this.props.tier];

    let buttonColor = Button.enums.Colors[`Brand${plan}Plan`];
    let buttonLabel = 'Select';
    let buttonStyle: Styles | undefined = Button.enums.Styles.Outline;
    let buttonIcon: Icons | undefined;
    let color: Colors;
    let link: { path: string } | undefined;
    const priceCopy = priceInCents >= 0 ? `$${priceInCents / 100} /unit /month` : 'Custom';
    const name = PaymentSubscriptionPlanNames[this.props.tier];
    const description = PaymentSubscriptionPlanDescriptions[this.props.tier];
    const featureList = PaymentSubscriptionPlanFeatureLists[this.props.tier];
    const listColor = UnorderedList.enums.Colors[`Brand${plan}Plan`];

    // If  you leave onClick out of the PricingTierCard component then the card buttons will use the below links
    // TODO change the below links

    switch (this.props.tier) {
      case enums.Tiers.BASIC: {
        color = Colors.BrandBasicPlan;
        link = {
          path: `${Globals.WEB_MANAGE_DOMAIN}/landlord/start?plan=basic`,
        };
        break;
      }
      case enums.Tiers.ENTERPRISE: {
        buttonIcon = Button.enums.Icons.ExternalLink;
        buttonLabel = 'Get in touch';
        color = Colors.BrandEnterprisePlan;
        link = {
          path: 'mailto:sales@caretaker.com',
        };
        break;
      }
      case enums.Tiers.LITE:
      default: {
        color = Colors.BrandLitePlan;
        // Need custom link for this selection
        link = {
          path: `${Globals.WEB_MANAGE_DOMAIN}/landlord/start`,
        };
        break;
      }
    }

    if (this.props.isSelected && this.props.isDisabled) {
      buttonColor = Button.enums.Colors.Gray;
      buttonLabel = 'Current plan';
      buttonStyle = Button.enums.Styles.Secondary;
    } else if (this.props.isSelected) {
      buttonIcon = Button.enums.Icons.Check;
      buttonLabel = 'Selected';
      buttonStyle = undefined;
    }

    return (
      <div
        className={classNames({
          [styles['pricing-tier-card']]: true,
          [styles['pricing-tier-card--faded']]: this.props.isFaded,
          [styles[`pricing-tier-card--tier-${_.kebabCase(this.props.tier)}`]]: this.props.tier,
        })}>
        <Card
          color={color}
          shadow={Card.enums.Shadows.Small}>
          <div styleName="pricing-tier-card__wrapper">
            <div>
              <div styleName="pricing-tier-card__tier">
                <Heading
                  content={name}
                  size={Heading.enums.Sizes.Large}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                <Text
                  content={description}
                  size={Text.enums.Sizes.Large}
                />
              </div>
              <div styleName="pricing-tier-card__content">
                <HorizontalRule />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
                <h2 styleName="pricing-tier-card__price">
                  <div styleName="pricing-tier-card__price__spans">
                    <div styleName="pricing-tier-card__price__number">
                      <Heading
                        font={Heading.enums.Fonts.Secondary}
                        content={priceCopy}
                        size={Heading.enums.Sizes.Medium}
                      />
                    </div>
                  </div>
                </h2>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
                <Button
                  color={buttonColor}
                  icon={buttonIcon}
                  isDisabled={this.props.isDisabled}
                  label={buttonLabel}
                  onClick={this.props.onClick ? this.handleClick : undefined}
                  link={this.props.onClick ? undefined : link}
                  style={buttonStyle}
                  type={Button.enums.Types.Button}
                  width={Button.enums.Widths.Full}
                />
                <VerticalSpacing
                  size={this.props.isMobile ? VerticalSpacing.enums.Sizes.Medium : VerticalSpacing.enums.Sizes.Large}
                />
                {this.props.hasFeatures ? (
                  <Fragment>
                    <UnorderedList
                      items={_.map(featureList, featureLabel => ({
                        color: listColor,
                        label: featureLabel,
                      }))}
                      hasBorders={false}
                      size={UnorderedList.enums.Sizes.Medium}
                      style={UnorderedList.enums.Styles.Secondary}
                    />
                    <VerticalSpacing
                      size={this.props.isMobile ? VerticalSpacing.enums.Sizes.Large : VerticalSpacing.enums.Sizes.Small}
                    />
                  </Fragment>
                ) : null}
              </div>
            </div>
            {this.props.hasAnchorLink ? (
              <Fragment>
                <div styleName="tiers-view__footer">
                  <div styleName="tiers-view__footer-banner">
                    <MapSvg />Limited by location.&nbsp;
                    <a href="/manual/getting-started/locations">
                      See details
                    </a>
                  </div>
                </div>
              </Fragment>
            ) : null }
          </div>
        </Card>
      </div>
    );
  }
}

export default compose(
  withDevice,
  withStyles(styles),
)(PricingTierCard);
