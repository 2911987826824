// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._rrEaz7{-webkit-box-align:center;align-items:center;border-top:1px solid #e6e8eb;display:-webkit-box;display:flex;-webkit-box-pack:justify;justify-content:space-between;padding:24px 12px}._rrEaz7 svg{flex-shrink:0;height:32px;width:32px}._rrEaz7 button{-webkit-box-flex:0;flex:0 2 auto}._rrEaz7 div{-webkit-box-flex:1;flex:1 0 auto;justify-self:flex-start;margin-right:32px}._rrEaz7 button{margin-top:16px}@media screen and (max-width:680px){._rrEaz7{-webkit-box-orient:vertical;-webkit-box-direction:reverse;flex-direction:column-reverse;padding:24px 0 0}._rrEaz7 div{margin:24px 0;width:100%}._rrEaz7 a,._rrEaz7 button{width:100%}._rrEaz7 h5,._rrEaz7 p{text-align:center}._rrEaz7 h5{font-size:16px;padding-bottom:4px}}", ""]);
// Exports
exports.locals = {
	"next-steps-card": "_rrEaz7"
};
module.exports = exports;
