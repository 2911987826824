// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._vqQ6De{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-pack:center;justify-content:center;margin-bottom:56px}._vqQ6De ._99vhJj svg{height:24px;opacity:1;-webkit-transition:opacity .1s;transition:opacity .1s}._vqQ6De ._99vhJj:hover svg{opacity:.8}@media screen and (max-width:1127px){._vqQ6De{margin:0}}", ""]);
// Exports
exports.locals = {
	"flip-logo": "_vqQ6De",
	"flip-logo__svg": "_99vhJj"
};
module.exports = exports;
