import React, { MouseEvent, ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import {
  CarotPosition,
  Position,
} from './types';

import styles from './Dropdown.scss';

interface Props {
  carotPosition?: CarotPosition;
  children: ReactNode;
  onClick?: (e: MouseEvent) => void;
  position: Position;
}

function Dropdown(props: Props) {
  return (
    <div
      onClick={props.onClick}
      styleName="dropdown"
      style={{
        bottom: props.position.bottom !== undefined ? props.position.bottom : 'auto',
        left: props.position.left !== undefined ? props.position.left : 'auto',
        right: props.position.right !== undefined ? props.position.right : 'auto',
        top: props.position.top !== undefined ? props.position.top : 'auto',
      }}>
      {props.carotPosition ? (
        <div
          styleName="dropdown__carot"
          style={{
            left: props.carotPosition.left !== undefined ? props.carotPosition.left : 'auto',
            right: props.carotPosition.right !== undefined ? props.carotPosition.right : 'auto',
          }}
        />
      ) : null}
      <div styleName="dropdown__children">
        {props.children}
      </div>
    </div>
  );
}

export default withStyles(styles)(Dropdown);
