import { canUseDOM } from '~tools/utils/environment';

import { Events } from './enums';

import { AnalyticsTrackInput } from './types';

import BaseAnalytics from './base';

class GoogleAnalytics extends BaseAnalytics {
  identify = (uuid: string): void => {
    if (!canUseDOM) return;

    // (window as any).ga('set', 'userId', uuid);
  }

  page = (route: string): void => {
    if (!canUseDOM) return;

    // (window as any).ga('set', 'page', route);
    // (window as any).ga('send', 'pageview');
  }

  track = (event: Events, properties?: AnalyticsTrackInput): void => {
    if (!canUseDOM) return;

    // (window as any).ga('send', 'event', {
    //   eventAction: event,
    //   eventCategory: properties?.category || 'All',
    //   eventLabel: properties?.label,
    //   eventValue: properties?.value || 0,
    // });
  }
}

export default GoogleAnalytics;
