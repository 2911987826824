import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withAddPhotoToListing, { AddPhotoToListingProps } from '~tools/react/graphql/mutations/photos/withAddPhotoToListing';
import withCopyPhotoToListing, { CopyPhotoToListingProps } from '~tools/react/graphql/mutations/photos/withCopyPhotoToListing';
import withDeletePhoto, { DeletePhotoProps } from '~tools/react/graphql/mutations/photos/withDeletePhoto';

import Button from '~tools/react/components/Button';

import ListingSetupPhoto from './containers/ListingSetupPhoto';
import ImportBuildingPhotos from './containers/ImportBuildingPhotos';

import ManageListingPhotosEmptyState from './components/ManageListingPhotosEmptyState';
import ManageListingPhotosReorderOverlay from './components/ManageListingPhotosReorderOverlay';
import ManageListingPhotosUploadButton from './components/ManageListingPhotosUploadButton';
import ManageListingPhotosView from './components/ManageListingPhotosView';

import query from './ListingSetupPhotos.gql';

import { InputProps, SavedPhoto, State } from './types';

type Props =
  InputProps &
  AddPhotoToListingProps &
  CopyPhotoToListingProps &
  DeletePhotoProps;

class ListingSetupPhotos extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReordering: false,
      isReorderOverlayOpen: false,
      photos: props.photos || [],
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!nextProps.isLoading && this.props.isLoading) {
      this.setState({ photos: nextProps.photos });
    }
  }

  handleFileUpload = async ({
    file,
    imageUrl,
    height,
    width,
  }) =>
    this.setState({
      photos: _.concat(this.state.photos, [
        {
          file,
          height,
          width,
          position: this.state.photos.length,
          url: imageUrl,
        },
      ]),
    })

  handleSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;
    let photos = _.orderBy(this.state.photos, 'position');
    photos.splice(newIndex, 0, photos.splice(oldIndex, 1)[0]);
    photos = _.map(photos, (p, index) => ({ ...p, position: index, uuid: p.uuid }));
    this.setState({ photos });
    const photosToSave = _.map(
      photos,
      (photo, index) => ({ position: index, uuid: photo.uuid }),
    ) as { uuid: string, position: number }[];
    await this.props.onUpdateListing({ photos: photosToSave });
  }

  handleDeletePhoto = async (photo) => {
    const orderedPhotos = _.orderBy(this.state.photos, 'position');
    const indexOfPhoto = _.indexOf(orderedPhotos, photo);
    const photos = _.compact(_.map(orderedPhotos, (p, index) => {
      if (index === indexOfPhoto) return null;
      if (index > indexOfPhoto) {
        return {
          ...p,
          position: index - indexOfPhoto - 1,
        };
      }
      return {
        ...p,
        position: index,
      };
    }));
    this.setState({ photos });
    await this.props.deletePhoto(photo.uuid);
    const photosToSave = _.map(photos, p => _.pick(p, 'uuid', 'position')) as { uuid: string, position: number }[];
    this.props.onUpdateListing({ photos: photosToSave });
  }

  handleUpdateUuid = photo =>
    this.setState({
      photos: _.map(this.state.photos, (p) => {
        if (p.position === photo.position && !p.uuid) {
          return photo;
        }
        return p;
      }),
    })

  handleCopyPhotos = async (photos: SavedPhoto[]) => {
    this.setState({
      photos: _.concat(this.state.photos, photos),
    });

    await Promise.all(_.map(
      photos,
      photo => this.props.copyPhotoToListing(photo.uuid, { listingUuid: this.props.listingUuid }),
    ));
  }

  render() {
    const hasNoPhotos = !this.props.isLoading && this.state.photos.length === 0;
    const photos = _.orderBy(this.state.photos, 'position');
    return (
      <Fragment>
        {this.state.isReorderOverlayOpen ?
          <ManageListingPhotosReorderOverlay
            onClick={this.toggleReorderPhotos}
          /> : null}
        {hasNoPhotos ? <ManageListingPhotosEmptyState /> : null}
        {/* Using react sortable HOC so prop names must match spec */}
        <ManageListingPhotosView
          lockAxis="y"
          lockToContainerEdges={true}
          onSortEnd={this.handleSortEnd}
          isReordering={this.state.isReordering || this.state.isReorderOverlayOpen}>
          {this.props.isLoading ?
            _.times(3, x => (
              <ListingSetupPhoto
                addPhotoToListing={this.props.addPhotoToListing}
                deletePhoto={this.handleDeletePhoto}
                disabled={true}
                index={x}
                isLoading={true}
                isReordering={false}
                key={x}
                listingUuid={this.props.listingUuid}
                mediaTags={[]}
                updateUuid={this.handleUpdateUuid}
              />
            )) : null}
          {_.map(photos, (p, index) => (
            <ListingSetupPhoto
              addPhotoToListing={this.props.addPhotoToListing}
              deletePhoto={this.handleDeletePhoto}
              disabled={!this.state.isReordering}
              index={index}
              isLoading={false}
              isReordering={this.state.isReordering || this.state.isReorderOverlayOpen}
              key={p.uuid || p.url}
              listingUuid={this.props.listingUuid}
              mediaTags={this.props.mediaTags}
              photo={p}
              position={index}
              updateUuid={this.handleUpdateUuid}
            />
          ))}
        </ManageListingPhotosView>
        {this.state.isReordering || this.state.isReorderOverlayOpen ? null : (
          <Fragment>
            <ManageListingPhotosUploadButton onSubmit={this.handleFileUpload} />
            {!hasNoPhotos && this.state.photos.length > 1 ? (
              <Button
                color={Button.enums.Colors.White}
                label="Reorder photos"
                onClick={this.toggleReorderOverlay}
                style={Button.enums.Styles.Secondary}
                width={Button.enums.Widths.Full}
              />
            ) : null}
          </Fragment>
        )}
        <ImportBuildingPhotos
          copyPhotosToListing={this.handleCopyPhotos}
          listingUuid={this.props.listingUuid}
          photos={this.state.photos}
        />
      </Fragment>
    );
  }

  toggleReorderOverlay = () => this.setState({ isReorderOverlayOpen: !this.state.isReorderOverlayOpen });
  toggleReorderPhotos = () => this.setState({ isReorderOverlayOpen: false, isReordering: !this.state.isReordering });
}

interface MediaTag {
  name: string;
  uuid: string;
}

interface Response {
  mediaTags: MediaTag[];
  viewer: {
    listing: {
      photos: SavedPhoto[];
      uuid: string;
    }
    uuid: string;
  } | null;
}

interface Variables {
  listingUuid: string;
}

interface QueryProps {
  isLoading: boolean;
  mediaTags: MediaTag[];
  photos: SavedPhoto[];
}

export default compose(
  withCopyPhotoToListing,
  withAddPhotoToListing,
  withDeletePhoto,
  withRouter,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: props => ({
      variables: {
        listingUuid: props.listingUuid,
      },
      skip: !props.listingUuid,
      ssr: false,
    }),
    props: props => ({
      isLoading: props.loading,
      mediaTags: props.data?.mediaTags || [],
      photos: props.data?.viewer?.listing.photos || [],
    }),
  }),
)(ListingSetupPhotos);
