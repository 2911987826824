// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._YXr0WD{-webkit-box-align:center;align-items:center;border:none;box-shadow:none;color:#3c4858;display:-webkit-box;display:flex;padding:0;-webkit-transition:opacity .2s cubic-bezier(.645,.045,.355,1);transition:opacity .2s cubic-bezier(.645,.045,.355,1)}._YXr0WD:hover{opacity:.8}._YXr0WD:hover._RnEQkM svg{-webkit-transform:rotate(180deg) translateX(6px);transform:rotate(180deg) translateX(6px)}._YXr0WD:hover._P9WnGX svg{-webkit-transform:translateX(6px);transform:translateX(6px)}._YXr0WD svg{-webkit-transition:-webkit-transform .2s cubic-bezier(.645,.045,.355,1);transition:-webkit-transform .2s cubic-bezier(.645,.045,.355,1);transition:transform .2s cubic-bezier(.645,.045,.355,1);transition:transform .2s cubic-bezier(.645,.045,.355,1), -webkit-transform .2s cubic-bezier(.645,.045,.355,1)}._YXr0WD._GN6X86{margin:auto}._YXr0WD._QDoiwk{margin-left:auto}._YXr0WD._2dANVW{margin-right:auto}._YXr0WD._P9WnGX svg{margin-left:8px}._YXr0WD._P9WnGX._QDozQz{margin-right:auto}._YXr0WD._RnEQkM svg{margin-right:8px;-webkit-transform:rotate(180deg);transform:rotate(180deg)}._YXr0WD._RnEQkM._QDozQz{margin-left:auto}._YXr0WD._YXrr8Y{pointer-events:none}._YXr0WD._6OATpY{color:#000}._YXr0WD._0N5xVq,._YXr0WD._5aAw6r{color:#1f8eed}._YXr0WD._6OAB3k{color:#0253b9}._YXr0WD._VYQ9y9,._YXr0WD._BX05Aa{color:#003487}._YXr0WD._5aAEBG{color:#000}._YXr0WD._ernJmp{color:#3c4858}._YXr0WD._2dAiDW{color:#21bc7a}._YXr0WD._YXrEGq{color:#c8289b}._YXr0WD._wdj3Vo{color:#ca0021}._YXr0WD._rrEzjY{color:#f2a615}._YXr0WD._zaNSoy{color:#fff}._YXr0WD._WVjiED{font-size:20px}._YXr0WD._O0GN6R{font-size:18px}._YXr0WD._yprcMz{font-size:16px}._YXr0WD._yprcMz svg{height:16px;width:16px}._YXr0WD._P9WO5e{font-size:14px}._YXr0WD._P9WO5e svg{height:13px;width:13px}", ""]);
// Exports
exports.locals = {
	"arrow-link": "_YXr0WD",
	"arrow-link--direction-left": "_RnEQkM",
	"arrow-link--direction-right": "_P9WnGX",
	"arrow-link--align-center": "_GN6X86",
	"arrow-link--align-right": "_QDoiwk",
	"arrow-link--align-left": "_2dANVW",
	"arrow-link--has-auto-margin": "_QDozQz",
	"arrow-link--disabled": "_YXrr8Y",
	"arrow-link--color-black": "_6OATpY",
	"arrow-link--color-blue": "_0N5xVq",
	"arrow-link--color-brand-lite-plan": "_5aAw6r",
	"arrow-link--color-brand-leaseholder-plan": "_6OAB3k",
	"arrow-link--color-brand-advanced-plan": "_VYQ9y9",
	"arrow-link--color-brand-basic-plan": "_BX05Aa",
	"arrow-link--color-brand-enterprise-plan": "_5aAEBG",
	"arrow-link--color-gray": "_ernJmp",
	"arrow-link--color-green": "_2dAiDW",
	"arrow-link--color-purple": "_YXrEGq",
	"arrow-link--color-red": "_wdj3Vo",
	"arrow-link--color-yellow": "_rrEzjY",
	"arrow-link--color-white": "_zaNSoy",
	"arrow-link--size-xlarge": "_WVjiED",
	"arrow-link--size-large": "_O0GN6R",
	"arrow-link--size-medium": "_yprcMz",
	"arrow-link--size-small": "_P9WO5e"
};
module.exports = exports;
