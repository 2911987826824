import React, { Fragment, PureComponent } from 'react';
import { canUseDOM } from 'exenv';

import Button from '~tools/react/components/Button';
import Modal from '~tools/react/components/Modal';
import withStyles from '~tools/react/hocs/withStyles';

import styles from './ThreadSupportModal.scss';

import Help from './svgs/help.svg';

interface Props {
  isOpen: boolean;
  hideRequestHelp: boolean;
  onClickRequestHelp: () => any;
  onClose: () => any;
}

class ThreadSupportModal extends PureComponent<Props> {
  handleClickPrivateEmail = () => {
    if (canUseDOM && window) {
      window.open('mailto:support@caretaker.com');
    }
    this.props.onClose();
  }

  render() {
    let copy = 'Invite a support specialist to join the conversation, or send a private email to keep it between you and our support team';
    if (this.props.hideRequestHelp) {
      copy = 'Send a private email to get help from a support specialist.';
    }
    return (
      <Modal onClose={this.props.onClose} isOpen={this.props.isOpen}>
        <div styleName="support-modal">
          <Help />
          <h3>Need help?</h3>
          <p>{copy}</p>
          {!this.props.hideRequestHelp && (
            <Fragment>
              <Button onClick={this.props.onClickRequestHelp} width={Button.enums.Widths.Full} label="Invite support" />
              <div styleName="support-modal__or">OR</div>
            </Fragment>)}
          <Button onClick={this.handleClickPrivateEmail} style={Button.enums.Styles.Outline} width={Button.enums.Widths.Full} label="Send a private email" />
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(ThreadSupportModal);
