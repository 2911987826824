import React, { Fragment } from 'react';

import { ShipmentStatuses } from '~tools/types/graphqlSchema';

import Alert from '~tools/react/components/Alert';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import { formatUnitName } from '~web-manage/lib/common/utils/addressUnit';

interface Props {
  address: {
    state: string,
  };
  shippingAddressUnit: {
    address: {
      state: string;
      streetAddress1: string;
      uuid: string;
    };
    name: string | null;
    uuid: string;
  };
  status?: ShipmentStatuses;
  trackingUrl?: string;
}

function getShippingTimeEstimate(state: string): string {
  if (state === 'NY') return '1-2 days';
  return '4-6 days';
}

function SmartLockShippingBanner(props: Props) {
  const displayedUnitAddress = props.shippingAddressUnit.name ? `${props.shippingAddressUnit.address.streetAddress1}, ${formatUnitName(props.shippingAddressUnit)}` : props.shippingAddressUnit.address.streetAddress1;

  let statusText: string;
  let alertColor = Alert.enums.Colors.Gray;
  let alertIcon = Alert.enums.Icons.Info;
  switch (props.status) {
    case ShipmentStatuses.DELIVERED: {
      statusText = `Your smart lock *has been delivered* to ${displayedUnitAddress}.`;
      break;
    }
    case ShipmentStatuses.IN_TRANSIT: {
      alertColor = Alert.enums.Colors.Blue;
      alertIcon = Alert.enums.Icons.Info;
      statusText = `Your smart lock *has shipped* to ${displayedUnitAddress}. Expect it to arrive within ${getShippingTimeEstimate(props.shippingAddressUnit.address.state)}.`;
      break;
    }
    case ShipmentStatuses.RETURNED: {
      statusText = `Your smart lock *has been returned*.`;
      break;
    }
    case ShipmentStatuses.PREPARING: {
      alertColor = Alert.enums.Colors.Blue;
      alertIcon = Alert.enums.Icons.Info;
      statusText = `Your smart lock *is being prepared*. It should ship within one business day, with updates posted here.`;
      break;
    }
    case ShipmentStatuses.FAILED: {
      alertColor = Alert.enums.Colors.Red;
      alertIcon = Alert.enums.Icons.Alert;
      statusText = `This smart lock shipment *encountered an exception*.`;
      break;
    }
    case ShipmentStatuses.VOID: {
      alertColor = Alert.enums.Colors.Red;
      alertIcon = Alert.enums.Icons.Alert;
      statusText = `This smart lock order has been *voided*.`;
      break;
    }
    default:
      // Don't return a status banner if the given status isn't handled by one of the above cases.
      return null;
  }

  return (
    <Fragment>
      <Alert
        color={alertColor}
        description={statusText}
        icon={alertIcon}
        link={{
          icon: Alert.enums.ButtonIcons.ExternalLink,
          label: 'Track this shipment',
          path: props.trackingUrl ?? '/',
          shouldOpenNewTab: true,
        }}
      />
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
    </Fragment>
  );
}

export default SmartLockShippingBanner;
