import React from 'react';
import { Redirect } from 'react-router-dom';

export default {
  /* eslint-disable no-undef */

  //
  // Primary app routes
  '/': require(/* webpackChunkName: "home" */'~web-manage/lib/common/scenes/Home').default,
  '/login': require(/* webpackChunkName: "login" */'~tools/react/scenes/Login').default,
  '/signup': require(/* webpackChunkName: "register" */'~tools/react/scenes/Register').default,

  //
  // Account routes
  '/start': require(/* webpackChunkName: "start" */'~web-manage/lib/common/scenes/Start').default,
  '/landlord/start': require(/* webpackChunkName: "landlord-start" */'~web-manage/lib/common/scenes/LandlordStart').default,
  '/lite/start': require(/* webpackChunkName: "landlord-start" */'~web-manage/lib/common/scenes/LiteStart').default,

  // Leaseholder redirects

  '/leaseholder/start': () => <Redirect to="/start" />,
  '/leaseholder/select-plan/:addressUnitUuid': () => <Redirect to="/start" />,
  '/landlord-approval': () => <Redirect to="/" />,

  // Unit routes
  '/units': require(/* webpackChunkName: "units" */'~web-manage/lib/common/scenes/Units').default,
  '/units/new': () => <Redirect to="/start" />, // Temporary until we fix all the links
  '/units/:propertyManagerContractUuid': require(/* webpackChunkName: "unit" */'~web-manage/lib/common/scenes/Unit').default,
  '/units/:propertyManagerContractUuid/applicants/:applicationUuid/:tab/:tabUuid?': require(/* webpackChunkName: "unit-application" */'~web-manage/lib/common/scenes/Applicant').default,
  '/units/:propertyManagerContractUuid/market': require(/* webpackChunkName: "unit-market-redirect" */'~web-manage/lib/common/scenes/__redirects/UnitMarketListingRedirect').default,
  '/units/:propertyManagerContractUuid/market/listing-details': require(/* webpackChunkName: "unit-market-listing-redirect" */'~web-manage/lib/common/scenes/__redirects/UnitMarketListingRedirect').default,
  '/units/:propertyManagerContractUuid/market/listing-details/:section': require(/* webpackChunkName: "unit-market-listing-section-redirect" */'~web-manage/lib/common/scenes/__redirects/UnitMarketListingRedirect').default,
  '/units/:propertyManagerContractUuid/access': require(/* webpackChunkName: "unit-access" */'~web-manage/lib/common/scenes/Unit/scenes/UnitAccess').default,
  '/units/:propertyManagerContractUuid/applications': require(/* webpackChunkName: "unit-applications" */'~web-manage/lib/common/scenes/Unit/scenes/UnitApplications').default,
  '/units/:propertyManagerContractUuid/marketing': require(/* webpackChunkName: "unit-marketing" */'~web-manage/lib/common/scenes/Unit/scenes/UnitMarketing').default,
  '/units/:propertyManagerContractUuid/verification': require(/* webpackChunkName: "unit-verification" */'~web-manage/lib/common/scenes/Unit/scenes/UnitVerification').default,
  '/units/:propertyManagerContractUuid/maintain': require(/* webpackChunkName: "unit-maintain" */'~web-manage/lib/common/scenes/Unit/scenes/UnitMaintain').default,

  //
  // Finances routes
  '/finances': () => <Redirect to="/revenue" />, // require(/* webpackChunkName: "finances" */'~web-manage/lib/common/scenes/Finances').default,
  '/revenue': require(/* webpackChunkName: "revenue" */'~web-manage/lib/common/scenes/Revenue').default,
  '/expenses': require(/* webpackChunkName: "expenses" */'~web-manage/lib/common/scenes/Expenses').default,
  '/authorizations/:issuedCardAuthorizationUuid': require(/* webpackChunkName: "issued-card-authorization" */'~web-manage/lib/common/scenes/Authorization').default,

  //
  // Access routes
  '/access': () => <Redirect to="/smart-locks" />, // Temporary until we have a proper Access overview
  '/keyboxes': () => <Redirect to="/smart-locks" />,
  '/keyboxes/:orderUuid': require(/* webpackChunkName: "keybox-redirect" */'~web-manage/lib/common/scenes/__redirects/KeyboxRedirect').default,
  '/smart-locks': require(/* webpackChunkName: "smart-locks" */'~web-manage/lib/common/scenes/SmartLocks').default,
  '/smart-locks/:orderUuid': require(/* webpackChunkName: "smart-lock" */'~web-manage/lib/common/scenes/SmartLock').default,

  //
  // Market routes
  '/market': () => <Redirect to="/questions" />, // Temporary until we have a proper Market overview
  '/viewings': require(/* webpackChunkName: "viewings" */'~web-manage/lib/common/scenes/Viewings').default,
  '/viewings/:viewingUuid': require(/* webpackChunkName: "viewing" */'~web-manage/lib/common/scenes/Viewing').default,
  '/questions': require(/* webpackChunkName: "questions" */'~web-manage/lib/common/scenes/Questions').default,
  '/questions/:listingQuestionUuid': require(/* webpackChunkName: "question" */'~web-manage/lib/common/scenes/Question').default,
  '/listings/:listingUuid/next-steps': require(/* webpackChunkName: "listing-setup-confirmation" */'~web-manage/lib/common/scenes/ListingSetupConfirmation').default,
  '/listings/:listingUuid/:step': require(/* webpackChunkName: "listing-setup" */'~web-manage/lib/common/scenes/ListingSetup').default,

  //
  // Application routes
  '/applicants': require(/* webpackChunkName: "applicants" */'~web-manage/lib/common/scenes/Applicants').default,
  '/applicants/:applicationUuid': require(/* webpackChunkName: "applicant" */'~web-manage/lib/common/scenes/Applicant').default,

  //
  // Leases routes
  '/leases': require(/* webpackChunkName: "leases" */'~web-manage/lib/common/scenes/Leases').default,
  '/leases/:leaseUuid': require(/* webpackChunkName: "lease" */'~web-manage/lib/common/scenes/Lease').default,
  '/security-deposits': require(/* webpackChunkName: "security-deposits" */'~web-manage/lib/common/scenes/SecurityDeposits').default,
  '/security-deposits/:securityDepositUuid': require(/* webpackChunkName: "security-deposit" */'~web-manage/lib/common/scenes/SecurityDeposit').default,

  //
  // Maintain routes
  '/maintain': require(/* webpackChunkName: "maintain" */'~web-manage/lib/common/scenes/Maintain').default,
  '/inspections': require(/* webpackChunkName: "inspections" */'~web-manage/lib/common/scenes/Inspections').default,
  '/issues': require(/* webpackChunkName: "issues" */'~web-manage/lib/common/scenes/Issues').default,
  '/issues/:issueUuid': require(/* webpackChunkName: "issue" */'~web-manage/lib/common/scenes/Issue').default,
  '/inspections/:conditionInspectionUuid': require(/* webpackChunkName: "inspection" */'~web-manage/lib/common/scenes/Inspection').default,

  //
  // Settings routes
  '/settings': require(/* webpackChunkName: "settings" */'~web-manage/lib/common/scenes/Settings').default,
  '/settings/applications': require(/* webpackChunkName: "applications-settings" */'~web-manage/lib/common/scenes/ApplicationsSettings').default,
  '/settings/leases': require(/* webpackChunkName: "leases-settings" */'~web-manage/lib/common/scenes/LeasesSettings').default,
  '/settings/maintenance': require(/* webpackChunkName: "maintenance-settings" */'~web-manage/lib/common/scenes/MaintenanceSettings').default,
  '/settings/maintenance/service-providers': require(/* webpackChunkName: "service-providers" */'~web-manage/lib/common/scenes/ServiceProviders').default,
  '/settings/maintenance/service-providers/:servicePreferenceUuid': require(/* webpackChunkName: "service-provider" */'~web-manage/lib/common/scenes/ServiceProvider').default,

  // Moved Account scenes, now in Settings
  '/account': () => <Redirect to="/settings/account" />,
  '/settings/account': require(/* webpackChunkName: "account-settings" */'~web-manage/lib/common/scenes/AccountSettings').default,

  '/account/billing': () => <Redirect to="/settings/billing" />,
  '/settings/billing': require(/* webpackChunkName: "billing-settings" */'~web-manage/lib/common/scenes/BillingSettings').default,

  '/account/payments': () => <Redirect to="/settings/payouts" />,
  '/settings/payouts': require(/* webpackChunkName: "payouts-settings" */'~web-manage/lib/common/scenes/PayoutsSettings').default,

  //
  // Redirects
  '/listings/:listingUuid': require(/* webpackChunkName: "listing-redirect" */'~web-manage/lib/common/scenes/__redirects/ListingRedirect').default,
  '/messages/:threadUuid': require(/* webpackChunkName: "messages-redirect" */'~web-manage/lib/common/scenes/__redirects/MessagesRedirect').default,
  '/outbound/stripe/:accountLinkType': require(/* webpackChunkName: "stripe-connect-redirect" */'~web-manage/lib/common/scenes/__redirects/StripeConnectRedirect').default,

  // 404
  '/*': require(/* webpackChunkName: "not-found" */'~web-core/lib/common/scenes/NotFound').default,

  /* eslint-enable no-undef */
};
