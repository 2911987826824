// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._DBDpvV{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;position:relative;width:100%}._DBDpvV ._mmJkW9{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;padding:0 4px 12px}._krP7w1{border-radius:5px;display:grid;-webkit-box-flex:1;flex:1;grid-template-columns:repeat(7,1fr);position:relative}", ""]);
// Exports
exports.locals = {
	"calendar": "_DBDpvV",
	"calendar__header": "_mmJkW9",
	"calendar-grid": "_krP7w1"
};
module.exports = exports;
