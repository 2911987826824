import React, { Component } from 'react';
import _ from 'lodash';

import withStyles from '~tools/react/hocs/withStyles';

import Flip from './svgs/flip.svg';
import styles from './PhotoEditorModalFilterFlip.scss';
import PhotoEditorModalFilterView from '../PhotoEditorModalFilterView';
import * as enums from '../../../../enums';

interface Props {
  isActive: boolean;
  onChange: (filter: string, val: string) => void;
  onClickTab: (tab: enums.Filters) => void;
  primaryAction: () => void;
  value?: string;
}

class PhotoEditorModalFilterFlip extends Component<Props> {
  handleClickHorizontal = () => {
    let val = 'h';
    if (this.props.value && _.indexOf(this.props.value, 'h') > -1) {
      val = _.replace(this.props.value, 'h', '');
    } else if (this.props.value) {
      val = 'hv';
    }
    this.props.onChange('flip', val);
  };

  handleClickVertical = () => {
    let val = 'v';
    if (this.props.value && _.indexOf(this.props.value, 'v') > -1) {
      val = _.replace(this.props.value, 'v', '');
    } else if (this.props.value) {
      val = 'hv';
    }
    this.props.onChange('flip', val);
  };

  render() {
    return (
      <PhotoEditorModalFilterView
        primaryAction={this.props.primaryAction}
        onClickTab={this.props.onClickTab}
        isActive={this.props.isActive}
        filter={enums.Filters.Flip}>
        <div styleName="filter-flip">
          <button
            styleName={_.indexOf(this.props.value, 'h') > -1 ? 'active' : ''}
            onClick={this.handleClickHorizontal}>
            <Flip />
          </button>
          <button
            styleName={_.indexOf(this.props.value, 'v') > -1 ? 'active' : ''}
            onClick={this.handleClickVertical}>
            <Flip />
          </button>
        </div>
      </PhotoEditorModalFilterView>
    );
  }
}

export default withStyles(styles)(PhotoEditorModalFilterFlip);
