export enum CardBrands {
  'American Express' = 'American Express',
  Bank = 'BANK',
  'Diners Club' = 'Diners Club',
  Discover = 'Discover',
  JCB = 'JCB',
  MasterCard = 'MasterCard',
  UnionPay = 'UnionPay',
  Unknown = 'Unknown',
  Visa = 'Visa',
}
