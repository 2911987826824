import withMutation from '~tools/react/graphql/withMutation';

import MarkNotificationAsReadMutation from './markNotificationAsRead.gql';

interface Notification {
  uuid: string;
  readAt: string;
}

interface Data {
  markNotificationAsRead: Notification;
}

interface Variables {
  uuid: string;
}

export interface MarkNotificationAsReadProps {
  markNotificationAsRead: (uuid: string) => Promise<Notification>;
}

export default withMutation<{}, Data, Variables, MarkNotificationAsReadProps>(MarkNotificationAsReadMutation, {
  props: props => ({
    markNotificationAsRead: async (uuid) => {
      const res = await props.mutate({
        variables: { uuid },
        optimisticResponse: {
          markNotificationAsRead: {
            uuid,
            readAt: (new Date()).toISOString(),
          },
        },
      });
      if (!res || !res.data) throw new Error('Error marking notification as read.');

      return res.data.markNotificationAsRead;
    },
  }),
});
