import { PaymentSubscriptionPlans } from '~tools/types/graphqlSchema';

export const PaymentSubscriptionPlanNames = {
  [PaymentSubscriptionPlans.BASIC]: 'Landlord',
  [PaymentSubscriptionPlans.ADVANCED]: 'Entrepreneur',
  [PaymentSubscriptionPlans.ENTERPRISE]: 'Manager',
  [PaymentSubscriptionPlans.LEASEHOLDER]: 'Leaseholder',
  [PaymentSubscriptionPlans.LITE]: 'Starter',
};

export const PaymentSubscriptionPlanFeatureLists = {
  [PaymentSubscriptionPlans.ENTERPRISE]: [
    'Volume discounts with customized installations and setups',
    'Dedicated coordinator with local knowledge assigned to your units',
    'Multiple accounts with finely-tuned permissions and version control',
  ],
  [PaymentSubscriptionPlans.BASIC]: [
    'Unlimited managed units',
    'Unlimited property listings',
    'Invite, onboard, and collect payments from unlimited tenants',
    'Keep properties in top condition with inspections and issue tracking',
  ],
  [PaymentSubscriptionPlans.LITE]: [
    '1 managed unit',
    '1 property listing',
    'Invite, onboard, and collect payments from a single tenant',
  ],
};
