import React, { ReactNode } from 'react';
import classNames from 'classnames';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './PhotoEditorModalView.scss';

interface Props {
  children: ReactNode;
  isFaded: boolean;
}

function PhotoEditorModalView(props: Props) {
  return (
    <div styleName={classNames({ 'photos-view': true, 'photos-view--faded': props.isFaded })}>
      <div>
        {props.children}
      </div>
    </div>
  );
}

export default withStyles(styles)(PhotoEditorModalView);
