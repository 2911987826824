// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._KpPrM8{color:#182029;font-size:14px;font-weight:700;letter-spacing:1px;line-height:24px;margin:0;padding:0;text-transform:uppercase}._KpPrM8._jrVyD3{color:#0253b9}._KpPrM8._99viWZ{color:#003487}", ""]);
// Exports
exports.locals = {
	"brand-card-title": "_KpPrM8",
	"brand-card-title--brand-leaseholder": "_jrVyD3",
	"brand-card-title--brand-landlord": "_99viWZ"
};
module.exports = exports;
