import _ from 'lodash';

import { AccessInstructionAccessorEntityTypes, AccessInstructionEntityTypes, CreateAccessInstructionInput } from '~tools/types/graphqlSchema';

import CreateAccessInstructionMutation from '~tools/react/graphql/mutations/accessInstructions/createAccessInstruction.gql';
import withMutation from '~tools/react/graphql/withMutation';

import fragment from './AddressUnitUpdateFragment.gql';

interface AccessInstruction {
  uuid: string;
  notes: string | null;
  photos: {
    uuid: string,
    url: string,
  }[];
  addressUnit: {
    uuid: string,
  };
}

interface Data {
  createAccessInstruction: AccessInstruction;
}

interface Variables {
  input: CreateAccessInstructionInput;
}

interface ManagedAddressUnitFragment {
  uuid: string;
  accessInstruction: AccessInstruction | null;
}

export { AccessInstructionAccessorEntityTypes, AccessInstructionEntityTypes };

export interface CreateAccessInstructionProps {
  createAccessInstruction: (input: CreateAccessInstructionInput) => Promise<AccessInstruction>;
}

export default withMutation<{}, Data, Variables, CreateAccessInstructionProps>(CreateAccessInstructionMutation, {
  props: props => ({
    createAccessInstruction: async (input: CreateAccessInstructionInput) => {
      const res = await props.mutate({
        update: (store, updateProps) => {
          const accessInstruction = updateProps.data ? updateProps.data.createAccessInstruction : null;
          if (!accessInstruction) return;

          const id = `ManagedAddressUnit:${accessInstruction.addressUnit.uuid}`;
          const cachedAddressUnit = _.cloneDeep(store.readFragment({ id, fragment })) as ManagedAddressUnitFragment;
          if (!cachedAddressUnit) return;

          cachedAddressUnit.accessInstruction = accessInstruction;

          store.writeFragment({ id, fragment, data: cachedAddressUnit });
        },
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating access instruction.');

      return res.data.createAccessInstruction;
    },
  }),
});
