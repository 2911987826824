// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._6OAHXm{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row;padding:16px 24px}._6OAHXm ._DBDhzm{-webkit-box-align:center;align-items:center;color:#182029;display:-webkit-box;display:flex;-webkit-box-flex:1;flex:1;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row}._6OAHXm ._DBDhzm ._orGiER{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-pack:center;justify-content:center;width:40px}._6OAHXm ._DBDhzm ._orGiER ._mmJvdB{color:#8492a6}._6OAHXm ._KpP81a{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row}._6OAHXm:not(:last-of-type){border-bottom:1px solid #e6e8eb}", ""]);
// Exports
exports.locals = {
	"payment-source-row": "_6OAHXm",
	"payment-source-row__left": "_DBDhzm",
	"payment-source-row__left__icon": "_orGiER",
	"payment-source-row__bank-icon": "_mmJvdB",
	"payment-source-row__right": "_KpP81a"
};
module.exports = exports;
