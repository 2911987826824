import _ from 'lodash';
import classNames from 'classnames';
import React, {
  Children,
  cloneElement,
  Component,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react';

import withStyles from '~tools/react/hocs/withStyles';
import withDevice, { DeviceProps } from '~tools/react/hocs/withDevice';

import AnimatedResizerSection from './components/AnimatedResizerSection';

import * as enums from './enums';

import styles from './AnimatedResizer.scss';

interface InputProps {
  headerAttributes?: {
    backgroundColor: string;
    height: string;
  };
  children: ReactNode;
  width?: enums.Widths;
  isAnimated?: boolean;
}

type Props = InputProps & DeviceProps;

interface State {
  height: string;
  width: string;
}

class AnimatedResizer extends Component<Props, State> {
  static AnimatedResizerSection = AnimatedResizerSection;
  static enums = enums;

  state: State = {
    height: 'auto',
    width: 'auto',
  };

  onSizeChange = (opts: { height: number; width: number }) => {
    this.setState({
      height: `${opts.height}px`,
      width: this.props.width === enums.Widths.Full ? '100%' : `${opts.width}px`,
    });
  };

  render() {
    const isAnimated =
      this.props.isAnimated !== undefined ? this.props.isAnimated : !this.props.isMobile;
    const headerStyle = {
      backgroundColor: 'transparent',
      height: '0px',
    };
    if (this.props.headerAttributes) {
      headerStyle.backgroundColor = this.props.headerAttributes.backgroundColor;
      headerStyle.height = this.props.headerAttributes.height;
    }
    const children = Children.toArray(this.props.children);
    const shownIndex = _.findIndex(
      children,
      (child) => isValidElement(child) && child.props.isShown,
    );

    return (
      <div
        className={classNames({
          [styles['animated-resizer']]: true,
          [styles['animated-resizer--animate-height']]: isAnimated,
        })}
        style={{
          height: this.state.height,
          width: this.state.width,
        }}
      >
        <div
          styleName="animated-resizer__position"
          style={{
            height: this.state.height,
            width: this.state.width,
          }}
        >
          <div
            styleName="animated-resizer__header"
            style={headerStyle}
          />
          {_.map(children, (child, i) => {
            if (isValidElement(child)) {
              return cloneElement(child as ReactElement, {
                key: i,
                width: this.props.width,
                isLeft: i < shownIndex,
                isRight: i > shownIndex,
                onSizeChange: this.onSizeChange,
              });
            }

            return child;
          })}
        </div>
      </div>
    );
  }
}

export default withDevice(withStyles(styles)(AnimatedResizer));
