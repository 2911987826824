import React, { Component, MouseEvent } from 'react';
import isEqual from 'react-fast-compare';

import Button from '~tools/react/components/Button';
import DropdownMenu, { DropdownMenuSection } from '~tools/react/components/DropdownMenu';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './DropdownMenuButton.scss';

interface Props {
  menuSections: DropdownMenuSection[];
}

interface State {
  isMenuOpen: boolean;
}

class DropdownMenuButton extends Component<Props, State> {
  state: State = {
    isMenuOpen: false,
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleCloseMenu);
  }

  handleCloseMenu = () => {
    document.removeEventListener('click', this.handleCloseMenu);
    this.setState({ isMenuOpen: false });
  }
  handleOpenMenu = (e: MouseEvent<EventTarget>) => {
    e.nativeEvent.stopImmediatePropagation();

    document.addEventListener('click', this.handleCloseMenu);
    this.setState({ isMenuOpen: true });
  }

  render() {
    return (
      <div styleName="dropdown-menu-button">
        <Button
          align={Button.enums.Alignments.Right}
          color={Button.enums.Colors.Gray}
          icon={Button.enums.Icons.Ellipsis}
          onClick={
            this.state.isMenuOpen ?
              this.handleCloseMenu :
              this.handleOpenMenu
          }
          size={Button.enums.Sizes.Small}
          style={Button.enums.Styles.Outline}
        />
        {this.state.isMenuOpen ? (
          <DropdownMenu
            carotPosition={{
              right: '25px',
            }}
            position={{
              right: '-13px',
              top: '54px',
            }}
            sections={this.props.menuSections}
          />
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(DropdownMenuButton);
