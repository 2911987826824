import { PaymentSubscriptionPlans } from '~tools/types/graphqlSchema';

// Keeping ADVANCED plan for now to avoid bug re: the one person who is on it
// TODO change the name of BASIC to LANDLORD for clarity

export const PaymentSubscriptionPlanNames = {
  // Free plan
  [PaymentSubscriptionPlans.LITE]: 'Starter',
  // Paid subscriptions; Homeowner plan will be added here
  [PaymentSubscriptionPlans.BASIC]: 'Landlord',
  [PaymentSubscriptionPlans.ENTERPRISE]: 'Manager',
  // Deprecated plans; not removing bc don't want to create issues for existing subs
  [PaymentSubscriptionPlans.LEASEHOLDER]: 'Leaseholder',
  [PaymentSubscriptionPlans.ADVANCED]: 'Entrepreneur',

};

export const PaymentSubscriptionPlanDescriptions = {
  //  [PaymentSubscriptionPlans.HOMEOWNER]: 'Take care of your primary residence',
  [PaymentSubscriptionPlans.LITE]: 'A simple dashboard for managing a single door. Free forever',
  [PaymentSubscriptionPlans.BASIC]: 'Own and manage rental properties. Tenants and rent made easy',
  [PaymentSubscriptionPlans.ENTERPRISE]: 'For property managers operating portfolios of rental units',
};
// -1 = no limit
export const PaymentSubscriptionPlanUnitLimits = {
  [PaymentSubscriptionPlans.BASIC]: -1,
  [PaymentSubscriptionPlans.ADVANCED]: -1,
  [PaymentSubscriptionPlans.ENTERPRISE]: -1,
  [PaymentSubscriptionPlans.LEASEHOLDER]: 1,
  [PaymentSubscriptionPlans.LITE]: 1,
};

// -1 = case-by-case variable pricing
export const PaymentSubscriptionPlanPricesInCents = {
  [PaymentSubscriptionPlans.BASIC]: 1200,
  [PaymentSubscriptionPlans.ADVANCED]: 3400,
  [PaymentSubscriptionPlans.ENTERPRISE]: -1,
  [PaymentSubscriptionPlans.LEASEHOLDER]: 2900,
  [PaymentSubscriptionPlans.LITE]: 0,
};

export const PaymentSubscriptionPlanFeatureLists = {
  [PaymentSubscriptionPlans.ENTERPRISE]: [
    'Volume discounts with customized installations and setups',
    'Dedicated coordinator with local knowledge assigned to your units',
    'Multiple accounts with finely-tuned permissions and version control',
  ],
  [PaymentSubscriptionPlans.BASIC]: [
    'Unlimited managed units',
    'Unlimited property listings',
    'Invite, onboard, and collect payments from unlimited tenants',
    'Keep properties in top condition with inspections and issue tracking',
  ],
  [PaymentSubscriptionPlans.LITE]: [
    '1 managed unit',
    '1 property listing',
    'Invite, onboard, and collect payments from a single tenant',
  ],
};
