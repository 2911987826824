import { CreatePhotoInputType as CreatePhotoInput, PhotoEntityTypes } from '~tools/types/graphqlSchema';

import CreatePhotoMutation from '~tools/react/graphql/mutations/photos/createPhoto.gql';
import withMutation from '~tools/react/graphql/withMutation';

export interface Photo {
  createdAt: string;
  filename: string;
  fileType: string;
  tag: string;
  updatedAt: string;
  url: string;
  uuid: string;
}

export { PhotoEntityTypes };

interface Data {
  createPhoto: Photo;
}

interface Variables {
  input: CreatePhotoInput;
}

export interface CreatePhotoProps {
  createPhoto: (input: CreatePhotoInput) => Promise<Photo>;
}

export default withMutation<{}, Data, Variables, CreatePhotoProps>(CreatePhotoMutation, {
  props: props => ({
    createPhoto: async (input) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating upload.');

      return res.data.createPhoto;
    },
  }),
});
