import classNames from 'classnames';
import React from 'react';
import _ from 'lodash';

import withStyles from '~tools/react/hocs/withStyles';

import BankSVG from '~tools/svgs/icons/interface/bank.svg';
import CreditCardSVG from '~tools/svgs/icons/interface/credit-card.svg';
import DocumentSVG from '~tools/svgs/icons/interface/document.svg';
import DollarSignSVG from '~tools/svgs/icons/interface/dollar-sign.svg';
import DoorHandle from '~tools/svgs/icons/interface/door-handle.svg';
import DropletSVG from '~tools/svgs/icons/interface/droplet.svg';
import GearSVG from '~tools/svgs/icons/interface/gear.svg';
import KeyboxSVG from '~tools/svgs/icons/interface/keybox.svg';
import LockSVG from '~tools/svgs/icons/interface/lock.svg';
import RefreshSVG from '~tools/svgs/icons/interface/refresh.svg';
import UserSVG from '~tools/svgs/icons/interface/user.svg';
import WrenchSVG from '~tools/svgs/icons/interface/wrench.svg';
import ZapSVG from '~tools/svgs/icons/interface/zap.svg';

import * as enums from './enums';

import styles from './IconBlock.scss';

const iconToComponent = {
  [enums.Icons.Bank]: BankSVG,
  [enums.Icons.CreditCard]: CreditCardSVG,
  [enums.Icons.Document]: DocumentSVG,
  [enums.Icons.DollarSign]: DollarSignSVG,
  [enums.Icons.DoorHandle]: DoorHandle,
  [enums.Icons.Droplet]: DropletSVG,
  [enums.Icons.Gear]: GearSVG,
  [enums.Icons.Keybox]: KeyboxSVG,
  [enums.Icons.Lock]: LockSVG,
  [enums.Icons.Refresh]: RefreshSVG,
  [enums.Icons.User]: UserSVG,
  [enums.Icons.Wrench]: WrenchSVG,
  [enums.Icons.Zap]: ZapSVG,
};

interface Props {
  color?: enums.Colors;
  icon: enums.Icons;
  size?: enums.Sizes;
}

function IconBlock(props: Props) {
  const Icon = _.get(iconToComponent, props.icon);

  return (
    <Icon
      className={classNames({
        [styles['icon-block']]: true,
        [styles[`icon-block--color-${_.kebabCase(props.color)}`]]: props.color,
        [styles[`icon-block--size-${_.kebabCase(props.size)}`]]: props.size,
      })}
    />
  );
}

export default withStyles(styles)(IconBlock);
