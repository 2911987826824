// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._YXrtO9{border:1px solid #e6e8eb;border-radius:5px;display:-webkit-box;display:flex;margin:24px 32px;padding:16px}", ""]);
// Exports
exports.locals = {
	"author-header": "_YXrtO9"
};
module.exports = exports;
