import classNames from 'classnames';
import _ from 'lodash';
import queryString from 'query-string';
import React, { PureComponent } from 'react';

import { canUseDOM } from '~tools/utils/environment';
import withStyles from '~tools/react/hocs/withStyles';

import styles from './BackgroundImage.scss';

interface Props {
  adjustment?: {
    auto?: string[];
    bri?: number;
    con?: number;
    exp?: number;
    flip?: string;
    gam?: number;
    high?: number;
    hue?: number;
    invert?: boolean;
    rot?: number;
    sat?: number;
    shad?: number;
    sharp?: number;
    usm?: number;
    usmrad?: number;
    vib?: number;
  },
  size?: {
    h?: number;
    w?: number;
    crop?: string;
  },
  backgroundSize?: string;
  className?: string;
  isAnimated?: boolean;
  isLazy?: boolean;
  onLoad?: () => void;
  src: string;
  styleName?: string; // eslint-disable-line
}

interface State {
  isLoading: boolean;
}

// This is nicely typed but we don't actually need it atm
// interface QueryParamObject {
//   auto: string | string[];
//   bri?: number;
//   con?: number;
//   crop?: string;
//   exp?: number;
//   fit: string;
//   flip?: string;
//   gam?: number;
//   h?: number;
//   high?: number;
//   hue?: number;
//   invert?: boolean;
//   rot?: number;
//   sat?: number;
//   shad?: number;
//   sharp?: number;
//   usm?: number;
//   usmrad?: number;
//   vib?: number;
//   w?: number;
// }

const PLACEHOLDER_QUALITY_SCALE = 0.05;

class BackgroundImage extends PureComponent<Props, State> {
  boundingClientRect: ClientRect | null;
  backgroundImageRef: HTMLElement | null;

  static defaultProps = {
    isAnimated: false,
    isLazy: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: !!props.isLazy,
    };

    this.boundingClientRect = null;
    this.backgroundImageRef = null;
  }

  componentWillMount() {
    if (canUseDOM) this.initializeImage();
  }

  handleLoad = () => {
    if (this.props.onLoad && this.state.isLoading) this.props.onLoad();
    if (this.state.isLoading) this.setState({ isLoading: false });
  }

  handleRefAssignment = (element: HTMLElement | null) => {
    if (element) {
      this.backgroundImageRef = element;
      this.boundingClientRect = element.getBoundingClientRect();
    }
  }

  render() {
    return (
      <div
        className={classNames({
          [styles['background-image']]: true,
          [styles['background-image--animate']]: this.props.isAnimated,
          [styles['background-image--blurred']]: this.state.isLoading,
        }, this.props.className)}
        key={this.props.src}
        ref={this.handleRefAssignment}
        style={{
          backgroundSize: this.props.backgroundSize,
          backgroundImage: `url('${this.state.isLoading ? this.getPlaceholderSrc() : this.getSrc()}')`,
        }}
      />
    );
  }

  initializeImage = () => {
    const image = new Image();
    image.onload = this.handleLoad;
    image.src = this.getSrc();

    if (image.complete) {
      this.handleLoad();
    }
  }

  getSrc = () => `${this.props.src}?${queryString.stringify(this.getQueryParamObject(), { arrayFormat: 'comma' })}`;

  getSrcSet = () => {
    if (!this.backgroundImageRef || !this.boundingClientRect) return '';
    const boundingRect = this.boundingClientRect;
    const query = {
      h: boundingRect.height || undefined,
      w: boundingRect.width || undefined,
      ...this.getQueryParamObject(),
    };

    return _.toString(
      _.times(4, dpr =>
        `${this.props.src}?${queryString.stringify({
          ...query,
          dpr,
        }, { arrayFormat: 'comma' })} ${dpr}x`,
      ),
    );
  };

  getPlaceholderSrc = () => {
    const query = this.getQueryParamObject();

    if (typeof query.h === 'number') query.h *= PLACEHOLDER_QUALITY_SCALE;
    if (typeof query.w === 'number') query.w *= PLACEHOLDER_QUALITY_SCALE;

    return `${this.props.src}?${queryString.stringify(query, { arrayFormat: 'comma' })}`;
  };

  getQueryParamObject = (): Record<string, string | string[] | number | boolean | null | undefined> => {
    let query: {
      auto: string[];
      fit: string;
    } = {
      auto: [],
      fit: 'crop',
    };

    if (this.props.adjustment) {
      query = {
        ...query,
        ...this.props.adjustment,
      };
    }

    if (this.props.size) {
      query = {
        ...query,
        ...this.props.size,
      };
    }

    query.auto.push('format', 'compress');
    query.auto = _.uniq(query.auto);
    return query;
  };
}

export default withStyles(styles)(BackgroundImage);
