// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._VYQFVy{-webkit-box-flex:1;flex-grow:1;height:80px;-webkit-transition:all .2s;transition:all .2s}._VYQFVy._orGFQr{-webkit-box-flex:0;flex-grow:0;width:80px}", ""]);
// Exports
exports.locals = {
	"dropzone-view": "_VYQFVy",
	"dropzone-view--size-small": "_orGFQr"
};
module.exports = exports;
