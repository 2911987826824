// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._APR7XN{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;font-size:16px;font-weight:300;height:auto}._APR7XN a,._APR7XN span{-webkit-transition:all .25s;transition:all .25s;white-space:nowrap}._APR7XN a{color:#8492a6}._APR7XN a:hover,._APR7XN span{color:#182029}._APR7XN svg{margin:0 12px;stroke:#8492a6;stroke-width:1}._APR7XN._RnEy95 a,._APR7XN._RnEy95 span{font-size:14px;font-weight:500}._APR7XN._RnEy95 svg{margin:0 4px}", ""]);
// Exports
exports.locals = {
	"breadcrumbs": "_APR7XN",
	"breadcrumbs--compact": "_RnEy95"
};
module.exports = exports;
