import classNames from 'classnames';
import React, { PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './CreditScore.scss';

interface Props {
  score: number;
}

class CreditScore extends PureComponent<Props> {
  render() {
    let color: string;
    if (this.props.score < 580) color = 'red';
    else if (this.props.score < 670) color = 'orange';
    else if (this.props.score < 740) color = 'yellow';
    else if (this.props.score < 800) color = 'green';
    else color = 'dark-green';
    return (
      <div styleName="credit-score">
        <div
          styleName={classNames({
            'credit-score__slider': true,
            [`credit-score__slider--${color}`]: true,
          })}
          style={{
            left: `calc(${((this.props.score - 300) / 550) * 100}% - 45px)`,
          }}>
          {this.props.score}
        </div>
        <div styleName="credit-score__bar" />
      </div>
    );
  }
}

export default withStyles(styles)(CreditScore);
