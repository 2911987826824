import _ from 'lodash';
import classNames from 'classnames';
import React, { PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import { Item } from '~tools/react/components/Form/components/Typeahead/types';

import * as enums from './enums';

import styles from './TypeaheadItem.scss';

interface Props {
  isSelected: boolean;
  label: string;
  onClick: (arg: Item) => void;
  size?: enums.Sizes;
  value: string;
}

class TypeaheadItem extends PureComponent<Props> {
  static defaultProps = {
    size: enums.Sizes.Medium,
  };

  handleClick = () => this.props.onClick({ value: this.props.value, label: this.props.label });

  render() {
    return (
      <button
        className={classNames({
          [styles['typeahead-item']]: true,
          [styles['typeahead-item--selected']]: this.props.isSelected,
          [styles[`typeahead-item--size-${_.kebabCase(this.props.size)}`]]: this.props.size,
        })}
        type="button"
        onMouseDown={this.handleClick}>
        {this.props.label}
      </button>
    );
  }
}

export default withStyles(styles)(TypeaheadItem);
