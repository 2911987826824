import classNames from 'classnames';
import _ from 'lodash';
import React, { PureComponent } from 'react';

import { KeyCodes } from '~web-core/lib/common/enums/javascript';

// Direct (non-loadable) import to avoid race-cases in Forms
import Input from '~tools/react/components/Form/components/Input/Input';

import Spinner from '~tools/react/components/Spinner';
import withStyles from '~tools/react/hocs/withStyles';

import TypeaheadItem from './components/TypeaheadItem';

import * as enums from './enums';
import * as types from './types';

import styles from './Typeahead.scss';

export interface Props {
  icon?: enums.Icons;
  isDisabled?: boolean;
  isLoading?: boolean;
  isOpen?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  items: types.Item[];
  label?: string;
  labelFormat?: enums.LabelFormats;
  name: string;
  onBlur?: (arg: string) => void;
  onChange?: (arg: string) => void;
  onClick: (arg: types.Item) => void;
  onFocus?: () => void;
  placeholder?: string;
  size?: enums.Sizes;
  style?: enums.Styles;
  value?: string;
}

interface State {
  isVisible: boolean;
  selectedIndex: number;
}

class Typeahead extends PureComponent<Props, State> {
  static enums = enums;

  didClickTypeahead = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      isVisible: !!props.isOpen,
      selectedIndex: -1,
    };
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickDocument);
  }

  handleKeyDown = (keyCode, e) => {
    switch (keyCode) {
      case KeyCodes.DOWN: {
        this.setState({
          isVisible: true,
          selectedIndex: this.state.selectedIndex + 2 > this.props.items.length ? 0 : this.state.selectedIndex + 1,
        });
        break;
      }
      case KeyCodes.UP: {
        this.setState({
          isVisible: true,
          selectedIndex: this.state.selectedIndex - 1 < 0 ? this.props.items.length - 1 : this.state.selectedIndex - 1,
        });
        break;
      }
      case KeyCodes.ENTER: {
        const item = this.props.items[this.state.selectedIndex];
        if (item) this.props.onClick(item);
        this.closeTypeahead();
        e.preventDefault();
        e.stopPropagation();
        break;
      }
      case KeyCodes.ESCAPE: {
        this.closeTypeahead();
        e.preventDefault();
        e.stopPropagation();
        break;
      }
      default:
        break;
    }
  }

  handleBlur = (data: string) => {
    if (this.props.onBlur) this.props.onBlur(data);
  }

  handleClick = () => {
    this.didClickTypeahead = true;
  }

  handleClickItem = (data: types.Item) => {
    this.props.onClick(data);
    this.closeTypeahead();
  }

  handleClickDocument = () => {
    if (this.didClickTypeahead) {
      this.didClickTypeahead = false;
      return;
    }
    this.closeTypeahead();
  }

  handleChange = (data: string) => {
    this.openTypeahead();
    if (this.props.onChange) {
      this.props.onChange(data);
    }
  }

  render() {
    return (
      <div // eslint-disable-line jsx-a11y/click-events-have-key-events
        onClick={this.handleClick}
        className={classNames({
          [styles['form-typeahead']]: true,
          [styles[`form-typeahead--size-${_.kebabCase(this.props.size)}`]]: this.props.size,
          [styles[`form-typeahead--style-${_.kebabCase(this.props.style)}`]]: this.props.style,
        })}>
        <Input
          icon={this.props.icon}
          isAutoComplete={false}
          isDisabled={this.props.isDisabled}
          isReadOnly={this.props.isReadOnly}
          isRequired={this.props.isRequired}
          label={this.props.label}
          labelFormat={this.props.labelFormat || enums.LabelFormats.Stacked}
          name={this.props.name}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.openTypeahead}
          onKeyDown={this.handleKeyDown}
          placeholder={this.props.placeholder}
          size={this.props.size}
          value={this.props.value}
        />
        {this.state.isVisible ? (
          <div styleName="form-typeahead__view">
            {_.map(this.props.items, (item, index) => (
              <TypeaheadItem
                isSelected={this.state.selectedIndex === index}
                key={item.value}
                label={item.label}
                onClick={this.handleClickItem}
                size={this.props.size}
                value={item.value}
              />
            ))}
          </div>
        ) : null}
        <div
          className={classNames({
            [styles['form-typeahead__spinner']]: true,
            [styles['form-typeahead__spinner--visible']]: this.props.isLoading,
          })}>
          <Spinner />
        </div>
      </div>
    );
  }

  closeTypeahead = () => {
    document.removeEventListener('click', this.handleClickDocument);
    this.setState({ isVisible: false });
  }

  openTypeahead = () => {
    document.addEventListener('click', this.handleClickDocument);
    this.setState({ isVisible: true });
    if (this.props.onFocus) this.props.onFocus();
  }
}

export default withStyles(styles)(Typeahead);
