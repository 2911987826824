import React, { PureComponent } from 'react';
import classNames from 'classnames';

import withStyles from '~tools/react/hocs/withStyles';
import Card from '~tools/react/components/Card';
import Text from '~tools/react/components/Text';

import { formatShortAddress } from '~web-manage/lib/common/utils/addressUnit';

import { PropertyManagerContract } from '../../types';
import styles from './UnitPanel.scss';

interface Props {
  gridIndex: number;
  onRemove: (propertyManagerContract: PropertyManagerContract) => void;
  propertyManagerContract: PropertyManagerContract;
}

class UnitPanel extends PureComponent<Props> {
  handleClickRemove = () => {
    this.props.onRemove(this.props.propertyManagerContract);
  };

  render() {
    return (
      <div
        className={classNames({
          [styles[`unit-panel`]]: true,
          [styles[`unit-panel--border-right`]]: this.props.gridIndex % 2 === 0,
          [styles[`unit-panel--border-bottom`]]: this.props.gridIndex % 4 < 2,
        })}>
        <Card.CardSection
          action={{
            icon: Card.CardSection.enums.ActionIcons.Cross,
            onClick: this.handleClickRemove,
          }}
          title={formatShortAddress(this.props.propertyManagerContract.addressUnit)}>
          <Text
            content={`${this.props.propertyManagerContract.addressUnit.issues.total} total issues`}
            color={Text.enums.Colors.Secondary}
          />
        </Card.CardSection>
      </div>
    );
  }
}

export default withStyles(styles)(UnitPanel);
