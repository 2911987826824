import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';
import pluralize from 'pluralize';
import React, { ComponentType, Fragment, PureComponent } from 'react';

import ArrowLink from '~tools/react/components/ArrowLink';
import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import ConfirmationModal from '~tools/react/components/ConfirmationModal';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Tag from '~tools/react/components/Tag';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import withResendLeaseInvite, {
  ResendLeaseInviteProps,
} from '~tools/react/graphql/mutations/leaseInvites/withResendLeaseInvite';
import withRevokeLeaseInvite, {
  RevokeLeaseInviteProps,
} from '~tools/react/graphql/mutations/leaseInvites/withRevokeLeaseInvite';
import withVoidLease, { VoidLeaseProps } from '~tools/react/graphql/mutations/leases/withVoidLease';
import withQuery from '~tools/react/graphql/withQuery';
import { compose } from '~tools/react/hocs/utils';
import Alert from '~tools/svgs/icons/interface/alert-triangle.svg';
import Check from '~tools/svgs/icons/interface/check-circle.svg';
import Clock from '~tools/svgs/icons/interface/clock.svg';
import Refresh from '~tools/svgs/icons/interface/refresh.svg';
import { DocumentTags, StripePaymentMethodTypes } from '~tools/types/graphqlSchema';
import { formatAsUSD } from '~tools/utils/string';
import { formatTimestamp } from '~tools/utils/time';

import DataRow from '~web-manage/lib/common/components/DataRow';
import IconBlock from '~web-manage/lib/common/components/IconBlock';

import CreateLeaseModal from './containers/CreateLeaseModal';

import query from './UnitTenantSection.gql';

const DAYS_UNTIL_RENT_DISPLAY = 14;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

interface State {
  didSendReminderSuccessfully?: boolean;
  isCreateLeaseModalOpen: boolean;
  isRevokeConfirmModalOpen: boolean;
  isSendingReminder: boolean;
}

interface QueryProps {
  isLoading: boolean;
  lease: Lease | null;
  viewer: {
    uuid: string;
    role: 0 | 9;
  } | null;
}

interface InputProps {
  addressUnitUuid?: string;
  leaseUuid?: string;
  onCreateLease: () => void | Promise<void>;
}

type Props = ResendLeaseInviteProps & RevokeLeaseInviteProps & VoidLeaseProps & InputProps & QueryProps;

class UnitTenantSection extends PureComponent<Props, State> {
  timeout: NodeJS.Timeout | undefined;
  state: State = {
    isCreateLeaseModalOpen: false,
    isRevokeConfirmModalOpen: false,
    isSendingReminder: false,
  };

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  handleOpenCreateLeaseModal = () => this.setState({ isCreateLeaseModalOpen: true });
  handleCloseCreateLeaseModal = () => this.setState({ isCreateLeaseModalOpen: false });

  handleOpenRevokeConfirmModal = () => this.setState({ isRevokeConfirmModalOpen: true });
  handleCloseRevokeConfirmModal = () => this.setState({ isRevokeConfirmModalOpen: false });

  handleRevokeInvite = async () => {
    const lease = this.props.lease;
    const leaseInvite = lease?.leaseInvite;
    if (!lease || !leaseInvite) return;

    try {
      await this.props.revokeLeaseInvite(leaseInvite.uuid);
      await this.props.voidLease(lease.uuid);
      this.handleCloseRevokeConfirmModal();
    } catch (err) {
      throw err;
    }
  };

  handleSendReminder = async () => {
    const leaseInvite = this.props.lease?.leaseInvite;
    if (!leaseInvite) return;

    this.setState({ isSendingReminder: true });
    try {
      await this.props.resendLeaseInvite(leaseInvite.uuid);
      this.timeout = setTimeout(() => {
        this.setState({
          isSendingReminder: false,
          didSendReminderSuccessfully: true,
        });
      }, 2000);
    } catch (err) {
      this.setState({
        isSendingReminder: false,
        didSendReminderSuccessfully: false,
      });
    }
  };

  render() {
    const lease = this.props.lease;

    const totalRentPaidInCents = lease ? _.sum(_.map(lease.rentPayments, 'amountInCents')) : 0;
    const totalMonthsPaid = lease ? dayjs(lease.nextRentPaymentMonthStarting).diff(lease.startDate, 'months') : 0;
    const totalMonthsRemaining = lease ? dayjs(lease.endDate).diff(dayjs(), 'month') : 0;
    const totalNumPayments = lease ? lease.rentPayments.length : 0;

    const isOverdue = lease ? dayjs(lease.nextRentPaymentMonthStarting).isBefore(dayjs()) : false;
    const isUpcoming = lease
      ? dayjs(lease.nextRentPaymentMonthStarting).diff(dayjs(), 'days') <= DAYS_UNTIL_RENT_DISPLAY
      : false;
    const isAutopayEnabled = lease?.defaultPaymentMethod && lease?.hasAutopayEnabled;

    let Icon = Check;
    let cardHighlightColor = lease || this.props.isLoading ? undefined : Card.CardSection.enums.HighlightColors.Gray;
    let iconColor = '#15A964';
    let textColor = Text.enums.Colors.Primary;
    if (isUpcoming) {
      Icon = Clock;
      iconColor = '#1F8EED';
    }
    if (isOverdue) {
      Icon = Alert;
      cardHighlightColor = Card.CardSection.enums.HighlightColors.Red;
      iconColor = '#CA0021';
      textColor = Text.enums.Colors.Error;
    }

    if (lease?.leaseInvite && !lease.lessee) {
      return (
        <Card.CardSection
          highlightColor={Card.CardSection.enums.HighlightColors.Gray}
          isLoading={this.props.isLoading}
          spacing={Card.CardSection.enums.Spacing.Small}
        >
          <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
            <IconBlock
              color={IconBlock.enums.Colors.Gray}
              icon={IconBlock.enums.Icons.Document}
            />
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
            <div>
              <Row
                flexBehavior={Row.enums.FlexBehaviors.Default}
                verticalAlignment={Row.enums.VerticalAlignments.Center}
              >
                <Text
                  content={`*${lease.leaseInvite.email}* paying *$${formatAsUSD(
                    lease.rentInCents / 100,
                    true,
                  )}* USD /mo`}
                  size={Text.enums.Sizes.Medium}
                  isMarkdown
                />
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                <Tag
                  color={Tag.enums.Colors.Blue}
                  icon={Tag.enums.Icons.Clock}
                  size={Tag.enums.Sizes.Medium}
                  label="Invite pending"
                />
              </Row>
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
              <DataRow>
                <DataRow.DataRowItem label="Next payment">
                  {lease ? (
                    <Row
                      flexBehavior={Row.enums.FlexBehaviors.Default}
                      verticalAlignment={Row.enums.VerticalAlignments.Center}
                    >
                      <Text
                        size={Text.enums.Sizes.Large}
                        content={`*$${formatAsUSD(lease.nextRentPaymentAmountInCents / 100)}* USD due`}
                        isMarkdown
                      />
                      &nbsp;
                      <Text
                        size={Text.enums.Sizes.Large}
                        content={`${dayjs(dayjs().startOf('day')).to(
                          dayjs(lease.nextRentPaymentMonthStarting).startOf('day'),
                        )}`}
                        isMarkdown
                        color={textColor}
                      />
                      <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXSmall} />
                      <Icon
                        style={{ color: iconColor }}
                        width="18"
                        height="18"
                      />
                    </Row>
                  ) : (
                    <Text
                      size={Text.enums.Sizes.Large}
                      content="-"
                      isEmphasized
                    />
                  )}
                </DataRow.DataRowItem>
                <DataRow.DataRowItem label="Autopay">
                  {isAutopayEnabled ? (
                    <Row
                      flexBehavior={Row.enums.FlexBehaviors.Default}
                      verticalAlignment={Row.enums.VerticalAlignments.Center}
                    >
                      <div style={{ height: '30px', display: 'flex', alignItems: 'flex-end' }}>
                        <Text
                          size={Text.enums.Sizes.Medium}
                          content="Enabled"
                          isMarkdown
                          color={Text.enums.Colors.Info}
                        />
                      </div>
                      <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                      <Refresh
                        style={{ margin: '4px 0 0', color: '#1F8EED' }}
                        width="16"
                        height="16"
                      />
                    </Row>
                  ) : (
                    <Text
                      size={Text.enums.Sizes.Large}
                      content="Disabled"
                      color={Text.enums.Colors.Secondary}
                      isEmphasized
                    />
                  )}
                </DataRow.DataRowItem>
                <DataRow.DataRowItem
                  label="Total paid"
                  description={`$${formatAsUSD(totalRentPaidInCents / 100, true)}`}
                  tooltip={
                    lease
                      ? {
                          text: `$${formatAsUSD(totalRentPaidInCents / 100, true)} over ${totalNumPayments} ${pluralize(
                            'payments',
                            totalNumPayments,
                          )}${
                            totalMonthsPaid > 1
                              ? ` from ${formatTimestamp(lease.startDate, 'MMM D, YYYY')} to ${formatTimestamp(
                                  lease.nextRentPaymentMonthStarting,
                                  'MMM D, YYYY',
                                )}`
                              : ''
                          }`,
                        }
                      : undefined
                  }
                />
                <DataRow.DataRowItem
                  label="Term"
                  description={
                    lease
                      ? `${formatTimestamp(lease.startDate, 'MMM D, YYYY')} - ${formatTimestamp(
                          lease.endDate,
                          'MMM D, YYYY',
                        )}`
                      : '-'
                  }
                  tooltip={{
                    text: `${totalMonthsRemaining} ${pluralize('month', totalMonthsRemaining)} remaining`,
                  }}
                />
              </DataRow>
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                <Button
                  color={Button.enums.Colors.Red}
                  icon={Button.enums.Icons.Trash}
                  style={Button.enums.Styles.Secondary}
                  label="Cancel invite"
                  onClick={this.handleOpenRevokeConfirmModal}
                  size={Button.enums.Sizes.XSmall}
                />
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                <Button
                  style={Button.enums.Styles.Secondary}
                  onClick={this.handleSendReminder}
                  isDisabled={!_.isUndefined(this.state.didSendReminderSuccessfully)}
                  isLoading={this.state.isSendingReminder}
                  size={Button.enums.Sizes.XSmall}
                  {...this.getSendReminderProps()}
                />
              </Row>
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
            </div>
          </Row>
          <ConfirmationModal
            confirmAction={{
              color: ConfirmationModal.enums.ButtonColors.Red,
              icon: ConfirmationModal.enums.ButtonIcons.Trash,
              label: 'Cancel invite',
              onClick: this.handleRevokeInvite,
            }}
            cancelAction={{
              label: 'Nevermind',
              onClick: this.handleCloseRevokeConfirmModal,
            }}
            description={`Are you sure you want to cancel the pending invite for *${lease.leaseInvite.email}*?`}
            isOpen={this.state.isRevokeConfirmModalOpen}
            onClose={this.handleCloseRevokeConfirmModal}
            title="Cancel invite?"
          />
        </Card.CardSection>
      );
    }

    return (
      <Fragment>
        <Card.CardSection
          highlightColor={cardHighlightColor}
          isLoading={this.props.isLoading}
          spacing={Card.CardSection.enums.Spacing.Small}
        >
          <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
            {isOverdue ? (
              <IconBlock
                color={IconBlock.enums.Colors.Red}
                icon={IconBlock.enums.Icons.Document}
              />
            ) : (
              <IconBlock
                color={lease ? IconBlock.enums.Colors.Blue : undefined}
                icon={IconBlock.enums.Icons.Document}
              />
            )}
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
            <div>
              {lease || this.props.isLoading ? (
                <Fragment>
                  <Text
                    content={
                      lease?.lessee
                        ? `*${lease.lessee.fullName}* paying *$${formatAsUSD(lease.rentInCents / 100, true)}* USD /mo`
                        : '-'
                    }
                    size={Text.enums.Sizes.XLarge}
                    isMarkdown
                  />
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                  <DataRow>
                    <DataRow.DataRowItem label="Next payment">
                      {lease ? (
                        <Row
                          flexBehavior={Row.enums.FlexBehaviors.Default}
                          verticalAlignment={Row.enums.VerticalAlignments.Center}
                        >
                          <Text
                            size={Text.enums.Sizes.Large}
                            content={`$${formatAsUSD(lease.nextRentPaymentAmountInCents / 100)} USD due`}
                            isMarkdown
                          />
                          &nbsp;
                          <Text
                            size={Text.enums.Sizes.Large}
                            content={`*${dayjs(dayjs().startOf('day')).to(
                              dayjs(lease.nextRentPaymentMonthStarting).startOf('day'),
                            )}*`}
                            isMarkdown
                            color={textColor}
                          />
                          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXSmall} />
                          <Icon
                            style={{ color: iconColor }}
                            width="18"
                            height="18"
                          />
                        </Row>
                      ) : (
                        <Text
                          size={Text.enums.Sizes.Large}
                          content="-"
                          isEmphasized
                        />
                      )}
                    </DataRow.DataRowItem>
                    <DataRow.DataRowItem label="Autopay">
                      {isAutopayEnabled ? (
                        <Row
                          flexBehavior={Row.enums.FlexBehaviors.Default}
                          verticalAlignment={Row.enums.VerticalAlignments.Center}
                        >
                          <div style={{ height: '28px', display: 'flex', alignItems: 'flex-end' }}>
                            <Text
                              size={Text.enums.Sizes.Medium}
                              content="Enabled"
                              isMarkdown
                            />
                          </div>
                          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                          <Refresh
                            style={{ margin: '0', color: '#1F8EED' }}
                            width="16"
                            height="16"
                          />
                        </Row>
                      ) : (
                        <Text
                          size={Text.enums.Sizes.Large}
                          color={Text.enums.Colors.Secondary}
                          content="Disabled"
                          isEmphasized
                        />
                      )}
                    </DataRow.DataRowItem>
                    <DataRow.DataRowItem
                      label="Total paid"
                      description={`$${formatAsUSD(totalRentPaidInCents / 100, true)}`}
                      tooltip={
                        lease
                          ? {
                              text: `$${formatAsUSD(
                                totalRentPaidInCents / 100,
                                true,
                              )} over ${totalNumPayments} ${pluralize('payments', totalNumPayments)}${
                                totalMonthsPaid > 1
                                  ? ` from ${formatTimestamp(lease.startDate, 'MMM D, YYYY')} to ${formatTimestamp(
                                      lease.nextRentPaymentMonthStarting,
                                      'MMM D, YYYY',
                                    )}`
                                  : ''
                              }`,
                            }
                          : undefined
                      }
                    />
                    <DataRow.DataRowItem
                      label="Term"
                      description={
                        lease
                          ? `${formatTimestamp(lease.startDate, 'MMM D, YYYY')} - ${formatTimestamp(
                              lease.endDate,
                              'MMM D, YYYY',
                            )}`
                          : '-'
                      }
                      tooltip={{
                        text: `${totalMonthsRemaining} ${pluralize('month', totalMonthsRemaining)} remaining`,
                      }}
                    />
                  </DataRow>
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                  <ArrowLink
                    color={isOverdue ? ArrowLink.enums.Colors.Red : ArrowLink.enums.Colors.Blue}
                    label="View &amp; manage the active lease"
                    link={{
                      path: `/leases/${lease?.uuid}`,
                    }}
                    size={ArrowLink.enums.Sizes.Small}
                  />
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                </Fragment>
              ) : (
                <Fragment>
                  <Text
                    content="*Invite a renter*"
                    size={Text.enums.Sizes.Large}
                    isMarkdown
                  />
                  <Text
                    size={Text.enums.Sizes.Small}
                    content="If you already have a tenant in mind, send them a lease for review. Otherwise, market your listing."
                  />
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                  <Button
                    color={Button.enums.Colors.Gray}
                    icon={Button.enums.Icons.Plus}
                    label="Invite a renter"
                    onClick={this.handleOpenCreateLeaseModal}
                    size={Button.enums.Sizes.XSmall}
                    style={Button.enums.Styles.Secondary}
                  />
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                </Fragment>
              )}
            </div>
          </Row>
        </Card.CardSection>
        {this.props.addressUnitUuid ? (
          <CreateLeaseModal
            addressUnitUuid={this.props.addressUnitUuid}
            isOpen={this.state.isCreateLeaseModalOpen}
            onClose={this.handleCloseCreateLeaseModal}
            onCreateLease={this.props.onCreateLease}
          />
        ) : null}
      </Fragment>
    );
  }

  getSendReminderProps = () => {
    if (this.state.didSendReminderSuccessfully === true) {
      return {
        label: 'Reminder sent!',
        color: Button.enums.Colors.Gray,
        icon: Button.enums.Icons.Check,
      };
    }

    if (this.state.didSendReminderSuccessfully === false) {
      return {
        label: 'Error sending reminder',
        color: Button.enums.Colors.Red,
        icon: Button.enums.Icons.Cross,
      };
    }

    return {
      label: 'Send reminder',
      color: Button.enums.Colors.Gray,
      icon: Button.enums.Icons.Send,
    };
  };
}

export interface Lease {
  defaultPaymentMethod: {
    usBankAccount: {
      accountType: string;
      bankName: string;
      currency: string;
      last4: string;
      routingNumber: string;
    } | null;
    card: {
      brand: string;
      expMonth: number;
      expYear: number;
      id: string;
      last4: string;
      name: string;
    } | null;
    id: string;
    type: StripePaymentMethodTypes;
  } | null;
  document: {
    uuid: string;
    accessUrl: string;
    eSignedUrl: string;
    tag: DocumentTags;
    viewerHasSigned: boolean;
    upload: {
      uuid: string;
      url: string;
    };
  };
  endDate: string;
  hasAutopayEnabled: boolean;
  leaseInvite: {
    acceptedAt: string | null;
    createdAt: string;
    email: string;
    uuid: string;
  } | null;
  lessee: {
    uuid: string;
    defaultPhotoUrl: string;
    firstName: string;
    fullName: string;
    photo: {
      uuid: string;
      url: string;
    } | null;
  } | null;
  nextRentPaymentAmountInCents: number;
  nextRentPaymentMonthStarting: string;
  rentInCents: number;
  rentPayments: {
    amountInCents: number;
    uuid: string;
  }[];
  startDate: string;
  uuid: string;
}

interface Response {
  viewer: {
    lease: Lease | null;
    role: 0 | 9;
    uuid: string;
  };
}

interface Variables {
  leaseUuid: string;
}

export default compose(
  withResendLeaseInvite,
  withRevokeLeaseInvite,
  withVoidLease,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: (props) => ({
      variables: {
        leaseUuid: props.leaseUuid,
      },
      skip: !props.leaseUuid,
      ssr: false,
    }),
    props: (props) => ({
      isLoading: props.loading,
      lease: props.data?.viewer?.lease ?? null,
      viewer: props.data?.viewer
        ? {
            role: props.data.viewer.role,
            uuid: props.data.viewer.uuid,
          }
        : null,
    }),
  }),
)(UnitTenantSection) as ComponentType<InputProps>;
