// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._99v7AV{display:grid;grid-gap:16px;grid-template-columns:1fr 1fr 1fr 1fr;position:relative;width:100%}._99v7AV._zaNoMZ{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-flow:column wrap}._99v7AV._zaNoMZ:active{cursor:-webkit-grabbing!important;cursor:grabbing!important}", ""]);
// Exports
exports.locals = {
	"photo-list-view": "_99v7AV",
	"photo-list-view--reordering": "_zaNoMZ"
};
module.exports = exports;
