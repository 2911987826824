export { StripeExternalAccountTypes } from '~tools/types/graphqlSchema';

export enum PayoutStatuses {
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  IN_TRANSIT = 'IN_TRANSIT',
  PAID = 'PAID',
  PENDING = 'PENDING',
  SCHEDULED = 'SCHEDULED',
  SUCCEEDED = 'SUCCEEDED',
}

export enum CardBrands {
  Visa = 'VISA',
  Amex = 'AMEX',
  Discover = 'DISCOVER',
  Mastercard = 'MASTERCARD',
}
