import isEqual from 'react-fast-compare';
import _ from 'lodash';
import moment from 'moment-timezone';
import { AxisBottom, AxisLeft } from '@vx/axis';
import { Grid } from '@vx/grid';
import { Group } from '@vx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import { BarStack } from '@vx/shape';
import React, { Component } from 'react';

import { formatAsUSD } from '~tools/utils/string';

import withStyles from '~tools/react/hocs/withStyles';

import BarPart from './components/BarPart';

import styles from './SVGChart.scss';

const flipBlue = '#1F8EED';
const flipBlueLight = '#85D7FF';
const blueGray5 = '#AAB7C9';
const blueGray6 = '#C0CAD8';
const blueGray7 = '#DFE1E4';

interface Data {
  Expected: number;
  Received: number;
  groupStartsAt: string;
}

interface TooltipData {
  color: string;
  data: Data;
  height: number;
  key: string;
  width: number
  x: number;
  y: number;
}

interface InputProps {
  data: Data[];
  height: number;
  onHideTooltip: () => void;
  onShowTooltip: (args: {
    tooltipData: TooltipData;
    tooltipTop: number;
    tooltipLeft: number;
  }) => void;
  tooltipGroupStartsAt?: string;
}

type Props = InputProps;

interface State {
  data: Data[];
  keys: string[];
  xMax: number;
  yMax: number;
  yMaxValue: number;
}

enum TextAnchors {
  End = 'end',
  Middle = 'middle',
}

const CHART_WIDTH_IN_PIXELS = 618.25;
const emptyStateYMaxInCents = 1500000;
const margin = {
  bottom: 30,
  left: 50,
  right: 0,
  top: 20,
};
const colors = scaleOrdinal({
  range: [flipBlue, flipBlueLight, 'transparent'],
});
const xScale = scaleBand<string>({
  padding: 0.3,
});
const yScale = scaleLinear<number>({
  nice: true,
});

class SVGChart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      data: [],
      keys: [],
      xMax: 0,
      yMax: 0,
      yMaxValue: 0,
    };
  }

  componentDidMount() {
    this.setState(this.getStateFromProps(this.props));
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (!isEqual(this.props, nextProps) || !isEqual(this.state, nextState));
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(this.props, prevProps)) {
      this.setState(this.getStateFromProps(this.props));
    }
  }

  render() {
    const width = CHART_WIDTH_IN_PIXELS;
    const height = this.props.height;

    const data = this.state.data;
    const keys = this.state.keys;
    const xMax = this.state.xMax;
    const yMax = this.state.yMax;
    const yMaxValue = this.state.yMaxValue;

    return (
      <svg
        onMouseLeave={this.props.onHideTooltip}
        height={height}
        width={width}>
        <Grid
          height={yMax}
          left={margin.left}
          columnLineStyle={{
            stroke: 'transparent',
          }}
          numTicksRows={4}
          stroke={blueGray7}
          top={margin.top}
          width={xMax}
          xOffset={xScale.bandwidth() / 2}
          xScale={xScale}
          yScale={yScale}
        />
        <Group
          left={margin.left}
          top={margin.top}>
          <BarStack
            color={colors}
            data={data}
            keys={keys}
            x={this.chartX}
            xScale={xScale}
            yScale={yScale}>
            {barStacks => {
              return barStacks.map(barStack => {
                return barStack.bars.map(bar => {
                  return (
                    <BarPart
                      bar={{
                        ...bar,
                        data: bar.bar.data,
                      }}
                      key={`bar-stack-${barStack.index}-${bar.index}`}
                      offset={(xScale.paddingInner() * xScale.step()) / 2}
                      onHideTooltip={this.props.onHideTooltip}
                      onShowTooltip={this.props.onShowTooltip}
                      tooltipGroupStartsAt={this.props.tooltipGroupStartsAt}
                      yMax={yMax}
                    />
                  );
                });
              });
            }}
          </BarStack>
        </Group>
        <AxisLeft
          hideAxisLine
          hideTicks
          left={margin.left}
          numTicks={4}
          scale={yScale}
          tickFormat={this.formatAsUSD}
          tickLabelProps={this.formatLeftAxisLabel}
          tickLength={4}
          tickStroke={blueGray6}
          tickValues={
            yMaxValue === 0 ? (
              [emptyStateYMaxInCents * (1 / 3), emptyStateYMaxInCents * (2 / 3), emptyStateYMaxInCents]
            ) : undefined
          }
          top={margin.top}
        />
        <AxisBottom
          hideTicks
          hideAxisLine
          stroke={blueGray6}
          left={margin.left}
          scale={xScale}
          tickFormat={this.formatAsMonth}
          tickLength={6}
          tickLabelProps={this.formatBottomAxisLabel}
          tickStroke={blueGray6}
          top={yMax + margin.top}
        />
      </svg>
    );
  }

  getStateFromProps = (props: Props) => {
    const keys = _.filter(_.keys(props.data[0]), k => k !== 'groupStartsAt');
    const totals = _.reduce(props.data, (ret: number[], cur) => {
      const t: number = _.reduce(keys, (rawDailyTotal, k) => {
        const dailyTotal = rawDailyTotal + +cur[k];
        return dailyTotal;
      }, 0);
      ret.push(t);
      return ret;
    }, []);
    const yMaxValue = Math.max(...totals);
    const data = _.map(props.data, d => ({
      ...d,
      max: yMaxValue || emptyStateYMaxInCents,
    }));
    const width = CHART_WIDTH_IN_PIXELS;
    const height = props.height;
    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    colors.domain(keys);
    xScale.domain(_.map(props.data, 'groupStartsAt'));
    xScale.rangeRound([0, xMax]);
    yScale.domain([0, yMaxValue || emptyStateYMaxInCents]);
    yScale.range([yMax, 0]);

    return {
      data,
      keys,
      xMax,
      yMax,
      yMaxValue,
    };
  }

  chartX = (data: Data): string => data.groupStartsAt;
  chartY = (data: Data): number => data.Received;

  formatAsUSD = (amountInCents: number) => `$${formatAsUSD(amountInCents / (100 * 1000))}K`;
  formatAsMonth = (isoString: string) => moment.utc(isoString).format('MMM YYYY');

  formatBottomAxisLabel = (value) => {
    let fill = blueGray5;
    if (moment.utc().startOf('month').isSame(value)) {
      fill = flipBlue;
    }

    return {
      dy: '3px',
      fill,
      fontSize: 12,
      fontWeight: 500,
      textAnchor: TextAnchors.Middle,
    };
  };
  formatLeftAxisLabel = () => ({
    dx: '-7px',
    dy: '0.33em',
    fill: blueGray5,
    fontSize: 12,
    fontWeight: 500,
    textAnchor: TextAnchors.End,
  });
}

export default withStyles(styles)(SVGChart);
