import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { StripeAccountHolderTypes } from '~tools/types/graphqlSchema';

import withStripe, { StripeProps } from '~tools/react/hocs/withStripe';
import withCreateStripeFinancialConnectionSession, {
  CreateStripeFinancialConnectionSessionProps,
} from '~tools/react/graphql/mutations/accounts/withCreateStripeFinancialConnectionSession';
import withAddPayoutAccount, {
  AddPayoutAccountProps,
} from '~tools/react/graphql/mutations/payoutAccounts/withAddPayoutAccount';

import AnimatedStepsFlow from '~tools/react/components/AnimatedStepsFlow';
import Button from '~tools/react/components/Button';
import Form from '~tools/react/components/Form';
import Input from '~tools/react/components/Form/components/Input';
import SkeletonBodyText from '~tools/react/components/SkeletonBodyText';
import SkeletonDisplayText from '~tools/react/components/SkeletonDisplayText';
import graphql, { compose } from '~tools/react/graphql';

import { OnUpdateListing } from '~web-manage/lib/common/scenes/ListingSetup/types';
import ListingSetupInfo from '~web-manage/lib/common/scenes/ListingSetup/components/ListingSetupInfoBlock';

import ManageListingPaymentsPayout from './components/ManageListingPaymentsPayout';
import query from './ListingSetupPayments.gql';

export interface PayoutAccount {
  id: string;
  defaultForCurrency: boolean;
  type: string;
  bankAccount: {
    id: string;
    accountHolderName: string;
    routingNumber: string;
    bankName: string;
  } | null;
  card: {
    id: string;
    brand: string;
    name: string;
    last4: string;
  } | null;
}

interface InputProps extends RouteComponentProps {
  defaultSecurityDepositInCents: number;
  rentInCents: number;
  isLoading: boolean;
  onUpdateListing: OnUpdateListing;
  payoutAccounts: PayoutAccount[];
  viewerFullName: string;
  refetch: () => Promise<any>;
}

type Props = InputProps &
  CreateStripeFinancialConnectionSessionProps &
  StripeProps &
  AddPayoutAccountProps;

// interface State {
//   isOpeningFinancialConnectionModal: boolean;
// }

class ListingSetupPayments extends Component<Props> { // , State> {
  // state = {
  //   isOpeningFinancialConnectionModal: false,
  // };

  handleSubmit = () => this.props.history.push('availability');

  render() {
    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit}>
          <Form.FormSection columns={Form.FormSection.enums.Columns.Two}>
            <Input
              isDisabled={this.props.isLoading}
              isRequired={true}
              label="Monthly rent"
              labelFormat={Input.enums.LabelFormats.Stacked}
              name="rentInCents"
              minAmount={1}
              onBlur={this.updateMonthlyRent}
              placeholder="2500"
              type={Input.enums.Types.Currency}
              value={this.props.rentInCents ? this.props.rentInCents / 100 : 0}
            />
            <Input
              isDisabled={this.props.isLoading}
              label="Security deposit"
              labelFormat={Input.enums.LabelFormats.Stacked}
              name="defaultSecurityDepositInCents"
              onBlur={this.updateSecurityDeposit}
              placeholder="2500"
              type={Input.enums.Types.Currency}
              value={
                this.props.defaultSecurityDepositInCents
                  ? this.props.defaultSecurityDepositInCents / 100
                  : 0
              }
            />
          </Form.FormSection>
          <ListingSetupInfo
            icon={ListingSetupInfo.enums.Icons.Deposit}
            description="File claims with your deposit"
            primaryLink={{
              path: 'https://caretaker.com/questions/how-do-security-deposits-work',
              label: 'Learn more',
            }}
          />
          {this.props.isLoading ? (
            <Fragment>
              <SkeletonDisplayText size={SkeletonDisplayText.enums.Sizes.Small} />
              <SkeletonBodyText lines={2} />
            </Fragment>
          ) : (
            <ManageListingPaymentsPayout
              viewerFullName={this.props.viewerFullName}
              primaryPayoutAccount={_.find(this.props.payoutAccounts, 'defaultForCurrency') || null}
              onClick={this.openFinancialConnectionModal}
            />
          )}
          <AnimatedStepsFlow.AnimatedStepsFlowStep.AnimatedStepsFlowStepFooter
            primaryAction={{
              label: 'Continue',
              type: Button.enums.Types.Submit,
            }}
            secondaryLink={{
              path: 'amenities',
            }}
          />
        </Form>
      </Fragment>
    );
  }

  openFinancialConnectionModal = async () => {
    const stripe = this.props.stripe;
    if (!stripe) return;

    // this.setState({ isOpeningFinancialConnectionModal: true });
    const clientSecret = await this.props.createStripeFinancialConnectionSession({
      accountHolderType: StripeAccountHolderTypes.ACCOUNT,
    });
    // this.setState({ isOpeningFinancialConnectionModal: false });
    const result = await stripe.collectBankAccountToken({
      clientSecret,
    });

    if (result.error) {
      console.log(result.error.message);
    } else if (result.token) {
      await this.props.addPayoutAccount(result.token.id);
      await this.props.refetch();
    }
  };

  updateMonthlyRent = (rent) => {
    if (!rent) return;

    const rentInCents = _.parseInt(rent) * 100;
    if (rentInCents !== this.props.rentInCents) {
      this.props.onUpdateListing({
        rentInCents,
        defaultSecurityDepositInCents: !this.props.rentInCents
          ? rentInCents
          : this.props.defaultSecurityDepositInCents,
      });
    }
  };

  updateSecurityDeposit = (deposit) =>
    deposit &&
    _.parseInt(deposit) * 100 !== this.props.defaultSecurityDepositInCents &&
    this.props.onUpdateListing({ defaultSecurityDepositInCents: _.parseInt(deposit) * 100 });
}

export default compose(
  withRouter,
  withStripe,
  withCreateStripeFinancialConnectionSession,
  withAddPayoutAccount,
  graphql(query, {
    options: (props) => ({
      variables: {
        listingUuid: props.listingUuid,
      },
    }),
    props: (props) => ({
      defaultSecurityDepositInCents: _.get(
        props.data.viewer,
        'listing.defaultSecurityDepositInCents',
        0,
      ),
      isLoading: props.data.loading,
      payoutAccounts: _.get(props.data.viewer, 'payoutAccounts', []),
      rentInCents: _.get(props.data.viewer, 'listing.rentInCents', 0),
      viewerFullName: _.get(props.data.viewer, 'fullName'),
      refetch: props.data.refetch,
    }),
  }),
)(ListingSetupPayments);
