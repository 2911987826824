import withMutation from '~tools/react/graphql/withMutation';
import { ValidatePropertyManagerContractEligibilityInput } from '~tools/types/graphqlSchema';

import validatePropertyManagerContractEligibility from './validatePropertyManagerContractEligibility.gql';

interface Data {
  validatePropertyManagerContractEligibility: boolean;
}

export interface ValidatePropertyManagerContractEligibilityProps {
  validatePropertyManagerContractEligibility: (input: ValidatePropertyManagerContractEligibilityInput) => Promise<boolean>;
}

export default withMutation<{}, Data, {}, ValidatePropertyManagerContractEligibilityProps>(validatePropertyManagerContractEligibility, {
  props: props => ({
    validatePropertyManagerContractEligibility: async (input: ValidatePropertyManagerContractEligibilityInput) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error validating property management contract eligibility.');

      return res.data.validatePropertyManagerContractEligibility;
    },
  }),
});
