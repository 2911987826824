// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._0N5fy0{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;max-width:450px;padding:24px;width:450px}", ""]);
// Exports
exports.locals = {
	"boost-amount-view": "_0N5fy0"
};
module.exports = exports;
