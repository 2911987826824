// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._ernbnz{overflow:hidden}._ernbnz._VYQn73{margin:auto 0;-webkit-transition:all .25s;transition:all .25s}._ernbnz ._drDdAN{position:relative;z-index:1}._ernbnz ._orGx7N{left:0;position:absolute;top:0;-webkit-transition:height .25s;transition:height .25s;width:100%;will-change:height;z-index:0}@media screen and (max-width:1127px){._ernbnz{-webkit-box-flex:1;flex-grow:1}}", ""]);
// Exports
exports.locals = {
	"animated-resizer": "_ernbnz",
	"animated-resizer--animate-height": "_VYQn73",
	"animated-resizer__position": "_drDdAN",
	"animated-resizer__header": "_orGx7N"
};
module.exports = exports;
