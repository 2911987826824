import _ from 'lodash';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';

import { formatAsUSD } from '~tools/utils/string';

import Card from '~tools/react/components/Card';
import Heading from '~tools/react/components/Heading';
import Row from '~tools/react/components/Row';
import SkeletonBlock from '~tools/react/components/SkeletonBlock';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import BarChartVisual from './components/BarChartVisual';
import ChartView from './components/ChartView';
import LeasingStatsView from './components/LeasingStatsView';

import withRentPaymentVolume, { RentPaymentVolumeProps, RentPaymentVolumeProvidedProps } from './graphql/withRentPaymentVolume';

type Props = RentPaymentVolumeProps & RentPaymentVolumeProvidedProps;

class RentPaymentsVolumeChart extends PureComponent<Props> {
  render() {
    const activeLeases = _.filter(
      this.props.leases,
      lease => moment(lease.endDate).tz(lease.addressUnit.address.timezone).isAfter(),
    );
    const overdueLeases = _.filter(
      activeLeases,
      lease =>
        !!lease.nextRentPaymentMonthStarting &&
        moment(lease.nextRentPaymentMonthStarting).tz(lease.addressUnit.address.timezone).isBefore(),
    );
    const orderedActiveLeases = _.orderBy(activeLeases, 'rentInCents');

    return (
      <Card
        header={this.props.header}>
        <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
          <ChartView>
            <Card.CardSection>
              <div style={{ width: '100%' }}>
                <BarChartVisual
                  data={this.props.rentPaymentVolume}
                  height={this.props.height}
                  isLoading={this.props.isLoading}
                />
              </div>
            </Card.CardSection>
          </ChartView>
          <LeasingStatsView>
            <Card.CardSection>
              <div
                style={{
                  position: 'absolute',
                  top: 24,
                  right: 32,
                }}>
                {this.props.isLoading ? (
                  <SkeletonBlock
                    height="15px"
                    width="72px"
                  />
                ) : (
                  <Text
                    color={Text.enums.Colors.Info}
                    size={Text.enums.Sizes.Small}
                    content="View leases"
                    link={{
                      path: '/leases',
                    }}
                    hasHoverStyles
                  />
                )}
              </div>
              <div style={{ whiteSpace: 'nowrap' }}>
                {this.props.isLoading ? (
                  <SkeletonBlock
                    height="18px"
                    width="40%"
                  />
                ) : (
                  <Text
                    color={Text.enums.Colors.Primary}
                    content="Current active leases"
                    size={Text.enums.Sizes.Medium}
                  />
                )}
                {this.props.isLoading ? (
                  <SkeletonBlock
                    margin={{
                      top: '8px',
                      bottom: '8px',
                    }}
                    height="24px"
                    width="30%"
                  />
                ) : (
                  <Heading
                    color={Heading.enums.Colors.Brand}
                    content={`${activeLeases.length}`}
                    font={Heading.enums.Fonts.Secondary}
                    size={Heading.enums.Sizes.Small}
                  />
                )}
                {this.props.isLoading ? (
                  <SkeletonBlock
                    height="18px"
                    width="50%"
                  />
                ) : (
                  <Text
                    color={Text.enums.Colors.Secondary}
                    content={`${activeLeases.length ? (_.sumBy(activeLeases, lease => moment(lease.endDate).tz(lease.addressUnit.address.timezone).diff(moment(), 'months', true)) / activeLeases.length).toFixed(2) : '-'} months remaining on avg.`}
                    size={Text.enums.Sizes.Small}
                  />
                )}
              </div>
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              <div style={{ whiteSpace: 'nowrap' }}>
                {this.props.isLoading ? (
                  <SkeletonBlock
                    height="18px"
                    width="40%"
                    margin={{
                      top: '10px',
                    }}
                  />
                ) : (
                  <Text
                    color={Text.enums.Colors.Primary}
                    content="Leases with overdue rent"
                    size={Text.enums.Sizes.Medium}
                  />
                )}
                {this.props.isLoading ? (
                  <SkeletonBlock
                    margin={{
                      top: '8px',
                      bottom: '8px',
                    }}
                    height="24px"
                    width="30%"
                  />
                ) : (
                  <Heading
                    color={overdueLeases.length ? Heading.enums.Colors.Error : Heading.enums.Colors.Primary}
                    content={`${overdueLeases.length}`}
                    font={Heading.enums.Fonts.Secondary}
                    size={Heading.enums.Sizes.Small}
                  />
                )}
                {this.props.isLoading ? (
                  <SkeletonBlock
                    height="18px"
                    width="50%"
                  />
                ) : (
                  <Text
                    color={Text.enums.Colors.Secondary}
                    content={`${overdueLeases.length ? (_.sumBy(overdueLeases, lease => moment().diff(moment(lease.nextRentPaymentMonthStarting).tz(lease.addressUnit.address.timezone), 'months', true)) / overdueLeases.length).toFixed(2) : '-'} months overdue on avg.`}
                    size={Text.enums.Sizes.Small}
                  />
                )}
              </div>
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              <div style={{ whiteSpace: 'nowrap' }}>
                {this.props.isLoading ? (
                  <SkeletonBlock
                    height="18px"
                    width="40%"
                    margin={{
                      top: '10px',
                    }}
                  />
                ) : (
                  <Text
                    color={Text.enums.Colors.Primary}
                    content="Average rent"
                    size={Text.enums.Sizes.Medium}
                  />
                )}
                {this.props.isLoading ? (
                  <SkeletonBlock
                    margin={{
                      top: '8px',
                      bottom: '8px',
                    }}
                    height="24px"
                    width="30%"
                  />
                ) : (
                  <Heading
                    color={Heading.enums.Colors.Brand}
                    content={activeLeases.length ? `$${formatAsUSD(_.sumBy(activeLeases, 'rentInCents') / (activeLeases.length * 100), true)}` : '-'}
                    font={Heading.enums.Fonts.Secondary}
                    size={Heading.enums.Sizes.Small}
                  />
                )}
                {this.props.isLoading ? (
                  <SkeletonBlock
                    height="18px"
                    width="50%"
                  />
                ) : (
                  <Text
                    color={Text.enums.Colors.Secondary}
                    content={`${orderedActiveLeases.length ? `$${formatAsUSD(orderedActiveLeases[Math.round(orderedActiveLeases.length / 2)]?.rentInCents / 100, true)}` : '-'} median`}
                    size={Text.enums.Sizes.Small}
                  />
                )}
              </div>`
            </Card.CardSection>
          </LeasingStatsView>
        </Row>
      </Card>
    );
  }
}

export default withRentPaymentVolume(RentPaymentsVolumeChart);
