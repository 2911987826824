// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._0N5YOK{display:grid;grid-gap:16px;grid-template-columns:1fr 1fr}._0N5YOK ._APRZKQ{border-radius:5px;overflow:hidden}", ""]);
// Exports
exports.locals = {
	"photo-grid": "_0N5YOK",
	"photo-grid__thumbnail": "_APRZKQ"
};
module.exports = exports;
