export enum Positions {
  Down = 'DOWN',
  Left = 'LEFT',
  Right = 'RIGHT',
  Up = 'UP',
}

export enum Lengths {
  Medium = 'MEDIUM',
}
