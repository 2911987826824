export enum Alignments {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT',
}

export enum Colors {
  Black = 'BLACK',
  Blue = 'BLUE',
  BrandAdvancedPlan = 'BRAND_ADVANCED_PLAN',
  BrandBasicPlan = 'BRAND_BASIC_PLAN',
  BrandEnterprisePlan = 'BRAND_ENTERPRISE_PLAN',
  BrandLeaseholderPlan = 'BRAND_LEASEHOLDER_PLAN',
  BrandLitePlan = 'BRAND_LITE_PLAN',
  Gray = 'GRAY',
  Green = 'GREEN',
  Purple = 'PURPLE',
  Red = 'RED',
  White = 'WHITE',
  Yellow = 'YELLOW',
}

export enum Directions {
  Left = 'LEFT',
  Right = 'RIGHT',
}

export enum Sizes {
  XLarge = 'XLARGE',
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
}
