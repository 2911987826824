import React, { Component, ComponentType, Fragment } from 'react';
import { SortableElement, SortableElementProps } from 'react-sortable-hoc';

import { compose } from '~tools/react/hocs/utils';
import withSignUploadRequest, { SignUploadRequestProps } from '~tools/react/graphql/mutations/uploads/withSignUploadRequest';
import withUpdatePhoto, { UpdatePhotoProps } from '~tools/react/graphql/mutations/photos/withUpdatePhoto';
import { AddPhotoToListingProps } from '~tools/react/graphql/mutations/photos/withAddPhotoToListing';

import PhotoEditorModal from '~tools/react/components/PhotoEditorModal';
import { Photo, Tag } from '~web-manage/lib/common/scenes/ListingSetup/scenes/ListingSetupPhotos/types';

import PhotoCard from './components/PhotoCard';

export interface Adjustment { [key: string]: string | number }

interface InputProps extends AddPhotoToListingProps {
  deletePhoto: (photo: Photo) => void;
  isLoading: boolean;
  isReordering: boolean;
  listingUuid: string;
  mediaTags: Tag[];
  photo?: Photo;
  position?: number;
  updateUuid: (photo: Photo) => void;
}

interface State {
  photo?: Photo;
  error: boolean,
  isEditorModalOpen: boolean,
  cacheTimestamp: number, // This is for updating the fetch of the image, if filters are applied
  progress: number,
}

type Props =
  InputProps &
  SignUploadRequestProps &
  UpdatePhotoProps;

class ListingSetupPhoto extends Component<Props, State> {
  ref = null;

  constructor(props) {
    super(props);
    this.state = {
      cacheTimestamp: new Date().getTime(),
      error: false,
      isEditorModalOpen: false,
      photo: undefined,
      progress: props.file ? 0 : 100,
    };

    if (props.photo && props.photo.file) {
      this.handleFileUpload();
    }
  }

  handleFileUpload = async () => {
    const photo = this.props.photo;
    if (!photo) return;

    try {
      const file = photo.file;
      if (!file) return;

      const aws = await this.props.signUploadRequest({ filename: file.name });
      const xhr = new XMLHttpRequest();
      const uploadForm = new FormData();

      uploadForm.append('key', aws.key);
      uploadForm.append('AWSAccessKeyId', aws.accessKeyId);
      uploadForm.append('acl', aws.acl);
      uploadForm.append('policy', aws.policy);
      uploadForm.append('signature', aws.signature);
      uploadForm.append('Content-Type', aws.contentType);
      uploadForm.append('file', file);

      xhr.addEventListener('load', async () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          const p = await this.props.addPhotoToListing({
            height: photo.height,
            key: aws.key,
            listingUuid: this.props.listingUuid,
            position: this.props.position || 0,
            width: photo.width,
          });
          this.setState({ photo: p }, () => this.props.updateUuid(p));
        } else {
          this.setState({ progress: 100, error: true });
        }
      });

      xhr.addEventListener('error', () => {
        this.setState({ progress: 100, error: true });
      });

      xhr.open('POST', aws.bucketUrl);

      xhr.upload.addEventListener('progress', (e: ProgressEvent) => {
        this.setState({ progress: (e.loaded / e.total) * 100, error: false });
      });

      xhr.send(uploadForm);
    } catch (e) {
      this.setState({ progress: 100, error: true });
    }
  };

  handleDeletePhoto = () => {
    const photo = this.props.photo;
    if (!photo) return;

    this.setState({ isEditorModalOpen: false });
    if (this.state.photo) {
      this.props.deletePhoto({ uuid: this.state.photo.uuid, ...photo });
    } else {
      this.props.deletePhoto(photo);
    }
  }

  render() {
    const photo = this.props.photo;
    if (this.props.isLoading || !photo) {
      return (
        <PhotoCard isLoading />
      );
    }

    const hasError = this.state.error && this.state.progress === 100;
    const formattedUrl = this.props.isReordering ? `${photo.url}?fit=min&auto=enhance,compress&h=100&w=738` :
      `${photo.url}?fit=min&auto=enhance,compress&h=100&w=138&${this.state.cacheTimestamp}`;
    return (
      <Fragment>
        <PhotoEditorModal
          deletePhoto={this.handleDeletePhoto}
          isOpen={this.state.isEditorModalOpen}
          mediaTags={this.props.mediaTags}
          onClose={this.toggleEditor}
          selectedPhoto={this.state.photo || photo}
          updatePhoto={this.props.updatePhoto}
        />
        <PhotoCard
          bindRef={this.bindRef}
          hasError={hasError}
          isReordering={this.props.isReordering}
          onClick={hasError ? this.handleFileUpload : this.toggleEditor}
          position={this.props.position}
          progress={this.state.progress}
          url={photo.file ? photo.url : formattedUrl}
        />
      </Fragment>
    );
  }

  toggleEditor = refreshCache =>
    this.setState({
      cacheTimestamp: refreshCache ? new Date().getTime() : this.state.cacheTimestamp,
      isEditorModalOpen: !this.state.isEditorModalOpen,
    });

  bindRef = (c) => {
    if (c) {
      this.ref = c;
    }
  };
}

export default SortableElement(compose(
  withSignUploadRequest,
  withUpdatePhoto,
)(ListingSetupPhoto)) as ComponentType<InputProps & SortableElementProps>;
