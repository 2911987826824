import React, { PureComponent } from 'react';

import * as enums from './enums';

interface Props {
  quantity?: number;
  size: enums.Sizes;
}

const sizeToPx = {
  [enums.Sizes.XXXSmall]: 4,
  [enums.Sizes.XXSmall]: 8,
  [enums.Sizes.XSmall]: 12,
  [enums.Sizes.Small]: 16,
  [enums.Sizes.Medium]: 24,
  [enums.Sizes.Large]: 32,
  [enums.Sizes.XLarge]: 40,
  [enums.Sizes.XXLarge]: 48,
  [enums.Sizes.XXXLarge]: 56,
};

class HorizontalSpacing extends PureComponent<Props> {
  static enums = enums;

  render() {
    const quantity = this.props.quantity ?? 1;
    const size = sizeToPx[this.props.size];
    const width = size * quantity;

    return (
      <div
        style={{
          maxWidth: width,
          minWidth: width,
          width,
        }}
      />
    );
  }
}

export default HorizontalSpacing;
