import _ from 'lodash';

import { canUseDOM } from '~tools/utils/environment';

import { AnalyticsIdentifyInput } from './types';
import BaseAnalytics from './base';

class BugsnagAnalytics extends BaseAnalytics {
  identify = (uuid: string) => {
    if (!canUseDOM) return;

    (window as any).bugsnagClient.user = {
      id: uuid,
      uuid,
    };
  }

  set = (rawProps: AnalyticsIdentifyInput) => {
    if (!canUseDOM) return;

    (window as any).bugsnagClient.user = _.mapKeys(rawProps, (value, key) => {
      if (key !== 'id') return key;
      return key;
    });
  }
}

export default BugsnagAnalytics;
