import { Events } from './enums';
import { AnalyticsIdentifyInput, AnalyticsTrackInput } from './types';

class BaseAnalytics {
  identify = (uuid: string): void => {} // eslint-disable-line @typescript-eslint/no-unused-vars
  page = (route: string): void => {} // eslint-disable-line @typescript-eslint/no-unused-vars
  set = (properties: AnalyticsIdentifyInput): void => {} // eslint-disable-line @typescript-eslint/no-unused-vars
  track = (event: Events, properties?: AnalyticsTrackInput): void => {} // eslint-disable-line @typescript-eslint/no-unused-vars
}

export default BaseAnalytics;
