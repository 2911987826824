import classNames from 'classnames';
import React, { Component, ReactNode } from 'react';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './AnimatedResizerSection.scss';

import * as enums from '../../enums';

interface Props {
  children: ReactNode;
  isLeft?: boolean;
  isRight?: boolean;
  isShown?: boolean;
  onSizeChange?: (opts: { height: number, width: number }) => void;
  width?: enums.Widths | null;
}

interface State {
  isRelativelyPositioned: boolean;
}

class AnimatedResizerSection extends Component<Props, State> {
  form?: HTMLFormElement;
  resizeSensor?: ResizeSensor;
  timeout?: NodeJS.Timeout;

  static defaultProps = {
    isLeft: false,
    isRight: false,
    isShown: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isRelativelyPositioned: !!this.props.isShown,
    };
  }

  componentDidMount() {
    if (this.props.isShown) {
      // Short timeout to attempt to avoid "flash resize" from unloaded loadable components
      this.timeout = setTimeout(() => {
        this.initializeResizeSensor();
      }, 100);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.resizeSensor && this.props.isShown && !nextProps.isShown) {
      this.resizeSensor.detach();
      delete this.resizeSensor;
    }
  }

  componentDidUpdate() {
    if (this.props.isShown && !this.resizeSensor) this.initializeResizeSensor();
  }

  componentWillUnmount() {
    if (this.resizeSensor) {
      this.resizeSensor.detach();
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
      delete this.timeout;
    }
  }

  render() {
    return (
      <div
        className={classNames({
          [styles['animated-resizer-section']]: true,
          [styles['animated-resizer-section--full-width']]: this.props.width === enums.Widths.Full,
          [styles['animated-resizer-section--left']]: this.props.isLeft,
          [styles['animated-resizer-section--shown']]: this.props.isShown,
          [styles['animated-resizer-section--right']]: this.props.isRight,
        })}
        style={{ position: this.state.isRelativelyPositioned ? 'relative' : undefined }}
        ref={this.assignRef}>
        {this.props.children}
      </div>
    );
  }

  assignRef = (element) => { this.form = element; };

  initializeResizeSensor = () => {
    if (this.form && this.props.onSizeChange) {
      this.props.onSizeChange({
        height: this.form.offsetHeight,
        width: this.form.offsetWidth,
      });
      if (this.state.isRelativelyPositioned) {
        this.timeout = setTimeout(() => this.setState({ isRelativelyPositioned: false }), 0);
      }

      this.resizeSensor = new ResizeSensor(this.form, () => {
        if (this.props.isShown && this.form && this.props.onSizeChange) {
          this.props.onSizeChange({
            height: this.form.offsetHeight,
            width: this.form.offsetWidth,
          });
        }
      });
    }
  };
}

export default withStyles(styles)(AnimatedResizerSection);
