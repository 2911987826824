// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._prWCAA{-webkit-box-align:center;align-items:center;color:#aab7c9;display:-webkit-box;display:flex;-webkit-box-flex:1;flex:1;font-size:14px;text-align:left}._prWCAA:last-of-type{padding-right:0}", ""]);
// Exports
exports.locals = {
	"table__th": "_prWCAA"
};
module.exports = exports;
