// @flow

import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './ManageListingNextStepsSubleasePreview.scss';

type Props = {|
  subleasePreviewUrl: ?string,
|};

function ManageListingNextStepsSubleasePreview(props: Props) {
  return <iframe styleName="sublease-preview" srcDoc={props.subleasePreviewUrl} />;
}

export default withStyles(styles)(ManageListingNextStepsSubleasePreview);
