import withMutation from '~tools/react/graphql/withMutation';
import { FetchOrCreateConditionInspectionInput } from '~tools/types/graphqlSchema';

import FetchOrCreateConditionInspectionMutation from './fetchOrCreateConditionInspection.gql';

interface ConditionInspection {
  // TODO: Anything else you want?
  uuid: string;
}

interface Data {
  fetchOrCreateConditionInspection: ConditionInspection;
}

interface Variables {
  input: FetchOrCreateConditionInspectionInput;
}

export interface FetchOrCreateConditionInspectionProps {
  fetchOrCreateConditionInspection: (input: FetchOrCreateConditionInspectionInput) => Promise<ConditionInspection>;
}

export default withMutation<{}, Data, Variables, FetchOrCreateConditionInspectionProps>(FetchOrCreateConditionInspectionMutation, {
  props: props => ({
    fetchOrCreateConditionInspection: async (input: FetchOrCreateConditionInspectionInput) => {
      const res = await props.mutate({
        variables: { input },
      });
      if (!res || !res.data) throw new Error('Error creating condition inspection.');

      return res.data.fetchOrCreateConditionInspection;
    },
  }),
});
