// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

import withStyles from '~tools/react/hocs/withStyles';
import Check from '~tools/svgs/icons/interface/check.svg';

import styles from './ImportBuildingPhotosModalPhoto.scss';

import type { Photo } from '../../../../../../types';

type Props = {
  isAdded?: boolean,
  isActive?: boolean,
  photo: Photo,
  onClick?: Photo => void,
};

class ImportBuildingPhotosModalPhoto extends Component<Props> {
  handleOnClick = () => {
    if (this.props.onClick) this.props.onClick(this.props.photo);
  }

  render() {
    if (this.props.isActive) {
      return (
        <div styleName="modal-photo-active">
          <img alt="" src={this.props.photo.url} />
        </div>
      );
    }
    return (
      <button
        onClick={this.handleOnClick}
        styleName={classNames({ 'modal-photo': true, 'modal-photo--added': this.props.isAdded })}>
        <img alt="" src={this.props.photo.url} />
        {this.props.isAdded ? <Check /> : null}
      </button>
    );
  }
}

export default withStyles(styles)(ImportBuildingPhotosModalPhoto);
