import withMutation from '~tools/react/graphql/withMutation';
import { LoginInputType } from '~tools/types/graphqlSchema';

import Login from './login.gql';

export interface Account {
  email: string;
  facebookToken: string | null;
  fullName: string;
  isActive: boolean;
  isPhoneNumberVerified: boolean;
  phoneNumber: string | null;
  photoUrl: string;
  status: string;
  token: string;
  uuid: string;
}

interface Data {
  login: Account;
}

interface Variables {
  input: LoginInputType;
}

export interface LoginProps {
  login: (args: LoginInputType) => Promise<Account>;
}

export default withMutation<{}, Data, Variables, LoginProps>(Login, {
  props: props => ({
    login: async (input: LoginInputType) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error logging in.');

      return res.data.login;
    },
  }),
});
