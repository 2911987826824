// @flow

export const BOOKMARKED_LISTING = 'Bookmarked Listing';
export const CREATED_LISTING = 'Created Listing';
export const DOWNLOAD_APP_CLICKED = 'Clicked Download App Banner';
export const DOWNLOAD_APP_EXITED = 'Exited Download App Banner';
export const POSTED_LISTING = 'Posted Listing';
export const UNBOOKMARKED_LISTING = 'Unbookmarked Listing';

// Sublet agreement lander
export const COMPLETED_TEMPLATE_STEP = 'Completed customize sublet agreement step';
export const DOWNLOADED_CUSTOM_SUBLET_AGREEMENT = 'Downloaded sublet agreement';

// Pay rent lander //// Standalone leases
export const RENT_LANDER_TO_CREATE_LEASE_CONVERSION = 'Submitted pay rent form from lander';
export const SENT_RENT_PAYMENT = 'Sent rent payment';

// Search Filter Alerts
export const TOGGLED_SEARCH_FILTER_ALERT = 'Toggled Search Filter Alert';

// Boosts
export const ENTERED_BOOST_MINIMUM_AMOUNT = 'Entered Boost Minimum Amount';
export const PAUSED_BOOST_CAMPAIGN = 'Paused Boost Campaign';
export const PURCHASED_BOOST = 'Purchased Boost';
export const VIEWED_BOOST_INSIGHTS_MODAL = 'Viewed Boost Insights Modal';
export const VIEWED_BOOST_PAYMENT_FORM = 'Viewed Boost Payment Form';
export const VIEWED_BOOST_MODAL = 'Viewed Boost Modal';
export const VIEWED_INSIGHTS_CAMPAIGN_DETAILS = 'Viewed Insights Campaign Details';
export const VIEWED_PAUSE_BOOST_CONFIRMATION_MODAL = 'Viewed Pause Boost Confirmation Modal';

// Application Flow - Renter Side (In order of flow)
export const CREATED_CONVERSATION = 'Created Conversation';
export const SENT_MESSAGE = 'Sent Message';
export const VIEWED_APPLICATION_PREVIEW_MODAL = 'Viewed Application Preview Modal';
export const VIEWED_EMPLOYMENT_INCOME_VERIFICATION_MODAL = 'Viewed Employment Income Verification Modal';
export const CLOSED_EMPLOYMENT_INCOME_VERIFICATION_MODAL = 'Closed Employment Income Verification Modal';
export const COMPLETED_EMPLOYMENT_INCOME_VERIFICATION_MODAL = 'Completed Employment Income Verification Modal';
export const VIEWED_COMPLETE_APPLICATION_MODAL = 'Viewed Complete Application Modal'; // Submit application confirmation + prompts to put down upfront rent/deposit
export const VERIFIED_EMPLOYMENT_INCOME = 'Verified Employment Income';
export const SUBMITTED_FLIP_APPLICATION = 'Submitted Caretaker Application'; // 'SubmitApplication' is the FB Standard event
export const WITHDREW_FLIP_APPLICATION = 'Withdrew Caretaker Application';
export const AUTHORIZED_UPFRONT_RENT_DEPOSIT = 'Authorized Upfront Rent Deposit'; // Puts down upfront rent/deposit
export const AUTHORIZED_SECURITY_DEPOSIT = 'Authorized Security Deposit';
export const VIEWED_RELEASE_UPFRONT_RENT_DEPOSIT_MODAL = 'Viewed Release Upfront Rent Deposit Modal';
export const RELEASED_UPFRONT_RENT_DEPOSIT = 'Released Upfront Rent Deposit';
export const ENABLED_AUTOPAY_ON_LEASE = 'Enabled Autopay On Lease';
export const DISABLED_AUTOPAY_ON_LEASE = 'Disabled Autopay On Lease';

// Application Flow - Lister Side (In order of flow)
export const REQUEST_APPLICATION = 'Requested Application';
export const VIEWED_EDIT_UPFRONT_RENT_DEPOSIT_MODAL = 'Viewed Edit Upfront Rent Deposit Modal';
export const UPDATED_UPFRONT_RENT_DEPOSIT_AMOUNT = 'Updated Upfront Rent Deposit Amount';
export const UPDATED_SECURITY_DEPOSIT_AMOUNT = 'Updated Security Deposit Amount';
export const REQUEST_SOCIAL = 'Requested Social';
export const REQUEST_EMPLOYMENT = 'Requested Employment';
export const REQUEST_CREDIT_SCORE = 'Requested Credit Score';
export const ACCEPTED_APPLICATION = 'Accepted Application';
export const REJECTED_APPLICATION = 'Rejected Application';
export const REQUESTED_UPFRONT_RENT_DEPOSIT = 'Requested Upfront Rent Deposit';
export const SKIPPED_UPFRONT_RENT_DEPOSIT_REQUEST = 'Skipped Upfront Rent Deposit Request';
export const SKIPPED_SECURITY_DEPOSIT_REQUEST = 'Skipped Security Deposit Request';
export const ACCEPTED_UPFRONT_RENT_DEPOSIT = 'Accepted Upfront Rent Deposit Request';
export const CREATED_LEASE = 'Created Lease';

// Application Flow - Both Renter + Lister
export const SIGNED_SUBLEASE = 'Signed Sublease';

// Standard FB events
export const ADD_TO_WISHLIST = 'AddToWishlist'; // Bookmark listing
export const COMPLETED_REGISTRATION = 'CompleteRegistration'; // Signed up
export const INITIATE_CHECKOUT = 'InitiateCheckout'; // Clicked send message button
export const PURCHASE = 'Purchase'; // Created First Conversation
export const VIEW_CONTENT = 'ViewContent'; // Viewed Listing


export const FACEBOOK_STANDARD_EVENTS = [
  ADD_TO_WISHLIST,
  COMPLETED_REGISTRATION,
  INITIATE_CHECKOUT,
  PURCHASE,
  SUBMITTED_FLIP_APPLICATION,
  VIEW_CONTENT,
];
