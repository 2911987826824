import { AccessInstructionEntityTypes, UpdateAccessInstructionInput } from '~tools/types/graphqlSchema';

import UpdateAccessInstructionMutation from '~tools/react/graphql/mutations/accessInstructions/updateAccessInstruction.gql';
import withMutation from '~tools/react/graphql/withMutation';

interface AccessInstruction {
  uuid: string;
  notes: string | null;
  photos: {
    uuid: string,
    url: string,
  }[];
}

interface Data {
  updateAccessInstruction: AccessInstruction;
}

interface Variables {
  input: UpdateAccessInstructionInput;
  uuid: string;
}

export { AccessInstructionEntityTypes };

export interface UpdateAccessInstructionProps {
  updateAccessInstruction: (uuid: string, input: UpdateAccessInstructionInput) => Promise<AccessInstruction>;
}

export default withMutation<{}, Data, Variables, UpdateAccessInstructionProps>(UpdateAccessInstructionMutation, {
  props: props => ({
    updateAccessInstruction: async (uuid: string, input: UpdateAccessInstructionInput) => {
      const res = await props.mutate({
        variables: {
          input,
          uuid,
        },
      });
      if (!res || !res.data) throw new Error('Error updating access instruction.');

      return res.data.updateAccessInstruction;
    },
  }),
});
