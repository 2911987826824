import loadable from '@loadable/component';
import _ from 'lodash';

import InteractiveMapControlsZoom from './components/InteractiveMapControlsZoom';

const LoadableInteractiveMapControls = loadable(() => import(/* webpackChunkName: "interactive-map-controls" */'./InteractiveMapControls'));

const InteractiveMapControls = _.assign(LoadableInteractiveMapControls, {
  InteractiveMapControlsZoom,

  // Legacy support
  MapViewControlsZoom: InteractiveMapControlsZoom,
});

export default InteractiveMapControls;
