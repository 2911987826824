import React, { Component } from 'react';
import _ from 'lodash';

import withStyles from '~tools/react/hocs/withStyles';
import * as enums from '~tools/react/components/PhotoEditorModal/enums';

import styles from './PhotoEditorModalFilterSlider.scss';
import PhotoEditorModalFilterView from '../PhotoEditorModalFilterView';

interface Props {
  defaultValue: number;
  filter: enums.Filters;
  isActive: boolean;
  label: string;
  maxValue: number;
  minValue: number;
  onChange: (query: string, val: string | number) => void;
  onClickTab: (tab: enums.Filters) => void;
  primaryAction: () => void;
  step: number;
  value?: string | number;
}

interface State {
  value: string | number;
}

const filterToQuery = {
  [enums.Filters.Brightness]: 'bri',
  [enums.Filters.Contrast]: 'con',
  [enums.Filters.Rotate]: 'rot',
  [enums.Filters.Saturation]: 'sat',
  [enums.Filters.Shadow]: 'shad',
  [enums.Filters.Highlight]: 'high',
};

class PhotoEditorModalFilterSlider extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || props.defaultValue,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value || nextProps.defaultValue });
    }
  }

  handleMouseUp = () => this.props.onChange(filterToQuery[this.props.filter], this.state.value);

  handleChange = e => this.setState({ value: _.parseInt(e.target.value) });

  handleSecondaryAction = () => this.setState({ value: this.props.defaultValue }, this.handleMouseUp);

  render() {
    return (
      <PhotoEditorModalFilterView
        primaryAction={this.props.primaryAction}
        isEdited={this.state.value !== this.props.defaultValue}
        secondaryAction={this.handleSecondaryAction}
        onClickTab={this.props.onClickTab}
        isActive={this.props.isActive}
        filter={this.props.filter}>
        <div styleName="filter-slider">
          <label>
            {this.state.value}
            <span>{this.props.label}</span>
          </label>
          <input
            max={this.props.maxValue}
            min={this.props.minValue}
            onChange={this.handleChange}
            onMouseUp={this.handleMouseUp}
            step={this.props.step}
            type="range"
            value={this.state.value}
          />
        </div>
      </PhotoEditorModalFilterView>
    );
  }
}

export default withStyles(styles)(PhotoEditorModalFilterSlider);
