import { StripePaymentMethodTypes } from '~tools/types/graphqlSchema';
import withMutation from '~tools/react/graphql/withMutation';

import SetDefaultPaymentMethodMutation from './setDefaultPaymentMethod.gql';

interface UsBankAccount {
  accountHolderType: string;
  accountType: string;
  bankName: string;
  last4: string;
  routingNumber: string;
  statusDetails: {
    blocked?: {
      networkCode: string;
      reason: string;
    };
  };
}

interface Card {
  brand: string;
  dynamicLast4: string;
  expMonth: number;
  expYear: number;
  last4: string;
}

interface CardPaymentMethod {
  id: string;
  isDefault: boolean;
  type: StripePaymentMethodTypes.CARD;
  card: Card;
}

interface BankPaymentMethod {
  id: string;
  isDefault: boolean;
  type: StripePaymentMethodTypes.US_BANK_ACCOUNT;
  usBankAccount: UsBankAccount;
}

export type PaymentMethod = CardPaymentMethod | BankPaymentMethod;

interface Data {
  setDefaultPaymentMethod: PaymentMethod;
}

interface Variables {
  paymentMethodId: string;
}

export interface SetDefaultPaymentMethodProps {
  setDefaultPaymentMethod: (paymentMethodId: string) => Promise<PaymentMethod>;
}
export default withMutation<{}, Data, Variables, SetDefaultPaymentMethodProps>(SetDefaultPaymentMethodMutation, {
  props: props => ({
    setDefaultPaymentMethod: async (paymentMethodId: string) => {
      const res = await props.mutate({
        variables: {
          paymentMethodId,
        },
      });
      if (!res || !res.data) throw new Error('Error setting default payment method.');

      return res.data.setDefaultPaymentMethod;
    },
  }),
});
