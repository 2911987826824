import classNames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';

import Spinner from '~tools/react/components/utility/Spinner';
import withStyles from '~tools/react/hocs/withStyles';
import ChevronDown from '~tools/svgs/icons/interface/chevron-down.svg';
import ChevronRight from '~tools/svgs/icons/interface/chevron-right.svg';
import Cross from '~tools/svgs/icons/interface/cross.svg';

import InlinePaymentMethodVisual from '~web-manage/lib/common/components/InlinePaymentMethodVisual';

import * as enums from './enums';

import styles from './PayoutAccountRow.scss';

const SymbolIcons = {
  [enums.Icons.Cross]: Cross,
  [enums.Icons.ChevronRight]: ChevronRight,
  [enums.Icons.ChevronDown]: ChevronDown,
};

interface PayoutAccount {
  id: string;
  bankAccount: {
    accountName?: string;
    bankName: string;
    last4: string;
  };
}

interface Props {
  icon: enums.Icons;
  isDisabled: boolean;
  // This can also be Thunk since handleDropdownToggle doesn't take any args
  onClick: (uuid: string) => Promise<void> | void;
  payoutAccount: PayoutAccount;
}

interface State {
  isLoading: boolean;
}

class PayoutAccountRow extends Component<Props, State> {
  _isMounted: boolean;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    // Renamed to _isMounted per https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
    // eslint-disable-next-line no-underscore-dangle
    this._isMounted = false;
  }

  componentDidMount() {
    // eslint-disable-next-line no-underscore-dangle
    this._isMounted = true;
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-underscore-dangle
    this._isMounted = false;
  }

  handleClick = async () => {
    this.setState({ isLoading: true });
    await this.props.onClick(this.props.payoutAccount.id);
    // eslint-disable-next-line no-underscore-dangle
    if (this._isMounted) this.setState({ isLoading: false });
  };

  render() {
    if (!this.props.payoutAccount) return null;

    const bankAccount = this.props.payoutAccount.bankAccount;
    const Icon = SymbolIcons[this.props.icon] ? SymbolIcons[this.props.icon] : null;

    return (
      <button
        onClick={this.handleClick}
        disabled={this.props.isDisabled}
        className={classNames({
          [styles['payout-account-row']]: true,
          [styles['payout-account-row--disabled']]: this.props.isDisabled && !this.state.isLoading,
        })}
      >
        <InlinePaymentMethodVisual
          type={InlinePaymentMethodVisual.enums.Types.US_BANK_ACCOUNT}
          usBankAccount={{
            accountType: bankAccount.accountName,
            bankName: bankAccount.bankName,
            last4: bankAccount.last4,
          }}
        />
        <div styleName="payout-account-row__icon">
          {this.state.isLoading ? (
            <Spinner styleName="payout-account-row__icon__spinner" />
          ) : (
            <Icon styleName="payout-account-row__icon__chevron" />
          )}
        </div>
      </button>
    );
  }
}

export default _.assign(withStyles(styles)(PayoutAccountRow), { enums });
