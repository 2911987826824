import withMutation from '~tools/react/graphql/withMutation';
import { PhoneNumberTypes } from '~tools/types/graphqlSchema';

import FetchOrCreatePhoneNumberMutation from './fetchOrCreatePhoneNumber.gql';

interface PhoneNumber {
  phoneNumber: string;
  type: PhoneNumberTypes;
  uuid: string;
}

interface FetchOrCreatePhoneNumberInput {
  phoneNumber: string;
  type: PhoneNumberTypes;
}

interface Data {
  fetchOrCreatePhoneNumber: PhoneNumber;
}

interface Variables {
  input: FetchOrCreatePhoneNumberInput;
}

export interface FetchOrCreatePhoneNumberProps {
  fetchOrCreatePhoneNumber: (input: FetchOrCreatePhoneNumberInput) => Promise<PhoneNumber>;
}

export default withMutation<{}, Data, Variables, FetchOrCreatePhoneNumberProps>(FetchOrCreatePhoneNumberMutation, {
  props: props => ({
    fetchOrCreatePhoneNumber: async (input) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating phone number.');

      return res.data.fetchOrCreatePhoneNumber;
    }
  }),
});
