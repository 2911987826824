import React, { Fragment, PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import { extractMessageFromError } from '~tools/utils/error';

import Alert from '~tools/react/components/Alert';
import Button from '~tools/react/components/Button';
import Confetti from '~tools/react/components/Confetti';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import ThemedModal from '~tools/react/components/ThemedModal';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import * as enums from './enums';

import styles from './ConfirmationModal.scss';

interface Action {
  color?: enums.ButtonColors;
  icon?: enums.ButtonIcons;
  isLoading?: boolean;
  label: string;
  onClick: () => void | Promise<void>;
}

interface Props {
  cancelAction?: Action;
  confirmAction: Action;
  description: string;
  isLoading?: boolean;
  isOpen: boolean;
  isSuccess?: boolean;
  onClose: () => void;
  title: string;
}

interface State {
  errorMessage?: string;
  isLoading: boolean;
}

class ConfirmationModal extends PureComponent<Props> {
  state: State = {
    isLoading: false,
  };

  handleConfirm = async () => {
    this.setState({ isLoading: true });
    try {
      await this.props.confirmAction.onClick();
      this.setState({
        errorMessage: undefined,
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        errorMessage: extractMessageFromError(err),
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <ThemedModal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        width={ThemedModal.enums.Widths.Medium}
        title={this.props.title}>
        <ThemedModal.ThemedModalSection>
          {this.state.errorMessage ? (
            <Fragment>
              <Alert
                color={Alert.enums.Colors.Red}
                title={this.state.errorMessage}
              />
              <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
            </Fragment>
          ) : null}
          <Text
            content={this.props.description}
            isMarkdown
            size={Text.enums.Sizes.Large}
          />
          {this.props.isSuccess ? <Confetti styleName="confetti" /> : null}
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
          <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
            {this.props.cancelAction ? (
              <Fragment>
                <Button
                  align={Button.enums.Alignments.Right}
                  color={this.props.cancelAction.color || Button.enums.Colors.Gray}
                  icon={this.props.cancelAction.icon}
                  isLoading={this.props.cancelAction.isLoading}
                  label={this.props.cancelAction.label}
                  onClick={this.props.cancelAction.onClick}
                  size={Button.enums.Sizes.Small}
                  style={Button.enums.Styles.Outline}
                />
                <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
              </Fragment>
            ) : null}
            <Button
              align={!this.props.cancelAction ? Button.enums.Alignments.Right : undefined}
              color={this.props.confirmAction.color || Button.enums.Colors.Blue}
              icon={this.props.confirmAction.icon}
              isLoading={this.props.confirmAction.isLoading || this.props.isLoading || this.state.isLoading}
              label={this.props.confirmAction.label}
              onClick={this.handleConfirm}
              size={Button.enums.Sizes.Small}
            />
          </Row>
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

export default withStyles(styles)(ConfirmationModal);
