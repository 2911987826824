import CreateDocumentMutation from '~tools/react/graphql/mutations/documents/createDocument.gql';
import withMutation from '~tools/react/graphql/withMutation';

import { CreateDocumentInput, DocumentTags } from '~tools/types/graphqlSchema';

export interface Document {
  createdAt: string;
  filename: string;
  fileType: string;
  fullyExecutedAt: string | null;
  tag: string;
  updatedAt: string;
  uuid: string;
}

interface Data {
  createDocument: Document;
}

interface Variables {
  input: CreateDocumentInput;
}

export { DocumentTags };

export interface CreateDocumentProps {
  createDocument: (input: CreateDocumentInput) => Promise<Document>;
}

export default withMutation<{}, Data, Variables, CreateDocumentProps>(CreateDocumentMutation, {
  props: props => ({
    createDocument: async (input) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating document.');

      return res.data.createDocument;
    },
  }),
});
