import _ from 'lodash';

import withMutation from '~tools/react/graphql/withMutation';
import { CreatePaymentSubscriptionInput } from '~tools/types/graphqlSchema';

import { PaymentSubscriptionPlanPricesInCents } from '~tools/constants/workspace';
import analytics, { Events } from '~tools/utils/analytics';

import CreatePaymentSubscriptionMutation from './createPaymentSubscription.gql';

interface Response {
  uuid: string;
}

interface Data {
  createPaymentSubscription: Response;
}

interface Variables {
  input: CreatePaymentSubscriptionInput;
}

export interface CreatePaymentSubscriptionProps {
  createPaymentSubscription: (input: CreatePaymentSubscriptionInput) => Promise<Response>;
}

export default withMutation<{}, Data, Variables, CreatePaymentSubscriptionProps>(CreatePaymentSubscriptionMutation, {
  props: props => ({
    createPaymentSubscription: async (input: CreatePaymentSubscriptionInput) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating payment subscription.');

      const value = PaymentSubscriptionPlanPricesInCents[input.plan];
      analytics.track(Events.CreatePaymentSubscription, {
        plan: _.startCase(_.toLower(input.plan)),
        label: _.startCase(_.toLower(input.plan)),
        value,
      });

      return res.data.createPaymentSubscription;
    },
  }),
});
