import React, { Fragment, PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import _ from 'lodash';
import queryString from 'query-string';

import Globals from '~web-manage/lib/common/globals';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import { pageToCursor } from '~tools/react/graphql/utils';

import { TransactionStatuses } from '~tools/types/graphqlSchema';

import { downloadFileUrl } from '~tools/utils/download';
import { formatAsUSD } from '~tools/utils/string';
import { formatTimestamp } from '~tools/utils/time';

import Card from '~tools/react/components/Card';
import Tag from '~tools/react/components/Tag';
import Text from '~tools/react/components/Text';
import { Colors as TagColors } from '~tools/react/components/Tag/enums';

import Table from '~tools/react/components/Table';

import RentPayoutModal from './containers/RentPayoutModal';

import query from './RentPaymentsCard.gql';

const NUMBER_OF_RENT_PAYMENTS_TO_FETCH = 5;

function getTagColorForStatus(status: TransactionStatuses): TagColors | undefined {
  switch (status) {
    case TransactionStatuses.SUCCEEDED:
    case TransactionStatuses.PAID:
      return TagColors.Green;
    case TransactionStatuses.CANCELED:
    case TransactionStatuses.FAILED:
      return TagColors.Red;
    case TransactionStatuses.IN_TRANSIT:
    case TransactionStatuses.PENDING:
    default:
      return TagColors.Blue;
  }
}

interface Transaction {
  payout: {
    status: TransactionStatuses;
    uuid: string;
  } | null;
  refundedAt: string | null;
  status: TransactionStatuses;
  uuid: string;
}

interface RentPayment {
  amountInCents: number;
  createdAt: string;
  creditTransaction: Transaction | null;
  debitTransaction: Transaction;
  monthEnding: string | null;
  monthStarting: string | null;
  uuid: string;
}

interface InputProps {
  leaseUuid: string;
}

type Props =
  InputProps &
  RouteComponentProps &
  QueryProps;

interface State {
  isExportEnabled: boolean;
  isRentPayoutModalOpen: boolean;
  selectedRentPaymentUuid?: string;
}

class RentPaymentsCard extends PureComponent<Props, State> {
  state: State = {
    isExportEnabled: true,
    isRentPayoutModalOpen: false,
  };

  handleExportRentPayments = () => {
    if (!this.state.isExportEnabled) return;
    this.setState({ isExportEnabled: false });

    const leaseUuid = this.props.leaseUuid;
    if (!leaseUuid) return;

    downloadFileUrl(`${Globals.API_CORE_DOMAIN}/user-exports/rent-payments?leaseUuid=${leaseUuid}`);

    // Leave the button disabled for a few seconds so the user doesn't double download the file.
    setTimeout(() => this.setState({ isExportEnabled: true }), 5000);
  };

  handleCloseRentPayoutModal = () => this.setState({
    isRentPayoutModalOpen: false,
    selectedRentPaymentUuid: undefined,
  });
  handleOpenRentPaymentModal = (selectedRentPaymentUuid?: string) => {
    if (!selectedRentPaymentUuid) return;

    this.setState({
      selectedRentPaymentUuid,
      isRentPayoutModalOpen: true,
    });
  };

  render() {
    return (
      <Fragment>
        <Card
          header={{
            title: 'Rent payments',
            actions: [{
              label: this.state.isExportEnabled ? 'Export' : 'Exported!',
              icon: this.state.isExportEnabled ? Card.enums.ActionIcons.Download : Card.enums.ActionIcons.Check,
              isDisabled: !this.state.isExportEnabled,
              onClick: this.handleExportRentPayments,
            }],
          }}
          overflow={Card.enums.OverflowValues.Hidden}>
          <Table>
            <Table.TableHead>
              <Table.TableRow>
                <Table.TableHeader width={Table.TableHeader.enums.Widths.MINIMIZED}>Amount</Table.TableHeader>
                <Table.TableHeader width={Table.TableHeader.enums.Widths.MINIMIZED} />
                <Table.TableHeader width={Table.TableHeader.enums.Widths.AUTO}>Period</Table.TableHeader>
                <Table.TableHeader width={Table.TableHeader.enums.Widths.AUTO}>Payment Status</Table.TableHeader>
                <Table.TableHeader width={Table.TableHeader.enums.Widths.AUTO}>Payout Status</Table.TableHeader>
                <Table.TableHeader>Initiated</Table.TableHeader>
                <Table.TableHeader />
              </Table.TableRow>
            </Table.TableHead>
            <Table.TableBody>
              {this.props.rentPayments.length > 0 ? (
                _.map(this.props.rentPayments, rentPayment => (
                  <Table.TableRow
                    key={rentPayment.uuid}
                    id={rentPayment.uuid}
                    onClick={this.handleOpenRentPaymentModal}>
                    <Table.TableData width={Table.TableData.enums.Widths.MINIMIZED}>
                      <Text
                        content={`*$${formatAsUSD(rentPayment.amountInCents / 100, true)}*`}
                        size={Text.enums.Sizes.Medium}
                        isMarkdown
                      />
                    </Table.TableData>
                    <Table.TableData width={Table.TableData.enums.Widths.MINIMIZED}>
                      <Text
                        content="USD"
                        size={Text.enums.Sizes.Small}
                        color={Text.enums.Colors.Secondary}
                        shouldWrap={false}
                        isMarkdown
                      />
                    </Table.TableData>
                    <Table.TableData width={Table.TableHeader.enums.Widths.AUTO}>
                      {(rentPayment.monthStarting && rentPayment.monthEnding) ? (
                        <Text
                          content={`${formatTimestamp(rentPayment.monthStarting, 'MMM D, YYYY')} - ${formatTimestamp(rentPayment.monthEnding, 'MMM D, YYYY')}`}
                          size={Text.enums.Sizes.Small}
                          shouldWrap={false}
                          isMarkdown
                        />
                      ) : (
                        <Tag label="Upfront rent" />
                      )}
                    </Table.TableData>
                    <Table.TableData width={Table.TableHeader.enums.Widths.AUTO}>
                      <Tag
                        label={rentPayment.debitTransaction.refundedAt ? 'REFUNDED' : rentPayment.debitTransaction.status}
                        color={
                          rentPayment.debitTransaction.refundedAt ?
                            Tag.enums.Colors.Red :
                            getTagColorForStatus(rentPayment.debitTransaction.status)
                        }
                      />
                    </Table.TableData>
                    <Table.TableData width={Table.TableHeader.enums.Widths.AUTO}>
                      <Tag
                        label={this.getPayoutStatus(rentPayment.creditTransaction)}
                        color={
                          rentPayment.creditTransaction?.payout ?
                            getTagColorForStatus(rentPayment.creditTransaction.payout.status) :
                            undefined
                        }
                        icon={
                          rentPayment.creditTransaction?.payout ?
                            this.getPayoutIcon(rentPayment.creditTransaction.payout.status) :
                            undefined
                        }
                      />
                    </Table.TableData>
                    <Table.TableData>
                      <Text
                        content={formatTimestamp(rentPayment.createdAt, 'MMM D, YYYY, h:mm A')}
                        size={Text.enums.Sizes.Small}
                      />
                    </Table.TableData>
                  </Table.TableRow>
                ))
              ) : (
                <Table.TableEmptyState
                  label="You haven't received any payments yet."
                />
              )}
              <Table.TablePaginator
                startCursor={this.props.rentPaymentsPageInfo?.startCursor ?? ''}
                endCursor={this.props.rentPaymentsPageInfo?.endCursor ?? ''}
                resultsPerPage={NUMBER_OF_RENT_PAYMENTS_TO_FETCH}
                totalResults={this.props.rentPaymentsTotal}
              />
            </Table.TableBody>
          </Table>
        </Card>
        <RentPayoutModal
          isOpen={this.state.isRentPayoutModalOpen}
          onClose={this.handleCloseRentPayoutModal}
          leaseUuid={this.props.leaseUuid}
          rentPaymentUuid={this.state.selectedRentPaymentUuid ?? null}
        />
      </Fragment>
    );
  }

  getPayoutStatus = (creditTransaction: Transaction | null) => {
    if (creditTransaction?.payout?.status === TransactionStatuses.PENDING) {
      return _.startCase(TransactionStatuses.IN_TRANSIT);
    }

    if (creditTransaction?.payout) return _.startCase(creditTransaction.payout.status);
    if (creditTransaction) return creditTransaction.status;

    return TransactionStatuses.PENDING;
  }

  getPayoutIcon = (status: TransactionStatuses) => {
    switch (status) {
      case TransactionStatuses.IN_TRANSIT:
      case TransactionStatuses.PENDING: {
        return Tag.enums.Icons.Clock;
      }
      case TransactionStatuses.PAID:
      case TransactionStatuses.SUCCEEDED: {
        return Tag.enums.Icons.Check;
      }
      case TransactionStatuses.FAILED:
      case TransactionStatuses.CANCELED: {
        return Tag.enums.Icons.Cross;
      }
    }
  }
}

interface Response {
  viewer: {
    lease: {
      rentPayments: RentPayment[];
      rentPaymentsPageInfo: {
        endCursor: string | null;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor: string | null;
      };
      rentPaymentsTotal: number;
      uuid: string;
    } | null;
    uuid: string;
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  rentPayments: RentPayment[];
  rentPaymentsPageInfo: {
    endCursor: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string | null;
  } | null;
  rentPaymentsTotal: number;
}

interface Variables {
  uuid: string;
}

export default compose(
  withRouter,
  withQuery<InputProps & RouteComponentProps, Response, Variables, QueryProps>(query, {
    options: props => {
      const qs = queryString.parse(props.location.search);

      const page = _.parseInt(typeof qs.page === 'string' ? qs.page : '1') ?? undefined;
      const after = page ? pageToCursor(page, NUMBER_OF_RENT_PAYMENTS_TO_FETCH) : undefined;

      return {
        variables: {
          after,
          first: NUMBER_OF_RENT_PAYMENTS_TO_FETCH,
          leaseUuid: props.leaseUuid,
        },
        ssr: true,
      };
    },
    props: props => ({
      isLoading: props.loading,
      rentPayments: props.data?.viewer?.lease?.rentPayments ?? [],
      rentPaymentsPageInfo: props.data?.viewer?.lease?.rentPaymentsPageInfo ?? null,
      rentPaymentsTotal: props.data?.viewer?.lease?.rentPaymentsTotal ?? 0,
    }),
  }),
)(RentPaymentsCard);
