import React, { Fragment, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  name: string;
}

function AnimatedStepsFlowSection(props: Props) {
  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
}

export default AnimatedStepsFlowSection;
