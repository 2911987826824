import _ from 'lodash';
import React, { Fragment, PureComponent } from 'react';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withDeletePhoto, { DeletePhotoProps } from '~tools/react/graphql/mutations/photos/withDeletePhoto';
import withUpdateListing, { UpdateListingProps } from '~tools/react/graphql/mutations/listings/withUpdateListing';

import Alert from '~tools/react/components/Alert';
import Button from '~tools/react/components/Button';
import Center from '~tools/react/components/Center';
import Text from '~tools/react/components/Text';
import ThemedModal from '~tools/react/components/ThemedModal';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import EditablePhoto, { Photo, Tag } from './containers/EditablePhoto';

import PhotoListView from './components/PhotoListView';
import ReorderInstructionsOverlay from './components/ReorderInstructionsOverlay';
import UploadButton from './components/UploadButton';

import NoPhotosSVG from './svgs/no-photos.svg';

import query from './UpdatePhotosModal.gql';

interface InputProps {
  isOpen: boolean;
  listingUuid: string;
  onClose: () => void;
}

type Props = InputProps & QueryProps & DeletePhotoProps & UpdateListingProps;

interface State {
  error?: {
    description: string;
    title: string;
  };
  isReordering: boolean;
  isReorderInstructionsOverlayOpen: boolean;
  photos: Photo[];
}

class UpdatePhotosModal extends PureComponent<Props, State> {
  containerRef?: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      isReordering: false,
      isReorderInstructionsOverlayOpen: false,
      photos: props.photos || [],
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isLoading && !this.props.isLoading) {
      this.setState({ photos: this.props.photos });
    }
  }

  handleFileUpload = async ({
    file,
    imageUrl,
    height,
    width,
  }) => {
    this.setState({
      photos: _.concat(this.state.photos, [{
        file,
        height,
        width,
        position: this.state.photos.length,
        url: imageUrl,
      }]),
    });
  };

  handleSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;
    let photos = _.orderBy(this.state.photos, 'position');
    photos.splice(newIndex, 0, photos.splice(oldIndex, 1)[0]);
    photos = _.map(photos, (p, index) => ({ ...p, position: index, uuid: p.uuid }));
    this.setState({ photos });
    const photosToSave = _.filter(_.map(photos, (p, index) => ({ position: index, uuid: p.uuid })), 'uuid');
    await this.props.updateListing(this.props.listingUuid, { photos: photosToSave });
  }

  handleDeletePhoto = async (photo) => {
    const orderedPhotos = _.orderBy(this.state.photos, 'position');
    const indexOfPhoto = _.indexOf(orderedPhotos, photo);
    const photos = _.compact(_.map(orderedPhotos, (p, index) => {
      if (index === indexOfPhoto) return null;
      if (index > indexOfPhoto) {
        return {
          ...p,
          position: index - indexOfPhoto - 1,
        };
      }
      return {
        ...p,
        position: index,
      };
    }));
    this.setState({ photos });
    await this.props.deletePhoto(photo.uuid);
    const photosToSave = _.filter(_.map(photos, p => _.pick(p, 'uuid', 'position')), 'uuid');
    this.props.updateListing(this.props.listingUuid, { photos: photosToSave });
  }

  handleUpdateUuid = (photo: Photo) => {
    this.setState({
      photos: _.map(this.state.photos, (p) => {
        if (p.position === photo.position && !p.uuid) {
          return photo;
        }
        return p;
      }),
    });
  };

  handleError = (error: {
    description: string;
    title: string;
  }) => {
    this.setState({ error });
  };

  handleCloseReordering = () => this.setState({
    isReordering: false,
  });

  handleCloseReorderInstructionsOverlay = () => this.setState({
    isReorderInstructionsOverlayOpen: false,
  });
  handleOpenReorderInstructionsOverlay = () => this.setState({
    isReordering: true,
    isReorderInstructionsOverlayOpen: true,
  });

  render() {
    const hasNoPhotos = !this.props.isLoading && this.state.photos.length === 0;
    const photos = _.orderBy(this.state.photos, 'position');

    return (
      <ThemedModal
        width={ThemedModal.enums.Widths.Medium}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        onNavigateBack={this.state.isReordering ? this.handleCloseReordering : undefined}
        title={this.state.isReordering ? 'Reordering' : 'Photos'}>
        {hasNoPhotos ? (
          <ThemedModal.ThemedModalSection backgroundColor={ThemedModal.ThemedModalSection.enums.BackgroundColors.Gray}>
            <Center>
              <NoPhotosSVG style={{ width: '32px', height: '32px' }} />
            </Center>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
            <Text
              align={Text.enums.Align.Center}
              color={Text.enums.Colors.Secondary}
              content="No photos added yet"
            />
          </ThemedModal.ThemedModalSection>
        ) : null}
        <ThemedModal.ThemedModalSection>
          {this.state.isReorderInstructionsOverlayOpen ? (
            <ReorderInstructionsOverlay onClick={this.handleCloseReorderInstructionsOverlay} />
          ) : null}
          {/* Using react sortable HOC so prop names must match spec */}
          <PhotoListView
            bindRef={this.getContainerRef}
            helperContainer={this.containerRef}
            lockAxis="y"
            lockToContainerEdges={true}
            onSortEnd={this.handleSortEnd}
            isReordering={this.state.isReordering}>
            {this.props.isLoading ? _.times(3, x => (
              <EditablePhoto
                index={x}
                isLoading
                key={x}
                listingUuid={this.props.listingUuid}
                mediaTags={[]}
                position={x}
                photo={{
                  height: 0,
                  position: x,
                  url: '',
                  uuid: '',
                  width: 0,
                }}
              />
            )) : null}
            {_.map(photos, (p, index) => (
              <EditablePhoto
                index={index}
                isReordering={this.state.isReordering}
                key={p.uuid || p.url}
                listingUuid={this.props.listingUuid}
                mediaTags={this.props.mediaTags}
                onDelete={this.handleDeletePhoto}
                onSuccess={this.handleUpdateUuid}
                photo={p}
                position={index}
              />
            ))}
          </PhotoListView>
        </ThemedModal.ThemedModalSection>
        {this.state.isReordering ? (
          <ThemedModal.ThemedModalSection>
            <Button
              label="Save"
              onClick={this.handleCloseReordering}
              style={UploadButton.enums.Styles.Outline}
              width={UploadButton.enums.Widths.Full}
            />
          </ThemedModal.ThemedModalSection>
        ) : (
          <ThemedModal.ThemedModalSection>
            {this.state.error ? (
              <Fragment>
                <Alert
                  color={Alert.enums.Colors.Red}
                  title={this.state.error.title}
                  description={this.state.error.description}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
              </Fragment>
            ) : null}
            <UploadButton
              label="Upload photos"
              onError={this.handleError}
              onSubmit={this.handleFileUpload}
              style={UploadButton.enums.Styles.Outline}
              width={UploadButton.enums.Widths.Full}
            />
            {!hasNoPhotos && this.state.photos.length > 1 ? (
              <Fragment>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                <Button
                  color={Button.enums.Colors.White}
                  label="Reorder photos"
                  onClick={this.handleOpenReorderInstructionsOverlay}
                  style={Button.enums.Styles.Secondary}
                  width={Button.enums.Widths.Full}
                />
              </Fragment>
            ) : null}
          </ThemedModal.ThemedModalSection>
        )}
      </ThemedModal>
    );
  }

  getContainerRef = (elem?: HTMLDivElement) => { this.containerRef = elem; };
}

interface Listing {
  photos: Photo[];
  updatedAt: string;
  uuid: string;
}

interface Viewer {
  listing: Listing;
  uuid: string;
}

interface Response {
  mediaTags: Tag[];
  viewer: Viewer;
}

interface QueryProps {
  isLoading: boolean;
  mediaTags: Tag[];
  photos: Photo[];
}

export default compose(
  withDeletePhoto,
  withUpdateListing,
  withQuery<InputProps, Response, {}, QueryProps>(query, {
    options: (props) => ({
      variables: {
        listingUuid: props.listingUuid,
      },
      skip: !props.isOpen,
    }),
    props: (props) => ({
      isLoading: props.loading,
      mediaTags: props.data?.mediaTags || [],
      photos: props.data?.viewer.listing.photos || [],
    }),
  }),
)(UpdatePhotosModal);
