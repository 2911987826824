import React, { Component, ReactNode, SyntheticEvent } from 'react';
import classNames from 'classnames';

import { compose } from '~tools/react/hocs/utils';
import withStyles from '~tools/react/hocs/withStyles';
import withDevice from '~tools/react/hocs/withDevice';
import Lottie from '~tools/react/components/utility/Lottie';

import { Types as ButtonTypes } from '~tools/react/components/Button/enums';
import Heading from '~tools/react/components/Heading';
import Text from '~tools/react/components/Text';

import { Check } from '~web-core/lib/common/svgs/icons/interface';

import AnimatedStepsFlowStepLocked from './components/AnimatedStepsFlowStepLocked';
import AnimatedStepsFlowStepFooter from './components/AnimatedStepsFlowStepFooter';

import House from './json/house.json';
import Beds from './json/beds.json';
import Dog from './json/dog.json';
import Money from './json/money.json';
import Box from './json/box.json';
import Paper from './json/paper.json';
import Photos from './json/photos.json';
import Star from './json/star.json';

import { Colors, Icons } from '../../enums';
import styles from './AnimatedStepsFlowStep.scss';

const IconsToSvg = {
  [Icons.BedLights]: Beds,
  [Icons.Box]: Box,
  [Icons.Dog]: Dog,
  [Icons.HouseMarker]: House,
  [Icons.MoneyBags]: Money,
  [Icons.Paper]: Paper,
  [Icons.Photos]: Photos,
  [Icons.Star]: Star,
};

interface Action {
  onClick: (e: SyntheticEvent<EventTarget>) => void;
  label: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  type?: ButtonTypes;
}

interface LinkType {
  path: string;
  label: string;
  type?: ButtonTypes;
}

interface Props {
  children: ReactNode;
  color: Colors;
  icon?: Icons;
  isCompleted?: boolean;
  isDisabled?: boolean;
  isMobile?: boolean;
  hasFooter: boolean;
  lockedStepAction?: {
    label: string;
    path: string;
  },
  primaryAction?: Action;
  primaryLink?: LinkType;
  secondaryAction?: Action;
  secondaryLink?: LinkType;
  subtitle: string;
  title: string;
}

interface State {
  isVisible: boolean;
}

class AnimatedStepsFlowStep extends Component<Props, State> {
  timeout: NodeJS.Timeout | null;
  static AnimatedStepsFlowStepFooter = AnimatedStepsFlowStepFooter;
  static defaultProps = {
    color: Colors.Blue,
    hasFooter: true,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isVisible: !!props.isMobile,
    };

    this.timeout = null;
  }

  componentDidMount() {
    if (!this.props.isMobile) {
      this.setState({ isVisible: false });
      this.timeout = setTimeout(() => this.setState({ isVisible: true }), 200);
    }
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  render() {
    if (this.props.isDisabled) {
      return (
        <AnimatedStepsFlowStepLocked
          lockedStepAction={this.props.lockedStepAction}
        />
      );
    }

    return (
      <div styleName="step">
        <div styleName="step__header">
          {this.props.icon ? (
            <div styleName="step__header__icon">
              {this.state.isVisible ? (
                <Lottie
                  width={40}
                  height={40}
                  options={{
                    loop: false,
                    autoplay: true,
                    prerender: true,
                    // @ts-ignore Have a look at the type for animationData
                    animationData: IconsToSvg[this.props.icon],
                  }}
                />
              ) : null}
            </div>
          ) : null}
          <div
            styleName={classNames({
              step__header__text: true,
              'step__header__text--complete': this.props.isCompleted,
            })}>
            <div>
              <Heading content={this.props.title} size={Heading.enums.Sizes.Large} />
              <Text content={this.props.subtitle} />
            </div>
            {this.props.isCompleted ? <span><Check /></span> : null}
          </div>
          <hr />
        </div>
        {this.props.children}
        {this.props.hasFooter ? (
          <AnimatedStepsFlowStepFooter
            color={this.props.color}
            primaryAction={this.props.primaryAction}
            primaryLink={this.props.primaryLink}
            secondaryAction={this.props.secondaryAction}
            secondaryLink={this.props.secondaryLink}
          />
        ) : null}
      </div>
    );
  }
}

export default compose(
  withDevice,
  withStyles(styles),
)(AnimatedStepsFlowStep);
