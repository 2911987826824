export enum Align {
  Center = 'CENTER',
  Right = 'RIGHT',
}

export enum Colors {
  BrandAdvancedPlan = 'BRAND_ADVANCED_PLAN',
  BrandBasicPlan = 'BRAND_BASIC_PLAN',
  BrandEnterprisePlan = 'BRAND_ENTERPRISE_PLAN',
  BrandLeaseholderPlan = 'BRAND_LEASEHOLDER_PLAN',
  BrandLitePlan = 'BRAND_LITE_PLAN',
  Error = 'ERROR',
  Info = 'INFO',
  Inherit = 'INHERIT',
  Orange = 'ORANGE',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Success = 'SUCCESS',
  Warning = 'WARNING',
}

export enum Decorations {
  DottedUnderline = 'DOTTED_UNDERLINE',
  Underline = 'UNDERLINE',
}

export enum Fonts {
  Primary = 'PRIMARY',
  Monospace = 'MONOSPACE',
}

export enum OverflowValues {
  Ellipsis = 'ELLIPSIS',
}

export enum Sizes {
  XLarge = 'XLARGE',
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
  XSmall = 'XSMALL',
}

export enum Styles {
  Negative = 'NEGATIVE',
}

export enum Tags {
  P = 'P',
  Span = 'SPAN',
}

export enum Weights {
  Medium = 'MEDIUM',
}
