// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._rrEsrE{min-height:150px;-webkit-transition:all .25s;transition:all .25s}._rrEsrE ._YXrE9M{color:#8492a6;font-size:14px;font-weight:300;margin-bottom:16px;padding:0 24px}._rrEsrE ._YXrE9M div{display:inline-block;width:15%}._rrEsrE ._YXrE9M div:first-of-type{width:25%}@media screen and (max-width:767px){._rrEsrE ._YXrE9M{display:none}}._rrEsrE ._2dAHjd{max-height:630px;overflow-y:scroll}", ""]);
// Exports
exports.locals = {
	"boost-insights": "_rrEsrE",
	"boost-insights__header": "_YXrE9M",
	"boost-insights__body": "_2dAHjd"
};
module.exports = exports;
