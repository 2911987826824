import _ from 'lodash';
import { ApolloQueryResult } from 'apollo-client';
import { RouteComponentProps } from 'react-router';
import React, { PureComponent } from 'react';

import { formatAsUSD } from '~tools/utils/string';
import { formatTimestamp } from '~tools/utils/time';

import { BalanceTransactionTypes, IssueServiceTypes } from '~tools/types/graphqlSchema';
import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Card from '~tools/react/components/Card';
import Center from '~tools/react/components/Center';
import Heading from '~tools/react/components/Heading';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import Spinner from '~tools/react/components/Spinner';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import withQuery from '~tools/react/graphql/withQuery';

import DataGrid from '~web-manage/lib/common/components/DataGrid';
import DataRow from '~web-manage/lib/common/components/DataRow';
import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import { IssuedCardAuthorization } from './types';
import query from './Authorization.gql';

type InputProps =
  RouteComponentProps<{ issuedCardAuthorizationUuid: string }>;

type Props =
  QueryProps &
  InputProps;

class Authorization extends PureComponent<Props> {
  render() {
    const issuedCardAuthorization = this.props.issuedCardAuthorization;
    if (!issuedCardAuthorization || this.props.isLoading) {
      return (
        <ManageStage isLoading={this.props.isLoading}>
          <Card>
            <Card.CardSection>
              <Center>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
                <Spinner />
              </Center>
            </Card.CardSection>
          </Card>
        </ManageStage>
      );
    }

    const balanceTransaction = _.find(
      issuedCardAuthorization.balanceTransactions,
      bt => bt.type === BalanceTransactionTypes.ISSUING_AUTHORIZATION_HOLD,
    );
    const amountStr = `$${formatAsUSD(Math.abs(issuedCardAuthorization.amountInCents) / 100, true)}`;
    const issue = issuedCardAuthorization?.issuedCard?.issue;

    return (
      <ManageStage>
        <Breadcrumbs
          items={[
            { path: '/finances', label: 'Finances' },
            { path: '/expenses', label: 'Expenses' },
            { label: 'Authorization' },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
        <Card>
          <Card.CardSection>
            <Heading
              content="Authorization"
              font={Heading.enums.Fonts.Secondary}
              size={Heading.enums.Sizes.XXXSmall}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
            <Heading
              content={`*${amountStr}* USD`}
              font={Heading.enums.Fonts.Secondary}
              isMarkdown
              size={Heading.enums.Sizes.Medium}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
            <HorizontalRule />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
            <DataRow>
              {balanceTransaction ? (
                <DataRow.DataRowItem
                  label="Balance"
                  link={{
                    path: `/expenses`,
                  }}
                  description={
                    `${
                      balanceTransaction.balance.availableAmountInCents < 0 ? '- ' : ''
                    }$${
                      formatAsUSD(Math.abs(balanceTransaction.balance.availableAmountInCents) / 100, true)
                    } USD`
                  }
                />
              ) : null}
              {issue ? (
                <DataRow.DataRowItem
                  label="Issue"
                  link={{
                    path: `/issues/${issue.uuid}`,
                  }}
                  description={
                    issue.serviceType === IssueServiceTypes.OTHER ?
                      'Issue' :
                      `${_.capitalize(issue.serviceType)} issue`
                  }
                />
              ) : null}
            </DataRow>
          </Card.CardSection>
          <Card.CardSection title="Details">
            <DataGrid>
              <DataGrid.DataGridColumn>
                <DataGrid.DataGridRow
                  label="Created on"
                  description={formatTimestamp(issuedCardAuthorization.createdAt, 'MMM DD, YYYY')}
                />
                <DataGrid.DataGridRow
                  label="Description"
                  description={issuedCardAuthorization.statementDescriptor || undefined}
                />
              </DataGrid.DataGridColumn>
            </DataGrid>
          </Card.CardSection>
        </Card>
      </ManageStage>
    );
  }
}

interface Response {
  viewer: {
    issuedCardAuthorization: IssuedCardAuthorization | null;
    uuid: string;
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  refetch: () => Promise<ApolloQueryResult<Response>>;
  issuedCardAuthorization: IssuedCardAuthorization | null;
}

export default withQuery<InputProps, Response, {}, QueryProps>(query, {
  options: ({ match }) => ({
    variables: {
      issuedCardAuthorizationUuid: match.params.issuedCardAuthorizationUuid,
    },
    ssr: true,
  }),
  props: props => ({
    isLoading: props.loading,
    issuedCardAuthorization: props.data?.viewer?.issuedCardAuthorization ?? null,
    refetch: props.refetch,
  }),
})(Authorization);
