// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._YXrtGK{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;height:100vh;-webkit-box-pack:center;justify-content:center}._YXrtGK._orGVnx{height:800px}._J2VXnZ{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;height:100vh;-webkit-box-pack:center;justify-content:center;margin:auto;width:600px}._J2VXnZ._99vXn9{height:800px}", ""]);
// Exports
exports.locals = {
	"loading": "_YXrtGK",
	"loading--container": "_orGVnx",
	"error": "_J2VXnZ",
	"error--container": "_99vXn9"
};
module.exports = exports;
