import { ApolloQueryResult } from 'apollo-client';
import React, { PureComponent } from 'react';

import { PaymentSubscriptionPlans } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';

import withAuth, { AuthProps } from '~tools/react/hocs/withAuth';

import Redirect from '~tools/react/components/utility/Redirect';

import query from './ExistingUnitsRedirect.gql';

type Props =
  InputProps &
  QueryProps;

class ExistingUnitsRedirect extends PureComponent<Props> {
  render() {
    if (this.props.isLoading) return null;
    if (!this.props.paymentSubscription && this.props.propertyManagerContracts.length === 0) return null;

    return (
      <Redirect to="/units" />
    );
  }
}

interface PaymentSubscription {
  activatedAt: string;
  createdAt: string;
  plan: PaymentSubscriptionPlans;
  uuid: string;
}

interface PropertyManagerContract {
  uuid: string;
}

interface Response {
  viewer: {
    paymentSubscription: PaymentSubscription | null;
    propertyManagerContracts: PropertyManagerContract[];
    uuid: string;
  } | null;
}

type InputProps = AuthProps;

interface QueryProps {
  isLoading: boolean;
  paymentSubscription: PaymentSubscription | null;
  propertyManagerContracts: PropertyManagerContract[];
  refetch: () => Promise<ApolloQueryResult<Response>>;
}

export default compose(
  withAuth,
  withQuery<InputProps, Response, {}, QueryProps>(query, {
    options: (props) => ({
      fetchPolicy: 'network-only',
      skip: !props.isLoggedIn,
      ssr: false,
    }),
    props: (props) => ({
      isLoading: props.loading,
      propertyManagerContracts: props.data?.viewer?.propertyManagerContracts ?? [],
      paymentSubscription: props.data?.viewer?.paymentSubscription ?? null,
      refetch: props.refetch,
    }),
  }),
)(ExistingUnitsRedirect);
