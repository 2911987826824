import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './TableEmptyState.scss';

interface Props {
  label: string;
}

function TableEmptyState(props: Props) {
  return (
    <tr styleName="table-empty-state">
      <td colSpan={8}>
        <p>
          {props.label}
        </p>
      </td>
    </tr>
  );
}

export default withStyles(styles)(TableEmptyState);
