export enum Columns {
  Two = 'TWO',
  Three = 'THREE',
  Four = 'FOUR',
  Five = 'FIVE',
}

export enum Spacing {
  Medium = 'MEDIUM',
  Small = 'SMALL',
  None = 'NONE',
}
