export { Icons as ButtonIcons } from '~tools/react/components/Button/enums';

export enum Colors {
  Blue = 'BLUE',
  Gray = 'GRAY',
  Green = 'GREEN',
  Red = 'RED',
  Yellow = 'YELLOW',
}

export enum Icons {
  Alert = 'ALERT',
  Check = 'CHECK',
  Clock = 'CLOCK',
  Info = 'INFO',
}

export enum Heights {
  Medium = 'MEDIUM',
  Small = 'SMALL',
}
