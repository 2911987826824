import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';

import GenericLoadingVisual from '~tools/react/components/GenericLoadingVisual';
import Card from '~tools/react/components/Card';

import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import Text from '~tools/react/components/Text';
import Row from '~tools/react/components/Row';
import Heading from '~tools/react/components/Heading';
import Button from '~tools/react/components/Button';

import Stage from '~web-core/lib/common/stages/Stage';
import { formatShortAddress } from '~web-manage/lib/common/utils/addressUnit';

import AuthorHeader from '../AuthorHeader';
import { ConditionInspection } from '../../types';
import InspectionRoom from './components/InspectionRoom';

interface Props {
  conditionInspection: ConditionInspection | null;
  onExitPrintMode: () => void;
}

class InspectionPrintable extends Component<Props, {}> {
  debouncedShowPrintPrompt = _.debounce(() => {
    window.print();
  }, 1000);

  render() {
    const conditionInspection = this.props.conditionInspection;

    if (!conditionInspection) {
      return (
        <Stage>
          <GenericLoadingVisual />
        </Stage>
      );
    }

    const addressUnitRooms = _.sortBy(conditionInspection.addressUnit.addressUnitRooms, r => r.name);

    return (
      <Stage
        hasNavigation={false}
        hasAppBanner={false}
        hasFooter={false}
        isProtected={true}>
        <Card>
          <Card.CardSection>
            <Row
              flexBehavior={Row.enums.FlexBehaviors.Default}
              verticalAlignment={Row.enums.VerticalAlignments.Center}>
              <Heading
                content="Inspection report"
                font={Heading.enums.Fonts.Secondary}
                size={Heading.enums.Sizes.Small}
              />
              <Button
                align={Button.enums.Alignments.Right}
                color={Button.enums.Colors.Gray}
                label="Exit print mode"
                onClick={this.props.onExitPrintMode}
                size={Button.enums.Sizes.Small}
                style={Button.enums.Styles.Outline}
              />
            </Row>
            <Text
              content={`${formatShortAddress(conditionInspection.addressUnit)} · Submitted ${moment(conditionInspection.createdAt).format('MMM DD, YYYY')}`}
              size={Text.enums.Sizes.Small}
            />
          </Card.CardSection>
          {!conditionInspection.lockedAt ? (
            <Card.CardAlert
              color={Card.CardAlert.enums.Colors.Blue}
              icon={Card.CardAlert.enums.Icons.Alert}
              message="This inspection is in progress, the issues reported below could change until the inspection is finalized."
            />
          ) : null}
          <AuthorHeader
            name={conditionInspection.inspectorAccount.fullName}
            photoUrl={conditionInspection.inspectorAccount.photoUrl}
          />
          <Card.CardSection>
            {addressUnitRooms.map(room => {
              const reports = conditionInspection.conditionReports.filter(r => r.addressUnitRoom.uuid === room.uuid);

              return (
                <div key={room.uuid}>
                  <InspectionRoom
                    onImageLoad={this.debouncedShowPrintPrompt}
                    room={room}
                    reports={reports}
                  />
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
                </div>
              );
            })}
          </Card.CardSection>
        </Card>
      </Stage>
    );
  }
}

export default InspectionPrintable;
