import FetchOrCreateAddressUnitMutation from '~tools/react/graphql/mutations/addressUnits/fetchOrCreateAddressUnit.gql';
import withMutation from '~tools/react/graphql/withMutation';

export interface AddressUnit {
  address: {
    city: string;
    country: string;
    latitude: number;
    longitude: number;
    neighborhood: string | null;
    state: string;
    streetAddress1: string;
    timezone: string;
    uuid: string;
    zipCode: string;
  }
  name: string | null;
  uuid: string;
}

interface FetchOrCreateAddressUnitInput {
  addressUuid: string;
  name?: string;
}

interface Data {
  fetchOrCreateAddressUnit: AddressUnit;
}

interface Variables {
  input: FetchOrCreateAddressUnitInput;
}

export interface FetchOrCreateAddressUnitProps {
  fetchOrCreateAddressUnit: (input: FetchOrCreateAddressUnitInput) => Promise<AddressUnit>;
}

export default withMutation<{}, Data, Variables, FetchOrCreateAddressUnitProps>(FetchOrCreateAddressUnitMutation, {
  props: props => ({
    fetchOrCreateAddressUnit: async (input) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error fetching or creating address unit.');

      return res.data.fetchOrCreateAddressUnit;
    }
  }),
});
