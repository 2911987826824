import withQuery from '~tools/react/graphql/withQuery';

import query from './IpGeolocation.gql';

interface IpGeolocation {
  city: string;
  countryCode: string;
  countryName: string;
  latitude: number;
  longitude: number;
  regionCode: string;
  regionName: string;
  timeZone: string;
  zipCode: string;
}

interface Response {
  ipGeolocation: IpGeolocation | null;
}

export interface IpGeolocationProps {
  ipGeolocation: IpGeolocation | null;
}

export default withQuery<{}, Response, {}, IpGeolocationProps>(query, {
  props: props => ({
    ipGeolocation: props.data?.ipGeolocation || null,
  }),
});
