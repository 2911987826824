// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._aeRkDQ{position:absolute;right:14px;top:40px}", ""]);
// Exports
exports.locals = {
	"brand-shapes": "_aeRkDQ"
};
module.exports = exports;
