import { ApolloQueryResult } from 'apollo-client';
import _ from 'lodash';
import memoize from 'memoize-one';
import React, { Fragment, PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { compose } from '~tools/react/hocs/utils';
import withPaymentSubscription, { PaymentSubscriptionProps } from '~tools/react/hocs/withPaymentSubscription';

import withQuery from '~tools/react/graphql/withQuery';
import withAttachTimeSlotsToCalendar, { AttachTimeSlotsToCalendarProps, CalendarTimeSlotEntityTypes } from '~tools/react/graphql/mutations/calendars/withAttachTimeSlotsToCalendar';
import withCreateAccessInstruction, { AccessInstructionAccessorEntityTypes, AccessInstructionEntityTypes, CreateAccessInstructionProps } from '~tools/react/graphql/mutations/accessInstructions/withCreateAccessInstruction';
import withCreateCalendar, { CalendarEntityTypes, CreateCalendarProps } from '~tools/react/graphql/mutations/calendars/withCreateCalendar';
import withCreatePhoto, { CreatePhotoProps, PhotoEntityTypes } from '~tools/react/graphql/mutations/photos/withCreatePhoto';
import withDeletePhoto, { DeletePhotoProps } from '~tools/react/graphql/mutations/photos/withDeletePhoto';
import withDetachTimeSlotsFromCalendar, { DetachTimeSlotsFromCalendarProps } from '~tools/react/graphql/mutations/calendars/withDetachTimeSlotsFromCalendar';
import withUpdateAccessInstruction, { UpdateAccessInstructionProps } from '~tools/react/graphql/mutations/accessInstructions/withUpdateAccessInstruction';

import { extractMessageFromError } from '~tools/utils/error';

import ModalStage from '~web-manage/lib/common/stages/ModalStage';
import LoadingVisual from '~web-manage/lib/common/components/__deprecated/LoadingVisual';

import Dropzone from '~tools/react/containers/Dropzone';

import Alert from '~tools/react/components/Alert';
import Button from '~tools/react/components/Button';
import Form, { FormField, Select, TextArea } from '~tools/react/components/Form';
import Heading from '~tools/react/components/Heading';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import Toast from '~tools/react/components/Toast';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import WidthConstraintView from '~tools/react/components/WidthConstraintView';

import AccessPhoto from './components/AccessPhoto';
import DropzoneView from './components/DropzoneView';
import PhotosView from './components/PhotosView';
import ViewingTimeRow from './components/ViewingTimeRow';

import { AddressUnit, DayOfWeekIndex, PropertyManagerContract, TimeSlot } from './types';
import { formatHour, getCombinedTimeSlots, getDayText, getLocalizedTimeValues } from './utils';

import query from './UnitAccess.gql';

interface State {
  accessInstructionsErrorMessage?: string;
  dayOfWeek?: number;
  endsAt?: number;
  isAccessInstructionsToastOpen: boolean;
  isLoading: boolean;
  isNotesFieldHighlighted: boolean;
  isUpdating: boolean;
  isViewingsToastOpen: boolean;
  notes?: string;
  startsAt?: number;
  viewingsErrorMessage?: string;
}

type Props =
  RouteComponentProps &
  QueryProps &
  AttachTimeSlotsToCalendarProps &
  CreateAccessInstructionProps &
  CreatePhotoProps &
  CreateCalendarProps &
  DeletePhotoProps &
  DetachTimeSlotsFromCalendarProps &
  UpdateAccessInstructionProps &
  PaymentSubscriptionProps;

class UnitAccess extends PureComponent<Props, State> {
  state: State = {
    isAccessInstructionsToastOpen: false,
    isLoading: false,
    isNotesFieldHighlighted: false,
    isUpdating: false,
    isViewingsToastOpen: false,
  };

  getCombinedSlots = memoize(
    (viewingSlots: TimeSlot[], timezone: string) => getCombinedTimeSlots(viewingSlots, timezone),
  )

  getDays = memoize(() => {
    const dayInts = _.range(0, 7);
    return _.map(dayInts, dayNumber => ({
      label: getDayText(dayNumber) as string,
      value: dayNumber,
    }));
  })

  getStartTimes = memoize((endsAt?: number) => {
    const hourInts = _.range(7, endsAt ?? 22);
    return _.map(hourInts, hourInt => ({
      label: formatHour(hourInt),
      value: hourInt,
    }));
  })

  getEndTimes = memoize((startsAt?: number) => {
    const hourInts = _.range(startsAt ?? 8, 23);
    return _.map(hourInts, hourInt => ({
      label: formatHour(hourInt),
      value: hourInt,
    }));
  })

  handleCloseAccessInstructionsToast = () => this.setState({ isAccessInstructionsToastOpen: false });
  handleCloseViewingsToast = () => this.setState({ isViewingsToastOpen: false });

  handleChangeDayOfWeek = (value: string) => this.setState({ dayOfWeek: _.parseInt(value, 10) });
  handleChangeEndsAt = (value: string) => this.setState({ endsAt: _.parseInt(value, 10) });
  handleChangeStartsAt = (value: string) => this.setState({ startsAt: _.parseInt(value, 10) });

  handleChangeNotes = (value: string) => this.setState({ notes: value });
  handleBlurNotes = () => {
    if (!this.state.notes) return;
    this.handleUpdateAccessInstruction({ notes: this.state.notes });
  }

  handleCreateTimeSlots = async (data: {
    dayOfWeek: string;
    endsAt: string;
    startsAt: string;
  }) => {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return;

    const accessInstruction = await this.getOrCreateAccessInstruction(propertyManagerContract);
    if (!accessInstruction.notes) {
      this.setState({
        isNotesFieldHighlighted: true,
        viewingsErrorMessage: 'Your unit is missing access instructions! Add them above before adding viewing windows.',
      });
      return;
    }

    const dayOfWeek = _.parseInt(data.dayOfWeek) as DayOfWeekIndex;
    const endsAt = _.parseInt(data.endsAt);
    const startsAt = _.parseInt(data.startsAt);
    if (startsAt >= endsAt) {
      this.setState({ viewingsErrorMessage: 'Your end time can\'t be after your start time.' });
      return;
    }

    try {
      this.setState({
        isLoading: true,
        viewingsErrorMessage: undefined,
      });
      const calendar = await this.getOrCreateCalendar(propertyManagerContract.addressUnit);
      await this.props.attachTimeSlotsToCalendar(calendar.uuid, {
        daysOfWeek: [dayOfWeek],
        eligibleEntityTypes: [CalendarTimeSlotEntityTypes.VIEWING],
        endingHour: endsAt,
        startingHour: startsAt,
      });
      await this.props.refetch();
      this.setState({
        dayOfWeek: undefined,
        endsAt: undefined,
        isLoading: false,
        isViewingsToastOpen: true,
        startsAt: undefined,
      });
    } catch (err) {
      this.setState({
        isLoading: false,
        viewingsErrorMessage: extractMessageFromError(err),
      });
    }
  }

  handleDeletePhoto = async (uuid: string) => {
    await this.props.deletePhoto(uuid);
    await this.props.refetch();
  }

  handleDeleteTimeSlots = async (timeSlot: TimeSlot) => {
    const propertyManagerContract = this.props.propertyManagerContract;
    const calendar = propertyManagerContract?.addressUnit.calendar;
    if (!propertyManagerContract || !calendar) return;

    const timeValues = getLocalizedTimeValues(timeSlot, propertyManagerContract.addressUnit.address.timezone);
    try {
      this.setState({ viewingsErrorMessage: undefined });
      await this.props.detachTimeSlotsFromCalendar(calendar.uuid, {
        daysOfWeek: [timeValues.dayIndex],
        endingHour: timeValues.endTimeInt === 0 ? 24 : timeValues.endTimeInt,
        startingHour: timeValues.startTimeInt,
        eligibleEntityTypes: [CalendarTimeSlotEntityTypes.VIEWING],
      });
      await this.props.refetch();
    } catch (err) {
      this.setState({ viewingsErrorMessage: extractMessageFromError(err) });
    }
  }

  handleUpdateAccessInstruction = async (data: {
    notes: string;
  }) => {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return;

    const addressUnit = propertyManagerContract.addressUnit;
    const timeSlots = addressUnit.calendar?.timeSlots ?? [];
    if (timeSlots.length > 0 && !data.notes) {
      this.setState({
        accessInstructionsErrorMessage: 'You still have viewing windows open. Make sure to remove all windows before removing your access instructions.',
        isNotesFieldHighlighted: true,
        notes: addressUnit.accessInstruction?.notes,
      });
      return;
    }

    this.setState({
      accessInstructionsErrorMessage: undefined,
      isNotesFieldHighlighted: false,
      isUpdating: true,
    });
    try {
      const accessInstruction = await this.getOrCreateAccessInstruction(propertyManagerContract);
      await this.props.updateAccessInstruction(accessInstruction.uuid, { notes: data.notes });
      await this.props.refetch();
      this.setState({
        isAccessInstructionsToastOpen: true,
        isUpdating: false,
      });
    } catch (err) {
      this.setState({
        accessInstructionsErrorMessage: extractMessageFromError(err),
        isUpdating: false,
      });
    }
  }

  handleUploadError = (err: Error) => {
    this.setState({ accessInstructionsErrorMessage: err.message });
  }
  handleUploadPhoto = async (photo: {
    key: string;
    bucketUrl: string;
    bucket: string;
  }) => {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return;

    const accessInstruction = await this.getOrCreateAccessInstruction(propertyManagerContract);
    await this.props.createPhoto({
      entityType: PhotoEntityTypes.ACCESS_INSTRUCTION,
      entityUuid: accessInstruction.uuid,
      key: photo.key,
    });
    await this.props.refetch();
  }

  render() {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (this.props.isLoading || !propertyManagerContract) {
      return (
        <ModalStage>
          <LoadingVisual />
        </ModalStage>
      );
    }

    const addressUnit = propertyManagerContract.addressUnit;
    const timeSlots = addressUnit.calendar?.timeSlots ?? [];
    const photos = addressUnit.accessInstruction?.photos ?? [];
    // const hasPaymentSubscription = !!this.props.paymentSubscription;
    const combinedSlots = this.getCombinedSlots(timeSlots, addressUnit.address.timezone);
    // const smartLockOrder = this.getSmartLockOrder();

    return (
      <ModalStage title="Manage access">
        <Toast
          icon={Toast.enums.Icons.Bookmark}
          isOpen={this.state.isAccessInstructionsToastOpen}
          onClick={this.handleCloseAccessInstructionsToast}
          onClose={this.handleCloseAccessInstructionsToast}
          title="Access instructions successfully updated."
        />
        <Toast
          icon={Toast.enums.Icons.Bookmark}
          isOpen={this.state.isViewingsToastOpen}
          onClick={this.handleCloseViewingsToast}
          onClose={this.handleCloseViewingsToast}
          title="Viewing windows successfully added."
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXLarge} />
        <Row
          flexBehavior={Row.enums.FlexBehaviors.Default}>
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXLarge} />
          <WidthConstraintView
            hasAutoMargin={false}
            size={WidthConstraintView.enums.Sizes.XSmall}>
            {/* {hasPaymentSubscription && !(smartLockOrder || addressUnit.smartLock) ? (
              <Fragment>
                <Alert
                  color={Alert.enums.Colors.Blue}
                  description="Order a smart lock from the unit overview to set up contactless viewings"
                  link={{
                    path: `/units/${propertyManagerContract.uuid}`,
                    label: 'Head there',
                  }}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
              </Fragment>
            ) : null} */}
            <Heading
              content="Access instructions"
              font={Heading.enums.Fonts.Secondary}
              size={Heading.enums.Sizes.Small}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
            {this.state.accessInstructionsErrorMessage ? (
              <Fragment>
                <Alert
                  color={Alert.enums.Colors.Red}
                  title={this.state.accessInstructionsErrorMessage}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
              </Fragment>
            ) : null}
            <Heading
              content="Notes"
              font={Heading.enums.Fonts.Secondary}
              size={Heading.enums.Sizes.XSmall}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
            <HorizontalRule />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
            <Form onSubmit={this.handleUpdateAccessInstruction}>
              <TextArea
                isInvalid={this.state.isNotesFieldHighlighted}
                label="How should a guest go about entering your unit?"
                labelFormat={TextArea.enums.LabelFormats.Stacked}
                name="notes"
                placeholder="Ring the buzzer marked #3R and say that you're here from Caretaker. If no one is home, the front gate will have a smart lock with code 30 56 21. Please tour on your own and return the keys when you're done."
                size={TextArea.enums.Sizes.Medium}
                onBlur={this.handleBlurNotes}
                onChange={this.handleChangeNotes}
                value={this.state.notes ?? propertyManagerContract.addressUnit.accessInstruction?.notes}
              />
              <div>
                <Button
                  isLoading={this.state.isUpdating}
                  label="Update notes"
                  size={Button.enums.Sizes.XSmall}
                  style={Button.enums.Styles.Outline}
                  type={Button.enums.Types.Submit}
                />
              </div>
            </Form>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
            <Heading
              content="Photos"
              font={Heading.enums.Fonts.Secondary}
              size={Heading.enums.Sizes.XSmall}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
            <HorizontalRule />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
            <FormField
              labelFormat={FormField.enums.LabelFormats.Stacked}
              label="Add photos to help visitors access your place">
              <PhotosView>
                {_.map(addressUnit.accessInstruction?.photos, photo => (
                  <AccessPhoto
                    key={photo.url}
                    onDeletePhoto={this.handleDeletePhoto}
                    photo={photo}
                  />
                ))}
                <DropzoneView size={photos.length > 0 ? DropzoneView.enums.Sizes.Small : undefined}>
                  <Dropzone
                    placeholder={photos.length === 0 ? 'Upload photo' : undefined}
                    placeholderIcon={Dropzone.enums.Icons.PlusCircle}
                    // Ensure the dropzone resets after we upload a photo
                    key={photos.length}
                    onError={this.handleUploadError}
                    onLoad={this.handleUploadPhoto}
                    uploadType={Dropzone.enums.UploadTypes.Image}
                  />
                </DropzoneView>
              </PhotosView>
            </FormField>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXLarge} />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
            <Heading
              content="Time windows"
              font={Heading.enums.Fonts.Secondary}
              size={Heading.enums.Sizes.Small}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
            <Heading
              content="Add an available window"
              font={Heading.enums.Fonts.Secondary}
              size={Heading.enums.Sizes.XSmall}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
            <HorizontalRule />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
            {this.state.viewingsErrorMessage ? (
              <Fragment>
                <Alert
                  color={Alert.enums.Colors.Red}
                  description={this.state.viewingsErrorMessage}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
              </Fragment>
            ) : null}
            <FormField
              label="When can your unit be accessed?"
              labelFormat={FormField.enums.LabelFormats.Stacked}>
              <Form onSubmit={this.handleCreateTimeSlots}>
                <Row
                  flexBehavior={Row.enums.FlexBehaviors.Default}
                  verticalAlignment={Row.enums.VerticalAlignments.Center}>
                  <Select
                    isRequired={true}
                    name="dayOfWeek"
                    onChange={this.handleChangeDayOfWeek}
                    options={this.getDays()}
                    placeholder="Day of week..."
                    value={!_.isNil(this.state.dayOfWeek) ? `${this.state.dayOfWeek}` : undefined}
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                  <Text content="from" />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                  <Select
                    isRequired={true}
                    name="startsAt"
                    onChange={this.handleChangeStartsAt}
                    options={this.getStartTimes(!_.isNil(this.state.endsAt) ? this.state.endsAt : undefined)}
                    placeholder="Start time..."
                    value={!_.isNil(this.state.startsAt) ? `${this.state.startsAt}` : undefined}
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                  <Text content="until" />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                  <Select
                    isRequired={true}
                    name="endsAt"
                    onChange={this.handleChangeEndsAt}
                    options={this.getEndTimes(!_.isNil(this.state.startsAt) ? this.state.startsAt + 1 : undefined)}
                    placeholder="End time..."
                    value={!_.isNil(this.state.endsAt) ? `${this.state.endsAt}` : undefined}
                  />
                </Row>
                <div>
                  <Button
                    isLoading={this.state.isLoading}
                    label="Add this window"
                    size={Button.enums.Sizes.XSmall}
                    style={Button.enums.Styles.Outline}
                    type={Button.enums.Types.Submit}
                  />
                </div>
              </Form>
            </FormField>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
            <Heading
              content="Current time window(s) for your unit"
              font={Heading.enums.Fonts.Secondary}
              size={Heading.enums.Sizes.XSmall}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
            {timeSlots.length === 0 ? (
              <Fragment>
                <HorizontalRule />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
                <Text
                  content="You haven’t added any windows yet. Add one and it'll display here."
                  color={Text.enums.Colors.Secondary}
                />
              </Fragment>
            ) : null}
            {_.map(combinedSlots, timeSlot => (
              <Fragment key={timeSlot.uuid}>
                <HorizontalRule />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
                <ViewingTimeRow
                  onDelete={this.handleDeleteTimeSlots}
                  timeSlot={timeSlot}
                  timezone={addressUnit.address.timezone}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
              </Fragment>
            ))}
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXLarge} />
          </WidthConstraintView>
        </Row>
      </ModalStage>
    );
  }

  getSmartLockOrder = () => {
    const propertyManagerContract = this.props.propertyManagerContract;

    const smartLockOrder = propertyManagerContract?.orders?.find(order =>
      order.orderItems.some(oi => oi.product.tableName === 'smartlocks')
    );
    return smartLockOrder;
  }

  getOrCreateAccessInstruction = async (propertyManagerContract: PropertyManagerContract) => {
    const addressUnit = propertyManagerContract.addressUnit;
    if (addressUnit.accessInstruction) {
      return addressUnit.accessInstruction;
    }

    const accessInstruction = await this.props.createAccessInstruction({
      accessorEntityType: addressUnit.smartLock ? AccessInstructionAccessorEntityTypes.SMART_LOCK : undefined,
      accessorEntityUuid: addressUnit.smartLock?.uuid,
      entityType: AccessInstructionEntityTypes.LISTING,
      entityUuid: propertyManagerContract.listing.uuid,
    });
    await this.props.refetch();
    return accessInstruction;
  }

  getOrCreateCalendar = async (addressUnit: AddressUnit) => {
    if (addressUnit.calendar) {
      return addressUnit.calendar;
    }

    const calendar = await this.props.createCalendar({
      entity: {
        uuid: addressUnit.uuid,
        type: CalendarEntityTypes.ADDRESS_UNIT,
      },
    });
    return calendar;
  }
}

interface Response {
  viewer: {
    propertyManagerContract: PropertyManagerContract | null;
    uuid: string;
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  propertyManagerContract: PropertyManagerContract | null;
  refetch: () => Promise<ApolloQueryResult<Response>>;
}

interface Variables {
  uuid: string;
}

export default compose(
  withAttachTimeSlotsToCalendar,
  withCreateAccessInstruction,
  withCreateCalendar,
  withCreatePhoto,
  withDeletePhoto,
  withDetachTimeSlotsFromCalendar,
  withPaymentSubscription,
  withUpdateAccessInstruction,
  withQuery<RouteComponentProps<{ propertyManagerContractUuid: string }>, Response, Variables, QueryProps>(query, {
    options: props => ({
      fetchPolicy: 'network-only',
      skip: !props.match.params.propertyManagerContractUuid,
      ssr: false,
      variables: {
        propertyManagerContractUuid: props.match.params.propertyManagerContractUuid,
      },
    }),
    props: props => ({
      isLoading: props.loading,
      propertyManagerContract: props.data?.viewer?.propertyManagerContract ?? null,
      refetch: props.refetch,
    }),
  }),
)(UnitAccess);
