// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._wdja8v{border-left:1px solid #e6e8eb;margin:8px 0 8px 16px}._prW2N1{-webkit-box-align:center;align-items:center;color:#8492a6;display:-webkit-box;display:flex;font-size:14px;font-weight:300;-webkit-box-pack:justify;justify-content:space-between;line-height:22.4px;margin-bottom:4px;text-align:left}._prW2N1,._prW2N1 svg{-webkit-transition:color .1s cubic-bezier(.215,.61,.355,1);transition:color .1s cubic-bezier(.215,.61,.355,1)}._prW2N1 svg{margin-right:16px}._prW2N1 span{background-color:#182029;border-radius:50%;display:inline-block;height:6px;margin-right:16px;width:6px}@media screen and (max-width:1127px){._prW2N1 span{margin-left:16px}}._prW2N1:last-child{margin-bottom:0}._prW2N1 ._99vod0{padding:10px 12px}._prW2N1 ._mmJoEZ svg,._prW2N1 ._O0GT6v svg{height:16px;width:16px}._prW2N1 ._mmJoEZ{padding-top:8px}._prW2N1 ._mmJoEZ svg path{stroke-width:1.5px}._prW2N1 ._O0GT6v{color:#c0cad8;padding-top:2px}._prW2N1._orGrbA{color:#182029;font-weight:500}._prW2N1._rrEajw{color:#aab7c9;pointer-events:none}._prW2N1._32Ay2k{background-color:#f6f8fa}._prW2N1._BX0s1p._orGrbA{background-color:#eff2f7;border-radius:10px}._prW2N1._orGp72 ._99vod0{padding:8px 20px}._prW2N1._orGp72._orGrbA{border-left:2px solid #e6e8eb}._prW2N1._aeR6VM span{background-color:#1f8eed}._prW2N1._aeR6VM._orGrbA{color:#1f8eed}._prW2N1._aeR6VM._BX0s1p._orGrbA{background-color:#e8f4ff}._prW2N1._aeR6VM._orGp72._orGrbA{border-color:#1f8eed}._prW2N1._aeR6VM:hover{color:#1f8eed}._prW2N1._P9Wa70 span{background-color:#ff7849}._prW2N1._P9Wa70._orGrbA{color:#ff7849}._prW2N1._P9Wa70._BX0s1p._orGrbA{background-color:#feefe0}._prW2N1._P9Wa70._orGp72._orGrbA{border-color:#ff7849}._prW2N1._P9Wa70:hover{color:#ff7849}._prW2N1:hover{color:#182029}@media screen and (max-width:1127px){._prW2N1{font-size:16px;line-height:25.6px}}", ""]);
// Exports
exports.locals = {
	"dropdown": "_wdja8v",
	"vertical-menu-link": "_prW2N1",
	"vertical-menu-link__label": "_99vod0",
	"vertical-menu-link__completed-icon": "_mmJoEZ",
	"vertical-menu-link__disabled-icon": "_O0GT6v",
	"vertical-menu-link--active": "_orGrbA",
	"vertical-menu-link--disabled": "_rrEajw",
	"vertical-menu-link--expanded": "_32Ay2k",
	"vertical-menu-link--style-primary": "_BX0s1p",
	"vertical-menu-link--style-secondary": "_orGp72",
	"vertical-menu-link--color-blue": "_aeR6VM",
	"vertical-menu-link--color-orange": "_P9Wa70"
};
module.exports = exports;
