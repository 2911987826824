import classNames from 'classnames';
import _ from 'lodash';
import React, { Fragment, PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Link from '~tools/react/components/utility/Link';

import Dropdown, { CarotPosition, Position } from '~tools/react/components/Dropdown';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import * as enums from './enums';

import styles from './DropdownMenu.scss';

interface ClickableProp {
  color?: enums.ItemColors;
  label: string;
}

interface ActionProp extends ClickableProp {
  onClick: () => void;
}

interface LinkProp extends ClickableProp {
  path: string;
  shouldOpenNewTab?: boolean;
}

export type DropdownMenuSectionItem = ActionProp | LinkProp;

export interface DropdownMenuSection {
  items: DropdownMenuSectionItem[];
}

interface Props {
  carotPosition: CarotPosition;
  position: Position;
  sections: DropdownMenuSection[];
}

class DropdownMenu extends PureComponent<Props> {
  render() {
    return (
      <Dropdown
        carotPosition={this.props.carotPosition}
        position={this.props.position}>
        {_.map(this.props.sections, (section, index) => {
          const links = _.map(section.items, (item) => {
            if ('onClick' in item) {
              return (
                <button
                  className={classNames({
                    [styles.link]: true,
                    [styles[`link__color-${_.kebabCase(item.color)}`]]: !!item.color,
                  })}
                  onClick={item.onClick}
                  key={item.label}>
                  <Text
                    content={item.label}
                    isEmphasized
                  />
                </button>
              );
            }

            return (
              <Link
                key={item.label}
                shouldOpenNewTab={item.shouldOpenNewTab}
                className={classNames({
                  [styles.link]: true,
                  [styles[`link__color-${_.kebabCase(item.color)}`]]: !!item.color,
                })}
                to={item.path}>
                <Text
                  content={item.label}
                  isEmphasized
                />
              </Link>
            );
          });

          return (
            <Fragment key={`dropdown-menu-section-${index}`}>
              {links}
              {index !== this.props.sections.length - 1 ? (
                <Fragment>
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                  <HorizontalRule />
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                </Fragment>
              ) : null}
            </Fragment>
          );
        })}
      </Dropdown>
    );
  }
}

export default withStyles(styles)(DropdownMenu);
