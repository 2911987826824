import isEqual from 'react-fast-compare';
import _ from 'lodash';
import { AxisBottom, AxisLeft } from '@vx/axis';
import { Grid } from '@vx/grid';
import { Group } from '@vx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import { BarStack } from '@vx/shape';
import React, { Component } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import BarPart from './components/BarPart';

import styles from './SkeletonSVGChart.scss';

const flipBlue = '#1F8EED';
const flipBlueLight = '#85D7FF';
const whiteGray2 = '#EFF2F7';

interface Data {
  Expected: number;
  Received: number;
  groupStartsAt: string;
}

interface InputProps {
  data: Data[];
  height: number;
}

type Props =
  InputProps;

interface State {
  data: Data[];
  keys: string[];
  xMax: number;
  yMax: number;
  yMaxValue: number;
}

const CHART_WIDTH_IN_PIXELS = 618.25;
const TickComponent = () => null;

class SkeletonSVGChart extends Component<Props, State> {
  emptyStateYMaxInCents = 1500000;
  margin = {
    bottom: 30,
    left: 50,
    right: 0,
    top: 20,
  };
  colors = scaleOrdinal({
    range: [flipBlue, flipBlueLight, 'transparent'],
  });
  xScale = scaleBand<string>({
    padding: 0.3,
  });
  yScale = scaleLinear<number>({
    nice: true,
  });

  constructor(props: Props) {
    super(props);

    this.state = this.getStateFromProps(props);
  }

  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(this.props, nextProps);
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(this.props, prevProps)) {
      this.setState(this.getStateFromProps(this.props));
    }
  }

  render() {
    const width = CHART_WIDTH_IN_PIXELS;
    const height = this.props.height;

    const data = this.state.data;
    const keys = this.state.keys;
    const xMax = this.state.xMax;
    const yMax = this.state.yMax;

    return (
      <svg
        height={height}
        width={width}>
        <Grid
          height={yMax}
          left={this.margin.left}
          columnLineStyle={{
            stroke: 'transparent',
          }}
          numTicksRows={4}
          stroke={whiteGray2}
          top={this.margin.top}
          width={xMax}
          xOffset={this.xScale.bandwidth() / 2}
          xScale={this.xScale}
          yScale={this.yScale}
        />
        <Group
          left={this.margin.left}
          top={this.margin.top}>
          <BarStack
            color={this.colors}
            data={data}
            keys={keys}
            x={this.chartX}
            xScale={this.xScale}
            yScale={this.yScale}>
            {barStacks => {
              return barStacks.map(barStack => {
                return barStack.bars.map(bar => {
                  return (
                    <BarPart
                      bar={bar}
                      key={`bar-stack-${barStack.index}-${bar.index}`}
                    />
                  );
                });
              });
            }}
          </BarStack>
        </Group>
        <AxisLeft
          hideAxisLine
          hideTicks
          hideZero
          left={this.margin.left}
          numTicks={4}
          scale={this.yScale}
          stroke={whiteGray2}
          tickComponent={TickComponent}
          tickLength={4}
          top={this.margin.top}
        />
        <AxisBottom
          hideAxisLine
          hideTicks
          hideZero
          left={this.margin.left}
          scale={this.xScale}
          stroke={whiteGray2}
          tickComponent={TickComponent}
          tickLength={6}
          top={yMax + this.margin.top}
        />
      </svg>
    );
  }

  getStateFromProps = (props: Props) => {
    const keys = _.filter(_.keys(props.data[0]), k => k !== 'groupStartsAt');
    const totals = _.reduce(props.data, (ret: number[], cur) => {
      const t: number = _.reduce(keys, (rawDailyTotal, k) => {
        const dailyTotal = rawDailyTotal + +cur[k];
        return dailyTotal;
      }, 0);
      ret.push(t);
      return ret;
    }, []);
    const yMaxValue = Math.max(...totals);
    const data = _.map(props.data, d => ({
      ...d,
      max: yMaxValue || this.emptyStateYMaxInCents,
    }));
    const width = CHART_WIDTH_IN_PIXELS;
    const height = props.height;
    const xMax = width - this.margin.left - this.margin.right;
    const yMax = height - this.margin.top - this.margin.bottom;

    this.colors.domain(keys);
    this.xScale.domain(_.map(props.data, 'groupStartsAt'));
    this.xScale.rangeRound([0, xMax]);
    this.yScale.domain([0, yMaxValue || this.emptyStateYMaxInCents]);
    this.yScale.range([yMax, 0]);

    return {
      data,
      keys,
      xMax,
      yMax,
      yMaxValue,
    };
  }

  chartX = (data: Data): string => data.groupStartsAt;
  chartY = (data: Data): number => data.Received;
}

export default withStyles(styles)(SkeletonSVGChart);
