import { scaleOrdinal } from 'd3-scale';
export default function ordinalScale(_ref) {
  var range = _ref.range,
      domain = _ref.domain,
      unknown = _ref.unknown;
  var scale = scaleOrdinal();
  if (range) scale.range(range);
  if (domain) scale.domain(domain);
  if (unknown) scale.unknown(unknown); // @ts-ignore

  scale.type = 'ordinal';
  return scale;
}