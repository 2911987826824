// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1qAa9D>input{-webkit-appearance:none;background:hsla(0,0%,100%,.25);border-radius:14px;height:12px;margin:0;width:100%}._1qAa9D{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;-webkit-box-pack:start;justify-content:flex-start;padding-top:24px;width:100%}._1qAa9D label{color:#fff;font-size:50px;font-weight:100;margin:0;padding-bottom:16px}._1qAa9D label span{opacity:.25}._1qAa9D>input::-webkit-slider-thumb{-webkit-appearance:none;background:#fff;border-radius:50%;cursor:pointer;height:18px;-webkit-transition:all .2s cubic-bezier(.215,.61,.355,1);transition:all .2s cubic-bezier(.215,.61,.355,1);width:18px}._1qAa9D>input:hover::-webkit-slider-thumb{-webkit-transform:scale(1.25);transform:scale(1.25)}._1qAa9D>input:active::-webkit-slider-thumb{box-shadow:0 0 0 12px hsla(0,0%,100%,.25);-webkit-transform:scale(1.1);transform:scale(1.1)}", ""]);
// Exports
exports.locals = {
	"filter-slider": "_1qAa9D"
};
module.exports = exports;
