// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._XP85jE{border-radius:6px;display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;z-index:1}._XP85jE,._XP85jE ._99vvAz{background-color:#fff;box-shadow:0 5px 40px 0 rgba(24,32,41,.08),0 30px 100px 0 rgba(24,32,41,.1);position:absolute}._XP85jE ._99vvAz{content:\"\";height:15px;top:-8px;-webkit-transform:rotate(45deg);transform:rotate(45deg);width:15px;z-index:0}._XP85jE ._drDwGn{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;padding:8px}", ""]);
// Exports
exports.locals = {
	"dropdown": "_XP85jE",
	"dropdown__carot": "_99vvAz",
	"dropdown__children": "_drDwGn"
};
module.exports = exports;
