// @flow
import moment from 'moment';

// returns boolean
const didDatePass = (date: Date | string) => new Date(date) < new Date();

// Format: Monday
const weekdayFormat = (date: Date | string) => moment(date).format('dddd');

// Format: 06/20/2017
const shortFormat = (date: Date | string) => moment(date).format('L');

// Format Oct 5, 2018
const shortDateFormat = (date: Date | string) => moment(date).format('ll');

// Format: October 13th, 2017
const longFormat = (date: Date | string) => moment(date).format('MMMM Do, YYYY');

// Format: 21 days ago
const timeAgo = (date: Date | string) => moment(date).fromNow();

// Format: Today or October 13th, 2017
const todayOrLongDate = (date: Date | string) => {
  if (didDatePass(date)) {
    return 'Today';
  }
  return longFormat(date);
};

// Format: Today or 06/20/2017
const todayOrShortDate = (date: Date | string) => {
  if (didDatePass(date)) {
    return 'Today';
  }
  return shortFormat(date);
};


export default {
  didDatePass,
  longFormat,
  shortDateFormat,
  shortFormat,
  timeAgo,
  todayOrLongDate,
  todayOrShortDate,
  weekdayFormat,
};
