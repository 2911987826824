import { ApolloQueryResult } from 'apollo-client';
import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { PaymentSubscriptionPlans } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withCreatePropertyManagerContract, { CreatePropertyManagerContractProps } from '~tools/react/graphql/mutations/propertyManagerContracts/withCreatePropertyManagerContract';
import withFetchOrCreateAddress, { FetchOrCreateAddressProps } from '~tools/react/graphql/mutations/addresses/withFetchOrCreateAddress';
import withFetchOrCreateAddressUnit, { FetchOrCreateAddressUnitProps } from '~tools/react/graphql/mutations/addressUnits/withFetchOrCreateAddressUnit';
import withFetchOrCreateListingForPropertyManagerContract, { FetchOrCreateListingForPropertyManagerContractProps } from '~tools/react/graphql/mutations/listings/withFetchOrCreateListingForPropertyManagerContract';
import withUpdatePaymentSubscription, { UpdatePaymentSubscriptionProps } from '~tools/react/graphql/mutations/paymentSubscriptions/withUpdatePaymentSubscription';

import Button from '~tools/react/components/Button';
import Form, { Input } from '~tools/react/components/Form';
import GeocodeTypeahead from '~tools/react/components/GeocodeTypeahead';
import InteractiveMap from '~tools/react/components/InteractiveMap';
import Row from '~tools/react/components/Row';
import ThemedModal from '~tools/react/components/ThemedModal';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import ApartmentNameView from './components/ApartmentNameView';
import MapView from './components/MapView';

import query from './AddUnitModal.gql';

const DEFAULT_MAP_BOUNDS = {
  minLng: -74.091796875,
  minLat: 40.647824541622086,
  maxLng: -73.92013549804688,
  maxLat: 40.77794168298968,
};

interface QueryProps {
  isLoading: boolean;
  propertyManagers: {
    uuid: string;
  }[];
  refetch: () => Promise<ApolloQueryResult<Response>>;
  viewer: {
    activePaymentSubscription: {
      plan: PaymentSubscriptionPlans;
      uuid: string;
    };
    paymentSubscription: {
      activatedAt: string;
      createdAt: string;
      plan: PaymentSubscriptionPlans;
      uuid: string;
    } | null;
    propertyManagerContracts: {
      uuid: string;
    }[];
    propertyManagerContractsTotal: number;
    uuid: string;
  } | null;
}

interface InputProps {
  isOpen: boolean;
  onAddUnit: () => void;
  onClose: () => void;
}

type Props =
  InputProps &
  RouteComponentProps &
  QueryProps &
  CreatePropertyManagerContractProps &
  FetchOrCreateAddressUnitProps &
  FetchOrCreateAddressProps &
  FetchOrCreateListingForPropertyManagerContractProps &
  UpdatePaymentSubscriptionProps;

interface State {
  bounds?: {
    minLng: number;
    minLat: number;
    maxLng: number;
    maxLat: number;
  };
  center?: {
    lat: number;
    lng: number;
  };
  googlePlaceId?: string;
  isLoading: boolean;
  streetAddress1?: string;
}

class NewUnitModal extends PureComponent<Props, State> {
  state: State = {
    isLoading: false,
  };

  handleTypeaheadOnSubmit = (data) => {
    this.setState({
      bounds: data.bounds,
      center: data.center,
      googlePlaceId: data.placeId,
      streetAddress1: data.value,
    });
  }

  handleSubmit = async (data: { name: string }) => {
    const viewer = this.props.viewer;
    if (!viewer) return;

    this.setState({ isLoading: true });
    try {
      const googlePlaceId = this.state.googlePlaceId;
      if (!googlePlaceId) return;

      const address = await this.props.fetchOrCreateAddress({ googlePlaceId });
      const addressUnit = await this.props.fetchOrCreateAddressUnit({ addressUuid: address.uuid, name: data.name });

      const activePaymentSubscription = viewer.activePaymentSubscription;
      await this.props.updatePaymentSubscription(activePaymentSubscription.uuid, {
        quantity: viewer.propertyManagerContractsTotal + 1,
      });
      const flipPropertyManager = this.props.propertyManagers[0];
      const propertyManagerContract = await this.props.createPropertyManagerContract({
        addressUnitUuid: addressUnit.uuid,
        propertyManagerUuid: flipPropertyManager.uuid,
        startsAt: new Date().toString(),
      });
      await this.props.fetchOrCreateListingForPropertyManagerContract({
        propertyManagerContractUuid: propertyManagerContract.uuid,
      });
      await this.props.refetch();
    } catch (err) {
      this.setState({ isLoading: false });
      throw err;
    }

    this.setState({
      bounds: undefined,
      center: undefined,
      googlePlaceId: undefined,
      streetAddress1: undefined,
      isLoading: false,
    });
    this.props.onAddUnit();
    this.props.onClose();
  }

  render() {
    return (
      <ThemedModal
        isOpen={this.props.isOpen}
        title="Add unit"
        width={ThemedModal.enums.Widths.Medium}
        onClose={this.props.onClose}>
        <ThemedModal.ThemedModalSection>
          <Form onSubmit={this.handleSubmit}>
            <div>
              <Row isColumnOnMobile={false}>
                <GeocodeTypeahead
                  label="Address"
                  name="streetAddress1"
                  value={this.state.streetAddress1}
                  onSubmit={this.handleTypeaheadOnSubmit}
                  placeholder="52 Marcy Ave, Brooklyn, NY 11211"
                />
                <ApartmentNameView>
                  <Input
                    labelFormat={Input.enums.LabelFormats.Stacked}
                    label="Apt No."
                    name="name"
                    placeholder="2R"
                  />
                </ApartmentNameView>
              </Row>
              <MapView isHidden={!this.state.bounds}>
                <InteractiveMap bounds={this.state.bounds || DEFAULT_MAP_BOUNDS}>
                  {this.state.center ? (
                    <InteractiveMap.InteractiveMapMarker
                      latitude={this.state.center.lat}
                      longitude={this.state.center.lng}
                      type={InteractiveMap.InteractiveMapMarker.enums.Types.Pin}
                    />
                  ) : null}
                </InteractiveMap>
              </MapView>
            </div>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
            <Button
              align={Button.enums.Alignments.Right}
              icon={Button.enums.Icons.ChevronRight}
              isDisabled={!this.state.googlePlaceId}
              isLoading={this.state.isLoading}
              label="Continue"
              type={Button.enums.Types.Submit}
            />
          </Form>
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

interface Response {
  propertyManagers: {
    uuid: string;
  }[];
  viewer: {
    activePaymentSubscription: {
      plan: PaymentSubscriptionPlans;
      uuid: string;
    };
    paymentSubscription: {
      activatedAt: string;
      createdAt: string;
      plan: PaymentSubscriptionPlans;
      uuid: string;
    } | null;
    propertyManagerContracts: {
      uuid: string;
    }[];
    propertyManagerContractsTotal: number;
    uuid: string;
  } | null;
}

export default compose(
  withCreatePropertyManagerContract,
  withFetchOrCreateAddress,
  withFetchOrCreateAddressUnit,
  withFetchOrCreateListingForPropertyManagerContract,
  withUpdatePaymentSubscription,
  withQuery<InputProps, Response, {}, QueryProps>(query, {
    props: (props) => ({
      isLoading: props.loading,
      propertyManagers: props.data?.propertyManagers ?? [],
      refetch: props.refetch,
      viewer: props.data?.viewer ?? null,
    }),
  }),
)(NewUnitModal);
