import loadable, { LoadableComponent } from '@loadable/component';
import _ from 'lodash';

import * as enums from './enums';
import { Props } from './types';

const LoadableImage = loadable(() => import(/* webpackChunkName: "image" */'./Image')) as LoadableComponent<Props>;
const Image = _.assign(LoadableImage, { enums });

export default Image;
