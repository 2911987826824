import hoistStatics from 'hoist-non-react-statics';
import { ComponentType } from 'react';

import withQuery from '~tools/react/graphql/withQuery';
import { OfferType } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';

import query from './withListingBoostOffers.gql';

interface Offer {
  amountInCents: number | null;
  createdAt: string;
  description: string;
  isActive: boolean;
  isMultipleOfMinimum: boolean;
  minimumThresholdInCents: number;
  percentAmount: number;
  type: OfferType;
  updatedAt: string;
  uuid: string;
}

interface Response {
  offers: Offer[];
}

export interface WithListingBoostOffersProps {
  offers: Offer[];
}

export default function withListingBoostOffers<T>(ComposedComponent: ComponentType<T & WithListingBoostOffersProps>) {
  const WithListingBoostOffers = compose(
    withQuery<T, Response, {}, WithListingBoostOffersProps>(query, {
      props: props => ({
        offers: props.data?.offers || [],
      }),
    })
  )(ComposedComponent);

  WithListingBoostOffers.ComposedComponent = ComposedComponent;
  WithListingBoostOffers.displayName = `withListingBoostOffers(${ComposedComponent.displayName || ComposedComponent.name || 'Component'})`;

  return hoistStatics(WithListingBoostOffers, ComposedComponent);
}
