import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';

import Center from '~tools/react/components/Center';
import { Input } from '~tools/react/components/Form';
import Heading from '~tools/react/components/Heading';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import WidthConstraintView from '~tools/react/components/WidthConstraintView';
import graphql, { compose } from '~tools/react/graphql';
import withUpdateAccount, { UpdateAccountProps } from '~tools/react/graphql/mutations/accounts/withUpdateAccount';
import withConfirmVerificationWithToken, {
  ConfirmVerificationWithTokenProps,
} from '~tools/react/graphql/mutations/verifications/withConfirmVerificationWithToken';
import withCreateVerificationForListing, {
  CreateVerificationForListingProps,
} from '~tools/react/graphql/mutations/verifications/withCreateVerificationForListing';
import Lock from '~tools/svgs/icons/interface/lock.svg';

import ModalStage from '~web-manage/lib/common/stages/ModalStage';

import DashboardListingVerifyVerifiedState from './components/DashboardListingVerifyVerifiedState';
import VerifyMethodCard from './components/VerifyMethodCard';
import DashboardListingVerifyConfirmationModal from './containers/DashboardListingVerifyConfirmationModal';
import { VerificationMethods } from './enums';
import { Listing, Verification, Viewer } from './types';

import query from './UnitVerification.gql';

interface InputProps {
  isLoading: boolean;
  listing: Listing;
  refetch: () => Promise<any>;
  verifications: Verification[];
  viewer: Viewer;
}

interface State {
  isListingVerifyConfirmationModalOpen: boolean;
  isLoading: boolean;
  isVerified: boolean;
  listingVerifyConfirmationModalMethod: VerificationMethods;
}

type Props = InputProps & ConfirmVerificationWithTokenProps & CreateVerificationForListingProps & UpdateAccountProps;

class UnitVerification extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isListingVerifyConfirmationModalOpen: false,
      isLoading: false,
      isVerified: Boolean(_.find(props.verifications, (v) => v.verifiedAt)) || false,
      listingVerifyConfirmationModalMethod: VerificationMethods.BILLING_ADDRESS,
    };
  }

  componentWillReceiveProps(nextProps) {
    const verifiedVerification = _.find(nextProps.verifications, (v) => !!v.verifiedAt);
    if (verifiedVerification && !this.state.isVerified) {
      this.setState({ isVerified: true });
    }
  }

  handleVerifyListing = async (payload: { method: VerificationMethods; paymentMethodId?: string; ssn?: string }) => {
    const input = _.extend(
      {
        listingUuid: this.props.listing.uuid,
      },
      payload,
    );

    this.setState({ isLoading: true });

    await this.props.createVerificationForListing(input);
    await this.props.refetch();

    this.setState({
      isListingVerifyConfirmationModalOpen: false,
      isLoading: false,
    });
  };

  handleVerifyListingWithBillingAddress = () => this.confirmVerificationForMethod(VerificationMethods.BILLING_ADDRESS);
  handleVerifyListingWithIdentityCheck = async (data: { [key: string]: any }) => {
    if (data.dateOfBirth) {
      this.setState({ isLoading: true });
      await this.props.updateAccount({
        dateOfBirth: data.dateOfBirth,
      });
    }

    const verification = await this.handleVerifyListing({
      method: VerificationMethods.IDENTITY_CHECK,
      ssn: data.ssn,
    });

    return verification;
  };
  handleVerifyListingWithPostcard = () => this.confirmVerificationForMethod(VerificationMethods.POSTCARD);

  handleSubmitVerificationCode = async (data: { [key: string]: any }) => {
    this.setState({ isLoading: true });
    await this.props.confirmVerificationWithToken(data.verificationToken);
    await this.props.refetch();
    this.setState({ isLoading: false });
  };

  handleCloseListingVerifyConfirmationModal = () => {
    this.setState({
      isListingVerifyConfirmationModalOpen: false,
    });
  };

  render() {
    if (this.state.isVerified && !this.props.isLoading) {
      return (
        <ModalStage title="Address verification">
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXLarge} />
          <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXLarge} />
            <WidthConstraintView
              hasAutoMargin={false}
              size={WidthConstraintView.enums.Sizes.Medium}>
              <DashboardListingVerifyVerifiedState
                listing={this.props.listing}
                verifications={this.props.verifications}
              />
            </WidthConstraintView>
          </Row>
        </ModalStage>
      );
    }

    const verifications = this.props.verifications;

    const failedBillingVerification = this.getFailedVerification(verifications, VerificationMethods.BILLING_ADDRESS);
    const failedIdentityCheck = this.getFailedVerification(verifications, VerificationMethods.IDENTITY_CHECK);
    const postcardVerification = this.getPendingVerification(verifications, VerificationMethods.POSTCARD);
    const postcardVerificationSentAt = postcardVerification
      ? moment(postcardVerification.createdAt).format('MMMM Do')
      : '';

    const dob = this.props.viewer.dateOfBirth ? new Date(this.props.viewer.dateOfBirth) : null;

    return (
      <ModalStage title="Address verification">
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXLarge} />
        <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXLarge} />
          <WidthConstraintView
            hasAutoMargin={false}
            size={WidthConstraintView.enums.Sizes.Medium}>
            <Heading
              content="Choose a verification method below"
              font={Heading.enums.Fonts.Secondary}
              size={Heading.enums.Sizes.XSmall}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
            <Text content="Verifying your listing helps ensure high quality applicants and increases search visibility. Use any of the below to methods to verify your current address matches the listing address." />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
            <HorizontalRule />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
            <Row>
              {failedBillingVerification ? (
                <VerifyMethodCard
                  description="We weren't able to match the listing address with billing address on your card. Please try a different method."
                  isLoading={this.state.isLoading}
                  metaText="Failed"
                  status={VerifyMethodCard.enums.Statuses.Failed}
                  title="Billing address"
                />
              ) : (
                <VerifyMethodCard
                  button={{
                    label: 'Select payment method',
                    onClick: this.handleVerifyListingWithBillingAddress,
                  }}
                  description="Authorize a $1 charge on your card to verify the billing address matches this listing address.
                    You will not actually be charged for this."
                  format={VerifyMethodCard.enums.Formats.Button}
                  isLoading={this.state.isLoading}
                  metaText="Instant"
                  status={VerifyMethodCard.enums.Statuses.Pending}
                  title="Billing address"
                />
              )}
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Large} />
              {failedIdentityCheck ? (
                <VerifyMethodCard
                  description={`Sorry, we ran an identity check for you on ${moment(
                    failedIdentityCheck.createdAt,
                  ).format('MMMM Do')}
                    and were unable to verify your address matches the listing. Please try a different method.`}
                  isLoading={this.state.isLoading}
                  metaText="Failed"
                  status={VerifyMethodCard.enums.Statuses.Failed}
                  title="Identity check"
                />
              ) : (
                <VerifyMethodCard
                  button={{
                    label: 'Run identity check',
                  }}
                  description="Run a quick identity check with your DoB and SSN to verify your address."
                  form={{
                    inputs: [
                      {
                        label: 'Date of birth',
                        name: 'dateOfBirth',
                        placeholder: '01/01/2000',
                        type: Input.enums.Types.Date,
                        value: dob ? `${dob.getUTCMonth() + 1}-${dob.getUTCDate()}-${dob.getUTCFullYear()}` : null,
                      },
                      {
                        label: 'SSN',
                        name: 'ssn',
                        pattern: /^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$/,
                        placeholder: '123-45-6789',
                      },
                    ],
                    onSubmit: this.handleVerifyListingWithIdentityCheck,
                  }}
                  format={VerifyMethodCard.enums.Formats.Form}
                  isLoading={this.state.isLoading}
                  metaText="Instant"
                  status={VerifyMethodCard.enums.Statuses.Pending}
                  title="Identity check"
                />
              )}
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Large} />
              {postcardVerification ? (
                <VerifyMethodCard
                  button={{
                    label: 'Submit verification code',
                  }}
                  description={`Postcard sent to ${this.props.listing.streetAddress} on ${postcardVerificationSentAt}.
                    Enter your code below when you receive your postcard.`}
                  form={{
                    inputs: [
                      {
                        label: 'Verification code',
                        name: 'verificationToken',
                        placeholder: 'A1b2C3d4',
                      },
                    ],
                    onSubmit: this.handleSubmitVerificationCode,
                  }}
                  format={VerifyMethodCard.enums.Formats.Form}
                  isLoading={this.state.isLoading}
                  metaText="4-6 days"
                  status={VerifyMethodCard.enums.Statuses.Pending}
                  title="Postcard"
                />
              ) : (
                <VerifyMethodCard
                  button={{
                    label: 'Send postcard',
                    onClick: this.handleVerifyListingWithPostcard,
                  }}
                  description="Send a postcard to this listing address and submit the included verification code when you recieve it."
                  format={VerifyMethodCard.enums.Formats.Button}
                  isLoading={this.state.isLoading}
                  metaText="4-6 days"
                  status={VerifyMethodCard.enums.Statuses.Pending}
                  title="Postcard"
                />
              )}
            </Row>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
            <HorizontalRule />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
            <Center>
              <Lock
                style={{
                  color: '#8492A6',
                  height: '18px',
                  width: '20px',
                }}
              />
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
              <Text
                color={Text.enums.Colors.Secondary}
                content=" Your data is secure and encrypted. Your SSN is used solely for this identity check and not stored by Caretaker."
                isEmphasized
                size={Text.enums.Sizes.Small}
              />
            </Center>
            <DashboardListingVerifyConfirmationModal
              isLoading={this.state.isLoading}
              isOpen={this.state.isListingVerifyConfirmationModalOpen}
              onClose={this.handleCloseListingVerifyConfirmationModal}
              onConfirmation={this.handleVerifyListing}
              verificationMethod={this.state.listingVerifyConfirmationModalMethod}
            />
          </WidthConstraintView>
        </Row>
      </ModalStage>
    );
  }

  confirmVerificationForMethod = (METHOD: VerificationMethods) => {
    this.setState({
      isListingVerifyConfirmationModalOpen: true,
      listingVerifyConfirmationModalMethod: METHOD,
    });
  };

  getFailedVerification = (verifications: Verification[], verificationMethod: VerificationMethods) => {
    const filteredVerifications = _.filter(verifications, (v) => v.verificationMethod.slug === verificationMethod);
    const sortedVerifications = _.sortBy(filteredVerifications, (v) => -v.createdAt);
    const failedVerification = _.find(sortedVerifications, (v) => !!v.failedAt);

    return failedVerification;
  };

  getPendingVerification = (verifications: Verification[], verificationMethod: VerificationMethods) => {
    const filteredVerifications = _.filter(verifications, (v) => v.verificationMethod.slug === verificationMethod);
    const sortedVerifications = _.sortBy(filteredVerifications, (v) => -v.createdAt);
    const failedVerification = _.find(sortedVerifications, (v) => !v.verifiedAt && !v.failedAt);

    return failedVerification;
  };
}

export default compose(
  graphql(query, {
    options: ({ match }) => ({
      variables: {
        propertyManagerContractUuid: match.params.propertyManagerContractUuid,
      },
      ssr: false,
    }),
    props: (props) => ({
      isLoading: props.data.loading,
      refetch: props.data.refetch,
      listing: _.get(props.data.viewer, 'propertyManagerContract.listing', {}),
      verifications: _.get(props.data.viewer, 'propertyManagerContract.listing.verifications', []),
      viewer: props.data.viewer || {},
    }),
  }),
  withConfirmVerificationWithToken,
  withCreateVerificationForListing,
  withUpdateAccount,
)(UnitVerification);
