/*
  DEPRECATED:

  Using this for now since Roger is working on a similar thing that i can likely port to
*/

import { formatAsUSD } from '~tools/utils/string';

import {
  QualificationThresholdModifiers,
  QualificationThresholdTypes,
  QualificationTypes,
} from '~tools/types/graphqlAdminSchema';

export interface Qualification {
  thresholdModifier: QualificationThresholdModifiers;
  thresholdValue: number;
  thresholdType: QualificationThresholdTypes;
  type: QualificationTypes;
}

function getModifierWord(modifier: QualificationThresholdModifiers) {
  switch (modifier) {
    case QualificationThresholdModifiers.LESS_THAN:
      return 'under';
    case QualificationThresholdModifiers.EQUAL_TO:
      return 'equal to';
    case QualificationThresholdModifiers.GREATER_THAN:
      return 'over';
    default:
      return '';
  }
}

export function getQualificationName(qualification: Qualification) {
  const type = qualification.type as QualificationTypes;
  const modifierWord = getModifierWord(qualification.thresholdModifier);

  switch (type) {
    case QualificationTypes.CREDIT_SCORE:
      return (qualification.thresholdType === QualificationThresholdTypes.NUMBER) ?
        `Credit score ${modifierWord} ${qualification.thresholdValue}` :
        'Credit score report';
    case QualificationTypes.CIVIL_HISTORY:
      return 'Clean eviction record';
    case QualificationTypes.INCOME:
      if (qualification.thresholdType === QualificationThresholdTypes.MULTIPLIER) {
        return `Annual income ${modifierWord} ${qualification.thresholdValue}x the rent`;
      } else if (qualification.thresholdType === QualificationThresholdTypes.NUMBER) {
        return `Annual income ${modifierWord} $${formatAsUSD(qualification.thresholdValue)}`;
      }
      return 'Proof of annual income';
    case QualificationTypes.ASSETS:
      if (qualification.thresholdType === QualificationThresholdTypes.MULTIPLIER) {
        return `Proof of assets ${modifierWord} ${qualification.thresholdValue}x the rent`;
      }
      return `Proof of assets ${modifierWord} $${formatAsUSD(qualification.thresholdValue)}`;
    case QualificationTypes.CRIMINAL_HISTORY:
      return 'Clean felony record';
    default:
      return '';
  }
}
