// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._jrVqO1{-webkit-box-align:center;align-items:center;color:#8492a6;display:-webkit-box;display:flex;font-size:14px;font-weight:300;height:19px;-webkit-box-pack:center;justify-content:center;margin:8px 0;position:relative}._jrVqO1 span{background:#fff;font-size:14px;position:absolute;text-align:center;width:60px;z-index:1}._jrVqO1:after{background:#fff;content:\"\";display:block;height:9px;left:0;position:absolute;right:0;top:0}._jrVqO1:before{background:#e6e8eb;content:\"\";display:block;-webkit-box-flex:1;flex:1;height:1px;width:100%}", ""]);
// Exports
exports.locals = {
	"thread-date": "_jrVqO1"
};
module.exports = exports;
