// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._DBDHjn{border-left:1px solid #e6e8eb;position:relative;width:35%}@media screen and (max-width:1127px){._DBDHjn{width:100%}}", ""]);
// Exports
exports.locals = {
	"leasing-stats-view": "_DBDHjn"
};
module.exports = exports;
