import { CreateLeaseInput, LeaseTypes } from '~tools/types/graphqlSchema';

import withMutation from '~tools/react/graphql/withMutation';

import CreateLeaseMutation from './createLease.gql';

interface Lease {
  addressUnit: {
    address: {
      city: string;
      state: string;
      streetAddress1: string;
      uuid: string;
      zipCode: string;
    };
    name: string | null;
    uuid: string;
  };
  createdAt: string;
  document: {
    uuid: string;
  };
  endDate: string | null;
  rentInCents: number;
  securityDepositInCents: number;
  startDate: string | null;
  type: LeaseTypes;
  updatedAt: string;
  uuid: string;
}

interface Data {
  createLease: Lease;
}

interface Variables {
  input: CreateLeaseInput;
}

export interface CreateLeaseProps {
  createLease: (input: CreateLeaseInput) => Promise<Lease>;
}

export default withMutation<{}, Data, Variables, CreateLeaseProps>(CreateLeaseMutation, {
  props: props => ({
    createLease: async (input) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error creating lease.');

      return res.data.createLease;
    },
  }),
});
