import classNames from 'classnames';
import React, { PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import Spinner from '~tools/react/components/Spinner';

import TrashIcon from './svgs/trash.svg';

import styles from './AccessPhoto.scss';

interface Props {
  photo: {
    url: string,
    uuid: string,
  };
  onDeletePhoto: (uuid: string) => Promise<void>;
}

interface State {
  isLoading: boolean;
}

class AccessPhoto extends PureComponent<Props, State> {
  state = {
    isLoading: false,
  };

  handleDelete = async () => {
    this.setState({ isLoading: true });
    try {
      await this.props.onDeletePhoto(this.props.photo.uuid);
    } catch (err) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <div
        className={classNames({
          [styles['access-photo']]: true,
          [styles['access-photo--loading']]: this.state.isLoading,
        })}
        style={{
          backgroundImage: `url(${this.props.photo.url})`,
        }}>
        <button
          disabled={this.state.isLoading}
          onClick={this.handleDelete}
          styleName="access-photo--delete-button">
          {this.state.isLoading ? <Spinner /> : <TrashIcon />}
        </button>
      </div>
    );
  }
}

export default withStyles(styles)(AccessPhoto);
