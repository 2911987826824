import withMutation from '~tools/react/graphql/withMutation';
import { PurchaseListingBoostInputType } from '~tools/types/graphqlSchema';

import PurchaseListingBoostMutation from './purchaseListingBoost.gql';

interface ListingBoost {
  adsetId: string;
  amountInCents: number;
  createdAt: string;
  updatedAt: string;
  uuid: string;
}

interface Data {
  purchaseListingBoost: ListingBoost;
}

interface Variables {
  input: PurchaseListingBoostInputType;
}

export interface PurchaseListingBoostProps {
  purchaseListingBoost: (input: PurchaseListingBoostInputType) => Promise<ListingBoost>;
}

export default withMutation<{}, Data, Variables, PurchaseListingBoostProps>(PurchaseListingBoostMutation, {
  props: props => ({
    purchaseListingBoost: async (input: PurchaseListingBoostInputType) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error purchasing listing boost.');

      return res.data.purchaseListingBoost;
    },
  }),
});
