// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._ypr5Ro td{padding:16px 24px}@media screen and (max-width:1127px){._ypr5Ro td{padding:16px}}._ypr5Ro td p{color:#8492a6;font-size:16px;font-weight:300;padding:0}", ""]);
// Exports
exports.locals = {
	"table-empty-state": "_ypr5Ro"
};
module.exports = exports;
