import React from 'react';

import Text from '~tools/react/components/Text';
import ThemedModal from '~tools/react/components/ThemedModal';
import TextLink from '~tools/react/components/TextLink';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import Globals from '~web-manage/lib/common/globals';

interface Props {
  isOpen: boolean;
  addressUnitUuid: string;
  onClose: () => void;
}

function RequestInspectionModal(props: Props) {
  return (
    <ThemedModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Request inspection"
      width={ThemedModal.enums.Widths.Small}>
      <ThemedModal.ThemedModalSection>
        <Text
          content="Send this link to anyone you'd like to complete an inspection on your unit. You'll be able to view their progress as they create it."
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
        <TextLink
          path={`${Globals.WEB_CORE_DOMAIN}/inspections/create/${props.addressUnitUuid}`}
          size={TextLink.enums.Sizes.Medium}
        />
      </ThemedModal.ThemedModalSection>
    </ThemedModal>
  );
}

export default RequestInspectionModal;
