// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._BX0e2E{position:relative}._BX0e2E ._VYQe65{-webkit-box-align:center;align-items:center;background-color:hsla(0,0%,100%,.8);border-radius:5px;display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;height:100%;-webkit-box-pack:center;justify-content:center;left:0;position:absolute;top:0;width:100%;z-index:4}", ""]);
// Exports
exports.locals = {
	"unit-details-view": "_BX0e2E",
	"unit-details-view__overlay": "_VYQe65"
};
module.exports = exports;
