import withMutation from '~tools/react/graphql/withMutation';
import { ChangePasswordInputType } from '~tools/types/graphqlSchema';

import ChangePasswordMutation from './changePassword.gql';

interface Data {
  changePassword: boolean;
}

interface Variables {
  input: ChangePasswordInputType;
}

export interface ChangePasswordProps {
  changePassword: (input: ChangePasswordInputType) => Promise<boolean>;
}

export default withMutation<{}, Data, Variables, ChangePasswordProps>(ChangePasswordMutation, {
  props: props => ({
    changePassword: async (input: ChangePasswordInputType) => {
      const res = await props.mutate({
        variables: {
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error changing password.');

      return res.data.changePassword;
    },
  }),
});
