import classNames from 'classnames';
import _ from 'lodash';
import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import * as enums from './enums';

import styles from './DropzoneView.scss';

interface Props {
  children: ReactNode;
  size?: enums.Sizes;
}

function DropzoneView(props: Props) {
  return (
    <div
      className={classNames({
        [styles['dropzone-view']]: true,
        [styles[`dropzone-view--size-${_.kebabCase(props.size)}`]]: props.size,
      })}>
      {props.children}
    </div>
  );
}

export default withStyles(styles)(DropzoneView);
