import _ from 'lodash';
import memoize from 'memoize-one';

import {
  QualificationSubtypes,
  QualificationThresholdModifiers,
  QualificationThresholdTypes,
  QualificationThresholdVariables,
  QualificationTypes,
} from '~tools/types/graphqlSchema';

import { Qualification } from './types';

export const getMemoizedPreface = memoize((qualification: Qualification) => {
  switch (qualification.type) {
    case QualificationTypes.INCOME: {
      const subtype = _.lowerCase(qualification.subtype);
      if (qualification.thresholdType === QualificationThresholdTypes.BOOLEAN) {
        return 'Applicant must have a source of income';
      }

      return `${_.upperFirst(subtype)} income must be`;
    }
    case QualificationTypes.CREDIT_SCORE: {
      if (qualification.thresholdType === QualificationThresholdTypes.BOOLEAN) {
        return 'Applicant must have a credit report';
      }

      return 'Credit score must be';
    }
    case QualificationTypes.CIVIL_HISTORY: {
      if (qualification.thresholdType === QualificationThresholdTypes.BOOLEAN) {
        return 'Applicant must have an eviction history report';
      }

      if (qualification.subtype === QualificationSubtypes.EVICTION_RECORD) {
        return `Number of eviction ${_.lowerCase(qualification.thresholdUnits)} must be`;
      }

      return null;
    }
    case QualificationTypes.CRIMINAL_HISTORY: {
      if (qualification.thresholdType === QualificationThresholdTypes.BOOLEAN) {
        return 'Applicant must have a criminal history report';
      }

      if (qualification.subtype === QualificationSubtypes.FELONY_RECORD) {
        return `Number of felony ${_.lowerCase(qualification.thresholdUnits)} must be`;
      }

      return null;
    }
    default: {
      return null;
    }
  }
});

export const getMemoizedThresholdModifier = memoize((qualification: Qualification) => {
  if (qualification.thresholdType === QualificationThresholdTypes.BOOLEAN) return null;

  return _.lowerCase(qualification.thresholdModifier);
});

export const getMemoizedThresholdModifierOptions = memoize((qualification: Qualification) => {
  switch (qualification.type) {
    case QualificationTypes.CRIMINAL_HISTORY:
    case QualificationTypes.CIVIL_HISTORY: {
      return [
        QualificationThresholdModifiers.LESS_THAN,
      ];
    }
    case QualificationTypes.CREDIT_SCORE: {
      return [
        QualificationThresholdModifiers.GREATER_THAN,
      ];
    }
    case QualificationTypes.INCOME:
    default: {
      return [
        QualificationThresholdModifiers.GREATER_THAN,
        QualificationThresholdModifiers.LESS_THAN,
      ];
    }
  }
});

export const getMemoizedThresholdUnits = memoize((qualification: Qualification) => {
  return _.lowerCase(qualification.thresholdUnits);
});

export const getMemoizedThresholdVariable = memoize((qualification: Qualification) => {
  switch (qualification.thresholdVariable) {
    case QualificationThresholdVariables.MONTHLY_RENT_IN_USD: {
      return 'monthly rent';
    }
    default: {
      return null;
    }
  }
});

export const getMemoizedThresholdValue = memoize((qualification: Qualification) => {
  if (qualification.thresholdType === QualificationThresholdTypes.BOOLEAN) return null;

  return `${qualification.thresholdValue}`;
});
