import _ from 'lodash';
import { ApolloQueryResult } from 'apollo-client';
import { RouteComponentProps } from 'react-router-dom';
import React, { PureComponent } from 'react';
import moment from 'moment';

import Card from '~tools/react/components/Card';
import Text from '~tools/react/components/Text';
import Row from '~tools/react/components/Row';
import withQuery from '~tools/react/graphql/withQuery';
import { getAbbreviatedStreetAddress } from '~tools/utils/geography/addresses';
import Avatar from '~tools/react/components/Avatar';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';

import { formatUnitName } from '~web-manage/lib/common/utils/addressUnit';
import ManageStage from '~web-manage/lib/common/stages/ManageStage';
import Table from '~tools/react/components/Table';

import { AddressUnit, ConditionInspection, PropertyManagerContract } from './types';
import query from './Inspections.gql';

type Props = InputProps & QueryProps;

class Maintain extends PureComponent<Props> {
  render() {
    return (
      <ManageStage>
        <Card
          header={{
            subtitle: 'Review unit inspection history and view inspections',
            title: 'All inspections',
          }}>
          <Table>
            <Table.TableHead>
              <Table.TableRow>
                <Table.TableHeader>Started</Table.TableHeader>
                <Table.TableHeader>Status</Table.TableHeader>
                <Table.TableHeader>Unit</Table.TableHeader>
                <Table.TableHeader>Inspector</Table.TableHeader>
                <Table.TableHeader>Updated</Table.TableHeader>
                <Table.TableHeader></Table.TableHeader>
              </Table.TableRow>
            </Table.TableHead>
            <Table.TableBody>
              {_.map(this.props.conditionInspections, (inspection) => {
                const shortName = `${
                  inspection.inspectorAccount.firstName
                } ${
                  inspection.inspectorAccount.lastName.charAt(0)
                }.`;

                const abbreviatedStreetAddress = getAbbreviatedStreetAddress(inspection.addressUnit.address.streetAddress1);
                return (
                  <Table.TableRow
                    key={inspection.uuid}
                    link={{ path: `/inspections/${inspection.uuid}` }}>
                    {/* Started */}
                    <Table.TableData>
                      <Text
                        content={moment(inspection.createdAt).fromNow()}
                        overflow={Text.enums.OverflowValues.Ellipsis}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Small}
                      />
                    </Table.TableData>

                    {/* Status */}
                    <Table.TableData>
                      <Text
                        content={inspection.lockedAt ? 'Completed' : 'In progress'}
                        overflow={Text.enums.OverflowValues.Ellipsis}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Small}
                      />
                    </Table.TableData>

                    {/* Unit */}
                    <Table.TableData>
                      <Text
                        content={`${abbreviatedStreetAddress}, ${formatUnitName(inspection.addressUnit)}`}
                        overflow={Text.enums.OverflowValues.Ellipsis}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Small}
                      />
                    </Table.TableData>

                    {/* Author */}
                    <Table.TableData>
                      <Row
                        flexBehavior={Row.enums.FlexBehaviors.Default}
                        verticalAlignment={Row.enums.VerticalAlignments.Center}>
                        <Avatar
                          size={Avatar.enums.Sizes.XSmall}
                          imageUrl={inspection.inspectorAccount.photoUrl}
                        />
                        <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                        <Text
                          content={shortName}
                          overflow={Text.enums.OverflowValues.Ellipsis}
                          shouldWrap={false}
                          size={Text.enums.Sizes.Small}
                        />
                      </Row>
                    </Table.TableData>

                    {/* Updated */}
                    <Table.TableData>
                      <Text
                        content={moment(inspection.lastReportUpdatedAt).fromNow()}
                        overflow={Text.enums.OverflowValues.Ellipsis}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Small}
                      />
                    </Table.TableData>
                  </Table.TableRow>
                );
              })}
              {this.props.conditionInspections.length === 0 ? (
                <Table.TableEmptyState
                  label="Once anyone creates an inspection of any of your units, you'll see them listed here!"
                />
              ) : null}
            </Table.TableBody>
          </Table>
        </Card>
      </ManageStage>
    );
  }

  getUnitName = (unit: AddressUnit) => {
    if (!unit.name) return '-';
    return formatUnitName(unit);
  }
}

interface Response {
  viewer: {
    uuid: string;
    propertyManagerContracts: PropertyManagerContract[];
  }
}

interface Variables {
  filter?: {
    isActive?: boolean | null;
  };
}

type InputProps = RouteComponentProps;

interface QueryProps {
  isLoading: boolean;
  conditionInspections: ConditionInspection[];
  refetch: (variables?: Variables) => Promise<ApolloQueryResult<Response>>;
}

export default withQuery<InputProps, Response, Variables, QueryProps>(query, {
  options: () => {
    return {
      variables: {
        filter: {
          isActive: true,
        },
      },
    };
  },
  props: (props) => {
    const propertyManagerContracts = props.data?.viewer?.propertyManagerContracts ?? [];

    const conditionInspections = propertyManagerContracts
      .flatMap(pmc => pmc.addressUnit.conditionInspections.map(inspection => {
        const lastReportUpdatedAt = _.max(
          inspection.conditionReports.map(r => new Date(r.updatedAt).getTime())
        ) ?? new Date(inspection.createdAt).getTime();

        return {
          ...inspection,
          lastReportUpdatedAt,
          propertyManagerContractUuid: pmc.uuid,
        };
      }))
      .sort((a, b) => b.lastReportUpdatedAt - a.lastReportUpdatedAt);

    return {
      isLoading: props.loading,
      conditionInspections,
      refetch: props.refetch,
    };
  },
})(Maintain);
