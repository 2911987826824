import React from 'react';
import { Link } from 'react-router-dom';

import withStyles from '~tools/react/hocs/withStyles';
import ArrowRight from '~tools/svgs/icons/interface/arrow-right.svg';
import ChevronRight from '~tools/svgs/icons/interface/chevron-right.svg';
import CreditCard from '~tools/svgs/icons/interface/credit-card.svg';
import Text from '~tools/react/components/Text';
import Heading from '~tools/react/components/Heading';

import styles from './ManageListingPaymentsPayout.scss';

import { PayoutAccount } from '../../ListingSetupPayments';

interface Props {
  onClick: () => void,
  primaryPayoutAccount: PayoutAccount | null,
  viewerFullName: string,
}

function ManageListingPaymentsPayout(props: Props) {
  if (props.primaryPayoutAccount) {
    return (
      <div styleName="manage-listing-payments-payout manage-listing-payments-payout--col">
        <div styleName="manage-listing-payments-payout__top">
          <div>
            <Heading font={Heading.enums.Fonts.Secondary} size={Heading.enums.Sizes.XSmall} content="Primary payout account" />
            <Text content="All future payments will be deposited here" />
          </div>
          <CreditCard />
        </div>
        {props.primaryPayoutAccount.type === 'CARD' && props.primaryPayoutAccount.card ? (
          <div>
            <div styleName="manage-listing-payments-payout__item">
              <Text content="Card holder" />
              <Text content={props.primaryPayoutAccount.card.name || props.viewerFullName} />
            </div>
            <div styleName="manage-listing-payments-payout__item">
              <Text content="Last 4" />
              <Text content={props.primaryPayoutAccount.card.last4} />
            </div>
            <div styleName="manage-listing-payments-payout__item">
              <Text content="Card brand" />
              <Text content={props.primaryPayoutAccount.card.brand} />
            </div>
          </div>
        ) : null}
        {props.primaryPayoutAccount.type === 'BANK_ACCOUNT' && props.primaryPayoutAccount.bankAccount ? (
          <div>
            <div styleName="manage-listing-payments-payout__item">
              <Text content="Account holder" />
              <Text content={props.primaryPayoutAccount.bankAccount.accountHolderName || props.viewerFullName} />
            </div>
            <div styleName="manage-listing-payments-payout__item">
              <Text content="Routing number" />
              <Text content={props.primaryPayoutAccount.bankAccount.routingNumber} />
            </div>
            <div styleName="manage-listing-payments-payout__item">
              <Text content="Bank name" />
              <Text content={props.primaryPayoutAccount.bankAccount.bankName} />
            </div>
          </div>
        ) : null}
        <div styleName="manage-listing-payments-payout__btn">
          <button onClick={props.onClick} type="button">
            Add new account
          </button>
          <Link target="_blank" to="/settings/payouts">
            Payout settings <ChevronRight />
          </Link>
        </div>
      </div>
    );
  }
  return (
    <div styleName="manage-listing-payments-payout">
      <div>
        <Heading size={Heading.enums.Sizes.Small} content="Get rent payments" />
        <Text content="Setup your payout method to recieve payments. All major banks supported and completely free" />
        <button onClick={props.onClick} type="button">
          Setup payout <ArrowRight />
        </button>
      </div>
      <div styleName="manage-listing-payments-payout__illus">
        <span />
        <span />
        <div />
        <div />
      </div>
    </div>
  );
}

export default withStyles(styles)(ManageListingPaymentsPayout);
