import React, { PureComponent } from 'react';
import _ from 'lodash';

import withStyles from '~tools/react/hocs/withStyles';
import Text from '~tools/react/components/Text';
import Tag from '~tools/react/components/Tag';
import { ConditionReportRequiredServiceTypes } from '~tools/types/graphqlSchema';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';

import * as tagEnums from '~tools/react/components/Tag/enums';

import { ReportPhoto } from '../../../../types';
import styles from './ReportRow.scss';

interface Props {
  displayReport: {
    uuid: string;
    description: string;
    photos: ReportPhoto[];
  };
  requiredServiceType: ConditionReportRequiredServiceTypes;
  tag: {
    color?: tagEnums.Colors;
    icon?: tagEnums.Icons;
    label: string;
  };
  onImageLoad: () => void;
}

class ReportRow extends PureComponent<Props, {}> {
  render() {
    // @ts-ignore
    const primaryPhotoUrl: string = _.minBy(this.props.displayReport.photos, p => p.position).url ?? '';

    const header = this.props.requiredServiceType === ConditionReportRequiredServiceTypes.OTHER ?
      `Issue report` :
      `${_.capitalize(this.props.requiredServiceType)} issue report`;

    return (
      <div styleName="report-row">
        <div styleName="report-row__left">
          <img
            alt=""
            src={`${primaryPhotoUrl}?w=160`}
            styleName="report-row__left__image"
            onLoad={this.props.onImageLoad}
          />
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
          <div>
            <Text
              content={header}
              isEmphasized
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
            <Text content={this.props.displayReport.description} />
          </div>
        </div>
        <div>
          <Tag
            color={this.props.tag.color}
            icon={this.props.tag.icon}
            label={this.props.tag.label}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ReportRow);
