import _ from 'lodash';
import classNames from 'classnames';
import pluralize from 'pluralize';
import React, { Component } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import { VIEWED_INSIGHTS_CAMPAIGN_DETAILS } from '~web-core/lib/_common/constants/analytics';
import {
  analytics,
  dateHelpers,
  formatMoneyString,
} from '~web-core/lib/_common/utils/';
import ChevronDown from '~web-core/lib/_common/components/utils/svg/chevron-down.svg';
import { Boost } from '../../types';

import StatusCircle from './components/StatusCircle';
import ProgressBar from './components/ProgressBar';

import styles from './BoostInsight.scss';

interface Props {
  boost: Boost;
  onOpenPauseBoostModal: (selectedPauseBoostUuid?: string) => void;
}

type State = {
  isExpanded: boolean,
};

const NOT_AVAILABLE = 'N/A';
const PENDING = 'Pending';

class BoostInsight extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isExpanded: false,
    };
  }

  handleOpenPauseBoostModal = () => this.props.onOpenPauseBoostModal(this.props.boost.uuid);

  handleExpandCardToggle = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });

    analytics.track(VIEWED_INSIGHTS_CAMPAIGN_DETAILS, {
      boost_uuid: this.props.boost.uuid,
    });
  }

  handleData = () => {
    const boost = this.props.boost;
    const isRecurring = boost.insights.isDaily;
    const budgetInCents = isRecurring ? boost.dailyAmountInCents : boost.amountInCents;
    // $FlowGuarded
    const budgetInDollars = budgetInCents / 100;

    const budgetString = formatMoneyString(budgetInDollars);
    const budgetCopy = isRecurring ? 'per day' : 'total';

    const campaignStatus = this.getCampaignStatus();
    const isCampaignActive = this.getIsCampaignActive();
    const spendPercentOfBudget = this.getSpendPercentOfBudget();
    const availableToSpendPercent = this.getAvailableToSpendPercent();
    const campaignStartDate = this.getCampaignStartDate();
    const campaignEndDate = this.getCampaignEndDate();
    const availableToSpend = this.getAvailableToSpend();
    const totalUserSpend = this.getTotalUserSpend();

    return {
      availableToSpend,
      availableToSpendPercent,
      budgetCopy,
      budgetString,
      campaignEndDate,
      campaignStartDate,
      campaignStatus,
      isCampaignActive,
      isRecurring,
      spendPercentOfBudget,
      totalUserSpend,
    };
  }

  render() {
    const overviewBody = this.renderOverview();
    const detailsBody = this.renderDetails();
    const mobileBody = this.renderMobile();

    return (
      <div styleName="boost-card">
        {overviewBody}
        {detailsBody}
        {mobileBody}
      </div>
    );
  }

  renderMobile = () => {
    const boost = this.props.boost;
    const {
      availableToSpend,
      budgetCopy,
      budgetString,
      campaignEndDate,
      campaignStartDate,
      campaignStatus,
      isCampaignActive,
      isRecurring,
      totalUserSpend,
    } = this.handleData();

    const boostCardDetailsClasses = classNames({
      'boost-card-mobile-details': true,
      'boost-card-mobile-details--expanded': this.state.isExpanded,
    });

    const impressions = formatMoneyString(boost.insights.impressions);
    return (
      <div styleName="boost-card-mobile">
        <div styleName="boost-card-mobile-overview">
          <div styleName="boost-card-mobile__row">
            <span>{impressions}</span> ad {pluralize('view', boost.insights.impressions)}
          </div>
          <div styleName="boost-card-mobile__row">
            <span>Campaign start date:</span>
            <span>{campaignStartDate}</span>
          </div>
          <div styleName="boost-card-mobile__row">
            <span>Campaign end date:</span>
            <span>{campaignEndDate}</span>
          </div>
          <div styleName="boost-card-mobile__row">
            <span>Status:</span>
            <span>
              <StatusCircle active={isCampaignActive} /> <span>{campaignStatus}</span>
            </span>
          </div>
          <div styleName="boost-card-mobile__row">
            <span>Budget:</span>
            <span>${budgetString} {budgetCopy}</span>
          </div>
          <div styleName="boost-card-mobile__row">
            <button onClick={this.handleExpandCardToggle}>
              {this.state.isExpanded ? 'Hide' : 'View'} analytics
            </button>
          </div>
        </div>
        <div styleName={boostCardDetailsClasses}>
          <div styleName="boost-card-mobile__row boost-card-mobile__row--analytics">
            <p styleName="boost-card-mobile__title">
              Analytics
            </p>
            <p>
              <span>
                {impressions}
              </span>
              {' '}times your ad was viewed
            </p>
            <p>
              <span>
                {formatMoneyString(boost.insights.reach)}
              </span>
              {' '}people saw your ad
            </p>
            <p>
              <span>
                {formatMoneyString(boost.insights.clicks)}
              </span>
              {' '}clicked your ad
            </p>
          </div>
          <div styleName="boost-card-mobile__row">
            <p styleName="boost-card-mobile__title">
              Budget
            </p>
            <div styleName="boost-card-mobile__budget-row">
              <span>Campaign type:</span>
              <span>{isRecurring ? 'Recurring' : 'One Time'}</span>
            </div>
            {!isRecurring &&
              <div styleName="boost-card-mobile__budget-row">
                <span>Available to spend:</span>
                <span>{availableToSpend}</span>
              </div>
            }
            <div styleName="boost-card-mobile__budget-row boost-card-mobile__budget-row--spent">
              <span>What you have spent:</span>
              <span>- {totalUserSpend}</span>
            </div>
          </div>
          {isRecurring && boost.isActive &&
            <div styleName="boost-card-mobile__row boost-card-mobile__row--footer">
              <button onClick={this.handleOpenPauseBoostModal}>
                End campaign
              </button>
            </div>
          }
        </div>
      </div>
    );
  }

  renderOverview = () => {
    const { boost } = this.props;
    const {
      budgetCopy,
      budgetString,
      campaignEndDate,
      campaignStartDate,
      campaignStatus,
      isCampaignActive,
    } = this.handleData();

    const overviewAnalyticsClasses = classNames({
      'boost-card-overview__column': true,
      'boost-card-overview__column--analytics': true,
      'boost-card-overview__column--analytics-expanded': !!this.state.isExpanded,
    });

    const campaignStatusColumn = classNames({
      'boost-card-overview__column': true,
      'boost-card-overview__column-campaign-status': true,
      'boost-card-overview__column-campaign-status--active': isCampaignActive,
    });

    const impressions = formatMoneyString(boost.insights.impressions);
    return (
      <div
        styleName="boost-card-overview"
        onClick={this.handleExpandCardToggle}>
        <div styleName="boost-card-overview__column">
          {impressions} ad {pluralize('view', boost.insights.impressions)}
        </div>
        <div styleName="boost-card-overview__column">
          {campaignStartDate}
        </div>
        <div styleName="boost-card-overview__column">
          {campaignEndDate}
        </div>
        <div styleName={campaignStatusColumn}>
          <StatusCircle active={isCampaignActive} /> <span>{campaignStatus}</span>
        </div>
        <div styleName="boost-card-overview__column">
          <span>${budgetString}</span> {budgetCopy}
        </div>
        <div styleName={overviewAnalyticsClasses}>
          <span>{this.state.isExpanded ? 'Hide' : 'View'} analytics</span>
          <ChevronDown
            fill="#1f8eed"
            height={15}
            width={15}
          />
        </div>
      </div>
    );
  }

  renderDetails = () => {
    const { boost } = this.props;
    const {
      availableToSpend,
      availableToSpendPercent,
      budgetString,
      campaignEndDate,
      campaignStartDate,
      isRecurring,
      spendPercentOfBudget,
      totalUserSpend,
    } = this.handleData();

    const boostCardDetailsClasses = classNames({
      'boost-card-details': true,
      'boost-card-details--expanded': this.state.isExpanded,
    });

    return (
      <div styleName={boostCardDetailsClasses}>
        <div styleName="boost-card-details__block-container">
          <div styleName="boost-card-details__block">
            <h1>
              {formatMoneyString(boost.insights.impressions)}
            </h1>
            <p>Ad views</p>
          </div>
          <div styleName="boost-card-details__block">
            <h1>
              {formatMoneyString(boost.insights.reach)}
            </h1>
            <p>People saw your ad</p>
          </div>
          <div styleName="boost-card-details__block">
            <h1>
              {formatMoneyString(boost.insights.clicks)}
            </h1>
            <p>Ad clicks</p>
          </div>
        </div>
        <div styleName="boost-card-details__stats">
          <div styleName="boost-card-details__stats-budget">
            <div styleName="boost-card-details__stats-row">
              <h3>Budget Details</h3>
            </div>
            <div styleName="boost-card-details__stats-row">
              <p>Campaign type:</p>
              <span>
                {isRecurring ? `Recurring - $${budgetString}/day` : `One time - $${budgetString} total`}
              </span>
            </div>
            {!isRecurring &&
              <div styleName="boost-card-details__stats-row boost-card-details__stats-row--available">
                <p>Available to spend:</p>
                <span>{availableToSpend}</span>
                <ProgressBar percent={availableToSpendPercent} color="#1F8EED" />
              </div>
            }
            <div styleName="boost-card-details__stats-row boost-card-details__stats-row--spent">
              <p>What you have spent:</p>
              <span>- {totalUserSpend}</span>
              <ProgressBar percent={spendPercentOfBudget} color="#FF5167" />
            </div>
          </div>
          <div styleName="boost-card-details__stats-timeframe">
            <div styleName="boost-card-details__stats-row">
              <h3>Timeframe</h3>
            </div>
            <div styleName="boost-card-details__stats-row">
              <p>Campaign start date:</p>
              <span>{campaignStartDate}</span>
            </div>
            <div styleName="boost-card-details__stats-row">
              <p>Campaign end date:</p>
              <span>{campaignEndDate}</span>
            </div>
          </div>
        </div>
        {isRecurring && boost.isActive &&
          <div styleName="boost-card-details__footer">
            <button
              onClick={this.handleOpenPauseBoostModal}
              styleName="boost-card-details__footer-pause">
              End campaign
            </button>
          </div>
        }
      </div>
    );
  }

  getCampaignStartDate = () => {
    if (_.isNull(this.props.boost.insights.startDate)) {
      return NOT_AVAILABLE;
    }

    return dateHelpers.shortDateFormat(this.props.boost.insights.startDate);
  }

  getCampaignEndDate = () => {
    const endDate = this.props.boost.insights.endDate;
    const isDaily = this.props.boost.insights.isDaily;

    // TODO this is not correct
    if (_.isNull(endDate) && isDaily && this.props.boost.isActive) {
      return 'Active';
    }

    if (_.isNull(endDate)) {
      return NOT_AVAILABLE;
    }

    return dateHelpers.shortDateFormat(endDate);
  }

  getAvailableToSpend = () => {
    const budget = this.props.boost.amountInCents;
    const spendInCents = this.props.boost.insights.spendInCents;
    const campaignStatus = this.getCampaignStatus();
    const isPending = campaignStatus === PENDING;

    if (isPending) {
      const budgetInDollars = _.round(budget / 100, 2);
      const formattedBudget = formatMoneyString(budgetInDollars);
      return `$${formattedBudget}`;
    }

    if (_.isNull(spendInCents)) {
      return NOT_AVAILABLE;
    }

    const budgetAvailableInDollars = _.round((budget - spendInCents) / 100, 2);

    return (budgetAvailableInDollars < 0) ? '$0' : `$${budgetAvailableInDollars}`;
  }

  getTotalUserSpend = () => {
    const spendInCents = this.props.boost.insights.spendInCents;
    const amountInCents = this.props.boost.amountInCents;
    const offer = this.props.boost.offer;

    // @ts-ignore
    if (spendInCents === NOT_AVAILABLE) {
      return NOT_AVAILABLE;
    }

    // TODO should we just use spend from the boost or do this
    if (!offer && (spendInCents > amountInCents)) {
      const roundedAmountInCents = _.round(amountInCents / 100, 2);
      return `$${formatMoneyString(roundedAmountInCents)}`;
    }

    // This is to match the formatting that availableToSpend has
    const formattedSpend = _.round(spendInCents / 100, 2);
    return `$${formattedSpend}`;
  }

  getAvailableToSpendPercent = () => {
    const campaignStatus = this.getCampaignStatus();
    const isPending = campaignStatus === PENDING;
    if (isPending) return 100;

    const percentSpent = this.getSpendPercentOfBudget();

    if (_.isNaN(percentSpent) || percentSpent === 0) {
      return 0;
    }

    const percent = 100 - percentSpent;

    if (percent < 0) {
      return 0;
    }

    if (percent > 100) {
      return 100;
    }

    return percent;
  }

  getSpendPercentOfBudget = () => {
    const budget = this.props.boost.amountInCents;
    const spendInCents = this.props.boost.insights.spendInCents;

    if (_.isNull(budget)) {
      return 0;
    }

    // @ts-ignore
    if (parseFloat(spendInCents) > budget) {
      return 100;
    }

    // @ts-ignore
    const budgetSpent = parseFloat(spendInCents) * 100;
    const percent = budgetSpent / budget;

    const finalPercent = _.round(percent * 100);

    if (finalPercent < 0) {
      return 0;
    }

    if (finalPercent > 100) {
      return 100;
    }

    return finalPercent;
  }

  getIsCampaignActive = () => {
    const isBoostActive = this.props.boost.isActive;
    const isDaily = this.props.boost.insights.isDaily;
    const endDate = this.props.boost.insights.endDate;
    const startDate = this.props.boost.insights.startDate;

    if (!isDaily && isBoostActive) {
      // TODO right?
      return true;
    }

    if (isDaily && !isBoostActive) {
      return false;
    }

    if (isBoostActive && isDaily && _.isNull(endDate)) {
      return true;
    }

    const didStartDatePass = dateHelpers.didDatePass(startDate);
    const didEndDatePass = dateHelpers.didDatePass(endDate);

    return didStartDatePass && !didEndDatePass;
  }

  getIsCampaignArchived = () => {
    const isBoostActive = this.props.boost.isActive;
    const isDaily = this.props.boost.insights.isDaily;
    const endDate = this.props.boost.insights.endDate;
    const didEndDatePass = dateHelpers.didDatePass(endDate);

    if (isDaily && !isBoostActive) {
      return true;
    }

    if (!_.isNull(endDate) && didEndDatePass) {
      return true;
    }

    return false;
  }

  getCampaignStatus = () => {
    const didStartDatePass = dateHelpers.didDatePass(this.props.boost.insights.startDate);
    const isDailyDisabled = this.props.boost.insights.isDaily && !this.props.boost.isActive;

    if (isDailyDisabled) {
      return 'Archived';
    }

    if (!didStartDatePass) {
      return PENDING;
    }

    if (this.getIsCampaignArchived()) {
      return 'Archived';
    }

    if (this.getIsCampaignActive()) {
      return 'Active';
    }

    return 'N/A';
  }
}

export default withStyles(styles)(BoostInsight);
