import React, { Fragment, ReactNode } from 'react';

import Heading from '~tools/react/components/Heading';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import Tag from '~tools/react/components/Tag';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';

interface Props {
  title: string;
  isVerified?: boolean;
  children: ReactNode;
}

function QualificationView(props: Props) {
  return (
    <Fragment>
      {props.isVerified !== undefined ? (
        <div style={{ display: 'flex', alignItems: 'center', }}>
          <Heading
            content={props.title}
            font={Heading.enums.Fonts.Secondary}
            size={Heading.enums.Sizes.XSmall}
          />
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
          <Tag label={props.isVerified ? 'Verified' : 'Unverified'} color={props.isVerified ? Tag.enums.Colors.Green : undefined} />
        </div>
      ) : (
        <Heading
          content={props.title}
          font={Heading.enums.Fonts.Secondary}
          size={Heading.enums.Sizes.XSmall}
        />
      )}
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
      <HorizontalRule />
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
      {props.children}
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
    </Fragment>
  );
}

export default QualificationView;
