// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._mmJhvW{cursor:pointer;display:-webkit-box;display:flex;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}._mmJhvW input{display:none}", ""]);
// Exports
exports.locals = {
	"photos-upload-button": "_mmJhvW"
};
module.exports = exports;
