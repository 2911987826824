import React from 'react';

import HorizontalRule from '~tools/react/components/HorizontalRule';
import Text from '~tools/react/components/Text';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './AndHorizontalRule.scss';

function AndHorizontalRule() {
  return (
    <div styleName="and-horizontal-rule">
      <span styleName="and-horizontal-rule__text">
        <Text
          color={Text.enums.Colors.Secondary}
          content="AND"
          size={Text.enums.Sizes.XSmall}
        />
      </span>
      <HorizontalRule />
    </div>
  );
}

export default withStyles(styles)(AndHorizontalRule);
