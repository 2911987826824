// @flow

import React from 'react';
import classNames from 'classnames';

import withStyles from '~tools/react/hocs/withStyles';
import ChevronLeft from '~tools/svgs/icons/interface/chevron-right.svg';
import Cross from '~tools/svgs/icons/interface/cross.svg';
import Heading from '~tools/react/components/Heading';
import Text from '~tools/react/components/Text';

import styles from './ImportBuildingPhotosModalHeader.scss';

type Props = {
  description: string,
  isActive: boolean,
  onClickBack: () => void,
  onClose: () => void,
  subtitle: string,
  title: string,
};

function ImportBuildingPhotosModalHeader(props: Props) {
  return (
    <div styleName={classNames({ 'modal-header': true, 'modal-header--active': props.isActive })}>
      <button styleName="modal-header__x" onClick={props.onClose}>
        <Cross />
      </button>
      <div>
        {props.isActive ? (
          <button onClick={props.onClickBack} styleName="modal-header__title">
            <ChevronLeft />
            <Text size={Text.enums.Sizes.XLarge} content="Back" />
          </button>
        ) : (
          <div styleName="modal-header__title">
            <Heading size={Heading.enums.Sizes.Small} content={props.title} />
            <Heading size={Heading.enums.Sizes.Small} content={props.subtitle} />
          </div>
        )}
        {props.isActive ? null : <Text content={props.description} /> }
      </div>
    </div>
  );
}

export default withStyles(styles)(ImportBuildingPhotosModalHeader);
