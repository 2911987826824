// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._VYQrnX{-webkit-box-align:center;align-items:center;background:#fff;border-radius:5px;display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column;max-width:90vw;padding:32px;width:420px}._VYQrnX h3,._VYQrnX p{color:#182029;margin:0}._VYQrnX>svg{height:50px;margin-top:16px;width:50px}._VYQrnX>p{color:#3c4858;font-weight:200;padding-bottom:32px;padding-top:12px;text-align:center}._VYQrnX>h3{color:#182029;font-size:26px;letter-spacing:-.2px;line-height:32px;padding-top:24px;text-align:center}._VYQrnX ._NRBU6y{-webkit-box-align:center;align-items:center;color:#aab7c9;display:-webkit-box;display:flex;font-size:14px;margin:12px 0;width:100%}._VYQrnX ._NRBU6y:after,._VYQrnX ._NRBU6y:before{background:#e6e8eb;content:\"\";display:block;height:1px;width:100%}._VYQrnX ._NRBU6y:before{margin-right:8px}._VYQrnX ._NRBU6y:after{margin-left:8px}", ""]);
// Exports
exports.locals = {
	"support-modal": "_VYQrnX",
	"support-modal__or": "_NRBU6y"
};
module.exports = exports;
