import _ from 'lodash';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

import { compose } from '~tools/react/hocs/utils';
import ArrowLink from '~tools/react/components/ArrowLink';
import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Card from '~tools/react/components/Card';
import ConfirmationModal from '~tools/react/components/ConfirmationModal';
import GenericLoadingVisual from '~tools/react/components/GenericLoadingVisual';
import Grid from '~tools/react/components/Grid';
import Heading from '~tools/react/components/Heading';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import withAddPropertyManagerContractToServicePreference, { AddPropertyManagerContractToServicePreferenceProps } from '~tools/react/graphql/mutations/servicePreferences/withAddPropertyManagerContractToServicePreference';
import withDeleteServicePreference, { DeleteServicePreferenceProps } from '~tools/react/graphql/mutations/servicePreferences/withDeleteServicePreference';
import withQuery from '~tools/react/graphql/withQuery';
import withRemovePropertyManagerContractFromServicePreference, { RemovePropertyManagerContractFromServicePreferenceProps } from '~tools/react/graphql/mutations/servicePreferences/withRemovePropertyManagerContractFromServicePreference';

import { formatPhoneNumber } from '~web-manage/lib/common/utils/phoneNumber';
import { formatShortAddress } from '~web-manage/lib/common/utils/addressUnit';
import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import AddUnitDropdown from './components/AddUnitDropdown';
import UnitPanel from './components/UnitPanel';
import UpdateServicePreferenceNotesModal from './containers/UpdateServicePreferenceNotesModal';

import { PropertyManagerContract, ServicePreference } from './types';
import query from './ServiceProvider.gql';

type RouteProps = RouteComponentProps<{ servicePreferenceUuid: string}>;

type Props = QueryProps
  & AddPropertyManagerContractToServicePreferenceProps
  & DeleteServicePreferenceProps
  & RemovePropertyManagerContractFromServicePreferenceProps
  & RouteProps;

interface State {
  isConfirmRemoveServicePreferenceModalOpen: boolean;
  isUpdateServicePreferenceNotesModalOpen: boolean;
  isLoading: boolean;
  propertyManagerContractToRemove: PropertyManagerContract | null;
}

class ServiceProvider extends Component<Props, State> {
  state: State = {
    isConfirmRemoveServicePreferenceModalOpen: false,
    isUpdateServicePreferenceNotesModalOpen: false,
    isLoading: false,
    propertyManagerContractToRemove: null,
  };

  handleClickRemoveServicePreference = () => this.setState({ isConfirmRemoveServicePreferenceModalOpen: true });

  handleCloseServicePreferenceNotesModal = () => this.setState({ isUpdateServicePreferenceNotesModalOpen: false });
  handleOpenServicePreferenceNotesModal = () => this.setState({ isUpdateServicePreferenceNotesModalOpen: true });

  handleClickRemoveUnit = (propertyManagerContract: PropertyManagerContract) => {
    this.setState({ propertyManagerContractToRemove: propertyManagerContract });
  };

  handleCloseConfirmationModal = () => this.setState({
    isConfirmRemoveServicePreferenceModalOpen: false,
    propertyManagerContractToRemove: null,
  });

  handleUpdatedNotes = () => this.props.refetch();

  handleConfirmRemoveServicePreference = async () => {
    const servicePreference = this.props.servicePreference;
    if (!servicePreference) return;

    try {
      this.setState({ isLoading: true });
      await this.props.deleteServicePreference(servicePreference.uuid);
      await this.props.refetch();
      this.setState({ isLoading: false });

      this.props.history.push('/settings/maintenance/service-providers');
    } catch (err) {
      this.setState({ isLoading: false });
      throw err;
    }
  };

  handleConfirmRemovePropertyManagerContract = async () => {
    const servicePreference = this.props.servicePreference;
    if (!servicePreference) return;

    const propertyManagerContractToRemove = this.state.propertyManagerContractToRemove;
    if (!propertyManagerContractToRemove) return;

    try {
      this.setState({ isLoading: true });
      await this.props.removePropertyManagerContractFromServicePreference(servicePreference.uuid, {
        propertyManagerContractUuid: propertyManagerContractToRemove.uuid,
      });
      await this.props.refetch();
      this.setState({
        isLoading: false,
        propertyManagerContractToRemove: null,
      });
    } catch (err) {
      this.setState({ isLoading: false });
      throw err;
    }
  };

  handleAddPropertyManagerContract = async (propertyManagerContractUuid: string) => {
    const servicePreference = this.props.servicePreference;
    if (!servicePreference) return;

    try {
      this.setState({ isLoading: true });
      await this.props.addPropertyManagerContractToServicePreference(servicePreference.uuid, {
        propertyManagerContractUuid,
      });
      await this.props.refetch();
      this.setState({
        isLoading: false,
        propertyManagerContractToRemove: null,
      });
    } catch (err) {
      this.setState({ isLoading: false });
      throw err;
    }
  };

  render() {
    const servicePreference = this.props.servicePreference;
    if (this.props.isLoading || !servicePreference) {
      return (
        <ManageStage>
          <Card>
            <Card.CardSection>
              <GenericLoadingVisual />
            </Card.CardSection>
          </Card>
        </ManageStage>
      );
    }

    let phoneNumberFormatted: string | undefined;
    const phoneNumber = servicePreference.serviceProvider.organization.phoneNumbers[0]?.phoneNumber;
    if (_.isString(phoneNumber)) {
      phoneNumberFormatted = formatPhoneNumber(phoneNumber);
    }

    return (
      <ManageStage>
        <Breadcrumbs
          items={[
            { path: `/settings`, label: 'Settings' },
            { path: `/settings/maintenance`, label: `Maintenance` },
            { path: `/settings/maintenance/service-providers`, label: `Service providers` },
            { label: servicePreference.serviceProvider.organization.name },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
        <Card header={{
          actions: [{
            color: Card.enums.ActionColors.Red,
            label: 'Remove',
            onClick: this.handleClickRemoveServicePreference,
          }],
          title: servicePreference.serviceProvider.organization.name,
        }}>
          <Card.CardSection title="Details">
            <Grid columns={Grid.enums.Columns.Two}>
              {/* Left */}
              <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                <div style={{ minWidth: '145px' }}>
                  <Text
                    color={Text.enums.Colors.Secondary}
                    content="Name"
                    size={Text.enums.Sizes.Medium}
                  />
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                  <Text
                    color={Text.enums.Colors.Secondary}
                    content="Service type"
                    size={Text.enums.Sizes.Medium}
                  />
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                  <Text
                    color={Text.enums.Colors.Secondary}
                    content="Date added"
                    size={Text.enums.Sizes.Medium}
                  />
                </div>
                <div>
                  {/* Name */}
                  <Text
                    content={servicePreference.serviceProvider.organization.name}
                    size={Text.enums.Sizes.Medium}
                  />
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />

                  {/* Service type */}
                  <Text
                    content={_.capitalize(servicePreference.serviceType)}
                    size={Text.enums.Sizes.Medium}
                  />
                  <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />

                  {/* Date added */}
                  <Text
                    content={moment(servicePreference.createdAt).format('MMMM DD, YYYY')}
                    size={Text.enums.Sizes.Medium}
                  />
                </div>
              </Row>

              {/* Right */}
              <div>
                <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                  <div style={{ minWidth: '145px' }}>
                    <Text
                      color={Text.enums.Colors.Secondary}
                      content="Email"
                      size={Text.enums.Sizes.Medium}
                    />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                    <Text
                      color={Text.enums.Colors.Secondary}
                      content="Phone"
                      size={Text.enums.Sizes.Medium}
                    />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                    <Text
                      color={Text.enums.Colors.Secondary}
                      content="Website"
                      size={Text.enums.Sizes.Medium}
                    />
                  </div>
                  <div>
                    {/* Email */}
                    <Text
                      color={servicePreference.serviceProvider.organization.email ?
                        undefined :
                        Text.enums.Colors.Secondary}
                      content={servicePreference.serviceProvider.organization.email || 'N/A'}
                      size={Text.enums.Sizes.Medium}
                    />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />

                    {/* Phone */}
                    <Text
                      color={phoneNumberFormatted ?
                        undefined :
                        Text.enums.Colors.Secondary}
                      content={phoneNumberFormatted || 'N/A'}
                      size={Text.enums.Sizes.Medium}
                    />
                    <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />

                    {/* Website */}
                    {servicePreference.serviceProvider.organization.website ? (
                      <ArrowLink
                        color={ArrowLink.enums.Colors.Blue}
                        label={servicePreference.serviceProvider.organization.website}
                        link={{
                          path: servicePreference.serviceProvider.organization.website,
                          shouldOpenNewTab: true,
                        }}
                        size={ArrowLink.enums.Sizes.Medium}
                      />
                    ) : (
                      <Text
                        color={Text.enums.Colors.Secondary}
                        content="N/A"
                        size={Text.enums.Sizes.Medium}
                      />
                    )}
                  </div>
                </Row>
              </div>
            </Grid>
          </Card.CardSection>
          <Card.CardSection
            action={{
              icon: Card.CardSection.enums.ActionIcons.Edit,
              onClick: this.handleOpenServicePreferenceNotesModal,
            }}
            title="Notes">
            <Text
              color={servicePreference.notes ? Text.enums.Colors.Primary : Text.enums.Colors.Secondary}
              content={servicePreference.notes || 'Click the edit button to the right to add your notes here.'}
              size={Text.enums.Sizes.Medium}
            />
          </Card.CardSection>
        </Card>
        <Card>
          <Card.CardSection>
            <Row
              flexBehavior={Row.enums.FlexBehaviors.Default}
              verticalAlignment={Row.enums.VerticalAlignments.Center}>
              <Heading
                content="Add unit"
                font={Heading.enums.Fonts.Secondary}
                priority={Heading.enums.Priorities.One}
                size={Heading.enums.Sizes.XSmall}
              />
              <div style={{ marginLeft: 'auto' }}>
                <AddUnitDropdown
                  onAddUnit={this.handleAddPropertyManagerContract}
                />
              </div>
            </Row>
          </Card.CardSection>
          {servicePreference.propertyManagerContracts.length ? (
            <Grid
              columns={Grid.enums.Columns.Two}
              spacing={Grid.enums.Spacing.None}>
              {servicePreference.propertyManagerContracts.map((propertyManagerContract, gridIndex) => (
                <UnitPanel
                  key={propertyManagerContract.uuid}
                  gridIndex={gridIndex}
                  onRemove={this.handleClickRemoveUnit}
                  propertyManagerContract={propertyManagerContract}
                />
              ))}
            </Grid>
          ) : (
            <Card.CardSection>
              <Text
                content="Add units you'd prefer this service providers to work on here."
                color={Text.enums.Colors.Secondary}
              />
            </Card.CardSection>
          )}

        </Card>
        <ConfirmationModal
          cancelAction={{
            label: 'Cancel',
            onClick: this.handleCloseConfirmationModal,
          }}
          confirmAction={{
            label: 'Remove',
            color: ConfirmationModal.enums.ButtonColors.Red,
            onClick: this.handleConfirmRemoveServicePreference,
          }}
          description={`Are you sure you want to remove ${servicePreference.serviceProvider.organization.name} from your service provider list?`}
          isLoading={this.props.isLoading || this.state.isLoading}
          isOpen={this.state.isConfirmRemoveServicePreferenceModalOpen}
          onClose={this.handleCloseConfirmationModal}
          title="Remove service provider"
        />
        <ConfirmationModal
          cancelAction={{
            label: 'Cancel',
            onClick: this.handleCloseConfirmationModal,
          }}
          confirmAction={{
            label: 'Remove',
            color: ConfirmationModal.enums.ButtonColors.Red,
            onClick: this.handleConfirmRemovePropertyManagerContract,
          }}
          description={this.state.propertyManagerContractToRemove ?
            `Are you sure you want to remove ${formatShortAddress(this.state.propertyManagerContractToRemove.addressUnit)} from the list of units for this service provider?` :
            ''
          }
          isLoading={this.props.isLoading || this.state.isLoading}
          isOpen={!!this.state.propertyManagerContractToRemove}
          onClose={this.handleCloseConfirmationModal}
          title="Remove unit from service provider"
        />
        <UpdateServicePreferenceNotesModal
          isOpen={this.state.isUpdateServicePreferenceNotesModalOpen}
          servicePreferenceUuid={servicePreference.uuid}
          onClose={this.handleCloseServicePreferenceNotesModal}
          onSubmit={this.handleUpdatedNotes}
        />
      </ManageStage>
    );
  }
}

interface QueryProps {
  isLoading: boolean;
  servicePreference: ServicePreference | null;
  refetch: () => Promise<any>;
}

interface Response {
  viewer: {
    servicePreference: ServicePreference | null;
    uuid: string;
  }
}

export default compose(
  withQuery<RouteProps, Response, {}, QueryProps>(query, {
    options: props => ({
      variables: {
        servicePreferenceUuid: props.match.params.servicePreferenceUuid,
      },
      skip: !props.match.params.servicePreferenceUuid,
    }),
    props: props => ({
      isLoading: props.loading,
      servicePreference: props.data?.viewer?.servicePreference ?? null,
      refetch: props.refetch,
    }),
  }),
  withAddPropertyManagerContractToServicePreference,
  withDeleteServicePreference,
  withRemovePropertyManagerContractFromServicePreference,
)(ServiceProvider);
