import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './TableHead.scss';

interface Props {
  children: ReactNode;
}

function TableHead(props: Props) {
  return (
    <thead>
      {props.children}
    </thead>
  );
}

export default withStyles(styles)(TableHead);
