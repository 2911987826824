import React, { ReactNode } from 'react';

import SkeletonBodyText from '~tools/react/components/SkeletonBodyText';
import withStyles from '~tools/react/hocs/withStyles';

import styles from './TableHeader.scss';

interface Props {
  children?: ReactNode,
  isLoading?: boolean,
}

function TableHeader(props: Props) {
  if (props.isLoading) {
    return (
      <li styleName="table__th">
        <SkeletonBodyText />
      </li>
    );
  }

  return (
    <li styleName="table__th">
      {props.children}
    </li>
  );
}

TableHeader.defaultProps = {
  isLoading: false,
};

export default withStyles(styles)(TableHeader);
