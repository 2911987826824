import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React, { Fragment, PureComponent } from 'react';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import { extractMessageFromError } from '~tools/utils/error';

import { StripeConnectAccountBusinessTypes, StripeConnectAccountCapabilities } from '~tools/types/graphqlSchema';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';

import withSendEmailVerificationEmail, { SendEmailVerificationEmailProps } from '~tools/react/graphql/mutations/accounts/withSendEmailVerificationEmail';
import withUpdateAccount, { UpdateAccountProps } from '~tools/react/graphql/mutations/accounts/withUpdateAccount';

import Alert from '~tools/react/components/Alert';
import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import Center from '~tools/react/components/Center';
import Form, { Input } from '~tools/react/components/Form';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Tag from '~tools/react/components/Tag';
import Text from '~tools/react/components/Text';
import Tooltip from '~tools/react/components/Tooltip';
import VerticalRule from '~tools/react/components/VerticalRule';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import AuthModal from '~tools/react/containers/AuthModal';

import DataGrid from '~web-manage/lib/common/components/DataGrid';
import Icon from '~web-manage/lib/common/components/Icon';

import ChangePasswordModal from './containers/ChangePasswordModal';

import query from './AccountSettings.gql';

type Props =
  QueryProps &
  UpdateAccountProps &
  SendEmailVerificationEmailProps;

interface FormData {
  email: string;
  name: string;
  phoneNumber: string;
}

interface UpdateAccountData {
  email?: string;
  name: string;
  phoneNumber?: string;
}

interface State {
  didSendEmailVerificationEmail: boolean;
  error: string | null;
  isChangePasswordModalOpen: boolean;
  isEditing: boolean;
  isLoading: boolean;
  isSendingVerificationEmail: boolean;
  isVerifySmsModalOpen: boolean;
  activeAuthModalView: string;
}

class AccountSettings extends PureComponent<Props, State> {
  state: State = {
    activeAuthModalView: AuthModal.enums.Views.RequestSms,
    didSendEmailVerificationEmail: false,
    error: null,
    isChangePasswordModalOpen: false,
    isEditing: false,
    isLoading: false,
    isSendingVerificationEmail: false,
    isVerifySmsModalOpen: false,
  };

  handleCloseChangePasswordModal = () => this.setState({ isChangePasswordModalOpen: false });
  handleOpenChangePasswordModal = () => this.setState({ isChangePasswordModalOpen: true });

  handleCloseVerifySmsModal = () => this.setState({ isVerifySmsModalOpen: false });
  handleOpenVerifySmsModal = () => this.setState({ isVerifySmsModalOpen: true });

  handleOpenEditing = () => this.setState({ isEditing: true });
  handleCloseEditing = () => this.setState({ isEditing: false });

  handleChangeAuthModalView = (view: string) => this.setState({ activeAuthModalView: view });

  handleUpdateAccount = async (rawData: FormData) => {
    const data: UpdateAccountData = rawData;
    const viewer = this.props.viewer;
    if (!viewer) return;

    const phoneNumber = data.phoneNumber?.match(/\d+/g);
    if (phoneNumber) data.phoneNumber = phoneNumber.join('');
    else if (!phoneNumber) delete data.phoneNumber;

    if (rawData.email === viewer.email) delete data.email;

    this.setState({
      error: '',
      isLoading: true,
    });

    try {
      await this.props.updateAccount(data);
      if (data.email) {
        await this.props.sendEmailVerificationEmail();
      }
      this.setState({
        isEditing: false,
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        isLoading: false,
        error: extractMessageFromError(err),
      });
    }
  }

  handleSendEmailVerificationEmail = async () => {
    this.setState({ isSendingVerificationEmail: true });

    try {
      await this.props.sendEmailVerificationEmail();
      this.setState({
        didSendEmailVerificationEmail: true,
        isSendingVerificationEmail: false,
      });
    } catch (err) {
      this.setState({ isSendingVerificationEmail: false });
    }
  }

  render() {
    const viewer = this.props.viewer;
    const hasConnectAccount = viewer?.stripeConnectAccount;

    let dateOfBirth: Dayjs | undefined;
    if (
      viewer?.stripeConnectAccount?.businessType === StripeConnectAccountBusinessTypes.INDIVIDUAL &&
      viewer?.stripeConnectAccount.individual.dob &&
      viewer?.stripeConnectAccount.individual.dob.year !== null &&
      viewer?.stripeConnectAccount.individual.dob.month !== null &&
      viewer?.stripeConnectAccount.individual.dob.day !== null
    ) {
      dateOfBirth = dayjs();
      dateOfBirth = dateOfBirth.set('year', viewer.stripeConnectAccount.individual.dob.year);
      dateOfBirth = dateOfBirth.set('month', viewer.stripeConnectAccount.individual.dob.month);
      dateOfBirth = dateOfBirth.set('date', viewer.stripeConnectAccount.individual.dob.day);
    }

    return (
      <ManageStage>
        <AuthModal
          isOpen={this.state.isVerifySmsModalOpen}
          activeView={this.state.activeAuthModalView}
          onClose={this.handleCloseVerifySmsModal}
          onChangeView={this.handleChangeAuthModalView}
        />
        <Breadcrumbs
          items={[
            { path: `/settings`, label: 'Settings' },
            { path: `/settings/account`, label: 'Account details' },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
        <Card
          header={{
            title: 'Account',
            actions: this.state.isEditing ? [
              {
                color: Card.enums.ActionColors.Blue,
                formId: 'update-account',
                isLoading: this.state.isLoading,
                label: 'Save',
              },
              {
                isDisabled: this.state.isLoading,
                label: 'Cancel',
                onClick: this.handleCloseEditing,
              },
            ] : [
              {
                icon: Card.enums.ActionIcons.Edit,
                label: 'Edit',
                onClick: this.handleOpenEditing,
              },
            ],
          }}
          isLoading={this.props.isLoading}>
          <Card.CardSection>
            {this.state.isEditing ? (
              <Form
                id="update-account"
                onSubmit={this.handleUpdateAccount}>
                {this.state.error ? (
                  <Form.FormSection>
                    <Alert
                      icon={Alert.enums.Icons.Alert}
                      description={this.state.error}
                      color={Alert.enums.Colors.Red}
                    />
                  </Form.FormSection>
                ) : null}
                <DataGrid>
                  <DataGrid.DataGridColumn>
                    <DataGrid.DataGridRow label="Email">
                      <Input
                        name="email"
                        type={Input.enums.Types.Email}
                        value={viewer?.email}
                      />
                    </DataGrid.DataGridRow>
                    <DataGrid.DataGridRow label="Name">
                      <Input
                        name="name"
                        type={Input.enums.Types.Text}
                        placeholder="Brian Smith"
                        value={viewer?.fullName ?? undefined}
                      />
                    </DataGrid.DataGridRow>
                    <DataGrid.DataGridRow label="Phone">
                      <Input
                        mask="(999) 999-9999"
                        name="phoneNumber"
                        placeholder="(800) 888-8888"
                        type={Input.enums.Types.Tel}
                        value={viewer?.phoneNumber ?? undefined}
                      />
                    </DataGrid.DataGridRow>
                    <DataGrid.DataGridRow label="Password">
                      <Button
                        align={Button.enums.Alignments.Left}
                        color={Button.enums.Colors.Gray}
                        icon={Button.enums.Icons.Edit}
                        label="Change password..."
                        onClick={this.handleOpenChangePasswordModal}
                        size={Button.enums.Sizes.XSmall}
                        style={Button.enums.Styles.Secondary}
                        width={Button.enums.Widths.Auto}
                      />
                    </DataGrid.DataGridRow>
                  </DataGrid.DataGridColumn>
                </DataGrid>
              </Form>
            ) : (
              <DataGrid>
                <DataGrid.DataGridColumn>
                  <DataGrid.DataGridRow label="Email">
                    <Row
                      flexBehavior={Row.enums.FlexBehaviors.Default}
                      verticalAlignment={Row.enums.VerticalAlignments.Center}>
                      <Text
                        content={viewer?.email ?? ''}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Medium}
                      />
                      <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                      {viewer?.isEmailVerified ? (
                        <Tooltip
                          position={Tooltip.enums.Positions.Up}
                          text="Your email address is verified.">
                          <Center>
                            <Icon
                              svg={Icon.enums.SVGs.Check}
                              color={Icon.enums.Colors.Green}
                              size={{
                                height: '16px',
                                width: '16px',
                              }}
                            />
                          </Center>
                        </Tooltip>
                      ) : (
                        <Fragment>
                          <Tag
                            color={Tag.enums.Colors.Gray}
                            label="Unverified"
                            size={Tag.enums.Sizes.Small}
                          />
                          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                          <VerticalRule />
                          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                          <Button
                            color={Button.enums.Colors.Blue}
                            icon={
                              this.state.didSendEmailVerificationEmail ?
                                Button.enums.Icons.Check :
                                Button.enums.Icons.ChevronRight
                            }
                            isDisabled={this.state.didSendEmailVerificationEmail}
                            isLoading={this.state.isSendingVerificationEmail}
                            label={this.state.didSendEmailVerificationEmail ? 'Sent verification email' : 'Re-send verification email'}
                            onClick={this.handleSendEmailVerificationEmail}
                            size={Button.enums.Sizes.XSmall}
                            style={Button.enums.Styles.Outline}
                          />
                        </Fragment>
                      )}
                    </Row>
                  </DataGrid.DataGridRow>
                  <DataGrid.DataGridRow label="Name">
                    <Text
                      content={viewer?.fullName ?? ''}
                      shouldWrap={false}
                      size={Text.enums.Sizes.Medium}
                    />
                  </DataGrid.DataGridRow>
                  <DataGrid.DataGridRow label="Phone">
                    <Row
                      flexBehavior={Row.enums.FlexBehaviors.Default}
                      verticalAlignment={Row.enums.VerticalAlignments.Center}>
                      <Text
                        content={viewer?.formattedPhoneNumber ?? ''}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Medium}
                      />
                      <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                      {viewer?.isPhoneNumberVerified ? (
                        <Tooltip
                          position={Tooltip.enums.Positions.Up}
                          text="Your phone number is verified">
                          <Center>
                            <Icon
                              svg={Icon.enums.SVGs.Check}
                              color={Icon.enums.Colors.Green}
                              size={{
                                height: '16px',
                                width: '16px',
                              }}
                            />
                          </Center>
                        </Tooltip>
                      ) : (
                        <Fragment>
                          <Tag
                            color={Tag.enums.Colors.Gray}
                            label="Unverified"
                            size={Tag.enums.Sizes.Small}
                          />
                          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                          <VerticalRule />
                          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                          <Button
                            color={Button.enums.Colors.Blue}
                            icon={Button.enums.Icons.ChevronRight}
                            label="Verify now"
                            onClick={this.handleOpenVerifySmsModal}
                            size={Button.enums.Sizes.XSmall}
                            style={Button.enums.Styles.Outline}
                          />
                        </Fragment>
                      )}
                    </Row>
                  </DataGrid.DataGridRow>
                  <DataGrid.DataGridRow label="Password">
                    <Text
                      content="••••••••••••"
                      shouldWrap={false}
                      size={Text.enums.Sizes.XSmall}
                    />
                  </DataGrid.DataGridRow>
                </DataGrid.DataGridColumn>
              </DataGrid>
            )}
          </Card.CardSection>
        </Card>
        <Card
          header={{
            title: 'Business',
            actions: [
              {
                icon: Card.enums.ActionIcons.ExternalLink,
                label: 'Update on Stripe',
                link: {
                  path: `/outbound/stripe/update`,
                },
                isDisabled: !hasConnectAccount,
              },
            ],
          }}
          isLoading={this.props.isLoading}>
          {hasConnectAccount ? (
            <Card.CardSection>
              <DataGrid>
                <DataGrid.DataGridColumn>
                  <DataGrid.DataGridRow label="Business type">
                    <Text
                      content={viewer?.stripeConnectAccount?.businessType ? _.startCase(_.toLower(viewer.stripeConnectAccount.businessType)) : 'N/A'}
                      shouldWrap={false}
                      size={Text.enums.Sizes.Medium}
                    />
                  </DataGrid.DataGridRow>
                  {viewer?.stripeConnectAccount?.businessType === StripeConnectAccountBusinessTypes.COMPANY ? (
                    <Fragment>
                      <DataGrid.DataGridRow label="Business">
                        <Text
                          content={viewer.stripeConnectAccount.company.name ?? 'N/A'}
                          shouldWrap={false}
                          size={Text.enums.Sizes.Medium}
                        />
                      </DataGrid.DataGridRow>
                      <DataGrid.DataGridRow label="Tax ID">
                        <Text
                          color={
                            !viewer.stripeConnectAccount.company.taxIdProvided ?
                              Text.enums.Colors.Secondary :
                              undefined
                          }
                          content={viewer.stripeConnectAccount.company.taxIdProvided ? '•• – •••••••' : 'Not provided'}
                          shouldWrap={false}
                          size={
                            viewer.stripeConnectAccount.company.taxIdProvided ?
                              Text.enums.Sizes.XSmall :
                              Text.enums.Sizes.Medium
                          }
                        />
                      </DataGrid.DataGridRow>
                      <DataGrid.DataGridRow label="VAT ID">
                        <Text
                          color={
                            !viewer.stripeConnectAccount.company.vatIdProvided ?
                              Text.enums.Colors.Secondary :
                              undefined
                          }
                          content={viewer.stripeConnectAccount.company.vatIdProvided ? '•••••••••' : 'Not provided'}
                          shouldWrap={false}
                          size={
                            viewer.stripeConnectAccount.company.vatIdProvided ?
                              Text.enums.Sizes.XSmall :
                              Text.enums.Sizes.Medium
                          }
                        />
                      </DataGrid.DataGridRow>
                    </Fragment>
                  ) : null}
                  {viewer?.stripeConnectAccount?.businessType === StripeConnectAccountBusinessTypes.INDIVIDUAL ? (
                    <Fragment>
                      <DataGrid.DataGridRow label="Date of birth">
                        <Text
                          content={
                            dateOfBirth ? `${dateOfBirth.format('MMM D, YYYY')}` : 'Not provided'
                          }
                          shouldWrap={false}
                          size={Text.enums.Sizes.Medium}
                          color={!dateOfBirth ? Text.enums.Colors.Secondary : undefined}
                        />
                      </DataGrid.DataGridRow>
                      <DataGrid.DataGridRow label="SSN">
                        <Text
                          color={
                            !viewer.stripeConnectAccount.individual.ssnLast4Provided ?
                              Text.enums.Colors.Secondary :
                              undefined
                          }
                          content={viewer.stripeConnectAccount.individual.ssnLast4Provided ? '••• – •• – ••••' : 'Not provided'}
                          shouldWrap={false}
                          size={
                            viewer.stripeConnectAccount.individual.ssnLast4Provided ?
                              Text.enums.Sizes.XSmall :
                              Text.enums.Sizes.Medium
                          }
                        />
                      </DataGrid.DataGridRow>
                    </Fragment>
                  ) : null}
                  <DataGrid.DataGridRow label="Payouts status">
                    <Row
                      flexBehavior={Row.enums.FlexBehaviors.Default}
                      verticalAlignment={Row.enums.VerticalAlignments.Center}>
                      <Text
                        color={!viewer?.stripeConnectAccount?.payoutsEnabled ? Text.enums.Colors.Error : undefined}
                        content={viewer?.stripeConnectAccount?.payoutsEnabled ? 'Enabled' : 'Disabled'}
                        shouldWrap={false}
                        size={Text.enums.Sizes.Medium}
                      />
                      <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                      {viewer?.stripeConnectAccount?.payoutsEnabled ? (
                        <Icon
                          svg={Icon.enums.SVGs.Check}
                          color={Icon.enums.Colors.Green}
                          size={{
                            height: '16px',
                            width: '16px',
                          }}
                        />
                      ) : (
                        <Icon
                          svg={Icon.enums.SVGs.Alert}
                          color={Icon.enums.Colors.Red}
                          size={{
                            height: '16px',
                            width: '16px',
                          }}
                        />
                      )}
                    </Row>
                  </DataGrid.DataGridRow>
                </DataGrid.DataGridColumn>
                <DataGrid.DataGridColumn>
                  {viewer?.stripeConnectAccount?.businessType === StripeConnectAccountBusinessTypes.COMPANY ? (
                    <Fragment>
                      <DataGrid.DataGridRow label="Email">
                        <Text
                          color={
                            !viewer.stripeConnectAccount.email ?
                              Text.enums.Colors.Secondary :
                              undefined
                          }
                          content={viewer.stripeConnectAccount.email ?? 'Not provided'}
                          shouldWrap={false}
                          size={Text.enums.Sizes.Medium}
                        />
                      </DataGrid.DataGridRow>
                      <DataGrid.DataGridRow label="Phone">
                        <Text
                          color={
                            !viewer.stripeConnectAccount.company.phone ?
                              Text.enums.Colors.Secondary :
                              undefined
                          }
                          content={viewer.stripeConnectAccount.company.phone ?? 'Not provided'}
                          shouldWrap={false}
                          size={Text.enums.Sizes.Medium}
                        />
                      </DataGrid.DataGridRow>
                      <DataGrid.DataGridRow label="Address">
                        {viewer.stripeConnectAccount.company.address.line1 !== null ? (
                          <Fragment>
                            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                            <Text
                              content={`${viewer.stripeConnectAccount.company.address.line1}`}
                              size={Text.enums.Sizes.Medium}
                            />
                            {viewer.stripeConnectAccount.company.address.line2 ? (
                              <Text
                                content={viewer.stripeConnectAccount?.company.address.line2}
                                size={Text.enums.Sizes.Medium}
                              />
                            ) : null}
                            <Text
                              content={`${viewer.stripeConnectAccount.company.address.city}, ${viewer.stripeConnectAccount.company.address.state} ${viewer.stripeConnectAccount.company.address.postalCode}`}
                              size={Text.enums.Sizes.Medium}
                            />
                          </Fragment>
                        ) : (
                          <Text
                            color={Text.enums.Colors.Secondary}
                            content="Not provided"
                            shouldWrap={false}
                            size={Text.enums.Sizes.Medium}
                          />
                        )}
                      </DataGrid.DataGridRow>
                    </Fragment>
                  ) : null}
                  {viewer?.stripeConnectAccount?.businessType === StripeConnectAccountBusinessTypes.INDIVIDUAL ? (
                    <Fragment>
                      <DataGrid.DataGridRow label="Email">
                        <Text
                          color={
                            !viewer.stripeConnectAccount.email ?
                              Text.enums.Colors.Secondary :
                              undefined
                          }
                          content={viewer.stripeConnectAccount.email ?? 'Not provided'}
                          shouldWrap={false}
                          size={Text.enums.Sizes.Medium}
                        />
                      </DataGrid.DataGridRow>
                      <DataGrid.DataGridRow label="Phone">
                        <Text
                          color={
                            !viewer.stripeConnectAccount.individual.phone ?
                              Text.enums.Colors.Secondary :
                              undefined
                          }
                          content={viewer.stripeConnectAccount.individual.phone ?? 'Not provided'}
                          shouldWrap={false}
                          size={Text.enums.Sizes.Medium}
                        />
                      </DataGrid.DataGridRow>
                      <DataGrid.DataGridRow label="Address">
                        {viewer.stripeConnectAccount.individual.address.line1 !== null ? (
                          <Fragment>
                            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
                            <Text
                              content={`${viewer.stripeConnectAccount.individual.address.line1}`}
                              size={Text.enums.Sizes.Medium}
                            />
                            {viewer.stripeConnectAccount.individual.address.line2 ? (
                              <Text
                                content={viewer.stripeConnectAccount?.individual.address.line2}
                                size={Text.enums.Sizes.Medium}
                              />
                            ) : null}
                            <Text
                              content={`${viewer.stripeConnectAccount.individual.address.city}, ${viewer.stripeConnectAccount.individual.address.state} ${viewer.stripeConnectAccount.individual.address.postalCode}`}
                              size={Text.enums.Sizes.Medium}
                            />
                          </Fragment>
                        ) : (
                          <Text
                            color={Text.enums.Colors.Secondary}
                            content="Not provided"
                            shouldWrap={false}
                            size={Text.enums.Sizes.Medium}
                          />
                        )}
                      </DataGrid.DataGridRow>
                    </Fragment>
                  ) : null}
                </DataGrid.DataGridColumn>
              </DataGrid>
            </Card.CardSection>
          ) : (
            <Card.CardSection>
              <Center direction={Center.enums.Directions.Column}>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <Row
                  flexBehavior={Row.enums.FlexBehaviors.Default}
                  verticalAlignment={Row.enums.VerticalAlignments.Center}>
                  <Icon
                    svg={Icon.enums.SVGs.Alert}
                    color={Icon.enums.Colors.Red}
                    size={{
                      height: '20px',
                      width: '20px',
                    }}
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                  <Heading
                    content="Your account needs to be activated"
                    font={Heading.enums.Fonts.Secondary}
                    size={Heading.enums.Sizes.XSmall}
                  />
                </Row>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
                <Text content="Please activate your account before updating business details" />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <Button
                  color={Button.enums.Colors.Blue}
                  style={Button.enums.Styles.Secondary}
                  icon={Button.enums.Icons.ChevronRight}
                  label="Activate account"
                  link={{
                    path: '/',
                  }}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
              </Center>
            </Card.CardSection>
          )}
        </Card>
        <ChangePasswordModal
          isOpen={this.state.isChangePasswordModalOpen}
          onClose={this.handleCloseChangePasswordModal}
        />
      </ManageStage>
    );
  }
}

interface Address {
  city: string | null;
  country: string;
  line1: string | null;
  line2: string | null;
  postalCode: string | null;
  state: string | null;
}

interface BaseStripeConnectAccount {
  capabilities: StripeConnectAccountCapabilities;
  chargesEnabled: boolean;
  detailsSubmitted: boolean;
  email: string;
  id: string;
  payoutsEnabled: boolean;
  requirements: {
    currentlyDue: string[];
    disabledReason: string;
    pastDue: string[];
  };
}

interface StripeConnectAccountCompany extends BaseStripeConnectAccount {
  businessType: 'COMPANY';
  company: {
    address: Address;
    directorsProvided: boolean;
    executivesProvided: boolean;
    name: string;
    ownersProvided: boolean;
    phone: string;
    taxIdProvided: boolean;
    vatIdProvided: boolean;
  };
}

interface StripeConnectAccountIndividual extends BaseStripeConnectAccount {
  businessType: 'INDIVIDUAL';
  individual: {
    address: Address;
    dob: {
      day: number | null;
      month: number | null;
      year: number | null;
    } | null;
    firstName: string;
    lastName: string;
    phone: string;
    ssnLast4Provided: boolean;
    verification: {
      status: string;
    };
  };
}

type StripeConnectAccount = StripeConnectAccountCompany | StripeConnectAccountIndividual;

interface Viewer {
  email: string;
  formattedPhoneNumber: string | null;
  fullName: string;
  isEmailVerified: boolean;
  isPhoneNumberVerified: boolean;
  phoneNumber: string | null;
  stripeConnectAccount: StripeConnectAccount | null;
  uuid: string;
}

interface Response {
  viewer: Viewer | null;
}

interface QueryProps extends Response {
  isLoading: boolean;
}

export default compose(
  withQuery<{}, Response, {}, QueryProps>(query, {
    props: (props) => ({
      isLoading: props.loading,
      viewer: props.data?.viewer ?? null,
    }),
  }),
  withSendEmailVerificationEmail,
  withUpdateAccount,
)(AccountSettings);
