import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import * as enums from '../../enums';
import styles from './BrandCardTitle.scss';

interface Props {
  brand?: enums.Brands;
  content: string;
}

function BrandCardTitle(props: Props) {
  return (
    <h2
      className={classNames({
        [styles['brand-card-title']]: true,
        [styles[`brand-card-title--brand-${_.kebabCase(props.brand)}`]]: props.brand,
      })}>
      {props.content}
    </h2>
  );
}

export default withStyles(styles)(BrandCardTitle);
