import React, { ComponentType, PureComponent } from 'react';
import _ from 'lodash';

import Modal from '~tools/react/components/Modal';
import DocumentEmbedView from '~tools/react/components/DocumentEmbedView';

import graphql, { compose } from '~tools/react/graphql';
import enableEsignaturesOnDocumentMutation from '~tools/react/graphql/mutations/documents/enableEsignaturesOnDocument.gql';
import EsigningEmbedUrlQuery from './EsigningEmbedUrlQuery.gql';

import HelloSignModalView from './components/HelloSignModalView';

interface InputProps {
  documentUuid: string;
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}

interface Props extends InputProps {
  enableEsignaturesOnDocument: () => Promise<void>,
  eSigningEmbedUrl: string | null,
  isLoading: boolean,
  startPolling: (interval: number) => void,
  stopPolling: () => void,
}

class HelloSignModal extends PureComponent<Props> {
  isEnablingSignatures = false;

  async componentDidUpdate(prevProps: Props) {
    if (this.props.isOpen &&
      !this.props.eSigningEmbedUrl &&
      !this.isEnablingSignatures &&
      !this.props.isLoading) {
      this.isEnablingSignatures = true;
      await this.props.enableEsignaturesOnDocument();
      this.props.startPolling(1000);
    }

    if (!prevProps.eSigningEmbedUrl && this.props.eSigningEmbedUrl) {
      this.props.stopPolling();
      this.isEnablingSignatures = false;
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen}>
        <HelloSignModalView>
          <DocumentEmbedView
            containerId="hello-sign-container"
            isLoading={!this.props.eSigningEmbedUrl}
            onSuccess={this.props.onSuccess}
            onCancel={this.props.onCancel}
            embedUrl={this.props.eSigningEmbedUrl}
          />
        </HelloSignModalView>
      </Modal>
    );
  }
}

export default compose(
  graphql(EsigningEmbedUrlQuery, {
    skip: props => !props.documentUuid || !props.isOpen,
    options: ownProps => ({
      variables: {
        docUuid: ownProps.documentUuid,
      },
      fetchPolicy: 'cache-and-network',
      ssr: false,
    }),
    props: props => ({
      isLoading: props.data.loading,
      startPolling: props.data.startPolling,
      stopPolling: props.data.stopPolling,
      eSigningEmbedUrl: _.get(props.data.viewer, 'document.eSigningEmbedUrl'),
    }),
  }),
  graphql(enableEsignaturesOnDocumentMutation, {
    props: ({ mutate, ownProps }) => ({
      enableEsignaturesOnDocument: () =>
        mutate({
          variables: { docUuid: ownProps.documentUuid },
        }),
    }),
  }),
)(HelloSignModal) as ComponentType<InputProps>;
