export enum Colors {
  Blue = 'BLUE',
  BrandAdvancedPlan = 'BRAND_ADVANCED_PLAN',
  BrandBasicPlan = 'BRAND_BASIC_PLAN',
  BrandEnterprisePlan = 'BRAND_ENTERPRISE_PLAN',
  BrandLeaseholderPlan = 'BRAND_LEASEHOLDER_PLAN',
  BrandLitePlan = 'BRAND_LITE_PLAN',
  Gray = 'GRAY',
  Green = 'GREEN',
  Orange = 'ORANGE',
  Purple = 'PURPLE',
  Red = 'RED',
  Yellow = 'YELLOW',
}

export enum Icons {
  Alert = 'ALERT',
  Check = 'CHECK',
  Clock = 'CLOCK',
  Cross = 'CROSS',
  ExternalSend = 'EXTERNAL_SEND',
  Lock = 'LOCK',
  Mail = 'MAIL',
  Refresh = 'REFRESH',
}

export enum Sizes {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
}

export enum Styles {
  Inverse = 'INVERSE',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
}
