import React, { PureComponent } from 'react';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withUpdateListing, { UpdateListingProps } from '~tools/react/graphql/mutations/listings/withUpdateListing';

import Button from '~tools/react/components/Button';
import Form, { TextArea } from '~tools/react/components/Form';
import ThemedModal from '~tools/react/components/ThemedModal';

import query from './UpdateDescriptionModal.gql';

interface InputProps {
  isOpen: boolean;
  listingUuid: string;
  onClose: () => void;
}

type Props = InputProps & QueryProps & UpdateListingProps;

class UpdateDescriptionModal extends PureComponent<Props> {
  handleSubmit = async (data: {
    description: string;
    headline: string;
  }) => {
    await this.props.updateListing(this.props.listingUuid, data);
    this.props.onClose();
  };

  render() {
    const listing = this.props.listing;

    return (
      <ThemedModal
        width={ThemedModal.enums.Widths.Medium}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title="Description">
        <ThemedModal.ThemedModalSection>
          <Form onSubmit={this.handleSubmit}>
            <TextArea
              label="Description"
              labelFormat={TextArea.enums.LabelFormats.Stacked}
              name="description"
              placeholder="Why do you want in a tenant? What do you like about this place?"
              value={listing?.description}
              size={TextArea.enums.Sizes.Medium}
            />
            <Button
              align={Button.enums.Alignments.Right}
              label="Save"
              size={Button.enums.Sizes.Small}
              type={Button.enums.Types.Submit}
            />
          </Form>
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

interface Listing {
  addressUnit: {
    address: {
      streetAddress1: string;
      uuid: string;
    };
    name?: string;
    uuid: string;
  };
  bedrooms?: number;
  description?: string;
  updatedAt: string;
  uuid: string;
}

interface Viewer {
  listing: Listing;
  uuid: string;
}

interface Response {
  viewer: Viewer;
}

interface QueryProps {
  isLoading: boolean;
  listing: Listing | null;
}

export default compose(
  withUpdateListing,
  withQuery<InputProps, Response, {}, QueryProps>(query, {
    options: (props) => ({
      variables: {
        listingUuid: props.listingUuid,
      },
      skip: !props.isOpen,
    }),
    props: (props) => ({
      isLoading: props.loading,
      listing: props.data?.viewer.listing || null,
    }),
  }),
)(UpdateDescriptionModal);
