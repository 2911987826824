import _ from 'lodash';
import pluralize from 'pluralize';
import React, { Component } from 'react';

import Modal from '~tools/react/components/Modal';
import withStyles from '~tools/react/hocs/withStyles';
import Spinner from '~tools/react/components/Spinner';

import ListingSetupNavbar from '~web-manage/lib/common/scenes/ListingSetup/components/ListingSetupNavbar';
import { SavedPhoto } from '~web-manage/lib/common/scenes/ListingSetup/scenes/ListingSetupPhotos/types';

import ImportBuildingPhotosModalHeader from './components/ImportBuildingPhotosModalHeader';
import ImportBuildingPhotosModalPhoto from './components/ImportBuildingPhotosModalPhoto';
import ImportBuildingPhotosModalPhotoView from './components/ImportBuildingPhotosModalPhotoView';

import styles from './ImportBuildingPhotosModal.scss';

interface Props {
  buildingName: string;
  buildingPhotos: SavedPhoto[];
  copyPhotosToListing: (photos: SavedPhoto[]) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
}

interface State {
  isLoading: boolean;
  selectedPhoto?: SavedPhoto;
  addedPhotos: SavedPhoto[];
}

class ImportBuildingPhotosModal extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      selectedPhoto: undefined,
      addedPhotos: [],
      isLoading: false,
    };
  }

  handleAddPhoto = () => {
    if (this.state.selectedPhoto) {
      this.setState({
        selectedPhoto: undefined,
        addedPhotos: _.concat(this.state.addedPhotos, [this.state.selectedPhoto]),
      });
    }
  };

  handleRemovePhoto = () => {
    if (this.state.selectedPhoto) {
      this.setState({
        selectedPhoto: undefined,
        addedPhotos: _.reject(this.state.addedPhotos, { uuid: this.state.selectedPhoto.uuid }),
      });
    }
  };

  handleClickBack = () => this.setState({ selectedPhoto: undefined });

  handleSelectPhoto = p => this.setState({ selectedPhoto: p });

  handleCopyPhotos = async () => {
    this.setState({ isLoading: true });
    await this.props.copyPhotosToListing(this.state.addedPhotos);
    this.setState({ addedPhotos: [], isLoading: false });
    this.props.onClose();
  }

  render() {
    const isSelectedPhotoAdded = this.state.selectedPhoto &&
      _.find(this.state.addedPhotos, { uuid: this.state.selectedPhoto.uuid });
    const primaryLabel = isSelectedPhotoAdded ? 'Remove photo' : 'Select photo';
    const totalPhotosAdded = _.size(_.filter(_.values(this.state.addedPhotos)));
    const primaryAction = isSelectedPhotoAdded ? this.handleRemovePhoto : this.handleAddPhoto;
    return (
      <Modal
        shouldRenderScreen={false}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}>
        <Modal isOpen={this.state.isLoading}>
          <Spinner />
        </Modal>
        <div styleName="building-modal">
          <ImportBuildingPhotosModalHeader
            description="Photos can always be removed and added later"
            isActive={!!this.state.selectedPhoto}
            onClickBack={this.handleClickBack}
            onClose={this.props.onClose}
            title="Import photos"
            totalPhotosAdded={totalPhotosAdded}
            subtitle={this.props.buildingName}
          />
          <ImportBuildingPhotosModalPhotoView isActive={!!this.state.selectedPhoto}>
            {this.state.selectedPhoto ? (
              <ImportBuildingPhotosModalPhoto isActive photo={this.state.selectedPhoto} />
            ) : (
              _.map(this.props.buildingPhotos, (photo, index) => (
                <ImportBuildingPhotosModalPhoto
                  isAdded={_.find(this.state.addedPhotos, { uuid: photo.uuid })}
                  onClick={this.handleSelectPhoto}
                  photo={photo}
                  key={`${photo.uuid}-${index}`}
                />
              ))
            )}
          </ImportBuildingPhotosModalPhotoView>
          <div styleName="building-modal__nav">
            <ListingSetupNavbar
              marginTopSize={ListingSetupNavbar.enums.MarginTopSize.None}
              type={ListingSetupNavbar.enums.Types.Action}
              secondaryAction={{
                label: this.state.selectedPhoto ? 'Back' : 'Close',
                onClick: this.state.selectedPhoto ? this.handleClickBack : this.props.onClose,
              }}
              primaryAction={{
                onClick: this.state.selectedPhoto ? primaryAction : this.handleCopyPhotos,
                isDisabled: this.state.selectedPhoto ? false : this.state.addedPhotos.length === 0,
                label: this.state.selectedPhoto
                  ? primaryLabel
                  : `Add ${
                    totalPhotosAdded ? `${totalPhotosAdded} ${pluralize('photo', totalPhotosAdded)}` : 'photos'
                  } to listing`,
              }}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(ImportBuildingPhotosModal);
