// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._7RAi8p p{color:#273444;font-weight:100;margin:0 0 24px}._7RAi8p ._1qACx9{border-top:1px solid #e6e8eb;display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row;-webkit-box-pack:end;justify-content:flex-end;margin:24px 0 0;padding:24px 0 0}._7RAi8p ._1qACx9 button:not(:last-child){margin-right:24px}", ""]);
// Exports
exports.locals = {
	"verify-postcard-confirmation": "_7RAi8p",
	"verify-postcard-confirmation__btns": "_1qACx9"
};
module.exports = exports;
