import React, { Component } from 'react';
import _ from 'lodash';

import { ChevronDown } from '~web-core/lib/common/svgs/icons/directional';
import withStyles from '~tools/react/hocs/withStyles';

import * as enums from '~tools/react/components/PhotoEditorModal/enums';
import { Tag } from '~tools/react/components/PhotoEditorModal/types';

import PhotoEditorModalFilterView from '../PhotoEditorModalFilterView';
import styles from './PhotoEditorModalFilterTags.scss';

interface Props {
  isActive: boolean;
  mediaTags: Tag[];
  onChange: (tag: Tag) => Promise<void>;
  onClickTab: (tab: enums.Filters) => void;
  primaryAction: () => void;
  value?: Tag | null;
}

class PhotoEditorModalFilterTags extends Component<Props> {
  handleChange = (e) => {
    const tag = _.find(this.props.mediaTags, { uuid: e.currentTarget.value });
    if (!tag) return;
    return this.props.onChange(tag);
  };

  render() {
    return (
      <PhotoEditorModalFilterView
        primaryAction={this.props.primaryAction}
        onClickTab={this.props.onClickTab}
        isActive={this.props.isActive}
        filter={enums.Filters.Tag}>
        <div styleName="filter-tags">
          <select placeholder="Select a tag" value={_.get(this.props.value, 'uuid', '')} onChange={this.handleChange}>
            <option disabled value="">
              Select a tag
            </option>
            {_.map(this.props.mediaTags, tag => (
              <option key={tag.uuid} value={tag.uuid}>
                {_.upperFirst(_.lowerCase(tag.name))}
              </option>
            ))}
          </select>
          <ChevronDown />
        </div>
        <div styleName="filter-tags-desc">
          <p>Tags help renters understand which room they are viewing</p>
        </div>
      </PhotoEditorModalFilterView>
    );
  }
}

export default withStyles(styles)(PhotoEditorModalFilterTags);
