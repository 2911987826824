import { DocumentNode } from 'graphql';
import hoistStatics from 'hoist-non-react-statics';
import _ from 'lodash';
import React, { ComponentType } from 'react';
import { Query, QueryFunctionOptions, QueryResult } from 'react-apollo';

import { removeNodesAndEdges } from '~tools/react/graphql';

function withQuery<TInputProps, TData, TVariables, TOuputProps>(
  query: DocumentNode,
  config: {
    options?: QueryFunctionOptions<TVariables> | ((props: TInputProps) => QueryFunctionOptions<TVariables>);
    props: (props: {
      ownProps: TInputProps;
      data: TData | undefined;
      variables: TVariables;
    } & QueryResult<TData, TVariables>) => TOuputProps;
  }
) {
  return (ComposedComponent: ComponentType<TInputProps & TOuputProps>) => {
    const displayName = `withQuery(${ComposedComponent.displayName || ComposedComponent.name || 'Component'})`;

    function WithQuery(props: TInputProps) {
      const options = _.isFunction(config.options) ? config.options(props) : config.options;
      return (
        <Query<TData, TVariables>
          fetchPolicy={options ? options.fetchPolicy : undefined}
          context={options ? options.context : undefined}
          notifyOnNetworkStatusChange={options ? options.notifyOnNetworkStatusChange : undefined}
          query={query}
          ssr={options ? options.ssr : false}
          skip={options ? !!options.skip : false}
          // @ts-ignore idfk
          variables={options ? options.variables : undefined}>
          {(queryProps) => {
            const passedProps = config.props({
              ...queryProps,
              data: removeNodesAndEdges(queryProps.data),
              ownProps: props,
              variables: queryProps.variables,
            });
            return (
              <ComposedComponent {...passedProps} {...props} />
            );
          }}
        </Query>
      );
    }

    WithQuery.displayName = displayName;

    return hoistStatics(WithQuery, ComposedComponent);
  };
}

export default withQuery;
