// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._5aADq3{border-bottom-left-radius:5px;border-bottom-right-radius:5px;overflow:hidden;width:400px}@media screen and (max-width:1127px){._5aADq3{width:100vw}}", ""]);
// Exports
exports.locals = {
	"notifications": "_5aADq3"
};
module.exports = exports;
