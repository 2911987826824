import classNames from 'classnames';
import _ from 'lodash';
import React, { ChangeEvent, KeyboardEvent, MouseEvent, PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import { KeyCodes } from '~web-core/lib/common/enums/javascript';
import MagnifyingGlass from '~tools/svgs/icons/interface/search.svg';
import { SearchableItem } from '~web-manage/lib/common/stages/ManageStage/containers/Navigation/containers/Search/types';

import Spinner from '~tools/react/components/Spinner';

import SearchTypeaheadItem from './components/SearchTypeaheadItem';

import * as enums from './enums';

import styles from './SearchTypeahead.scss';

export interface Props {
  isLoading?: boolean;
  items: SearchableItem[];
  onChange?: (arg: string) => void;
  onSelectItem: (item: SearchableItem) => void;
  placeholder?: string;
  value: string;
}

interface State {
  isVisible: boolean;
  selectedIndex: number;
}

class SearchTypeahead extends PureComponent<Props, State> {
  state: State = {
    isVisible: false,
    selectedIndex: -1,
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickDocument);
  }

  handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.keyCode) {
      case KeyCodes.DOWN: {
        this.setState({
          isVisible: true,
          selectedIndex: this.state.selectedIndex + 2 > this.props.items.length ? 0 : this.state.selectedIndex + 1,
        });
        break;
      }
      case KeyCodes.UP: {
        this.setState({
          isVisible: true,
          selectedIndex: this.state.selectedIndex - 1 < 0 ? this.props.items.length - 1 : this.state.selectedIndex - 1,
        });
        break;
      }
      case KeyCodes.ENTER: {
        const item = this.props.items[this.state.selectedIndex];
        if (item) {
          this.props.onSelectItem(this.props.items[this.state.selectedIndex]);
          this.closeTypeahead();
        }
        e.preventDefault();
        e.stopPropagation();
        break;
      }
      case KeyCodes.ESCAPE: {
        this.closeTypeahead();
        e.preventDefault();
        e.stopPropagation();
        break;
      }
      default:
        break;
    }
  }

  handleClick = (e: MouseEvent<EventTarget>) => {
    e.nativeEvent.stopImmediatePropagation();
  }

  handleClickDocument = () => {
    this.closeTypeahead();
  }

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const element = e.currentTarget;
    const data = element.value;
    this.openTypeahead();
    if (this.props.onChange) this.props.onChange(data);
  }

  render() {
    return (
      <div
        onClick={this.handleClick}
        styleName="search-typeahead">
        <div
          className={classNames({
            [styles['search-typeahead__input']]: true,
            [styles['search-typeahead__input--expanded']]: this.state.isVisible,
          })}>
          <input
            autoComplete="off"
            onChange={this.handleChange}
            onFocus={this.openTypeahead}
            onKeyDown={this.handleKeyDown}
            placeholder={this.props.placeholder}
            value={this.props.value}
          />
          <MagnifyingGlass />
        </div>
        {this.state.isVisible ? (
          <div styleName="search-typeahead__view">
            {_.map(this.props.items, (item, index) => (
              <SearchTypeaheadItem
                currentSearch={this.props.value}
                isSelected={this.state.selectedIndex === index}
                path={item.path}
                label={item.label}
                description={item.description}
                icon={item.icon}
                key={item.path}
                onClick={this.closeTypeahead}
              />
            ))}
          </div>
        ) : null}
        <div
          className={classNames({
            [styles['search-typeahead__spinner']]: true,
            [styles['search-typeahead__spinner--visible']]: this.props.isLoading,
          })}>
          <Spinner color={Spinner.enums.Colors.White} />
        </div>
      </div>
    );
  }

  closeTypeahead = () => {
    document.removeEventListener('click', this.handleClickDocument);
    this.setState({ isVisible: false });
  }

  openTypeahead = () => {
    document.addEventListener('click', this.handleClickDocument);
    this.setState({ isVisible: true });
  }
}

const StyledSearchTypahead = withStyles(styles)(SearchTypeahead);
export default _.assign(StyledSearchTypahead, { enums });
