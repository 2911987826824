// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._RnEWdp{height:1px;white-space:nowrap}._RnEWdp ._ZJNAbA{background-color:#f6f8fa;color:#182029;font-size:14px;font-weight:300;height:100%;overflow:hidden;padding:12px 8px;text-overflow:ellipsis;white-space:nowrap}._RnEWdp ._br00da{display:block;height:100%}._RnEWdp._0N5kVO{text-align:left}._RnEWdp._ern09Z{text-align:right}._RnEWdp._vqQ89M{width:1px}._RnEWdp._BX0EDR{width:auto}._RnEWdp:first-of-type ._ZJNAbA{padding-left:24px}._RnEWdp:last-of-type ._ZJNAbA{padding-right:24px}", ""]);
// Exports
exports.locals = {
	"table-header": "_RnEWdp",
	"table-header__content": "_ZJNAbA",
	"table-header__span": "_br00da",
	"table-header--alignment-left": "_0N5kVO",
	"table-header--alignment-right": "_ern09Z",
	"table-header--width-minimized": "_vqQ89M",
	"table-header--width-auto": "_BX0EDR"
};
module.exports = exports;
