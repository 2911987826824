export enum Filters {
  Brightness = 'BRIGHTNESS',
  Contrast = 'CONTRAST',
  Flip = 'FLIP',
  Rotate = 'ROTATE',
  Saturation = 'SATURATION',
  Tag = 'TAG',
  Highlight = 'HIGHLIGHT',
  Shadow = 'SHADOW',
}
