import React, { PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import InfoSVG from '~tools/svgs/icons/interface/info.svg';

import Tooltip from '~tools/react/components/Tooltip';

import styles from './InfoTooltip.scss';

interface Props {
  size?: number;
  tooltipText: string;
}

class InfoTooltip extends PureComponent<Props> {
  render() {
    return (
      <div
        style={this.props.size ? {
          width: `${this.props.size}px`,
          height: `${this.props.size}px`,
        } : undefined}
        styleName="info-tooltip">
        <Tooltip
          position={Tooltip.enums.Positions.Up}
          text={this.props.tooltipText}>
          <InfoSVG
            style={this.props.size ? {
              width: `${this.props.size}px`,
              height: `${this.props.size}px`,
            } : undefined}
            styleName="info-icon"
          />
        </Tooltip>
      </div>
    );
  }
}

export default withStyles(styles)(InfoTooltip);
