import _ from 'lodash';
import moment from 'moment';
import React, {
  Component,
  Fragment,
} from 'react';
import { RouteComponentProps } from 'react-router';

import graphql, { compose } from '~tools/react/graphql';
import renderSubleaseAgreementMutation from '~tools/react/graphql/mutations/documents/renderSubleaseAgreement.gql';

import ModalStage from '~web-manage/lib/common/stages/ModalStage';

import Button from '~tools/react/components/Button';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import MapListingCard from '~tools/react/components/MapListingCard';
import Row from '~tools/react/components/Row';
import SkeletonBodyText from '~tools/react/components/SkeletonBodyText';
import ThemedModal from '~tools/react/components/ThemedModal';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import WidthConstraintView from '~tools/react/components/WidthConstraintView';
import { ListingTypes, RenderSubleaseAgreementInputType } from '~tools/types/graphqlSchema';

import AnimationView from './components/AnimationView';
import ManageListingNextStepsFooter from './components/ManageListingNextStepsFooter';
import ManageListingNextStepsHeader from './components/ManageListingNextStepsHeader';
import ManageListingNextStepsSubleasePreview from './components/ManageListingNextStepsSubleasePreview';
import NextSteps from './components/NextSteps';

import query from './ListingSetupConfirmation.gql';

interface QueryProps {
  renderSubleaseAgreement: (input: RenderSubleaseAgreementInputType) => Promise<{ content: string }>;
  listing: {
    bathrooms: number;
    bedrooms: number;
    city: string;
    description: string;
    earliestMoveInDate: string;
    isEligibleForTakeover: boolean;
    isVerified: boolean;
    listingType: ListingTypes;
    locationString: string;
    minLeaseTerm: number;
    neighborhood: string | null;
    photos: { uuid: string, url: string, position: number }[];
    propertyManagerContract: {
      uuid: string;
    };
    rentInCents: number;
    slug: string;
    state: string;
    streetAddress: string;
    uuid: string;
  };
  hasPayoutAccount: boolean;
  isLoading: boolean;
  viewer: {
    photoUrl: string;
    fullName: string;
  };
}

interface State {
  isSubleasePreviewModalOpen: boolean;
  subleasePreviewUrl: string | null;
}

type Props = QueryProps
  & RouteComponentProps<{ listingUuid: string }>;

class ListingSetupConfirmation extends Component<Props, State> {
  state = {
    isSubleasePreviewModalOpen: false,
    subleasePreviewUrl: null,
  };

  handlePreviewSubleaseDocument = async () => {
    const subleasePreview = await this.props.renderSubleaseAgreement({
      hasSmartLock: false,
      hasPaymentMethodsRequirement: false,
      hasRentGuarentee: true,
      hasTOSAgreement: true,
      leaseEndString: moment(this.props.listing.earliestMoveInDate).add(this.props.listing.minLeaseTerm, 'month').format('MMMM Do, YYYY'),
      leaseStartString: moment(this.props.listing.earliestMoveInDate).format('MMMM Do, YYYY'),
      rentString: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.props.listing.rentInCents / 100).toString(),
      streetAddress: this.props.listing.streetAddress,
      sublessee: '<Sublessee>',
      sublessor: this.props.viewer.fullName,
    });

    this.setState({ subleasePreviewUrl: subleasePreview.content });
    this.togglePreviewModal();
  };

  render() {
    const listing = this.getListingForMapCard();
    return (
      <ModalStage
        closeRedirectPath={`/units/${listing.propertyManagerContract?.uuid}`}
        title="List this listing">
        <ThemedModal onClose={this.togglePreviewModal} title="Lease preview" isOpen={this.state.isSubleasePreviewModalOpen}>
          <ThemedModal.ThemedModalSection>
            <ManageListingNextStepsSubleasePreview subleasePreviewUrl={this.state.subleasePreviewUrl} />
          </ThemedModal.ThemedModalSection>
        </ThemedModal>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXLarge} />
        <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXLarge} />
          <WidthConstraintView hasAutoMargin={false}>
            <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
              <AnimationView animation={AnimationView.enums.Animations.FadeUp}>
                <ManageListingNextStepsHeader />
                {this.props.isLoading ? (
                  <SkeletonBodyText hasBlock={true} lines={2} />
                ) : (
                  <Fragment>
                    <NextSteps>
                      <NextSteps.NextStepsCard
                        description="Add viewing windows so that renters can come see the place in person"
                        icon={NextSteps.NextStepsCard.enums.Icons.BuildingCheck}
                        title="Set up viewings"
                        linkPath={`/units/${listing.propertyManagerContract?.uuid}/access`}
                        label="Set up viewings"
                      />
                      {this.props.hasPayoutAccount ? (
                        <NextSteps.NextStepsCard
                          description="Send all future payments directly to your bank account or debit card for free"
                          icon={NextSteps.NextStepsCard.enums.Icons.Money}
                          title="Get payments"
                          linkPath="/account/payments"
                          label="Setup payouts"
                        />
                      ) : null}
                    </NextSteps>
                  </Fragment>
                )}
                <ManageListingNextStepsFooter>
                  <Button
                    align={Button.enums.Alignments.Right}
                    label="Continue"
                    link={{
                      path: `/units/${listing.propertyManagerContract?.uuid}`,
                    }}
                    type={Button.enums.Types.Submit}
                  />
                </ManageListingNextStepsFooter>
              </AnimationView>
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXLarge} />
              {!this.props.isLoading ? (
                <AnimationView
                  animation={AnimationView.enums.Animations.FadeUp}
                  delay="0.25s">
                  <MapListingCard listing={listing} />
                </AnimationView>
              ) : null}
            </Row>
          </WidthConstraintView>
          <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXLarge} />
        </Row>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXLarge} />
      </ModalStage>
    );
  }

  getListingForMapCard = () => ({
    description: this.props.listing.description,
    earliestMoveInDate: this.props.listing.earliestMoveInDate,
    images: _.map(this.props.listing.photos, p => ({ url: p.url, position: p.position, id: p.uuid })),
    locationString: this.props.listing.locationString,
    numBathrooms: this.props.listing.bathrooms,
    numBedrooms: this.props.listing.bedrooms,
    propertyManagerContract: this.props.listing.propertyManagerContract,
    rentInDollars: this.props.listing.rentInCents / 100,
    slug: this.props.listing.slug,
    title: `${_.capitalize(_.lowerCase(this.props.listing.listingType))} in ${this.props.listing.neighborhood || this.props.listing.city}, ${this.props.listing.state}`,
    type: _.startCase(_.lowerCase(this.props.listing.listingType)),
    user: { fullName: this.props.viewer.fullName, imageUrl: this.props.viewer.photoUrl },
  })

  togglePreviewModal = () => this.setState({ isSubleasePreviewModalOpen: !this.state.isSubleasePreviewModalOpen });
}

export default compose(
  graphql(query, {
    options: props => ({
      variables: {
        listingUuid: props.match.params.listingUuid,
      },
    }),
    props: props => ({
      buildingUuid: _.get(props.data.viewer, 'listing.building.uuid'),
      hasPayoutAccount: !_.size(_.get(props.data.viewer, 'payoutAccounts', [])),
      isLoading: props.data.loading,
      listing: _.get(props.data.viewer, 'listing', {}),
      viewer: props.data.viewer || {},
    }),
  }),
  graphql(renderSubleaseAgreementMutation, {
    props: props => ({
      renderSubleaseAgreement: async (input) => {
        const res = await props.mutate({
          variables: {
            input,
          },
        });

        return res.data.renderSubleaseAgreement;
      },
    }),
  }),
)(ListingSetupConfirmation);
