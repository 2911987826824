// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._BX0iq8{position:relative;width:75%}", ""]);
// Exports
exports.locals = {
	"chart-view": "_BX0iq8"
};
module.exports = exports;
