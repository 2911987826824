import React from 'react';
import { Redirect } from 'react-router';

function ServiceProviders() {
  // Just redirects to maintenance settings for backwards compatability.
  return (
    <Redirect to="/settings/maintenance" />
  );
}

export default ServiceProviders;
