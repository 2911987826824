import withMutation from '~tools/react/graphql/withMutation';
import { AttachEvidenceInputType } from '~tools/types/graphqlSchema';

import AttachEvidence from './attachEvidence.gql';

interface Claim {
  amountInCents: number;
  createdAt: string;
  description: string;
  uuid: string;
}

interface Data {
  attachEvidence: Claim;
}

interface Variables {
  uuid: string;
  input: AttachEvidenceInputType;
}

export interface AttachEvidenceProps {
  attachEvidence: (claimUuid: string, input: AttachEvidenceInputType) => Promise<Claim>;
}

export default withMutation<{}, Data, Variables, AttachEvidenceProps>(AttachEvidence, {
  props: props => ({
    attachEvidence: async (claimUuid: string, input: AttachEvidenceInputType) => {
      const res = await props.mutate({
        variables: {
          uuid: claimUuid,
          input,
        },
      });
      if (!res || !res.data) throw new Error('Error attaching evidence to claim.');

      return res.data.attachEvidence;
    },
  }),
});
