export { Themes } from '~tools/react/hocs/withTheme/enums';

export enum Alignments {
  Center = 'CENTER',
  Left = 'LEFT',
  Right = 'RIGHT',
}

export enum Colors {
  Black = 'BLACK',
  Blue = 'BLUE',
  BrandAdvancedPlan = 'BRAND_ADVANCED_PLAN',
  BrandBasicPlan = 'BRAND_BASIC_PLAN',
  BrandEnterprisePlan = 'BRAND_ENTERPRISE_PLAN',
  BrandLeaseholderPlan = 'BRAND_LEASEHOLDER_PLAN',
  BrandLitePlan = 'BRAND_LITE_PLAN',
  DarkBlue = 'DARK_BLUE',
  FacebookBlue = 'FACEBOOK_BLUE',
  Gray = 'GRAY',
  Green = 'GREEN',
  Orange = 'ORANGE',
  Purple = 'PURPLE',
  Red = 'RED',
  White = 'WHITE',
  Yellow = 'YELLOW',
}

export enum Icons {
  Bell = 'BELL',
  BrandAccess = 'BRAND_ACCESS',
  Check = 'CHECK',
  ChevronDown = 'CHEVRON_DOWN',
  ChevronLeft = 'CHEVRON_LEFT',
  ChevronRight = 'CHEVRON_RIGHT',
  ChevronUp = 'CHEVRON_UP',
  Cross = 'CROSS',
  Download = 'DOWNLOAD',
  Edit = 'EDIT',
  Ellipsis = 'ELLIPSIS',
  ExternalLink = 'EXTERNAL_LINK',
  Facebook = 'FACEBOOK',
  Filter = 'FILTER',
  MagnifyingGlass = 'MAGNIFYING_GLASS',
  Mail = 'MAIL',
  Message = 'MESSAGE',
  Paperclip = 'PAPERCLIP',
  Plus = 'PLUS',
  RSS = 'RSS',
  Refresh = 'REFRESH',
  Send = 'SEND',
  Trash = 'TRASH',
  Twitter = 'TWITTER',
}

export enum Sizes {
  XLarge = 'XLARGE',
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
  XSmall = 'XSMALL',
}

export enum Styles {
  Inverse = 'INVERSE',
  Outline = 'OUTLINE',
  Secondary = 'SECONDARY',
}

export enum Types {
  Button = 'BUTTON',
  Reset = 'RESET',
  Submit = 'SUBMIT',
}

export enum Widths {
  Auto = 'AUTO',
  Full = 'FULL',
}
