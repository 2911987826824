import _ from 'lodash';
import loadable from '@loadable/component';

import * as enums from './enums';
import CarouselSlide from './components/CarouselSlide';

const Carousel = loadable(() => import(/* webpackChunkName: "carousel" */'./Carousel'));

export default _.assign(Carousel, {
  enums,
  CarouselSlide,
});
