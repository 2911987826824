import _ from 'lodash';
import { AsYouType } from 'libphonenumber-js/min';
import Globals from '../globals';
import { LISTING_FIELDS } from '../constants';

export { default as analytics } from './analytics';
export { default as countdown } from './countdown';
export { default as dateHelpers } from './dateHelpers';
export { default as featureFlags } from './featureFlags';
export { default as form } from './form';
export { default as ManageListingsUtils } from './manageListing';
export { default as pluralHelper } from './plural';
export { default as stateHelper } from './stateHelper';

const {
  LATITUDE,
  LONGITUDE,
  AREA_CODE,
  NEIGHBORHOOD,
  STREET_ADDRESS,
  CITY,
  STATE,
} = LISTING_FIELDS;

String.prototype.capFirst = function(string) {
  const newString = string || this;
  return newString.charAt(0).toUpperCase() + newString.slice(1);
};

export function bindTrailingArgs(ClassName, ...boundArgs) {
  return class BoundClass extends ClassName {
    constructor(args) {
      super(args, ...boundArgs);
    }
  };
}

/* eslint-disable */
export function decodeEntities(result, value, key) {
  if (!value) {
    result[key] = value;
    return;
  } else if (typeof value === 'string') {
    result[key] = _.unescape(value);
    return;
  } else if (typeof value === 'object') {
    result[key] = _.transform(value, decodeEntities);
    return;
  }

  result[key] = value;
}
/* eslint-enable */

export function removeLeadingSlashes(string) {
  return string.replace(/^\/+/g, '');
}

export function formatPhoneNumber(value) {
  if (!value) return '';

  let phoneNumber = value.replace('+', '');
  if (phoneNumber.replace(/\D/g, '').length > 10) {
    phoneNumber = `+${phoneNumber}`;
  }

  if (phoneNumber.replace(/\D/g, '').length > 15)
    phoneNumber = phoneNumber.replace(/\D/g, '').slice(0, 15);

  // Remove trailing non-numeric characters
  return new AsYouType('US').input(phoneNumber).replace(/[^a-z\d]*$/gi, '');
}

export function getApiPath(route, role): string {
  if (!role || role < 9) return Globals.API_PATH + route;

  let delimiter = '?';
  if (route.indexOf('?') !== -1) delimiter = '&';
  return `${Globals.API_PATH + route + delimiter}superman`;
}

export function formatMoneyString(amount, useFloat = false): string {
  if (!amount && amount !== 0) return '';

  const value = useFloat ? parseFloat(amount) : parseInt(amount, 10);
  return value
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function moneyStringToFloat(str): number {
  return parseFloat(str.split('.')[0].replace(/\D/g, ''));
}

export function parseErrorResponse(resp) {
  try {
    const errorResp = JSON.parse(resp.text);
    let errorMessage;
    let messageString = '';

    if (_.has(errorResp, 'error')) {
      errorMessage = errorResp.error;
    } else if (_.has(errorResp, 'errors')) {
      errorMessage = errorResp.errors;
    } else {
      messageString =
        'Sorry! We ran in to an error. If this problem persists, please contact support.';
    }

    if (_.isArray(errorMessage)) {
      messageString = 'We ran in to a few errors when trying to save this: ';
      _.each(errorMessage, (message) => {
        messageString = `${messageString + message}.<br>`;
      });
    } else {
      messageString = errorMessage;
    }

    return messageString;
  } catch (err) {
    return 'Sorry! We ran in to an error. If this problem persists, please contact support.';
  }
}

export function encodeLocationSlug(value): string {
  if (!value) return '';

  return value.replace(/, /g, '--').replace(/ /g, '-');
}

export function decodeLocationSlug(value): string {
  if (!value) return '';

  return value.replace(/--/g, ', ').replace(/-/g, ' ');
}

export function getImageStyling(props, urlSize) {
  if (!props) return {};

  const height = parseInt(props.height, 10);
  const width = parseInt(props.width, 10);
  const styles = {};

  if (width && height) {
    styles.backgroundSize = height > width ? 'contain' : 'cover';
  }
  styles.backgroundImage = `url("${props[urlSize]}")`;
  return styles;
}

export function mirrorKeys(keys) {
  let obj = {};
  _.forEach(keys, (key) => (obj[_.toUpper(_.snakeCase(key))] = key));
  return obj;
}

export function getFieldValues(data, fields) {
  return _.mapValues(_.pick(data, fields), (val) => val);
}

export function setFieldValues(data, fields) {
  return _.mapValues(_.pick(data, fields), (val) => val);
}

export function parseMapAddress(data, fieldData) {
  const addressComponents = data.gmaps
    ? data.gmaps.address_components
    : data.address_components;

  let streetNumber;
  let location;
  let route;

  if (data.location) {
    location = data.location;
  } else {
    location = {
      lat: data.geometry.location.lat(),
      lng: data.geometry.location.lng(),
    };
  }

  fieldData[LATITUDE] = location.lat;
  fieldData[LONGITUDE] = location.lng;

  fieldData[AREA_CODE] = null;
  fieldData[NEIGHBORHOOD] = null;
  fieldData[CITY] = null;
  fieldData[STATE] = null;

  _.map(addressComponents, (component) => {
    _.map(component.types, (type) => {
      switch (type) {
        case 'street_number':
          streetNumber = component.long_name;
          break;
        case 'route':
          route = component.long_name;
          break;
        case 'postal_code':
          fieldData[AREA_CODE] = component.long_name;
          break;
        case 'neighborhood':
          fieldData[NEIGHBORHOOD] = component.long_name;
          break;
        case 'sublocality':
        case 'locality':
          fieldData[CITY] = component.long_name;
          break;
        case 'administrative_area_level_1':
          fieldData[STATE] = component.short_name;
          break;
        default:
          break;
      }
    });
  });
  if (streetNumber) {
    fieldData[STREET_ADDRESS] = streetNumber;
  }

  if (route) {
    fieldData[STREET_ADDRESS] = route;
  }

  if (streetNumber && route) {
    fieldData[STREET_ADDRESS] = `${streetNumber} ${route}`;
  }

  return fieldData;
}

export function monthDiff(d1, d2) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth() + 1;
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export const MOBILE_REGEX = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

export const EMOJI_REGEX = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

export const isNullOrUndefined = (item, ...rest) => {
  let restBool = false;
  if (rest && rest.length > 0)
    restBool = _.every(rest, (r) => isNullOrUndefined(r));
  return _.isNull(item) || _.isUndefined(item) || restBool;
};

export function parseGQLError(err) {
  let finalError = {};
  _.map(err.graphQLErrors, (error) => {
    if (error && error.message && _.isObject(error.message)) {
      _.map(error.message, (val, key) => {
        finalError[key] = _.head(val);
      });
    } else if (error.message && _.isString(error.message)) {
      finalError = error;
    }
  });
  return finalError;
}
