import withMutation from '~tools/react/graphql/withMutation';

import MarkNotificationsAsReadMutation from './markNotificationsAsRead.gql';

interface Data {
  markNotificationsAsRead: boolean;
}

export interface MarkNotificationsAsReadProps {
  markNotificationsAsRead: () => Promise<boolean>;
}

export default withMutation<{}, Data, {}, MarkNotificationsAsReadProps>(MarkNotificationsAsReadMutation, {
  props: props => ({
    markNotificationsAsRead: async () => {
      const res = await props.mutate({
        optimisticResponse: {
          markNotificationsAsRead: true,
        },
      });
      if (!res || !res.data) throw new Error('Error marking notifications as read.');

      return res.data.markNotificationsAsRead;
    },
  }),
});
