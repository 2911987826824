import classNames from 'classnames';
import _ from 'lodash';
import React, { PureComponent } from 'react';

import AutoResizingTextarea from '~tools/react/components/AutoResizingTextarea';
import Button from '~tools/react/components/Button';
import Tooltip from '~tools/react/components/Tooltip';
import withStyles from '~tools/react/hocs/withStyles';
import { KeyCodes } from '~web-core/lib/common/enums/javascript';
import { Paperclip } from '~web-core/lib/common/svgs/icons/interface';

import InputAttachment from './components/InputAttachment';
import styles from './Input.scss';

import { LocalAttachment } from '../../types';

const SUPPORTED_PASTE_TYPES = ['image/jpeg', 'image/png', 'image/tiff', 'application/pdf'];

interface Props {
  onSubmit: (text: string) => void;
  isContainerScrolled: boolean;
  attachments?: LocalAttachment[];
  uploadFile?: (input: { file: File, height: number, width: number }) => Promise<void>;
  removeAttachment?: (uuid: string) => void;
  isAttachmentsEnabled?: boolean;
}

interface State {
  value: string;
}

class Input extends PureComponent<Props, State> {
  static defaultProps = {
    isAttachmentsEnabled: true,
  };

  state = {
    value: '',
  };

  ref: HTMLTextAreaElement | null = null;

  fileInput: HTMLInputElement | null = null;

  handleKeyPress = (e) => {
    if (e.shiftKey) return;
    switch (e.which) {
      case KeyCodes.ENTER:
        this.handleSubmit();
        e.preventDefault();
        break;
      default:
        break;
    }
  }

  handleSubmit = () => {
    this.props.onSubmit(this.state.value);
    this.setState({ value: '' });
  }

  handleChange = event => this.setState({ value: event.currentTarget.value });

  handleFileChange = (e) => {
    e.preventDefault();

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    _.each(files, (item) => {
      if (SUPPORTED_PASTE_TYPES.indexOf(item.type) !== -1) {
        this.createPreview(item);
      }
    });
  }

  handleAddAttachmentClick = () => {
    if (this.fileInput) {
      this.fileInput.click();
    }
  }

  handleRemoveAttachment = (uuid: string) => {
    if (this.props.removeAttachment) this.props.removeAttachment(uuid);
  }

  render() {
    const hasAttachments = this.props.attachments && this.props.attachments.length > 0;
    return (
      <div
        styleName={classNames({
          'thread-input__container': true,
          'thread-input__container--scrolled': this.props.isContainerScrolled,
        })}>
        <div
          styleName="thread-input">
          <div styleName="thread-input__input">
            {this.props.isAttachmentsEnabled ? (
              <input accept="image/*, application/pdf" hidden={true} multiple={true} ref={this.bindFileRef} onChange={this.handleFileChange} type="file" name="photo" />
            ) : null}
            <div styleName="thread-input__textarea">
              <AutoResizingTextarea
                ref={this.assignRef}
                onKeyPress={this.handleKeyPress}
                onChange={this.handleChange}
                lineHeight={20}
                value={this.state.value}
                placeholder="Type here..."
              />
            </div>
            <div styleName="thread-input__buttons">
              {this.props.isAttachmentsEnabled ? (
                <Tooltip
                  position={Tooltip.enums.Positions.Up}
                  text="Attach a file">
                  <button styleName="thread-input__attachment-button" onClick={this.handleAddAttachmentClick}>
                    <Paperclip />
                  </button>
                </Tooltip>
              ) : null}
              <Button
                color={Button.enums.Colors.Blue}
                label="Send"
                size={Button.enums.Sizes.Small}
                onClick={this.handleSubmit}
                isDisabled={this.state.value === '' || (hasAttachments && _.some(this.props.attachments, att => att.progressUploaded < 1))}
              />
            </div>
          </div>
          {hasAttachments ? (
            <div styleName="thread-input__attachments">
              {_.map(this.props.attachments, attachment => (
                <InputAttachment
                  onDelete={this.handleRemoveAttachment}
                  fileName={attachment.file.name}
                  fileSize={attachment.file.size}
                  uuid={attachment.photo ? attachment.photo.uuid : null}
                  key={attachment.file.name}
                  uploadProgress={attachment.progressUploaded}
                />
              ))}
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  bindFileRef = (c) => {
    this.fileInput = c;
  }

  assignRef = (c) => {
    if (c) {
      this.ref = c;
    }
  }

  createPreview = (file: File) => {
    const { uploadFile } = this.props;
    if (!uploadFile) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result !== 'string') return;
      const image = new Image();
      image.src = reader.result;
      image.onload = function onLoadEnd() {
        uploadFile({
          file,
          height: image.height,
          width: image.width,
        });
      };
    };
    reader.readAsDataURL(file);
  }
}

export default withStyles(styles)(Input);
