import React, { ReactNode } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './BoostInsightsTableView.scss';

interface Props {
  children: ReactNode;
}

function BoostInsightsTableView(props: Props) {
  return (
    <div styleName="boost-insights">
      <div styleName="boost-insights__header">
        <div>Views</div>
        <div>Start</div>
        <div>End</div>
        <div>Status</div>
        <div>Budget</div>
        <div />
      </div>
      <div styleName="boost-insights__body">
        {props.children}
      </div>
    </div>
  );
}

export default withStyles(styles)(BoostInsightsTableView);
