import React, { PureComponent } from 'react';
import pluralize from 'pluralize';
import _ from 'lodash';

import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import UnorderedList from '~tools/react/components/UnorderedList';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import BrandCardTitle from './components/BrandCardTitle';
import BrandShapes from './components/BrandShapes';
import * as enums from './enums';

// Don't reorder these props, they're listed in order of appearence.
interface Props {
  brand?: enums.Brands,
  featureName: string;
  title: string;
  intro: string;
  bullets: string[];
  availablePlans?: enums.AvailablePlans[];
  learnMorePath?: string;
}

const brandToListColor = {
  [enums.Brands.Landlord]: UnorderedList.enums.Colors.BrandAdvancedPlan,
  [enums.Brands.Leaseholder]: UnorderedList.enums.Colors.BrandLeaseholderPlan,
};

const brandToButtonColor = {
  [enums.Brands.Landlord]: Button.enums.Colors.BrandAdvancedPlan,
  [enums.Brands.Leaseholder]: Button.enums.Colors.BrandLeaseholderPlan,
};

const brandToCardColor = {
  [enums.Brands.Landlord]: Card.enums.Colors.BrandAdvancedPlan,
  [enums.Brands.Leaseholder]: Card.enums.Colors.BrandLeaseholderPlan,
};

class PayWallCard extends PureComponent<Props, {}> {
  static defaultProps = {
    brand: enums.Brands.Leaseholder,
    availablePlans: [
      enums.AvailablePlans.Leaseholder,
      enums.AvailablePlans.Landlord,
    ],
  }

  render() {
    const availabilityStr = pluralize.isPlural(this.props.featureName) ?
      `${this.props.featureName} are available on the` :
      `${this.props.featureName} is available on the`;

    const hasMultiplePlans = (this.props.availablePlans || []).length > 1;

    return (
      <Card color={this.props.brand ? brandToCardColor[this.props.brand] : undefined}>
        <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
          <Card.CardSection>
            <BrandCardTitle
              brand={this.props.brand}
              content={this.props.featureName}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
            <Heading
              content={this.props.title}
              font={Heading.enums.Fonts.Secondary}
              size={Heading.enums.Sizes.Small}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
            <Text content={this.props.intro} />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
            <UnorderedList
              hasBorders={false}
              items={this.props.bullets.map(bullet => ({
                color: this.props.brand ? brandToListColor[this.props.brand] : undefined,
                label: bullet,
              }))}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
            <Row
              flexBehavior={Row.enums.FlexBehaviors.Default}
              verticalAlignment={Row.enums.VerticalAlignments.Center}>
              <Text content={availabilityStr} />
              {this.props.availablePlans?.includes(enums.AvailablePlans.Leaseholder) ? (
                <Text
                  content="&nbsp;Leaseholder&nbsp;"
                  link={{
                    path: 'https://caretaker.com/leaseholder',
                    shouldOpenNewTab: true,
                  }}
                  color={Text.enums.Colors.Info}
                  hasHoverStyles
                />
              ) : null}
              {hasMultiplePlans ? (
                <Text content="and" />
              ) : null}
              {this.props.availablePlans?.includes(enums.AvailablePlans.Landlord) ? (
                <Text
                  content="&nbsp;Landlord&nbsp;"
                  link={{
                    path: 'https://caretaker.com/landlord',
                    shouldOpenNewTab: true,
                  }}
                  color={Text.enums.Colors.Info}
                  hasHoverStyles
                />
              ) : null}
              <Text content={` plan${hasMultiplePlans ? 's' : ''}.`} />
            </Row>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
            <Row
              flexBehavior={Row.enums.FlexBehaviors.Default}
              verticalAlignment={Row.enums.VerticalAlignments.Center}>
              <Button
                color={this.props.brand ? brandToButtonColor[this.props.brand] : undefined}
                label="Upgrade my account"
                size={Button.enums.Sizes.Small}
                link={{
                  path: '/account/billing?isManagePlanModalOpen=true',
                }}
              />
              {this.props.learnMorePath ? (
                <>
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                  <Button
                    color={Button.enums.Colors.Gray}
                    icon={Button.enums.Icons.ExternalLink}
                    label="Learn more"
                    link={{
                      path: this.props.learnMorePath,
                      shouldOpenNewTab: true,
                    }}
                    size={Button.enums.Sizes.Small}
                    style={Button.enums.Styles.Outline}
                  />
                </>
              ) : null}
            </Row>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
          </Card.CardSection>
          <div style={{ minWidth: '20%', position: 'relative' }}>
            <BrandShapes />
          </div>
        </Row>
      </Card>
    );
  }
}

export default PayWallCard;
