import _ from 'lodash';
import cookies from 'js-cookie';
import ExecutionEnvironment from 'exenv';

const globals = {
  defaultHeaders: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  defaultAuthHeaders: () => ({
    ...globals.defaultHeaders,
    'x-flip-token': cookies.get('token', '/') || cookies.get('placeholderToken', '/'),
  }),
};

if (ExecutionEnvironment.canUseDOM) {
  _.merge(globals, window.GLOBALS);
} else {
  _.merge(globals, JSON.parse(process.env.GLOBALS));
}

export default globals;
