import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { compose } from '~tools/react/hocs/utils';
import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Card from '~tools/react/components/Card';
import Checkbox from '~tools/react/components/Form/components/Checkbox';
import GenericLoadingVisual from '~tools/react/components/GenericLoadingVisual';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import withQuery from '~tools/react/graphql/withQuery';
import withCreateLeasesSetting, { CreateLeasesSettingProps } from '~tools/react/graphql/mutations/leasesSettings/withCreateLeasesSetting';
import withUpdateLeasesSetting, { UpdateLeasesSettingProps } from '~tools/react/graphql/mutations/leasesSettings/withUpdateLeasesSetting';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import { LeasesSetting } from './types';
import query from './LeasesSettings.gql';

type Props = QueryProps
  & CreateLeasesSettingProps
  & UpdateLeasesSettingProps
  & RouteComponentProps;

class LeasesSettings extends Component<Props, {}> {
  handleChangeRequireAutopay = async (isChecked: boolean) => {
    if (!this.props.leasesSetting) {
      await this.props.createLeasesSetting({
        isAutopayRequired: isChecked,
      });
    } else {
      await this.props.updateLeasesSetting(this.props.leasesSetting.uuid, {
        isAutopayRequired: isChecked,
      });
    }
    await this.props.refetch();
  };

  render() {
    if (this.props.isLoading) {
      return (
        <ManageStage>
          <Card>
            <Card.CardSection>
              <GenericLoadingVisual />
            </Card.CardSection>
          </Card>
        </ManageStage>
      );
    }

    return (
      <ManageStage>
        <Breadcrumbs
          items={[
            { path: `/settings`, label: 'Settings' },
            { path: `/settings/leases`, label: `Leases settings` },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
        <Card header={{
          title: 'Autopay',
          subtitle: 'Require autopay on any new leases you sign with tenants.',
        }}>
          <Card.CardSection>
            <Row verticalAlignment={Row.enums.VerticalAlignments.Center} flexBehavior={Row.enums.FlexBehaviors.Default}>
              <Checkbox
                color={Checkbox.enums.Colors.Blue}
                isChecked={!!this.props.leasesSetting?.autopayRequiredAt}
                onChange={this.handleChangeRequireAutopay}
                style={Checkbox.enums.Styles.Toggle}
              />
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
              <Text
                content="Require autopay on new leases"
                isEmphasized
              />
            </Row>
          </Card.CardSection>
        </Card>
      </ManageStage>
    );
  }
}

interface QueryProps {
  isLoading: boolean;
  leasesSetting: LeasesSetting | null;
  refetch: () => Promise<any>;
}

interface Response {
  viewer: {
    uuid: string;
    leasesSetting: LeasesSetting | null;
  } | null;
}

export default compose(
  withQuery<RouteComponentProps, Response, {}, QueryProps>(query, {
    props: props => ({
      isLoading: props.loading,
      leasesSetting: props.data?.viewer?.leasesSetting ?? null,
      refetch: props.refetch,
    }),
  }),
  withCreateLeasesSetting,
  withUpdateLeasesSetting,
)(LeasesSettings);
