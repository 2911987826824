// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._EGnPpe{display:table-cell;height:47px;text-align:left;vertical-align:top;white-space:nowrap}._EGnPpe ._x28bVb{display:block;height:100%;overflow:hidden;padding:8px;text-overflow:ellipsis;white-space:nowrap}._EGnPpe ._32ApOd{-webkit-box-align:center;align-items:center;display:-webkit-box;display:flex;height:100%;white-space:nowrap}._EGnPpe ._32ApOd p{line-height:25px}._EGnPpe._drDWZO ._32ApOd{-webkit-box-pack:start;justify-content:flex-start;text-align:left}._EGnPpe._P9WKmk ._32ApOd{-webkit-box-pack:end;justify-content:flex-end;text-align:right}._EGnPpe._99vdBE{width:1px}._EGnPpe._ypryv9{width:auto}._EGnPpe:first-of-type ._x28bVb{padding-left:24px}._EGnPpe:last-of-type ._x28bVb{padding-right:24px}", ""]);
// Exports
exports.locals = {
	"table-data": "_EGnPpe",
	"table-data__content": "_x28bVb",
	"table-data__span": "_32ApOd",
	"table-data--alignment-left": "_drDWZO",
	"table-data--alignment-right": "_P9WKmk",
	"table-data--width-minimized": "_99vdBE",
	"table-data--width-auto": "_ypryv9"
};
module.exports = exports;
