import { ApolloQueryResult } from 'apollo-client';
import _ from 'lodash';
import React, { Fragment, PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import { StreetDirectionAbbreviations, StreetGenericAbbreviations } from '~tools/constants/streets';
import withArchiveListingAnswer, { ArchiveListingAnswerProps } from '~tools/react/graphql/mutations/listingAnswers/withArchiveListingAnswer';
import withCreateListingAnswer, { CreateListingAnswerProps } from '~tools/react/graphql/mutations/listingAnswers/withCreateListingAnswer';
import withUpdateListingAnswer, { UpdateListingAnswerProps } from '~tools/react/graphql/mutations/listingAnswers/withUpdateListingAnswer';

import ManageStage from '~web-manage/lib/common/stages/ManageStage';

import Avatar from '~tools/react/components/Avatar';
import Breadcrumbs from '~tools/react/components/Breadcrumbs';
import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import ConfirmationModal from '~tools/react/components/ConfirmationModal';
import Form, { Checkbox, TextArea } from '~tools/react/components/Form';
import Heading from '~tools/react/components/Heading';
import HorizontalRule from '~tools/react/components/HorizontalRule/HorizontalRule';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Tag from '~tools/react/components/Tag';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import LoadingVisual from '~web-manage/lib/common/components/__deprecated/LoadingVisual';

import ListingCard from './components/ListingCard';
import QandA from './components/QandA';

import { ListingQuestion } from './types';

import query from './Question.gql';

interface QueryProps {
  isLoading: boolean;
  listingQuestion: ListingQuestion | null;
  refetch: () => Promise<ApolloQueryResult<Response>>;
}

interface RouteParams {
  listingQuestionUuid: string;
  tab: string;
}

interface FormData {
  answerText: string;
}

interface State {
  isArchiveConfirmationOpen: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  shouldPost: boolean;
}

type Props =
  RouteComponentProps<RouteParams> &
  CreateListingAnswerProps &
  UpdateListingAnswerProps &
  ArchiveListingAnswerProps &
  QueryProps;

class Question extends PureComponent<Props> {
  state: State = {
    isArchiveConfirmationOpen: false,
    isLoading: false,
    isUpdating: false,
    shouldPost: true,
  }

  handleShouldPostChange = () => {
    this.setState({ shouldPost: !this.state.shouldPost });
  };

  handleCloseArchiveConfirmation = () => this.setState({ isArchiveConfirmationOpen: false });
  handleOpenArchiveConfirmation = () => this.setState({ isArchiveConfirmationOpen: true });

  handleArchive = async () => {
    if (!this.props.listingQuestion || !this.props.listingQuestion.listingAnswer) return;
    this.setState({ isLoading: true });

    try {
      await this.props.archiveListingAnswer(this.props.listingQuestion.listingAnswer.uuid);
      this.setState({ isArchiveConfirmationOpen: false, isLoading: false });
    } catch (err) {
      this.setState({ isArchiveConfirmationOpen: false, isLoading: false });
      throw err;
    }
  }

  handleUpdate = async () => this.setState({ isUpdating: true });

  handleSubmit = async (formData: FormData) => {
    if (!this.props.listingQuestion) return;
    this.setState({ isLoading: true });

    try {
      if (!this.props.listingQuestion.listingAnswer) {
        await this.props.createListingAnswer({
          questionUuid: this.props.listingQuestion.uuid,
          shouldPost: this.state.shouldPost,
          text: formData.answerText,
        });
      } else {
        await this.props.updateListingAnswer(this.props.listingQuestion.listingAnswer.uuid, {
          text: formData.answerText,
        });
      }
      await this.props.refetch();
      this.setState({ isLoading: false });
    } catch (err) {
      this.setState({ isLoading: false });
      throw err;
    }
  }

  render() {
    const listingQuestion = this.props.listingQuestion;
    if (this.props.isLoading || !listingQuestion) {
      return (
        <ManageStage>
          <LoadingVisual />
        </ManageStage>
      );
    }

    const addressUnit = listingQuestion.listing.addressUnit;
    const streetAddressParts = addressUnit.address.streetAddress1.split(' ');
    const streetNumber = _.take(streetAddressParts, 1);
    const streetNameParts = _.drop(streetAddressParts, 1);

    const finalIndex = streetNameParts.length - 1;
    const generic = _.toUpper(streetNameParts[finalIndex]);
    const genericAbbreviation = _.upperFirst(_.toLower(StreetGenericAbbreviations[generic]));
    streetNameParts[finalIndex] = genericAbbreviation ? `${genericAbbreviation}.` : streetNameParts[finalIndex];
    if (streetNameParts.length > 2) {
      const direction = _.toUpper(streetNameParts[0]);
      const directionAbbreviation = _.upperFirst(_.toLower(StreetDirectionAbbreviations[direction]));
      streetNameParts[0] = directionAbbreviation ? `${directionAbbreviation}.` : streetNameParts[0];
    }
    const abbreviatedStreetAddress = `${streetNumber} ${streetNameParts.join(' ')}`;

    const nameParts = listingQuestion.askerAccount.fullName.split(' ');
    const shortName = nameParts.length > 1 ? `${nameParts[0]} ${nameParts[1].charAt(0)}.` : `${nameParts[0]}`;

    return (
      <ManageStage>
        <Breadcrumbs
          items={[
            { path: '/questions', label: 'All questions' },
            { label: _.truncate(listingQuestion.prompt, { length: 35 }) },
          ]}
          style={Breadcrumbs.enums.Styles.Compact}
        />
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
        <Card overflow={Card.enums.OverflowValues.Hidden}>
          <Row>
            <div>
              <Card.CardSection>
                <div style={{ alignItems: 'center', display: 'flex' }}>
                  <Avatar
                    imageUrl={listingQuestion.askerAccount.photoUrl}
                    size={Avatar.enums.Sizes.XSmall}
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                  <Heading
                    font={Heading.enums.Fonts.Secondary}
                    content={`*${shortName}* asked about *${abbreviatedStreetAddress}*`}
                    isMarkdown
                    size={Heading.enums.Sizes.XXSmall}
                  />
                  <div style={{ marginLeft: 'auto' }} />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                  {listingQuestion.listingAnswer?.archivedAt ? (
                    <Tag
                      label="Archived"
                    />
                  ) : (
                    <Tag
                      color={listingQuestion.listingAnswer ? Tag.enums.Colors.Green : Tag.enums.Colors.Red}
                      icon={listingQuestion.listingAnswer ? Tag.enums.Icons.Check : undefined}
                      label={listingQuestion.listingAnswer ? 'Answered' : 'Unanswered'}
                      style={listingQuestion.listingAnswer ? Tag.enums.Styles.Secondary : Tag.enums.Styles.Primary}
                    />
                  )}
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Medium} />
                </div>
              </Card.CardSection>
              <Card.CardSection>
                <QandA
                  question={listingQuestion.prompt}>
                  {listingQuestion.listingAnswer && !this.state.isUpdating ? ( // eslint-disable-line
                    <Fragment>
                      <Text
                        content={listingQuestion.listingAnswer.text}
                        size={Text.enums.Sizes.Large}
                      />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                      <HorizontalRule />
                      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
                      <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                        {!listingQuestion.listingAnswer.archivedAt ? (
                          <Button
                            align={Button.enums.Alignments.Right}
                            color={Button.enums.Colors.Black}
                            isLoading={this.state.isLoading || !!listingQuestion.archivedAt}
                            label="Update"
                            onClick={this.handleUpdate}
                            size={Button.enums.Sizes.Small}
                            style={Button.enums.Styles.Outline}
                            type={Button.enums.Types.Button}
                          />
                        ) : null}
                        <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XSmall} />
                        <Button
                          align={listingQuestion.listingAnswer.archivedAt ? Button.enums.Alignments.Right : undefined}
                          color={Button.enums.Colors.Red}
                          isDisabled={!!listingQuestion.listingAnswer.archivedAt}
                          isLoading={this.state.isLoading || !!listingQuestion.archivedAt}
                          label={listingQuestion.listingAnswer.archivedAt ? 'Archived' : 'Archive'}
                          onClick={this.handleOpenArchiveConfirmation}
                          size={Button.enums.Sizes.Small}
                          style={Button.enums.Styles.Outline}
                          type={Button.enums.Types.Button}
                        />
                      </Row>
                    </Fragment>
                  ) : (
                    <Form onSubmit={this.handleSubmit}>
                      <Form.FormSection columns={Form.FormSection.enums.Columns.One}>
                        <TextArea
                          isDisabled={this.state.isLoading || !!listingQuestion.listingAnswer?.archivedAt}
                          isRequired={true}
                          placeholder="Type your answer here..."
                          labelFormat={TextArea.enums.LabelFormats.Stacked}
                          name="answerText"
                          size={TextArea.enums.Sizes.Medium}
                          value={listingQuestion.listingAnswer?.text}
                        />
                      </Form.FormSection>
                      <Form.FormSection columns={Form.FormSection.enums.Columns.One}>
                        <Checkbox
                          isDisabled={this.state.isLoading || !!listingQuestion.listingAnswer?.archivedAt}
                          label="Hide this answer from other interested renters"
                          onChange={this.handleShouldPostChange}
                          isChecked={!this.state.shouldPost}
                        />
                      </Form.FormSection>
                      <Form.FormSection>
                        <Button
                          align={Button.enums.Alignments.Right}
                          isDisabled={!!listingQuestion.listingAnswer?.archivedAt}
                          isLoading={this.state.isLoading}
                          label={!listingQuestion.listingAnswer ? 'Submit' : 'Save'}
                          size={Button.enums.Sizes.Small}
                          type={Button.enums.Types.Submit}
                        />
                      </Form.FormSection>
                    </Form>
                  )}
                </QandA>
              </Card.CardSection>
            </div>
            <ListingCard
              listing={{
                description: listingQuestion.listing.description,
                earliestMoveInDate: listingQuestion.listing.earliestMoveInDate,
                images: _.map(listingQuestion.listing.photos, photo => ({
                  id: photo.uuid,
                  position: photo.position,
                  url: photo.url,
                })),
                locationString: `${listingQuestion.listing.addressUnit.address.city}, ${listingQuestion.listing.addressUnit.address.state}`,
                numBathrooms: listingQuestion.listing.bathrooms,
                numBedrooms: listingQuestion.listing.bedrooms,
                rentInCents: listingQuestion.listing.rentInCents / 100,
                title: `${_.capitalize(_.lowerCase(listingQuestion.listing.listingType))} in ${listingQuestion.listing.neighborhood || listingQuestion.listing.city}, ${listingQuestion.listing.state}`,
                type: _.startCase(_.lowerCase(listingQuestion.listing.listingType)),
              }}
              propertyManagerContractUuid={listingQuestion.listing.propertyManagerContract?.uuid}
            />
          </Row>
        </Card>
        <ConfirmationModal
          confirmAction={{
            label: 'Archive',
            onClick: this.handleArchive,
          }}
          description="Are you sure you want to archive this question? The renter won't be able to see your answer and the question will no longer appear on your listing."
          isOpen={this.state.isArchiveConfirmationOpen}
          onClose={this.handleCloseArchiveConfirmation}
          title="Archive this question?"
        />
      </ManageStage>
    );
  }
}

interface Response {
  viewer: {
    listingQuestion: ListingQuestion | null;
    uuid: string;
  }
}

interface Variables {
  listingQuestionUuid: string;
}

export default compose(
  withQuery<RouteComponentProps<{ listingQuestionUuid: string }>, Response, Variables, QueryProps>(query, {
    options: props => ({
      ssr: false,
      skip: !props.match.params.listingQuestionUuid,
      variables: {
        listingQuestionUuid: props.match.params.listingQuestionUuid,
      },
    }),
    props: props => ({
      isLoading: props.loading,
      listingQuestion: props.data?.viewer?.listingQuestion || null,
      refetch: props.refetch,
    }),
  }),
  withArchiveListingAnswer,
  withCreateListingAnswer,
  withUpdateListingAnswer,
)(Question);
