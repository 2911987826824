import classNames from 'classnames';
import _ from 'lodash';
import React, { ReactNode } from 'react';

import SkeletonBodyText from '~tools/react/components/SkeletonBodyText';

import withStyles from '~tools/react/hocs/withStyles';

import * as enums from './enums';

import styles from './TableHeader.scss';

interface Props {
  alignment?: enums.Alignments;
  children?: ReactNode;
  isLoading?: boolean;
  width?: enums.Widths;
}

function TableHeader(props: Props) {
  return (
    <th
      className={classNames({
        [styles['table-header']]: true,
        [styles[`table-header--alignment-${_.kebabCase(props.alignment)}`]]: props.alignment,
        [styles[`table-header--width-${_.kebabCase(props.width)}`]]: props.width,
      })}>
      <div styleName="table-header__content">
        <span styleName="table-header__span">
          {props.isLoading ? (
            <SkeletonBodyText />
          ) : props.children}
        </span>
      </div>
    </th>
  );
}

TableHeader.defaultProps = {
  alignment: enums.Alignments.LEFT,
  isLoading: false,
  width: enums.Widths.MINIMIZED,
};

export default withStyles(styles)(TableHeader);
