import { ApolloQueryResult } from 'apollo-client';
import _ from 'lodash';
import React, { ComponentType, PureComponent } from 'react';

import { compose } from '~tools/react/hocs/utils';
import withDevice, { DeviceProps } from '~tools/react/hocs/withDevice';

import withQuery from '~tools/react/graphql/withQuery';
import withCreatePhoto, { CreatePhotoProps, PhotoEntityTypes } from '~tools/react/graphql/mutations/photos/withCreatePhoto';
import withDeletePhoto, { DeletePhotoProps } from '~tools/react/graphql/mutations/photos/withDeletePhoto';
import withCreateAccessInstruction, { AccessInstructionAccessorEntityTypes, AccessInstructionEntityTypes, CreateAccessInstructionProps } from '~tools/react/graphql/mutations/accessInstructions/withCreateAccessInstruction';
import withUpdateAccessInstruction, { UpdateAccessInstructionProps } from '~tools/react/graphql/mutations/accessInstructions/withUpdateAccessInstruction';
import withGenerateSmartLockOneTimeCode, { GenerateSmartLockOneTimeCodeProps } from '~tools/react/graphql/mutations/smartLocks/withGenerateSmartLockOneTimeCode';

import Button from '~tools/react/components/Button';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import AnimatedResizer from '~tools/react/components/AnimatedResizer';
import ThemedModal from '~tools/react/components/ThemedModal';

import AccessForm, { UploadedPhoto } from '../../components/AccessForm';
import MaskedSmartLockCode from '../../components/MaskedSmartLockCode';
import KeyboxSetupStep from './components/KeyboxSetupStep';

import query from './KeyboxSetupModal.gql';
import { PropertyManagerContract } from './types';

enum Steps {
  UNLOCK_SMART_LOCK,
  SLIDE_SHACKLE,
  REMOVE_SHACKLE,
  SECURE_SMART_LOCK,
  TAKE_PHOTO,
}

interface State {
  currentStep: Steps;
  isGeneratingAccessCode: boolean;
  smartLockAccessCode: string | null;
}

interface InputProps {
  isOpen: boolean;
  propertyManagerContractUuid: string;
  onClose: () => void;
  onSmartLockSetupComplete?: () => void;
}

type Props =
  InputProps &
  QueryProps &
  DeviceProps &
  CreatePhotoProps &
  DeletePhotoProps &
  CreateAccessInstructionProps &
  UpdateAccessInstructionProps &
  GenerateSmartLockOneTimeCodeProps;

class KeyboxSetupModal extends PureComponent<Props> {
  state: State = {
    currentStep: Steps.UNLOCK_SMART_LOCK,
    isGeneratingAccessCode: false,
    smartLockAccessCode: null,
  };

  handleSubmitAccessForm = async (photos: UploadedPhoto[], smartLockDescription: string) => {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return;

    const addressUnit = propertyManagerContract.addressUnit;

    let accessInstruction;
    if (addressUnit.accessInstruction) {
      accessInstruction = await this.props.updateAccessInstruction(addressUnit.accessInstruction.uuid, {
        accessorEntityType: addressUnit.smartLock ? AccessInstructionAccessorEntityTypes.SMART_LOCK : undefined,
        accessorEntityUuid: addressUnit.smartLock?.uuid,
        notes: smartLockDescription,
      });
    } else {
      let trimmedSmartLockDescription = smartLockDescription.trim();
      if (trimmedSmartLockDescription.substr(-1) !== '.') {
        trimmedSmartLockDescription = `${trimmedSmartLockDescription}.`;
      }

      accessInstruction = await this.props.createAccessInstruction({
        accessorEntityType: addressUnit.smartLock ? AccessInstructionAccessorEntityTypes.SMART_LOCK : undefined,
        accessorEntityUuid: addressUnit.smartLock?.uuid,
        entityType: AccessInstructionEntityTypes.LISTING,
        entityUuid: propertyManagerContract.listing.uuid,
        notes: trimmedSmartLockDescription,
      });
    }

    await Promise.all(_.map(photos, p => (
      this.props.createPhoto({
        entityType: PhotoEntityTypes.ACCESS_INSTRUCTION,
        entityUuid: accessInstruction.uuid,
        key: p.key,
      })
    )));

    if (this.props.onSmartLockSetupComplete) this.props.onSmartLockSetupComplete();
    this.props.onClose();
  }

  handleDeletePhoto = async (uuid: string) => {
    await this.props.deletePhoto(uuid);
    await this.props.refetch();
  }

  handleGenerateAccessCode = async () => {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return;

    const addressUnit = propertyManagerContract.addressUnit;
    if (!addressUnit.smartLock) return;
    this.setState({ isGeneratingAccessCode: true });
    const accessCode = await this.props.generateSmartLockOneTimeCode(addressUnit.smartLock.uuid);
    this.setState({ smartLockAccessCode: accessCode, isGeneratingAccessCode: false });
  }

  handleAdvanceToNextStep = () => this.setState({ currentStep: this.state.currentStep + 1 });
  handleGoBack = () => this.setState({ currentStep: this.state.currentStep - 1 });

  render() {
    const propertyManagerContract = this.props.propertyManagerContract;
    if (!propertyManagerContract) return null;

    return (
      <ThemedModal
        bodyBackgroundColor={ThemedModal.enums.BodyBackgroundColors.White}
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title="Setup your smart lock"
        width={ThemedModal.enums.Widths.ExtraLarge}>
        <ThemedModal.ThemedModalSection>
          <AnimatedResizer width={AnimatedResizer.enums.Widths.Full}>
            <AnimatedResizer.AnimatedResizerSection
              isLeft={this.state.currentStep > Steps.UNLOCK_SMART_LOCK}
              isShown={this.state.currentStep === Steps.UNLOCK_SMART_LOCK}>
              <KeyboxSetupStep
                description="Enter the access code provided below, tap the lock icon, and pull the face of the lock towards you to open the smart lock. Once it's open, hang your keys on the keyrack and leave any fobs or cards needed to access your apartment inside."
                illustration={KeyboxSetupStep.enums.Illustrations.UnlockSmartLock}
                isMobile={this.props.isMobile}
                isShown={this.state.currentStep === Steps.UNLOCK_SMART_LOCK}
                stepNumber={1}
                title="Unlock your smart lock">
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
                <HorizontalRule />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <Text
                  color={Text.enums.Colors.Secondary}
                  content="This code will be valid for one use."
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XSmall} />
                <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                  <MaskedSmartLockCode code={this.state.smartLockAccessCode} />
                  <Button
                    label="Get access code"
                    onClick={this.handleGenerateAccessCode}
                    size={Button.enums.Sizes.Small}
                    isLoading={this.state.isGeneratingAccessCode}
                    isDisabled={!!this.state.smartLockAccessCode}
                    style={Button.enums.Styles.Outline}
                    width={this.props.isMobile ? Button.enums.Widths.Full : Button.enums.Widths.Auto}
                  />
                </Row>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <HorizontalRule />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
                <Button
                  label="Continue"
                  onClick={this.handleAdvanceToNextStep}
                  width={this.props.isMobile ? Button.enums.Widths.Full : Button.enums.Widths.Auto}
                />
              </KeyboxSetupStep>
            </AnimatedResizer.AnimatedResizerSection>
            <AnimatedResizer.AnimatedResizerSection
              isLeft={this.state.currentStep > Steps.SLIDE_SHACKLE}
              isRight={this.state.currentStep < Steps.SLIDE_SHACKLE}
              isShown={this.state.currentStep === Steps.SLIDE_SHACKLE}>
              <KeyboxSetupStep
                description="In the middle of the smart lock interior - directly above the key rack - you will see a small, silver bar. Slide it to the right."
                illustration={KeyboxSetupStep.enums.Illustrations.SlideShackle}
                isMobile={this.props.isMobile}
                isShown={this.state.currentStep === Steps.SLIDE_SHACKLE}
                onClickBack={this.handleGoBack}
                onClickForward={this.handleAdvanceToNextStep}
                stepNumber={2}
                title="Slide the shackle release"
              />
            </AnimatedResizer.AnimatedResizerSection>
            <AnimatedResizer.AnimatedResizerSection
              isLeft={this.state.currentStep > Steps.REMOVE_SHACKLE}
              isRight={this.state.currentStep < Steps.REMOVE_SHACKLE}
              isShown={this.state.currentStep === Steps.REMOVE_SHACKLE}>
              <KeyboxSetupStep
                description="Pull the smart lock shackle upwards to detach it from the smart lock body. Now you're ready to find a place to attach the smart lock."
                illustration={KeyboxSetupStep.enums.Illustrations.RemoveShackle}
                isMobile={this.props.isMobile}
                isShown={this.state.currentStep === Steps.REMOVE_SHACKLE}
                onClickBack={this.handleGoBack}
                onClickForward={this.handleAdvanceToNextStep}
                stepNumber={3}
                title="Remove the smart lock shackle"
              />
            </AnimatedResizer.AnimatedResizerSection>
            <AnimatedResizer.AnimatedResizerSection
              isLeft={this.state.currentStep > Steps.SECURE_SMART_LOCK}
              isRight={this.state.currentStep < Steps.SECURE_SMART_LOCK}
              isShown={this.state.currentStep === Steps.SECURE_SMART_LOCK}>
              <KeyboxSetupStep
                description="Reattach the shackle to your smart lock around a railing where it can't be removed. The location needs to be accessible by visitors who don't have keys or fobs. If you're in a building that does not require a key or a fob and allows items in the hallways, you can hang it on the handle of your door."
                illustration={KeyboxSetupStep.enums.Illustrations.SecureSmartLock}
                isMobile={this.props.isMobile}
                isShown={this.state.currentStep === Steps.SECURE_SMART_LOCK}
                onClickBack={this.handleGoBack}
                onClickForward={this.handleAdvanceToNextStep}
                stepNumber={4}
                title="Secure your smart lock"
              />
            </AnimatedResizer.AnimatedResizerSection>
            <AnimatedResizer.AnimatedResizerSection
              isRight={this.state.currentStep < Steps.TAKE_PHOTO}
              isShown={this.state.currentStep === Steps.TAKE_PHOTO}>
              <KeyboxSetupStep
                illustration={KeyboxSetupStep.enums.Illustrations.TakePhoto}
                description={`Add a photo of your attached smart lock and a quick description of where to look for it${
                  propertyManagerContract.addressUnit.accessInstruction?.notes ?
                    ' to your existing notes' :
                    ', along with anything else a viewer may need to know to get into the unit.'
                }`}
                isMobile={this.props.isMobile}
                isShown={this.state.currentStep === Steps.TAKE_PHOTO}
                stepNumber={5}
                title="Take photos">
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <AccessForm
                  accessInstruction={propertyManagerContract.addressUnit.accessInstruction}
                  defaultAccessInstructionNotes={propertyManagerContract.addressUnit.accessInstruction?.notes ?? null}
                  isMobile={this.props.isMobile}
                  isShown={this.state.currentStep === Steps.TAKE_PHOTO}
                  onCancel={this.handleGoBack}
                  onDeletePhoto={this.handleDeletePhoto}
                  onSubmit={this.handleSubmitAccessForm}
                />
              </KeyboxSetupStep>
            </AnimatedResizer.AnimatedResizerSection>
          </AnimatedResizer>
        </ThemedModal.ThemedModalSection>
      </ThemedModal>
    );
  }
}

interface Response {
  viewer: {
    propertyManagerContract: PropertyManagerContract | null;
    uuid: string;
  } | null;
}

interface QueryProps {
  isLoading: boolean;
  propertyManagerContract: PropertyManagerContract | null;
  refetch: () => Promise<ApolloQueryResult<Response>>;
}

interface Variables {
  uuid: string;
}

export default compose(
  withCreateAccessInstruction,
  withUpdateAccessInstruction,
  withCreatePhoto,
  withDeletePhoto,
  withGenerateSmartLockOneTimeCode,
  withDevice,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    options: (props) => ({
      skip: !props.isOpen,
      variables: {
        uuid: props.propertyManagerContractUuid,
      },
    }),
    props: (props) => ({
      isLoading: props.loading,
      refetch: props.refetch,
      propertyManagerContract: props.data?.viewer?.propertyManagerContract || null,
    }),
  })
)(KeyboxSetupModal) as ComponentType<InputProps>;
