import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import { QualificationTypes } from '~tools/types/graphqlSchema';

import { Input, Select } from '~tools/react/components/Form';

import styles from './RequirementValueInput.scss';

interface Props {
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  type: QualificationTypes;
  value?: number;
}

const getMaxAmount = (type: QualificationTypes) => {
  switch (type) {
    case QualificationTypes.INCOME: {
      return 100;
    }
    default: {
      return undefined;
    }
  }
};

function RequirementValueInput(props: Props) {
  if (props.type === QualificationTypes.CREDIT_SCORE) {
    return (
      <div style={{ width: '75px' }}>
        <Select
          isDisabled={props.isDisabled}
          isRequired
          name="value"
          onChange={props.onChange}
          options={[
            {
              label: '500',
              value: 500,
            },
            {
              label: '550',
              value: 550,
            },
            {
              label: '600',
              value: 600,
            },
            {
              label: '650',
              value: 650,
            },
            {
              label: '700',
              value: 700,
            },
            {
              label: '750',
              value: 750,
            },
            {
              label: '800',
              value: 800,
            },
          ]}
          placeholder="650..."
          value={props.value}
        />
      </div>
    );
  }

  if (props.type === QualificationTypes.CIVIL_HISTORY || props.type === QualificationTypes.CRIMINAL_HISTORY) {
    return (
      <div style={{ width: '65px' }}>
        <Select
          isDisabled={props.isDisabled}
          isRequired
          name="value"
          onChange={props.onChange}
          options={[
            {
              label: '1',
              value: 1,
            },
            {
              label: '2',
              value: 2,
            },
            {
              label: '3',
              value: 3,
            },
            {
              label: '4',
              value: 4,
            },
            {
              label: '5',
              value: 5,
            },
          ]}
          placeholder="1-5..."
          value={props.value}
        />
      </div>
    );
  }

  return (
    <div style={{ width: '55px' }}>
      <Input
        isDisabled={props.isDisabled}
        isRequired
        name="value"
        maxAmount={getMaxAmount(props.type)}
        onChange={props.onChange}
        placeholder="30"
        type={Input.enums.Types.Number}
        value={props.value}
      />
    </div>
  );
}

export default withStyles(styles)(RequirementValueInput);
