import React from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import BrandShapesLeftBottom from './svgs/brand-shapes-left-bottom.svg';
import BrandShapesRightBottom from './svgs/brand-shapes-right-bottom.svg';
import BrandShapesLeftTop from './svgs/brand-shapes-left-top.svg';
import BrandShapesRightTop from './svgs/brand-shapes-right-top.svg';

import styles from './ListersBrandShapes.scss';

function ListersBrandShapes() {
  return (
    <div styleName="brand-shapes">
      <BrandShapesLeftTop styleName="brand-shapes__left-top" />
      <BrandShapesRightTop styleName="brand-shapes__right-top" />
      <BrandShapesLeftBottom styleName="brand-shapes__left-bottom" />
      <BrandShapesRightBottom styleName="brand-shapes__right-bottom" />
    </div>
  );
}

export default withStyles(styles)(ListersBrandShapes);
