import _ from 'lodash';
import React, { ComponentType } from 'react';

import { PaymentSubscriptionPlans } from '~tools/types/graphqlSchema';
import { PaymentSubscriptionPlanNames } from '~tools/constants/workspace';

import withStyles from '~tools/react/hocs/withStyles';

import ArrowLink from '~tools/react/components/ArrowLink';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import IconBlock from '~web-manage/lib/common/components/IconBlock';

import * as enums from './enums';

import styles from './SubscriptionSettingCard.scss';

interface InputProps {
  link: {
    path: string;
  };
  plan?: enums.Plans;
  title: string;
}

type Props = InputProps;

function SubscriptionSettingCard(props: Props) {
  const plan = props.plan || PaymentSubscriptionPlans.BASIC;
  const planName = PaymentSubscriptionPlanNames[plan] || 'Lite';
  const capitalPlan = _.startCase(_.toLower(plan));
  return (
    <div styleName="setting-card">
      <IconBlock
        icon={IconBlock.enums.Icons.Refresh}
      />
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
      <ArrowLink
        color={ArrowLink.enums.Colors.Blue}
        label={props.title}
        link={props.link}
        size={ArrowLink.enums.Sizes.Medium}
      />
      <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXSmall} />
      <Row
        flexBehavior={Row.enums.FlexBehaviors.Default}>
        <Text
          content="Change your"
        />
        <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXSmall} />
        <Text
          content={`*${planName}*`}
          color={Text.enums.Colors[`Brand${capitalPlan}Plan`]}
          isMarkdown
        />
        <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXXSmall} />
        <Text
          content="plan"
        />
      </Row>
    </div>
  );
}

export default withStyles(styles)(SubscriptionSettingCard) as ComponentType<Props>;
