import _ from 'lodash';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';


import { PaymentSubscriptionPlans } from '~tools/types/graphqlSchema';

import Stage from '~web-core/lib/common/stages/Stage';

import { compose } from '~tools/react/hocs/utils';
import withQuery from '~tools/react/graphql/withQuery';
import withAuth, { AuthProps } from '~tools/react/hocs/withAuth';
import withDevice, { DeviceProps } from '~tools/react/hocs/withDevice';
import withFetchOrCreateAddress, { FetchOrCreateAddressProps } from '~tools/react/graphql/mutations/addresses/withFetchOrCreateAddress';
import withFetchOrCreateAddressUnit, { FetchOrCreateAddressUnitProps } from '~tools/react/graphql/mutations/addressUnits/withFetchOrCreateAddressUnit';
import withIpGeolocation, { IpGeolocationProps } from '~tools/react/graphql/queries/withIpGeolocation';

import analytics, { Events } from '~tools/utils/analytics';
import { getBoundsFromCenter } from '~tools/utils/geography/coordinates';

import withCreateListing, { CreateListingProps } from '~tools/react/graphql/mutations/listings/withCreateListing';
import withFetchOrCreateListingForPropertyManagerContract, { FetchOrCreateListingForPropertyManagerContractProps } from '~tools/react/graphql/mutations/listings/withFetchOrCreateListingForPropertyManagerContract';
import withCreatePaymentSubscription, { CreatePaymentSubscriptionProps } from '~tools/react/graphql/mutations/paymentSubscriptions/withCreatePaymentSubscription';
import withCreatePropertyManagerContract, { CreatePropertyManagerContractProps } from '~tools/react/graphql/mutations/propertyManagerContracts/withCreatePropertyManagerContract';

import AuthModal from '~tools/react/containers/AuthModal';
import BasicHelmet from '~tools/react/components/BasicHelmet';
import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import Form, { FormField, Input } from '~tools/react/components/Form';
import GeocodeTypeahead from '~tools/react/components/GeocodeTypeahead';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import HorizontalRule from '~tools/react/components/HorizontalRule';
import InteractiveMap from '~tools/react/components/InteractiveMap';
import MarketingWidthConstraintView from '~tools/react/components/MarketingWidthConstraintView';
import Row from '~tools/react/components/Row';
import Text from '~tools/react/components/Text';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';
import WidthConstraintView from '~tools/react/components/WidthConstraintView';

import ApartmentNameView from './components/ApartmentNameView';
import MapView from './components/MapView';
import Logo from './components/FlipLogo';

import query from './LiteStart.gql';

const DEFAULT_MAP_BOUNDS = {
  minLng: -74.091796875,
  minLat: 40.647824541622086,
  maxLng: -73.92013549804688,
  maxLat: 40.77794168298968,
};

interface QueryProps {

  propertyManagers: {
    uuid: string;
  }[];
}


type InputProps = RouteComponentProps<{ addressUnitUuid: string }>;

type Props =
  InputProps &
  QueryProps &
  RouteComponentProps &
  FetchOrCreateAddressProps &
  FetchOrCreateAddressUnitProps &
  IpGeolocationProps &
  AuthProps &
  CreateListingProps &
  CreatePaymentSubscriptionProps &
  CreatePropertyManagerContractProps &
  FetchOrCreateListingForPropertyManagerContractProps &
  DeviceProps;

interface State {
  bounds?: {
    minLng: number;
    minLat: number;
    maxLng: number;
    maxLat: number;
  };
  center?: {
    lat: number;
    lng: number;
  };
  googlePlaceId?: string;
  isLoading: boolean;
  listingType?: string;
  isAuthModalOpen: boolean;
  activeAuthModalView: string;
  addressUnit?: string;
}

class LiteStart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      bounds: getBoundsFromCenter({
        lat: props.ipGeolocation?.latitude || 40.730610,
        lng: props.ipGeolocation?.longitude || -73.935242,
      }, 3),
      isLoading: false,
      activeAuthModalView: AuthModal.enums.Views.Register,
      addressUnit: '',
      isAuthModalOpen: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.ipGeolocation !== prevProps.ipGeolocation &&
      this.props.ipGeolocation?.latitude &&
      this.props.ipGeolocation?.longitude
    ) {
      this.setState({
        bounds: getBoundsFromCenter({
          lat: this.props.ipGeolocation.latitude,
          lng: this.props.ipGeolocation.longitude,
        }, 3),
      });
    }
  }

  handleSubmit = async (data: {
    name: string;
  }) => {
    const googlePlaceId = this.state.googlePlaceId;
    if (!googlePlaceId) return;
    this.setState({ isLoading: true });
    try {
      const address = await this.props.fetchOrCreateAddress({ googlePlaceId });
      const addressUnit = await this.props.fetchOrCreateAddressUnit({ addressUuid: address.uuid, name: data.name });
      this.setState({
        addressUnit: addressUnit.uuid,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
      throw err;
    }
  };

  handleChangeAuthModalView = (view: string) => this.setState({ activeAuthModalView: view });
  handleCloseAuthModal = async () => {
    this.setState({ isAuthModalOpen: false });
    if (this.props.isLoggedIn) this.handleSetupSubscription();
  };

  handleSetupSubscription = async () => {
    if (this.props.isLoggedIn && this.state.addressUnit) {
      try {
        await this.props.createPaymentSubscription({
          plan: PaymentSubscriptionPlans.LITE,
          quantity: 1,
        });
        const flipPropertyManager = this.props.propertyManagers[0];
        const propertyManagerContract = await this.props.createPropertyManagerContract({
          addressUnitUuid: this.state.addressUnit,
          propertyManagerUuid: flipPropertyManager.uuid,
          startsAt: new Date().toString(),
        });
        await this.props.fetchOrCreateListingForPropertyManagerContract({
          propertyManagerContractUuid: propertyManagerContract.uuid,
        });
        this.props.history.push(`/units/${propertyManagerContract.uuid}/`);
      }
      catch (err) {
        this.setState({ isLoading: false });
        throw err;
      }
    }
    else if (!this.props.isLoggedIn) {
      this.setState({ isAuthModalOpen: true });
      return;
    }
  }

  handleTypeaheadSubmit = (data: {
    bounds: {
      minLng: number;
      minLat: number;
      maxLng: number;
      maxLat: number;
    };
    center: {
      lat: number;
      lng: number;
    };
    placeId: string;
  }) => {

    this.setState({
      bounds: data.bounds,
      center: data.center,
      googlePlaceId: data.placeId,
    });

    analytics.track(Events.SelectNewUnitAddress);
  }

  render() {
    return (
      <Stage
        alert={null}
        hasAppBanner={false}
        hasFooter={false}
        hasNavigation={false}>
        <AuthModal
          activeView={this.state.activeAuthModalView}
          isOpen={this.state.isAuthModalOpen}
          onChangeView={this.handleChangeAuthModalView}
          onClose={this.handleCloseAuthModal}
        />
        <BasicHelmet
          title="Add a unit"
          description="Get started and add your unit on Caretaker."
          path="/lite/start"
        />
        <WidthConstraintView size={WidthConstraintView.enums.Sizes.Large}>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
          <div style={{ alignItems: 'center' }}>
            <Logo /></div>
          <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXLarge} />

          <MarketingWidthConstraintView>
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
            <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
              <div style={{ width: this.props.isMobile ? '100%' : '55%' }}>


                <Heading
                  content="Add your unit"
                  align={Heading.enums.Align.Center}
                  size={Heading.enums.Sizes.Large}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Small} />
                <Text
                  content="First we need your property's address."
                  size={Text.enums.Sizes.Large}
                  align={Text.enums.Align.Center}
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <HorizontalRule />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.XLarge} />
                <Form onSubmit={this.handleSubmit}>
                  <div>
                    <Row flexBehavior={Row.enums.FlexBehaviors.Grow} isColumnOnMobile={false}>
                      <div style={{ width: '100%' }}>
                        <GeocodeTypeahead
                          isRequired={true}
                          label="Address"
                          name="streetAddress1"
                          onSubmit={this.handleTypeaheadSubmit}
                          placeholder="1 Caretaker Road, New Britain, CT, USA"
                          size={Input.enums.Sizes.Large}

                        /></div>
                      <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                      <ApartmentNameView>
                        <Input
                          labelFormat={Input.enums.LabelFormats.Stacked}
                          label="Apt No."
                          name="name"
                          placeholder="2R"
                          size={Input.enums.Sizes.Large}

                        />
                      </ApartmentNameView>
                      <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Small} />
                      <div
                        style={{
                          maxWidth: '115px',
                          width: '115px',
                        }}>
                        <FormField
                          labelFormat={FormField.enums.LabelFormats.Stacked}
                          label="&nbsp;">
                          <Button
                            align={Button.enums.Alignments.Right}
                            icon={Button.enums.Icons.Plus}
                            isDisabled={!this.state.googlePlaceId}
                            isLoading={this.state.isLoading}
                            label="Add"
                            type={Button.enums.Types.Submit}
                          />
                        </FormField>
                      </div>
                    </Row>
                  </div>
                </Form>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
                {this.state.addressUnit ? (
                  <Card shadow={Card.enums.Shadows.None}>
                    <Card.CardSection>
                      <Row
                        flexBehavior={Row.enums.FlexBehaviors.Default}
                        isColumnOnMobile={false}
                        verticalAlignment={Row.enums.VerticalAlignments.Center}>
                        <div style={{ marginRight: 'auto' }}>
                          <Row
                            flexBehavior={Row.enums.FlexBehaviors.Default}
                            isColumnOnMobile={false}>
                            <Text
                              content="Looks good!"
                              size={Text.enums.Sizes.Large}
                              isEmphasized
                            />
                          </Row></div>
                      </Row>
                    </Card.CardSection>
                  </Card>
                ) : null}

                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <HorizontalRule />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
                <Text
                  content="You'll be able to make changes to this unit and invite a tenant from your dashboard at any point in the future."
                />
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Medium} />
                <Row flexBehavior={Row.enums.FlexBehaviors.Default}>
                  <Button
                    align={Button.enums.Alignments.Right}
                    label="Go back"
                    style={Button.enums.Styles.Inverse}
                    link={{
                      path: '/start?customer=basic',
                    }}
                  />
                  <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXSmall} />
                  <Button
                    align={Button.enums.Alignments.Right}
                    icon={Button.enums.Icons.ChevronRight}
                    isDisabled={!this.state.addressUnit}
                    isLoading={this.state.isLoading}
                    label="Continue"
                    type={Button.enums.Types.Submit}
                    onClick={this.handleSetupSubscription}
                  />  </Row>
                <VerticalSpacing size={VerticalSpacing.enums.Sizes.Large} />
              </div>
              <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.XXLarge} />
              {!this.props.isMobile ? (
                <div style={{ width: '45%' }}>
                  <Card>
                    <MapView>
                      <InteractiveMap bounds={this.state.bounds || DEFAULT_MAP_BOUNDS}>
                        {this.state.center ? (
                          <InteractiveMap.InteractiveMapMarker
                            latitude={this.state.center.lat}
                            longitude={this.state.center.lng}
                            type={InteractiveMap.InteractiveMapMarker.enums.Types.Pin}
                          />
                        ) : null}
                      </InteractiveMap>
                    </MapView>
                  </Card>
                </div>
              ) : null}
            </Row>
          </MarketingWidthConstraintView>
        </WidthConstraintView>
        <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXXLarge} />
      </Stage>
    );
  }
}

interface Variables {
  uuid: string;
}

type Response = QueryProps;

export default compose(
  withAuth,
  withDevice,
  withFetchOrCreateAddress,
  withFetchOrCreateAddressUnit,
  withCreateListing,
  withCreatePaymentSubscription,
  withCreatePropertyManagerContract,
  withFetchOrCreateListingForPropertyManagerContract,
  withIpGeolocation,
  withQuery<InputProps, Response, Variables, QueryProps>(query, {
    props: (props) => ({
      isLoading: props.loading,
      propertyManagers: props.data?.propertyManagers ?? [],
    }),
  }),
)(LiteStart);
