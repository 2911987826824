import classNames from 'classnames';
import _ from 'lodash';
import React, { PureComponent } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import styles from './HorizontalRule.scss';

import * as enums from './enums';

interface InputProps {
  color?: enums.Colors;
  size?: enums.Sizes;
}

type Props =
  InputProps;

class HorizontalRule extends PureComponent<Props> {
  static defaultProps = {
    color: enums.Colors.Primary,
  }
  static enums = enums;

  render() {
    return (
      <hr
        className={classNames({
          [styles['horizontal-rule']]: true,
          [styles[`horizontal-rule--color-${_.kebabCase(this.props.color)}`]]: this.props.color,
          [styles[`horizontal-rule--size-${_.kebabCase(this.props.size)}`]]: this.props.size,
        })}
      />
    );
  }
}

export default withStyles(styles)(HorizontalRule);
