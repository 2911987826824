import React, { Fragment } from 'react';

import Button from '~tools/react/components/Button';
import Card from '~tools/react/components/Card';
import Heading from '~tools/react/components/Heading';
import HorizontalSpacing from '~tools/react/components/HorizontalSpacing';
import VerticalSpacing from '~tools/react/components/VerticalSpacing';

import FlippedIllo from './svgs/flipped.svg';

interface Props {
  renterFirstName: string;
  leasePath: string;
}

function CompleteStep(props: Props) {
  return (
    <Fragment>
      <Card.CardSection>
        <div style={{ display: 'flex', justifyContent: 'space-between', }}>
          <div>
            <Heading
              content="It's a done deal!"
              font={Heading.enums.Fonts.Secondary}
              priority={Heading.enums.Priorities.Three}
              size={Heading.enums.Sizes.XXSmall}
            />
            <VerticalSpacing size={VerticalSpacing.enums.Sizes.XXSmall} />
            <Heading
              content={`${props.renterFirstName}'s application has been accepted and your lease is fully executed. Rent payments will be automatically charged to ${props.renterFirstName} as soon as the lease initiates.`}
              font={Heading.enums.Fonts.Secondary}
              priority={Heading.enums.Priorities.Four}
              size={Heading.enums.Sizes.XXXSmall}
            />
          </div>
          <div style={{ display: 'flex', }}>
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Large} />
            <FlippedIllo />
            <HorizontalSpacing size={HorizontalSpacing.enums.Sizes.Large} />
          </div>
        </div>
      </Card.CardSection>
      <Card.CardSection>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', }}>
          <Button
            label="Go to Leases"
            size={Button.enums.Sizes.Small}
            style={Button.enums.Styles.Outline}
            link={{
              path: props.leasePath,
            }}
          />
        </div>
      </Card.CardSection>
    </Fragment>
  );
}

export default CompleteStep;
