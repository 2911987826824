// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._P9W2oY{padding:1px;width:100%}", ""]);
// Exports
exports.locals = {
	"editable-access-notes": "_P9W2oY"
};
module.exports = exports;
