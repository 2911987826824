import withMutation from '~tools/react/graphql/withMutation';

import UpdateReadUpToMutation from '~tools/react/graphql/mutations/threads/updateReadUpTo.gql';

interface Thread {
  uuid: string;
}

interface Data {
  updateReadUpTo: Thread;
}

interface Variables {
  uuid: string;
}

export interface UpdateReadUpToProps {
  updateReadUpTo: (threadUuid: string) => Promise<Thread>;
}

export default withMutation<{}, Data, Variables, UpdateReadUpToProps>(UpdateReadUpToMutation, {
  props: props => ({
    updateReadUpTo: async (threadUuid) => {
      const res = await props.mutate({
        variables: {
          uuid: threadUuid,
        },
      });
      if (!res || !res.data) throw new Error('Error updating read up to.');

      return res.data.updateReadUpTo;
    },
  }),
});
