import React, { ReactElement } from 'react';

import withStyles from '~tools/react/hocs/withStyles';

import DataGridColumn from './components/DataGridColumn';

import styles from './DataGrid.scss';

interface Props {
  title?: string;
  children: ReactElement<typeof DataGridColumn> | ReactElement<typeof DataGridColumn>[];
}

function DataGrid(props: Props) {
  return (
    <div styleName="data-grid">
      {props.title ? (
        <h4 styleName="data-grid__title">
          {props.title}
        </h4>
      ) : null}
      <div styleName="data-grid__columns">
        {props.children}
      </div>
    </div>
  );
}

export default withStyles(styles)(DataGrid);
