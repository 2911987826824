// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._99vvMp{border:none;border-top:1px solid #e6e8eb;box-shadow:none;margin:0;padding:0;width:100%}._99vvMp._J2V113{border-color:#e6e8eb}._99vvMp._wdje7K{border-color:#3c4858}._99vvMp._O0GY3w{width:32px}", ""]);
// Exports
exports.locals = {
	"horizontal-rule": "_99vvMp",
	"horizontal-rule--color-primary": "_J2V113",
	"horizontal-rule--color-secondary": "_wdje7K",
	"horizontal-rule--size-small": "_O0GY3w"
};
module.exports = exports;
