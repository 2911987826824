// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._aeRJ1n{-webkit-box-align:center;align-items:center;border:1px solid #1f8eed;border-radius:5px;color:#1f8eed;cursor:pointer;display:-webkit-box;display:flex;-webkit-box-pack:center;justify-content:center;margin-bottom:16px;padding:12px 8px;-webkit-transition:all .25s cubic-bezier(.215,.61,.355,1);transition:all .25s cubic-bezier(.215,.61,.355,1);-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}._aeRJ1n:not(._x28YdK):hover{background:#1f8eed;color:#fff}._aeRJ1n input{display:none}", ""]);
// Exports
exports.locals = {
	"photos-upload-button": "_aeRJ1n",
	"photos-upload-button--disabled": "_x28YdK"
};
module.exports = exports;
