import withMutation from '~tools/react/graphql/withMutation';
import { CreateLeasesSettingInput } from '~tools/types/graphqlSchema';

import CreateLeasesSettingMutation from './createLeasesSetting.gql';

interface LeasesSetting {
  uuid: string;
}

interface Response {
  createLeasesSetting: LeasesSetting;
}

interface Variables {
  input: CreateLeasesSettingInput;
}

export interface CreateLeasesSettingProps {
  createLeasesSetting: (input: CreateLeasesSettingInput) => Promise<LeasesSetting>;
}

export default withMutation<{}, Response, Variables, CreateLeasesSettingProps>(CreateLeasesSettingMutation, {
  props: props => ({
    createLeasesSetting: async (input: CreateLeasesSettingInput) => {
      const res = await props.mutate({
        variables: { input },
      });

      if (!res || !res.data) throw new Error('Error creating leases setting');
      if (!res.data.createLeasesSetting) throw new Error('No createLeasesSetting returned');

      return res.data.createLeasesSetting;
    },
  }),
});
